import React, { useState, useEffect } from "react";
import { AutoComplete, Col, Grid, Row } from "antd";
import Icon from "../../../assets/icons";
import styled from "styled-components";
import { useGetCustomerListForSearchLazyQuery } from "../../../generated/graphql";

interface Props {
  onChange: (value: any) => void;
}

const { useBreakpoint } = Grid;

const CustomerSearch: React.FC<Props> = ({ onChange }) => {
  const breakpoints = useBreakpoint();
  const [getCustomerList, { data: customerList }] =
    useGetCustomerListForSearchLazyQuery();

  const [options, setOptions] = useState<any[]>([]);
  const [value, setValue] = useState("");

  useEffect(() => {
    const result = customerList?.customers.items.map((c) => ({
      label: `${c.firstName} ${c.lastName}`,
      value: c.id,
    }));
    setOptions(result || []);
  }, [customerList]);

  const handleSearch = (value: string) => {
    searchCustomer(value);
    setValue(value);
  };

  const handleSelect = (value: string, item: any) => {
    setValue(item.label);
    onChange(customerList?.customers.items.find((c) => c.id === value));
  };

  const searchCustomer = (value: string) => {
    getCustomerList({
      variables: {
        options: {
          filter: {
            lastName: {
              contains: value,
            },
          },
          take: 50,
        },
      },
    });
  };

  return (
    <Row gutter={24} align="middle">
      {breakpoints.md && (
        <Col md={{ span: 9 }} span={24}>
          <span style={{ fontSize: 18, fontWeight: 700 }}>Customer Search</span>
        </Col>
      )}
      <Col md={{ span: 15 }} span={24}>
        <InputArea>
          <Icon keyword="search" color="#C9CED6" width={24} />
          <AutoComplete
            options={options}
            onSelect={handleSelect}
            style={{ minWidth: 200, width: "100%" }}
            onSearch={handleSearch}
            placeholder="Last name"
            value={value}
          ></AutoComplete>
        </InputArea>
      </Col>
    </Row>
  );
};

const InputArea = styled.div`
  background-color: #fff;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  grid-gap: 12px;
  width: 100%;
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    .ant-select-selection-placeholder {
      font-size: 16px;
      font-weight: bold;
    }
    .ant-select-selection-search {
      input {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
`;

export default CustomerSearch;
