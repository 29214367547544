import React, { useEffect, useState } from "react";
import TwfModal from "../../../component/modal";
import Icon from "../../../assets/icons";
import ModalTitle from "../../../component/modal/title";
import { H3 } from "../../../component/header";
import { Col, Form, message, Row, Select } from "antd";
import { MarginBox } from "../../../component/margin";
import TwfButton from "../../../component/button";
import { useAddCustomersToClubMutation } from "../../../generated/graphql";

const { Item } = Form;

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  customerID: string;
  nonInvolvedClubs: any[];
}

const AddToClub: React.FC<ComponentProps> = ({
  visible,
  setVisible,
  customerID,
  nonInvolvedClubs,
}) => {
  const [form] = Form.useForm();
  const [addCustomersToClubMutation, { loading: addLoading }] =
    useAddCustomersToClubMutation();

  const [selectMapper, setSelectMapper] = useState([] as any[]);

  useEffect(() => {
    if (nonInvolvedClubs.length !== 0) {
      const selectObjects: any[] = [];
      nonInvolvedClubs.forEach((club) => {
        selectObjects.push({
          label: club.name,
          value: club.id,
        });
      });
      setSelectMapper(selectObjects);
    }
  }, [nonInvolvedClubs]);

  const submitFormHandler = (values: any) => {
    addCustomersToClubMutation({
      variables: {
        clubId: values.id,
        customerIds: [customerID],
      },
      refetchQueries: ["GetClubCustomerList"],
    })
      .then(({ data }) => {
        if (data?.addCustomersToClub) {
          message.success("Customer Added To Club");
          setVisible(false);
          form.resetFields();
        }
      })
      .catch((error) => message.error(error.message));
  };

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closeIcon={<Icon keyword="close" />}
      footer={false}
      width={662}
      centered
    >
      <ModalTitle>
        <H3>Add To Club</H3>
      </ModalTitle>
      <Form form={form} layout="vertical" onFinish={submitFormHandler}>
        <Item
          label="Club"
          name="id"
          rules={[
            {
              required: true,
              message: "Please select a club!",
            },
          ]}
        >
          <Select
            suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
            placeholder="Select"
            allowClear
            options={selectMapper}
          ></Select>
        </Item>
        <MarginBox mt={48}>
          <Row gutter={12} justify="end">
            <Col>
              <TwfButton
                color-twf="gray-line"
                size-twf="md"
                type-twf="wide"
                onClick={() => {
                  setVisible(false);
                  form.resetFields();
                }}
              >
                Cancel
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                type="primary"
                color-twf="primary"
                size-twf="md"
                type-twf="wide"
                htmlType="submit"
                loading={addLoading}
              >
                Add
              </TwfButton>
            </Col>
          </Row>
        </MarginBox>
      </Form>
    </TwfModal>
  );
};

export default AddToClub;
