import { List } from "antd";
import TwfButton from "component/button";
import styled from "styled-components";

const { Item } = List;

const AssetItem = styled(Item)`
  &.ant-list-item {
    position: relative;
    &.selected {
      font-size: 37px;
      border: 1px solid #ccc;
      .ant-card {
        background: #f7f7f7;
      }
    }
    &.selected::before {
      position: absolute;
      top: -25px;
      left: -20px;
      z-index: 1;
      content: "\\2713";
      line-height: 28px;
      background: white;
      border: 3px solid green;
      border-radius: 34px;
      font-size: 40px;
      font-weight: bold;
      padding: 10px;
      transition: auto;
      transform: scale(0.5);
      color: green;
    }
    & .ant-card-cover {
      position: relative;
      ::after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
      & .rect-img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    & .ant-card-meta-description {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

const AssetThumbnail = styled.div`
  border-radius: 14px;
  border: 1px solid #d9d9d9;
  & img {
    width: 100px;
    height: 100px;
    padding: 8px;
    border-radius: 14px;
  }
`;

const UploadButton = styled(TwfButton)`
  height: 90px;
  color: #333;
  font-size: 0.8rem;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  &:hover,
  :focus {
    color: #333;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
  }
`;

const PreviewItem = styled.div`
  overflow: hidden;
  word-break: break-all;
`;

export { AssetItem, AssetThumbnail, UploadButton, PreviewItem };
