import { H3 } from "component/header";
import TwfModal from "component/modal";
import React, { FC } from "react";
import CloseIcon from "../../../assets/icons/close";
import { Col, Divider, Row, Space } from "antd";
import { MarginBox } from "../../../component/margin";
import TableTitle, { TableBigTitle } from "../../../component/table/title";
import TableItem from "../../../component/table/item";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";
import { PriceDetail, PriceTable, PriceTotalDetail, PriceTotalValue, PriceValue } from "../../../component/table/price";

interface ReviewProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const SeasonReview: FC<ReviewProps> = ({
                                         visible,
                                         setVisible,
                                       }) => {
  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      width={800}
      footer={false}
      centered
      closeIcon={<CloseIcon />}
    >

      <H3>
        Process Club Member
      </H3>
      <MarginBox mt={24} mb={12}>
        <Row gutter={12}>
          <Col span={6}>
            <TableTitle>Product</TableTitle>
          </Col>
          <Col span={4}>
            <TableTitle>Price</TableTitle>
          </Col>
          <Col span={3}>
            <TableTitle>Qty</TableTitle>
          </Col>
          <Col span={4}>
            <TableTitle>Total</TableTitle>
          </Col>
        </Row>
        <Row gutter={12} align="middle">
          <Col span={6}>
            <TableItem>2020 Dawna Rosé</TableItem>
          </Col>
          <Col span={4}>
            <TableItem>$26.00</TableItem>
          </Col>
          <Col span={3}>
            <TableItem>2</TableItem>
          </Col>
          <Col span={4}>
            <TableItem>$52.00</TableItem>
          </Col>
          <Col span={7}>
            <Space>
              <TwfButton type-twf="icon" size-twf="sm">
                <Icon keyword="edit" width={16} color="#3C64B1" />
              </TwfButton>
              <TwfButton type-twf="icon" size-twf="sm">
                <Icon keyword="delete" width={16} color="#FF4C61" />
              </TwfButton>
            </Space>
          </Col>
        </Row>
      </MarginBox>
      <MarginBox mb={15}>
        <Row gutter={12}>
          <Col span={6}>
            <TableTitle>Customer</TableTitle>
            <TableItem>Sharath Chandra</TableItem>
          </Col>
          <Col span={6}>
            <TableTitle>Club</TableTitle>
            <TableItem>Anarchist 6 Bottle</TableItem>
          </Col>
          <Col span={6}>
            <TableTitle>Order Type</TableTitle>
            <TableItem>Club Order</TableItem>
          </Col>
          <Col span={6}>
            <TableTitle>Submitted Date</TableTitle>
            <TableItem>06/02/2021</TableItem>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <TableTitle>Payment Type</TableTitle>
            <TableItem>Credit Card</TableItem>
          </Col>
          <Col span={6}>
            <TableTitle>Shipping Options</TableTitle>
            <TableItem>Delivery</TableItem>
          </Col>
          <Col span={6}>
            <TableTitle>Credit Card Information</TableTitle>
            <TableItem>1234 1234 1234 1234</TableItem>
          </Col>
          <Col span={6}>
            <TableTitle>Future Ship Date</TableTitle>
            <TableItem>06/15/2021</TableItem>
          </Col>
        </Row>
        <Divider />
        <MarginBox>
          <TableBigTitle>Shipping Information</TableBigTitle>
          <TableItem>Sharath Chandra</TableItem>
          <TableItem>(123) 456-7890</TableItem>
          <TableItem>chandra@thewinefoundry.com</TableItem>
          <TableItem>01/01/1980</TableItem>
          <TableItem>1234 Happy Road, Apt#4, San Jose, CA 95123</TableItem>

          <TableBigTitle>Billing Information</TableBigTitle>
          <TableItem>1234 Happy Road, Apt#4, San Jose, CA 95123</TableItem>
        </MarginBox>
        <Divider />
        <MarginBox
          style={{ maxWidth: 400 }}
          mb={48}
        >
          <PriceTable>
            <tbody>
            <tr>
              <PriceDetail>Shipping</PriceDetail>
              <PriceValue>$0.00</PriceValue>
            </tr>
            <tr>
              <PriceDetail>Sales Tax</PriceDetail>
              <PriceValue>$0.00</PriceValue>
            </tr>
            <tr>
              <PriceDetail>Discounts</PriceDetail>
              <PriceValue>$0.00</PriceValue>
            </tr>
            <tr>
              <PriceDetail>Subtotal</PriceDetail>
              <PriceValue>$14.00</PriceValue>
            </tr>
            <tr>
              <PriceTotalDetail>Total</PriceTotalDetail>
              <PriceTotalValue>$104.00</PriceTotalValue>
            </tr>
            </tbody>
          </PriceTable>
        </MarginBox>
        <Row gutter={12} justify="end">
          <Col>
            <TwfButton
              type="primary"
              size-twf="md"
              type-twf="wide"
              ghost
            >
              Cancel
            </TwfButton>
          </Col>
          <Col>
            <TwfButton
              type="primary"
              size-twf="md"
              type-twf="wide"
            >
              Cancel
            </TwfButton>
          </Col>
        </Row>
      </MarginBox>
    </TwfModal>
  );
};

export default SeasonReview;