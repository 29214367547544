import React, { FC } from "react";
import { PageTitle } from "../../../component/header";
import { Col, Form, Grid, Input, Row, Space } from "antd";
import TwfButton from "../../../component/button";
import ShadowCard from "../../../component/card";
import { MarginBox } from "../../../component/margin";
import EditProfileMobileFooter from "./mobile-footer";

const { useBreakpoint } = Grid;
const { Item } = Form;

const EditProfilePage: FC = () => {
  const breakpoints = useBreakpoint();
  return (
    <div>
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <Row justify="space-between" align="middle">
          <Col>
            <PageTitle>
              Edit Profile
            </PageTitle>
          </Col>
          {
            breakpoints.md ? (
              <Col>
                <Space size={24}>
                  <TwfButton ghost size-twf="lg" type-twf="wide" type="primary">
                    Cancel
                  </TwfButton>
                  <TwfButton size-twf="lg" color-twf="primary" type-twf="wide">
                    Save
                  </TwfButton>
                </Space>
              </Col>
            ) : (
              <EditProfileMobileFooter/>
            )
          }
        </Row>
        <ShadowCard style={{ padding: breakpoints.md ? 42: 20 }}>
          <MarginBox style={{ maxWidth: "500px" }}>
            <Form layout="vertical">
              <Item label="Username">
                <Input />
              </Item>
              <Item label="First Name">
                <Input />
              </Item>
              <Item label="Last Name">
                <Input />
              </Item>
              <Item label="Email">
                <Input />
              </Item>
            </Form>
          </MarginBox>
        </ShadowCard>
      </Space>
    </div>
  );
};

export default EditProfilePage;