import { LOGIN, LOGOUT } from "../action/authenticate";

const INITIAL_STATE = {};

interface ActionType {
  type?: string;
  data?: any;
}

const UserReducer = (state: any = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        ...action.data,
      };
    case LOGOUT:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default UserReducer;
