import styled from "styled-components";

const TableItem = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 4px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export default TableItem;
