import { Col, Form, Input, message, Row } from "antd";
import Center from "component/center";
import { H2 } from "component/header";
import { MarginBox } from "component/margin";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import AuthLayoutCard from "../style/card";
import AuthLayoutContainer from "../style/container";
import AuthLayoutWrap from "../style/wrap";
import * as qs from "query-string";
import { useResetAdminPasswordMutation } from "generated/graphql";
import { useNavigate } from "react-router-dom";

const { Item } = Form;

const ResetPassword = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [resetAdminPassword, { loading }] = useResetAdminPasswordMutation();

  const submitFormHandler = (values: any) => {
    const password = values.password;

    if (
      typeof window !== "undefined" &&
      password &&
      password.trim().length !== 0
    ) {
      const parsed = qs.parse(window.location.search);
      const givenToken = parsed.token;
      resetAdminPassword({
        variables: {
          password: password,
          token: givenToken as string,
        }
      }).then((data)=>{
        if(data?.data?.resetAdminPassword?.id){
          message.success("Your password has been successfully reset. You can now proceed with logging into your account");
          setTimeout(()=>{
            navigate("/");
          },1000);
        }
      }).catch((error)=>{
        message.error(error.message);
      })
        .then((data: any) => {
          if (data?.data?.resetAdminPassword?.id) {
            message.success(
              "Password reset successfully . Please continue login."
            );
            setTimeout(() => {
              navigate("/");
            }, 1000);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  return (
    <AuthLayoutContainer>
      <AuthLayoutCard>
        <AuthLayoutWrap>
          <Center>
            <H2>Setup your new password</H2>
            <MarginBox mb={40} />
            <Form form={form} onFinish={submitFormHandler}>
              <Row align="middle" justify="center" gutter={[0, 24]}>
                <Col span={24}>
                  <Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter new password!",
                      },
                    ]}
                  >
                    <Input placeholder="New Password" type="password" />
                  </Item>
                  <Item
                    name="confirmPassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm the password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="Confirm Password" type="password" />
                  </Item>
                </Col>
                <Col span={24}>
                  <Item>
                    <TwfButton
                      type="primary"
                      color-twf="primary"
                      size-twf="lg"
                      type-twf="wide"
                      htmlType="submit"
                      loading={loading}
                      style={{ margin: "0 auto" }}
                    >
                      Reset Password
                      <Icon keyword="arrow-right" width={20} color="#fff" />
                    </TwfButton>
                  </Item>
                </Col>
              </Row>
            </Form>
          </Center>
        </AuthLayoutWrap>
      </AuthLayoutCard>
    </AuthLayoutContainer>
  );
};

export default ResetPassword;
