import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';

interface ComponentProps {
    value?:string|undefined;
    setContent: any;
};

const RichEditor: React.FC<ComponentProps> = ({
    value,
    setContent
}) => {

    const [editorValue, setEditorValue] =
    useState(RichTextEditor.createValueFromString("", 'html'));
    
    useEffect(()=>{
        if(value){
            setEditorValue(
                RichTextEditor.createValueFromString(value, 'html')
            );
        }
    },[value])
    
    const handleChange = (newValue:any) => {
        setEditorValue(newValue);
        setContent(newValue.toString("html"));
    };

    const handleHtmlChange = (newHtmlValue:any) =>{
        setEditorValue(
            RichTextEditor.createValueFromString(newHtmlValue.target.value,'html')
        )
        setContent(newHtmlValue.target.value);
    }
    
    return (
        <>
        <div>
            <RichTextEditor
            value={editorValue}
            onChange={handleChange}
            editorStyle={{ height:"500px" }}
            />
        </div>
        <div style={{ marginTop:"2%" }}>

        <TextArea
            style={{ height:"500px" }}
            className="emailSource"
            placeholder="Email Template Source Code"
            value={editorValue.toString("html")}
            onChange={handleHtmlChange}
         />
         
        </div>
        </>
    );


}

export default RichEditor;
