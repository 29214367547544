import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const HomeIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.24912 14.0849H4.33245V21.6682C4.33245 22.8631 5.3042 23.8349 6.49912 23.8349H19.4991C20.694 23.8349 21.6658 22.8631 21.6658 21.6682V14.0849H22.7491C22.9633 14.0848 23.1728 14.0212 23.3509 13.9022C23.529 13.7832 23.6678 13.614 23.7498 13.4161C23.8317 13.2181 23.8532 13.0004 23.8114 12.7902C23.7696 12.5801 23.6665 12.3871 23.515 12.2356L13.765 2.4856C13.6645 2.38491 13.5451 2.30503 13.4137 2.25053C13.2823 2.19602 13.1414 2.16797 12.9991 2.16797C12.8568 2.16797 12.716 2.19602 12.5845 2.25053C12.4531 2.30503 12.3337 2.38491 12.2332 2.4856L2.4832 12.2356C2.33174 12.3871 2.2286 12.5801 2.18682 12.7902C2.14504 13.0004 2.16649 13.2181 2.24847 13.4161C2.33044 13.614 2.46926 13.7832 2.64737 13.9022C2.82548 14.0212 3.03489 14.0848 3.24912 14.0849ZM10.8325 21.6682V16.2515H15.1658V21.6682H10.8325ZM12.9991 4.78335L19.4991 11.2834V16.2515L19.5002 21.6682H17.3325V16.2515C17.3325 15.0566 16.3607 14.0849 15.1658 14.0849H10.8325C9.63754 14.0849 8.66578 15.0566 8.66578 16.2515V21.6682H6.49912V11.2834L12.9991 4.78335Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export default HomeIcon;
