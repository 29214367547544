import { MenuProps } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../../assets/icons";
import { TwfMenu } from "../style/menu";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

interface ComponentProps {
  collapsed: boolean;
  setSideMenuVisible: (value: boolean) => void;
}

const TwfMenuLists: React.FC<ComponentProps> = ({
  collapsed,
  setSideMenuVisible,
}) => {
  const history = useNavigate();
  const location = useLocation();

  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  let mainAdmin = false;

  if (localStorage) {
    if (localStorage.getItem("used-channel") === "__default_channel__") {
      mainAdmin = true;
    }
  }

  const adminMenuItems: MenuItem[] = [
    getItem("Brands", "brands", <Icon keyword="home" />),
    getItem("Orders", "orders", <Icon keyword="credit" />),
    getItem("Products", "products", <Icon keyword="bottle" />),
    getItem("Marketing", "marketing", <Icon keyword="marketing" />),
    getItem("Compliance", "compliance", <Icon keyword="lineChart" />),
    getItem("Shipping", "shipping", <Icon keyword="shipping" />),
    getItem("Reports", "reports", <Icon keyword="guide" />),
    getItem("Help", "guide", <Icon keyword="lineChart" />),
    getItem("Settings", "settings", <Icon keyword="setting" />),
    getItem("Announcements", "announcement", <Icon keyword="announce" />),
    getItem("BETA 1.0.0", "/", <></>),
  ];

  const storeMenuItems: MenuItem[] = [
    getItem("Brands", "brands", <Icon keyword="home" />),
    getItem("Dashboard", "dashboard", <Icon keyword="dashboard" />),
    getItem("Orders", "orders", <Icon keyword="credit" />),
    getItem("Customers", "customers", <Icon keyword="customers" />),
    getItem("Assets", "assets", <Icon keyword="photo" />),
    getItem("Products", "products", <Icon keyword="bottle" />),
    getItem("Marketing", "marketing", <Icon keyword="marketing" />),
    getItem("Club", "club", <Icon keyword="club" />),
    getItem("Compliance", "compliance", <Icon keyword="lineChart" />),
    getItem("Shipping", "shipping", <Icon keyword="shipping" />),
    getItem("Reports", "reports", <Icon keyword="guide" />),
    getItem("Help", "guide", <Icon keyword="lineChart" />),
    getItem("Settings", "settings", <Icon keyword="setting" />),
    getItem("BETA 1.0.0", "/", <></>),
  ];

  useEffect(() => {
    if (localStorage.getItem("vendure-token") === "__default_channel__") {
      setMenuItems(adminMenuItems);
    } else {
      if (!mainAdmin) {
        storeMenuItems.shift();
      }
      setMenuItems(storeMenuItems);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("vendure-token")]);

  const [keys, setKeys] = useState<any[]>([]);

  useEffect(() => {
    setKeys([location.pathname.split("/")[1]]);
  }, [location]);

  const onRouteHandler = ({ key }: any) => {
    setSideMenuVisible(false);
    history("/" + key);
    setKeys([key]);
  };

  return (
    <TwfMenu
      mode="inline"
      selectedKeys={keys}
      defaultSelectedKeys={["dashboard"]}
      onClick={onRouteHandler}
      items={menuItems}
      inlineCollapsed={collapsed}
    />
  );
};

export default TwfMenuLists;
