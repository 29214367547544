import styled from "styled-components";
import { H1 } from "../../../component/header";

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
  grid-gap: 48px;
  padding: 21px 45px;
  min-height: 151px;
  @media (max-width: 768px) {
    padding: 12px 10px;
    grid-template-columns: 20px auto;
    grid-gap: 12px;
    min-height: 70px;
  }
`;
export const CardTitle = styled(H1)`
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 11px;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 4px;
  }
`;
