import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const AnnounceIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    version="1.1"
    id="Layer_1"
    viewBox="0 0 492.249 492.249"
    width={width ? width : 22}
    height={width ? width : 22}
    fill={color ? color : "#000"}
    color={color ? color : "#000"}
    strokeWidth="2"
  >
    <g>
      <g>
        <path
          d="M400.922,46.353c-10.115,0-19.771,3.52-28.797,9.841l-0.059-0.081c-121.12,87.986-233.341,94.62-287.274,97.808
			l-4.332,0.26c-30.26,1.822-57.784,21.534-71.827,51.433c-11.385,24.24-11.519,53.5-0.351,78.279
			c14.514,32.197,42.298,51.736,72.856,51.774c4.151-0.032,9.093,0.053,14.383,0.217c-5.95,40.993,18.324,82.988,60.386,101.211
			c13.51,5.856,27.755,8.803,41.87,8.803c11.01,0,21.942-1.793,32.385-5.394c24.149-8.327,42.817-25.303,52.563-47.793
			c1.371-3.163,2.472-6.425,3.447-9.721c27.878,13.084,56.798,29.589,85.895,50.726l0.059-0.081c9.026,6.32,18.682,9.84,28.797,9.84
			c51.212,0,91.327-87.216,91.327-198.558S452.134,46.353,400.922,46.353z M264.658,384.877
			c-7.524,17.361-22.053,30.505-40.918,37.01c-19.183,6.601-40.49,5.591-60.005-2.861c-34.43-14.921-54.199-49.218-48.514-82.081
			c37.716,2.969,92.082,12.305,152.745,37.95C267.106,378.296,266.059,381.647,264.658,384.877z M80.966,315.973
			c-21.683,0.207-43.639-15.577-54.731-40.178c-8.832-19.601-8.75-42.707,0.226-61.808c10.976-23.375,32.12-38.76,55.183-40.154
			l4.308-0.255c48.766-2.882,143.603-8.502,250.7-70.865c-16.766,35.809-27.05,85.893-27.05,142.202
			c0,56.346,10.299,106.454,27.084,142.266C225.644,322.555,123.325,315.467,80.966,315.973z M386.167,244.915
			c0,30.74-22.948,56.158-52.601,60.183c-2.701-18.454-4.274-38.556-4.274-60.183c0-21.629,1.574-41.733,4.275-60.188
			C363.221,188.754,386.167,214.175,386.167,244.915z M400.922,423.781c-5.76,0-11.578-2.165-17.292-5.981l0.013-0.019
			c-1.004-0.729-2.002-1.347-3.005-2.066c-17.996-14.128-34.451-46.381-43.659-91.24c38.887-5.659,68.881-39.128,68.881-79.559
			c0-40.435-29.995-73.904-68.88-79.564c9.205-44.842,25.651-77.086,43.639-91.222c1.013-0.725,2.011-1.347,3.025-2.084
			l-0.013-0.018c5.713-3.816,11.532-5.982,17.292-5.982c34.596,0,71.635,71.875,71.635,178.87S435.518,423.781,400.922,423.781z"
        />
      </g>
    </g>
  </svg>
);

export default AnnounceIcon;
