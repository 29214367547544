import styled from "styled-components";

const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 56px;
  margin-bottom: 27px;
`;

export default ModalTitle;
