import { FunctionComponent } from "react";
import { Row, Col, InputNumber } from "antd";
import TwfButton from "component/button";
import { OperationCard, OperationProps } from "../conditions";

const OrderPercentageDiscountAction: FunctionComponent<OperationProps> = ({
  code,
  configArgs,
  onConfigArgChange,
  onRemove,
}) => {
  const discount =
    configArgs.find((arg) => arg.name === "discount")?.value || "0";

  return (
    <OperationCard
      title="Discount order by _%"
      style={{ marginTop: 16 }}
      actions={[
        <TwfButton key="remove" type="link" onClick={() => onRemove(code)}>
          Remove
        </TwfButton>,
      ]}
    >
      <Row>
        <Col>
          Discount:{" "}
          <InputNumber
            min={0}
            max={100}
            formatter={(value: any) => `${value}%`}
            parser={(value: any) => value.replace("%", "")}
            value={discount}
            onChange={(value: any) =>
              onConfigArgChange(
                { name: "discount", value: String(value) },
                code
              )
            }
            style={{ width: 160 }}
          />
        </Col>
      </Row>
    </OperationCard>
  );
};

export default OrderPercentageDiscountAction;
