import { Checkbox, Col, Form, Input, message, Row } from "antd";
import USStateSelector from "component/form-elements/us-state-selector";
import SmartyAutoComplete from "component/smarty-street";
import { Suggestion } from "component/smarty-street/types";
import { Address } from "generated/graphql";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store";
import { setCustomerStreetLine1Action } from "store/action/ui.action";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import { H3 } from "../../../component/header";
import TwfModal from "../../../component/modal";
import ModalTitle from "../../../component/modal/title";
import { useUpdateCustomerAddressMutation } from "../../../generated/graphql";
const { Item } = Form;

interface ComponentProps {
  address?: Address;
  visible?: boolean;
  setVisible: (value: boolean) => void;
}

const CustomerSingleAddAddress: React.FC<ComponentProps> = ({
  address,
  visible,
  setVisible,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const customerAddressStreetLine1 = useSelector(
    (state: any) => state.ui.customerAddressStreetLine1
  );
  const now = new Date();
  const minDate = new Date(
    now.getFullYear() - 130,
    now.getMonth(),
    now.getDate()
  );
  const maxDate = new Date(
    now.getFullYear() - 21,
    now.getMonth(),
    now.getDate()
  );

  const [form] = Form.useForm();

  const [updateCustomerAddressMutation, { loading: updateLoading }] =
    useUpdateCustomerAddressMutation();

  useEffect(() => {
    if (address) {
      form.setFieldsValue({
        ...address,
        ...address.customFields,
        defaultBillingAddress: [address.defaultBillingAddress],
        defaultShippingAddress: [address.defaultShippingAddress],
      });
    }
  }, [address, form]);

  const submitFormHandler = (data: any) => {
    if (!data.streetLine1) {
      data.streetLine1 = customerAddressStreetLine1;
    }
    updateCustomerAddress(data);
  };

  const updateCustomerAddress = (data: any) => {
    if (customerAddressStreetLine1 !== "") {
      data.streetLine1 = customerAddressStreetLine1;
    }
    if (address?.id) {
      updateCustomerAddressMutation({
        variables: {
          input: {
            id: address.id,
            phoneNumber: data.phoneNumber,
            streetLine1: data.streetLine1,
            streetLine2: data.streetLine2,
            company: data.company,
            city: data.city,
            province: data.province,
            countryCode: "us",
            postalCode: data.postalCode,
            defaultBillingAddress: data.defaultBillingAddress[0] ? true : false,
            defaultShippingAddress: data.defaultShippingAddress[0]
              ? true
              : false,
            customFields: {
              nickName: data.nickName,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              dob: data.dob,
            },
          },
        },
        refetchQueries: ["GetCustomer"],
      })
        .then((data) => {
          if (data.data?.updateCustomerAddress) {
            message.success("Successfully updated customer address");
            setVisible(false);
            form.resetFields();
            dispatch(setCustomerStreetLine1Action(""));
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  const cancelHandler = () => {
    form.resetFields();
    setVisible(false);
  };

  const initialValues = {
    countryCode: "United States",
  };

  const addressChangeHandler = (address: Suggestion) => {
    form.setFieldsValue({
      streetLine1: address.street_line,
      streetLine2: address.secondary,
      city: address.city,
      province: address.state,
      postalCode: address.zipcode,
    });
  };

  return (
    <TwfModal
      visible={visible}
      footer={false}
      closeIcon={<Icon keyword="close" />}
      width={662}
      centered
      onCancel={cancelHandler}
    >
      <ModalTitle>
        <H3>Edit Address</H3>
      </ModalTitle>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={submitFormHandler}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Item label="Nick Name" name="nickName">
              <Input placeholder="Nick Name" />
            </Item>
          </Col>
          <Col sm={12} span={24}>
            <Item label="First Name" name="firstName">
              <Input placeholder="First Name" />
            </Item>
          </Col>
          <Col sm={12} span={24}>
            <Item label="Last Name" name="lastName">
              <Input placeholder="Last Name" />
            </Item>
          </Col>
          <Col sm={12} span={24}>
            <Item label="Phone" name="phoneNumber">
              <Input placeholder="Phone" />
            </Item>
          </Col>
          <Col sm={12} span={24}>
            <Item label="Email" name="email">
              <Input placeholder="Email" />
            </Item>
          </Col>
          <Col sm={24} span={24}>
            <Item label="Company" name="company">
              <Input placeholder="Company" />
            </Item>
          </Col>
          <Col span={24}>
            <Item label="DOB" name="dob">
              <Input
                type="date"
                id="date_of_birth_alt"
                name="date_of_birth_alt"
                min={minDate.toISOString().substring(0, 10)}
                max={maxDate.toISOString().substring(0, 10)}
                autoComplete="bday"
                required
                placeholder="DOB (MM/DD/YYYY)"
              />
            </Item>
          </Col>
          <Col span={24}>
            <Item label="Address" name="streetLine1">
              <SmartyAutoComplete
                customerAddress
                customerStreetAddress1={address?.streetLine1}
                selectedData={addressChangeHandler}
              />
            </Item>
            <Item name="streetLine2">
              <Input placeholder="Address 2" />
            </Item>
          </Col>
          <Col sm={12} span={24}>
            <Item label="City" name="city">
              <Input placeholder="City" />
            </Item>
          </Col>
          <Col sm={12} span={24}>
            <USStateSelector label="State" name="province" />
          </Col>
          <Col sm={12} span={24}>
            <Item label="Country" name="countryCode">
              <Input placeholder="Country" readOnly />
            </Item>
          </Col>
          <Col sm={12} span={24}>
            <Item label="Zip" name="postalCode">
              <Input placeholder="ZIP" />
            </Item>
          </Col>
          <Col sm={12} span={24}>
            <Item name="defaultBillingAddress">
              <Checkbox.Group>
                <Checkbox value={true}>Default Billing Address</Checkbox>
              </Checkbox.Group>
            </Item>
          </Col>
          <Col sm={12} span={24}>
            <Item name="defaultShippingAddress">
              <Checkbox.Group>
                <Checkbox value={true}>Default Shipping Address</Checkbox>
              </Checkbox.Group>
            </Item>
          </Col>
          <Col span={24}>
            <Row gutter={[12, 12]} justify="end">
              <Col>
                <TwfButton
                  size-twf="xm"
                  color-twf="gray-line"
                  type-twf="wide"
                  onClick={cancelHandler}
                >
                  Cancel
                </TwfButton>
              </Col>
              <Col>
                <TwfButton
                  size-twf="xm"
                  type="primary"
                  color-twf="primary"
                  type-twf="wide"
                  htmlType="submit"
                  loading={updateLoading}
                >
                  Save
                </TwfButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </TwfModal>
  );
};

export default CustomerSingleAddAddress;
