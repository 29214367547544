import React from "react";
import { Table } from "antd";
import { SettingPanelProps } from "./../index";

const OwnerProfileSetting: React.FC<SettingPanelProps> = ({
  channel,
  columns,
  isMain
}) => {
  const customFields = channel?.customFields;

  const data = [
    {
      key: "phone",
      option: "Phone",
      value: customFields?.phone,
      readonly: isMain===true? null:true,
    },
    {
      key: "address",
      option: "Address",
      value: customFields?.address,
      readonly: isMain===true? null:true,
    },
    {
      key: "city",
      option: "City",
      value: customFields?.city,
      readonly: isMain===true? null:true,
    },
    {
      key: "state",
      option: "State",
      value: customFields?.state,
      readonly: isMain===true? null:true,
    },
    {
      key: "zip",
      option: "Zip",
      value: customFields?.zip,
      readonly: isMain===true? null:true,
    },
    {
      key: "membershipLevel",
      option: <span style={{ color: "#FB8832" }}>Membership Level</span>,
      render: (value: string) => {
        if(isNaN(Number(value))){
          return value;
        }else{
          return <span style={{ color: "#FB8832" }}>${value}</span>;
        }
      }, 
      value: customFields?.membershipLevel,
      readonly: isMain===true? null:true,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      showHeader={false}
      style={{ width: "100%" }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default OwnerProfileSetting;
