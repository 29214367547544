import { FunctionComponent } from "react";
import TwfButton from "component/button";
import { OperationProps } from "../conditions";
import { EmptyCard } from "../actions";

const FreeOfCostAction: FunctionComponent<OperationProps> = ({
  code,
  onRemove,
}) => {
  return (
    <EmptyCard
      title="Free of Cost"
      style={{ marginTop: 16 }}
      actions={[
        <TwfButton key="remove" type="link" onClick={() => onRemove(code)}>
          Remove
        </TwfButton>,
      ]}
    ></EmptyCard>
  );
};

export default FreeOfCostAction;
