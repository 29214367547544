import React from "react";
import { Checkbox, Col, Form, Row, Typography } from "antd";
import { TwfCard } from "../../../../component/card";
import { BlockTitle } from "../../../../component/header";
import { MarginBox } from "../../../../component/margin";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
const { Item } = Form;

const DiscountShippingTypes: React.FC = () => {
  const breakpoints = useBreakpoint();
  return (
    <TwfCard
      style={{ padding: breakpoints.md ? "32px 48px" : "15px", minHeight: 600 }}
    >
      <MarginBox mb={32}>
        <BlockTitle>Shipping Types</BlockTitle>
      </MarginBox>
      <Row gutter={[30, 15]}>
        <Col span={24} md={{ span: 6 }}>
          <MarginBox mb={12}>
            <Typography>Merchandise Shipping</Typography>
          </MarginBox>
          <Item>
            <Checkbox>GSO Ground</Checkbox>
          </Item>
          <Item>
            <Checkbox>UPS Ground</Checkbox>
          </Item>
          <Item>
            <Checkbox>UPS Expedited</Checkbox>
          </Item>
          <Item>
            <Checkbox>UPS Overnight</Checkbox>
          </Item>
        </Col>
        <Col span={24} md={{ span: 6 }}>
          <MarginBox mb={12}>
            <Typography>Shipping Current</Typography>
          </MarginBox>
          <Item>
            <Checkbox>GSO Ground</Checkbox>
          </Item>
          <Item>
            <Checkbox>UPS Ground</Checkbox>
          </Item>
          <Item>
            <Checkbox>UPS Expedited</Checkbox>
          </Item>
          <Item>
            <Checkbox>UPS Overnight</Checkbox>
          </Item>
        </Col>
        <Col span={24} md={{ span: 6 }}>
          <MarginBox mb={12}>
            <Typography>Shipping New</Typography>
          </MarginBox>
          <Item>
            <Checkbox>GSO Ground</Checkbox>
          </Item>
          <Item>
            <Checkbox>UPS Expedited</Checkbox>
          </Item>
        </Col>
        <Col span={24} md={{ span: 6 }}>
          <MarginBox mb={12}>
            <Typography>Text</Typography>
          </MarginBox>
          <Item>
            <Checkbox>GSO Ground</Checkbox>
          </Item>
        </Col>
      </Row>
    </TwfCard>
  );
};

export default DiscountShippingTypes;
