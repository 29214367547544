import React from "react";
import { Form, Input,notification } from "antd";
import TwfButton from "../../../component/button";
import { Channel, useUpdateChannelMutation } from "generated/graphql";


//TODO fix Type
interface Props {
  channel: Channel | any;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const AdvisoryMessage: React.FC<Props> = ({ channel }) => {
  const [updateChannelMutation, { loading }] = useUpdateChannelMutation();
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: 'Success',
      description:
        'Advisory Message Was Updated',
    });
  };

  const onFinish = (values: any) => {
    try {
      updateChannelMutation({
        variables: {
          input: { id: channel.id, ...values },
        },
        refetchQueries: ["getActiveChannel"],
      });
      openNotificationWithIcon('success')
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form
      name="nest-messages"
      onFinish={onFinish}
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 20,
        minHeight: 100,
      }}
    >
      {contextHolder}
      <Form.Item
        name={["customFields", "advisoryMessage"]}
       // rules={[{ required: true, message: "Please Input Advisory Message" }]}
      >
        <Input.TextArea
          style={{ minHeight: 100 }}
          defaultValue={channel?.customFields?.advisoryMessage}
        />
      </Form.Item>

      <span style={{ padding: 20 }}>
        <TwfButton
          type="primary"
          htmlType="submit"
          style={{ borderRadius: 4 }}
          loading={loading}
        >
          SAVE
        </TwfButton>
      </span>
    </Form>
  );
};

export default AdvisoryMessage;
