import React, { FC, Fragment, useState, useEffect } from "react";
import { Col, message, Popconfirm, Row, Space, Tabs } from "antd";
import { PageTitle } from "../../../component/header";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";
import SeasonShipmentSetup from "./seasonShipmentSetup";
import SeasonClubMembers from "./seasonClubMembers";
import SeasonSummary from "./seasonSummary";
import SeasonReview from "./seasonReview";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import SeasonProcessMobileFooter from "../components/mobile-footer";
import { useNavigate, useParams } from "react-router";
import {
  useGetSeasonQuery,
  useProcessSeasonMutation,
  useUpdateSeasonMutation,
} from "generated/graphql";
import { SeasonProcessState } from "enums/enums";

const { TabPane } = Tabs;

export interface SeasonShipment {
  shipDate?: Date;
}

const SeasonSteps: FC = () => {
  const history = useNavigate();
  const breakpoints = useBreakpoint();
  const { id } = useParams<{ id: string }>();
  const cid:string = id as string;
  const { data } = useGetSeasonQuery({ variables: { id:cid } });
  const [updateSeasonMutation, { loading }] = useUpdateSeasonMutation();
  const [processSeasonMutation,{ loading:processSeasonLoading }] = useProcessSeasonMutation();

  const [reviewVisible, setReviewVisible] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<string>("shipmentSetup");
  const [seasonShipment, setSeasonShipment] = useState<SeasonShipment>();

  const [proc,setProc] = useState<string>("Process All Members");


  useEffect(() => {
    if (data)
      setSeasonShipment({ shipDate: data?.season?.seasonProcess?.shipDate });
  }, [data]);

  const callbackHandler = (key: any) => {
    setCurrentStep(key);
  };

  const saveSeasonHandler = () => {
    if (seasonShipment)
      updateSeasonMutation({
        variables: {
          input: {
            id:cid,
            seasonProcess: {
              shipDate: seasonShipment.shipDate
                ? seasonShipment.shipDate
                : null,
              status: seasonShipment.shipDate
                ? SeasonProcessState.Scheduled
                : SeasonProcessState.NotProcessed,
            },
          },
        },
        refetchQueries: ["getSeason"],
      })
        .then(({ data }) => {
          if (data?.updateSeason) message.success("Season updated");
        })
        .catch((error) => message.error(error.message));
  };

  const processSeason = (customerId?: string) => {
    setProc("Please Wait")
    processSeasonMutation({
      variables: { seasonId: cid, customerId },
      refetchQueries: ["getSeasonCustomerList"],
    })
      .then(() => {
        setProc("Process All Members");
        message.success(`${customerId ? "Customer" : "Season"} processed`);
      })
      .catch((error) => {
        //todo: set larger timeout on appolo 
        setProc("Process All Members");
        
        if(error.message === "Notice: The selected season has already been processed or cannot be processed at this time."){
          message.error(error.message)
        }else{
          message.success('Your request has been successfully queued. We will process this season as soon as possible. Please check back in a few minutes.')
        }
      });
  };

  return (
    <Fragment>
      <Space
        direction="vertical"
        size={24}
        style={{
          width: "100%",
          marginBottom: breakpoints.md ? 0 : 72,
        }}
      >
        <Row align="middle" gutter={[24, 24]} justify="space-between">
          <Col>
            <PageTitle>{data?.season?.name}</PageTitle>
          </Col>
          {breakpoints.md && (
            <Fragment>
              <Col>
                <Space size={12}>
                  {currentStep === "clubMembers" && (
                    <Popconfirm
                      title="Are You sure you want to process this season now?"
                      onConfirm={() => processSeason()}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <TwfButton type-twf="wide" type="primary" size-twf="md" loading={processSeasonLoading}>
                        <Icon keyword="process" color="#ffffff" />
                        {proc}
                      </TwfButton>
                    </Popconfirm>
                  )}
                  {currentStep === "shipmentSetup" && (
                    <>
                      <TwfButton
                        type-twf="wide"
                        type="primary"
                        size-twf="md"
                        ghost
                        onClick={() => {
                          history(-1);
                        }}
                      >
                        Cancel
                      </TwfButton>
                      <TwfButton
                        type-twf="wide"
                        type="primary"
                        size-twf="md"
                        onClick={saveSeasonHandler}
                        loading={loading}
                      >
                        Save
                      </TwfButton>
                    </>
                  )}
                </Space>
              </Col>
            </Fragment>
          )}
        </Row>
        <Tabs onChange={callbackHandler}>
          <TabPane key="shipmentSetup" tab="Schedule Season">
            <SeasonShipmentSetup
              seasonShipment={seasonShipment}
              onSeasonShipmentChange={setSeasonShipment}
            />
          </TabPane>
          <TabPane key="clubMembers" tab="Season Members">
            <SeasonClubMembers onProcessCustomer={processSeason} />
          </TabPane>
          <TabPane key="summary" tab="Summary">
            <SeasonSummary />
          </TabPane>
        </Tabs>
      </Space>
      <SeasonReview visible={reviewVisible} setVisible={setReviewVisible} />
      {!breakpoints.md && (
        <SeasonProcessMobileFooter setReviewVisible={setReviewVisible} />
      )}
    </Fragment>
  );
};

export default SeasonSteps;
