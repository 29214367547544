import { useGetActiveAdministratorQuery } from "generated/graphql";

export default function useIsSuperAdmin() {
  const { data: activeAdmin } = useGetActiveAdministratorQuery();

  const isSuperAdmin = activeAdmin?.activeAdministrator?.user?.roles?.find(
    (r) => r.code === "__super_admin_role__"
  );

  return isSuperAdmin;
}
