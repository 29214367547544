/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useCallback, useReducer, useRef } from "react";
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  message,
  Popconfirm,
  Radio,
  Row,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { TwfCard } from "component/card";
import Container from "component/container";
import {
  GetProductVariantQuery,
  useAppellationsQuery,
  useCheckProductShipComplianceLazyQuery,
  useCheckProductShipComplianceQuery,
  useCreateAppellationMutation,
  useCreateVarietalMutation,
  useCreateWineLabelMutation,
  useCreateWineTypeMutation,
  useDeleteAppellationMutation,
  useDeleteProductFromComplianceMutation,
  useDeleteProductVariantMutation,
  useDeleteVarietalMutation,
  useDeleteWineLabelMutation,
  useDeleteWineTypeMutation,
  useVarietalsQuery,
  useWineLabelsQuery,
  useWineTypesQuery,
} from "generated/graphql";
import moment from "moment";
import { useEffect, useState } from "react";
import { wineCategories } from "./../../../enums/enums";
import TwfButton from "component/button";
import { useNavigate } from "react-router-dom";
import client from "api/graphql/connect";
import { DELETE_PRODUCT } from "graphql/mutations.graphql";
import DropdownAdder from "../components/dropdown-adder";

const { Item } = Form;
const { useBreakpoint } = Grid;

interface ViewProps {
  variant: GetProductVariantQuery;
  onChange: (state: any) => void;
  getProductResult: any;
  brandKey: string | undefined;
}

const ProductBasics: FC<ViewProps> = ({
  variant: { productVariant },
  onChange,
  getProductResult,
  brandKey,
}) => {
  const [form] = useForm();
  const breakpoints = useBreakpoint();
  const history = useNavigate();
  const initialState = {
    ...getProductResult?.product,
    customFields: { ...getProductResult?.product?.customFields },
  };
  delete initialState.customFields?.__typename;
  delete initialState?.__typename;

  let disabledSKU = true;

  if (typeof window !== 'undefined') {
    if(localStorage.getItem("used-channel")==="__default_channel__"){
      disabledSKU = false;
    }
  }

  const { data: channelWineLabels } = useWineLabelsQuery({
    variables: {
      options: {},
    },
  });

  const { data: channelWineTypes } = useWineTypesQuery({
    variables: {
      options: {},
    },
  });

  const { data: channelAppellations } = useAppellationsQuery({
    variables: {
      options: {},
    },
  });

  const { data: channelVarietals } = useVarietalsQuery({
    variables: {
      options: {},
    },
  });

  const [deleteProductFromComplianceMutation] =
    useDeleteProductFromComplianceMutation();
  const [deleteProductVariantMutation, { loading }] =
    useDeleteProductVariantMutation();

  const [checkProductShipCompliance, { loading: complianceLoading }] = 
  useCheckProductShipComplianceLazyQuery()

  const [labels, setLabels] = useState<any[]>([]);
  const [wineTypes, setWineTypes] = useState<any[]>([]);
  const [varietals, setVarietals] = useState<any[]>([]);
  const [appellations, setAppellations] = useState<any[]>([]);

  const [formKey, setFormKey] = useState(0);

  useEffect(() => {
    if (
      channelWineLabels?.wineLabels?.items &&
      channelWineLabels.wineLabels.items.length !== 0
    ) {
      const ourWineLabels = channelWineLabels.wineLabels.items;
      const wineLabelsDropdown = [] as any;
      ourWineLabels.forEach((label) => {
        const wineLabelObject = {
          label: label.label,
          value: label.label,
        } as any;
        wineLabelsDropdown.push(wineLabelObject);
      });
      setLabels(wineLabelsDropdown);
    }
  }, [channelWineLabels]);

  useEffect(() => {
    if (
      channelWineTypes?.wineTypes?.items &&
      channelWineTypes.wineTypes.items.length !== 0
    ) {
      const ourWineTypes = channelWineTypes.wineTypes.items;
      const wineTypesDropdown = [] as any;
      ourWineTypes.forEach((type) => {
        const wineTypeObject = {
          label: type.type,
          value: type.type,
        } as any;
        wineTypesDropdown.push(wineTypeObject);
      });
      setWineTypes(wineTypesDropdown);
    }
  }, [channelWineTypes]);

  useEffect(() => {
    if (
      channelAppellations?.appellations?.items &&
      channelAppellations.appellations.items.length !== 0
    ) {
      const ourWineAppellations = channelAppellations.appellations.items;
      const appellationsDropdown = [] as any;
      ourWineAppellations.forEach((appellation) => {
        const appellationObject = {
          label: appellation.appellation,
          value: appellation.appellation,
        } as any;
        appellationsDropdown.push(appellationObject);
      });
      setAppellations(appellationsDropdown);
    }
  }, [channelAppellations]);

  useEffect(() => {
    if (
      channelVarietals?.varietals?.items &&
      channelVarietals.varietals.items.length !== 0
    ) {
      const ourWineVarietals = channelVarietals.varietals.items;
      const varietalsDropdown = [] as any;
      ourWineVarietals.forEach((varietal) => {
        const varietalObject = {
          label: varietal.varietal,
          value: varietal.varietal,
        } as any;
        varietalsDropdown.push(varietalObject);
      });
      setVarietals(varietalsDropdown);
    }
  }, [channelVarietals]);

  const deleteProductHandler = () => {
    if (productVariant?.id) {
      /*deleteProductFromComplianceMutation({
          variables:{
            brandKey:brandKey as string,
            productKey:productVariant.sku
          }
        }).then(()=>{
          deletevariant(productVariant);
        }).catch(()=>{
          message.error("No such product found in compliance or some error.");
        });*/
      deletevariant(productVariant);
    }
  };

  const finallyDeleteProductHandler = useCallback((id: string) => {
    client
      .mutate({
        mutation: DELETE_PRODUCT,
        variables: { id },
        refetchQueries: ["GetProductList"],
      })
      .then(() => {
        message.success("Product successfully deleted");
        history("/products");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deletevariant = (productVariant: any) => {
    deleteProductVariantMutation({
      variables: { id: productVariant.id },
    })
      .then(({ data }) => {
        if (data?.deleteProductVariant) {
          finallyDeleteProductHandler(productVariant.productId);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const checkBoxItems = [
    {
      label: "Web",
      value: "1",
    },
    {
      label: "Club",
      value: "2",
    },
    {
      label: "Trade",
      value: "3",
    },
  ] as any;

  useEffect(() => {
    if (getProductResult?.product?.__typename === "Product") {
      setSelectedVarietal(getProductResult.product.customFields.varietal);
      setSelectedAppellation(getProductResult.product.customFields.appellation);
      setSelectedWineType(getProductResult.product.customFields.wineType);
      setSelectedWineLabel(getProductResult.product.customFields.label);
      if (productVariant?.customFields?.isOutOfStock !== undefined) {
        setOutOfStockBox(productVariant.customFields.isOutOfStock);
      }
      if(productVariant?.customFields?.shipComplianceVerified !== undefined) {
        setShipComplianceVerified(productVariant.customFields.shipComplianceVerified);
      }
      
      form.setFieldsValue({
        ...getProductResult.product,
        ...getProductResult.product.customFields,
        saleChannel:  getProductResult.product.customFields.saleChannel,
        bottlingDate: getProductResult.product.customFields?.bottlingDate
          ? moment(getProductResult.product.customFields?.bottlingDate)
          : "",
        harvestDate: getProductResult.product.customFields?.harvestDate
          ? moment(getProductResult.product.customFields?.harvestDate)
          : "",
        outOfStockMsg: productVariant?.customFields?.outOfStockMsg,
      });
      //setAssets(getProductResult?.product?.assets);
    }
  }, [getProductResult, form, productVariant]);

  const reducer = (state: any, action: any) => {
    return {
      ...state,
      [action.name]: action.value,
    };
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    onChange(state);
    // eslint-disable-next-line
  }, [state]);

  const [createVarietalMutation] = useCreateVarietalMutation();
  const [createAppellationMutation] = useCreateAppellationMutation();
  const [createWineTypeMutation] = useCreateWineTypeMutation();
  const [createWineLabelMutation] = useCreateWineLabelMutation();

  const [deleteVarietalMutation] = useDeleteVarietalMutation();
  const [deleteAppellationMutation] = useDeleteAppellationMutation();
  const [deleteWineTypeMutation] = useDeleteWineTypeMutation();
  const [deleteWineLabelMutation] = useDeleteWineLabelMutation();

  const [selectedVarietal, setSelectedVarietal] = useState(null);
  const [selectedAppellation, setSelectedAppellation] = useState(null);
  const [selectedWineType, setSelectedWineType] = useState(null);
  const [selectedWineLabel, setSelectedWineLabel] = useState(null);

  const [outofStockMsg, setOutofStockMsg] = useState(null);

  const [outOfStockBox, setOutOfStockBox] = useState(false);

  const [shipComplianceVerified, setShipComplianceVerified] = useState(false);

  const [name, setName] = useState("");
  const inputRef = useRef(null);

  const onNameChange = (event: any) => {
    setName(event.target.value);
  };

  const addItem = (e: any, type: string) => {
    e.preventDefault();
    if (name.length !== 0) {
      switch (type) {
        case "varietal":
          createVarietalMutation({
            variables: {
              input: {
                varietal: name,
              },
            },
            refetchQueries: ["Varietals"],
          })
            .then(() => {
              setName("");
            })
            .catch((error) => {
              message.error(error.message);
            });
          break;
        case "appellation":
          createAppellationMutation({
            variables: {
              input: {
                appellation: name,
              },
            },
            refetchQueries: ["Appellations"],
          })
            .then(() => {
              setName("");
            })
            .catch((error) => {
              message.error(error.message);
            });
          break;
        case "wineType":
          createWineTypeMutation({
            variables: {
              input: {
                type: name,
              },
            },
            refetchQueries: ["WineTypes"],
          })
            .then(() => {
              setName("");
            })
            .catch((error) => {
              message.error(error.message);
            });
          break;
        case "wineLabel":
          createWineLabelMutation({
            variables: {
              input: {
                label: name,
              },
            },
            refetchQueries: ["WineLabels"],
          })
            .then(() => {
              setName("");
            })
            .catch((error) => {
              message.error(error.message);
            });
          break;
      }
    }
  };

  const deleteItem = (value: string, type: string) => {
    switch (type) {
      case "varietal":
        const varietalId = channelVarietals!.varietals.items.filter(
          (varietal) => varietal.varietal === value
        )![0].id;
        deleteVarietalMutation({
          variables: {
            id: varietalId,
          },
          refetchQueries: ["Varietals"],
        })
          .then(() => {
            message.success("Varietal successfully deleted");
            setSelectedVarietal(null);
            form.resetFields(["varietal"]);
          })
          .catch((error) => {
            message.error(error.message);
          });
        break;
      case "appellation":
        const appellationId = channelAppellations!.appellations.items.filter(
          (appellation) => appellation.appellation === value
        )![0].id;
        deleteAppellationMutation({
          variables: {
            id: appellationId,
          },
          refetchQueries: ["Appellations"],
        })
          .then(() => {
            message.success("Appellation successfully deleted");
            setSelectedAppellation(null);
            form.resetFields(["appellation"]);
          })
          .catch((error) => {
            message.error(error.message);
          });
        break;
      case "wineType":
        const wineTypeId = channelWineTypes!.wineTypes.items.filter(
          (wineType) => wineType.type === value
        )![0].id;
        deleteWineTypeMutation({
          variables: {
            id: wineTypeId,
          },
          refetchQueries: ["WineTypes"],
        })
          .then(() => {
            message.success("Winetype successfully deleted");
            setSelectedWineType(null);
            form.resetFields(["appellation"]);
          })
          .catch((error) => {
            message.error(error.message);
          });
        break;
      case "wineLabel":
        const wineLabelId = channelWineLabels!.wineLabels.items.filter(
          (wineLabel) => wineLabel.label === value
        )![0].id;
        deleteWineLabelMutation({
          variables: {
            id: wineLabelId,
          },
          refetchQueries: ["WineLabels"],
        })
          .then(() => {
            message.success("Winetype successfully deleted");
            setSelectedWineLabel(null);
            form.resetFields(["appellation"]);
          })
          .catch((error) => {
            message.error(error.message);
          });
        break;
    }
  };

  const checkComplianceStatus = () => {
    if(productVariant?.sku){
      checkProductShipCompliance({
        variables:{
          productKey: productVariant.sku
        },
        fetchPolicy:"network-only",
      },
      ).then((data)=>{
        if(data?.data?.checkProductShipCompliance){
          setShipComplianceVerified(data.data.checkProductShipCompliance);
          message.success("The product seems to be in the ship compliance");
        }else{
          setShipComplianceVerified(false);
          message.error("The product doesn't seem in the ship compliance");
        }
        setFormKey(Math.floor(Math.random()*1000000));
      }).catch((error)=>{
        message.error(error.message);
        setShipComplianceVerified(false);
        setFormKey(Math.floor(Math.random()*1000000));
      })
    }else{
      message.error("SKU doesn't exists for the product. Check properly.");
    }
  }

  return (
    <Container key={formKey}>
      <TwfCard style={{ padding: breakpoints.md ? "49px 29px" : "20px" }}>
        <Form form={form} layout="vertical">
          <Row gutter={[12, 12]}>
            <Col lg={{ span: 12 }} span={24}>
            <TwfButton
                  size-twf="md"
                  style={{ float: "left", marginBottom:"5%" }}
                  color-twf="primary"
                  type-twf="wide"
                  loading={complianceLoading}
                  hidden={disabledSKU}
                  onClick={checkComplianceStatus}
                >
                  Verify Ship Compliance
                </TwfButton>
            </Col>
            <Col lg={{ span: 12 }} span={24}>
              <Popconfirm
                title="Are you sure to delete this product?"
                onConfirm={deleteProductHandler}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <TwfButton
                  size-twf="md"
                  style={{ float: "right" }}
                  color-twf="secondary"
                  type-twf="wide"
                  loading={loading}
                  hidden={disabledSKU}
                >
                  Delete Product
                </TwfButton>
              </Popconfirm>
            </Col>
            <Col lg={{ span: 12 }} span={24}>
              <Item label="Product Type" name="productType">
                <Radio.Group
                  onChange={(e) => {
                    dispatch({ name: "productType", value: e.target.value });
                  }}
                >
                  <Radio value={1}>Wine</Radio>
                  {/* <Radio value={2}>Bundle</Radio> */}
                </Radio.Group>
              </Item>
            </Col>
            <Col>
              <Item label="Sale Channel edit" name="saleChannel">
                <Checkbox.Group
                  options={checkBoxItems}
                  onChange={(e) => {
                    dispatch({ name: "saleChannel", value: e });
                  }}
                />
              </Item>
            </Col>
            <Col lg={{ span: 12 }} span={24}>
              <Item
                label="Product Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter product name!",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    dispatch({ name: "name", value: e.target.value });
                  }}
                />
              </Item>
            </Col>
            <Col lg={{ span: 12 }} span={24}>
              <Item label="Product URL" name="productUrl">
                <Input
                  onChange={(e) => {
                    dispatch({ name: "productUrl", value: e.target.value });
                  }}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Bottling Date" name="bottlingDate">
                <DatePicker
                  onChange={(e) => {
                    dispatch({ name: "bottlingDate", value: e });
                  }}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Harvest Date" name="harvestDate">
                <DatePicker
                  onChange={(e) => {
                    dispatch({ name: "harvestDate", value: e });
                  }}
                />
              </Item>
            </Col>
            <Col span={24}>
              <Row>
                <Col lg={{ span: 12 }} span={24}>
                  <Col>
                    <Item label="Wine Category" name="wineCategory">
                      <Radio.Group
                        options={wineCategories}
                        onChange={(e) => {
                          dispatch({
                            name: "wineCategory",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Item>
                  </Col>
                  <Col>
                    <Item label="Status" name="status">
                      <Radio.Group
                        onChange={(e) => {
                          dispatch({ name: "status", value: e.target.value });
                        }}
                      >
                        <Radio value={1}>Active</Radio>
                        <Radio value={0}>Inactive</Radio>
                        <Radio value={2}>Archived</Radio>
                      </Radio.Group>
                    </Item>
                  </Col>
                </Col>
                <Col lg={{ span: 12 }} span={24}>
                  <Item
                    label="Custom Out of Stock Message"
                    name="outOfStockMsg"
                  >
                    <Input.TextArea
                      style={{
                        height: 80,
                        width: "100%",
                      }}
                      onChange={(e) => {
                        dispatch({
                          name: "outOfStockMsg",
                          value: e.target.value,
                        });
                      }}
                    />
                  </Item>

                  <Item name="isOutOfStock" label="">
                    <Checkbox
                      checked={outOfStockBox}
                      onChange={(e) => {
                        setOutOfStockBox(e.target.checked);
                        dispatch({
                          name: "isOutOfStock",
                          value: e.target.checked,
                        });
                      }}
                    >
                      {" "}
                      Enable out of stock status
                    </Checkbox>
                  </Item>
                  {
                    !disabledSKU && (
                    <Item name="shipComplianceVerified" label="">
                      <Checkbox
                      checked={shipComplianceVerified}
                      onChange={(e) => {
                        setShipComplianceVerified(e.target.checked);
                        dispatch({
                          name: "shipComplianceVerified",
                          value: e.target.checked,
                        });
                      }}
                      >
                      {" "}
                      Is Ship Compliance Verified?
                      </Checkbox>
                      </Item>
                    )
                  }

                </Col>
              </Row>
            </Col>
            <Col sm={12} span={24}>
              <Item label="Varietal" name="varietal">
                <DropdownAdder
                  key={0}
                  options={varietals}
                  type="varietal"
                  deleteItem={deleteItem}
                  selected={selectedVarietal}
                  setSelected={setSelectedVarietal}
                  addItem={addItem}
                  name={name}
                  setName={setName}
                  onNameChange={onNameChange}
                  inputRef={inputRef}
                  dispatch={dispatch}
                />
              </Item>
            </Col>
            <Col sm={12} span={24}>
              <Item label="Appellation" name="appellation">
                <DropdownAdder
                  key={1}
                  options={appellations}
                  type="appellation"
                  deleteItem={deleteItem}
                  selected={selectedAppellation}
                  setSelected={setSelectedAppellation}
                  addItem={addItem}
                  name={name}
                  setName={setName}
                  onNameChange={onNameChange}
                  inputRef={inputRef}
                  dispatch={dispatch}
                />
              </Item>
            </Col>
            <Col sm={12} span={24}>
              <Item label="Wine Type" name="wineType">
                <DropdownAdder
                  key={2}
                  options={wineTypes}
                  type="wineType"
                  deleteItem={deleteItem}
                  selected={selectedWineType}
                  setSelected={setSelectedWineType}
                  addItem={addItem}
                  name={name}
                  setName={setName}
                  onNameChange={onNameChange}
                  inputRef={inputRef}
                  dispatch={dispatch}
                />
              </Item>
            </Col>
            <Col sm={12} span={24}>
              <Item label="Label" name="label">
                <DropdownAdder
                  key={3}
                  options={labels}
                  type="wineLabel"
                  deleteItem={deleteItem}
                  selected={selectedWineLabel}
                  setSelected={setSelectedWineLabel}
                  addItem={addItem}
                  name={name}
                  setName={setName}
                  onNameChange={onNameChange}
                  inputRef={inputRef}
                  dispatch={dispatch}
                />
              </Item>
            </Col>
          </Row>
          {/* <MarginBox mt={32}>
                    <Row
                      justify={breakpoints.md ? "end" : "center"}
                      gutter={breakpoints.md ? 24 : 12}
                    >
                      <Col>
                        <TwfButton
                          type="primary"
                          size-twf="md"
                          color-twf="primary"
                          type-twf="wide"
                          htmlType="submit"
                          loading={loading}
                        >
                          Submit
                        </TwfButton>
                      </Col>
                    </Row>
                        </MarginBox>*/}
        </Form>
      </TwfCard>
    </Container>
  );
};

export default ProductBasics;
