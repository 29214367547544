import { Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import { H3 } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import TwfModal from "../../../component/modal";
import ModalTitle from "../../../component/modal/title";
const { Item } = Form;
const { Option } = Select;

interface ComponentProps {
  visible?: boolean;
  setVisible: (value: boolean) => void;
}

const AddDiscounts: React.FC<ComponentProps> = ({ visible, setVisible }) => {
  const breakpoints = useBreakpoint();
  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closeIcon={<Icon keyword="close" />}
      width={662}
      footer={false}
      centered
    >
      <ModalTitle>
        <H3>Add New Discount</H3>
      </ModalTitle>
      <MarginBox>
        <Form layout="vertical">
          <Row gutter={24}>
            <Col span={24}>
              <Item label="Type">
                <Select
                  placeholder="Select"
                  suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
                >
                  <Option value="type1">Type 1</Option>
                  <Option value="type2">Type 2</Option>
                </Select>
              </Item>
            </Col>
            <Col span={24}>
              <Item label="Name">
                <Input />
              </Item>
            </Col>
            <Col span={24}>
              <Item label="Code">
                <Input />
              </Item>
            </Col>
            <Col span={24}>
              <Item label="Available To">
                <Select
                  placeholder="Select"
                  suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
                >
                  <Option value="type1">Value 1</Option>
                  <Option value="type2">Value 2</Option>
                </Select>
              </Item>
            </Col>
            <Col span={24}>
              <Item label="Product Selection">
                <Select
                  placeholder="Select"
                  suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
                >
                  <Option value="type1">Product 1</Option>
                  <Option value="type2">Product 2</Option>
                </Select>
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Start Date">
                <DatePicker />
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Expiry Date">
                <DatePicker />
              </Item>
            </Col>
            <Col span={24}>
              <Item label="Limited Use">
                <Select
                  placeholder="Select"
                  suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
                >
                  <Option value="type1">Value 1</Option>
                  <Option value="type2">Value 2</Option>
                </Select>
              </Item>
            </Col>
            <Col span={24}>
              <Item>
                <Checkbox>Allow Show Coupon on List in POS</Checkbox>
              </Item>
            </Col>
            <Col span={24}>
              <Item label="POS Coupon Name">
                <Input />
              </Item>
            </Col>
          </Row>
          <MarginBox mt={32}>
            <Row gutter={breakpoints.md ? [24, 24] : [12, 12]} justify="end">
              <Col>
                <TwfButton
                  color-twf="gray-line"
                  size-twf="md"
                  type-twf="wide"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Cancel
                </TwfButton>
              </Col>
              <Col>
                <TwfButton
                  type="primary"
                  color-twf="primary"
                  size-twf="md"
                  type-twf="wide"
                >
                  Create
                </TwfButton>
              </Col>
            </Row>
          </MarginBox>
        </Form>
      </MarginBox>
    </TwfModal>
  );
};

export default AddDiscounts;
