import React, { useState } from "react";
import { Select } from "antd";
import styled from "styled-components";
import { useGetStateListQuery } from "generated/graphql";

interface Props {
  onStateIdChange: (keyword: string) => void;
}

const StateSearch: React.FC<Props> = ({ onStateIdChange }) => {
  const { data: states } = useGetStateListQuery();

  const [selectedStateId, setSelectedStateId] = useState("0");

  const stateChangeHandler = (id: string) => {
    onStateIdChange(id);
    setSelectedStateId(id);
  };

  return (
    <InputArea>
      <Select
        showSearch
        bordered={false}
        value={selectedStateId}
        options={[
          { label: "All States", value: "0" },
          ...(states?.states.map((state) => ({
            label: state.name,
            value: state.id,
          })) || []),
        ]}
        style={{ width: 200, border: "none" }}
        placeholder="State"
        filterOption={(input, option) =>
          (option!.label as string)
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        onChange={stateChangeHandler}
      />
    </InputArea>
  );
};

const InputArea = styled.div`
  background-color: #fff;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  grid-gap: 12px;
  width: 100%;
  .ant-input {
    border: none !important;
    box-shadow: none !important;
  }
`;

export default StateSearch;
