import {
  ADD_TO_CART,
  GET_ORDERS,
  GET_PRODUCTS,
  SET_ORDER,
} from "../action/ecommerce.action";

const INITIAL_STATE = {
  currency: {
    name: "EUR",
    symbol: "€",
  },
};

interface ActionType {
  type?: string;
  data?: any;
}

const EcommerceReducer = (state: any = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        ...action.data,
      };
    case GET_ORDERS:
      return {
        ...state,
        ...action.data,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        ...action.data,
      };
    case SET_ORDER:
      return {
        ...state,
        order: action.data,
      };
    default:
      return state;
  }
};

export default EcommerceReducer;

export type RootState = ReturnType<typeof EcommerceReducer>;
