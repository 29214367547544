import Steps from "antd/lib/steps";
import styled from "styled-components";

const AuthSteps = styled(Steps)`
  &.ant-steps {
    .ant-steps-item {
      .ant-steps-item-content {
        padding-top: 6px;
        .ant-steps-item-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          color: #ffffff;
        }
      }

      .ant-steps-item-container {
        height: 70px;
      }
      .ant-steps-item-icon {
        width: 24px;
        height: 24px;

        .ant-steps-icon-dot {
          border: 2px solid #fff;
          left: -8px;
          background-color: transparent;
        }
      }
      .ant-steps-item-tail {
        :after {
          background-color: #ffffff;
          width: 2px;
          opacity: 0.5;
          margin-left: 12px;
          margin-top: 9px;
        }
      }
      &.ant-steps-item-active,
      &.ant-steps-item-finish {
        .ant-steps-item-icon {
          .ant-steps-icon-dot {
            width: 24px;
            height: 24px;
            left: -8px;
            border: 2px solid #fff;
            background-color: #1890ff;
          }
        }
        .ant-steps-item-tail {
          :after {
            opacity: 1;
          }
        }
      }
    }
  }
`;

export default AuthSteps;
