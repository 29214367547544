import React from "react";
import { BlockTitle } from "../../../component/header";
import TableItem from "../../../component/table/item";
import { Divider } from "antd";

interface Props {
  compdata:any;
  index:number;
}

const ComplianceBlockView: React.FC<Props> = ({
  compdata,
  index
}) => {

  //console.log(compdata);

  return (
        <div key={index+"d"}>
        <BlockTitle key={index+"b"}>{compdata.complianceStatus}</BlockTitle>
        <TableItem  key={index+"t"}>
          {compdata.complianceDescription}
          <br/><br/>
          License Relationship: {compdata.licenseRelationship}
          <br/><br/>
          Rule Type: {compdata.ruleType}
          <br/><br/>
          Rule Description: {compdata.ruleDescription}
          <br/><br/>
        </TableItem>
        <Divider/>
        </div>
  );
};

export default ComplianceBlockView;
