import React from "react";
import TwfModal from "../../../component/modal";
import ModalTitle from "../../../component/modal/title";
import { H3 } from "../../../component/header";
import Icon from "../../../assets/icons";
import { Col, DatePicker, Form, Row, Select } from "antd";
import TwfButton from "../../../component/button";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useOrderInvoiceReportLazyQuery } from "generated/graphql";
//import { useState } from "react";
import { message } from "antd/es";

const { Item } = Form;
const { Option } = Select;

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const ExportSales: React.FC<ComponentProps> = ({ visible, setVisible }) => {
  //const [loading,setLoading] =  useState<boolean>(false)
  const [generateOrderInvoiceReport, { loading }] =
    useOrderInvoiceReportLazyQuery();

  const onSubmitHandler = (value: any) => {
    try {
      const { startDate, endDate, status } = value;

      generateOrderInvoiceReport({
        variables: {
          input: {
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            status: status && status !== "All" ? status : undefined,
          },
        },
      })
        .then((data) => {
          if (data.data?.orderInvoiceReport) {
            let reportDownloadURL = `${window.location.protocol}//${data.data.orderInvoiceReport}`;
            if (reportDownloadURL.includes("https://http://")) {
              reportDownloadURL = reportDownloadURL.replace(
                "https://http://",
                "https://"
              ); // Remove the duplicate "http://" if present
            }
            window.location.href = reportDownloadURL;
          } else {
            message.error(data.error?.message || "Failed to generate report.");
          }
        })
        .catch((error) => {
          message.error(error.message || "An error occurred.");
        });
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const breakpoints = useBreakpoint();
  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      centered
      footer={false}
      closeIcon={<Icon keyword="close" />}
      width={662}
    >
      <ModalTitle>
        <H3>Export Sales Report</H3>
      </ModalTitle>
      <Form layout="vertical" onFinish={onSubmitHandler}>
        <div style={{ minHeight: 100 }}>
          <Row gutter={24}>
            <Col span={12}>
              <Item
                rules={[
                  { required: true, message: "Report Start Date is required" },
                ]}
                name="startDate"
                label="Start Date"
              >
                <DatePicker />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                rules={[
                  { required: true, message: "Report End Date is required" },
                ]}
                name="endDate"
                label="End Date"
              >
                <DatePicker />
              </Item>
            </Col>
            <Col span={24}>
              <Item name="status" label="Order Status">
                <Select
                defaultValue={'All'}
                  placeholder="Select"
                  suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
                >
                  <Option value="All">All</Option>
                  <Option value="Delivered">Delivered</Option>
                  <Option value="Shipped">Shipped</Option>
                  <Option value="ComplianceSuccess">
                    Compliance Succeeded
                  </Option>
                  <Option value="PaymentSettled">Payment Settled</Option>
                </Select>
              </Item>
            </Col>
          </Row>
        </div>
        <br />
        <Row gutter={breakpoints.md ? [24, 24] : [12, 12]} justify="end">
          <Col>
            <TwfButton
              color-twf="gray-line"
              size-twf="md"
              type-twf="wide"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </TwfButton>
          </Col>
          <Col>
            <TwfButton
              type="primary"
              color-twf="primary"
              size-twf="md"
              type-twf="wide"
              htmlType="submit"
              loading={loading}
            >
              Export
            </TwfButton>
          </Col>
        </Row>
      </Form>
    </TwfModal>
  );
};

export default ExportSales;
