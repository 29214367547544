import React from "react";
import AppBar from "../../../../component/app-bar";
import { Col, Row } from "antd";
import TwfButton from "../../../../component/button";
import { useNavigate } from "react-router";

const CreateMobileFooter: React.FC = () => {
  const history = useNavigate();
  return (
    <AppBar>
      <Row justify="space-around" align="middle">
        <Col>
          <TwfButton
            size-twf="lg"
            color-twf="gray-line"
            style={{ width: 100 }}
            onClick={() => {
              history(-1);
            }}
          >
            Cancel
          </TwfButton>
        </Col>
        <Col>
          <TwfButton
            size-twf="lg"
            type="primary"
            color-twf="primary"
            style={{ width: 100 }}
          >
            View
          </TwfButton>
        </Col>
      </Row>
    </AppBar>
  );
};

export default CreateMobileFooter;
