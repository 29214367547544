import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const CloseIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5046 4.31282C19.0646 3.89639 18.3761 3.89564 17.9352 4.31112L11.9636 9.93842L5.99207 4.31112C5.55118 3.89564 4.86268 3.89639 4.42268 4.31282L4.35726 4.37475C3.8802 4.82627 3.88103 5.58604 4.35907 6.03653L10.2317 11.5706L4.35907 17.1046C3.88103 17.5551 3.8802 18.3148 4.35726 18.7664L4.42269 18.8283C4.86268 19.2447 5.55118 19.2455 5.99208 18.83L11.9636 13.2027L17.9352 18.83C18.3761 19.2455 19.0646 19.2447 19.5046 18.8283L19.57 18.7664C20.0471 18.3148 20.0463 17.5551 19.5682 17.1046L13.6956 11.5706L19.5682 6.03652C20.0463 5.58604 20.0471 4.82627 19.57 4.37475L19.5046 4.31282Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export default CloseIcon;
