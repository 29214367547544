import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const PlusIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 11.1429H11.1429V18H8.85714V11.1429H2V8.85714H8.85714V2H11.1429V8.85714H18V11.1429Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export default PlusIcon;
