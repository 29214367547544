import {
  // Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Table,
} from "antd";
import { 
  bottleSizes, 
  //variantOptions, 
  vintageValues 
} from "enums/enums";
import { useDeleteProductVariantMutation } from "generated/graphql";
import { FC, useEffect, useState } from "react";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
// import { BlockTitle } from "../../../component/header";
import { MarginBox } from "../../../component/margin";

const { Item } = Form;

export interface OptionGroup {
  id?: string;
  name: string;
  options: ProductOption[];
  disabled?: boolean;
}

export interface Variant {
  id?: string;
  name: string;
  options: ProductOption[];
  sku: string;
  price: number;
  stock: number;
  selected?: boolean;
  isNew?: boolean;
}

export interface ProductOption {
  id?: string;
  name: string;
  disabled?: boolean;
}

type variantField = "sku" | "price" | "stock";

interface Props {
  optionGroups: OptionGroup[];
  setOptionGroups: (optionGroups: OptionGroup[]) => void;
  variants: Variant[];
  setVariants: (variants: Variant[]) => void;
  manage: boolean;
}

const Variants: FC<Props> = ({
  optionGroups,
  setOptionGroups,
  variants,
  setVariants,
  manage,
}) => {
  const [changed, setChanged] = useState<boolean>(false);

  const [deleteProductVariantMutation] = useDeleteProductVariantMutation();

  useEffect(() => {
    if (!manage) {
      optionGroups[0].name = "Bottle Size";
      //addOptionHandler();
      setOptionGroups([...optionGroups, { name: "Vintage", options: [] }]);
      //optionGroups.push({ name: 'Vintage', options: [] });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manage]);

  useEffect(() => {
    updateVariantTable(optionGroups);
    // eslint-disable-next-line
  }, [optionGroups]);

  /*const addOptionHandler = () => {
    setOptionGroups([...optionGroups, { name: "", options: [] }]);
  };

  const removeOptionHandler = (index: number) => {
    const list = [...optionGroups];
    list.splice(index, 1);
    setOptionGroups(list);
  };

  const optionChangeHandler = (value: string, index: number) => {
    if (!optionGroups.some((optionGroup) => optionGroup.name === value)) {
      const list = [...optionGroups];
      list[index]["name"] = value;
      setOptionGroups(list);
    } else {
      message.error(value + " is already added. You cannot repeat option.");
    }
  };*/

  const optionValuesSelectHandler = (value: string, index: number) => {
    const list = [...optionGroups];
    setChanged(true);
    list[index]["options"] = [{ name: value }];
    setOptionGroups(list);
  };

  const optionValuesDeselectHandler = (value: string, index: number) => {
    const list = [...optionGroups];
    setChanged(true);
    list[index]["options"] = [
      ...list[index]["options"].filter((option) => option.name !== value),
    ];
    setOptionGroups(list);
  };

  function updateVariantTable(optionGroups: OptionGroup[]) {
    const options = optionGroups.reduce((acc: ProductOption[], item) => {
      acc.push(
        ...item.options.map((option) => ({
          id: option?.id,
          name: option?.name,
        }))
      );
      return acc;
    }, []);
    const productOptions = optionGroups.map((optionGroup) =>
      optionGroup.options.map((option) => option.name)
    );
    if (productOptions.length > 0) {
      const variantTableRows = cartesian(...productOptions);
      const variantList: Variant[] = [];
      variantTableRows.forEach((row: string[]) => {
        const existingVariant = variants.find(
          (variant) =>
            variant.name === (Array.isArray(row) ? row.join(" ") : row)
        );
        if (existingVariant) variantList.push(existingVariant);
        else
          variantList.push({
            name: Array.isArray(row) ? row.join(" ") : row,
            options: Array.isArray(row)
              ? row.map((item) => ({
                  id: options.find((option) => option.name === item)?.id,
                  name: item,
                }))
              : [
                  {
                    id: options.find((option) => option.name === row)?.id,
                    name: row,
                  },
                ],
            sku: "",
            price: 0,
            stock: 0,
            selected: true,
            isNew: changed,
          });
      });
      setVariants(variantList.filter(hideUnused));
    }
  }

  const hideUnused = (record: Variant) => {
    if (record.selected) {
      if (record.isNew && record.selected) {
        return record;
      }
    } else {
      return record;
    }
  };

  function setProperty<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
    obj[key] = value;
  }

  const variantFieldChangeHandler = (
    field: variantField,
    value: any,
    index: any
  ) => {
    const list = [...variants];
    setProperty(list[index], field, value);
    setVariants(list);
  };

  const deleteProductVariantHandler = (id: string) => {
    deleteProductVariantMutation({
      variables: { id },
      refetchQueries: ["GetProductVariantOptions"],
    }).then(() => {
      message.success("Product variant deleted");
      updateVariantTable(optionGroups);
    });
  };

  const cartesian = (...a: any) =>
    a.reduce((a: any, b: any) =>
      a.flatMap((d: any) => b.map((e: any) => [d, e].flat()))
    );

  const columns: any = [
    /*
    {
      title: "Create",
      render: (record: any) =>
        !record.id ? (
          <Checkbox
            checked={record.selected}
            onChange={(e) => variantSelectHandler(e, record.key)}
          />
        ) : (
          ""
        ),
    },
    {
      title: "Variant",
      dataIndex: "name",
      render: (name: any) => (Array.isArray(name) ? name.join(" ") : name),
    },
    */
    {
      title: "SKU",
      render: (record: any) => {
        return !record.id ? (
          <Input
            name="sku"
            value={record.sku}
            onChange={(e) =>
              variantFieldChangeHandler("sku", e.target.value, record.key)
            }
          />
        ) : (
          record.sku
        );
      },
    },
    {
      title: "Price",
      render: (record: any) => {
        return !record.id ? (
          <InputNumber
            style={{ width: "100%" }}
            name="price"
            value={record.price}
            onChange={(value) =>
              variantFieldChangeHandler("price", value, record.key)
            }
          />
        ) : (
          (record.price / 100).toFixed(2)
        );
      },
    },
    {
      title: "Stock",
      render: (record: any) => {
        return !record.id ? (
          <InputNumber
            style={{ width: "100%" }}
            name="stock"
            value={record.stock}
            min={1}
            onChange={(value) =>
              variantFieldChangeHandler("stock", value, record.key)
            }
          />
        ) : (
          record.stock
        );
      },
    },
    {
      title: "",
      render: (record: any) => {
        return (
          record.id && (
            <TwfButton
              shape="circle"
              type-twf="icon"
              onClick={() => deleteProductVariantHandler(record.id)}
            >
              <Icon keyword="delete" color="#FF4C61" />
            </TwfButton>
          )
        );
      },
    },
  ];

  return (
    <div>
      <MarginBox mt={20}>
        <Row>
          <Col span={24} style={{ maxWidth: "800px" }}>
            {/* <BlockTitle>Product Variants</BlockTitle> */}

            {optionGroups.map((option, i) => (
              <Row gutter={[12, 12]} align="middle" key={i}>
                {/*<Col lg={6} md={12} span={24}>
                  <Item label="Option">
                    <Select
                      suffixIcon={
                        null
                      }
                      
                      options={variantOptions}
                      value={option.name}
                      onChange={(e) => optionChangeHandler(e, i)}
                      disabled={
                        true
                      }
                    />
                  </Item>
                  </Col>*/}
                <Col lg={18} md={12} span={24}>
                  <Item label={option.name}
                  rules={[
                    {
                      required: true,
                      message: `Please enter ${option.name}`,
                    },
                  ]}>
                    <Select
                      // mode="tags"
                      value={[...option.options.map((option) => option.name)]}
                      options={[
                        ...option.options.map((option) => ({
                          label: option.name,
                          value: option.name,
                          disabled: option.disabled,
                        })),
                        ...( option.name==="Bottle Size"?
                          bottleSizes: vintageValues
                        ).filter(
                          (data: any) =>
                            !option.options
                              .map((op) => op.name)
                              .includes(data.value)
                        ),
                      ]}
                      onSelect={(v: string) => optionValuesSelectHandler(v, i)}
                      onDeselect={(v: string) =>
                        optionValuesDeselectHandler(v, i)
                      }
                    ></Select>
                  </Item>
                </Col>
                {/*
                  <Col span={1}>
                    {optionGroups.length >= 1 && (
                      <TwfButton
                        shape="circle"
                        type-twf="icon"
                        hidden={i === 0}
                        onClick={() => removeOptionHandler(i)}
                      >
                        <Icon keyword="delete" color="#FF4C61" />
                      </TwfButton>
                    )}
                  </Col>
                    */}
              </Row>
            ))}

            
            {/*<Row>
              <MarginBox mb={15}>
                <TwfButton
                  type="primary"
                  size-twf="sm"
                  color-twf="primary"
                  type-twf="wide"
                  onClick={addOptionHandler}
                  hidden={manage}
                >
                  Add option
                </TwfButton>
              </MarginBox>
              </Row>*/}
              
          </Col>
          
          <Col span={24}>
            <Table
              locale={{ emptyText: 'Please select both bottle size and vintage' }}
              columns={columns}
              dataSource={variants.map((obj, i) => ({ ...obj, key: i }))}
              pagination={false}
              style={{ maxWidth: "max-content", marginTop:"5%" }}
              scroll={{ x: "max-content" }}
            ></Table>
          </Col>
          
        </Row>
      </MarginBox>
    </div>
  );
};

export default Variants;
