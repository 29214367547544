import React, { useState, useEffect } from "react";
import { Col, message, Row, Tabs } from "antd";
import TwfButton from "../../../component/button";
import { MarginBox } from "../../../component/margin";
import ClubSingleGeneral from "./general";
import ClubSingleSeasons from "./seasons";
import Icon from "../../../assets/icons";
import ClubAddSeason from "./addSeason";
import ClubSingleStats from "./stats";
import BackButton from "../../../component/button/back";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { PageTitle } from "../../../component/header";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ClubFragment,
  useGetClubQuery,
  useUpdateClubMutation,
} from "generated/graphql";
import { useParams } from "react-router-dom";
import ClubSingleMembers from "./members";
import ClubAddMember from "./addMember";

const { TabPane } = Tabs;


const ClubSingle: React.FC = () => {
  const history = useNavigate();
  const breakpoints = useBreakpoint();
  const { id } = useParams<{ id: string }>();
  const cid:string = id as string;
  const { data } = useGetClubQuery({
    variables: { id:cid },
  });
  const [updateClubMutation, { loading }] = useUpdateClubMutation();

  const [currentTab, setCurrentTab] = useState("general");
  const [createSeasonVisible, setCreateSeasonVisible] = useState(false);
  const [addMemberVisible, setAddMemberVisible] = useState(false);
  const [club, setClub] = useState<ClubFragment>();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (data?.club) setClub(data.club);
    if(searchParams?.get("tab")){
      setCurrentTab(searchParams.get("tab")!);
      searchParams.delete("tab");
      setSearchParams(searchParams);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const clubChangeHandler = (value: any) => {
    setClub({
      ...club,
      ...value,
      userChoice: value.userChoice === "1" ? true : false,
    });
  };

  const saveClubHandler = () => {
    if (club)
      updateClubMutation({
        variables: {
          input: {
            id:cid,
            name: club.name,
            description: club.description,
            order: club.order,
            //shippingMethodID: club.shippingMethod?.id,
            displayOnWeb: club.displayOnWeb,
            active: club.active,
            userChoice: club.userChoice,
            url: club.url,
            slug: club.slug,
            marketingUrl: club.marketingUrl,
            titleMetaTag: club.titleMetaTag,
            descriptionMetaTag: club.descriptionMetaTag,
          },
        },
        refetchQueries: ["GetClubList"],
      })
        .then(({ data }) => {
          if (data?.updateClub) message.success("Club updated");
        })
        .catch((error) => message.error(error.message));
  };

  const tabChangeHandler = (value: any) => {
    setCurrentTab(value);
  };

  return (
    <div>
      <Row
        gutter={[30, 15]}
        justify="space-between"
        style={{ marginBottom: breakpoints.md ? 30 : 30 }}
      >
        <Col>
          <Row gutter={[12, 12]} align="middle">
            <Col>
              <BackButton />
            </Col>
            <Col>
              <PageTitle>{club?.name}</PageTitle>
            </Col>
          </Row>
        </Col>
        <Col>
          {(currentTab === "general" || currentTab === "marketing") && (
            <Row gutter={[12, 12]}>
              <Col>
                <TwfButton
                  color-twf="gray-line"
                  size-twf="md"
                  type-twf="wide"
                  onClick={() => {
                    history(-1);
                  }}
                >
                  Cancel
                </TwfButton>
              </Col>
              <Col>
                <TwfButton
                  type="primary"
                  color-twf="primary"
                  size-twf="md"
                  type-twf="wide"
                  onClick={saveClubHandler}
                  loading={loading}
                >
                  Save
                </TwfButton>
              </Col>
            </Row>
          )}
          {currentTab === "members" && (
            <Row gutter={breakpoints.md ? [24, 24] : [12, 12]}>
              <Col>
                <TwfButton
                  type="primary"
                  color-twf="primary"
                  size-twf="md"
                  type-twf="wide"
                  onClick={() => {
                    setAddMemberVisible(true);
                  }}
                >
                  {breakpoints.md && <Icon keyword="plus" color="#ffffff" />}
                  Add New Member
                </TwfButton>
              </Col>
            </Row>
          )}
          {currentTab === "seasons" && (
            <Row gutter={breakpoints.md ? [24, 24] : [12, 12]}>
              <Col>
                <TwfButton
                  type="primary"
                  color-twf="primary"
                  size-twf="md"
                  type-twf="wide"
                  onClick={() => {
                    setCreateSeasonVisible(true);
                  }}
                >
                  {breakpoints.md && <Icon keyword="plus" color="#ffffff" />}
                  Add New Season
                </TwfButton>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <MarginBox>
        <Tabs onChange={tabChangeHandler} activeKey={currentTab}>
          <TabPane tab="General" key="general">
            <ClubSingleGeneral club={data} onClubChange={clubChangeHandler} />
          </TabPane>
          <TabPane tab="Members" key="members">
            <ClubSingleMembers />
          </TabPane>
          <TabPane tab="Seasons" key="seasons">
            <ClubSingleSeasons />
          </TabPane>
          {/*
          //marketing tab of club hidden by Navid
          <TabPane tab="Marketing" key="marketing">
            <ClubSingleMarketing club={data} onClubChange={clubChangeHandler} />
          </TabPane>
          */}
          <TabPane tab="Stats" key="stats">
            <ClubSingleStats />
          </TabPane>
        </Tabs>
      </MarginBox>
      <ClubAddMember
        visible={addMemberVisible}
        setVisible={setAddMemberVisible}
      />
      <ClubAddSeason
        visible={createSeasonVisible}
        setVisible={setCreateSeasonVisible}
      />
    </div>
  );
};

export default ClubSingle;
