import styled from "styled-components";

const InvoiceButton = styled.a`
  padding: 0 14px;
  border-radius: 10px;
  font-weight: 700;
  display: inline-flex;
  height: 30px;
  align-items: center;
  border: 1px solid #979797;
  color: #979797;
`;

export default InvoiceButton;