import {
  Col,
  Dropdown,
  Grid,
  Menu,
  message,
  Popconfirm,
  Row,
  Table,
} from "antd";
import Container from "component/container";
import { Address, useGetCustomerLazyQuery } from "generated/graphql";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import { TwfCard } from "../../../component/card";
import { MarginBox } from "../../../component/margin";
import { useDeleteCustomerAddressMutation } from "./../../../generated/graphql";
import CustomerSingleAddAddress from "./editAddress";
import CreateCustomerSingleAddAddress from './createAddress';

const { useBreakpoint } = Grid;

interface ComponentProps {
  addressCreateVisible?: boolean;
  setAddressCreateVisible: (value: boolean) => void;
}

const CustomerSingleAddress: React.FC<ComponentProps> = ({
  addressCreateVisible,
  setAddressCreateVisible,
}) => {
  const breakpoints = useBreakpoint();
  const [addressEditVisible,setAddressEditVisible]= useState(false)
  const { id } = useParams<{ id: string }>();
  const cid: string = id as string;
  const [getCustomerQuery, { data: customer }] = useGetCustomerLazyQuery();
  const [deleteCustomerAddress, { data: deleteResult }] =
    useDeleteCustomerAddressMutation();

  const [address, setAddress] = useState<Address>();

  const getCustomer = useCallback(() => {
    getCustomerQuery({ variables: { id: cid } });
  }, [cid, getCustomerQuery]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  const editHandler = (record: any) => {
    console.log(record,"onEdit")
    setAddress(record);
    setAddressEditVisible(true);
  };

  const confirmDeleteHandler = (record: any) => {
    deleteCustomerAddress({
      variables: { id: record.id },
      refetchQueries: ["GetCustomer"],
    });
  };

  useEffect(() => {
    if (deleteResult?.deleteCustomerAddress.success) {
      getCustomer();
      message.success("Address deleted successfully");
    }
  }, [deleteResult, getCustomer]);

  const columns: any[] = [
    {
      title: "NickName",
      dataIndex: "customFields",
      key: "nick",
      align: "center",
      render: (customFields: any) => customFields?.nickName,
    },
    {
      title: "Address",
      align: "center",
      render: (row: any) => `${row.streetLine1}, ${row.city}, ${row.province}`,
    },
    {
      title: "Default Billing",
      dataIndex: "defaultBillingAddress",
      render: (value: boolean) => (value ? "Yes" : "No"),
      align: "center",
    },
    {
      title: "Default Shipping",
      dataIndex: "defaultShippingAddress",
      render: (value: boolean) => (value ? "Yes" : "No"),
      align: "center",
    },
    {
      title: "",
      key: "action",
      width: breakpoints.md ? 120 : 50,
      fixed: "right",
      render: (record: any) => {
        return breakpoints.md ? (
          <Row gutter={24} justify="center">
            <Col>
              <TwfButton
                shape="circle"
                type-twf="icon"
                onClick={() => editHandler(record)}
              >
                <Icon keyword="edit" color="#3C64B1" />
              </TwfButton>
            </Col>
            <Col>
              <Popconfirm
                title="Are you sure to delete this address?"
                onConfirm={() => confirmDeleteHandler(record)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <TwfButton shape="circle" type-twf="icon">
                  <Icon keyword="delete" color="#FF4C61" />
                </TwfButton>
              </Popconfirm>
            </Col>
          </Row>
        ) : (
          <Dropdown
            placement="bottomRight"
            overlay={
              <Menu style={{ width: 120, padding: 4, borderRadius: 8 }}>
                <Menu.Item key="edit" onClick={editHandler}>
                  <Row gutter={8} align="middle">
                    <Col>
                      <Icon keyword="edit" color="#3C64B1" width={16} />
                    </Col>
                    <Col>Edit</Col>
                  </Row>
                </Menu.Item>
                <Menu.Item key="delete">
                  <Row gutter={8} align="middle">
                    <Col>
                      <Icon keyword="delete" color="#FF4C61" width={16} />
                    </Col>
                    <Col>Delete</Col>
                  </Row>
                </Menu.Item>
              </Menu>
            }
          >
            <TwfButton shape="circle" type-twf="icon">
              <Icon keyword="collapse" width={16} />
            </TwfButton>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Container>
      <TwfCard>
        <MarginBox
          pl={breakpoints.md ? 75 : 15}
          pr={breakpoints.md ? 75 : 15}
          mt={12}
          mb={56}
        >
          <Table
            columns={columns}
            dataSource={customer?.customer?.addresses?.map((obj) => ({
              ...obj,
              key: obj.id,
            }))}
            pagination={false}
            style={{ maxWidth: "max-content" }}
            scroll={{ x: "max-content" }}
          />
          <CustomerSingleAddAddress
            address={address}
            visible={addressEditVisible}
            setVisible={setAddressEditVisible}
          />
          <CreateCustomerSingleAddAddress
          visible={addressCreateVisible}
          setVisible={setAddressCreateVisible}
          />
        </MarginBox>
      </TwfCard>
    </Container>
  );
};

export default CustomerSingleAddress;
