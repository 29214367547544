import { Col, Row, Tabs } from "antd";
import ClubReportsTable from "component/club/club-report-table";
import { FC, useEffect, useState } from "react";
import { PageTitle } from "../../component/header";
import { MarginBox } from "../../component/margin";
import AllReportSales from "./components/allSales";
import ReportSales from "./components/sales";
import CustomerReport from "component/customer/customer-report";
const { TabPane } = Tabs;

interface PageProps {}

const ReportsPage: FC<PageProps> = () => {
  const [isSuperadmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("used-channel") === "__default_channel__") {
        setIsSuperAdmin(true);
      }
    }
  }, []);

  return (
    <div>
      <Row gutter={[30, 15]} justify="space-between" align="middle">
        <Col>
          <PageTitle>Reports</PageTitle>
        </Col>
      </Row>
      <MarginBox mt={27}>
        <Tabs>
          <TabPane tab="Individual Sales Report" key="sales">
            <ReportSales />
          </TabPane>
          {isSuperadmin && (
            <TabPane tab="Client Sales Detail" key="admin-sales">
              <AllReportSales />
            </TabPane>
          )}
          <TabPane tab="Club Details Report" key="club-details">
            <ClubReportsTable />
          </TabPane>
          <TabPane tab="Customer Report" key="customer-report">
            <CustomerReport />
          </TabPane>
          {/* <TabPane tab="Marketing" key="marketing">
            <ReportMarketing />
          </TabPane>
          <TabPane tab="Customers" key="customers">
            <ReportCustomers />
          </TabPane> */}
        </Tabs>
      </MarginBox>
    </div>
  );
};

export default ReportsPage;
