import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const ArchiveIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.41602 4.33203H20.5827C21.4446 4.33203 22.2713 4.67444 22.8808 5.28393C23.4903 5.89343 23.8327 6.72008 23.8327 7.58203V18.4154C23.8327 19.2773 23.4903 20.104 22.8808 20.7135C22.2713 21.323 21.4446 21.6654 20.5827 21.6654H5.41602C4.55406 21.6654 3.72741 21.323 3.11792 20.7135C2.50843 20.104 2.16602 19.2773 2.16602 18.4154V7.58203C2.16602 6.72008 2.50843 5.89343 3.11792 5.28393C3.72741 4.67444 4.55406 4.33203 5.41602 4.33203ZM5.41602 6.4987C5.1287 6.4987 4.85315 6.61283 4.64998 6.816C4.44682 7.01916 4.33268 7.29471 4.33268 7.58203V18.4154C4.33268 18.7027 4.44682 18.9782 4.64998 19.1814C4.85315 19.3846 5.1287 19.4987 5.41602 19.4987H20.5827C20.87 19.4987 21.1456 19.3846 21.3487 19.1814C21.5519 18.9782 21.666 18.7027 21.666 18.4154V7.58203C21.666 7.29471 21.5519 7.01916 21.3487 6.816C21.1456 6.61283 20.87 6.4987 20.5827 6.4987H5.41602ZM17.1962 11.9154C16.9552 12.8451 16.4123 13.6685 15.6527 14.2564C14.8931 14.8442 13.9598 15.1631 12.9993 15.1631C12.0389 15.1631 11.1056 14.8442 10.346 14.2564C9.58638 13.6685 9.04349 12.8451 8.80252 11.9154H4.33268V9.7487H21.666V11.9154H17.1962ZM14.8757 11.9154H11.123C11.3132 12.2447 11.5867 12.5182 11.9161 12.7084C12.2454 12.8985 12.619 12.9986 12.9993 12.9986C13.3797 12.9986 13.7533 12.8985 14.0826 12.7084C14.412 12.5182 14.6855 12.2447 14.8757 11.9154Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export default ArchiveIcon;
