/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuOutlined } from "@ant-design/icons";
import { Collapse, message, Table } from "antd";
import Icon from "assets/icons";
import {
  Page,
  SortOrder,
  useGetPageMenusLazyQuery,
  useUpdatePageMenuMutation,
} from "generated/graphql";
import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import TwfButton from "../../../component/button";
import { MenuPanelProps } from "../index";
import AddNewMenu from "./addNewMenu";

import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const { Panel } = Collapse;

const MenusSetting: React.FC<MenuPanelProps> = ({
  channel,
  columns,
  isMain,
  callMainPages,
  reloadMenu,
}) => {

  const [getPageMenus] = useGetPageMenusLazyQuery({
    fetchPolicy: "network-only",
  });

  const [updatePageMenu] = useUpdatePageMenuMutation();

  const [addMenu, setAddMenu] = useState<boolean>(false);

  const [collapseOpen, setCollapseOpen] = useState(false);

  const [mainPages, setMainPages] = useState<Page[]>([]);

  const [pages, setPages] = useState<any>({});
  const [whichPage, setWhichPage] = useState<string>();

  const [dummy, setDummy] = useState<any[]>([]);

  const [parentId, setParentId] = useState<string>();

  useEffect(() => {
    const getMainPages = () => {
      getPageMenus({
        variables: {
          options: {
            filter: {
              parent_id: {
                eq: 0,
              },
            },
          },
        },
      })
        .then((data) => {
          if (data.data?.pages) {
            data.data.pages.items.sort((a, b) => {
              return (a?.order_no || 10000) - (b?.order_no || 10000);
            });
            setMainPages(data.data.pages.items);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    };

    getMainPages();
  }, [getPageMenus]);

  useEffect(() => {
    const getInnerPages = (parent_id: string) => {
      getPageMenus({
        variables: {
          options: {
            filter: {
              parent_id: {
                eq: Number(parent_id),
              },
            },
            sort:{
              order_no: SortOrder.Asc
            }
          },
        },
      })
        .then((data) => {
          if (data.data?.pages) {
            const pageItems = data.data.pages.items;
            const finalPageItems: any[] = [];

            const mainItem = mainPages.find((page) => page.id === parent_id);
            if (mainItem) {
              const pageRefinedItem = {
                key: mainItem.id,
                option: mainItem.title,
                value: mainItem.redirect,
                order_no: mainItem.order_no,
                saveTo: "menu",
                readonly: isMain === true ? null : true,
                isMain: true,
              };
              finalPageItems.push(pageRefinedItem);
              pages[parent_id] = finalPageItems;
            }

            if (pageItems.length !== 0) {
              pageItems.forEach((pageItem) => {
                const pageRefinedItem = {
                  key: pageItem.id,
                  option: pageItem.title,
                  value: pageItem.redirect,
                  order_no: pageItem.order_no,
                  saveTo: "menu",
                  readonly: isMain === true ? null : true,
                  isMain: false,
                };
                finalPageItems.push(pageRefinedItem);
              });
              pages[parent_id] = finalPageItems;
            }

            setPages(pages);
            setDummy(pages[parent_id]);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    };

    if (whichPage != null) {
      getInnerPages(whichPage);
    }
  }, [getPageMenus, isMain, mainPages, pages, whichPage]);

  const collapseChanged = (key: any) => {
    if (key.length !== 0) {
      const index = Number(key[0]);
      setWhichPage(mainPages[index].id);
      setCollapseOpen(true);
    }else{
      setCollapseOpen(false);
    }
  };

  const onDragEndHandler = (result: DropResult) => {
    if (!result.destination) return;
    const items = Array.from(mainPages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setMainPages(items);

    const pagesArray: string[] = [];
    items.forEach((element) => {
      const page = element.title;
      pagesArray.push(page);
    });
    for (let [i, page] of pagesArray.entries()) {
      const order_no = i + 1;
      const pageName = page;
      const mainPageObject = mainPages.find((page) => page.title === pageName);
      if (mainPageObject?.id) {
        updatePageMenu({
          variables: {
            input: {
              id: mainPageObject.id,
              order_no: order_no,
            },
          },
          // eslint-disable-next-line no-loop-func
        })
          .catch((error) => {
            message.error(error.message);
          });
      }
    }
    message.success("Page order successfully updated!");
  };

  const createIcon = (title: string) => {
    return (
        <>
          {title}
          <MenuOutlined style={{ cursor: 'grab', color: '#999', float:"right", marginRight:"5%" }} />
        </>
    );
  }

  return (
    <>
      <div style={{ marginTop: "2%", marginBottom: "2q%", marginLeft: "2%" }}>
        <TwfButton
          type="primary"
          type-twf="wide"
          color-twf="primary"
          size-twf="md"
          style={{
            marginLeft: "2%",
            marginRight: "2%",
            marginTop: "2%",
            marginBottom: "2%",
          }}
          onClick={() => {
            setParentId("0");
            setAddMenu(true);
          }}
        >
          <Icon keyword="plus" color="#ffffff" />
          Add New Menu
        </TwfButton>
      </div>
      {/* @ts-ignore */}
      <DragDropContext onDragEnd={onDragEndHandler}>
        <Droppable droppableId="pages">
          {(provided) => (
            <div
              className="pages"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {mainPages.map((page, index) => (
                <Draggable key={page.id} draggableId={page.id} index={index}
                isDragDisabled={collapseOpen}>
                  {(provided) => (
                    <div
                      className="pages_list"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Collapse key={page.id} onChange={collapseChanged}>
                        <Panel header={createIcon(page.title)} key={index}>
                          <TwfButton
                            type="primary"
                            type-twf="wide"
                            color-twf="primary"
                            size-twf="md"
                            style={{
                              marginLeft: "2%",
                              marginRight: "2%",
                              marginTop: "2%",
                              marginBottom: "2%",
                            }}
                            onClick={() => {
                              setParentId(page.id);
                              setAddMenu(true);
                            }}
                          >
                            <Icon keyword="plus" color="#ffffff" />
                            Add New Sub Menu
                          </TwfButton>

                          <Table
                            columns={columns}
                            dataSource={pages[page.id] || null}
                            pagination={false}
                            showHeader={false}
                            rowKey="index"
                            style={{ width: "100%" }}
                            scroll={{ x: "max-content" }}
                          />
                        </Panel>
                      </Collapse>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <br />
      <br />
      <AddNewMenu
        reloadMenu={reloadMenu}
        setVisible={setAddMenu}
        visible={addMenu}
        parentId={parentId!}
      />
    </>
  );
};

export default MenusSetting;
