import {
  Col,
  Dropdown,
  Grid,
  Menu,
  message,
  Row,
  Table,
  TablePaginationConfig,
  Tag,
} from "antd";
import {
  SortOrder,
  useDeleteClubMutation,
  useGetClubListLazyQuery,
} from "generated/graphql";
import { TableOptions } from "pages/orders/components/datatable";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../assets/icons";
import TwfButton from "../../component/button";
import { PageTitle } from "../../component/header";
import { MarginBox } from "../../component/margin";
import ClubCreate from "./components/create";

const { useBreakpoint } = Grid;

interface PageProps {}

const ClubPage: FC<PageProps> = () => {
  const breakpoints = useBreakpoint();
  const history = useNavigate();
  const [getClubsQuery, { data, loading }] = useGetClubListLazyQuery({
    fetchPolicy: "network-only",
  });
  const [deleteClubMutation] = useDeleteClubMutation();

  const [createVisible, setCreateVisible] = useState(false);
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    pagination: { current: 1, pageSize: 10 },
    sort: { id: SortOrder.Desc },
  });

  useEffect(() => {
    getClubsQuery({
      variables: {
        options: {
          skip:
            (tableOptions.pagination.pageSize || 10) *
            ((tableOptions.pagination.current || 1) - 1),
          take: tableOptions.pagination.pageSize,
          sort: tableOptions.sort,
        },
      },
    });
  }, [getClubsQuery, tableOptions]);

  const deleteCustomerGroup = (id: string) => {
    deleteClubMutation({
      variables: { id },
      refetchQueries: ["GetClubList"],
    })
      .then(() => message.success("Club deleted"))
      .catch((error) => message.error(error.message));
  };

  const tableChangeHandler = (
    pagination: TablePaginationConfig,
    filters: any,
    sort: any
  ) => {
    setTableOptions({
      pagination,
      sort: sort.field
        ? {
            [sort.field]:
              sort.order === "ascend" ? SortOrder.Asc : SortOrder.Desc,
          }
        : tableOptions.sort,
    });
  };

  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: {},
    },
    {
      title: "Order",
      dataIndex: "order",
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (active: any) => {
        return (
          <Tag color={!active ? "#FB8832" : "#4AAF05"}>
            {active ? "active" : "inactive"}
          </Tag>
        );
      },
    },
    {
      title: "Members",
      dataIndex: "clubCustomers",
      render: (customers: any[]) => customers?.length,
    },
    {
      title: "",
      key: "action",
      // width: breakpoints.md ? 102 : 50,
      fixed: "right",
      render: (record: any) => {
        const menuItems = [
          {
            key: "seasons",
            label: "Club Seasons",
            onClick: () => {
              history(`/club/single/${record.id}?tab=seasons`);
            },
          },
          {
            key: "edit",
            label: "Edit Club",
            onClick: () => {
              history(`/club/single/${record.id}`);
            },
          },
          {
            key: "delete",
            label: "Delete Club",
            onClick: () => {
              deleteCustomerGroup(record.id);
            },
          },
        ];
        return (
          <Row justify="center">
            <Col>
              <Dropdown
                placement="bottomRight"
                overlay={
                  <Menu items={menuItems} style={{ borderRadius: 12 }}>
                    {/*
                      <Popconfirm
                      title="Are you sure to delete this club?"
                      onConfirm={() => deleteCustomerGroup(record.id)}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Menu.Item key="delete">Delete Club</Menu.Item>
                    </Popconfirm>
                      */}
                  </Menu>
                }
              >
                <TwfButton shape="circle" type-twf="icon">
                  <Icon keyword="collapse" width={breakpoints.md ? 18 : 16} />
                </TwfButton>
              </Dropdown>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <div>
      <Row justify="space-between" gutter={[24, 12]} align="middle">
        <Col>
          <PageTitle>Clubs</PageTitle>
        </Col>
        <Col>
          <TwfButton
            type="primary"
            type-twf="wide"
            color-twf="primary"
            size-twf={breakpoints.md ? "lg" : "md"}
            onClick={() => {
              setCreateVisible(true);
            }}
          >
            {breakpoints.md && <Icon keyword="plus" color="#fff" />}
            Create New Club
          </TwfButton>
        </Col>
      </Row>
      <MarginBox mt={32}>
        <MarginBox mt={12} mb={56}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={data?.clubs.items.map((obj) => ({
              ...obj,
              key: obj.id,
            }))}
            pagination={{
              ...tableOptions.pagination,
              total: data?.clubs.totalItems,
              position: ["bottomCenter"],
              showSizeChanger: true,
            }}
            style={{ maxWidth: "max-content" }}
            scroll={{ x: "max-content" }}
            onChange={tableChangeHandler}
          />
        </MarginBox>
      </MarginBox>
      <ClubCreate visible={createVisible} setVisible={setCreateVisible} />
    </div>
  );
};

export default ClubPage;
