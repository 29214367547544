import { Col, Collapse, Input, message, Popconfirm, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Container from "component/container";
import {
  Asset,
  Channel,
  useGetActiveChannelQuery,
  useGetAdministratorsLazyQuery,
  useGetAdministratorsQuery,
  useGetRolesLazyQuery,
  useUpdateActiveAdministratorMutation,
  useUpdateAdministratorMutation,
  useUpdateChannelMutation,
  useGetActiveAdministratorQuery,
  useGetActiveChannelLazyQuery,
  useUpdatePageMenuMutation,
  useDeletePageMutation,
} from "generated/graphql";
import { FC, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import styled from "styled-components";
import Icon from "../../assets/icons";
import TwfButton from "../../component/button";
import { TwfCard } from "../../component/card";
import { PageTitle } from "../../component/header";
import { MarginBox } from "../../component/margin";
import AssetSelector from "../assets/component/asset-selector";
import AccountManagementSetting from "./components/account";
import AddNewUser from "./components/addNew";
import BrandProfileSetting from "./components/brand";
import OwnerProfileSetting from "./components/owner";
import SocialMediaSetting from "./components/social";
import UserManagementSetting from "./components/users";
import BasicInfoSetting from "./components/basicInfo";
import MenusSetting from "./components/menus";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import PolicySetting from "./components/policy";
import { fontFamilies } from "../../assets/data/fonts";
import { Typography, Select } from 'antd';
import RichEditor from "./components/richTextEditor/richTextEditor";
import AdvisoryMessage from "./components/advisoryMessage";
const { Text } = Typography;

const { Panel } = Collapse;

export interface SettingPanelProps {
  channel?: Channel;
  isMain: boolean;
  columns: any[];
  isSuperAdmin: boolean;
}

export interface MenuPanelProps {
  channel?: Channel;
  isMain: boolean;
  columns: any[];
  callMainPages: boolean;
  reloadMenu: () => void;
}

interface PageProps {}

const SettingsPage: FC<PageProps> = () => {
  const breakpoints = useBreakpoint();
  const [getActiveChannelQuery] = useGetActiveChannelLazyQuery();
  const [hideit, setHideit] = useState<boolean>(false);
  const [callMainPages, setCallMainPages] = useState<boolean>(false);
  const [menuRender, setMenuRender] = useState<number>(5);
  const [reloadKey, setReloadKey] = useState<number>(Math.floor(Math.random()*10000));
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getActiveChannelQuery()
      .then((data) => {
        if (data.data?.activeChannel) {
          if (data.data.activeChannel.code === "__default_channel__") {
            setHideit(true);
            setIsSuperAdmin(true);
          }
        }
      })
      .catch((error) => {
        setHideit(true);
        message.error(error.message);
      });
  }, [getActiveChannelQuery]);

  const { data: getChannelResult } = useGetActiveChannelQuery();
  const { data: getAdminResult } = useGetActiveAdministratorQuery();

  const [getAdministrators] = useGetAdministratorsLazyQuery();

  const { data: getAllAdministrators } = useGetAdministratorsQuery();

  const [getRoles] = useGetRolesLazyQuery();

  const [
    updateAdminMutation,
    { data: updateAccountResult, loading: loadingAdmin },
  ] = useUpdateActiveAdministratorMutation();

  const [
    updatePageMenuMutation,
    { data: updatePageMenuResult, loading: loadingMenu },
  ] = useUpdatePageMenuMutation();

  const [
    updateOneAdminMutation,
    { data: updateAdministratorResult, loading: loadingAdministrator },
  ] = useUpdateAdministratorMutation();

  const [
    updateChannelMutation,
    { data: updateChannelResult, loading: loadingChannel },
  ] = useUpdateChannelMutation();

  const [
    deletePageMenuMutation,
    { data: deletePageMenuResult, loading: loadingMenuDelete },
  ] = useDeletePageMutation();

  const [addVisible, setAddVisible] = useState(false);
  const [usersVisible, setUsersVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState<string>();
  const [account, setAccount] = useState<any>();
  const [role, setRole] = useState<any>();
  const [editableField, setEditableField] = useState<string>();
  const [editableValue, setEditableValue] = useState<string>();

  const [editableOption, setEditableOption] = useState<string>();

  const [editableOrder, setEditableOrder] = useState<string>();

  const editHandler = (record: any) => {
    setEditableField(record.key);
    setEditableValue(record.value);
    setEditableOption(record.option);
    setEditableOrder(record.order_no);
  };

  const deleteHandler = (record: any) => {
    const pageId = record.key;
    deletePageMenuMutation({
      variables: {
        id: pageId,
      },
      refetchQueries: ["GetPageMenus"],
    });
  };

  const saveHandler = (record: any) => {
    if (key === "password" && editableValue?.length === 0) {
      message.error("Password length cannot be zero");
    } else {
      if (record.saveTo === "oneaccount") {
        record.key = record.key.replace(/[0-9]/g, "");
        if (record.key === "password" && editableValue?.length === 0) {
          message.error("Password length cannot be zero");
        } else {
          updateFixAdmin(record.id, record.key, editableValue);
        }
      } else if (record.saveTo === "account") {
        updateAdmin(record.key, editableValue);
      } else if (record.saveTo === "menu") {
        updateMenu(record.key, editableOption, editableValue, editableOrder);
      } else {
        updateChannel(record.key, editableValue);
      }
    }
  };

  const clearHandler = (record: any) => {
    updateChannel(record.key, null);
  }

  const updateMenu = (
    field: string,
    option?: string,
    value?: string,
    order?: string
  ) => {
    if (!option) {
      message.error("Please enter a valid menu name");
    } else {
      updatePageMenuMutation({
        variables: {
          input: {
            id: field,
            title: option,
            redirect: value || "",
            order_no: order ? parseInt(order) : undefined,
          },
        },
        refetchQueries: ["GetPageMenus"],
      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("used-channel") === "__default_channel__") {
      setRole(getAdminResult?.activeAdministrator?.user.roles[0]);
      if (getChannelResult?.activeChannel.code === "__default_channel__") {
        if (getAdminResult?.activeAdministrator?.customFields) {
          const ad = JSON.parse(
            JSON.stringify(getAdminResult.activeAdministrator)
          );
          ad.customFields.isStoreAdmin = true;
          setAccount(ad);
        }
      } else {
        findthem();
      }
    } else {
      findthem(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChannelResult, getAdminResult]);

  const findthem = (_flag = 0) => {
    let roleid: string;

    setAccount(getAdminResult?.activeAdministrator);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let admin: any;
    //if(flag==0){
    getRoles()
      .then((data) => {
        if (data.data?.roles && data.data?.roles.items) {
          const roles = data.data.roles.items;
          for (let i = 0; i < roles.length; i++) {
            const channelids = roles[i].channels.map((channel) => channel.id);
            if (
              channelids.length === 1 &&
              channelids.indexOf(String(getChannelResult?.activeChannel.id)) !==
                -1
            ) {
              roleid = roles[i].id;
              setRole(roles[i]);
              break;
            }
          }
        }

        getAdministrators()
          .then((data1) => {
            if (
              data1.data?.administrators &&
              data1.data?.administrators.items
            ) {
              const admins = data1.data.administrators.items;
              for (let i = 0; i < admins.length; i++) {
                const rolesid = admins[i].user.roles.map((role) => role.id);
                if (
                  rolesid.length === 1 &&
                  rolesid.indexOf(String(roleid)) !== -1
                ) {
                  admin = admins[i];
                  break;
                }
              }
            }

            /*if (admin) {
              setAccount(admin);
            }*/
          })
          .catch((err1) => {
            message.error(err1.message);
          });
      })
      .catch((err) => {
        message.error(err.message);
      });
    /*}else{
      setAccount(getAdminResult?.activeAdministrator);
    }*/
  };

  useEffect(() => {
    setLoading(
      loadingChannel ||
        loadingAdmin ||
        loadingAdministrator ||
        loadingMenu ||
        loadingMenuDelete
    );
  }, [
    loadingChannel,
    loadingAdmin,
    loadingAdministrator,
    loadingMenu,
    loadingMenuDelete,
  ]);

  useEffect(() => {
    if (!loading) setEditableField("");
  }, [loading]);

  const reloadMenu = () => {
    setCallMainPages(true);
    const newKey = Math.floor(Math.random() * 100);
    setMenuRender(newKey);
    setReloadKey(Math.floor(Math.random() * 100));
  };

  useEffect(() => {
    if (
      updateChannelResult ||
      updateAccountResult ||
      updateAdministratorResult ||
      updatePageMenuResult ||
      deletePageMenuResult
    ) {
      if (deletePageMenuResult) {
        message.success("Successfully deleted page menu!");
      } else {
        message.success("Successfully updated!");
      }
      if (updatePageMenuResult || deletePageMenuResult) {
        reloadMenu();
      }
    }
  }, [
    updateChannelResult,
    updateAccountResult,
    updateAdministratorResult,
    updatePageMenuResult,
    deletePageMenuResult,
  ]);

  const updateChannel = (field: string, value?: any) => {
    if (getChannelResult?.activeChannel) {
      let channelInput = {
        id: getChannelResult?.activeChannel.id,
        customFields: { [field]: value },
      } as any;
      if(field==="token"){
        channelInput = {
          id: getChannelResult?.activeChannel.id,
          token: value
        }
      }
      updateChannelMutation({
        variables: {
          input: channelInput,
      },
      refetchQueries: ["getActiveChannel"],
      }).then((data)=>{
        if(data.data?.updateChannel.__typename==="Channel" && field==="token"){
          localStorage.setItem("vendure-token", data.data.updateChannel.token);
          navigate(0); 
        }
      })

    }
  };

  const updateFixAdmin = (id: string, field: string, value?: string) => {
    updateOneAdminMutation({
      variables: {
        input: {
          id: id,
          [field]: value,
        },
      },
      refetchQueries: ["GetActiveAdministrator", "GetAdministrators"],
    });
  };

  const updateAdmin = (field: string, value?: string) => {
    if (
      getChannelResult?.activeChannel &&
      (getChannelResult?.activeChannel.code === "__default_channel__" ||
        localStorage.getItem("used-channel") !== "__default_channel__")
    ) {
      updateAdminMutation({
        variables: {
          input: {
            [field]: value,
          },
        },
        refetchQueries: ["GetActiveAdministrator", "GetAdministrators"],
      });
    } else {
      updateOneAdminMutation({
        variables: {
          input: {
            id: account.id,
            [field]: value,
          },
        },
        refetchQueries: ["GetActiveAdministrator", "GetAdministrators"],
      });
    }
  };

  const assetSelectHandler = (asset: Asset) => {
    setEditableValue(asset.source);
  };

  const colorPickerChangeHandler = (color: any) => {
    setEditableValue(color.hex);
  };

  const fontPickerChangeHandler = (font: any) => {
    setEditableValue(font);
  };

  const renderOptionView = (type: string, key: string) => {
    setKey(key);
    switch (type) {
      case "file":
        return <AssetSelector onAssetSelect={assetSelectHandler} multi={false} />;
      case "color-picker":
        return (
          <SketchPicker
            color={editableOption || "#CCCCCC"}
            onChange={colorPickerChangeHandler}
          />
        );
      case "text-area":
          return (
            <TextArea 
            onChange={(e: any) => {
              setEditableOption(e.target.value)
            }}
            value={editableOption} 
            cols={20} 
            rows={10} 
            placeholder="Please enter value"
            />
          );
      case "font-picker":
        return (
          <Select
          style={{
            minWidth: 120,
          }}
          showSearch
          placeholder="Select a font"
          optionFilterProp="children"
          // value={editableOption || "Open Sans"}
          onChange={fontPickerChangeHandler}
           
           filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={fontFamilies}
          />
        );
      default:
        return (
          <Input
            onChange={(e: any) => setEditableOption(e.target.value)}
            style={{ borderRadius: 14 }}
            value={editableOption}
            type={"text"}
            placeholder="Please Enter Label"
          ></Input>
        );
    }
  };

  const renderOrderView = (type: string, key: string) => {
    setKey(key);
    switch (type) {
      case "file":
        return <AssetSelector onAssetSelect={assetSelectHandler} multi={false} />;
      case "text-area":
          return (
            <TextArea 
            onChange={(e: any) => {
              setEditableOrder(e.target.value)
            }}
            value={editableOrder} 
            cols={20} 
            rows={10} 
            placeholder="Please enter value"/>
          );
      case "color-picker":
        return (
          <SketchPicker
            color={editableOrder || "#CCCCCC"}
            onChange={colorPickerChangeHandler}
          />
        );
      case "font-picker":
        return (
          <Select
          style={{
            minWidth: 120,
          }}
          showSearch
          placeholder="Select a font"
          optionFilterProp="children"
          // value={editableOption || "Open Sans"}
          onChange={fontPickerChangeHandler}
           
           filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={fontFamilies}
          />
        );
      default:
        return (
          <Input
            onChange={(e: any) => setEditableOrder(e.target.value)}
            style={{ borderRadius: 14 }}
            value={editableOrder}
            type={"number"}
          ></Input>
        );
    }
  };

  const renderEditView = (type: string, key: string) => {
    setKey(key);
   // console.log(type, "Type")
    switch (type) {
      case "file":
        return <AssetSelector onAssetSelect={assetSelectHandler} multi={false} />;
      case "color-picker":
        return (
          <SketchPicker
            color={editableValue || "#CCCCCC"}
            onChange={colorPickerChangeHandler}
          />
        );
      case "text-area":
        return (
          <TextArea 
          onChange={(e: any) => {
            setEditableValue(e.target.value)
          }}
          value={editableValue} 
          cols={40} 
          rows={20} 
          placeholder="Please enter value"/>
        );
      case "rich-text-editor":
        return (
          <RichEditor 
          setContent={setEditableValue} 
          value={editableValue}
          />
        )
      case "font-picker":
        return (
          <Select
          style={{
            minWidth: 120,
          }}
          showSearch
          placeholder="Select a font"
          optionFilterProp="children"
          // value={editableOption || "Open Sans"}
          onChange={fontPickerChangeHandler}
           
           filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={fontFamilies}
          />
        );
      default:
        return (
          <Input
            onChange={(e: any) => setEditableValue(e.target.value)}
            style={{ borderRadius: 14 }}
            value={editableValue}
            type={key.indexOf("password") !== -1 ? "password" : "text"}
            placeholder="Please Enter Value"
          ></Input>
        );
    }
  };

  const columns: any[] = [
    {
      title: "",
      align: "center",
      render: (row: any) => {
        return row.saveTo === "menu" && editableField === row.key ? (
          renderOptionView(row.type, row.key)
        ) : row.render ? (
          row.render(row.option)
        ) : row.isMain === true ? (
          <>
            <strong>{row.option}</strong>
          </>
        ) : (
          row.option
        );
      },
    },
    {
      title: "",
      render: (row: any) =>
        editableField === row.key ? (
          renderEditView(row.type, row.key)
        ) : row.render ? (
          row.render(row.value)
        ) : row.isMain === true ? (
          // menu link  
          <Text
          style={{ width: 100 }}
          ellipsis={{ tooltip: row.value }}
        >   
          <strong>{row.value}</strong>  
          </Text>
        ) : (
          row.value
        ),
    },
    {
      title: "",
      align: "center",
      render: (row: any) => {
        if (row.saveTo === "menu" && !row.isMain) {
          return row.saveTo === "menu" && editableField === row.key ? (
            renderOrderView(row.order_no, row.key)
          ) : row.render ? (
            row.render(row.order_no)
          ) : (
            <span hidden={row.order_no === null}>
              <strong>Display Order</strong>
              <br /> {row.order_no}
            </span>
          );
        }
      },
    },
    {
      title: "",
      render: (row: any) => {
        return editableField !== row.key ? (
          <div style={{ display: "inline-flex", gap: "20px" }}>
            <TwfButton
              shape="circle"
              type-twf="icon"
              onClick={() => editHandler(row)}
              style={row.readonly && { visibility: "hidden" }}
            >
              <Icon color="#3C64B1" keyword="edit" />
            </TwfButton>
            <Popconfirm
              title="Are you sure to delete this menu?"
              onConfirm={() => deleteHandler(row)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <TwfButton
                shape="circle"
                type-twf="icon"
                hidden={row.saveTo !== "menu"}
                style={row.readonly && { visibility: "hidden" }}
              >
                <Icon color="#FF0000" keyword="delete" />
              </TwfButton>
            </Popconfirm>
          </div>
        ) : (
          <div style={{ display: "inline-flex", gap: "20px" }}>
            
            <TwfButton
              color-twf="gray-line"
              size-twf="sm"
              hidden={row.type !== "color-picker" && row.type!=="font-picker"}
              onClick={() => {
                clearHandler(row)
              }
              }
            >
              Clear
            </TwfButton>

            <Popconfirm
            title="Are you sure to edit the token? This will need subdomain changes."
            onConfirm={() => saveHandler(row)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
            disabled={row.key!=="token"}
            >
              
              <TwfButton
              color-twf="primary"
              type="primary"
              size-twf="sm"
              onClick={() => 
                row.key==="token"
                ?
                {}
                :
                saveHandler(row)
              }
              loading={loading}
              >
                Save
            </TwfButton>{" "}

            </Popconfirm>

            <TwfButton
              color-twf="gray-line"
              size-twf="sm"
              onClick={() => setEditableField("")}
            >
              Cancel
            </TwfButton>
          </div>
        );
      },
    },
    {
      title: "",
      className: "drag-visible",
      render: (row: any) => {
        if (row.saveTo === "menu" && row.isMain === true) {
          return (
            <p style={{ fontSize: "12px", fontWeight: "bolder" }}>
              Deleting this deletes entire menu
            </p>
          );
        }
      },
    },
  ];

  return (
    <div>
      <Row justify="space-between" align="middle" gutter={[30, 15]}>
        <Col>
          <Row gutter={12} align="middle">
            <Col>
              {usersVisible && (
                <ButtonWrap
                  shape="circle"
                  type-twf="icon"
                  onClick={() => {
                    setUsersVisible(false);
                  }}
                >
                  <Icon keyword="arrow-left" width={breakpoints.md ? 34 : 24} />
                </ButtonWrap>
              )}
            </Col>
            <Col>
              {usersVisible && <PageTitle>User Management</PageTitle>}
              {!usersVisible && <PageTitle>Settings</PageTitle>}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={[20, 10]}>
            <Col>
              <TwfButton
                type="primary"
                type-twf="wide"
                color-twf="primary"
                size-twf="md"
                //disabled={!account?.customFields?.isStoreAdmin}
                onClick={() => {
                  setAddVisible(true);
                }}
              >
                {breakpoints.md && <Icon keyword="plus" color="#ffffff" />}
                Add New Users
              </TwfButton>
            </Col>
            {/*
            <Col>
              <TwfButton
                type="primary"
                type-twf="wide"
                color-twf="danger"
                size-twf="md"
                disabled={!account?.customFields?.isStoreAdmin}
                onClick={() => {
                  if (!usersVisible) {
                    setBText("Edit Other Details");
                  } else {
                    setBText("Edit Users");
                  }
                  setUsersVisible(!usersVisible);
                }}
              >
                {breakpoints.md && <Icon keyword="edit" color="#ffffff" />}
                {bText}
              </TwfButton>
            </Col>
              */}
          </Row>
        </Col>
      </Row>
      <MarginBox mt={32}>
        <Container maxWidth="800px">
          <TwfCard style={{ padding: "4px 0 56px" }}>
            <Collapse>
              {!usersVisible && (
                <Panel header="Basic Info" key="1">
                  <BasicInfoSetting
                    key={reloadKey}
                    isSuperAdmin={isSuperAdmin}
                    isMain={true}
                    channel={getChannelResult?.activeChannel}
                    columns={columns}
                  />
                </Panel>
              )}
              {!usersVisible && (
                <Panel header="Owner Profile" key="2">
                  <OwnerProfileSetting
                    key={reloadKey}
                    isSuperAdmin={isSuperAdmin}
                    isMain={true}
                    channel={getChannelResult?.activeChannel}
                    columns={columns}
                  />
                </Panel>
              )}
              {!usersVisible && (
                <Panel header="Brand Profile" key="3">
                  <BrandProfileSetting
                    key={reloadKey}
                    isSuperAdmin={isSuperAdmin}
                    isMain={true}
                    channel={getChannelResult?.activeChannel}
                    columns={columns}
                  />
                </Panel>
              )}
              {!usersVisible && !hideit && (
                <Panel header="Social Media" key="4">
                  <SocialMediaSetting
                    key={reloadKey}
                    isSuperAdmin={isSuperAdmin}
                    isMain={true}
                    channel={getChannelResult?.activeChannel}
                    columns={columns}
                  />
                </Panel>
              )}
              {/*(!usersVisible && !hideit) && (
                <Panel header="Main Menu" key="5">
                  <MainMenuSetting
                    isMain={account?.customFields?.isStoreAdmin}
                    channel={getChannelResult?.activeChannel}
                    columns={columns}
                  />
                </Panel>
              )*/}
              {!usersVisible && !hideit && (
                <Panel header="Menus & SubMenus" key={menuRender}>
                  <MenusSetting
                    key={reloadKey}
                    isMain={true}
                    channel={getChannelResult?.activeChannel}
                    columns={columns}
                    callMainPages={callMainPages}
                    reloadMenu={reloadMenu}
                  />
                </Panel>
              )}
              {!usersVisible && (
                <Panel header="Account Management" key="6">
                  <AccountManagementSetting
                    key={reloadKey}
                    account={account}
                    columns={columns}
                  />
                </Panel>
              )}
              {!usersVisible && (
                <Panel header="User Management" key="7">
                  <UserManagementSetting
                    key={reloadKey}
                    isMain={true}
                    role={role}
                    administrators={getAllAdministrators?.administrators}
                    account={account}
                    columns={columns}
                  />
                </Panel>
              )}
              {!usersVisible && (
                <Panel header="Privacy Policy, Terms of Services and Refund Policy" key="8">
                  <PolicySetting
                    key={reloadKey}
                    isSuperAdmin={isSuperAdmin}
                    isMain={true}
                    columns={columns}
                    channel={getChannelResult?.activeChannel}
                  />
                </Panel>
              )}

{!usersVisible && (
                <Panel header="Advisory Message" key="9">
                 
                 <AdvisoryMessage  channel={getChannelResult?.activeChannel} />
                </Panel>
              )}
            </Collapse>
          </TwfCard>
        </Container>
      </MarginBox>
      <AddNewUser visible={addVisible} setVisible={setAddVisible} />
    </div>
  );
};

const ButtonWrap = styled(TwfButton)`
  background-color: transparent;
  :hover {
    background-color: transparent;
  }
`;

export default SettingsPage;
