import  { CaretDownOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space } from "antd";
import React, { SetStateAction, useState } from "react";

interface Props {
  options: any;
  type: string;
  deleteItem: (value: string, type: string) => void;
  selected: any;
  setSelected: React.Dispatch<SetStateAction<null>>;
  addItem: (e: any, type: string) => void;
  name: string;
  onNameChange: (event: any) => void;
  inputRef: React.MutableRefObject<null>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  dispatch?: (value: any) => void
}

const DropdownAdder: React.FC<Props> = ({
  options,
  type,
  deleteItem,
  selected,
  setSelected,
  addItem,
  name,
  setName,
  onNameChange,
  inputRef,
  dispatch
}) => {

  const [showCross, setShowCross] = useState(true)
  
  const detectChange = (opened: boolean)=>{
    if(!opened){
      setName('')
      setShowCross(false)
    }else{
      setShowCross(true)
    }
  }

  return (
    <Select
    suffixIcon={<CaretDownOutlined />}
    allowClear
    value={selected}
    onSelect={(e:any)=>{
        setShowCross(false)
        setSelected(e)
    }}
    onChange={(e:any)=>{
      (dispatch && dispatch({ 
        name:(type!=="wineLabel")?type:"label", 
        value:e 
      }))
    }}
    onClear={()=>{
        setSelected(null)
    }}
    onDropdownVisibleChange={detectChange}
    dropdownRender={(menu) => (
    <>
    {menu}
    <Divider
      style={{
        margin: '8px 0',
      }}
    />
    <Space
      style={{
        padding: '0 8px 4px',
      }}
    >
     <Input
        placeholder="Please enter Item"
        ref={inputRef}
        value={name}
        onChange={onNameChange}
     />
    <Button type="text" icon={<PlusOutlined />} onClick={(e)=>{
        addItem(e,type)
    }}>
        Add Item
    </Button>
    </Space>
    </>
    )}
    >
     {
      options.map((item:any,index:number)=>{
        return (
          <Select.Option key={index} value={item.value}>
            <span>
            {item.label} 
            {
              ((selected !== item.value)) &&
              <Button
                size="small" 
                hidden={!showCross}
                type="text" 
                style={{ float:"right" }}
                onClick={()=>{
                    deleteItem(item.value,type)
                }}>
                   <CloseOutlined/>
               </Button>
                }
              </span>
            </Select.Option>
          )
        })
      }
    </Select>
  );

};

export default DropdownAdder;
