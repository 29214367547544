import styled from "styled-components";

type Props = {
  maxWidth?: string;
  minWidth?: string;
};

const Container = styled.div<Props>`
  max-width: ${(props) => props.maxWidth || "max-content"};
  ${(props) => props.minWidth && `min-width = ${props.minWidth}`}
`;

export default Container;
