import { Menu } from "antd";
import styled from "styled-components";

const { Item } = Menu;

const TwfMenu = styled(Menu)`
  height: calc(100vh - 100px);
  background-color: transparent;
  padding-top: 42px;
  border: none;
  position: sticky;
  top: 0;
  &.ant-menu-inline-collapsed {
    .ant-menu-item {
      padding: 0;
      grid-gap: 0;
      justify-content: center;
      width: 54px;
      height: 54px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 12px;
    }
  }
  @media (max-width: 768px) {
    // height: 100vh;
    overflow-y: auto;
    padding-top: 62px;
    padding-bottom: 62px;
  }
`;

const TwfMenuItem = styled(Item)`
  &.ant-menu-item {
    height: 54px;
    display: flex;
    align-items: center;
    grid-gap: 12px;
    :after {
      border: none;
    }
    .ant-menu-title-content {
      color: #a6acbe;
      font-size: 15px;
      font-weight: bold;
    }
    svg {
      width: 26px;
      height: 26px;
      path {
        fill: #a6acbe;
      }
    }
    &.ant-menu-item-selected {
      .ant-menu-title-content {
        color: #3c64b1;
      }
      svg {
        path {
          fill: #3c64b1;
        }
      }
    }
  }
`;

export { TwfMenu, TwfMenuItem };
