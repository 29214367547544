import React, { useState } from "react";
import TwfModal from "../../../component/modal";
import Icon from "../../../assets/icons";
import ModalTitle from "../../../component/modal/title";
import { H3 } from "../../../component/header";
import { Col, Form, Input, message, Row } from "antd";
import { MarginBox } from "../../../component/margin";
import TwfButton from "../../../component/button";
import { TwfCard } from "../../../component/card";
import {
  useCreateChannelMutation,
  CreateChannelInput,
  LanguageCode,
  CurrencyCode,
  Permission,
  useCreateRoleMutation,
  useCreateAdministratorMutation,
  useSetDefaultComplianceMutation,
  useAssignPaymentstoChannelMutation,
  useCreateDefaultTemplatesMutation,
  useCreateDefaultProductAttributesMutation,
  useCreateDefaultPolicyMutation,
  useCreateDefaultPickupOptionsMutation,
  useCreateShippingsForChannelMutation
} from "../../../generated/graphql";
import styled from "styled-components";

const { Item } = Form;

interface CreateChannelFormData {
  brandName: string;
  token: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  membershipLevel: number;
  orderPrefix: string;
  complianceBrandKey: string;
}

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const channelAdminRolePermissions = [
  Permission.Authenticated,
  Permission.CreateAsset,
  Permission.ReadAsset,
  Permission.UpdateAsset,
  Permission.CreateCatalog,
  Permission.ReadCatalog,
  Permission.UpdateCatalog,
  Permission.DeleteCatalog,
  Permission.DeleteAsset,
  Permission.ReadCountry,
  Permission.CreateCustomer,
  Permission.ReadCustomer,
  Permission.UpdateCustomer,
  Permission.DeleteCustomer,
  Permission.CreateOrder,
  Permission.ReadOrder,
  Permission.UpdateOrder,
  Permission.DeleteOrder,
  Permission.ReadPaymentMethod,
  Permission.ReadShippingMethod,
  Permission.ReadZone,
  Permission.CreateProduct,
  Permission.UpdateProduct,
  Permission.ReadProduct,
  Permission.DeleteProduct,
  Permission.CreateSettings,
  Permission.ReadSettings,
  Permission.UpdateSettings,
  Permission.DeleteSettings,
  Permission.CreateCollection,
  Permission.ReadCollection,
  Permission.CreateCollection,
  Permission.DeleteCollection,
  Permission.CreateCustomerGroup,
  Permission.UpdateCustomerGroup,
  Permission.ReadCustomerGroup,
  Permission.DeleteCustomerGroup,
  Permission.CreateFacet,
  Permission.UpdateFacet,
  Permission.ReadFacet,
  Permission.DeleteFacet,
  Permission.CreatePromotion,
  Permission.UpdatePromotion,
  Permission.ReadPromotion,
  Permission.DeletePromotion,
  Permission.CreateTaxCategory,
  Permission.UpdateTaxCategory,
  Permission.ReadTaxCategory,
  Permission.DeleteTaxCategory,
  Permission.CreateTaxRate,
  Permission.UpdateTaxRate,
  Permission.ReadTaxRate,
  Permission.DeleteTaxRate,
  Permission.ReadChannel,
  Permission.UpdateChannel,
  Permission.CreateAdministrator,
  Permission.ReadAdministrator,
  Permission.UpdateAdministrator,
  Permission.DeleteAdministrator,
  Permission.CreatePaymentMethod,
  Permission.UpdatePaymentMethod,
  Permission.CreateShippingMethod,
  Permission.UpdatePaymentMethod
];

const CHANNEL_ADMIN_CODE = "channel-admin";

const CreateChannel: React.FC<ComponentProps> = ({ visible, setVisible }) => {
  const [form] = Form.useForm();
  const [createChannelMutation] = useCreateChannelMutation();
  const [createRoleMutation] = useCreateRoleMutation();
  const [createAdministratorMutation] = useCreateAdministratorMutation();
  const [setDefaultMutation] = useSetDefaultComplianceMutation();
  const [assignPaymentstoChannelMutation] = useAssignPaymentstoChannelMutation();
  const [createShippingsForChannelMutation] = useCreateShippingsForChannelMutation();
  
  
  const [createDefaultProductAttributes] = useCreateDefaultProductAttributesMutation();
  const [createDefaultPolicy] = useCreateDefaultPolicyMutation();

  const [ createDefaultPickupOptions ] = useCreateDefaultPickupOptionsMutation();

  const [ createDefaultTemplates ] = useCreateDefaultTemplatesMutation();

  const[loading,setLoading] = useState<boolean>(false);

  const submitFormHandler = async (formData: CreateChannelFormData): Promise<void> => {
    if(!formData.complianceBrandKey){
      message.error("Please enter compliance brand key under Brand Profile!");
      return;
    }
    if(!formData.orderPrefix){
      message.error("Please enter order prefix under Brand Profile!");
      return;
    }

    if(!formData.membershipLevel){
      message.error("Please enter membership level (cost) under Owner Profile!");
      return;
    }
    
    setLoading(true);
    await createChannel(formData)
      .then(async (channelId: string) => await createInternalCompliance(channelId))
      .then(async (channelId:string) => await assignPayments(channelId))
      .then(async (channelId:string) => await createShippings(channelId))
      .then(async (channelId:string) => await createPickups(channelId))
      .then(async (channelId:string) => await createTemplates(channelId))
      .then(async (channelId:string) => await createProductAttributes(channelId))
      .then(async (channelId:string) => await createPolicy(channelId))
      .then(async (channelId: string) => await createRole(channelId, formData))
      .then(async (roleId: string) => await createAdministrator(roleId, formData))
      
      .then((message: string) => notifySuccess(message))
      .catch((error: string) => {
        message.error(error)
        setLoading(false);
      });
    
  };

  const createChannel = (formData: CreateChannelFormData): Promise<string> => {
    return new Promise((resolve, reject) => {
      createChannelMutation({
        variables: { input: prepareCreateChannelInput(formData) },
        refetchQueries: ["GetChannels"],
      })
        .then(async ({ data }) => {
          if (data?.createChannel.__typename === "Channel")
            resolve(data.createChannel.id);
        })
        .catch((error) => reject(error.message));
    });
  };

  const prepareCreateChannelInput = (
    formData: CreateChannelFormData
  ): CreateChannelInput => {
    return {
      code: formData.brandName,
      token: formData.token,
      defaultLanguageCode: LanguageCode.En,
      pricesIncludeTax: true,
      currencyCode: CurrencyCode.Usd,
      defaultTaxZoneId: "1",
      defaultShippingZoneId: "1",
      customFields: {
        brandName: formData.brandName,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        membershipLevel: formData.membershipLevel.toString()||"",
        orderPrefix: formData.orderPrefix,
        complianceBrandKey: formData.complianceBrandKey,
      },
    };
  };

  const createInternalCompliance = (channelId:string): Promise<string> =>{
    return new Promise((resolve, reject) => {
      setDefaultMutation({
        variables:{
          channelId:channelId
        },
        refetchQueries:["GetComplianceList"]
      }
      ).then((data)=>{
        if(data.data?.setDefaultCompliance===true){
          resolve(channelId);
        }
      }).catch((error)=>{
        reject(error);
      })
    });
  }

  const assignPayments = (channelId:string): Promise<string> =>{
    return new Promise((resolve, reject) => {
      assignPaymentstoChannelMutation({
        variables:{
          channelId:channelId
        },
        refetchQueries:["GetComplianceList"]
      }
      ).then((data)=>{
        if(data.data?.assignPaymentstoChannel){
          resolve(channelId);
        }
      }).catch((error)=>{
        reject(error);
      })
    });
  }

  const createShippings = (channelId:string): Promise<string> =>{
    return new Promise((resolve, reject) => {
      createShippingsForChannelMutation({
        variables:{
          id: channelId
        },
        refetchQueries:["GetComplianceList"]
      }).then((data)=>{
        if(data.data?.createShippingsForChannel){
          resolve(channelId);
        }
      }).catch((error)=>{
        reject(error);
      })
    });
  }

  const createPickups = (channelId:string): Promise<string> =>{
    return new Promise((resolve, reject) => {
      createDefaultPickupOptions({
        variables:{
          id: channelId
        }
      }
      ).then((data)=>{
        if(data.data?.createDefaultPickupOptions){
          resolve(channelId);
        }
      }).catch((error)=>{
        reject(error);
      })
    });
  }

  const createTemplates = (channelId:string): Promise<string> =>{
    return new Promise((resolve, reject) => {
      createDefaultTemplates({
        variables:{
          id:channelId
        },
      }
      ).then((data)=>{
        if(data.data?.createDefaultTemplates){
          resolve(channelId);
        }
      }).catch((error)=>{
        reject(error);
      })
    });
  }

  const createProductAttributes = (channelId:string): Promise<string> =>{
    return new Promise((resolve, reject) => {
      createDefaultProductAttributes({
        variables:{
          id:channelId
        },
      }
      ).then((data)=>{
        if(data.data?.createDefaultProductAttributes){
          resolve(channelId);
        }
      }).catch((error)=>{
        reject(error);
      })
    });
  }

  const createPolicy = (channelId:string): Promise<string> =>{
    return new Promise((resolve, reject) => {
      createDefaultPolicy({
        variables:{
          id:channelId
        },
      }
      ).then((data)=>{
        if(data.data?.createPolicyPage){
          resolve(channelId);
        }
      }).catch((error)=>{
        reject(error);
      })
    });
  }

  const createRole = (channelId: string, formData: CreateChannelFormData): Promise<string> => {
    return new Promise((resolve, reject) => {
      createRoleMutation({
        variables: {
          input: {
            code: CHANNEL_ADMIN_CODE+"-"+formData.brandName+"-"+formData.token,
            description: CHANNEL_ADMIN_CODE+"-"+formData.brandName+"-"+formData.token,
            permissions: channelAdminRolePermissions,
            channelIds: [channelId],
          },
        },
      })
        .then(({ data }) => {
          if (data?.createRole.__typename === "Role")
            resolve(data.createRole.id);
        })
        .catch((error) => reject(error.message));
    });
  };

  const createAdministrator = (
    roleId: string,
    formData: CreateChannelFormData
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      createAdministratorMutation({
        variables: {
          input: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            emailAddress: formData.email,
            password: formData.password,
            roleIds: [roleId],
            customFields:{
              isStoreAdmin:true
            }
          },
        },
      })
        .then(({ data }) => {
          if (data?.createAdministrator.__typename === "Administrator")
            resolve("Store created");
        })
        .catch((error) => reject(error.message));
    });
  };
  

  const notifySuccess = (result: string) => {
    setLoading(false);
    message.success(result);
    setVisible(false);
    form.resetFields();
  };

  const generateToken = (): string => {
    const randomString = () => Math.random().toString(36).substr(3, 10);
    return `${randomString()}${randomString()}`;
  };

  const initialValues = { token: generateToken() };

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closeIcon={<Icon keyword="close" />}
      footer={false}
      width={662}
      centered
    >
      <ModalTitle>
        <H3>Create New Store</H3>
      </ModalTitle>
      <Form
        form={form}
        layout="vertical"
        onFinish={submitFormHandler}
        initialValues={initialValues}
      >
        <TwfCard style={{ padding: "4px 0 4px" }}>
            
                <Item
                  label="Brand"
                  name="brandName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the brand name!",
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="Store token"
                  name="token"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the store token!",
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="First name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the first name!",
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="Last name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the last name!",
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the email!",
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the password!",
                    },
                  ]}
                >
                  <Input type="password" />
                </Item>

                <Item label="Membership Level" name="membershipLevel"
                rules={[
                  {
                    required: true,
                    message: "Please enter the membership level!",
                  },
                ]}>
                  <Input type="number" />
                </Item>
                <Item label="Order Prefix" name="orderPrefix"
                rules={[
                  {
                    required: true,
                    message: "Please enter the order prefix!",
                  },
                ]}>
                  <Input />
                </Item>
                <Item label="Ship Compliance Brand Key" name="complianceBrandKey"
                rules={[
                  {
                    required: true,
                    message: "Please enter the ship compliance brand key!",
                  },
                ]}>
                  <Input />
                </Item>
                {/*
                <Item label="Ship Compliant Key" name="shipCompliantKey">
                  <Input disabled={true} value="Key" />
                </Item>
                */}
              
         
        </TwfCard>
        <MarginBox mt={48}>
          <Row gutter={12} justify="end">
            <Col>
              <TwfButton
                color-twf="gray-line"
                size-twf="md"
                type-twf="wide"
                onClick={() => {
                  setVisible(false);
                  form.resetFields();
                }}
              >
                Cancel
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                type="primary"
                color-twf="primary"
                size-twf="md"
                type-twf="wide"
                htmlType="submit"
                loading={loading}
              >
                Create
              </TwfButton>
            </Col>
          </Row>
        </MarginBox>
      </Form>
    </TwfModal>
  );
};

export const PanelContent = styled.div`
  padding: 20px;
`;

export default CreateChannel;
