import React from "react";
import TwfButton from "./index";
import Icon from "../../assets/icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Grid } from "antd";
const { useBreakpoint } = Grid;

interface ComponentProps {
  url?: string;
}

const BackButton: React.FC<ComponentProps> = ({ url }) => {
  const history = useNavigate();
  const breakpoints = useBreakpoint();
  return (
    <ButtonWrap
      shape="circle"
      type-twf="icon"
      onClick={() => {
        url ? history(url) : history(-1);
      }}
    >
      <Icon keyword="arrow-left" width={breakpoints.md ? 34 : 24} />
    </ButtonWrap>
  );
};

const ButtonWrap = styled(TwfButton)`
  background-color: transparent;
  :hover {
    background-color: transparent;
  }
`;

export default BackButton;
