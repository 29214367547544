import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthLayoutContainer from "../style/container";
import AuthLayoutCard from "../style/card";
import AuthLayoutWrap from "../style/wrap";
import { Form, Input, Alert, Row, Col } from "antd";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";
import TwfLink from "../../../component/button/link";
import { gql, useMutation } from "@apollo/client";
import { loginAction } from "../../../store/action/authenticate";
import { MarginBox } from "../../../component/margin";
import gulidImage from "./guild.png";
const { Item } = Form;

interface PageProps {}

const LOGIN_QUERY = gql`
  mutation login($username: String!, $password: String!, $rememberMe: Boolean) {
    login(username: $username, password: $password, rememberMe: $rememberMe) {
      ... on CurrentUser {
        id
        identifier
        channels {
          id
          code
          token
          permissions
        }
      }
    }
  }
`;

const TWF_AUTH_QUERY = gql`
  mutation authenticate($emailAddress: String!, $password: String!) {
    authenticate(
      input: {
        twfAdminAuth:{
          emailAddress: $emailAddress, 
          password: $password
        }
      }
    ) {
      ... on CurrentUser {
        id
        identifier
        channels {
          id
          code
          token
          permissions
        }
      }
    }
  }
`;

const LoginPage: FC<PageProps> = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState<String | null>(null);
  //const [login, { loading }] = useMutation(LOGIN_QUERY);

  const [authenticate, { loading }] = useMutation(TWF_AUTH_QUERY);
  const history = useNavigate();

  if (localStorage.getItem("vendure-token")) {
    localStorage.clear();
  }

  const onSubmitHandler = (value: any) => {
    let loginData = { ...value };
    /*login({ variables: loginData })
      .then(({ data }: any) => {
        if (data.login.__typename !== "CurrentUser") {
          setError("Username or Password Error!");
          return;
        }
        if (data.login.channels.length > 0)
          localStorage.setItem("vendure-token", data.login.channels[0].token);
        localStorage.setItem("used-channel", data.login.channels[0].code);
        dispatch(loginAction(data.login) as any);
        if (localStorage.getItem("used-channel") === "__default_channel__") {
          history("/brands");
        } else {
          history("/dashboard");
        }
      })
      .catch((error) => {
        const errors = error.graphQLErrors.map((error: any) => {
          return error.message;
        });
        setError(errors);
      });*/

      authenticate({ variables: loginData })
      .then(({ data }: any) => {
        if (data.authenticate.__typename !== "CurrentUser") {
          setError("Username or Password Error!");
          return;
        }
        if (data.authenticate.channels.length > 0)
          localStorage.setItem("vendure-token", data.authenticate.channels[0].token);
          localStorage.setItem("used-channel", data.authenticate.channels[0].code);
          dispatch(loginAction(data.authenticate) as any);
          if (localStorage.getItem("used-channel") === "__default_channel__") {
            history("/brands");
          } else {
            history("/dashboard");
          }
        })
        .catch((error) => {
          const errors = error.graphQLErrors.map((error: any) => {
            return error.message;
          });
          setError(errors);
      });
  };

  return (
    <Form layout="vertical" onFinish={onSubmitHandler}>
      <AuthLayoutContainer>
        <AuthLayoutCard>
          <AuthLayoutWrap>
            <MarginBox mb={34}>
              <img
                height={300}
                width={300}
                src={gulidImage}
                alt="the wine foundry gulid member area logo"
              />
            </MarginBox>
            {error && <Alert message={error} type="error" />}
            <Row>
              <Col span={24}>
                <Item
                  label="Email"
                  //name="username"
                  name="emailAddress"
                  rules={[
                    {
                      required: true,
                      //pattern:
                      //eslint-disable-next-line max-len
                      // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                      message: "Please enter valid email address!",
                    },
                  ]}
                >
                  <Input placeholder="your email address!" />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password",
                    },
                  ]}
                >
                  <Input placeholder="••••••••" type="password" />
                </Item>
              </Col>
            </Row>
            <Row align="middle" justify="space-between">
              {/*
              <Item name="rememberMe" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Item>
              <Item>
                <Link to="/forget">Forgot Password</Link>
              </Item>
              */}
            </Row>
            <MarginBox mt={32} mb={20}>
              <Row justify="center">
                <Col>
                  <Item>
                    <div>
                      <TwfButton
                        type="primary"
                        color-twf="primary"
                        size-twf="lg"
                        type-twf="wide"
                        htmlType="submit"
                        loading={loading}
                      >
                        Sign in
                        <Icon keyword="arrow-right" width={20} color="#fff" />
                      </TwfButton>
                    </div>
                  </Item>
                </Col>
              </Row>
              <TwfLink style={{ marginBottom:"10%", paddingBottom:"10%"}} to="/forget-password">Forgot Password?</TwfLink>
            </MarginBox>
            {/* <H3>WINE FOUNDERY MEMBER AREA</H3> */}
          </AuthLayoutWrap>
        </AuthLayoutCard>
      </AuthLayoutContainer>
    </Form>
  );
};

export default LoginPage;
