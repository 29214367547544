/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import { FunctionComponent, useEffect, useState } from "react";
import TwfButton from "component/button";
import { OperationCard, OperationProps } from "../conditions";
import { Row, Col, InputNumber } from "antd";
import { useGetShippingMethodListQuery } from "generated/graphql";

const FixShippingRateAction: FunctionComponent<OperationProps> = ({
  code,
  configArgs,
  onConfigArgChange,
  onRemove,
}) => {
  const { data } = useGetShippingMethodListQuery();
  const jsonString = configArgs.find((arg) => arg.name === "data")?.value;
  const [finalObject, setFinalObject] = useState({} as any);
  const [shippingMethods, setShippingMethods] = useState([] as string[]);
  const [shippingCosts, setShippingCosts] = useState([] as number[]);

  useEffect(() => {
    if (
      data &&
      data?.shippingMethods &&
      data.shippingMethods.totalItems !== 0
    ) {
      const enabledMethods = data.shippingMethods.items.filter(
        (method) => method.customFields?.enabled
      );
      const names = enabledMethods.map((method) => method.code);
      let shippingNames = [...new Set(names)];
      const nullIndex = shippingNames.indexOf("null-shipping-method");
      if (nullIndex !== -1) {
        shippingNames.splice(nullIndex, 1);
      }
      shippingNames = shippingNames.sort((a, b) => a.localeCompare(b));
      setShippingMethods(shippingNames);
      if (jsonString) {
        const finalString = jsonString.substring(1, jsonString.length - 1);
        const jsonObject = JSON.parse(finalString);
        setFinalObject(jsonObject);
        let jsonKeys = Object.keys(jsonObject);
        const difference = shippingNames.filter((x) => !jsonKeys.includes(x));
        jsonKeys.push(...difference);
        setShippingMethods(jsonKeys);
        jsonKeys = jsonKeys.sort((a, b) => a.localeCompare(b));
        const sCosts = [] as number[];
        jsonKeys.forEach((key) => {
          sCosts.push(jsonObject[key]);
        });
        setShippingCosts(sCosts);
      }
    }
  }, [data]);

  const convertToString = (key: string, value: string, index: number) => {
    finalObject[key] = value;
    const keys = Object.keys(finalObject);
    shippingCosts[index] = +value;
    setShippingCosts(shippingCosts);
    let newObject = Object.fromEntries(
      Object.entries(finalObject).filter(([_, v]) => v != null)
    );
    setFinalObject(newObject);
    const finalString = '"' + JSON.stringify(newObject) + '"';
    onConfigArgChange({ name: "data", value: finalString }, code);
  };

  return (
    <OperationCard
      title="Fix Flat Rate Discount"
      style={{ marginTop: 16 }}
      actions={[
        <TwfButton key="remove" type="link" onClick={() => onRemove(code)}>
          Remove
        </TwfButton>,
      ]}
    >
      <Row>
        <Col>
          <>
            Flat Rates (Keep Empty for No Change)
            <br/>
            Free Shipping (Input 0 for Free Shipping)
            {shippingMethods.map((method, index) => {
              return (
                <div key={index}>
                  <br />
                  <br />
                  {method}
                  {" : "}
                  <InputNumber
                    key={index}
                    min={0}
                    formatter={(value: any) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value: any) => {
                      // Handle empty input (null or undefined) and return 0
                      if (!value) {
                        return "0";
                      }
                      // Remove currency symbols and commas, then parse as a number
                      return value.replace(/\$\s?|(,*)/g, "");
                    }}
                    value={
                      shippingCosts[index] === null
                        ? undefined
                        : shippingCosts[index]
                    } // Use undefined for null values
                    onChange={(value: any) => {
                      convertToString(method, value, index);
                    }}
                    style={{ width: 160 }}
                  />
                </div>
              );
            })}
          </>
        </Col>
      </Row>
    </OperationCard>
  );
};

export default FixShippingRateAction;
