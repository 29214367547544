import React from "react";
import { useParams } from "react-router";
import { TwfCard } from "../../../component/card";
import { H3, H4 } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import { useGetClubStatQuery } from "generated/graphql";

interface Props {}

const ClubSingleStats: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const cid: string = id as string;
  const { data } = useGetClubStatQuery({
    variables: { id: cid },
  });

  return (
    <TwfCard style={{ padding: "32px 56px", minHeight: 600 }}>
      <MarginBox mb={32}>
        <MarginBox mb={8}>
          <H4>Total Members</H4>
        </MarginBox>
        <H3>{data?.clubStat?.totalMembers}</H3>
      </MarginBox>
      <MarginBox mb={32}>
        <MarginBox mb={8}>
          <H4>Active Members</H4>
        </MarginBox>
        <H3>{data?.clubStat?.activeMembers}</H3>
      </MarginBox>
      <MarginBox mb={32}>
        <MarginBox mb={8}>
          <H4>Cancelled Members</H4>
        </MarginBox>
        <H3>{data?.clubStat?.cancelledMembers}</H3>
      </MarginBox>
      <MarginBox mb={32}>
        <MarginBox mb={8}>
          <H4>Paused Members</H4>
        </MarginBox>
        <H3>{data?.clubStat?.pausedMembers}</H3>
      </MarginBox>
    </TwfCard>
  );
};

export default ClubSingleStats;
