import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const ContentViewIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 21C17.1046 21 18 20.1046 18 19C18 17.8954 17.1046 17 16 17C14.8954 17 14 17.8954 14 19C14 20.1046 14.8954 21 16 21Z"
      fill={color ? color : "#000000"}
    />
    <path
      d="M23.777 18.479C23.1591 16.9049 22.0929 15.5467 20.7105 14.5728C19.3281 13.5988 17.6902 13.052 16 13C14.3098 13.052 12.6719 13.5988 11.2895 14.5728C9.9071 15.5467 8.84088 16.9049 8.223 18.479L8 19L8.223 19.521C8.84088 21.0951 9.9071 22.4533 11.2895 23.4272C12.6719 24.4012 14.3098 24.948 16 25C17.6902 24.948 19.3281 24.4012 20.7105 23.4272C22.0929 22.4533 23.1591 21.0951 23.777 19.521L24 19L23.777 18.479ZM16 23C15.2089 23 14.4355 22.7654 13.7777 22.3259C13.1199 21.8864 12.6072 21.2616 12.3045 20.5307C12.0017 19.7998 11.9225 18.9956 12.0769 18.2196C12.2312 17.4437 12.6122 16.731 13.1716 16.1716C13.731 15.6122 14.4437 15.2312 15.2196 15.0769C15.9956 14.9225 16.7998 15.0017 17.5307 15.3045C18.2616 15.6072 18.8864 16.1199 19.3259 16.7777C19.7654 17.4355 20 18.2089 20 19C19.9987 20.0605 19.5768 21.0771 18.827 21.827C18.0771 22.5768 17.0605 22.9987 16 23Z"
      fill={color ? color : "#000000"}
    />
    <path
      d="M27 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V27C3 27.5304 3.21071 28.0391 3.58579 28.4142C3.96086 28.7893 4.46957 29 5 29H27C27.5304 29 28.0391 28.7893 28.4142 28.4142C28.7893 28.0391 29 27.5304 29 27V5C29 4.46957 28.7893 3.96086 28.4142 3.58579C28.0391 3.21071 27.5304 3 27 3ZM5 5H27V9H5V5ZM5 27V11H27V27H5Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);
export default ContentViewIcon;
