import { Col, Dropdown, Menu, Row, Space, Table } from "antd";
import { FC, Fragment, useState } from "react";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import ShadowCard from "../../../component/card";
import { PageTitle } from "../../../component/header";
import NewClub from "./new-club";

const { Item } = Menu;

const pageData = [
  {
    key: 1,
    name: "Joie Case Club",
    option: "Read Only",
    signupDate: "06/07/2021",
    cancelDate: "06/08/2021",
    totalShipment: 104,
  },
];

const ClubListPage: FC = () => {
  const [newVisible, setNewVisible] = useState<boolean>(false);
  const clubAction = () => (
    <Menu style={{ borderRadius: "12px", border: "1px solid #E5E5E5" }}>
      <Item key="skip">Skip Shipment</Item>
      <Item key="pause">Pause Membership</Item>
      <Item key="cancel">Cancel Membership</Item>
      <Item key="message">Send Message</Item>
    </Menu>
  );

  const columns: any = [
    {
      title: "Club Name",
      dataIndex: "name",
    },
    {
      title: "Club Option",
      dataIndex: "option",
    },
    {
      title: "Signup Data",
      dataIndex: "signupDate",
    },
    {
      title: "Cancel Date",
      dataIndex: "cancelDate",
    },
    {
      title: "Total Shipment",
      dataIndex: "totalShipment",
      render: (price: number) => (
        <Fragment>$ {price ? price.toFixed(2) : ""}</Fragment>
      ),
    },
    {
      title: "Action",
      render: (data: any) => {
        console.log(data);
        return (
          <Fragment>
            <Dropdown overlay={clubAction}>
              <TwfButton type="default" size-twf="sm">
                Club Actions
                <Icon keyword="chevron-down" color="#7D8592" />
              </TwfButton>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];
  return (
    <Fragment>
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <Row align="middle" justify="space-between">
          <Col>
            <PageTitle>Club List</PageTitle>
          </Col>
          <Col>
            <TwfButton
              type="primary"
              size-twf="md"
              type-twf="wide"
              onClick={() => {
                setNewVisible(true);
              }}
            >
              <Icon keyword="plus" color="#ffffff" />
              Add New Club
            </TwfButton>
          </Col>
        </Row>
        <ShadowCard>
          <Table
            columns={columns}
            dataSource={pageData}
            pagination={false}
            scroll={{ x: 600 }}
          />
        </ShadowCard>
      </Space>
      <NewClub visible={newVisible} setVisible={setNewVisible} />
    </Fragment>
  );
};

export default ClubListPage;
