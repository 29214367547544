import React, { useRef } from "react";
import { Col, Row } from "antd";
import Icon from "../../../assets/icons";
import TwfModal from "../../../component/modal";
import InvoiceDocument from "component/invoice";
import ReactToPrint from "react-to-print";
import { H3 } from "../../../component/header";
import ModalTitle from "../../../component/modal/title";
import { useGetActiveChannelQuery, useGetOrderQuery } from "generated/graphql";
import TwfButton from "./../../../component/button/index";

interface ViewProps {
  selectedId?: any;
  invoiceVisible?: boolean;
  setInvoiceVisible: (value: boolean) => void;
}

const InvoiceView: React.FC<ViewProps> = ({
  selectedId,
  invoiceVisible,
  setInvoiceVisible,
}) => {
  const invoiceRef = useRef<any>(null);
  const { data: orderResult } = useGetOrderQuery({
    variables: {
      id: selectedId,
    },
  });
  const { data: channelResult } = useGetActiveChannelQuery();

  return (
    <div style={{ overflowX:'scroll' }} className='no-scroll-bar'>
      <TwfModal
        width="1000px"
        style={{
          minWidth:'1000px'
        }}
        visible={invoiceVisible}
        footer={false}
        onCancel={() => {
          setInvoiceVisible(false);
        }}
        closeIcon={<Icon keyword="close" width={24} color="#0A1629" />}
      >
        <ModalTitle>
          <Row gutter={21} align="middle">
            <Col>
              <H3>ORDER INVOICE</H3>
            </Col>
          </Row>
        </ModalTitle>
        <InvoiceDocument
          ref={invoiceRef}
          order={orderResult?.order}
          channel={channelResult?.activeChannel}
        />
        <ReactToPrint
          trigger={() => (
            <TwfButton color-twf="primary" type="primary" size-twf="sm">
              Print
            </TwfButton>
          )}
          content={() => invoiceRef.current}
        />
      </TwfModal>
    </div>
  );
};

export default InvoiceView;
