import styled from "styled-components";

const AuthLayoutColumns = styled.div`
  display: grid;
  grid-template-columns: 346px auto;
  grid-gap: 30px;
  height: 100%;
  @media (max-width: 991px) {
    display: block;
  }
`;

export default AuthLayoutColumns;
