import React, { useEffect, useState } from "react";
import { Modal, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { useDispatch } from "react-redux";
import { productAssetsAction } from "store/action/ui.action";
import { AppDispatch } from "store";
import { useSelector } from 'react-redux';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

// interface Props {
//   assets: Asset[];
//   onRemove: (items: string[]) => void;
// }

const ImageWallCreate: React.FC = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>();
  const dispatch: AppDispatch = useDispatch();
  const selectedProductAssets = useSelector((state: { ui: { createProductAssets: any[] } }) => state.ui.createProductAssets);
  const handleCancel = () => setPreviewOpen(false);

  useEffect(() => {
    const mappedAssets =
    selectedProductAssets?.map((asset:any) => ({
        uid: asset.id,
        name: asset.name,
        status: "done" as const,
        url: asset.preview,
      })) || [];

    setFileList(mappedAssets);
  }, [selectedProductAssets]);

  //  dispatch(imageCounterAction(fileList?.length))
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  // const uploadButton = (
  //   <div>
  //     <PlusOutlined />
  //     <div style={{ marginTop: 8 }}>Upload</div>
  //   </div>
  // );

  const handleRemove = (file: any) => {
    console.log(file.uid)
    console.log(selectedProductAssets)
    dispatch(productAssetsAction(selectedProductAssets.filter((item:any)=> item.id !== file.uid)))
  };


  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleRemove}
      ></Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default ImageWallCreate;
