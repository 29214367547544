import { message, Table } from "antd";
import TwfButton from "component/button";
import { useDeleteAdministratorMutation, useUpdateAdministratorMutation } from "generated/graphql";
import React, { useEffect, useState } from "react";


interface UserManagementPanelProps {
  account?: any;
  columns: any[];
  administrators: any;
  isMain: boolean;
  role: any;
}

const UserManagementSetting: React.FC<UserManagementPanelProps> = ({
  columns,
  administrators,
  isMain,
  role
}) => {

  const [dataforadmins, setDataForAdmins] = useState<any>([]);

  const [deleteAdministrator] = useDeleteAdministratorMutation();
  const [updateAdministrator] = useUpdateAdministratorMutation();
  
  useEffect(()=>{
    const dt = [] as any;
    if(administrators){
      if(administrators.items.length!==0){
        for(let i=0;i<administrators.items.length;i++){
          const adm = administrators.items[i];
          if(role?.code==="__super_admin_role__"){
            createData(adm,dt);
          }else{
            const rolearr = adm.user.roles.map((role:any)=>role.id);
            if(rolearr.indexOf(role?.id)!==-1){
                createData(adm,dt);
            }
          }
        }
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[administrators,role])

  const deleteit = (id:string)=>{
    deleteAdministrator({
      variables:{
        id
      },
      refetchQueries:["GetAdministrators"]
    }).then((data)=>{
      if(data.data?.deleteAdministrator.__typename==="DeletionResponse"){
        message.success("Successfully deleted admin");
      }
    }).catch((error)=>{
      message.error(error.message);
    })
  }

  const _toggleit = (adm:any,state:boolean)=>{
    const roleIds = adm.user.roles.map(((role:any)=>role.id));
    updateAdministrator({
      variables:{
        input:{
          id:adm.id,
          emailAddress: adm.emailAddress,
          firstName: adm.firstName,
          lastName: adm.lastName,
          password: "",
          customFields:{
            isStoreAdmin:state
          },
          roleIds
        }
      },
      refetchQueries:["GetAdministrators"]
    }).then((data)=>{
      if(data.data?.updateAdministrator.__typename==="Administrator"){
        message.success("Successfully updated admin");
      }
    }).catch((error)=>{
      message.error(error.message);
    })
  }

  const createData = (adm:any, dt:any[])=>{
    const c = {
      key: adm.id+"delete",
      id: adm.id,
      option: "",
      saveTo: "",
      value: "",
      render: () => (
        <div
          style={{
            marginTop:"2%",
            marginBottom:"2%",
          }}
          className="delete-button"
        >
          <TwfButton color-twf="primary" onClick={()=>{
            deleteit(adm.id)
          }} size-twf="md" disabled={adm.emailAddress==="superadmin"}>Delete</TwfButton>
        </div>
      ),
      readonly: true,
    };
    const d = {
        key: adm.id+"firstName",
        id: adm.id,
        option: "First Name",
        saveTo: "oneaccount",
        value: adm.firstName,
        readonly: isMain===true? null:true,
      };
      const e = {
        key: adm.id+"lastName",
        id: adm.id,
        option: "Last Name",
        saveTo: "oneaccount",
        value: adm.lastName,
        readonly: isMain===true? null:true,
      };
      const f = {
        key: adm.id+"emailAddress",
        id: adm.id,
        option: "Email Address",
        saveTo: "oneaccount",
        value: adm.emailAddress,
        readonly: isMain===true? null:true,
      };

      /*const f1 = {
        key: adm.id+"isStoreAdmin",
        id: adm.id,
        option: "Store Admin",
        saveTo: "oneaccount",
        value: String(adm.customFields.isStoreAdmin),
        render: () => (
          <div
            style={{
              marginTop:"2%",
              marginBottom:"2%",
            }}
          >
            <Checkbox checked={adm.customFields.isStoreAdmin} onChange={(e)=>{
              toggleit(adm,e.target.checked)
            }}></Checkbox>
          </div>
        ),
        readonly: true,
      };*/

      const g = {
        key: adm.id+"password",
        id: adm.id,
        option: "Password",
        saveTo: "oneaccount",
        readonly: isMain===true? null:true,
      };
      const w = {
        key: adm.id+"none",
        readonly: true,
      };
      dt.push(c,d,e,f,g,w);
      setDataForAdmins(dt);
    }

    useEffect(()=>{
      const deleteButtons = document.querySelectorAll(".delete-button");
      if(deleteButtons.length!==0){
        for (let [i,_deleteButton] of deleteButtons.entries()){
          if( i%2 === 0){
            deleteButtons[i].setAttribute("style","display:none;");
          }
        }
      }
    },[dataforadmins])
  
  return (
    <Table
      columns={columns}
      dataSource={dataforadmins}
      pagination={false}
      showHeader={false}
    />
  );
};

export default UserManagementSetting;
