import React, { useEffect, useState } from "react";
import { Col, Form, Input, Select, Row, Checkbox } from "antd";
import { 
  useGetShippingMethodOperationsQuery,
  useCreateShippingMethodMutation,
  CreateShippingMethodInput,
  useGetShippingMethodListQuery
 } from "generated/graphql";
import TextEditor from "component/textEditor/textEditor";
import TwfButton from "component/button";
import { message } from "antd/es";
import { useNavigate } from "react-router-dom";

const { Item } = Form;

const ShippingGeneral: React.FC = () => {

  const history = useNavigate();

  const { data: GetShippingMethodListQuery } = useGetShippingMethodListQuery();

  useEffect(()=>{
    if(GetShippingMethodListQuery?.shippingMethods.totalItems!==0){
      history("/shipping");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[GetShippingMethodListQuery])

  const fulfillmentOptions: any[] | undefined = [];
  const eligibilityCheckers: any[] | undefined = [
    {
      value:null,
      label:"Select"
    }
  ];
  const shippingCalculators: any[] | undefined = [
    {
      value: null,
      label:"Select"
    }
  ];

  const shippingMethodCodes: any[] | undefined = [
    {
      value: null,
      label: "Select"
    }
  ]

  let smcfinal: any[] = [];


  const[defaultcriteria,setDefaultCriteria] = useState<boolean>(false);
  const[shipmentdefaultcriteria,setShipmentDefaultCriteria] = useState<boolean>(false);
  const[defaultcalculator,setDefaultCalculator] = useState<boolean>(false);
  const[twfcalculator,setTwfCalculator] = useState<boolean>(false);
  const[isDisabled, setIsDisabled] = useState<boolean>(false);
  const[isDefault,setIsDefault] = useState<boolean>(false);
  const[checkerDisable, _setCheckerDisable] = useState<boolean>(false);

  const { data } = useGetShippingMethodOperationsQuery();

  const [createShippingMethodMutation] = useCreateShippingMethodMutation();

  if(data){
    if(data?.fulfillmentHandlers && data?.fulfillmentHandlers?.length!==0){
      data.fulfillmentHandlers.forEach((handler)=>{
        const label = handler.code+": "+handler.description;
        const val = handler.code;
        fulfillmentOptions.push({
          value:val,
          label:label
        })
      });
    }

    if(fulfillmentOptions.length!==0){
      fulfillmentOptions[0].label = "Shipping Compliance Fulfillment";
    }


    if(data?.shippingEligibilityCheckers && data?.shippingEligibilityCheckers?.length!==0){
      data.shippingEligibilityCheckers.forEach((checker)=>{
        const label = checker.description;
        const val = checker.code;
        if(val!=="compliance-shipment-checker"){
          eligibilityCheckers.push({
            value:val,
            label:label
          })
        }
      });
    }

    let twfargs: any[] = [];

      if(data?.shippingCalculators && data?.shippingCalculators?.length!==0){
        data.shippingCalculators.forEach((calculator)=>{
          const label = calculator.description;
          const val = calculator.code;
          shippingCalculators.push({
            value:val,
            label:label
          })
          if(calculator.code==="twf-shipping-calculator"){
            twfargs = calculator.args[0].ui.options
          }
        });
    }
    
    if(twfargs.length!==0){
      twfargs.forEach((val)=>{
        if(val && Object.keys(val).length!==0){
          shippingMethodCodes.push({
            value:val.value,
            label:val.value
          })
        }
      })

      smcfinal = shippingMethodCodes.filter((obj, pos, arr) => {
        return arr
          .map(mapObj => mapObj.value)
          .indexOf(obj.value) === pos;
      });
    }
  }

  const shippingCriteria = (e:any)=>{
    switch(e){
      case "default-shipping-eligibility-checker":
        setDefaultCriteria(true);
        setShipmentDefaultCriteria(false);
        break;
      case "compliance-shipment-checker":
        setDefaultCriteria(false);
        setShipmentDefaultCriteria(true);
        break;
      default:
        setDefaultCriteria(false);
        setShipmentDefaultCriteria(false);
        break;
    }
  }

  const shippingCalculator = (e:any)=>{
    switch(e){
      case "default-shipping-calculator":
        setDefaultCalculator(true);
        setTwfCalculator(false);
        setIsDisabled(false);
        break;
      case "twf-shipping-calculator":
        setDefaultCalculator(false);
        setTwfCalculator(true);
        setIsDisabled(true);
        break;
      default:
        setDefaultCalculator(false);
        setTwfCalculator(false);
        setIsDisabled(false);
        break;
    }
  }

  const submitFormHandler  = (formData:any) => {
    if(formData["checker-code"]==="always-shipping"){
      formData.checker = {};
      formData.checker.arguments = [];
      formData.checker.code = formData["checker-code"];
      delete formData["checker-code"];
    }
    if(formData.orderMinimum){
      formData.checker = {};
      formData.checker.arguments = [];
      formData.checker.code = formData["checker-code"];
      formData.checker.arguments.push({
        name:"orderMinimum",
        value:(Number(formData.orderMinimum)*100).toString()
      })
      delete formData.orderMinimum;
      delete formData.shippingCode;
      delete formData["checker-code"];
    }
    if(formData.shippingCode){
      formData.checker = {};
      formData.checker.arguments = [];
      formData.checker.code = formData["checker-code"];
      formData.checker.arguments.push({
        name:"shippingCode",
        value:formData.shippingCode
      })
      delete formData.orderMinimum;
      delete formData.shippingCode;
      delete formData["checker-code"];
    }
    if(formData.rate){
      formData.calculator = {};
      formData.calculator.arguments = [];
      formData.calculator.code = formData["calculator-code"];
      formData.calculator.arguments.push(
      {
        name:"rate",
        value:(Number(formData.rate)*100).toString()
      },
      {
        "name": "includesTax",
        "value": "auto"
      },
      {
        "name": "taxRate",
        "value": "0"
      }
      )
      delete formData.rate;
      delete formData["calculator-code"];
    }

    formData.customFields = {
      "isdefault": isDefault
    }

    if(formData["calculator-code"]==="twf-csv-shipping-calculator"){
      formData.calculator = {};
      formData.calculator.arguments = [];
      formData.calculator.code = formData["calculator-code"];
      delete formData["calculator-code"];
    }

    if(formData["calculator-code"]==="twf-shipping-calculator"){
      const maxAmount = [];
      const minAmount = [];
      const price = [];
      if(typeof formData.minAmount==="string"){
        minAmount.push(formData.minAmount).toString()
      }
      if(typeof formData.maxAmount==="string"){
        maxAmount.push(formData.maxAmount).toString()
      }
      if(typeof formData.price==="string"){
        price.push((formData.price)*100).toString()
      }
      formData.calculator = {};
      formData.calculator.arguments = [];
      formData.calculator.code = formData["calculator-code"];
      formData.calculator.arguments.push(
        {
          "name":"method",
          "value":formData.method
        },
        {
          "name": "minAmount",
          "value": JSON.stringify(minAmount)
        },
        {
          "name": "maxAmount",
          "value": JSON.stringify(maxAmount)
        },
        {
          "name": "price",
          "value": JSON.stringify(price)
        },
        {
          "name": "avgCharge",
          "value": (Number(formData.avgCharge)*100).toString() || "-1"
        }
      )

      delete formData.method;
      delete formData.minAmount;
      delete formData.maxAmount;
      delete formData.price;
      delete formData.avgCharge;
      delete formData["calculator-code"];
      formData.customFields = {
        "isdefault": false
      }
    }

    formData.translations = [];
    formData.translations.push({
      "languageCode": "en",
      "name": formData.name,
      "description": formData.description || "",
      "customFields": {}
    })
    delete formData.name;
    delete formData.description;
    delete formData.isDefault; 
    createShipping(formData);
  }

  const createShipping = (formData: CreateShippingMethodInput) =>{
    createShippingMethodMutation({
      variables:{
        input:formData
      },
      refetchQueries:["GetShippingMethodList"]
    }).then((data)=>{
      if(data.data?.createShippingMethod.__typename==="ShippingMethod"){
        message.success("Shipping Method Successfully Created");
        history(`/shipping`);
       
      }
    }).catch((error)=>{
      message.error(error);
    })
  }
  
  return (
    <Row >
      <Col>
      <Form id="shippingForm"
      layout="vertical" onFinish={submitFormHandler}>
        <div style={{ minHeight: 400, width:"700px" }}>
          <Item name="name" label="Name" rules={[
            {
              required: true,
              message: "Please enter the name!",
            },
          ]}>
            <Input />
          </Item>
          <Item name="code" label="Code" rules={[
            {
              required: true,
              message: "Please enter the code!",
            },
          ]}>
            <Input />
          </Item>
          <Item name="description" label="Description">
          <TextEditor />
          </Item>
          <Item name="fulfillmentHandler" label="Fulfillment Handler"
          rules={[
            {
              required: true,
              message: "Please select fulfillment handler!",
            },
          ]}>
          <Select placeholder="Select" options={fulfillmentOptions}>
            </Select>
          </Item>
          <br/>

          <Row>
            <Col span={11} >
            
            <Item label="Is Default?" name="isDefault" style={{ marginLeft:"5px" }}>
              &nbsp;<Checkbox onChange={(e)=>{
                setIsDefault(e.target.checked)
              }} defaultChecked={false} disabled={isDisabled}></Checkbox>
            </Item>
            
            </Col>
          </Row>
          <br/>
          
          <Row>
            <Col span={11} style={{ marginRight:"20px" }}>
            <Item label="Shipping Eligibility Checker" name="checker-code"
            rules={[
              {
                required: true,
                message: "Please select shipping eligibility checker criteria!",
              },
            ]}>
              <Select disabled={checkerDisable} onChange={shippingCriteria} placeholder="Select" options={eligibilityCheckers}></Select>
              </Item>
            </Col>
            <Col span={11}>
            <Item label="Shipping Calculator" name="calculator-code"
            rules={[
              {
                required: true,
                message: "Please select a shipping calculator",
              },
            ]}>
              <Select onChange={shippingCalculator} placeholder="Select" options={shippingCalculators}></Select>
              </Item>
            </Col>
          </Row>
          
          <Row>
            <Col span={11}>
              <div className="defaultcriteria" hidden={!defaultcriteria}>
              <Item label="Minimum order value" name="orderMinimum"
              rules={[
                {
                  required: defaultcriteria,
                  message: "Please enter the minimum order value!",
                },
              ]}>
                <Input placeholder="$" type="number" step="0.01"/>
              </Item>
              </div>

              <div className="shipmentdefaultcriteria" hidden={!shipmentdefaultcriteria}>
                <Item label="Shipping Code" name="shippingCode" rules={[
                  {
                    required: shipmentdefaultcriteria,
                    message: "Please enter the shipping code!",
                  },
                  ]}>
                  <Input/>
                  </Item>
              </div>

            </Col>
            <Col span={11} style={{marginLeft:"20px"}} >
              
              <div className="defaultcalculator" hidden={!defaultcalculator}>
                <Item label="Shipping Price" name="rate"
                rules={[
                  {
                    required: defaultcalculator,
                    message: "Please enter the shipping price!",
                  },
                ]}>
                  <Input placeholder="$" type="number" step="0.01"/>
                  </Item>
                  </div>
            </Col>
        
            <Col span={20} style={{marginLeft:"340px"}} >
              <div className="twfcalculator" hidden={!twfcalculator}>

              <Item label="Shipping Method" name="method"
                rules={[
                  {
                    required: twfcalculator,
                    message: "Please select a shipping method!",
                  },
                ]}>
                  <Select placeholder="Select Shipping Method" options={smcfinal}></Select>
              </Item>
              
              <div style={{display:"flex",justifyContent: "space-between"}} className="twfcalculator-list">
                <Item label="Min Amount" name="minAmount"
                rules={[
                  {
                    required: twfcalculator,
                    message: "Please enter the minimum amount!",
                  },
                ]}
                style={{marginRight:"5px"}}>
                  <Input placeholder="" type="number"/>
                </Item>
                
                <Item label="Max Amount" name="maxAmount"
                rules={[
                  {
                    required: twfcalculator,
                    message: "Please enter the maximum amount!",
                  },
                ]} style={{marginRight:"5px"}}>
                  <Input placeholder="" type="number"/>
                </Item>

                <Item label="Price" name="price"
                rules={[
                  {
                    required: twfcalculator,
                    message: "Please enter the price!",
                  },
                ]}>
                  <Input placeholder="$" type="number" step="0.01"/>
                </Item>
                
             </div>

              <Item label="Average Charge on Additional Items (Optional)" name="avgCharge"
              rules={[
                {
                  required: twfcalculator,
                  message: "Please enter the additional charge!",
                },
              ]}>
                  <Input placeholder="$" type="number" step="0.01"/>
                </Item>

              </div>

              
            </Col>
            

          </Row>
        </div>

        <TwfButton
            type="primary"
            color-twf="primary"
            size-twf="md"
            type-twf="wide"
            htmlType="submit"
            >
              Save
              </TwfButton>
      </Form>
      </Col>
    </Row>
  );
};

export default ShippingGeneral;
