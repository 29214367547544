import React, { useEffect, useState } from "react";
import Icon from "../../../assets/icons";
import TwfModal from "../../../component/modal/index";
import { AssetThumbnail, UploadButton } from "./style";
import { Col, message, Row } from "antd";
import { Asset, useUpdateProductVariantsMutation } from "generated/graphql";
import AssetList from "./asset-list";
import Container from "component/container";
import ImageWall from "../image-wall/update";
import { useParams } from "react-router-dom";

interface ComponentProps {
  onAssetSelect: (item: Asset) => void;
  assets?: Asset[];
  multi: boolean;
}

const AssetSelector: React.FC<ComponentProps> = ({
  onAssetSelect,
  assets,
  multi,
}) => {
  const [visible, setVisible] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<Asset>();
  const [updateProductVariantsMutation, { loading }] =
    useUpdateProductVariantsMutation();
  const { id } = useParams<{ id: string }>();



  useEffect(() => {
    if (assets && assets.length > 0) setSelectedAsset(assets[0]);
  }, [assets]);

  const submitHandler = () => {
    if (selectedAsset) {
      setVisible(false);
      if (!multi) {
        onAssetSelect(selectedAsset);
      }

      if (multi) {
        const assetIdsToKeep = assets?.map((asset) => asset.id) || [];
        updateProductVariantsMutation({
          variables: {
            input: {
              id: id as string,
              assetIds: [...assetIdsToKeep, selectedAsset.id],
            },
          },
          refetchQueries: ["GetProductVariant", "GetProductWithVariants"],
        });
      }
    } else {
      message.warning("No asset is selected");
    }
  };

  return (
    <Container>
      <Row gutter={[8, 8]}>
        <Col>
          {selectedAsset && !multi ? (
            <AssetThumbnail>
              <img src={selectedAsset.preview} alt={selectedAsset.name} />
            </AssetThumbnail>
          ) : null}
          {multi ? <ImageWall /> : null}
        </Col>
        <Col>
          {assets?.length! < 5 ? (
            <UploadButton
              color-twf="primary"
              type="default"
              size-twf="sm"
              type-twf="normal"
              onClick={() => setVisible(true)}
              style={{ height: 100, padding: 25 }}
            >
              <Icon keyword="plus" width={12} color="#333" />
              Image
            </UploadButton>
          ) : null}
          {!multi ?   <UploadButton
              color-twf="primary"
              type="default"
              size-twf="sm"
              type-twf="normal"
              onClick={() => setVisible(true)}
              style={{ height: 100, padding: 25 }}
            >
              <Icon keyword="plus" width={12} color="#333" />
              Image
            </UploadButton> :null}
        </Col>
      </Row>
      <TwfModal
        open={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={submitHandler}
        width="100%"
        style={{ maxWidth: "1500px" }}
      >
        <AssetList isModal onAssetSelect={setSelectedAsset} />
      </TwfModal>
    </Container>
  );
};

export default AssetSelector;
