import React, { useState, useEffect } from "react";
import { TwfCard } from "../../../component/card";
import { MarginBox } from "../../../component/margin";
import {
  Col,
  message,
  Popconfirm,
  Row,
  Table,
  TablePaginationConfig,
  Dropdown,
  Menu,
  Checkbox,
} from "antd";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  DeletionResult,
  Season,
  SeasonProductVariant,
  SortOrder,
  useGetSeasonListForClubLazyQuery,
  useUpdateSeasonMutation,
} from "generated/graphql";
import { useNavigate, useParams } from "react-router";
import { TableOptions } from "../components/customerList";
import ClubEditSeason from "./editSeason";
import { useDeleteSeasonMutation } from "./../../../generated/graphql";
import { SeasonProcessState } from "enums/enums";

const ClubSingleSeasons: React.FC = () => {
  const history = useNavigate();
  const breakpoints = useBreakpoint();
  const { id } = useParams<{ id: string }>();
  const cid: string = id as string;
  const [getSeasonListQuery, { data, loading }] =
    useGetSeasonListForClubLazyQuery();
  const [deleteSeasonMutation] = useDeleteSeasonMutation();
  const [updateSeasonMutation] = useUpdateSeasonMutation();

  const [seasonIdToEdit, setSeasonIdToEdit] = useState<string>("");
  const [updateSeasonVisible, setUpdateSeasonVisible] = useState(false);
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    filter: {},
    skip: 0,
    take: 10,
    sort: { id: SortOrder.Desc },
  });

  useEffect(() => {
    getSeasonListQuery({
      variables: { clubID: cid, options: tableOptions },
    });
  }, [tableOptions, getSeasonListQuery, cid]);

  const seasonDeleteHandler = (id: string) => {
    deleteSeasonMutation({
      variables: { id },
      refetchQueries: ["getSeasonListForClub"],
    })
      .then(({ data }) => {
        if (data?.deleteSeason.result === DeletionResult.Deleted) {
          message.success("Season deleted");
        }
      })
      .catch((error) => message.error(error.message));
  };

  const tableChangeHandler = (
    pagination: TablePaginationConfig,
    filters: any,
    sort: any
  ) => {
    setTableOptions({
      filter: tableOptions.filter,
      skip: (pagination.pageSize || 10) * ((pagination.current || 1) - 1),
      take: pagination.pageSize || 10,
      sort: sort.field
        ? {
            [sort.field]:
              sort.order === "ascend" ? SortOrder.Asc : SortOrder.Desc,
          }
        : tableOptions.sort,
    });
  };

  const changeSeasonStatus = (event: any, seasonID: string) => {
    const status = event.target.checked;
    updateSeasonMutation({
      variables:{
        input:{
          id: seasonID,
          active: status
        }
      },
      refetchQueries:["getSeasonListForClub"]
    }).then((data)=>{
      if(data?.data?.updateSeason){
        message.success("Successfully updated season status");
      }
    }).catch((error)=>{
      message.error(error.message);
    })
    console.log(seasonID);
  }

  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Products",
      dataIndex: "seasonProductVariants",
      width: 420,
      align: "center",
      render: (variants: SeasonProductVariant[]) =>
        variants
          .map(
            (variant) =>
              `${variant.productVariant.product.name} ${variant.productVariant.name}`
          )
          .join(", "),
    },
    {
      title: "Process Status",
      align: "center",
      render: ({ seasonProcess }: Season) =>
        seasonProcess?.status === SeasonProcessState.NotProcessed
          ? "Not processed"
          : seasonProcess?.status === SeasonProcessState.Scheduled
          ? "Scheduled for " +
            new Date(seasonProcess?.shipDate).toLocaleDateString()
          : "Processed",
    },
    {
      title: "Active",
      align: "center",
      render: (season: Season) => {
        //return season.active ? "Active": "Inactive"
        return (
          <Checkbox
          checked={season.active}
          onChange={(e) => {
            changeSeasonStatus(e, season.id);
          }}
        ></Checkbox>
        );
      },
    },
    {
      title: "",
      fixed: "right",
      dataIndex: "id",
      width: breakpoints.md ? 220 : 90,
      render: (id: string) => {
        return (
          <>
            <Row justify="center">
              <Col>
                <Dropdown
                  placement="bottomRight"
                  overlay={
                    <Menu style={{ borderRadius: 12 }}>
                      <Menu.Item
                        key="process"
                        onClick={() => {
                          history("/club/season/" + id);
                        }}
                      >
                        Process season
                      </Menu.Item>
                      <Menu.Item
                        key="edit"
                        onClick={() => {
                          setSeasonIdToEdit(id);
                          setUpdateSeasonVisible(true);
                        }}
                      >
                        Edit season
                      </Menu.Item>
                      <Popconfirm
                        title="Are you sure to delete this season?"
                        onConfirm={() => seasonDeleteHandler(id)}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Menu.Item key="delete">Delete season</Menu.Item>
                      </Popconfirm>
                    </Menu>
                  }
                >
                  <TwfButton shape="circle" type-twf="icon">
                    <Icon keyword="collapse" width={breakpoints.md ? 18 : 16} />
                  </TwfButton>
                </Dropdown>
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  return (
    <>
      <TwfCard style={{ minHeight: 600 }}>
        <MarginBox mt={12} mb={56}>
          <Table
            scroll={{ x: 900 }}
            columns={columns}
            dataSource={data?.seasons.items.map((obj) => ({
              ...obj,
              key: obj.id,
            }))}
            pagination={{
              pageSize: tableOptions.take,
              current: tableOptions.skip / tableOptions.take + 1,
              total: data?.seasons.totalItems,
              position: ["bottomCenter"],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} Items`,
              showSizeChanger: true,
            }}
            onChange={tableChangeHandler}
            loading={loading}
          />
        </MarginBox>
      </TwfCard>
      <ClubEditSeason
        seasonId={seasonIdToEdit}
        visible={updateSeasonVisible}
        setVisible={setUpdateSeasonVisible}
      />
    </>
  );
};

export default ClubSingleSeasons;
