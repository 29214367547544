import React, { FC, Fragment } from "react";
import { Col, Form, Input, Row, Space } from "antd";
import { PageTitle } from "component/header";
import TwfButton from "component/button";
import ShadowCard from "component/card";
import { MarginBox } from "component/margin";
import ChangePasswordMobileFooter from "./mobile-footer";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const { Item } = Form;

const ChangePasswordPage: FC = () => {
  const breakpoints = useBreakpoint();
  return (
    <Fragment>
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <Row align="middle" justify="space-between">
          <Col>
            <PageTitle>
              Change Password
            </PageTitle>
          </Col>
          {
            breakpoints.md && (
              <Fragment>
                <Col>
                  <Space size={12}>
                    <TwfButton
                      type="primary"
                      ghost
                      size-twf="md"
                      type-twf="wide"
                    >
                      Cancel
                    </TwfButton>
                    <TwfButton
                      type="primary"
                      size-twf="md"
                      type-twf="wide"
                    >
                      Save
                    </TwfButton>
                  </Space>
                </Col>
              </Fragment>
            )
          }
        </Row>
        <ShadowCard style={{ minHeight: 500 }}>
          <MarginBox pt={48} pl={24} pr={24} pb={24} style={{ maxWidth: 700 }}>
            <Form layout="vertical">
              <Item label="Old Password">
                <Input type="password" />
              </Item>

              <Item label="New Password">
                <Input type="password" />
              </Item>

              <Item label="Retype New Password">
                <Input type="password" />
              </Item>
            </Form>
          </MarginBox>
        </ShadowCard>
      </Space>
      {
        !breakpoints.md && <ChangePasswordMobileFooter />
      }
    </Fragment>
  );
};

export default ChangePasswordPage;