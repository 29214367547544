import styled from "styled-components";

const Value = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #424347;
`

export default  Value