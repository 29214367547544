import { Col, Row, Table } from "antd";
import TwfButton from "component/button";
import BackButton from "component/button/back";
import Container from "component/container";
import {
  PickupOption,
  usePickupOptionsQuery,
} from "generated/graphql";
import React, { FC, useEffect, useState } from "react";
import { TwfCard } from "../../component/card";
import { PageTitle } from "../../component/header";
import { MarginBox } from "../../component/margin";
import PickUpUpdate from "./components/edit";
import Icon from "../../assets/icons";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

interface PageProps {}

const PickupOptionsPage: FC<PageProps> = () => {

  const [updateVisible, setUpdateVisible] = useState(false);
  const [pickUp, setPickUp] = useState<any>();

  const { data: GetPickupOptionsListQuery } = usePickupOptionsQuery({
    variables:{
      options:{}
    },
    fetchPolicy: "network-only",
  });

  const [data, setData] = useState<any>();

  useEffect(()=>{
    if(GetPickupOptionsListQuery?.pickupOptions?.items){
      let finalItems = GetPickupOptionsListQuery.pickupOptions.items;
      finalItems.forEach((item)=>delete item.__typename)
      setData(finalItems);
    }
  },[GetPickupOptionsListQuery])

  let mainAdmin = false;

  if (localStorage) {
    if (localStorage.getItem("used-channel") === "__default_channel__") {
      mainAdmin = true;
    }
  }

  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Label",
      key: "label",
      align: "center",
      render: (pickupOption: PickupOption)=>{
        return pickupOption.label
      }
    },
    {
      title: "Cost",
      key: "cost",
      align: "center",
      render: (pickupOption: PickupOption)=>{
        return pickupOption.cost
      }
    },
    {
      title: "Enabled",
      //key: "enabled",
      align: "center",
      render:(pickupOption: PickupOption)=>{
        return pickupOption.enabled ? 
        <CheckCircleOutlined style={{ color: "green" }} />: 
        <CloseCircleOutlined style={{ color: "red" }}/>
      }
    },
    {
      title: "",
      key: "",
      align: "center",
      render: (pickupOption: PickupOption) => {
        return (
          <TwfButton
            key={pickupOption.id}
            disabled={!mainAdmin}
            style={{ border:"none" }}
            onClick={() => {
              setPickUp(pickupOption)
              setUpdateVisible(true);
            }}
          >
             <Icon keyword="edit" color="#3C64B1" />
          </TwfButton>
        )
      }
    },
    {
      title: "",
      key: "",
      align: "center",
    },
  ];


  return (
    <div>
      <Row gutter={[30, 15]} justify="space-between">
        <Col>
          <PageTitle>
            <BackButton /> &nbsp; Pickup Options
          </PageTitle>
        </Col>
        <Col>
          <Row gutter={[20, 20]}></Row>
        </Col>
      </Row>
      <MarginBox mt={32}>
        <Container>
          <TwfCard>
            <MarginBox mt={12} mb={56}>
              <Table
                columns={columns}
                style={{ maxWidth: "max-content" }}
                scroll={{ x: "max-content" }}
                dataSource={data}
                pagination={false}
              />
            </MarginBox>
          </TwfCard>
        </Container>
      </MarginBox>

      <PickUpUpdate
       key={Math.floor(Math.random() * 10000)}
       pickup={pickUp}
       visible={updateVisible}
       setVisible={setUpdateVisible}
      />

    </div>

  );
};

export default PickupOptionsPage;
