import { FunctionComponent } from "react";
import { Row, Col, InputNumber } from "antd";
import TwfButton from "component/button";
import { OperationCard, OperationProps } from "../conditions";

const MinimumOrderQuantityCondition: FunctionComponent<OperationProps> = ({
  code,
  configArgs,
  onConfigArgChange,
  onRemove,
}) => {
  const orderQuantity =
    configArgs.find((arg) => arg.name === "min")?.value || "0";

  return (
    <OperationCard
      title={`If order contains minimum ${+orderQuantity} quantity`}
      style={{ marginTop: 16 }}
      actions={[
        <TwfButton key="remove" type="link" onClick={() => onRemove(code)}>
          Remove
        </TwfButton>,
      ]}
    >
      <Row>
        <Col>
          Quantity:{" "}
          <InputNumber
            min={0}
            /*formatter={(value: any) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }*/
            //parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
            value={+orderQuantity}
            onChange={(value: any) =>
              onConfigArgChange(
                { name: "min", value: value.toString() },
                code
              )
            }
            style={{ width: 160 }}
          />
        </Col>
      </Row>
    </OperationCard>
  );
};

export default MinimumOrderQuantityCondition;
