import { Alert } from "antd";
import styled from "styled-components";

const VerifyAlert = styled(Alert)`
  background-color: #f4f9fd;
  border-radius: 14px;
  border: none;
  padding: 23px 22px;
  .ant-alert-description {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #3f8cff;
  }
  .ant-alert-message {
    display: none;
  }
`;

export default VerifyAlert;
