import { Form, Space } from "antd";
import TwfModal from "component/modal";
import { FC, Fragment } from "react";
import CloseIcon from "../../../assets/icons/close";
import { H3 } from "../../../component/header";
import AddressForm from "./form";

interface AddressProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  data: any;
}

const AddressEdit: FC<AddressProps> = ({ visible, setVisible, data }) => {
  const cancelHandler = () => {
    setVisible(false);
  };

  const submitHandler = (value: any) => {
    console.log(value);
  };

  const makePrimaryHandler = (event: any) => {
    console.log(event.target.checked);
  };
  return (
    <Fragment>
      <TwfModal
        visible={visible}
        footer={false}
        closeIcon={<CloseIcon />}
        centered
        width={721}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
          <H3>Edit Address</H3>
          <Form layout="vertical" onFinish={submitHandler} initialValues={data}>
            <AddressForm
              cancelHandler={cancelHandler}
              makePrimaryHandler={makePrimaryHandler}
            />
          </Form>
        </Space>
      </TwfModal>
    </Fragment>
  );
};

export default AddressEdit;
