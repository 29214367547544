import styled from "styled-components";

const H1 = styled.h1`
  font-size: 36px;
  line-height: 45px;
`;
const H2 = styled.h2`
  font-size: 30px;
  line-height: 38px;
`;
const H3 = styled.h3`
  font-size: 22px;
  line-height: 28px;
`;
const H4 = styled.h4``;
const H5 = styled.h5``;
const H6 = styled.h6`
  font-size: 14px;
  line-height: 16px;
`;

const SubTitle = styled.p`
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #a6acbe;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 12px;
    font-weight: 600;
  }
`;

const Explain = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #bbbbbb;
  line-height: 20px;
  letter-spacing: 0.2px;
`;

const BlockTitle = styled.h3`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const SelectTitle = styled.h5`
  color: #7d8592;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  padding-left: 6px;
  margin-bottom: 2px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const PageTitle = styled(H2)`
  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 28px;
  }
`;

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  SubTitle,
  BlockTitle,
  SelectTitle,
  Explain,
  PageTitle,
};
