import { Col, Form, Input, message, Row, Select } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import TextEditor from "component/textEditor/textEditor";
import {
  CreateSeasonInput,
  CreateSeasonMutationVariables,
  SeasonProductVariantInput,
  useCreateSeasonMutation,
  useGetShippingMethodListQuery,
} from "generated/graphql";
import React, { useState } from "react";
import { useParams } from "react-router";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import { H3 } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import TwfModal from "../../../component/modal";
import ModalTitle from "../../../component/modal/title";
import SeasonProductVariant from "../components/season-product";

const { Item } = Form;

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const defaultSeasonProductVariant = {
  order: 1,
  defaultBottle: 1,
  minimumBottle: 1,
  maximumBottle: 1,
} as SeasonProductVariantInput;

const ClubAddSeason: React.FC<ComponentProps> = ({ visible, setVisible }) => {
  const [form] = Form.useForm();
  const breakpoints = useBreakpoint();
  const { id } = useParams<{ id: string }>();
  const cid: string = id as string;
  const [createSeasonMutation, { loading }] = useCreateSeasonMutation();

  const { data: shippingMethods } = useGetShippingMethodListQuery();

  const removeNull = (shippingM: any) => {
    return (
      shippingM.name !== "NULL SHIPPING METHOD" &&
      shippingM.customFields.enabled === true
    );
  };

  const [seasonProductVariants, setSeasonProductVariants] = useState<
    (SeasonProductVariantInput & { originalPrice?: number })[]
  >([defaultSeasonProductVariant]);

  const addNewSeasonProductVariant = () => {
    setSeasonProductVariants([
      ...seasonProductVariants,
      {
        ...defaultSeasonProductVariant,
        order: seasonProductVariants.length + 1,
      },
    ]);
  };

  const finishHandler = (data: CreateSeasonInput) => {
    if (!formIsValid()) return;
    createSeasonMutation({
      variables: prepareCreateSeasonInput(data),
      refetchQueries: ["getSeasonListForClub"],
    })
      .then(({ data }) => {
        if (data?.createSeason) {
          message.success("Season created");
          setVisible(false);
          form.resetFields();
          setSeasonProductVariants([defaultSeasonProductVariant]);
        }
      })
      .catch((error) => message.error(error.message));
  };

  const prepareCreateSeasonInput = (
    data: CreateSeasonInput
  ): CreateSeasonMutationVariables => {
    return {
      input: {
        name: data.name,
        shippingMethodID: data.shippingMethodID,
        minimumBottle: data.minimumBottle || 1,
        maximumBottle: data.maximumBottle || 10,
        //minimumBottle: 1,
        //maximumBottle: 1000,
        teaser: data.teaser,
        seasonProductVariants: seasonProductVariants.map(
          ({ originalPrice, ...variant }) => ({
            ...variant,
            price:
              variant.price === null
                ? (originalPrice ?? variant.price) * 100
                : variant.price * 100,
          })
        ),
        clubID: cid,
      },
    };
  };

  const formIsValid = () => {
    if (!seasonProductVariants.every((s) => s.productVariantID)) {
      message.warning("Product is not selected");
      return false;
    } else if (!seasonProductVariants.every((s) => s.defaultBottle)) {
      message.warning("Default bottle is not selected");
      return false;
      // } else if (!seasonProductVariants.every((s) => s.minimumBottle)) {
      //   message.warning("Minimum bottle is not selected");
      //   return false;
      // } else if (!seasonProductVariants.every((s) => s.maximumBottle)) {
      //   message.warning("Maximum bottle is not selected");
      //   return false;
    } else return true;
  };

  const updateSeasonProduct = (
    seasonProduct: SeasonProductVariantInput,
    index: number
  ) => {
    const seasonProducts = [...seasonProductVariants];
    seasonProducts[index] = seasonProduct;
    setSeasonProductVariants(seasonProducts);
  };

  const deleteSeasonProduct = (index: number) => {
    setSeasonProductVariants([
      ...seasonProductVariants.slice(0, index),
      ...seasonProductVariants.slice(index + 1),
    ]);
  };

  const initialValues = { displayOnWeb: false, active: true };

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closeIcon={<Icon keyword="close" />}
      footer={false}
      width={862}
      centered
    >
      <ModalTitle>
        <H3>Add New Season</H3>
      </ModalTitle>
      <Form
        form={form}
        layout="vertical"
        onFinish={finishHandler}
        initialValues={initialValues}
      >
        <Item
          label="Season/ Month"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter season name!",
            },
          ]}
        >
          <Input />
        </Item>
        <Row gutter={12}>
          <Col span={12}>
            {/* <Item
              label="Min Bottle/ Season"
              name="minimumBottle"
              rules={[
                {
                  required: true,
                  message: "Please enter minimum bottle!",
                },
              ]}
            >
              <InputNumber
                size="large"
                style={{ borderRadius: 12, width: "100%" }}
              />
            </Item> */}
          </Col>
          <Col span={12}>
            {/* <Item
              label="Max Bottle/ Season"
              name="maximumBottle"
              rules={[
                {
                  required: true,
                  message: "Please enter maximum bottle!",
                },
              ]}
            >
              <InputNumber
                size="large"
                style={{ borderRadius: 12, width: "100%" }}
              />
            </Item> */}
          </Col>
        </Row>
        <Item label="Teaser" name="teaser">
          <TextEditor />
        </Item>
        <Item
          label="Shipping Method"
          name="shippingMethodID"
          rules={[
            {
              required: true,
              message: "Please select shipping!",
            },
          ]}
        >
          <Select
            placeholder="Select"
            options={shippingMethods?.shippingMethods.items
              .filter(removeNull)
              .map((method) => ({
                value: method.id,
                label: method.name,
              }))}
            suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
          ></Select>
        </Item>
        {seasonProductVariants.map((seasonProduct, key) => (
          <SeasonProductVariant
            seasonProduct={seasonProduct}
            onSeasonProductChange={(seasonProduct) =>
              updateSeasonProduct(seasonProduct, key)
            }
            onSeasonProductRemove={() => deleteSeasonProduct(key)}
            key={key}
          />
        ))}
        <TwfButton
          type="link"
          style={{ color: "#373F41" }}
          onClick={addNewSeasonProductVariant}
        >
          <Icon keyword="plus" color="#A6ACBE" width={16} />
          Add
        </TwfButton>
        <MarginBox mt={32}>
          <Row gutter={breakpoints.md ? [24, 24] : [12, 12]} justify="end">
            <Col>
              <TwfButton
                size-twf="xm"
                color-twf="gray-line"
                type-twf="wide"
                onClick={() => {
                  setVisible(false);
                  form.resetFields();
                }}
              >
                Cancel
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                size-twf="xm"
                type="primary"
                color-twf="primary"
                type-twf="wide"
                htmlType="submit"
                loading={loading}
              >
                Add
              </TwfButton>
            </Col>
          </Row>
        </MarginBox>
      </Form>
    </TwfModal>
  );
};

export default ClubAddSeason;
