import React from "react";
import { TwfCard } from "../../../component/card";
import { Divider, Grid, Table } from "antd";
import { MarginBox } from "../../../component/margin";
import { GetCustomerQuery } from "generated/graphql";
import Container from "component/container";
const { useBreakpoint } = Grid;

interface ViewProps {
  customerQuery?: GetCustomerQuery;
}

const CustomerSingleGeneral: React.FC<ViewProps> = ({ customerQuery }) => {
  const breakpoints = useBreakpoint();

  const customer = customerQuery?.customer;

  const columns = [
    {
      title: "",
      dataIndex: "keyword",
      key: "keyword",
      width: breakpoints.md ? 174 : 130,
      render: (keyword: any) => {
        return <p style={{ textAlign: "right", fontWeight: 700 }}>{keyword}</p>;
      },
    },
    {
      title: "",
      dataIndex: "value",
      key: "value",
    },
  ];

  const data = [
    {
      key: "1",
      keyword: "ID",
      value: customer?.id,
    },
    {
      key: "2",
      keyword: "Username",
      value: customer?.user?.identifier,
    },
    {
      key: "3",
      keyword: "First Name",
      value: customer?.firstName,
    },
    {
      key: "4",
      keyword: "Last Name",
      value: customer?.lastName,
    },
    {
      key: "5",
      keyword: "Email",
      value: customer?.emailAddress,
    },
    {
      key: "5",
      keyword: "Phone Number",
      value: customer?.phoneNumber,
    },
    {
      key: "5",
      keyword: "Date of Birth",
      value:customer?.customFields?.dob,
    },
    {
      key: "6",
      keyword: "Signup Date",
      value: customer?.createdAt,
    },
    {
      key: "7",
      keyword: "Last Login",
      value: customer?.user?.lastLogin,
    },
    {
      key: "8",
      keyword: "Status",
      value: customer?.customFields?.status === 1 ? "Active" : "Inactive",
    },
    {
      key: "9",
      keyword: "Customer Group",
      value: customer?.customFields?.isTradeUser ? "Trade" : "Non-Trade"
    }
    /*{
      key: "9",
      keyword: "Tax Exemption",
      value: "N/A",
    },*/
  ];
  return (
    <Container maxWidth="600px">
    <TwfCard>
      <MarginBox pt={22} pb={58}>
        <Divider style={{ marginBottom: 0 }} />
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          showHeader={false}
        />
      </MarginBox>
    </TwfCard>
    </Container>
  );
};

export default CustomerSingleGeneral;
