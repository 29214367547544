import { Checkbox, Col, Divider, Form, Input, Row, Select } from "antd";
import React, { FC, Fragment } from "react";
import { ChevronDownIcon } from "../../../assets/icons/chevron";

const { Item } = Form;
const { Option } = Select;

const CardForm: FC = () => {
  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Item label="Billing Address">
            <Select
              suffixIcon={<ChevronDownIcon/>}
              placeholder='Select One'
            >
              <Option value='address1'>Address 1</Option>
            </Select>
          </Item>

          <Divider orientation="left">OR</Divider>

          <Item label="Billing Information" name='address1'>
            <Input placeholder='Address 1'/>
          </Item>
          <Item name='address2'>
            <Input placeholder='Address 2'/>
          </Item>
          <Item name='city'>
            <Input placeholder='City'/>
          </Item>
          <Item name='state'>
            <Input placeholder='State'/>
          </Item>
          <Item name='country'>
            <Select
              placeholder='Country'
              suffixIcon={<ChevronDownIcon/>}
            >
              <Option value='us'>United State</Option>
              <Option value='uk'>United Kingdom</Option>
            </Select>
          </Item>
          <Item name='zip'>
            <Input placeholder='Zip'/>
          </Item>
          <Item label='Credit Card Information' name='cardName'>
            <Input placeholder='Name on Card'/>
          </Item>
          <Item name='cardNumber'>
            <Input placeholder='Card #'/>
          </Item>
        </Col>
        <Col span={12}>
          <Item name="expiryDate">
            <Input
              placeholder="MM/DD"
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item name="cvs">
            <Input
              placeholder="CVS"
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            />
          </Item>
        </Col>
        <Col span={24}>
          <Item>
            <Checkbox>
              Make as Default
            </Checkbox>
          </Item>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CardForm;