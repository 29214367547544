import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const BurgerLongIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 2.33333C0.75 1.91341 0.916815 1.51068 1.21375 1.21375C1.51068 0.916815 1.91341 0.75 2.33333 0.75H15C15.4199 0.75 15.8227 0.916815 16.1196 1.21375C16.4165 1.51068 16.5833 1.91341 16.5833 2.33333C16.5833 2.75326 16.4165 3.15599 16.1196 3.45292C15.8227 3.74985 15.4199 3.91667 15 3.91667H2.33333C1.91341 3.91667 1.51068 3.74985 1.21375 3.45292C0.916815 3.15599 0.75 2.75326 0.75 2.33333Z"
      fill={color ? color : "#000000"}
    />
    <path
      d="M0.75 15.0013C0.75 14.5814 0.916815 14.1786 1.21375 13.8817C1.51068 13.5848 1.91341 13.418 2.33333 13.418H15C15.4199 13.418 15.8227 13.5848 16.1196 13.8817C16.4165 14.1786 16.5833 14.5814 16.5833 15.0013C16.5833 15.4212 16.4165 15.824 16.1196 16.1209C15.8227 16.4178 15.4199 16.5846 15 16.5846H2.33333C1.91341 16.5846 1.51068 16.4178 1.21375 16.1209C0.916815 15.824 0.75 15.4212 0.75 15.0013Z"
      fill={color ? color : "#000000"}
    />
    <path
      d="M0.75 21.3333C0.75 20.9134 0.916815 20.5107 1.21375 20.2137C1.51068 19.9168 1.91341 19.75 2.33333 19.75H15C15.4199 19.75 15.8227 19.9168 16.1196 20.2137C16.4165 20.5107 16.5833 20.9134 16.5833 21.3333C16.5833 21.7533 16.4165 22.156 16.1196 22.4529C15.8227 22.7499 15.4199 22.9167 15 22.9167H2.33333C1.91341 22.9167 1.51068 22.7499 1.21375 22.4529C0.916815 22.156 0.75 21.7533 0.75 21.3333Z"
      fill={color ? color : "#000000"}
    />
    <path
      d="M0.75 27.6654C0.75 27.2454 0.916815 26.8427 1.21375 26.5458C1.51068 26.2488 1.91341 26.082 2.33333 26.082H15C15.4199 26.082 15.8227 26.2488 16.1196 26.5458C16.4165 26.8427 16.5833 27.2454 16.5833 27.6654C16.5833 28.0853 16.4165 28.488 16.1196 28.7849C15.8227 29.0819 15.4199 29.2487 15 29.2487H2.33333C1.91341 29.2487 1.51068 29.0819 1.21375 28.7849C0.916815 28.488 0.75 28.0853 0.75 27.6654Z"
      fill={color ? color : "#000000"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0192 10.249C20.3927 11.3059 21.1278 12.1966 22.0946 12.7639C23.0614 13.3311 24.1976 13.5383 25.3024 13.3489C26.4072 13.1594 27.4094 12.5854 28.132 11.7284C28.8545 10.8714 29.2508 9.78658 29.2508 8.66566C29.2508 7.54473 28.8545 6.45989 28.132 5.6029C27.4094 4.74591 26.4072 4.17194 25.3024 3.98245C24.1976 3.79297 23.0614 4.00017 22.0946 4.56742C21.1278 5.13467 20.3927 6.02546 20.0192 7.08232H2.33333C1.91341 7.08232 1.51068 7.24914 1.21375 7.54607C0.916815 7.843 0.75 8.24573 0.75 8.66566C0.75 9.08558 0.916815 9.48831 1.21375 9.78524C1.51068 10.0822 1.91341 10.249 2.33333 10.249H20.0192ZM26.0833 8.66566C26.0833 9.08558 25.9165 9.48831 25.6196 9.78524C25.3227 10.0822 24.9199 10.249 24.5 10.249C24.0801 10.249 23.6773 10.0822 23.3804 9.78524C23.0835 9.48831 22.9167 9.08558 22.9167 8.66566C22.9167 8.24573 23.0835 7.843 23.3804 7.54607C23.6773 7.24914 24.0801 7.08232 24.5 7.08232C24.9199 7.08232 25.3227 7.24914 25.6196 7.54607C25.9165 7.843 26.0833 8.24573 26.0833 8.66566Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export { BurgerLongIcon };
