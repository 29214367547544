import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const ExportIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 16H13V7H16L12 2L8 7H11V16Z" fill={color ? color : "#000000"} />
    <path
      d="M5 22H19C20.103 22 21 21.103 21 20V11C21 9.897 20.103 9 19 9H15V11H19V20H5V11H9V9H5C3.897 9 3 9.897 3 11V20C3 21.103 3.897 22 5 22Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export default ExportIcon;
