import React from "react";
import TwfModal from "../../../component/modal";
import Icon from "../../../assets/icons";
import ModalTitle from "../../../component/modal/title";
import { BlockTitle, H3 } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import { Col, DatePicker, Form, message, Row, Select, Input } from "antd";
import TwfButton from "../../../component/button";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import TextEditor from "component/textEditor/textEditor";
import {
  CreateComplianceInput,
  useCreateComplianceMutation,
  useGetStateListQuery,
  useCheckIfComplianceMutation,
} from "generated/graphql";

const { Item } = Form;
const { Option } = Select;

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const CreateCompliance: React.FC<ComponentProps> = ({
  visible,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const breakpoints = useBreakpoint();
  const { data: states } = useGetStateListQuery();
  const [createComplianceMutation] = useCreateComplianceMutation();

  const [checkIfComplianceMutation] = useCheckIfComplianceMutation();

  const submitFormHandler = (formData: CreateComplianceInput): void => {
    checkIfComplianceMutation({
      variables: { id: formData.stateId },
    })
      .then(({ data }) => {
        if (data?.checkifCompliance == false) {
          creatit(formData);
        } else {
          message.error(
            "Internal Compliance Rule for the state already exists"
          );
        }
      })
      .catch((error) => message.error(error.message));
  };

  const creatit = (formData: any) => {
    formData["edited"]=false;
    console.log(formData);
    createComplianceMutation({
      variables: { input: formData },
      refetchQueries: ["GetComplianceList"],
    })
      .then(({ data }) => {
        if (data?.createCompliance.__typename === "Compliance") {
          message.success("Compliance created");
          setVisible(false);
          form.resetFields();
        }
      })
      .catch((error) => message.error(error.message));
  };

  const initialValues = { status: 1 };

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closeIcon={<Icon keyword="close" />}
      centered
      width={662}
      footer={false}
    >
      <ModalTitle>
        <H3>Create Membership</H3>
      </ModalTitle>
      <MarginBox mt={32}>
        <Form
          form={form}
          layout="vertical"
          onFinish={submitFormHandler}
          initialValues={initialValues}
        >
          <Row>
            <Col span={24}>
              <Item
                label="State"
                name="stateId"
                rules={[
                  {
                    required: true,
                    message: "Please enter the state!",
                  },
                ]}
              >
                <Select
                  showSearch
                  options={states?.states.map((state) => ({
                    label: state.name,
                    value: state.id,
                  }))}
                  style={{ width: "100%" }}
                  placeholder="State"
                  filterOption={(input, option) =>
                    (option!.label as string)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Item>
            </Col>
            <Col span={24}>
              <Item
                label="Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Please enter the status!",
                  },
                ]}
              >
                <Select
                  suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
                  placeholder="Select"
                >
                  <Option value={1}>Active</Option>
                  <Option value={0}>Inactive</Option>
                </Select>
              </Item>
            </Col>
            <Col span={24}>
              <BlockTitle style={{ marginBottom: 0 }}>
                Shipping Advisory
              </BlockTitle>
              <p style={{ color: "#7D8592", marginBottom: 6 }}>
                Appears in the cart and checkout.
              </p>
              <Item
                name="shippingAdvisory"
                rules={[
                  {
                    required: true,
                    message: "Please enter the shipping advisory!",
                  },
                ]}
              >
                <TextEditor />
              </Item>
            </Col>
            <Col span={24}>
              <Item
                label="Weather Hold Date"
                name="holdDate"
                rules={[
                  {
                    required: true,
                    message: "Please enter the weather hold date!",
                  },
                ]}
              >
                <DatePicker />
              </Item>
            </Col>
            <Col span={24}>
              <BlockTitle style={{ marginBottom: 0 }}>
                Compliance Advisory
              </BlockTitle>
              <p style={{ color: "#7D8592", marginBottom: 6 }}>
                Appears on the product list and drilldown pages.
              </p>
              <Item
                name="complianceAdvisory"
                rules={[
                  {
                    required: true,
                    message: "Please enter the compliance advisory!",
                  },
                ]}
              >
                <TextEditor />
              </Item>
            </Col>
            <Col span={24}>
              <BlockTitle style={{ marginBottom: 0 }}>
                Rules and Regulations
              </BlockTitle>
              <Item
                name="rules"
                rules={[
                  {
                    required: true,
                    message: "Please enter the rules & regulations!",
                  },
                ]}
              >
                <TextEditor />
              </Item>
            </Col>
            <Col span={24}>
              <BlockTitle>Note</BlockTitle>
              <Item
                name="note"
                rules={[
                  {
                    required: true,
                    message: "Please enter the note",
                  },
                ]}
              >
                <TextEditor />
              </Item>
            </Col>
          </Row>
          <MarginBox mt={32}>
            <Row justify="end" gutter={breakpoints.md ? [24, 24] : [12, 12]}>
              <Col>
                <TwfButton
                  color-twf="gray-line"
                  size-twf="md"
                  type-twf="wide"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Cancel
                </TwfButton>
              </Col>
              <Col>
                <TwfButton
                  type="primary"
                  color-twf="primary"
                  size-twf="md"
                  type-twf="wide"
                  htmlType="submit"
                >
                  Create
                </TwfButton>
              </Col>
            </Row>
          </MarginBox>
        </Form>
      </MarginBox>
    </TwfModal>
  );
};

export default CreateCompliance;
