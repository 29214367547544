import React, { Fragment } from "react";
import { TwfCard } from "../../../component/card";
import { MarginBox } from "../../../component/margin";
import { H3, H4 } from "../../../component/header";
import { useParams } from "react-router";
import { useGetSeasonStatQuery } from "generated/graphql";

const SeasonSummary = () => {
  const { id } = useParams<{ id: string }>();
  const cid:string = id as string;
  const { data } = useGetSeasonStatQuery({
    variables: { id:cid },
  });

  return (
    <Fragment>
      <TwfCard style={{ padding: "32px 56px", minHeight: 300 }}>
        <MarginBox mb={32}>
          <MarginBox mb={8}>
            <H4>Total Members</H4>
          </MarginBox>
          <H3>{data?.seasonStat?.totalMembers}</H3>
        </MarginBox>
        <MarginBox mb={32}>
          <MarginBox mb={8}>
            <H4>Processed Members</H4>
          </MarginBox>
          <H3>{data?.seasonStat?.processedMembers}</H3>
        </MarginBox>
        <MarginBox mb={32}>
          <MarginBox mb={8}>
            <H4>Declined Members</H4>
          </MarginBox>
          <H3>{data?.seasonStat?.declinedMembers}</H3>
        </MarginBox>
        <MarginBox mb={32}>
          <MarginBox mb={8}>
            <H4>Skipped/Unprocessed Members</H4>
          </MarginBox>
          <H3>{data?.seasonStat?.skippedMembers}</H3>
        </MarginBox>
      </TwfCard>
    </Fragment>
  );
};

export default SeasonSummary;
