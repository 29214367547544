import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const PictureIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg width={width ? width : 22}
       height={width ? width : 22}
       viewBox="6 6 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.4202 29.6394H8.58023C7.25423 29.6394 6.24023 28.6254 6.24023 27.2994V11.6994C6.24023 10.3734 7.25423 9.35938 8.58023 9.35938H30.4202C31.7462 9.35938 32.7602 10.3734 32.7602 11.6994V27.2994C32.7602 28.6254 31.7462 29.6394 30.4202 29.6394ZM8.58023 10.9194C8.11223 10.9194 7.80023 11.2314 7.80023 11.6994V27.2994C7.80023 27.7674 8.11223 28.0794 8.58023 28.0794H30.4202C30.8882 28.0794 31.2002 27.7674 31.2002 27.2994V11.6994C31.2002 11.2314 30.8882 10.9194 30.4202 10.9194H8.58023Z"
      fill={color ? color : "#000000"} />
    <path
      d="M23.3993 18.7205C21.6833 18.7205 20.2793 17.3165 20.2793 15.6005C20.2793 13.8845 21.6833 12.4805 23.3993 12.4805C25.1153 12.4805 26.5193 13.8845 26.5193 15.6005C26.5193 17.3165 25.1153 18.7205 23.3993 18.7205ZM23.3993 14.0405C22.5413 14.0405 21.8393 14.7425 21.8393 15.6005C21.8393 16.4585 22.5413 17.1605 23.3993 17.1605C24.2573 17.1605 24.9593 16.4585 24.9593 15.6005C24.9593 14.7425 24.2573 14.0405 23.3993 14.0405Z"
      fill={color ? color : "#000000"} />
    <path
      d="M27.5346 29.4071L14.8206 17.4731L7.56661 24.1811L6.47461 23.0891L14.8206 15.2891L28.6266 28.3151L27.5346 29.4071Z"
      fill={color ? color : "#000000"} />
    <path
      d="M31.5114 25.5069L27.2994 22.0749L23.7894 24.9609L22.7754 23.7129L27.2994 20.0469L32.4474 24.2589L31.5114 25.5069Z"
      fill={color ? color : "#000000"} />
  </svg>
);

export default PictureIcon;
