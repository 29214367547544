import { useState, useEffect } from "react";
import { Col, message, Row } from "antd";
import { H2 } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import BackButton from "../../../component/button/back";
import { useParams } from "react-router-dom";
import Variants, { OptionGroup, Variant } from "./variants";
import { useGetProductVariantOptionsQuery } from "./../../../generated/graphql";
import TwfButton from "component/button";
import {
  addOptionGroupToProduct,
  addOptionsToGroups,
  createProductOptionGroup,
  createProductVariantsForUpdate,
} from "../utils/common";

export interface OptionValue {
  id?: string;
  name: string;
  productOptionGroupId?: string;
  productOptionGroupName: string;
}

const ManageVariants = () => {
  const { id } = useParams<{ id: string }>();
  const cid:string = id as string;
  const { data, refetch } = useGetProductVariantOptionsQuery({
    variables: { id:cid },
  });

  const [loading, setLoading] = useState(false);
  const [optionGroups, setOptionGroups] = useState<OptionGroup[]>([
    { name: "", options: [] },
  ]);
  const [variants, setVariants] = useState<Variant[]>([
    { name: "", options: [], sku: "", price: 0, stock: 0 },
  ]);

  useEffect(() => {
    if (data?.product) {
      setOptionGroups(
        data.product.optionGroups.map((optionGroup) => ({
          id: optionGroup.id,
          name: optionGroup.name,
          options: optionGroup.options.map((option) => ({
            ...option,
            disabled: true,
          })),
          disabled: true,
        }))
      );
      setVariants(
        data.product.variants.map((variant) => ({
          id: variant.id,
          name: variant.name,
          options: variant.options,
          sku: variant.sku,
          price: variant.price,
          stock: variant.stockOnHand,
        }))
      );
    }
  }, [data]);

  const updateProductVariants = () => {
    setLoading(true);
    const selectedVariants = variants.filter((v) => v.selected);

    const newOptionGroups: OptionGroup[] = [];
    const newOptionValues: OptionValue[] = [];

    optionGroups.forEach((optionGroup) => {
      if (!optionGroup.id)
        newOptionGroups.push({ name: optionGroup.name, options: [] });
    });

    optionGroups.forEach((optionGroup) => {
      optionGroup.options.forEach((option) => {
        if (!option.id) {
          newOptionValues.push({
            name: option.name,
            productOptionGroupId: optionGroup.id,
            productOptionGroupName: optionGroup.name,
          });
        }
      });
    });

    if (newOptionGroups.length > 0) {
      createProductOptionGroup(newOptionGroups)
        .then((optionGroupIds) => addOptionGroupToProduct(optionGroupIds, cid))
        .then(() =>
          refetch().then(({ data }) => {
            if (data) {
              newOptionValues.forEach((optionValue) => {
                const newOptionGroup = data.product?.optionGroups.find(
                  (optionGroup) =>
                    optionGroup.name === optionValue.productOptionGroupName
                );
                if (newOptionGroup)
                  optionValue.productOptionGroupId = newOptionGroup.id;
              });
              addOptionsToGroups(newOptionValues)
                .then((createdOptionGroups) =>
                  createProductVariantsForUpdate(
                    createdOptionGroups,
                    selectedVariants,
                    cid
                  )
                )
                .then(() => {
                  message.success("Product variants added");
                  setLoading(false);
                })
                .catch((error: any) => {
                  message.error(error.message);
                  setLoading(false);
                });
            }
          })
        );
    } else if (newOptionValues.length > 0) {
      newOptionValues.forEach((optionValue) => {
        const existingOptionGroup = optionGroups.find(
          (optionGroup) =>
            optionGroup.name === optionValue.productOptionGroupName
        );
        if (existingOptionGroup)
          optionValue.productOptionGroupId = existingOptionGroup.id;
      });
      addOptionsToGroups(newOptionValues)
        .then((createdOptionGroups) =>
          createProductVariantsForUpdate(
            createdOptionGroups,
            selectedVariants,
            cid
          )
        )
        .then(() => {
          message.success("Product variants added");
          setLoading(false);
        })
        .catch((error: any) => {
          message.error(error.message);
          setLoading(false);
        });
    } else {
      createProductVariantsForUpdate([], selectedVariants, cid)
        .then(() => {
          message.success("Product variants added");
          setLoading(false);
        })
        .catch((error: any) => {
          message.error(error.message);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Row justify="space-between" gutter={[24, 24]}>
        <Col>
          <Row>
            <Col>
              <Row align="middle" gutter={12}>
                <Col>
                  <BackButton url={"/products/edit/" + id} />
                </Col>
                <Col>
                  <H2>Manage Variants</H2>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={24} align="middle">
            <Col>
              <TwfButton
                color-twf="primary"
                type="primary"
                size-twf="lg"
                type-twf="wide"
                onClick={updateProductVariants}
                loading={loading}
                disabled={
                  variants.filter((variant) => variant.selected).length === 0
                }
              >
                Add {variants.filter((variant) => variant.selected).length}{" "}
                Variant(s)
              </TwfButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <MarginBox mt={20}>
        <Variants
          optionGroups={optionGroups}
          setOptionGroups={setOptionGroups}
          variants={variants}
          setVariants={setVariants}
          manage={true}
        />
      </MarginBox>
    </div>
  );
};

export default ManageVariants;
