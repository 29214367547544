import { Card } from "antd";
import styled from "styled-components";

const ShadowCard = styled(Card)`
  box-shadow: 0 3px 0 #ededf6;
  border: none;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  .ant-card-body {
    padding: 0;
  }
`;

export const TwfCard = styled(Card)`
  border: none;
  border-radius: 10px;
  .ant-card-body {
    padding: 0;
  }
`;

export default ShadowCard;
