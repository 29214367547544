import { Checkbox, Col, Form, Grid, Input, message, Row, Select } from "antd";
import Container from "component/container";
import useIsSuperAdmin from "hooks/useIsSuperAdmin";
import React, { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import BackButton from "../../../component/button/back";
import { TwfCard } from "../../../component/card";
import { BlockTitle, PageTitle } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import { useUpdateCustomerMutation } from "../../../generated/graphql";
import { useGetCustomerQuery } from "./../../../generated/graphql";
import CreateMobileFooter from "./mobile-footer";

const { Item } = Form;
const { Option } = Select;
const { useBreakpoint } = Grid;

const CustomerEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const cid: string = id as string;
  const [form] = Form.useForm();
  const history = useNavigate();
  const breakpoints = useBreakpoint();
  const [updateCustomerMutation, { data: updateResult, loading }] =
    useUpdateCustomerMutation();
  const { data: customerResult } = useGetCustomerQuery({
    variables: {
      id: cid,
    },
  });

  const isSuperAdmin = useIsSuperAdmin();

  useEffect(() => {
    if (updateResult?.updateCustomer.__typename === "Customer") {
      message.success("Customer updated");
    }
  }, [updateResult]);

  const initializeForm = useCallback(
    (customerResult: any) => {
      const { customer } = customerResult;
      form.setFieldsValue({ ...customer, ...customer?.customFields });
    },
    [form]
  );

  useEffect(() => {
    if (customerResult) {
      initializeForm(customerResult);
    }
  }, [customerResult, initializeForm]);

  const submitFormHandler = (value: any) => {
    console.log(value);
    const {
      emailAddress,
      password,
      firstName,
      lastName,
      phoneNumber,
      ...rest
    } = value;
    updateCustomerMutation({
      variables: {
        input: {
          id: cid,
          firstName,
          lastName,
          phoneNumber,
          emailAddress,
          customFields: { ...rest },
        },
      },
      onCompleted(data) {
        if (data?.updateCustomer?.__typename !== "Customer") {
          message.error(
            // @ts-ignore
            data?.updateCustomer?.message || "Failed to update customer"
          );
        }
      },
      onError(error) {
        message.error(error?.message ?? "Failed to update customer");
      },
    });
  };

  const now = new Date();
  const minDate = new Date(
    now.getFullYear() - 130,
    now.getMonth(),
    now.getDate()
  );
  const maxDate = new Date(
    now.getFullYear() - 21,
    now.getMonth(),
    now.getDate()
  );

  return (
    <div>
      <Form form={form} onFinish={submitFormHandler} layout="vertical">
        <Row justify="space-between" gutter={[20, 20]}>
          <Col>
            <Row>
              <Col>
                <Row gutter={12} align="middle">
                  <Col>
                    <BackButton />
                  </Col>
                  <Col>
                    <PageTitle>Edit Customer</PageTitle>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {breakpoints.md && (
            <Col>
              <Row gutter={24}>
                <Col>
                  <TwfButton
                    color-twf="gray-line"
                    size-twf="lg"
                    type-twf="wide"
                    onClick={() => {
                      history("/customers");
                    }}
                  >
                    Cancel
                  </TwfButton>
                </Col>
                <Col>
                  <TwfButton
                    color-twf="primary"
                    type="primary"
                    size-twf="lg"
                    type-twf="wide"
                    htmlType="submit"
                    loading={loading}
                  >
                    Save
                  </TwfButton>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Container maxWidth="1000px">
          <TwfCard
            style={{
              marginTop: 32,
              padding: breakpoints.md ? "39px 44px" : "19px 24px 91px",
            }}
          >
            <Row gutter={[30, 30]}>
              <Col xl={12} span={24}>
                <MarginBox mb={56}>
                  <MarginBox mb={12}>
                    <BlockTitle>Login Information</BlockTitle>
                  </MarginBox>
                  <Item label="Email Address" name="emailAddress">
                    <Input placeholder="Email" disabled={!isSuperAdmin} />
                  </Item>
                  <Item label="Password" name="password">
                    <Input placeholder="******" disabled />
                  </Item>
                </MarginBox>
              </Col>
              <Col xl={12} span={24}>
                <Row gutter={16}>
                  <Col span={24}>
                    <MarginBox mb={20}>
                      <BlockTitle>Personal Information</BlockTitle>
                    </MarginBox>
                  </Col>
                  <Col sm={12} span={24}>
                    <Item name="firstName">
                      <Input placeholder="First Name" />
                    </Item>
                  </Col>
                  <Col sm={12} span={24}>
                    <Item name="lastName">
                      <Input placeholder="Last Name" />
                    </Item>
                  </Col>
                  <Col sm={12} span={24}>
                    <Item name="phoneNumber">
                      <Input placeholder="Phone" />
                    </Item>
                  </Col>
                  <Col sm={12} span={24}>
                    <Item name="dob">
                      <Input
                        type="date"
                        id="date_of_birth_alt"
                        name="date_of_birth_alt"
                        min={minDate.toISOString().substr(0, 10)}
                        max={maxDate.toISOString().substr(0, 10)}
                        autoComplete="bday"
                        placeholder="DOB (MM/DD/YYYY)"
                      />
                    </Item>
                  </Col>
                </Row>
              </Col>

              <Col
                xl={12}
                span={24}
                style={{ marginTop: breakpoints.xl ? "-80px" : 0 }}
              >
                <Row>
                  <Col span={24}>
                    <MarginBox mt={36}>
                      {/*<Item name="customerType">
                        <Select
                          placeholder="Customer Type"
                          suffixIcon={
                            <Icon keyword="chevron-down" color="#7D8592" />
                          }
                        >
                          <Option value={1}>Value 1</Option>
                        </Select>
                        </Item>*/}
                    </MarginBox>
                  </Col>
                  <Col span={24}>
                    <Item name="status">
                      <Select
                        placeholder="Status"
                        suffixIcon={
                          <Icon keyword="chevron-down" color="#7D8592" />
                        }
                      >
                        <Option value={1}>active</Option>
                        <Option value={2}>inactive</Option>
                      </Select>
                    </Item>
                  </Col>
                  {/*<Col span={24}>
                    <Item name="taxExemption">
                      <Select
                        suffixIcon={
                          <Icon keyword="chevron-down" color="#7D8592" />
                        }
                        placeholder="Tax Exemption"
                      >
                        <Option value={1}>Value 1</Option>
                      </Select>
                    </Item>
                      </Col>*/}
                  <Col span={24}>
                    <Item name="isTradeUser" valuePropName="checked">
                      <Checkbox>Trade user?</Checkbox>
                    </Item>
                  </Col>
                  {/*<Col span={24}>
                    <Item name="tradeAccountNumber">
                      <Input placeholder="Trade Acct #" />
                    </Item>
                    </Col>*/}
                </Row>
              </Col>
            </Row>
          </TwfCard>
        </Container>
      </Form>
      {!breakpoints.md && <CreateMobileFooter />}
    </div>
  );
};

export default CustomerEdit;
