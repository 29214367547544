import { Dropdown, Grid, Menu, message, Table } from "antd";
import Container from "component/container";
import { ClubCustomerState } from "enums/enums";
import {
  useGetClubCustomerListQuery,
  useGetClubListLazyQuery,
  useUpdateClubCustomerMutation,
} from "generated/graphql";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import { TwfCard } from "../../../component/card";
import { MarginBox } from "../../../component/margin";
import AddToClub from "./add-to-club";
import { LogicalOperator } from "generated/graphql";

const { useBreakpoint } = Grid;

interface ComponentProps {}

const CustomerSingleClub: React.FC<ComponentProps> = () => {
  const breakpoints = useBreakpoint();
  const { id } = useParams<{ id: string }>();
  const cid: string = id as string;
  const [clubAddVisible, setClubAddVisible] = useState(false);
  const { data } = useGetClubCustomerListQuery({
    variables: { customerId: cid },
  });

  const [getClubsQuery, { data: clubList }] = useGetClubListLazyQuery();

  useEffect(() => {
    if (data?.clubCustomers) {
      getClubsQuery({
        variables: {
          options: {
            filter: {
              id: {
                notIn: data.clubCustomers.items.map((customer) =>
                  customer.club.id.toString()
                ),
              },
              active: {
                eq: true,
              }
            },
            filterOperator: LogicalOperator.And
          },
        },
        fetchPolicy: "network-only"
      });
    }
  }, [data, getClubsQuery]);

  const [updateClubCustomerMutation] = useUpdateClubCustomerMutation();

  const updateClubCustomer = (id: string, status: ClubCustomerState) => {
    updateClubCustomerMutation({
      variables: { input: { id, status } },
      refetchQueries: ["GetClubCustomerList"],
    })
      .then(({ data }) => {
        if (data?.updateClubCustomer.__typename === "ClubCustomer")
          message.success("Subscription updated");
      })
      .catch((error) => message.error(error.message));
  };

  const clubAction = (record: any) => (
    <Menu style={{ borderRadius: 12 }}>
      <Menu.Item
        key="Paused"
        onClick={() => updateClubCustomer(record.id, ClubCustomerState.Paused)}
      >
        Pause Membership
      </Menu.Item>
      <Menu.Item
        key="Cancelled"
        onClick={() =>
          updateClubCustomer(record.id, ClubCustomerState.Cancelled)
        }
      >
        Cancel Membership
      </Menu.Item>
      <Menu.Item
        key="Active"
        onClick={() => updateClubCustomer(record.id, ClubCustomerState.Active)}
      >
        Activate Membership
      </Menu.Item>
    </Menu>
  );

  const columns: any = [
    {
      title: "Club Name",
      dataIndex: ["club", "name"],
    },
    {
      title: "User Choice",
      dataIndex: "userChoice",
      render: (userChoice: boolean) => (userChoice ? "Yes" : "No"),
    },
    {
      title: "Signup Date",
      dataIndex: "signUpDate",
      render: (signUpDate: any) => new Date(signUpDate).toLocaleString(),
    },
    {
      title: "Cancel Date",
      dataIndex: "cancelDate",
      render: (cancelDate: any) =>
        cancelDate ? new Date(cancelDate).toLocaleString() : "",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: number) => ClubCustomerState[status],
    },
    {
      title: "Actions",
      render: (data: any) => {
        return (
          <Dropdown placement="bottomRight" overlay={() => clubAction(data)}>
            {breakpoints.md ? (
              <TwfButton size-twf="sm" color-twf="gray-line">
                Club Actions <Icon keyword="chevron-down" color="#7D8592" />
              </TwfButton>
            ) : (
              <TwfButton shape="circle" type-twf="icon">
                <Icon keyword="collapse" color="#7D8592" width={16} />
              </TwfButton>
            )}
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Container>
      <TwfCard>
        <MarginBox
          pr={breakpoints.md ? 75 : 15}
          pl={breakpoints.md ? 75 : 15}
          mt={12}
          mb={56}
        >
          <TwfButton
            type="primary"
            color-twf="primary"
            size-twf="md"
            type-twf="wide"
            icon={<Icon keyword="plus" width={16} color="#fff" />}
            style={{ float: "right", marginBottom: "2%", marginTop: "2%" }}
            onClick={() => {
              setClubAddVisible(true);
            }}
          >
            Add Customer To Club
          </TwfButton>
          <Table
            columns={columns}
            dataSource={data?.clubCustomers.items.map((obj) => ({
              ...obj,
              key: obj.id,
            }))}
            pagination={false}
            style={{ maxWidth: "max-content" }}
            scroll={{ x: "max-content" }}
          />
        </MarginBox>
      </TwfCard>
      <AddToClub
        key={Math.floor(Math.random() * 10000)}
        customerID={id!}
        nonInvolvedClubs={clubList?.clubs.items || []}
        setVisible={setClubAddVisible}
        visible={clubAddVisible}
      />
    </Container>
  );
};

export default CustomerSingleClub;
