import { Col, Row, Table } from "antd";
import { message } from "antd/es";
import Icon from "assets/icons";
import BackButton from "component/button/back";
import Container from "component/container";
import { 
  useDeleteEmailCategoryMutation,
  useEmailCategoriesQuery,
} from "generated/graphql";
import { FC, useState } from "react";
import TwfButton from "../../component/button";
import { TwfCard } from "../../component/card";
import { PageTitle } from "../../component/header";
import { MarginBox } from "../../component/margin";
import AddNewEmailCategory from "./components/addNewCategory";
import UpdateEmailCategory from "./components/updateCategory";
import { useNavigate } from "react-router-dom";

interface PageProps {}

const EmailCategoryPage: FC<PageProps> = () => {

  const [visible, setVisible] = useState(false);
  const history = useNavigate();

  const [updateVisible, setUpdateVisible] = useState(false);
  const [emailCategory, setEmailCategory] = useState<any>();

  const { data } = useEmailCategoriesQuery({
    variables:{
      options:{}
    }
  });

  const [ deleteEmailCategoryMutation ] = useDeleteEmailCategoryMutation();

  const deleteEmailCategory = (categoryId:string) => {
    deleteEmailCategoryMutation({
      variables:{
        id:categoryId
      },
      refetchQueries:["EmailCategories"]
    }).then(()=>{
      message.success("Email Category Successfully Deleted");
      setTimeout(()=>{
        history(0);
      },2000);
    }).catch((error)=>{
      message.error(error.message);
    })
  }

  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      align: "center",
    },
    {
      title: "",
      key: "",
      align: "center",
    },
    {
        title: "Action",
        key: "action",
        render: (emailCategory:any) => {
          return (
            <>
            <TwfButton onClick={ () =>{
              setEmailCategory(emailCategory);
              setUpdateVisible(true);
            }}  shape="circle" type-twf="icon">
              <Icon keyword="edit" color="#3C64B1" />
            </TwfButton>
            <TwfButton onClick={ () =>{
              deleteEmailCategory(emailCategory.id)
            }} shape="circle" type-twf="icon">
              <Icon keyword="delete" color="red" />
            </TwfButton>
            </>
          );
        },
    },
    
  ];

  return (
    <div>
      <Row gutter={[30, 15]} justify="space-between">
        <Col>
          <PageTitle>
          <BackButton/> &nbsp;
            Email Categories
          </PageTitle>
        </Col>
        <Col>
          <Row gutter={[20, 20]}>
            <TwfButton
            type="primary"
            color-twf="primary"
            size-twf="md"
            type-twf="wide"
            onClick = {
              ()=>{
                setVisible(true);
              }
            }
            >
              <Icon keyword="plus" color="#ffffff" />
              Create New Category
            </TwfButton>
          </Row>
        </Col>
      </Row>
      <MarginBox mt={32}>
        <Container>
          <TwfCard>
            <MarginBox mt={12} mb={56}>
              <Table
                columns={columns}
                style={{ maxWidth: "max-content" }}
                scroll={{ x: "max-content" }}
                dataSource={data?.emailCategories.items}
                pagination={false}
              />
            </MarginBox>
          </TwfCard>
        </Container>
      </MarginBox>
      <AddNewEmailCategory visible={visible} setVisible={setVisible}/>
      <UpdateEmailCategory visible={updateVisible} setVisible={setUpdateVisible} emailCategory={emailCategory}/>
    </div>
  );
};

export default EmailCategoryPage;
