import { Col, Collapse, Form, Input, Row, message } from "antd";
import { TwfCard } from "component/card";
import ColorPicker from "component/colorPicker";
import AssetSelector from "pages/assets/component/asset-selector";
import React, { useEffect, useState } from "react";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import { H3 } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import TwfModal from "../../../component/modal";
import ModalTitle from "../../../component/modal/title";
import {
  Asset,
  ChannelFragment,
  UpdateChannelInput,
  useUpdateChannelMutation,
} from "../../../generated/graphql";
import { PanelContent } from "./create";

const { Item } = Form;
const { Panel } = Collapse;

interface UpdateChannelFormData {
  token: string;
  customFields: {
    brandName: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    phone: string;
    address: string;
    state: string;
    zip: string;
    membershipLevel: number;
    logo: string;
    legalEntityName: string;
    headerColor: string;
    footerColor: string;
    brandPhone: string;
    webmasterEmail: string;
    orderConfirmation: string;
    clubConfirmation: string;
    orderPrefix: string;
    instagram: string;
    facebook: string;
    linkedIn: string;
    twitter: string;
    pinterest: string;
    youtube: string;
    others: string;

    home: string;
    about: string;
    newsEvents: string;
    recipesPairings: string;
    gallery: string;
    ownerWinemaker: string;
    whereToBuy: string;
    contactUs: string;
    mailingList: string;
    wineShop: string;
    events: string;
    awards: string;
    contact: string;
    blog: string;
    wineClub: string;
    ourStory: string;
    vineyards: string;

    complianceBrandKey: string;
  };
}

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  channel?: ChannelFragment;
}

const EditChannel: React.FC<ComponentProps> = ({
  visible,
  setVisible,
  channel,
}) => {
  const [form] = Form.useForm();
  const [updateChannelMutation, { loading }] = useUpdateChannelMutation();

  const [headerColor, setHeaderColor] = useState("");
  const [footerColor, setFooterColor] = useState("");
  const [logo, setLogo] = useState<Asset | null>(null);

  useEffect(() => {
    form.setFieldsValue(channel);
    if (channel?.customFields?.headerColor)
      setHeaderColor(channel.customFields.headerColor);
    if (channel?.customFields?.footerColor)
      setFooterColor(channel.customFields.footerColor);
  }, [channel, form]);

  const submitFormHandler = (formData: UpdateChannelFormData): void => {
    if (!formData.customFields.complianceBrandKey) {
      message.error("Please enter compliance brand key under Brand Profile!");
      return;
    }
    if (!formData.customFields.orderPrefix) {
      message.error("Please enter order prefix under Brand Profile!");
      return;
    }

    if (!formData.customFields.membershipLevel) {
      message.error(
        "Please enter membership level (cost) under Owner Profile!"
      );
      return;
    }
    updateChannel(formData)
      .then((message: string) => notifySuccess(message))
      .catch((error: string) => message.error(error));
  };

  const updateChannel = (formData: UpdateChannelFormData): Promise<string> => {
    return new Promise((resolve, reject) => {
      updateChannelMutation({
        variables: { input: prepareUpdateChannelInput(formData) },
        refetchQueries: ["GetChannels"],
      })
        .then(({ data }) => {
          if (data?.updateChannel.__typename === "Channel")
            resolve("Store updated");
        })
        .catch((error) => reject(error.message));
    });
  };

  const prepareUpdateChannelInput = (
    formData: UpdateChannelFormData
  ): UpdateChannelInput => {
    return {
      id: channel!.id,
      code: formData.customFields.brandName,
      token: formData.token,
      customFields: {
        brandName: formData.customFields.brandName,
        firstName: formData.customFields.firstName,
        lastName: formData.customFields.lastName,
        email: formData.customFields.email,
        phone: formData.customFields.phone,
        address: formData.customFields.address,
        state: formData.customFields.state,
        zip: formData.customFields.zip,
        membershipLevel: formData.customFields.membershipLevel.toString() || "",
        logo: logo?.source,
        legalEntityName: formData.customFields.legalEntityName,
        headerColor: formData.customFields.headerColor,
        footerColor: formData.customFields.footerColor,
        brandPhone: formData.customFields.brandPhone,
        webmasterEmail: formData.customFields.webmasterEmail,
        orderConfirmation: formData.customFields.orderConfirmation,
        clubConfirmation: formData.customFields.clubConfirmation,
        orderPrefix: formData.customFields.orderPrefix,
        instagram: formData.customFields.instagram,
        facebook: formData.customFields.facebook,
        linkedIn: formData.customFields.linkedIn,
        twitter: formData.customFields.twitter,
        pinterest: formData.customFields.pinterest,
        youtube: formData.customFields.youtube,
        others: formData.customFields.others,
        complianceBrandKey: formData.customFields.complianceBrandKey,
      },
    };
  };

  const notifySuccess = (result: string) => {
    message.success(result);
    setVisible(false);
    form.resetFields();
  };

  const assetSelectHandler = (asset: Asset) => {
    setLogo(asset);
  };

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closeIcon={<Icon keyword="close" />}
      footer={false}
      width={662}
      centered
      getContainer={false}
    >
      <ModalTitle>
        <H3>Edit Store</H3>
      </ModalTitle>
      <Form form={form} layout="vertical" onFinish={submitFormHandler}>
        <TwfCard style={{ padding: "4px 0 4px" }}>
          <Collapse activeKey={["1", "2", "3", "4", "5"]}>
            <Panel header="Basic Info" key="1">
              <PanelContent>
                <Item
                  label="Brand"
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the brand name!",
                    },
                  ]}
                >
                  <Input
                    disabled={
                      form.getFieldValue("code") === "__default_channel__"
                    }
                  />
                </Item>
                <Item
                  label="Store token"
                  name="token"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the store token!",
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="First name"
                  name={["customFields", "firstName"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the first name!",
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="Last name"
                  name={["customFields", "lastName"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the last name!",
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="Email"
                  name={["customFields", "email"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the email!",
                    },
                  ]}
                >
                  <Input />
                </Item>
              </PanelContent>
            </Panel>
            <Panel header="Owner Profile" key="2">
              <PanelContent>
                <Item label="Phone Number" name={["customFields", "phone"]}>
                  <Input />
                </Item>
                <Item label="Address" name={["customFields", "address"]}>
                  <Input />
                </Item>
                <Item label="State" name={["customFields", "state"]}>
                  <Input />
                </Item>
                <Item label="Zip" name={["customFields", "zip"]}>
                  <Input />
                </Item>
                <Item
                  label="Membership Level"
                  name={["customFields", "membershipLevel"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the membership level!",
                    },
                  ]}
                >
                  <Input type="number" />
                </Item>
              </PanelContent>
            </Panel>
            <Panel header="Brand Profile" key="3">
              <PanelContent>
                <Item label="Logo" name="logo">
                  <AssetSelector
                    onAssetSelect={assetSelectHandler}
                    multi={false}
                  />
                </Item>
                <Item
                  label="Legal Entity Name"
                  name={["customFields", "legalEntityName"]}
                >
                  <Input />
                </Item>
                <Item
                  label="Header Color"
                  name={["customFields", "headerColor"]}
                >
                  <ColorPicker
                    color={headerColor}
                    onChange={(color) => setHeaderColor(color)}
                  />
                </Item>
                <Item
                  label="Footer Color"
                  name={["customFields", "footerColor"]}
                >
                  <ColorPicker
                    color={footerColor}
                    onChange={(color) => setFooterColor(color)}
                  />
                </Item>
                <Item
                  label="Phone Number"
                  name={["customFields", "brandPhone"]}
                >
                  <Input />
                </Item>
                <Item
                  label="Webmaster Email"
                  name={["customFields", "webmasterEmail"]}
                >
                  <Input />
                </Item>
                <Item
                  label="Order Confirmation"
                  name={["customFields", "orderConfirmation"]}
                >
                  <Input />
                </Item>
                <Item
                  label="Club Confirmation"
                  name={["customFields", "clubConfirmation"]}
                >
                  <Input />
                </Item>
                <Item
                  label="Order Prefix"
                  name={["customFields", "orderPrefix"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the order prefix!",
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="Ship Compliance Brand Key"
                  name={["customFields", "complianceBrandKey"]}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the ship compliance brand key!",
                    },
                  ]}
                >
                  <Input />
                </Item>
                {/*
                <Item label="Ship Compliant Key" name="shipCompliantKey">
                  <Input disabled={true} value="Key" />
                </Item>
                */}
              </PanelContent>
            </Panel>
            <Panel header="Social Media" key="4">
              <PanelContent>
                <Item label="Instagram" name={["customFields", "instagram"]}>
                  <Input />
                </Item>
                <Item label="Facebook" name={["customFields", "facebook"]}>
                  <Input />
                </Item>
                <Item label="Linkedln" name={["customFields", "linkedIn"]}>
                  <Input />
                </Item>
                <Item label="Twitter" name={["customFields", "twitter"]}>
                  <Input />
                </Item>
                <Item label="Pinterest" name={["customFields", "pinterest"]}>
                  <Input />
                </Item>
                <Item label="YouTube" name={["customFields", "youtube"]}>
                  <Input />
                </Item>
                <Item label="Others" name={["customFields", "others"]}>
                  <Input />
                </Item>
              </PanelContent>
            </Panel>
            <Panel header="Main Menu" key="5">
              <PanelContent>
                <Item label="Home" name={["customFields", "home"]}>
                  <Input />
                </Item>
                <Item label="About" name={["customFields", "about"]}>
                  <Input />
                </Item>
                <Item label="News/Events" name={["customFields", "newsEvents"]}>
                  <Input />
                </Item>
                <Item
                  label="Recipes Pairings"
                  name={["customFields", "recipesPairings"]}
                >
                  <Input />
                </Item>
                <Item
                  label="Owner/Wine Maker"
                  name={["customFields", "ownerWinemaker"]}
                >
                  <Input />
                </Item>
                <Item
                  label="Where To Buy?"
                  name={["customFields", "whereToBuy"]}
                >
                  <Input />
                </Item>
                <Item label="Contact Us" name={["customFields", "contactUs"]}>
                  <Input />
                </Item>
                <Item
                  label="Mailing List"
                  name={["customFields", "mailingList"]}
                >
                  <Input />
                </Item>
                <Item label="Wine Shop" name={["customFields", "wineShop"]}>
                  <Input />
                </Item>
                <Item label="Events" name={["customFields", "events"]}>
                  <Input />
                </Item>
                <Item label="Awards" name={["customFields", "awards"]}>
                  <Input />
                </Item>
                <Item label="Contact" name={["customFields", "contact"]}>
                  <Input />
                </Item>
                <Item label="Blog" name={["customFields", "blog"]}>
                  <Input />
                </Item>
                <Item label="Wine Club" name={["customFields", "wineClub"]}>
                  <Input />
                </Item>
                <Item label="Our Story" name={["customFields", "ourStory"]}>
                  <Input />
                </Item>
                <Item label="Vineyards" name={["customFields", "vineyards"]}>
                  <Input />
                </Item>
              </PanelContent>
            </Panel>
          </Collapse>
        </TwfCard>
        <MarginBox mt={48}>
          <Row gutter={12} justify="end">
            <Col>
              <TwfButton
                color-twf="gray-line"
                size-twf="md"
                type-twf="wide"
                onClick={() => {
                  setVisible(false);
                  form.resetFields();
                }}
              >
                Cancel
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                type="primary"
                color-twf="primary"
                size-twf="md"
                type-twf="wide"
                htmlType="submit"
                loading={loading}
              >
                Save
              </TwfButton>
            </Col>
          </Row>
        </MarginBox>
      </Form>
    </TwfModal>
  );
};

export default EditChannel;
