import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  Grid,
  Menu,
  Table,
  TablePaginationConfig,
  Tag,
  message,
  Modal,
  Button
} from "antd";
import TwfButton from "../../../component/button";
import { ChevronDownIcon } from "../../../assets/icons/chevron";
import TwfLink from "../../../component/button/link";
import OrderView from "./view";
import React, { useEffect } from "react";
import ComplianceView from "./compliance";
import {
  Customer,
  HistoryEntry,
  HistoryEntryType,
  Order,
  SortOrder,
  useGetOrderLazyQuery,
  useRefundOrderMutation,
} from "../../../generated/graphql";
import Icon from "../../../assets/icons";
import { orderClassifications, complianceStatuses } from "../../../enums/enums";
import {
  useSetOrderCustomFieldsMutation,
  useCancelOrderMutation,
  useDeleteOrderFromComplianceMutation,
} from "./../../../generated/graphql";
import InvoiceView from "./invoice";
import { OrderFilter } from "..";
import { GET_FILTERED_ORDERS } from "graphql/queries.graphql";
import { useLazyQuery } from "@apollo/client";

import { format } from "date-fns";
import moment from "moment";
import OrderHistory from "./history";

export const formatDateTime = (date: string | Date = new Date()): string =>
  format(new Date(date), "L/d/Y h:mmaaa");

export const formatDate = (date: string | Date = new Date()): string =>
  format(new Date(date), "L/d/Y");

export const formatTime = (date: string | Date = new Date()): string =>
  format(new Date(date), "h:mmaaa");

const { useBreakpoint } = Grid;

interface ViewProps {
  filter: OrderFilter;
}

export interface TableOptions {
  pagination: TablePaginationConfig;
  sort?: any;
}

const DataTable: React.FC<ViewProps> = ({ filter }) => {
  const history = useNavigate();

  const [getOrders, { data, loading }] = useLazyQuery(GET_FILTERED_ORDERS, {
    fetchPolicy: "network-only",
  });

  /*const orderHistory = [
    {
      "date": "2023-02-10T13:55:58.784Z",
      "heading": "Payment Settled",
      "text": "Some text"
    },
    {
      "date": "2023-02-10T13:55:58.784Z",
      "heading": "Payment Settled",
      "text": "Some text"
    },
  ];*/

  const [orderHistory, setOrderHistory] = useState<any[]>([]);

  const [setOrderCustomFieldsMutation, { data: setCustomFieldResult }] =
    useSetOrderCustomFieldsMutation();

  const [cancelOrderMutation] = useCancelOrderMutation();
  const [deleteOrderFromComplianceMutation] =
    useDeleteOrderFromComplianceMutation();

  const breakpoints = useBreakpoint();

  let isNotSuperAdmin = true;

  if (typeof window !== "undefined") {
    if (localStorage.getItem("used-channel") === "__default_channel__") {
      isNotSuperAdmin = false;
    }
  }

  const [tableOptions, setTableOptions] = useState<TableOptions>({
    pagination: { current: 1, pageSize: 10 },
    sort: { updatedAt: SortOrder.Desc },
  });
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [cust, setCust] = useState<any>();
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [viewVisible, setViewVisible] = useState<boolean>(false);
  const [orderHistoryVisible, setOrderHistoryVisible] = useState<boolean>(false);
  const [complianceVisible, setComplianceVisible] = useState<boolean>(false);
  const [invoiceVisible, setInvoiceVisible] = useState<boolean>(false);
  const [getOrderQuery] = useGetOrderLazyQuery();

  const [refundOrderMutation] = useRefundOrderMutation();

  let queryVariables = {
    options: {
      filter: {
        archived: {
          eq: filter.archived,
        },
      },
      skip:
        (tableOptions.pagination.pageSize || 10) *
        ((tableOptions.pagination.current || 1) - 1),
      take: tableOptions.pagination.pageSize,
      sort: tableOptions.sort,
    },
    filterValues: {
      filterData: filter.data,
      orderState: filter.orderState,
      orderStartDate: filter.orderStartDate,
      orderEndDate: filter.orderEndDate,
    },
  } as any;

  const refetchOrder = [
    {
      query: GET_FILTERED_ORDERS,
      variables: queryVariables,
    },
  ];

  useEffect(() => {
    getOrders({
      variables: queryVariables,
      fetchPolicy: "network-only",
    });
    // eslint-disable-next-line
  }, [
    getOrders,
    setCustomFieldResult,
    tableOptions,
    filter,
    localStorage.getItem("vendure-token"),
  ]);

  useEffect(() => {
    if (setCustomFieldResult) {
      message.success("Successfully Changed Archive State!");
    }
  }, [setCustomFieldResult]);

  const tableChangeHandler = (
    pagination: TablePaginationConfig,
    filters: any,
    sort: any
  ) => {
    setTableOptions({
      pagination,
      sort: sort.field
        ? {
            [sort.field]:
              sort.order === "ascend" ? SortOrder.Asc : SortOrder.Desc,
          }
        : tableOptions.sort,
    });
  };

  const columns: any = [
    {
      title: "Order ID",
      //width: 80,
      sorter: {},
      render: (record: Order) => {
        /*const prefix = record.channel?.customFields?.orderPrefix
          ? record.channel?.customFields?.orderPrefix + "-"
          : "";
        return prefix + record.id;*/
        return record.code;
      },
    },
    {
      title: "Order Status",
      dataIndex: "state",
      //width: 100,
      render: (status: any) => {
        switch (status) {
          case "ComplianceSuccess":
            status = "Success";
            return <Tag color="#039108">{status}</Tag>;
          case "ComplianceFailed":
            status = "Failed";
            return <Tag color="#eb190e">{status}</Tag>;
          case "Shipped":
            return <Tag color="#039108">{status}</Tag>;
          case "Delivered":
            return <Tag color="#039108">{status}</Tag>;
          case "ArrangingPayment":
            return <Tag color="#0e84eb">{status}</Tag>;
          case "AddingItems":
            return <Tag color="orange">Abandoned Cart</Tag>;
          case "PaymentSettled":
            return <Tag color="#FB8832">{status}</Tag>;
          case "Cancelled":
            return <Tag color="#eb190e">{status}</Tag>;
          case "PaymentDeclined":
            return <Tag color="#eb190e">{status}</Tag>;
          default:
            return <Tag color="#FB8832">{status}</Tag>;
        }
      },
    },
    /*
    {
      title: "Tracking Code",
      dataIndex: "code",
      sorter: {},
    },
    */
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (customer: any) => {
        return (
          customer && (
            <TwfLink
              to={`/customers/single/${customer.id}`}
            >{`${customer.firstName} ${customer.lastName}`}</TwfLink>
          )
        );
      },
    },
    {
      title: "Order Type",
      //width: 120,
      dataIndex: "customFields",
      render: (customFields: any) => {
        return orderClassifications.find(
          (c) => c.value === customFields?.orderClassification
        )?.label;
      },
    },
    {
      title: "Order Updated At",
      dataIndex: "updatedAt",
      sorter: true,
      render: (updatedAt: any) => moment(updatedAt).fromNow(),
      //width: 140,
    },
    {
      title: "Order Placed At",
      dataIndex: "orderPlacedAt",
      sorter: true,
      render: (orderPlacedAt: any) =>
        orderPlacedAt ? formatDateTime(orderPlacedAt) : "-",
      //width: 140,
    },
    {
      //TODO add dollar sign and format it every 3 digit
      title: "Amount",
      sorter: {},
      render: (record: any) => {
        if (record.state !== "Cancelled") {
          return (record.totalWithTax / 100).toFixed(2);
        } else {
          return (record.customFields.cancelledTotalWithTax / 100).toFixed(2);
        }
      },
      width: 100,
    },
    {
      title: "Compliance Status",
      //dataIndex: "customFields",
      //width: 100,
      render: (order: any) => {
        const value = complianceStatuses.find(
          (c) => c.value === order.customFields.complianceStatus
        )?.label;
        const orderType = orderClassifications.find(
          (c) => c.value === order.customFields?.orderClassification
        )?.label;
        if (order.customFields.complianceStatus === 1) {
          return <Tag color="#4AAF05">{value}</Tag>;
        } else if (order.customFields.complianceStatus === 0) {
          return <Tag color="#eb190e">{value}</Tag>;
        } else {
          if(orderType === "Trade"){
            return <Tag color="orange">N/A</Tag>;
          }else{
            return <Tag color="orange">Not Processed</Tag>;
          }
        }
      },
    },
    {
      title: "",
      key: "action",
      width: breakpoints.md ? 100 : 50,
      fixed: "right",
      render: (record: any) => {
        const orderdropdown: any = [
          {
            key: `view${record ? record.key : ""}`,
            label: "View Order",
            onClick: () => {
              onViewHandler(record);
            },
          },
          {
            key: `edit${record ? record.key : ""}`,
            label: "Edit Order",
            onClick: () => {
              onEditHandler(record);
            },
            disabled:
              record.state === "Cancelled" ||
              record.state === "Delivered" ||
              record.state === "Shipped" ||
              isNotSuperAdmin,
          },
          {
  key: `void${record ? record.key : ""}`,
  label: "Void Order",
  disabled: record.state === "Cancelled",
  //|| record.state === "Delivered",
  onClick: () => {
    Modal.confirm({
      title: "Are you sure you want to void this order?",
      onOk: () => refundOrder(record.id, record.code, record),
      onCancel: () => {},
      okText:'YES',
      cancelText:'NO'
    });
  },
  render: () => (
    <Button type="link">Void Order</Button>
  ),
},
          {
            key: `compliance${record ? record.key : ""}`,
            label: "View Compliance",
            onClick: () => {
              onViewComplianceHandler(record.id);
            },
            disabled:
              record.customFields?.byPassed ||
              record.state === "AddingItems" ||
              record.state === "ArrangingPayment",
          },
          // {
          //   key: `slip${record ? record.key : ""}`,
          //   label: "Packing Slip",
          //   onClick: () => {
          //     onSlipHandler(record.id);
          //   },
          // },
          {
            key: `print${record ? record.key : ""}`,
            label: "Print Invoice",
            onClick: () => {
              onPrintHandler(record.id);
            },
            disabled: record.state === "Cancelled",
          },
          {
            key: `archive${record ? record.key : ""}`,
            label: filter.archived ? "UnArchive Order" : "Archive Order",
            onClick: () => {
              onArchiveHandler(record);
            },
          },
          {
            key: `history${record ? record.key : ""}`,
            label: "Order History",
            onClick: () => {
              onOrderHistoryHandler(record);
            },
          },
        ];
        return (
          <Dropdown
            placement="bottomRight"
            overlay={
              <>
                <Menu
                  style={{ borderRadius: "12px", border: "1px solid #E5E5E5" }}
                  items={orderdropdown}
                ></Menu>
              </>
            }
          >
            {breakpoints.md ? (
              <TwfButton color-twf="gray-line" size-twf="sm">
                Order Actions
                <ChevronDownIcon color="#979797" width={18} />
              </TwfButton>
            ) : (
              <TwfButton shape="circle" type-twf="icon">
                <Icon keyword="collapse" width={16} />
              </TwfButton>
            )}
          </Dropdown>
        );
      },
    },
  ];

  const onselectionchange = (selectedRowKeys: any[]) => {
    setSelectedKeys(selectedRowKeys);
  };

  //actions
  const onViewHandler = (record: any) => {
    setViewVisible(true);
    setSelectedRecord(record);
  };

  const historyMapper = (history: HistoryEntry, customer?: Customer) => {
    let text = '';
    let heading = '';
    switch(history.type) {
      case HistoryEntryType.OrderStateTransition:
        heading = 'Order State Changed';
        text = `Order state changed from "${history.data.from}" to "${history.data.to}"`;
      break;
      case HistoryEntryType.OrderCouponApplied:
        heading = 'Coupon Applied';
        text = `Coupon "${history.data.couponCode}" applied`;
      break;
      case HistoryEntryType.OrderCouponRemoved:
        heading = 'Coupon Removed';
        text = `Coupon "${history.data.couponCode}" removed`;
      break;
      case HistoryEntryType.OrderPaymentTransition:
        heading = 'Payment State Changed';
        text = `Payment ${history.data.to}`;
      break;
      case HistoryEntryType.OrderCancellation:
        heading = 'Order Cancelled';
        text = `Order cancelled`;
      break;
      case HistoryEntryType.OrderFulfillment:
        heading = 'Order Fulfilled';
        text = `Order fulfilled: ${history.data.method}`;
      break;
      case HistoryEntryType.OrderRefundTransition:
        heading = 'Order Refund';
        text = `Order refund state changed from "${history.data.from}" to "${history.data.to}"`;
      break;
      case HistoryEntryType.OrderNote:
        heading = 'Order Note';
        text = `${history.data.note}`;
      break;
      case HistoryEntryType.OrderModified:
        heading = 'Order Modified';
        text = `Order modified`;
      break;
    }
    let creator = '';
    if(customer?.firstName && customer?.lastName){
      creator = customer.firstName + ' ' + customer.lastName;
    }
    if(history.administrator?.firstName && history.administrator?.lastName){
      creator = history.administrator.firstName + ' ' + history.administrator.lastName;
    }
    return {
      date: history.createdAt,
      creator: creator,
      heading,
      text,
    }
  }

  const [historyPagination, setHistoryPagination] = useState({
    current: 1, pageSize: 5
  });
  const [totalHistoryItems, setTotalHistoryItems] = useState(0);
  const onOrderHistoryHandler = (record: any) => {
      getOrderQuery({
        variables: {
          id: record.id,
          historyEntryListOptions:{
            sort:{
              createdAt: SortOrder.Desc,
            },
            skip:
            (historyPagination.pageSize) *
            ((historyPagination.current) - 1),
           take: historyPagination.pageSize,
          }
        },
      })
        .then((data) => {
          if (data.data?.order) {
            const history = data.data.order.history;
            setTotalHistoryItems(history.totalItems);
            if(history.totalItems!==0){
              const mapperArray: any[] = [];
              history.items.forEach((history: any)=>{
                if(data.data?.order?.customer){
                  const mapper = historyMapper(history, data.data.order.customer as any);
                  mapperArray.push(mapper);
                }else{
                  const mapper = historyMapper(history);
                  mapperArray.push(mapper);
                }
              })
              setOrderHistory(mapperArray);
            }
            setOrderHistoryVisible(true);
            setSelectedRecord(record);
          } else {
            message.error("The order seems not available in admin panel");
          }
        })
        .catch((error) => {
          message.error(error);
        });
  };

  useEffect(()=>{
    if (!selectedRecord || Object.keys(selectedRecord).length === 0 || !orderHistoryVisible) return;
    onOrderHistoryHandler(selectedRecord);
  },[historyPagination])

  // useEffect(()=>{
  //   if(orderHistoryVisible) return;
  //   setHistoryPagination((p)=>{
  //     return {current:1,pageSize:p.pageSize};
  //   });
  // },[orderHistoryVisible])

  const onEditHandler = (record: any) => {
    const currentToken = localStorage.getItem("vendure-token");
    if (currentToken !== record?.channel?.token) {
      message.error(
        `Please login to store ${record?.channel?.customFields?.brandName} to edit the order`
      );
    } else {
      history(`/orders/edit/${record.id}`);
    }
  };


  //TODO ask if refund should be issued if order status in shipped or Delivered 
  const refundOrder = (id: any, code: string, order: Order) => {
    switch (order.state) {
      case "PaymentSettled":
      case "ComplianceFailed":
      case "ComplianceSuccess":
      case "Shipped":
      case "Delivered":
        refundAndCancelOrder(id, code, order);
        break;
      default:
        onVoidHandler(id, code);
        break;
    }
  };
  

  const refundAndCancelOrder = (id: any, code: string, order: Order) => {
    let lines = [] as any;
    if (order.lines.length !== 0) {
      order.lines.forEach((data) => {
        // eslint-disable-next-line no-lone-blocks
        {
          lines.push({
            orderLineId: data.id,
            quantity: data.quantity,
          });
        }
      });
    }

    let pid;

    if (order.payments && order.payments.length !== 0) {
      pid = order.payments[0].id;
    }

    if (pid && lines.length !== 0) {
      refundOrderMutation({
        variables: {
          input: {
            adjustment: 0,
            shipping: order.shipping,
            paymentId: pid,
            lines: lines,
          },
        },
      }).then((data) => {
        if (data.data?.refundOrder.__typename === "Refund") {
          onVoidHandler(order.id, order.code);
        }
        if(data.data?.refundOrder.__typename === "AlreadyRefundedError"){
          onVoidHandler(order.id, order.code);
        }
        if (
          data.data?.refundOrder.__typename === "MultipleOrderError" ||
          data.data?.refundOrder.__typename === "NothingToRefundError" ||
          data.data?.refundOrder.__typename === "OrderStateTransitionError" ||
          data.data?.refundOrder.__typename === "PaymentOrderMismatchError" ||
          data.data?.refundOrder.__typename === "QuantityTooGreatError" ||
          data.data?.refundOrder.__typename === "RefundOrderStateError" ||
          data.data?.refundOrder.__typename === "RefundStateTransitionError"
        ) {
          message.error(data.data?.refundOrder?.message);
        }
      });
    } else {
      onVoidHandler(id, code);
    }
  };

  const onVoidHandler = (id: any, code: string) => {
    cancelOrderMutation({
      variables: {
        input: {
          orderId: id,
        },
      },
    }).then(({ data }) => {
      const result = Object(data?.cancelOrder);
      if (result.errorCode) {
        message.error(result.message);
      } else {
        deletefromcompliance(code);
      }
    });
  };

  const deletefromcompliance = (code: string) => {
    deleteOrderFromComplianceMutation({
      variables: {
        code: code,
      },
      refetchQueries: refetchOrder,
    }).then(({ data }) => {
      if (data) {
        message.success("Successfully voided the order");
      }
    });
  };

  const onViewComplianceHandler = (id: any) => {
    setComplianceVisible(true);
    setSelectedId(id);
    getOrderQuery({
      variables: {
        id: id,
      },
    })
      .then((data) => {
        if (data.data?.order) {
          setCust(data.data?.order?.customFields);
        } else {
          message.error("The order seems not available in admin panel");
        }
      })
      .catch((error) => {
        message.error(error);
      });
  };
  /*const onSlipHandler = (id: any) => {
    console.log(id);
  };*/

  const onPrintHandler = (id: number) => {
    setInvoiceVisible(true);
    setSelectedId(id);
  };

  const onArchiveHandler = (record: any) => {
    setOrderCustomFieldsMutation({
      variables: {
        input: {
          id: record.id,
          customFields: {
            archived: !record.customFields.archived,
          },
        },
      },
      refetchQueries: refetchOrder,
    });
  };

  const rowSelection = {
    selectedKeys,
    onChange: onselectionchange,
  };

  const filterChannel = (order: Order) => {
    return (
      order.channel !== null &&
      order.customFields?.deleted !== true &&
      order.totalQuantity !== 0
    );
  };

  return (
    <React.Fragment>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data?.filteredOrders?.items
          ?.filter(filterChannel)
          .map((obj: any) => ({
            ...obj,
            key: obj.id,
          }))}
        scroll={{ x: "max-content" }}
        loading={loading}
        pagination={{
          ...tableOptions.pagination,
          total: data?.filteredOrders?.totalItems,
          position: ["bottomCenter"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} Items`,
          showSizeChanger: true,
        }}
        onChange={tableChangeHandler}
        style={{ maxWidth: "max-content" }}
      />
      {selectedRecord.id && (
        <OrderView
          orderId={selectedRecord.id}
          viewVisible={viewVisible}
          setViewVisible={setViewVisible}
          selectedId={selectedId}
          voidHandler={onVoidHandler}
          refetchOrder={refetchOrder}
        />
      )}
      <ComplianceView
        complianceVisible={complianceVisible}
        setComplianceVisible={setComplianceVisible}
        selectedId={selectedId}
        customFields={cust}
      />
      <OrderHistory
        orderHistory={orderHistory}
        orderId={selectedRecord.id}
        totalItems={totalHistoryItems}
        visible={orderHistoryVisible}
        pagination={historyPagination}
        setVisible={setOrderHistoryVisible}
        updatePagination={setHistoryPagination}
      />
      {selectedId && (
        <InvoiceView
          invoiceVisible={invoiceVisible}
          setInvoiceVisible={setInvoiceVisible}
          selectedId={selectedId}
        />
      )}
    </React.Fragment>
  );
};

export default DataTable;
