import styled from "styled-components";
import { variant } from "styled-system";

interface Props {
  bg?: string;
}

const AuthLayoutCard = styled.div<Props>(
  {
    height: "100%",
    width: "100%",
    borderRadius: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 6px 58px rgba(196, 203, 214, 0.103611)",
    textAlign: "center",
    backgroundColor: "#fff",
    position: "relative",
    padding: "24px",
  },
  variant({
    prop: "bg",
    variants: {
      primary: {
        backgroundColor: "#3C64B1",
        "@media(max-width: 991px)": {
          display: "none",
        },
      },
      light: {
        backgroundColor: "#fff",
      },
    },
  })
);

export default AuthLayoutCard;
