import React from "react";
import AuthStepText from "../style/stepText";
import { MarginBox } from "../../../component/margin";
import { H3 } from "../../../component/header";
import { Col, Form, Input, Row } from "antd";
import AuthLayoutWrap from "../style/wrap";
const { Item } = Form;

const RegisterForm: React.FC = () => {
  return (
    <AuthLayoutWrap>
      <AuthStepText>STEP 1/3</AuthStepText>
      <MarginBox mt={7} mb={32}>
        <H3>Personal Information</H3>
      </MarginBox>
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <Item label="First Name" name="firstName">
              <Input />
            </Item>
            <Item label="Last Name" name="lastName">
              <Input />
            </Item>
            <Item label="Email Address" name="email">
              <Input placeholder="youremail@gmail.com" />
            </Item>
            <Item label="Password" name="password">
              <Input type="password" placeholder="••••••••" />
            </Item>
          </Col>
        </Row>
      </Form>
    </AuthLayoutWrap>
  );
};

export default RegisterForm;
