import React, { useEffect, useState } from "react";
import { TwfMenu } from "../style/menu";
import Icon from "../../assets/icons";
import { useNavigate, useLocation } from "react-router-dom";

interface ComponentProps {
  collapsed: boolean;
  setSideMenuVisible: (value: boolean) => void;
}

const TwfClientMenuLists: React.FC<ComponentProps> = ({
  collapsed,
  setSideMenuVisible,
}) => {
  const history = useNavigate();
  const location = useLocation();

  const [keys, setKeys] = useState<any[]>([]);

  useEffect(() => {}, [location]);

  const onRouteHandler = ({ key }: any) => {
    setSideMenuVisible(false);
    history("/user/" + key);
    setKeys([key]);
  };

  const menuItems = [
    {
      key: "dashboard",
      icon: <Icon keyword="home" />,
      label: `${collapsed ? "":"Dashboard"}`,
    },
    {
      key: "my-account",
      icon: <Icon keyword="account" />,
      label: collapsed ? "" : "My Account",
    },
    {
      key: "edit-profile",
      icon: <Icon keyword="pencil" />,
      label: collapsed ? "" :"Edit Profile",
    },
    {
      key: "address-book",
      icon: <Icon keyword="guide" />,
      label: collapsed ? "" : "Address Book",
    },
    {
      key: "my-orders",
      icon: <Icon keyword="picture" />,
      label: collapsed ? "" : "My Orders",
    },
    {
      key: "credit-cards",
      icon: <Icon keyword="credit" />,
      label: collapsed ? "" :"Credit Cards",
    },
    {
      key: "club-list",
      icon: <Icon keyword="club" />,
      label: collapsed ? "" :"Club List",
    },
    {
      key: "upcoming-shipments",
      icon: <Icon keyword="shipping" />,
      label: collapsed ? "" : "Upcoming Shipments",
    },
    {
      key: "change-password",
      icon: <Icon keyword="lock" />,
      label: collapsed ? "" :"Change Password",
    },
    {
      key: "settings",
      icon: <Icon keyword="setting" />,
      label: collapsed ? "" :"Settings",
      className: "bottomPosition",
    },
  ];

  return (
    <TwfMenu
      mode="inline"
      selectedKeys={keys}
      defaultSelectedKeys={["dashboard"]}
      onClick={onRouteHandler}
      items={menuItems}
    ></TwfMenu>
  );
};

export default TwfClientMenuLists;
