import React from "react";
import { Table } from "antd";
import { SettingPanelProps } from "./../index";

const SocialMediaSetting: React.FC<SettingPanelProps> = ({
  channel,
  columns,
  isMain
}) => {
  const customFields = channel?.customFields;

  const  isValidHttpUrl = (value: string) => {
    let url;
    
    try {
      url = new URL(value);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  const data = [
    {
      key: "instagram",
      option: "Instagram",
      value: customFields?.instagram,
      render: (value: string) => {
        if(isValidHttpUrl(value)){
          return <a href={value} target="_blank" rel="noreferrer">{value}</a>
        }else{
          return value;
        }
      }, 
      readonly: isMain===true? null:true,
    },
    {
      key: "facebook",
      option: "Facebook",
      value: customFields?.facebook,
      render: (value: string) => {
        if(isValidHttpUrl(value)){
          return <a href={value} target="_blank" rel="noreferrer">{value}</a>
        }else{
          return value;
        }
      }, 
      readonly: isMain===true? null:true,
    },
    {
      key: "linkedIn",
      option: "Linkedln",
      value: customFields?.linkedIn,
      render: (value: string) => {
        if(isValidHttpUrl(value)){
          return <a href={value} target="_blank" rel="noreferrer">{value}</a>
        }else{
          return value;
        }
      }, 
      readonly: isMain===true? null:true,
    },
    {
      key: "twitter",
      option: "Twitter ",
      value: customFields?.twitter,
      render: (value: string) => {
        if(isValidHttpUrl(value)){
          return <a href={value} target="_blank" rel="noreferrer">{value}</a>
        }else{
          return value;
        }
      }, 
      readonly: isMain===true? null:true,
    },
    {
      key: "pinterest",
      option: "Pinterest",
      value: customFields?.pinterest,
      render: (value: string) => {
        if(isValidHttpUrl(value)){
          return <a href={value} target="_blank" rel="noreferrer">{value}</a>
        }else{
          return value;
        }
      }, 
      readonly: isMain===true? null:true,
    },
    {
      key: "youtube",
      option: "YouTube",
      value: customFields?.youtube,
      render: (value: string) => {
        if(isValidHttpUrl(value)){
          return <a href={value} target="_blank" rel="noreferrer">{value}</a>
        }else{
          return value;
        }
      }, 
      readonly: isMain===true? null:true,
    },
    {
      key: "tiktok",
      option: "TikTok",
      value: customFields?.tiktok,
      render: (value: string) => {
        if(isValidHttpUrl(value)){
          return <a href={value} target="_blank" rel="noreferrer">{value}</a>
        }else{
          return value;
        }
      }, 
      readonly: isMain===true? null:true,
    },
    {
      key: "others",
      option: "Others",
      value: customFields?.others,
      render: (value: string) => {
        if(isValidHttpUrl(value)){
          return <a href={value} target="_blank" rel="noreferrer">{value}</a>
        }else{
          return value;
        }
      }, 
      readonly: isMain===true? null:true,
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      showHeader={false}
      style={{ width: "100%" }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default SocialMediaSetting;
