import { Layout } from "antd";
import styled from "styled-components";

const { Sider } = Layout;

const TwfSide = styled(Sider)`
  background-color: #373b53;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
`;

export default TwfSide;
