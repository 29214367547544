import React from "react";
import TwfModal from "../../../component/modal";
import Icon from "../../../assets/icons";
import ModalTitle from "../../../component/modal/title";
import { H3 } from "../../../component/header";
import {
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
} from "antd";
import { MarginBox } from "../../../component/margin";
import TwfButton from "../../../component/button";
import {
  PickupOption,
  useUpdatePickupOptionMutation,
} from "../../../generated/graphql";

const { Item } = Form;

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  pickup: PickupOption;
}

const PickUpUpdate: React.FC<ComponentProps> = ({ visible, setVisible, pickup }) => {
  const [form] = Form.useForm();

  const [updatePickupOption, { loading }] = useUpdatePickupOptionMutation();

  const submitFormHandler = (values: any) => {
    const input = {
      id: pickup?.id,
      label: values.label,
      cost: +values.cost,
      enabled: values.enabled,
    };
    updatePickupOption({
      variables: { input },
      refetchQueries: ["PickupOptions"],
      fetchPolicy:"network-only"
    })
      .then(({ data }) => {
        if (data?.updatePickupOption?.id) {
          message.success("Pickup Method Updated");
          form.resetFields();
          setVisible(false);
        }
      })
      .catch((error) => message.error(error.message));
  };

  const initialValues = {
    label: pickup?.label,
    cost: pickup?.cost,
    enabled: pickup?.enabled  
  }

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
        form.resetFields();
      }}
      closeIcon={<Icon keyword="close" />}
      footer={false}
      width={662}
      key={pickup?.id}
      centered
    >
      <ModalTitle>
        <H3>Update PickUp Method</H3>
      </ModalTitle>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={submitFormHandler}
        key={pickup?.id}
      >
        <Item
          label="Label"
          name="label"
          rules={[
            {
              required: true,
              message: "Please enter label!",
            },
          ]}
        >
          <Input />
        </Item>
        <Item label="Cost" name="cost"
        rules={[
          {
            required: true,
            message: "Please enter cost amount!",
          },
        ]}>
          <Input type="number" min="0" step="any"/>
        </Item>

        <Item name="enabled" valuePropName="checked">
        
            <Checkbox>Enabled?</Checkbox>
        
        </Item>

        <MarginBox mt={48}>
          <Row gutter={12} justify="end">
            <Col>
              <TwfButton
                color-twf="gray-line"
                size-twf="md"
                type-twf="wide"
                onClick={() => {
                  setVisible(false);
                  form.resetFields();
                }}
              >
                Cancel
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                type="primary"
                color-twf="primary"
                size-twf="md"
                type-twf="wide"
                htmlType="submit"
                loading={loading}
              >
                Update
              </TwfButton>
            </Col>
          </Row>
        </MarginBox>
      </Form>
    </TwfModal>
  );
};

export default PickUpUpdate;
