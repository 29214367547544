import React, { useState } from "react";
import { Suggestion } from "./types";
import axios from "axios";

type JSONResponse = {
  suggestions: {
    street_line: string;
    secondary: string;
    city: string;
    state: string;
    zipcode: string;
    entries: number;
  }[];
};

export const useAutoCompleteInput = () => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [searchVal, setSearchVal] = useState("");

  const getSuggestions = async (search: string, suggestion?: Suggestion) => {
    const stringToReturn = `${suggestion?.street_line} ${suggestion?.secondary} (${suggestion?.entries}) ${suggestion?.city} ${suggestion?.state} ${suggestion?.zipcode}`;

    try {
      const response = await axios.get<JSONResponse>(
        `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${
          process.env.REACT_APP_SMARTY_STREET_KEY
        }&search=${search}${
          suggestion ? `&selected=${stringToReturn}` : ""
        }&suggestions=5`
      );

      setSuggestions(response.data.suggestions);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    suggestions,
    setSuggestions,
    getSuggestions,
    searchVal,
  };
};
