import React, { useEffect } from "react";
import TwfModal from "../../../component/modal";
import Icon from "../../../assets/icons";
import ModalTitle from "../../../component/modal/title";
import { H3 } from "../../../component/header";
import { Col, Form, Input, message, Row } from "antd";
import TwfButton from "../../../component/button";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { EmailCategory, useUpdateEmailCategoryMutation } from "generated/graphql";
import { useNavigate } from "react-router-dom";

const { Item } = Form;

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  emailCategory: EmailCategory
}

const UpdateEmailCategory: React.FC<ComponentProps> = ({
  visible,
  setVisible,
  emailCategory
}) => {

  const breakpoints = useBreakpoint();
  const [form] = Form.useForm();

  const history = useNavigate();

  const [ updateEmailCategory ] = useUpdateEmailCategoryMutation();

  useEffect(()=>{
    if(emailCategory){
      form.setFieldValue("name",emailCategory.name)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[emailCategory])

  const updateNewCategory = ()=>{
    if(!form.getFieldValue("name") || form.getFieldValue("name").trim().length===0){
      message.error("Please enter the name for new category");
    }else{
      const formData = {
        id: emailCategory.id,
        name: form.getFieldValue("name"),
        slug: form.getFieldValue("name").toLowerCase().replace(/ /g,"_")
      }
      updateEmailCategory({
        variables: {
          input: formData
        },
        refetchQueries:["EmailCategories"]
      }).then(()=>{
        message.success("Email Category Successfully Updated!");
        setVisible(false);
        setTimeout(()=>{
          history(0);
        },1000);
      }).catch((error)=>{
        message.error(error.message);
      })
    }
  }

  return (
    <TwfModal
      closeIcon={<Icon keyword="close" />}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      centered
      footer={false}
      width={662}
    >
      <ModalTitle>
        <H3>Update Email Category</H3>
      </ModalTitle>
      <Form form={form} layout="vertical">
        <div>
          <Item label="Name" name="name">
            <Input/>
          </Item>
        </div>
        <Row gutter={breakpoints.md ? [24, 24] : [12, 12]} justify="end">
          <Col>
            <TwfButton
              type-twf="wide"
              color-twf="gray-line"
              size-twf="md"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </TwfButton>
          </Col>
          <Col>
            <Item>
              <TwfButton
                type-twf="wide"
                color-twf="primary"
                type="primary"
                size-twf="md"
                onClick={updateNewCategory}
              >
                Save
              </TwfButton>
            </Item>
          </Col>
        </Row>
      </Form>
    </TwfModal>
  );
};

export default UpdateEmailCategory;
