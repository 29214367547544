import React, { FC, Fragment } from "react";
import { Col, Row, Space, Table, Tag } from "antd";
import { PageTitle } from "component/header";
import SortBy from "../../orders/components/sort";
import AdvancedSearch from "../../orders/components/search";
import ShadowCard from "component/card";
import TwfButton from "component/button";
import { Link } from "react-router-dom";

const pageData = [
  {
    key: 1,
    id: "123",
    status: "Shipped",
    tracking: "123u832",
    product: {
      name: "Red Wine",
      slug: "product/red-wine"
    },
    orderType: "Internet Delivery",
    date: "03/03/12 "
  }
];

const MyOrdersPage: FC = () => {
  const columns: any = [
    {
      title: "#",
      dataIndex: "id",
      render: (id: string | number) => {
        return (
          <Fragment>
            # {id}
          </Fragment>
        );
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: string) => {
        return (
          <Fragment>
            <Tag
              color={
                status === "Pending"
                  ? "#FB8832"
                  : status === "Canceled"
                    ? "#FF5756"
                    : "#4AAF05"
              }
            >
              {status}
            </Tag>
          </Fragment>
        );
      }
    },
    {
      title: "Tracking",
      dataIndex: "tracking",
      render: (trackingNumber: string | number) => {
        return (
          <Fragment>
            # {trackingNumber}
          </Fragment>
        );
      }
    },
    {
      title: "Product",
      dataIndex: "product",
      render: (product: any) => {
        return (
          <Fragment>
            <Link to={product.slug}>
              {product.name}
            </Link>
          </Fragment>
        );
      }
    },
    {
      title: "Order Type",
      dataIndex: "orderType",
      align: "center"
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center"
    },
    {
      title: "",
      width: 100,
      render: (data: any) => {
        console.log(data);
        return (
          <Fragment>
            <Space>
              <TwfButton size-twf="sm" type-twf="wide">
                Invoice
              </TwfButton>
            </Space>
          </Fragment>
        );
      }
    }
  ];
  return (
    <div>
      <Space
        direction="vertical"
        size={24}
        style={{ width: "100%" }}
      >
        <Row
          gutter={[12, 12]}
          align="middle"
        >
          <Col
            span={24}
            md={{ span: 8 }}
          >
            <PageTitle>
              My Orders
            </PageTitle>
          </Col>
          <Col
            span={12}
            md={{ span: 6 }}
          >
            <AdvancedSearch
              placeholder="Search by last name"
              setFilter={(value) => {
                console.log(value);
              }}
            />
          </Col>
          <Col span={12} md={{ span: 4 }}>
            <SortBy />
          </Col>
        </Row>
        <ShadowCard>
          <Table
            columns={columns}
            dataSource={pageData}
            pagination={{ position: ["bottomCenter"] }}
            scroll={{ x: 600 }}
          />
        </ShadowCard>
      </Space>
    </div>
  );
};

export default MyOrdersPage;