/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import TwfModal from "../../../component/modal";
import ModalTitle from "../../../component/modal/title";
import { H3 } from "../../../component/header";
import Icon from "../../../assets/icons";
import { Col, Form, Input, message, Row } from "antd";
import TwfButton from "../../../component/button";
import { MarginBox } from "../../../component/margin";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useForm } from "antd/lib/form/Form";
import { useAddPageMenuMutation } from "generated/graphql";

const { Item } = Form;

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  parentId: string;
  reloadMenu: () => void
}

const AddNewMenu: React.FC<ComponentProps> = ({ 
  visible, 
  setVisible, 
  parentId,
  reloadMenu
}) => {
  const [form] = useForm();
  const breakpoints = useBreakpoint();

  const [addPageMenuMutation,{ loading }] = useAddPageMenuMutation();

  const submitFormHandler = (formData: any) => {
    addPageMenuMutation({
      variables:{
        input:{
          title: formData.title,
          redirect: formData.redirect,
          parent_id: Number(parentId)
        }
      },
      refetchQueries: ["GetPageMenus"],
    }).then(()=>{
      message.success("Successfully added the menu item");
      setVisible(false);
      reloadMenu();
    }).catch((error)=>{
      message.error(error.message);
    })
  };

  const camelCaseURL = (e:any)=>{
    let titleBasedUrl = e.target.value.toString();
    titleBasedUrl = titleBasedUrl.toLowerCase().replace(/ /g,"-");
    //console.log(titleBasedUrl);
  }

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      centered
      footer={false}
      closeIcon={<Icon keyword="close" />}
      width={662}
    >
      <Form form={form} layout="vertical" onFinish={submitFormHandler}>
        <ModalTitle>
          <H3>Add New Menu/Sub Menu</H3>
        </ModalTitle>
        <MarginBox style={{ minHeight: 250 }}>
          <Row>
            <Col span={24}>
              <Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please enter title!",
                  },
                ]}
              >
                <Input onChange={camelCaseURL} />
              </Item>
              <Item
                label="URL"
                name="redirect"
                rules={[
                  {
                    required: true,
                    message: "Please enter URL!",
                  },
                ]}
              >
                <Input/>
              </Item>
            </Col>
          </Row>
          
        </MarginBox>
        <Row gutter={breakpoints.md ? [24, 24] : [12, 12]} justify="end">
          <Col>
            <TwfButton
              color-twf="gray-line"
              size-twf="md"
              type-twf="wide"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </TwfButton>
          </Col>
          <Col>
            <TwfButton
              type="primary"
              color-twf="primary"
              size-twf="md"
              type-twf="wide"
              htmlType="submit"
              loading={loading}
            >
              Create
            </TwfButton>
          </Col>
        </Row>
      </Form>
    </TwfModal>
  );
};

export default AddNewMenu;
