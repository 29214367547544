import { FC, useEffect } from "react";
import { MarginBox } from "component/margin";
import ShadowCard from "../../../component/card";
import { Form } from "antd";
import { DatePicker } from "antd/es";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import moment from "moment";
import { SeasonShipment } from "./seasonSteps";

const { Item } = Form;

interface Props {
  seasonShipment?: SeasonShipment;
  onSeasonShipmentChange: (value: SeasonShipment) => void;
}

const SeasonShipmentSetup: FC<Props> = ({
  seasonShipment,
  onSeasonShipmentChange,
}) => {
  const [form] = Form.useForm();
  const breakpoints = useBreakpoint();

  useEffect(() => {
    if (seasonShipment)
      form.setFieldsValue({
        shipDate: seasonShipment.shipDate
          ? moment(seasonShipment.shipDate)
          : "",
      });
  }, [seasonShipment, form]);

  return (
    <ShadowCard>
      <MarginBox
        pt={breakpoints.md ? 56 : 12}
        pl={breakpoints.md ? 72 : 12}
        pr={breakpoints.md ? 72 : 12}
        pb={breakpoints.md ? 56 : 12}
        style={{ maxWidth: 700 }}
      >
        <Form
          form={form}
          layout="vertical"
          onValuesChange={onSeasonShipmentChange}
        >
          <Item label="Ship Date" name="shipDate" >
            {/* <DatePicker disabled={true} disabledDate={(current) => {
              
              let customDate = moment().format("YYYY-MM-DD");
              return current && current < moment(customDate, "YYYY-MM-DD");
            }}  /> */}
          </Item>
          {/* <Item label="Shipping Rate" name="shippingRate">
            <Select suffixIcon={<ChevronDownIcon />}>
              <Option value="season1">Season 1</Option>
            </Select>
          </Item> */}
        </Form>
      </MarginBox>
    </ShadowCard>
  );
};

export default SeasonShipmentSetup;
