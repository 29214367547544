import { FunctionComponent, useState, useEffect } from "react";
import { Row, Col, Input, Tag, AutoComplete } from "antd";
import TwfButton from "component/button";
import { OperationCard, OperationProps, VariantOption } from "../conditions";
import { SelectTitle } from "component/header";
import {
  useGetProductVariantLazyQuery,
  useProductSelectorSearchLazyQuery,
} from "generated/graphql";

const BuyXGetYFreeCondition: FunctionComponent<OperationProps> = ({
  code,
  configArgs,
  onConfigArgChange,
  onRemove,
}) => {
  const [productSelectorSearchQuery, { data: productResult }] =
    useProductSelectorSearchLazyQuery();
  const [getProductVariantXQuery, { data: dataX }] =
    useGetProductVariantLazyQuery();
  const [getProductVariantYQuery, { data: dataY }] =
    useGetProductVariantLazyQuery();

  const [productVariantOptions, setProductVariantOptions] = useState<
    VariantOption[]
  >([]);
  const [productVariantXValue, setProductVariantXValue] = useState<string>("");
  const [selectedProductVariantsX, setSelectedProductVariantsX] = useState<
    VariantOption[]
  >([]);
  const [productVariantYValue, setProductVariantYValue] = useState<string>("");
  const [selectedProductVariantsY, setSelectedProductVariantsY] = useState<
    VariantOption[]
  >([]);

  const amountX = configArgs.find((arg) => arg.name === "amountX")?.value;
  const amountY = configArgs.find((arg) => arg.name === "amountY")?.value;

  useEffect(() => {
    const productVariantIds = configArgs.find(
      (arg) => arg.name === "variantIdsX"
    )?.value;
    if (productVariantIds) {
      let variantIds: string[] = JSON.parse(productVariantIds);
      variantIds.forEach((id) => {
        getProductVariantXQuery({ variables: { id } });
      });
    }
  }, [configArgs, getProductVariantXQuery]);

  useEffect(() => {
    const productVariantIds = configArgs.find(
      (arg) => arg.name === "variantIdsY"
    )?.value;
    if (productVariantIds) {
      let variantIds: string[] = JSON.parse(productVariantIds);
      variantIds.forEach((id) => {
        getProductVariantYQuery({ variables: { id } });
      });
    }
  }, [configArgs, getProductVariantYQuery]);

  useEffect(() => {
    if (dataX?.productVariant) {
      setSelectedProductVariantsX((variants) => [
        ...variants,
        {
          label: `${dataX.productVariant?.product.name} ${
            dataX.productVariant!.name
          }`,
          value: dataX.productVariant!.id,
        },
      ]);
    }
  }, [dataX]);

  useEffect(() => {
    if (dataY?.productVariant) {
      setSelectedProductVariantsY((variants) => [
        ...variants,
        {
          label: `${dataY.productVariant?.product.name} ${
            dataY.productVariant!.name
          }`,
          value: dataY.productVariant!.id,
        },
      ]);
    }
  }, [dataY]);

  useEffect(() => {
    const result = productResult?.search.items.map((c) => ({
      label: `${c.productName} ${c.productVariantName}`,
      value: c.productVariantId,
    }));
    setProductVariantOptions(result || []);
  }, [productResult]);

  const productSelectorSearch = (term: string) => {
    productSelectorSearchQuery({ variables: { term, take: 10 } });
  };

  const productVariantXSearchHandler = (value: string) => {
    productSelectorSearch(value);
    setProductVariantXValue(value);
  };

  const addSelectedVariantX = (value: string, item: any) => {
    setProductVariantXValue("");
    setNewSelectedItemsX([...selectedProductVariantsX, item]);
  };

  const removeSelectedVariantX = (id: string) => {
    setNewSelectedItemsX([
      ...selectedProductVariantsX.filter(
        (variant: VariantOption) => variant.value !== id
      ),
    ]);
  };

  const setNewSelectedItemsX = (items: VariantOption[]) => {
    setSelectedProductVariantsX(items);
    onConfigArgChange(
      {
        name: "variantIdsX",
        value: JSON.stringify(items.map((item) => item.value)),
      },
      code
    );
  };

  const productVariantYSearchHandler = (value: string) => {
    productSelectorSearch(value);
    setProductVariantYValue(value);
  };

  const addSelectedVariantY = (value: string, item: any) => {
    setProductVariantYValue("");
    setNewSelectedItemsY([...selectedProductVariantsY, item]);
  };

  const removeSelectedVariantY = (id: string) => {
    setNewSelectedItemsY([
      ...selectedProductVariantsY.filter(
        (variant: VariantOption) => variant.value !== id
      ),
    ]);
  };

  const setNewSelectedItemsY = (items: VariantOption[]) => {
    setSelectedProductVariantsY(items);
    onConfigArgChange(
      {
        name: "variantIdsY",
        value: JSON.stringify(items.map((item) => item.value)),
      },
      code
    );
  };

  return (
    <OperationCard
      title="Buy _ of _ products, get _ of _ products free"
      style={{ marginTop: 16 }}
      actions={[
        <TwfButton key="remove" type="link" onClick={() => onRemove(code)}>
          Remove
        </TwfButton>,
      ]}
    >
      <Row>
        <Col span={24}>
          Amount X:{" "}
          <Input
            type="number"
            value={amountX}
            onChange={(e: any) =>
              onConfigArgChange(
                { name: "amountX", value: e.target.value },
                code
              )
            }
            style={{ width: 160 }}
          />
        </Col>
        <Col span={24} style={{ paddingTop: 16 }}>
          {selectedProductVariantsX.map((variant: VariantOption) => (
            <Tag
              closable
              key={variant.value}
              onClose={() => removeSelectedVariantX(variant.value)}
            >
              {variant.label}
            </Tag>
          ))}
        </Col>
        <Col span={24} style={{ paddingTop: 16 }}>
          <SelectTitle>Product variants X</SelectTitle>
          <AutoComplete
            options={productVariantOptions}
            onSelect={addSelectedVariantX}
            style={{ minWidth: 200, width: "100%" }}
            onSearch={productVariantXSearchHandler}
            placeholder="Search by product name or SKU"
            value={productVariantXValue}
          ></AutoComplete>
        </Col>
      </Row>
      <Row style={{ paddingTop: 30 }}>
        <Col span={24}>
          Amount Y:{" "}
          <Input
            type="number"
            value={amountY}
            onChange={(e: any) =>
              onConfigArgChange(
                { name: "amountY", value: e.target.value },
                code
              )
            }
            style={{ width: 160 }}
          />
        </Col>
        <Col span={24} style={{ paddingTop: 16 }}>
          {selectedProductVariantsY.map((variant: VariantOption) => (
            <Tag
              closable
              key={variant.value}
              onClose={() => removeSelectedVariantY(variant.value)}
            >
              {variant.label}
            </Tag>
          ))}
        </Col>
        <Col span={24} style={{ paddingTop: 16 }}>
          <SelectTitle>Product variants Y</SelectTitle>
          <AutoComplete
            options={productVariantOptions}
            onSelect={addSelectedVariantY}
            style={{ minWidth: 200, width: "100%" }}
            onSearch={productVariantYSearchHandler}
            placeholder="Search by product name or SKU"
            value={productVariantYValue}
          ></AutoComplete>
        </Col>
      </Row>
    </OperationCard>
  );
};

export default BuyXGetYFreeCondition;
