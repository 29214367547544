import React from "react";
import { Table } from "antd";

interface AccountPanelProps {
  account?: any;
  columns: any[];
}

const AccountManagementSetting: React.FC<AccountPanelProps> = ({
  account,
  columns,
}) => {
  let activeAdmin: any;
  if(account?.activeAdministrator){
    activeAdmin = account.activeAdministrator
  }else{
    activeAdmin = account;
  }

  const data = [
    {
      key: "emailAddress",
      option: "Username",
      saveTo: "account",
      value: activeAdmin?.user?.identifier,
    },
    {
      key: "password",
      option: "Password",
      saveTo: "account",
      value: "",
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      showHeader={false}
      style={{ width: "100%" }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default AccountManagementSetting;
