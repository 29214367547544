import { Col, DatePicker, Grid, message, Row, Segmented, 
  //TimePicker 
} from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import {  useCreateOrderMutation } from "generated/graphql";
import React, { FC, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setOrder } from "store/action/ecommerce.action";
import Icon from "../../assets/icons";
import TwfButton from "../../component/button";
import { PageTitle } from "../../component/header";
import { MarginBox } from "../../component/margin";
import DataTable from "./components/datatable";
import AdvancedSearch from "./components/search";

const { useBreakpoint } = Grid;

export interface OrderFilter {
  data?: string;
  orderState?: string;
  orderStartDate?: string;
  orderEndDate?: string;
  archived: boolean;
}

interface ReducerAction {
  type: string;
  value: string;
}

const OrdersPage: FC = () => {
  const breakpoints = useBreakpoint();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [createOrderMutation, { loading }] = useCreateOrderMutation();

  const [startDate, setStartDate] = useState("");
  //const [startTime, setStartTime] = useState("");

  const [endDate, setEndDate] = useState("");
  //const [endTime, setEndTime] = useState("");

  const [randomKey, setRandomKey] = useState(Math.floor(Math.random()*10000));

  const initialState: OrderFilter = { archived: false };

  const reducer = (state: OrderFilter, action: ReducerAction) => {
    switch (action.type) {
      case "archived":
        return { ...state, archived: !state.archived };
      case "data":
      case "orderState":
      case "orderStartDate":
      case "orderEndDate":
        return { ...state, [action.type]: action.value };
      default:
        return state;
    }
  };

  const [filter, filterDispatch] = useReducer(reducer, initialState);

  const createNewOrderClickHandler = () => {
    createOrderMutation().then(({ data }: any) => {
      if (data.createOrder.__typename === "Order") {
        dispatch<any>(setOrder(data.createOrder));
        history("/orders/create");
      }
    });
  };

  const filterOptions = [
    { label: "ALL", value: "" },
    {
      label: "SUCCESS",
      value: "compliancesuccess",
    },
    {
      label: "FAILED",
      value: "compliancefailed",
    },
    {
      label: "PAYMENT SETTLED",
      value: "paymentsettled",
    },
    {
      label: "PAYMENT DECLINED",
      value: "paymentdeclined",
    },
    {
      label: "SHIPPED",
      value: "shipped",
    },
    {
      label: "DELIVERED",
      value: "delivered",
    },
    {
      label: "CANCELLED",
      value: "cancelled",
    },
    {
      label: "ACTIVE",
      value: "active",
    },
  ];

  const onStartDateChange = (date: any, dateString: any) => {
    setStartDate(dateString);
  }

  const onEndDateChange = (date: any, dateString: any) => {
    setEndDate(dateString);
  }

  /*const onStartTimeChange = (time: any, timeString: any) => {
    setStartTime(timeString);
  }

  const onEndTimeChange = (time: any, timeString: any) => {
    setEndTime(timeString);
  }*/

  const onFilterChange = (value: SegmentedValue) => {
    filterDispatch({ type: "orderState", value: value.toString().trim() });
  };

  const clearFilter = () => {
    setRandomKey(Math.floor(Math.random()*10000));
    filterDispatch({ type: "orderStartDate", value: "" });
    filterDispatch({ type: "orderEndDate", value: "" });
  }

  const performDateFilter = () =>{
    //const checkIfAllInputs = startDate.length!==0 && startTime.length!==0 && endDate.length!==0 && endTime.length!==0;
    const checkIfAllInputs = startDate.length!==0 && endDate.length!==0;
    if(checkIfAllInputs){
      //const startFullDate = new Date(startDate+" "+startTime).toLocaleString();
      //const endFullDate = new Date(endDate+" "+endTime).toLocaleString();

      const startFullDate = new Date(startDate+" 00:00:00").toLocaleString();
      const endFullDate = new Date(endDate+" 00:00:00").toLocaleString();

      filterDispatch({ type: "orderStartDate", value: startFullDate });
      filterDispatch({ type: "orderEndDate", value: endFullDate });
    }else{
      message.error("Please enter date and time for both start and end day");
    }
  }

  return (
    <div>
      <Row gutter={[30, 20]} justify="space-between" wrap={false}>
        <Col style={{ width: "100%" }}>
          <Row align="middle" gutter={[30, 20]}>
            {breakpoints.md && (
              <Col>
                <PageTitle>Orders</PageTitle>
              </Col>
            )}
            <Col style={{ width: breakpoints.md ? "300px" : "100%" }}>
              <AdvancedSearch
                placeholder="Search...."
                setFilter={(value) => filterDispatch({ type: "data", value })}
              />
            </Col>
            {breakpoints.md && (
              <>
                <Col>
                  <TwfButton
                    size-twf="xl"
                    color-twf="gray-line"
                    onClick={() =>
                      filterDispatch({
                        type: "archived",
                        value: "",
                      })
                    }
                  >
                    <Icon keyword="archive" color="#979797" width={24} />{" "}
                    {filter.archived ? "Active" : "Archived"} orders
                  </TwfButton>
                </Col>
                {/*
                    <Col>
                      <TwfButton
                        size-twf="xl"
                        color-twf="gray-line"
                        onClick={exportHandler}
                      >
                        <Icon keyword="export" color="#979797" width={24} />{" "}
                        Export orders
                      </TwfButton>
                    </Col>
                    */}
              </>
            )}
          </Row>
        </Col>
        {breakpoints.md && (
          <Col>
            <TwfButton
              color-twf="primary"
              type="primary"
              size-twf="lg"
              type-twf="wide"
              onClick={createNewOrderClickHandler}
              loading={loading}
            >
              <Icon keyword="plus" width={16} color="#fff" />
              Create New Order
            </TwfButton>
          </Col>
        )}
      </Row>

      <Row>
      <Segmented
        style={{ marginTop: 20, marginBottom: 10 }}
        size="large"
        options={filterOptions}
        onChange={onFilterChange}
      />
      </Row>

      <div key={randomKey} style={{ marginTop:"2%" }}>
      <DatePicker  onChange={onStartDateChange} placeholder="Placed At - From" style={{ width:"25%", marginRight:"2%" }}/> 
      {/*<TimePicker onChange={onStartTimeChange} placeholder="Placed At - From" style={{ width:"25%", marginRight:"2%" }}/>*/}
      <DatePicker onChange={onEndDateChange} placeholder="Placed At - To" style={{ width:"25%", marginRight:"2%" }}/>
      {/*<TimePicker onChange={onEndTimeChange} placeholder="Placed At - To" style={{ width:"25%", marginRight:"2%" }}/>*/}
      
      <TwfButton 
      color-twf="primary"
      type="primary"
      onClick={performDateFilter}
      style={{ marginRight:"2%" }}
      >
        Apply Filter
      </TwfButton>

      <TwfButton 
      color-twf="secondary"
      type="primary"
      onClick={clearFilter}
      >
        Clear Filter
      </TwfButton>
      
      </div>
      
      <MarginBox mt={32}>
        <DataTable filter={filter} />
      </MarginBox>
    </div>
  );
};

export default OrdersPage;
