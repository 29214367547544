import React from "react";
import TwfModal from "../../../component/modal";
import Icon from "../../../assets/icons";
import ModalTitle from "../../../component/modal/title";
import { H3 } from "../../../component/header";
import {
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
} from "antd";
import { MarginBox } from "../../../component/margin";
import TwfButton from "../../../component/button";
import {
  LanguageCode,
  ShippingMethod,
  useUpdateShippingMethodMutation,
} from "../../../generated/graphql";

const { Item } = Form;

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  shippingMethod: ShippingMethod;
}

const ShippingMethodUpdate: React.FC<ComponentProps> = ({ visible, setVisible, shippingMethod }) => {
  const [form] = Form.useForm();

  const [updateShippingMethod, { loading }] = useUpdateShippingMethodMutation();

  const submitFormHandler = (values: any) => {
    const input = {
      id: shippingMethod?.id,
      code: values.code,
      customFields: {
        enabled: values.enabled,
      },
      translations: [
        {
          languageCode: LanguageCode.En,
        },
      ],
    };
    updateShippingMethod({
      variables: { input },
      refetchQueries: ["GetShippingMethodList"],
      fetchPolicy:"network-only"
    })
      .then(({ data }) => {
        if (data?.updateShippingMethod?.id) {
          message.success("Shipping Method Updated");
          form.resetFields();
          setVisible(false);
        }
      })
      .catch((error) => message.error(error.message));
  };

  const initialValues = {
    code: shippingMethod?.code,
    enabled: shippingMethod?.customFields?.enabled
  }

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
        form.resetFields();
      }}
      closeIcon={<Icon keyword="close" />}
      footer={false}
      width={662}
      key={shippingMethod?.id}
      centered
    >
      <ModalTitle>
        <H3>Update Shipping Method</H3>
      </ModalTitle>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={submitFormHandler}
        key={shippingMethod?.id}
      >
        <Item
          label="Code"
          name="code"
          rules={[
            {
              required: true,
              message: "Please enter shipping code!",
            },
          ]}
        >
          <Input />
        </Item>
        <Item name="enabled" valuePropName="checked">
            <Checkbox>Enabled?</Checkbox>
        </Item>

        <MarginBox mt={48}>
          <Row gutter={12} justify="end">
            <Col>
              <TwfButton
                color-twf="gray-line"
                size-twf="md"
                type-twf="wide"
                onClick={() => {
                  setVisible(false);
                  form.resetFields();
                }}
              >
                Cancel
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                type="primary"
                color-twf="primary"
                size-twf="md"
                type-twf="wide"
                htmlType="submit"
                loading={loading}
              >
                Update
              </TwfButton>
            </Col>
          </Row>
        </MarginBox>
      </Form>
    </TwfModal>
  );
};

export default ShippingMethodUpdate;
