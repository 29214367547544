import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const LockIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg width={width ? width : 22}
       height={width ? width : 22} viewBox="0 0 25 25" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4993 2.08203C9.62747 2.08203 7.29102 4.41849 7.29102 7.29036V10.4154H6.24935C5.10039 10.4154 4.16602 11.3497 4.16602 12.4987V20.832C4.16602 21.981 5.10039 22.9154 6.24935 22.9154H18.7493C19.8983 22.9154 20.8327 21.981 20.8327 20.832V12.4987C20.8327 11.3497 19.8983 10.4154 18.7493 10.4154H17.7077V7.29036C17.7077 4.41849 15.3712 2.08203 12.4993 2.08203ZM18.7493 12.4987L18.7514 20.832H6.24935V12.4987H18.7493ZM9.37435 10.4154V7.29036C9.37435 5.56745 10.7764 4.16536 12.4993 4.16536C14.2223 4.16536 15.6243 5.56745 15.6243 7.29036V10.4154H9.37435Z"
      fill={color ? color : "#000000"} />
  </svg>
);

export default LockIcon;
