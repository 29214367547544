import React, { FC, useEffect, useState } from "react";

import { Grid, Layout } from "antd";
import TwfHeader, { TwfHeaderWrap } from "./style/header";
import TwfContent from "./style/content";
import TwfSide from "./style/sider";
import { HamburgerActiveIcon, HamburgerIcon } from "../assets/icons/hamburger";
import TwfButton from "../component/button";
import { LogoutIcon } from "../assets/icons/logout";
import { Route, Routes, Navigate } from "react-router-dom";
import { clientRoutes } from "../route";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../store/action/authenticate";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import MobileDrawer from "./style/mobileDrawer";
import Icon from "../assets/icons";
import { useGetActiveChannelQuery } from "generated/graphql";
import TwfClientMenuLists from "./menu/client";

const { useBreakpoint } = Grid;

interface LayoutProps {
}

const TwfClientLayout: FC<LayoutProps> = () => {
  const breakpoints = useBreakpoint();
  const dispatch = useDispatch();
  const client = useApolloClient();
  const [logout] = useMutation(LOGOUT_QUERY);
  const user = useSelector((state: any) => state.user);
  const { data: getChannelResult } = useGetActiveChannelQuery();

  const [collapsed, setCollapsed] = useState(false);
  const [sideMenuVisible, setSideMenuVisible] = useState(false);
  const [logo, setLogo] = useState<string>();

  useEffect(() => {
    breakpoints.md ? setCollapsed(false) : setCollapsed(true);
  }, [breakpoints]);

  useEffect(() => {
    if (getChannelResult?.activeChannel.customFields?.logo)
      setLogo(getChannelResult?.activeChannel.customFields?.logo);
  }, [getChannelResult]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const toggleSideMenu = () => {
    setSideMenuVisible(true);
  };

  const logoutHandler = () => {
    logout({ variables: {} })
      .then(() => {
        client.resetStore();
        dispatch<any>(logoutAction({}));
      })
      .catch((error) => console.log(error));
  };

  const onSideMenuClose = () => {
    setSideMenuVisible(false);
  };

  return user.channels && user.channels[0].token ? (
    // &&    user.identifier === "superadmin"
    <Layout>
      {breakpoints.md ? (
        <TwfSide trigger={null} collapsible collapsed={collapsed} width={271}>
          {logo && (
            <TwfHeader
              style={{
                backgroundImage: `url(${logo})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                boxSizing: "border-box",
                border: "5px solid #3C64B1",
              }}
            />
          )}
          {!logo && (
            <TwfHeader
              style={{
                backgroundColor: " #3C64B1"
              }}
            />
          )}
          <TwfClientMenuLists
            collapsed={collapsed}
            setSideMenuVisible={setSideMenuVisible}
          />
        </TwfSide>
      ) : (
        <MobileDrawer
          placement="left"
          closable={true}
          onClose={onSideMenuClose}
          visible={sideMenuVisible}
          width={"100%"}
          closeIcon={<Icon keyword="close" />}
        >
          <TwfClientMenuLists
            collapsed={false}
            setSideMenuVisible={setSideMenuVisible}
          />
        </MobileDrawer>
      )}
      <Layout>
        <TwfHeader>
          <TwfHeaderWrap>
            {!collapsed && breakpoints.md ? (
              <TwfButton type-twf="icon" shape="circle" onClick={toggle}>
                <HamburgerActiveIcon width={26} color="#3C64B1" />
              </TwfButton>
            ) : (
              <TwfButton
                type-twf="icon"
                shape="circle"
                onClick={breakpoints.md ? toggle : toggleSideMenu}
              >
                <HamburgerIcon width={26} color="#3C64B1" />
              </TwfButton>
            )}
            <TwfButton type-twf="icon" shape="circle" onClick={logoutHandler}>
              <LogoutIcon width={24} color="#C9CED6" />
            </TwfButton>
          </TwfHeaderWrap>
        </TwfHeader>
        <TwfContent>
          <Routes>
            {clientRoutes.map((route, key) => (
              <Route
                key={key}
                path={route.path}
                element={<route.component/>}
              />
            ))}
          </Routes>
        </TwfContent>
      </Layout>
    </Layout>
  ) : (
    <Navigate to="/login" replace/>
  );
};

export default TwfClientLayout;

const LOGOUT_QUERY = gql`
  mutation {
    logout {
      success
    }
  }
`;
