import styled from "styled-components";
import { variant } from "styled-system";
import { Link } from "react-router-dom";

const TwfLink = styled(Link)(
  {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 600,
  },
  variant({
    prop: "primary",
    variants: {
      primary: {
        color: "#3F8CFF",
      },
    },
  })
);

export default TwfLink;
