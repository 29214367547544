import React, { useEffect, useState } from "react";
import { Modal, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { useParams } from "react-router-dom";
import {
  Asset,
  useGetProductVariantQuery,
  useUpdateProductVariantsMutation,
} from "generated/graphql";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ImageWall: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [updateProductVariantsMutation, { loading }] =
    useUpdateProductVariantsMutation();
  const { data } = useGetProductVariantQuery({
    variables: { id: id as string },
  });
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  console.log("image wall", data?.productVariant?.assets);
  const [fileList, setFileList] = useState<UploadFile[]>();

  const handleCancel = () => setPreviewOpen(false);

  useEffect(() => {
    const mappedAssets =
      data?.productVariant?.assets.map((asset) => ({
        uid: asset.id,
        name: asset.name,
        status: "done" as const,
        url: asset.preview,
      })) || [];

    setFileList(mappedAssets);
  }, [data?.productVariant?.assets]);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  // const uploadButton = (
  //   <div>
  //     <PlusOutlined />
  //     <div style={{ marginTop: 8 }}>Upload</div>
  //   </div>
  // );

  const handleRemove = (file: any) => {
    const assetIdsToKeep = data?.productVariant?.assets
      .filter((asset: Asset) => asset.id !== file.uid)
      .map((asset) => asset.id);

    updateProductVariantsMutation({
      variables: {
        input: {
          id: id as string,
          assetIds: assetIdsToKeep,
        },
      },
      refetchQueries: ["GetProductVariant", "GetProductWithVariants"],
    });
  };
  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleRemove}
      ></Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default ImageWall;
