import React, { useEffect, useState } from "react";
import TwfModal from "../../../component/modal";
import Icon from "../../../assets/icons";
import ModalTitle from "../../../component/modal/title";
import { H3 } from "../../../component/header";
import {
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import { MarginBox } from "../../../component/margin";
import TwfButton from "../../../component/button";
import {
  useGetShippingMethodListQuery,
  useCreateClubMutation,
  useAddAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useGetAnnouncementQuery,
} from "../../../generated/graphql";
import TextEditor from "component/textEditor/textEditor";

const { Item } = Form;
const { Option } = Select;

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  announcement: any;
}

const AnnouncementUpdate: React.FC<ComponentProps> = ({ visible, setVisible, announcement }) => {
  const [form] = Form.useForm();

  const [updateAnnouncement, { loading }] = useUpdateAnnouncementMutation();

  const submitFormHandler = (values: any) => {
    const input = {
      id: announcement?.id,
      name: values.name,
      content: values.content
    };
    updateAnnouncement({
      variables: { input },
      refetchQueries: ["GetAnnouncements"],
    })
      .then(({ data }) => {
        if (data?.updateAnnouncement?.id) {
          message.success("Announcement Updated");
          setVisible(false);
          form.resetFields();
        }
      })
      .catch((error) => message.error(error.message));
  };

  const initialValues = {
    name: announcement?.name,
    content: announcement?.content
  }

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closeIcon={<Icon keyword="close" />}
      footer={false}
      width={662}
      centered
    >
      <ModalTitle>
        <H3>Update Announcement</H3>
      </ModalTitle>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={submitFormHandler}
      >
        <Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter the announcement name!",
            },
          ]}
        >
          <Input />
        </Item>
        <Item label="Content" name="content"
        rules={[
          {
            required: true,
            message: "Please enter the announcement content!",
          },
        ]}>
          <TextEditor />
        </Item>
        <MarginBox mt={48}>
          <Row gutter={12} justify="end">
            <Col>
              <TwfButton
                color-twf="gray-line"
                size-twf="md"
                type-twf="wide"
                onClick={() => {
                  setVisible(false);
                  form.resetFields();
                }}
              >
                Cancel
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                type="primary"
                color-twf="primary"
                size-twf="md"
                type-twf="wide"
                htmlType="submit"
                loading={loading}
              >
                Update
              </TwfButton>
            </Col>
          </Row>
        </MarginBox>
      </Form>
    </TwfModal>
  );
};

export default AnnouncementUpdate;
