import { Table } from "antd";
import { TableProps } from "antd/es";
import { TwfCard } from "component/card";
import Container from "component/container";
import { MarginBox } from "component/margin";
import { SortOrder, useGetClubListQuery } from "generated/graphql";
import ClubReportTableAction from "./club-report-table-action";
import { ClubReportTableColumn } from "./types";

export default function ClubReportsTable() {
  const { data: clubs } = useGetClubListQuery({
    fetchPolicy: "network-only",
    variables: {
      options: {
        sort: { id: SortOrder.Desc },
      },
    },
  });

  return (
    <Container maxWidth="800px">
      <TwfCard>
        <MarginBox mt={12} mb={56}>
          <Table
            columns={columns}
            dataSource={clubs?.clubs?.items ?? undefined}
            pagination={false}
          />
        </MarginBox>
      </TwfCard>
    </Container>
  );
}

const columns: TableProps<ClubReportTableColumn>["columns"] = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "",
    key: "action",
    align: "center",
    render: (_, record: ClubReportTableColumn) => {
      return <ClubReportTableAction {...record} />;
    },
  },
];
