import React from "react";
import { Table } from "antd";
import { SettingPanelProps } from "../index";

const BasicInfoSetting: React.FC<SettingPanelProps> = ({
  channel,
  columns,
  isMain,
}) => {
  const customFields = channel?.customFields;

  const isMainAdmin = localStorage.getItem("used-channel")==="__default_channel__";

  const data = [
    {
      key: "brandName",
      option: "Brand",
      value: customFields?.brandName,
      readonly: isMain===true? null:true,
    },
    {
      key: "token",
      option: "Token",
      value: channel?.token,
      readonly: (isMainAdmin===true)? null:true,
    },
    {
      key: "firstName",
      option: "First Name",
      value: customFields?.firstName,
      readonly: isMain===true? null:true,
    },
    {
      key: "lastName",
      option: "Last Name",
      value: customFields?.lastName,
      readonly: isMain===true? null:true,
    },
    {
      key: "email",
      option: "Email",
      value: customFields?.email,
      readonly: isMain===true? null:true,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      showHeader={false}
      style={{ width: "100%" }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default BasicInfoSetting;
