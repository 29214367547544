import client from "../../api/graphql/connect";
import { QUERY_GET_ORDERS } from "../../api/queries/getOrders";
import { QUERY_GET_PRODUCTS } from "../../api/queries/getProducts";

export const ADD_TO_CART = "ADD_TO_CART";
export const GET_ORDERS = "GET_ORDERS";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const SET_ORDER = "SET_ORDER";

export const addToCart = (data: any) => (dispatch: any) => {
  dispatch({
    type: ADD_TO_CART,
    data: data,
  });
};

export const getOrders = () => (dispatch: any) => {
  client
    .query({
      query: QUERY_GET_ORDERS,
    })
    .then((response) => {
      dispatch({
        type: GET_ORDERS,
        data: response.data,
      });
    });
};

export const getProducts = () => (dispatch: any) => {
  client
    .query({
      query: QUERY_GET_PRODUCTS,
    })
    .then((response) => {
      dispatch({
        type: GET_PRODUCTS,
        data: response.data,
      });
    });
};

export const setOrder = (data: any) => (dispatch: any) => {
  dispatch({
    type: SET_ORDER,
    data: data,
  });
};
