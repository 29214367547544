import { ApolloProvider } from "@apollo/client";
import React, { FC } from "react";
import "react-quill/dist/quill.snow.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.less";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Provider } from "react-redux";
import client from "./api/graphql/connect";
import TwfLayout from "./layout";
import TwfClientLayout from "./layout/client";
import ForgetPassword from "./pages/auth/forget-password";
import LoginPage from "./pages/auth/login";
import RegisterPage from "./pages/auth/register";
import ResetPassword from "./pages/auth/reset-password";
import { store } from "./store";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHED_KEY!
);

const App: FC = () => {
  
  return (
    <React.Fragment>
      {process.env.REACT_APP_STAGING ?<div style={{ backgroundColor:'#dd066e',textAlign:'center',color:'white' }}>STAGING ENVIRONMENT</div>:null}
      <ApolloProvider client={client}>
        <Elements stripe={stripePromise}>
          <Provider store={store}>
            <BrowserRouter>
              <Routes>
                <Route
                  path={"/login"}
                  element={<LoginPage/>}
                />
                <Route
                  path={"/register"}
                  element={<RegisterPage/>}
                />
                <Route
                  path={"/forget-password"}
                  element={<ForgetPassword />}
                />
                <Route
                  path={"/reset-password"}
                  element={<ResetPassword />}
                />
                <Route path={"/user"} element={<TwfClientLayout />}/>
                <Route path={"/*"} element={<TwfLayout />}/>
              </Routes>
            </BrowserRouter>
          </Provider>
        </Elements>
      </ApolloProvider>
    </React.Fragment>
  );
};

export default App;
