import { Dropdown, Grid, Menu, message, Popconfirm, Table } from "antd";
import Container from "component/container";
import {
  useDeleteCustomerNoteMutation,
  useGetCustomerHistoryQuery,
} from "generated/graphql";
import React, { useState } from "react";
import { useParams } from "react-router";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import { TwfCard } from "../../../component/card";
import { MarginBox } from "../../../component/margin";
import CustomerSingleAddNote, { Note } from "./addNote";

const { useBreakpoint } = Grid;

interface ComponentProps {
  noteCreateVisible?: boolean;
  setNoteCreateVisible: (value: boolean) => void;
}

const CustomerSingleNotes: React.FC<ComponentProps> = ({
  noteCreateVisible,
  setNoteCreateVisible,
}) => {
  const breakpoints = useBreakpoint();
  const { id } = useParams<{ id: string }>();
  const cid: string = id as string;
  const { data, loading } = useGetCustomerHistoryQuery({
    variables: {
      id: cid,
      options: { filter: { type: { eq: "CUSTOMER_NOTE" } } },
    },
  });
  const [deleteNoteMutation] = useDeleteCustomerNoteMutation();

  const [note, setNote] = useState<Note | null>(null);

  const deleteNote = (id: string) => {
    deleteNoteMutation({
      variables: { id },
      refetchQueries: ["GetCustomerHistory"],
    }).then(() => message.success("Note deleted"));
  };

  const columns: any[] = [
    {
      title: "Note Details",
      dataIndex: "note",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
    },
    {
      title: "Follow Up Date",
      dataIndex: "date",
      align: "center",
      render: (date: string) =>
        date ? new Date(date).toLocaleDateString("en-US") : "",
    },
    {
      title: "Action",
      width: breakpoints.md ? 200 : 50,
      align: "center",
      fixed: "right",
      render: (record: any) => {
        return (
          <Dropdown
            overlay={
              <Menu style={{ borderRadius: 12 }}>
                <Menu.Item
                  onClick={() => {
                    setNote(record);
                    setNoteCreateVisible(true);
                  }}
                >
                  Edit
                </Menu.Item>
                <Popconfirm
                  title="Are you sure to delete this note?"
                  onConfirm={() => deleteNote(record.id)}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <Menu.Item>Delete</Menu.Item>
                </Popconfirm>
              </Menu>
            }
          >
            {breakpoints.md ? (
              <TwfButton size-twf="sm" color-twf="gray-line">
                Actions
                <Icon keyword="chevron-down" color="#7D8592" />
              </TwfButton>
            ) : (
              <TwfButton shape="circle" type-twf="icon">
                <Icon keyword="collapse" color="#7D8592" width={16} />
              </TwfButton>
            )}
          </Dropdown>
        );
      },
    },
  ];

  const dataSource = data?.customer?.history.items.map((history) => ({
    id: history.id,
    ...JSON.parse(history.data.note),
  }));

  return (
    <Container>
      <TwfCard>
        <MarginBox
          pr={breakpoints.md ? 75 : 15}
          pl={breakpoints.md ? 75 : 15}
          mt={12}
          mb={56}
        >
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            style={{ maxWidth: "max-content" }}
            scroll={{ x: "max-content" }}
            loading={loading}
          />
        </MarginBox>
        <CustomerSingleAddNote
          note={note}
          setNote={setNote}
          visible={noteCreateVisible}
          setVisible={setNoteCreateVisible}
        />
      </TwfCard>
    </Container>
  );
};

export default CustomerSingleNotes;
