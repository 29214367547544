import React from "react";
import { Dropdown, Menu } from "antd";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";

interface Props {}

const SortBy: React.FC<Props> = () => {
  const overlay = (
    <Menu>
      <Menu.Item></Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={overlay}>
      <TwfButton
        type-twf="wide"
        color-twf="white"
        size-twf="xl"
        style={{ width: "100%" }}
      >
        <Icon keyword="sort" color="#3C64B1" width={22} /> Sort by
        <Icon keyword="dropdown" width={14} />
      </TwfButton>
    </Dropdown>
  );
};

export default SortBy;
