import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SuperAdminPage from "./superAdmin";
import ChannelAdminPage from "./channelAdmin";
import { useGetActiveChannelLazyQuery } from "generated/graphql";
import { message } from "antd";

interface PageProps {}

const DashboardPage: FC<PageProps> = () => {
  const [getActiveChannelQuery] = useGetActiveChannelLazyQuery();
  const [hideit, setHideit] = useState<boolean>(false);

  useEffect(()=>{
    getActiveChannelQuery().then((data)=>{
      if(data.data?.activeChannel){
        if(data.data.activeChannel.code==="__default_channel__"){
          setHideit(true);
        }
      }
    }).catch((error)=>{
      setHideit(true);
      message.error(error.message);
    })

  },[getActiveChannelQuery]);
  
  return (
    <>
    {
      !hideit && (
        <ChannelAdminPage />
      )
    }
    </>
  )
};

export default DashboardPage;
