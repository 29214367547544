import { Dropdown, Menu, Table } from "antd";
import Container from "component/container";
import React, { useState } from "react";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import { TwfCard } from "../../../component/card";
import { MarginBox } from "../../../component/margin";
import ExportAllSales from "./exportAllSales";

const AllReportSales: React.FC = () => {
  const columns: any[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "",
      key: "action",
      align: "center",
      render: () => {
        return (
          <Dropdown
            overlay={
              <Menu style={{ borderRadius: 8 }}>
                <Menu.Item
                  onClick={() => {
                    setExportVisible(true);
                  }}
                >
                  Export Report
                </Menu.Item>
              </Menu>
            }
          >
            <TwfButton shape="circle" type-twf="icon">
              <Icon keyword="collapse" width={18} />
            </TwfButton>
          </Dropdown>
        );
      },
    },
  ];

  const data = [
    {
      name: "Client Sales detail report",
    },
  ];

  const [exportVisible, setExportVisible] = useState(false);

  return (
    <Container maxWidth="800px">
      <TwfCard>
        <MarginBox mt={12} mb={56}>
          <Table columns={columns} dataSource={data} pagination={false} />
        </MarginBox>
        <ExportAllSales visible={exportVisible} setVisible={setExportVisible} />
      </TwfCard>
    </Container>
  );
};

export default AllReportSales;
