import styled from "styled-components";
import TwfButton from "../../../../../component/button";

export const ShowButton = styled(TwfButton)`
  position: absolute;
  top: 16px;
  right: 20px;
  @media (max-width: 768px) {
    top: 10px;
    right: 10px;
  }
`;
