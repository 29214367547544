import React, { FC, useState, useEffect, useReducer } from "react";
import { TwfCard } from "../../../component/card";
import { Col, Form, Grid, Row, Select } from "antd";
import { BlockTitle, Explain } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import { useForm } from "antd/lib/form/Form";
import { Asset, GetProductVariantQuery, LanguageCode } from "generated/graphql";
import AssetSelector from "pages/assets/component/asset-selector";
import TextEditor from "component/textEditor/textEditor";
import Container from "component/container";
import { bottleSizes, vintageValues } from "enums/enums";

const { Item } = Form;
const { useBreakpoint } = Grid;

interface ViewProps {
  variant: GetProductVariantQuery;
  onChange: (state: any) => void;
  onProductChange: (state: any) => void;
}

const ProductSingleGeneral: FC<ViewProps> = ({
  variant: { productVariant },
  onChange,
  onProductChange,
}) => {
  const [form] = useForm();
  const breakpoints = useBreakpoint();
  const [bottleSize, setBottleSize] = useState<String>("");
  const [vintage, setVintage] = useState<String>("");

  useEffect(() => {
    dispatch({ name: "name", value: bottleSize + " " + vintage });
  }, [bottleSize, vintage]);



  const initialState = {
    customFields: { ...productVariant?.customFields },
  };
  delete initialState.customFields.__typename;

  const reducer = (state: any, action: any) => {
    switch (action.name) {
      case "name":
        return {
          ...state,
          translations: [{ name: action.value, languageCode: LanguageCode.En }],
        };
      case "assetIds":
        return {
          ...state,
          assetIds: action.value,
        };
      default:
        return {
          ...state,
          customFields: { ...state.customFields, [action.name]: action.value },
        };
    }
  };

  const productReducer = (state: any, action: any) => {
    return {
      ...state,
      [action.name]: action.value,
    };
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const [productState, productDispatch] = useReducer(productReducer, {});

  useEffect(() => {
    onChange(state);
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    onProductChange(productState);
    // eslint-disable-next-line
  }, [productState]);

  useEffect(() => {
    if (productVariant) {
      const code0 = productVariant!.options!.at(0)!.code;
      const code1 = productVariant!.options!.at(1)!.code;
      const isNum0 = isNaN(Number(code0));
      const isNum1 = isNaN(Number(code1));
      let bottleSize = productVariant!.options!.at(0)!.code;
      let vintage = productVariant!.options!.at(1)!.code;
      if (!isNum0) {
        vintage = code0;
        bottleSize = code1;
      }
      if (!isNum1) {
        vintage = code1;
        bottleSize = code0;
      }
      form.setFieldsValue({
        ...productVariant,
        ...productVariant.customFields,
        bottleSize: bottleSize,
        vintage: vintage,
      });
      //setBottleSize(productVariant!.options!.at(0)!.code);
      //setVintage(productVariant!.options!.at(1)!.code)
    }
  }, [form, productVariant]);

  const handleBottleSize = (size: any) => {
    console.log(size);
    productDispatch({ name: "bottleSize", value: size });
    setBottleSize(size);
  };

  const handleVintage = (vintage: any) => {
    console.log(vintage);
    productDispatch({ name: "vintage", value: vintage });
    setVintage(vintage);
  };

  const handleSelectAsset = (asset: Asset) => {
    dispatch({
      name: "assetIds",
      value: [asset.id],
    });
  };

  return (
    <Container>
      <TwfCard style={{ padding: breakpoints.md ? "49px 29px" : "20px" }}>
        <Form form={form}>
          <Row gutter={[30, 30]}>
            <Col lg={{ span: 12 }} span={24}>
              <MarginBox mb={27}>
                <BlockTitle>Bottle Size</BlockTitle>
                <Item name="bottleSize">
                  <Select
                    placeholder="Bottle Size"
                    options={bottleSizes}
                    onChange={(e: any) => {
                      handleBottleSize(e);
                    }}
                  />
                </Item>
              </MarginBox>
              <MarginBox mb={27}>
                <BlockTitle>Vintage</BlockTitle>
                <Item name="vintage">
                  <Select
                    placeholder="Vintage"
                    options={vintageValues}
                    onChange={(e: any) => handleVintage(e)}
                  />
                </Item>
              </MarginBox>
              <MarginBox mb={27}>
                <BlockTitle>Teaser</BlockTitle>
                <TextEditor
                  value={state.customFields.teaser}
                  onChange={(value: string) =>
                    dispatch({ name: "teaser", value })
                  }
                />
                <MarginBox mt={8}>
                  <Explain>
                    Add a brief introduction for your product listing page to
                    entice customers to learn more.
                  </Explain>
                </MarginBox>
              </MarginBox>
              <MarginBox mb={58}>
                <BlockTitle>Description</BlockTitle>
                <TextEditor
                  value={state.customFields.description}
                  onChange={(value: string) =>
                    dispatch({ name: "description", value })
                  }
                />
              </MarginBox>
              {/* <MarginBox mb={18}>
                <Explain>
                  Last Modified by Sharath Chandra on May 04, 2021 at 2:25 PM
                </Explain>
              </MarginBox> */}
            </Col>
            <Col lg={{ span: 12 }} span={24}>
              {/*<Row gutter={[24, 24]}>
                <Col lg={{ span: 14 }} span={24}>
                  <BlockTitle>Status</BlockTitle>
                  <Radio.Group
                    name="status"
                    value={state.customFields.status}
                    onChange={(e: any) =>
                      dispatch({ name: "status", value: e.target.value })
                    }
                  >
                    <Radio value={1}>Active</Radio>
                    <Radio value={0}>Inactive</Radio>
                    <Radio value={2}>Archived</Radio>
                  </Radio.Group>
                </Col>
                  </Row>*/}
              <MarginBox mt={32}>
                <Row gutter={[24, 24]}>
                  <Col span={12}>
                    <BlockTitle>Images</BlockTitle>
                    <AssetSelector
                      onAssetSelect={handleSelectAsset}
                      assets={productVariant?.assets}
                      multi
                    />
                  </Col>
                </Row>
              </MarginBox>

              {/*
              <MarginBox mt={32}>
                <Row>
                  <Col span={24}>
                    <BlockTitle>Cross Selling</BlockTitle>
                    <MarginBox mt={12}>
                      <Row gutter={12} align="middle">
                        <Col lg={{ span: 13 }} span={20}>
                          <Item>
                            <Input
                              disabled
                              prefix={<Icon keyword="search" />}
                              placeholder="Search by name or SKU to add"
                            />
                          </Item>
                        </Col>
                        <Col>
                          <MarginBox mb={24}>
                            <Dropdown
                              placement="bottomRight"
                              overlay={
                                <InfoBox>
                                  Choose up to maximum 3 products.
                                </InfoBox>
                              }
                            >
                              <TwfButton shape="circle" type-twf="icon">
                                <Icon
                                  keyword="info"
                                  width={24}
                                  color="#3C64B1"
                                />
                              </TwfButton>
                            </Dropdown>
                          </MarginBox>
                        </Col>
                      </Row>
                    </MarginBox>
                  </Col>
                </Row>
              </MarginBox>
              */}
            </Col>
          </Row>
        </Form>
      </TwfCard>
    </Container>
  );
};

export default ProductSingleGeneral;
