import styled from "styled-components";

const AuthWidgetNavWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 60px 40px;
  text-align: left;
  h1 {
    color: #fff;
    margin-top: 60px;
    margin-bottom: 54px;
  }
`;

export default AuthWidgetNavWrap;
