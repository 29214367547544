import {
  Col,
  Grid,
  InputNumber,
  Row,
  Table,
  TablePaginationConfig,
  Space,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { TableOptions } from "pages/orders/components/datatable";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { currencyFormatter } from "utils/currency-formatter";
import {
  SortOrder,
  //useGetProductListLazyQuery,
  useGetRefinedProductListLazyQuery,
} from "./../../../../generated/graphql";
import { OrderLine } from "./../../../orders/components/create/products";

const { useBreakpoint } = Grid;

interface Props {
  filter: ProductFilter;
  onOrderLineChange: (orderLines: OrderLine[]) => void;
}

interface ProductFilter {
  name?: string;
  type: number;
  status: number;
}

const AddProductsTable: React.FC<Props> = ({ filter, onOrderLineChange }) => {
  const history = useNavigate();
  const breakpoints = useBreakpoint();
  /*const [getProductListQuery, { data: products, loading }] =
    useGetProductListLazyQuery({
      fetchPolicy: "network-only",
    });*/
  
  const [ getRefinedProductListQuery , { data: products, loading }] =
    useGetRefinedProductListLazyQuery({
     fetchPolicy: "network-only"
    })

  const [tableData, setTableData] = useState<any[]>([]);
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    pagination: { current: 1, pageSize: 10 },
    sort: { id: SortOrder.Desc },
  });
  const [orderLines, setOrderLines] = useState<OrderLine[]>([]);

  const getProductList = useCallback(() => {
    getRefinedProductListQuery({
      variables: {
        options: {
          filter: {
            productType: { eq: filter.type },
            ...(filter.status === 2
              ? { status: { eq: 2 } }
              : { status: { lt: 2 } }),
            ...(filter.name && { name: { contains: filter.name } }),
          },
          skip:
            (tableOptions.pagination.pageSize || 10) *
            ((tableOptions.pagination.current || 1) - 1),
          take: tableOptions.pagination.pageSize,
          sort: tableOptions.sort,
        },
      },
    });
  }, [getRefinedProductListQuery, tableOptions, filter]);

  useEffect(() => {
    getProductList();
  }, [getProductList, tableOptions]);

  const tableChangeHandler = (
    pagination: TablePaginationConfig,
    filters: any,
    sort: any
  ) => {
    setTableOptions({
      pagination,
      sort: sort.columnKey
        ? {
            [sort.columnKey]:
              sort.order === "ascend" ? SortOrder.Asc : SortOrder.Desc,
          }
        : tableOptions.sort,
    });
  };

  useEffect(() => {
    let dataSource: any[] = [];
    products?.refinedProductsSearch.items?.forEach((product: any) => {
      if (product.channel) {
        let vintages = product.optionGroups.find(
          (item: any) => item.code === "vintage"
        );
        let vintagesString = "";
        let bottles = product.optionGroups.find(
          (item: any) => item.code === "bottle-size"
        );
        let bottlesString = "";
        vintages?.options.forEach((vintage: any) => {
          vintagesString = vintage.code.toString() + ", " + vintagesString;
        });
        bottles?.options.forEach((bottle: any) => {
          bottlesString = bottle.code.toString() + ", " + bottlesString;
        });
        const row: any = {};
        const children: any[] = [];
        product.variants.forEach((variant: any) => {
          const child: any = {};
          child.key = variant.id;
          child.vintage = variant.options.find(
            (option: any) => option.group.code === "vintage"
          )?.code;
          child.bottleSize = variant.options.find(
            (option: any) => option.group.code === "bottle-size"
          )?.code;
          child.sku = variant.sku;
          child.inventory = (
            variant.stockOnHand - variant.stockAllocated > 0
              ? variant.stockOnHand - variant.stockAllocated
              : 0
          ).toString();
          child.retailPrice = currencyFormatter.format(variant.price / 100);
          child.status = getStatus(+variant.customFields?.status);
          child.product = {
            name: product.name,
            featuredAsset: variant.featuredAsset,
            assets: variant.assets,
            isVariant: true,
            variantId: variant.id,
          };
          children.push(child);
        });

        children.forEach((child: any) => {
          row.key = `${product.id}-${child.key}`;
          row.product = child.product;
          row.vintage = child.vintage;
          row.bottleSize = child.bottleSize;
          row.overridePrice = "";
          row.sku = child.sku;
          row.inventory = child.inventory;
          row.retailPrice = child.retailPrice;
          row.status = child.status;
        });

        dataSource.push(row);
      }
    });

    setTableData(dataSource);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, products, breakpoints.md, filter]);

  let columns: any = [
    {
      title: (
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <strong>Product</strong>
          <strong>Quantity</strong>
        </Space>
      ),
      dataIndex: "product",
      key: "name",
      width: 350,
      sorter: {},
      render: (product: any) => {
        return (
          <Row gutter={12} align="middle" style={{ width: "100%" }}>
            <Col span={2}>
              <Checkbox
                onChange={(e) => {
                  orderLineFieldChangeHandler(
                    "selected",
                    e.target.checked,
                    product.variantId
                  );
                }}
                className="product-selector"
              />
            </Col>
            <Col span={5} style={{ width: 60, height: 50 }}>
              {product?.featuredAsset && (
                <img
                  src={product?.featuredAsset?.preview}
                  alt={product?.name}
                  width="50"
                  height="50"
                  style={{
                    backgroundColor: "#FAF6EE",
                    borderRadius: 12,
                    objectFit: "contain",
                  }}
                />
              )}
              {!product?.featuredAsset && product?.assets?.at(0) && (
                <img
                  src={product?.assets?.at(0)?.preview}
                  alt={product?.name}
                  width="50"
                  height="50"
                  style={{
                    backgroundColor: "#FAF6EE",
                    borderRadius: 12,
                    objectFit: "contain",
                  }}
                />
              )}
            </Col>
            <Col span={11}>{product?.name}</Col>
            <Col span={6}>
              <InputNumber
                defaultValue={1}
                min={1}
                size="large"
                style={{ borderRadius: 14 }}
                onChange={(value) => {
                  orderLineFieldChangeHandler(
                    "quantity",
                    value,
                    product.variantId
                  );
                }}
              />
            </Col>
          </Row>
        );
      },
    },
    // {
    //   title: "Store",
    //   key: "channel",
    //   render: (product: any) => {
    //     if (product.product?.channel && product.product.channel.customFields) {
    //       return product.product.channel.customFields.brandName;
    //     }
    //   },
    // },
    {
      title: "Vintage",
      dataIndex: "vintage",
    },
    {
      title: "Bottle Size",
      dataIndex: "bottleSize",
    },
    {
      title: "SKU",
      dataIndex: "sku",
    },
    {
      title: "Inventory",
      dataIndex: "inventory",
      key: "customer",
    },
    {
      title: "Retail Price",
      dataIndex: "retailPrice",
    },
    {
      title: "Override Price",
      dataIndex: "product",
      key: "overridePrice",
      render: (product: any) => (
        <InputNumber
          min={0}
          size="large"
          formatter={(value: any) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
          style={{ borderRadius: 14 }}
          onChange={(value) => {
            orderLineFieldChangeHandler("price", value, product.variantId);
          }}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a: any, b: any) => (a.english > b.english ? 1 : -1),
        multiple: 1,
      },
    },
  ];

  const getStatus = (status: number): string => {
   
    if (status === 1) {
      return "Active";
    } else if (status === 0) {
      return "InActive";
    } else {
      return "Archived";
    }
  };

  const orderLineFieldChangeHandler = (
    field: string,
    value: any,
    variantId: string
  ) => {
    const orderLine = orderLines.find((s) => s.id === variantId);
    if (orderLine)
      setOrderLines((lines) => [
        ...lines.filter((s) => s.id !== variantId),
        { ...orderLine, [field]: value },
      ]);
    else {
      setOrderLines((lines) => [...lines, { id: variantId, [field]: value }]);
    }
  };

  useEffect(() => {
    onOrderLineChange(orderLines);
    // eslint-disable-next-line
  }, [orderLines]);

  return (
    <React.Fragment>
      <Table
        loading={loading}
        columns={columns}
        dataSource={tableData}
        pagination={{
          ...tableOptions.pagination,
          total: products?.refinedProductsSearch.totalItems,
          position: ["bottomCenter"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} Items`,
          showSizeChanger: true,
        }}
        onChange={tableChangeHandler}
        style={{ maxWidth: "max-content" }}
        scroll={{ x: "max-content" }}
      />
    </React.Fragment>
  );
};

export default AddProductsTable;
