import styled from "styled-components";

interface Props {
  height?: number;
}

export const ScrollBox = styled.div<Props>`
  height: ${(props) => props.height}px;
  overflow-x: auto;
  overflow-y: inherit;
  padding-left: 15px;
  padding-right: 15px;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dadce4;
  }
`;
