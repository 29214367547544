import React from "react";
import TwfModal from "../../../component/modal";
import ModalTitle from "../../../component/modal/title";
import { H3 } from "../../../component/header";
import Icon from "../../../assets/icons";
import { Col, DatePicker, Form, Row, Select } from "antd";
import TwfButton from "../../../component/button";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useDetailedOrderInvoiceReportLazyQuery } from "generated/graphql";
import { message } from "antd/es";

const { Item } = Form;
const { Option } = Select;

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const ExportAllSales: React.FC<ComponentProps> = ({ visible, setVisible }) => {

  const [ generateDetailedOrderInvoiceReport, { loading } ] = useDetailedOrderInvoiceReportLazyQuery();

  const onSubmitHandler = (value: any) => {
    generateDetailedOrderInvoiceReport({
      variables:{
        input:{
          startDate: value?.startDate ? new Date(value.startDate): undefined,
          endDate: value?.startDate ? new Date(value.endDate): undefined,
          status: (value?.status && value.status!=="All") ? value.status : undefined,
        }
      }
    }).then((data)=>{
      if(data.data?.detailedOrderInvoiceReport){
        const reportDownloadURL = window.location.protocol+"//"+data.data.detailedOrderInvoiceReport;
        window.location.href=reportDownloadURL;
      }else{
        message.error(data.error?.message);
      }
    }).catch((error)=>{
      message.error(error.message);
    })
  }

  const breakpoints = useBreakpoint();
  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      centered
      footer={false}
      closeIcon={<Icon keyword="close" />}
      width={662}
    >
      <ModalTitle>
        <H3>Export Detailed Sales Report</H3>
      </ModalTitle>
      <Form layout="vertical" onFinish={onSubmitHandler}>
        <div style={{ minHeight: 100 }}>
          <Row gutter={24}>
            <Col span={12}>
              <Item name="startDate" label="Start Date"  rules={[
                  { required: true, message: "Report Start Date is required" },
                ]}>
                <DatePicker />
              </Item>
            </Col>
            <Col span={12}>
              <Item name="endDate" label="End Date"  rules={[
                  { required: true, message: "Report Start End is required" },
                ]}>
                <DatePicker />
              </Item>
            </Col>
            <Col span={24}>
              <Item name="status" label="Order Status">
                <Select
                  placeholder="Select"
                  suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
                  defaultValue={"All"}
                >
                  <Option value="All">All</Option>
                  <Option value="Delivered">Delivered</Option>
                  <Option value="Shipped">Shipped</Option>
                  <Option value="ComplianceSuccess">ComplianceSuccess</Option>
                  <Option value="ComplianceFailed">ComplianceFailed</Option>
                  <Option value="PaymentSettled">Payment Settled</Option>
                </Select>
              </Item>
              </Col>
          </Row>
        </div>
        <br/>
        <Row gutter={breakpoints.md ? [24, 24] : [12, 12]} justify="end">
          <Col>
            <TwfButton
              color-twf="gray-line"
              size-twf="md"
              type-twf="wide"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </TwfButton>
          </Col>
          <Col>
            <TwfButton
              type="primary"
              color-twf="primary"
              size-twf="md"
              type-twf="wide"
              htmlType="submit"
              loading={loading}
            >
              Export
            </TwfButton>
          </Col>
        </Row>
      </Form>
    </TwfModal>
  );
};

export default ExportAllSales;
