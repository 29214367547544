import React, { useEffect, useState } from "react";
import TwfModal from "../../../component/modal";
import ModalTitle from "../../../component/modal/title";
import Icon from "../../../assets/icons";
import { H3 } from "../../../component/header";
import { AutoComplete, Col, Form, Grid, Input, message, Row, Select } from "antd";
import { MarginBox } from "../../../component/margin";
import TwfButton from "../../../component/button";
import { bottleSizesNoMl } from "enums/enums";
import { 
  useTwfShippingMethodsByStateLazyQuery,
  useTwfShippingRateLazyQuery,
  useTwfShippingStatesQuery
} from "generated/graphql";

const states = require("us-state-converter");

const { Item } = Form;
const { useBreakpoint } = Grid;

interface ComponentProps {
  visible?: boolean;
  setVisible: (value: boolean) => void;
}

const Calculate: React.FC<ComponentProps> = ({ visible, setVisible }) => {
  
  const breakpoints = useBreakpoint();

  const [form] = Form.useForm();

  const shippingMethods =[] as any;

  const zones =[
    
  ] as any

  const [sZones,setSZones] = useState([]);

  const { data } = useTwfShippingStatesQuery();

  if(data?.twfShippingStates){
    data.twfShippingStates.forEach((zone)=>{
      const stateObject = states(zone.trim());
      zones.push({
        label:stateObject.name,
        value:zone.trim()
      })
    })
  }

  useEffect(()=>{
    setSZones(zones);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])

  const [getTwfShippingRate, { loading }] = useTwfShippingRateLazyQuery();

  const [getTwfShippingMethods] = useTwfShippingMethodsByStateLazyQuery();

  const [rate,setRate] = useState<any>();

  const [sTypes,setSTypes] = useState<any>(shippingMethods);

  const calculateShipping = (formData:any)=>{
    formData.sizeMl=Number(formData.sizeMl);
    formData.units=Number(formData.units);
    formData.stateCode = formData.zone;
    delete formData.zone;
    getTwfShippingRate({
      variables:{
        input:formData
      },
      fetchPolicy: "network-only"
    }).then((data)=>{
      if(data.data?.twfShippingRate){
        setRate("$ "+ data.data.twfShippingRate)
      }else{
        setRate("Not Found")
      }
    }).catch((error)=>{
      message.error(error.message)
    })
  }

  const getShippingTypes = (state:string)=>{
    form.resetFields(["shippingMethod"])
    getTwfShippingMethods({
      variables:{
        input:{
          stateCode: state,
          filterActive: true
        },
      },
      fetchPolicy: "network-only"
    }).then((data)=>{
      if(data.data?.twfShippingMethodsByState){
        data.data.twfShippingMethodsByState.forEach((sType)=>{
          const sT = {
            label:sType,
            value:sType
          } 
          shippingMethods.push(sT);
        })
        setSTypes(shippingMethods);
      }
    }).catch((error)=>{
      message.error(error.message)
    })
  }

  const handleSelect = (value: string) => {
    getShippingTypes(value);
    setSZones(zones);
  }

  const handleSearch = (value: string) => {
    const filtered = sZones.filter((zone:any)=>
    zone.label.toLowerCase().includes(value.toLowerCase()));
    if(filtered.length===0){
      setSZones(zones);
    }else{
      setSZones(filtered);
    }
  };

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      footer={false}
      closeIcon={<Icon keyword="close" />}
      width={662}
      centered
    >
      <ModalTitle>
        <H3>Calculate Shipping Rate</H3>
      </ModalTitle>
      <Form layout="vertical" form={form} onFinish={calculateShipping}>
        <MarginBox mt={32}>
          <Row>

          <Col span={24}>
              <Item name="zone" label="Zone"
               rules={[
                {
                  required: true,
                  message: "Please select the shipping zone!",
                },
              ]}>
                <AutoComplete
                options={sZones}
                onSelect={handleSelect}
                onSearch={handleSearch}
                placeholder="Select"
                ></AutoComplete>
              </Item>
            </Col>

            <Col span={24}>
              <Item name="sizeMl" label="Each Bottle Size"
               rules={[
                {
                  required: true,
                  message: "Please enter the size in ml!",
                },
              ]}>
                <Select
                  placeholder="Select"
                  suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
                  options={bottleSizesNoMl}
                >
                </Select>
              </Item>
            </Col>
            <Col span={24}>
              <Item name="units" label="Bottle Quantity"
               rules={[
                {
                  required: true,
                  message: "Please enter the no. of bottles!",
                },
              ]}>
                <Input type="number" min={1}/>
              </Item>
            </Col>

            <Col span={24}>
              <Item name="shippingMethod" label="Shipping Method"
               rules={[
                {
                  required: true,
                  message: "Please select a shipping method!",
                },
              ]}>
                <Select
                  placeholder="Select"
                  suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
                  options={sTypes}
                ></Select>
              </Item>
            </Col>

            { rate && (
              <div style={{
                marginTop:"5%" }}>
                  
                <h2>Shipping Rate:  {rate}</h2>
                </div>
            )
            }
            
          </Row>
          <MarginBox mt={32}>
            <Row gutter={breakpoints.md ? [24, 24] : [12, 12]} justify="end">
              <Col>
                <TwfButton
                  color-twf="gray-line"
                  size-twf="md"
                  type-twf="wide"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Cancel
                </TwfButton>
              </Col>
              <Col>
                <TwfButton
                  type="primary"
                  color-twf="primary"
                  size-twf="md"
                  type-twf="wide"
                  htmlType="submit"
                  loading={loading}
                >
                  Calculate
                </TwfButton>
              </Col>
            </Row>
          </MarginBox>
        </MarginBox>
      </Form>
    </TwfModal>
  );
};

export default Calculate;
