import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Checkbox, Col, Form, Grid, Input, Row, Select, message } from "antd";
import Container from "component/container";
import USStateSelector from "component/form-elements/us-state-selector";
import SmartyAutoComplete from "component/smarty-street";
import { Suggestion } from "component/smarty-street/types";
import { GET_CUSTOMER_LIST } from "graphql/queries.graphql";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import BackButton from "../../../component/button/back";
import { TwfCard } from "../../../component/card";
import { BlockTitle, PageTitle } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import {
  useCreateCustomerAddressMutation,
  useCreateCustomerCardMutation,
  useCreateCustomerMutation,
  useGetActiveChannelQuery,
} from "./../../../generated/graphql";
import CreateMobileFooter from "./mobile-footer";
//import { useSelector } from 'react-redux';

//const states = require("us-state-converter");

const { Item } = Form;
const { Option } = Select;
const { useBreakpoint } = Grid;

interface CardInfo {
  paymentMethodId: string;
}

const now = new Date();
const minDate = new Date(
  now.getFullYear() - 130,
  now.getMonth(),
  now.getDate()
);
const maxDate = new Date(now.getFullYear() - 21, now.getMonth(), now.getDate());

const CustomerCreate: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const breakpoints = useBreakpoint();
  const [createCustomerMutation] = useCreateCustomerMutation();
  const [createCustomerAddressMutation] = useCreateCustomerAddressMutation();
  const [customerAddress, setCustomerAddress] = useState({
    billingStreetAddress: "",
    personalStreetAddress: "",
  });
  const [createCustomerCardMutation] = useCreateCustomerCardMutation();
  const [localState, setLocalState] = useState({ dob: "text" });
  const [loading, setLoading] = useState(false);
  const [sameAddressChecked, setSameAddressChecked] = useState(false);
  //const customerAddressStreetLine1 = useSelector((state:any) => state.ui.customerAddressStreetLine1);

  const initialValues = {
    country: "United States",
    billingCountry: "United States",
  };

  const submitFormHandler = (formData: any) => {
    setLoading(true);
    createCustomer(formData)
      .then(async (customerId: string) => {
        createShippingAddress(formData, customerId);
        createBillingAddress(formData, customerId);
        await saveCardInfo(formData, customerId);
      })
      .then(() => {
        message.success("Customer created");
        history("/customers");
      })
      .catch((error) => {
        message.error(error);
        setLoading(false);
      });
  };

  const createCustomer = (customer: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      createCustomerMutation({
        variables: {
          input: {
            firstName: customer.firstName,
            lastName: customer.lastName,
            phoneNumber: customer.phoneNumber,
            emailAddress: customer.emailAddress,
            customFields: {
              dob: customer.dob,
              customerType: customer.customerType,
              status: customer.status,
              taxExemption: customer.taxExemption,
              isTradeUser: customer.isTradeUser,
              tradeAccountNumber: customer.tradeAccountNumber,
              adminCreated: true,
            },
          },
          password: customer.password,
        },
        refetchQueries, // the only way it refetches!
      }).then(({ data }: any) => {
        if (data.createCustomer.__typename === "Customer")
          resolve(data.createCustomer.id);
        else reject(data?.createCustomer?.message);
      });
    });
  };

  const createShippingAddress = (formData: any, customerId: string) => {
    createCustomerAddressMutation({
      variables: {
        customerId: customerId,
        input: {
          phoneNumber: formData.phoneNumber,
          streetLine1: formData.address1,
          streetLine2: formData.address2,
          city: formData.city,
          province: formData.state,
          countryCode: "us",
          postalCode: formData.zip,
          defaultShippingAddress: true,
          customFields: {
            nickName: "Default shipping",
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.emailAddress,
            dob: formData.dob,
          },
        },
      },
    });
  };

  const createBillingAddress = (formData: any, customerId: string) => {
    createCustomerAddressMutation({
      variables: {
        customerId: customerId,
        input: {
          phoneNumber: formData.phoneNumber,
          streetLine1: formData.billingAddress1,
          streetLine2: formData.billingAddress2,
          city: formData.billingCity,
          province: formData.billingState,
          countryCode: "us",
          postalCode: formData.billingZip,
          defaultBillingAddress: true,
          customFields: {
            nickName: "Default billing",
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.emailAddress,
            dob: formData.dob,
          },
        },
      },
    });
  };

  const { data: getActiveChannel } = useGetActiveChannelQuery();

  const saveCardInfo = async (formData: any, _customerId: string) => {
    if (elements == null || stripe == null || CardNumberElement === null)
      return;
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)!,
      billing_details: {
        email: formData.emailAddress,
        name: formData.nameOnCard,
        address: {
          postal_code: formData.billingZip,
          state: formData.billingState,
        },
      },
      metadata: {
        "store-token": getActiveChannel?.activeChannel?.token || null,
      },
    });
    //TODO: result should be saved in db
    //console.log(error, paymentMethod?.id, customerId);
    if (paymentMethod) {
      createCustomerCard(paymentMethod.id, formData.emailAddress)
        .then((_data) => {})
        .catch((error) => {
          message.error(error);
        });
    } else {
      message.error(error.message);
    }
  };

  const createCustomerCard = (id: any, email: string): Promise<CardInfo> => {
    return new Promise(async (resolve, reject) => {
      createCustomerCardMutation({
        variables: {
          paymentMethodId: id,
          customerEmail: email,
        },
      })
        .then(({ data }) => {
          if (data?.attachPaymentMethodToCustomer.__typename === "Card") {
            resolve(id);
          } else if (
            data?.attachPaymentMethodToCustomer.__typename ===
            "PaymentMethodAttachError"
          ) {
            if (
              data.attachPaymentMethodToCustomer.message ===
              "Credit card already exists"
            ) {
              resolve(id);
            } else {
              reject(data.attachPaymentMethodToCustomer.message);
            }
          }
        })
        .catch((error) => {
          if (error === "Credit card already exists") {
            resolve(id);
          } else {
            reject(error.message);
          }
        });
    });
  };

  const sameAddressHandler = (e: any) => {
    setSameAddressChecked(e.target.checked);
    if (e.target.checked) {
      setCustomerAddress((prev) => ({
        ...prev,
        billingStreetAddress: prev.personalStreetAddress,
      }));

      form.setFieldsValue({
        billingAddress1: customerAddress.personalStreetAddress,
        billingAddress2: form.getFieldValue("address2"),
        billingCity: form.getFieldValue("city"),
        billingState: form.getFieldValue("state"),
        billingZip: form.getFieldValue("zip"),
      });
    }
  };

  const addressChangeHandler = (address: Suggestion) => {
    form.setFieldsValue({
      address1: address.street_line,
      address2: address.secondary,
      city: address.city,
      state: address.state,
      zip: address.zipcode,
    });
  };

  const handleChangeValue = (value: string, type: string) => {
    if (type === "billing") {
      form.setFieldValue(" billingAddress1", value);
      setCustomerAddress((prev) => ({ ...prev, billingStreetAddress: value }));
    } else {
      form.setFieldValue("street1", value);
      setCustomerAddress((prev) => ({ ...prev, personalStreetAddress: value }));
    }
  };

  const billingAddressChangeHandler = (address: Suggestion) => {
    form.setFieldsValue({
      billingAddress1: address.street_line,
      billingAddress2: address.secondary,
      billingCity: address.city,
      billingState: address.state,
      billingZip: address.zipcode,
    });
  };

  const refetchQueries = [
    {
      query: GET_CUSTOMER_LIST,
      variables: {
        options: {
          filter: {
            archived: {
              eq: false,
            },
          },
          skip: 0,
          take: 10,
          sort: {
            id: "DESC",
          },
        },
      },
    },
  ];

  const billingInformation = () => {
    return (
      <Row gutter={15}>
        <Col span={24}>
          <BlockTitle>Billing Information</BlockTitle>
        </Col>
        <Col span={24}>
          <MarginBox mb={18}>
            <Item>
              <Checkbox onChange={sameAddressHandler}>
                Same as Shipping information
              </Checkbox>
            </Item>
          </MarginBox>
        </Col>
        <Col span={24}>
          <Item
            name="billingAddress1"
            rules={[
              {
                required: true,
                message: "enter billing address",
              },
            ]}
          >
            <SmartyAutoComplete
              customerAddress
              onChangeValue={(value) => handleChangeValue(value, "billing")}
              selectedData={billingAddressChangeHandler}
              disabled={sameAddressChecked}
              customerStreetAddress1={customerAddress.billingStreetAddress}
            />
          </Item>
        </Col>
        <Col span={24}>
          <Item name="billingAddress2">
            <Input
              placeholder="Apt., Suite, unit number, etc.(optional)"
              disabled={sameAddressChecked}
            />
          </Item>
        </Col>
        <Col span={24}>
          <Item
            name="billingCity"
            rules={[
              {
                required: true,
                message: "Please enter city!",
              },
            ]}
          >
            <Input placeholder="City" disabled={sameAddressChecked} />
          </Item>
        </Col>
        <Col span={24}>
          <USStateSelector
            name="billingState"
            rules={[
              {
                required: true,
                message: "Please enter state!",
              },
            ]}
            selectProps={{
              placeholder: "State",
              disabled: sameAddressChecked,
              suffixIcon: <Icon keyword="chevron-down" color="#7D8592" />,
              showSearch: true,
            }}
          />
        </Col>
        {/* <Col span={24}>
          <Item name="billingCountry">
            <Input
              placeholder="United States"
              value="United States"
              disabled={sameAddressChecked}
            />
          </Item>
        </Col> */}
        <Col span={24}>
          <Item
            name="billingZip"
            rules={[
              {
                required: true,
                message: "Please enter zip!",
              },
            ]}
          >
            <Input placeholder="Zip" disabled={sameAddressChecked} />
          </Item>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={submitFormHandler}
        layout="vertical"
      >
        <Row justify="space-between" gutter={[20, 20]}>
          <Col>
            <Row>
              <Col>
                <Row gutter={12} align="middle">
                  <Col>
                    <BackButton />
                  </Col>
                  <Col>
                    <PageTitle>Add New Customer</PageTitle>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {breakpoints.md && (
            <Col>
              <Row gutter={24}>
                <Col>
                  <TwfButton
                    color-twf="gray-line"
                    size-twf="lg"
                    type-twf="wide"
                    onClick={() => {
                      history("/customers");
                    }}
                  >
                    Cancel
                  </TwfButton>
                </Col>
                <Col>
                  <TwfButton
                    color-twf="primary"
                    type="primary"
                    size-twf="lg"
                    type-twf="wide"
                    htmlType="submit"
                    loading={loading}
                  >
                    Add
                  </TwfButton>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Container maxWidth="1200px">
          <TwfCard
            style={{
              marginTop: 32,
              padding: breakpoints.md ? "39px 44px" : "19px 24px 91px",
            }}
          >
            <Row gutter={[30, 30]}>
              <Col xl={12} span={24} style={{ width: "100%" }}>
                <Row gutter={[30, 30]}>
                  <Col span={24}>
                    <BlockTitle>Login Information</BlockTitle>
                    <Item
                      label="Email Address"
                      name="emailAddress"
                      rules={[
                        {
                          required: true,
                          message: "Please enter email!",
                        },
                      ]}
                    >
                      <Input type="email" placeholder="Email" />
                    </Item>
                    <Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter password!",
                        },
                      ]}
                    >
                      <Input type="password" placeholder="Password" />
                    </Item>
                  </Col>

                  {/* Customer */}
                  <Col span={24} style={{ marginTop: "-30px" }}>
                    <Row>
                      <Col span={24}>
                        <MarginBox mt={36}>
                          {/*<Item name="customerType">
                            <Select
                              placeholder="Customer Type"
                              suffixIcon={
                                <Icon keyword="chevron-down" color="#7D8592" />
                              }
                            >
                              <Option value={1}>Value 1</Option>
                            </Select>
                            </Item>*/}
                        </MarginBox>
                      </Col>
                      <Col span={24}>
                        <Item name="status">
                          <Select
                            placeholder="Status"
                            suffixIcon={
                              <Icon keyword="chevron-down" color="#7D8592" />
                            }
                          >
                            <Option value={1}>Active</Option>
                            <Option value={2}>InActive</Option>
                          </Select>
                        </Item>
                      </Col>
                      <Col span={24}>
                        <Item name="isTradeUser" valuePropName="checked">
                          <Checkbox>Trade user?</Checkbox>
                        </Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              {breakpoints.xl && <Col xl={12}>{billingInformation()}</Col>}

              <Col>
                <Row gutter={[30, 15]}>
                  <Col xl={12} span={24}>
                    <Row gutter={15}>
                      <Col span={24}>
                        <BlockTitle>Shipping Information</BlockTitle>
                      </Col>
                      <Col span={12}>
                        <Item
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "Please enter first name!",
                            },
                          ]}
                        >
                          <Input placeholder="First Name" />
                        </Item>
                      </Col>
                      <Col span={12}>
                        <Item
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: "Please enter last name!",
                            },
                          ]}
                        >
                          <Input placeholder="Last Name" />
                        </Item>
                      </Col>
                      <Col span={12}>
                        <Item name="phoneNumber">
                          <Input placeholder="Phone" />
                        </Item>
                      </Col>
                      <Col span={12}>
                        <Item name="dob">
                          <Input
                            type={localState.dob}
                            id="date_of_birth_alt"
                            name="date_of_birth_alt"
                            min={minDate.toISOString().substring(0, 10)}
                            max={maxDate.toISOString().substring(0, 10)}
                            autoComplete="bday"
                            placeholder="DOB (MM/DD/YYYY)"
                            onFocus={() =>
                              setLocalState((prevState) => ({
                                ...prevState,
                                dob: "date",
                              }))
                            }
                          />
                        </Item>
                      </Col>
                      <Col span={24}>
                        <Item name="address1">
                          <SmartyAutoComplete
                            customerStreetAddress1={
                              customerAddress.personalStreetAddress
                            }
                            onChangeValue={(value) =>
                              handleChangeValue(value, "personal")
                            }
                            selectedData={addressChangeHandler}
                          />
                        </Item>
                      </Col>
                      <Col span={24}>
                        <Item name="address2">
                          <Input placeholder="Apt., Suite, unit number, etc.(optional)" />
                        </Item>
                      </Col>
                      <Col span={12}>
                        <Item
                          name="city"
                          rules={[
                            {
                              required: true,
                              message: "Please enter city!",
                            },
                          ]}
                        >
                          <Input placeholder="City" />
                        </Item>
                      </Col>
                      <Col span={12}>
                        <USStateSelector
                          name="state"
                          selectProps={{
                            placeholder: "State",
                          }}
                        />
                      </Col>
                      {/* <Col span={12}>
                        <Item name="country">
                          <Input placeholder="United States" readOnly />
                        </Item>
                      </Col> */}
                      <Col span={12}>
                        <Item
                          name="zip"
                          rules={[
                            {
                              required: true,
                              message: "Please enter zip!",
                            },
                          ]}
                        >
                          <Input placeholder="Zip" />
                        </Item>
                      </Col>
                    </Row>
                  </Col>

                  {!breakpoints.xl && (
                    <Col xl={12} span={24}>
                      {billingInformation()}
                    </Col>
                  )}

                  <Col xl={12} span={24}>
                    <Row>
                      <Col span={24}>
                        <BlockTitle>Credit Card Information</BlockTitle>
                      </Col>
                      <Col span={24}>
                        <Item name="nameOnCard">
                          <Input placeholder="Name on Card" />
                        </Item>
                      </Col>
                      <Col span={24}>
                        <CardNumberItem name="cardNumber">
                          <CardNumberElement options={options} />
                        </CardNumberItem>
                      </Col>
                      <Col span={12}>
                        <CardExpiryItem name="expiryDate">
                          <CardExpiryElement options={options} />
                        </CardExpiryItem>
                      </Col>
                      <Col span={12}>
                        <CardCvcItem name="cvs">
                          <CardCvcElement options={options} />
                        </CardCvcItem>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </TwfCard>
        </Container>
      </Form>
      {!breakpoints.md && <CreateMobileFooter />}
    </div>
  );
};

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "16px",
          color: "#333",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          boxSizing: "border-box",
          "::placeholder": {
            color: "#aaa",
          },
        },
        invalid: {
          color: "#eb1c26",
        },
      },
    }),
    []
  );

  return options;
};

export default CustomerCreate;

const CardItem = styled(Item)`
  border: 1px solid rgba(60, 66, 87, 0.12);
  height: 40px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
  border-radius: 14px;
  margin-bottom: 0;
  box-sizing: border-box;
  padding: 3px 10px;
`;

const CardNumberItem = styled(CardItem)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
`;

const CardExpiryItem = styled(CardItem)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const CardCvcItem = styled(CardItem)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
`;
