import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  Row,
  TimePicker,
  message,
} from "antd";
import Container from "component/container";
import { MarginBox } from "component/margin";
import {
  ConfigurableOperationInput,
  useCreatePromotionMutation,
} from "generated/graphql";
import Actions from "pages/marketing/components/discount/actions";
import Conditions from "pages/marketing/components/discount/conditions";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TwfButton from "../../../../component/button";
import BackButton from "../../../../component/button/back";
import { PageTitle } from "../../../../component/header";

const { Item } = Form;
const { useBreakpoint } = Grid;

const AddDiscount: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const breakpoints = useBreakpoint();
  const [createPromotionMutation, { loading }] = useCreatePromotionMutation();

  const [conditions, setConditions] = useState<ConfigurableOperationInput[]>(
    []
  );
  const [actions, setActions] = useState<ConfigurableOperationInput[]>([]);

  const submitFormHandler = (promotion: any) => {
    const formData = {
      ...promotion,
      conditions,
      actions,
    } as any;
    if (
      promotion.perCustomerUsageLimit &&
      promotion.perCustomerUsageLimit.trim().length !== 0
    ) {
      formData["perCustomerUsageLimit"] = +promotion.perCustomerUsageLimit;
    }
    if (promotion.couponCode && promotion.couponCode.length !== 0) {
      formData["couponCode"] = promotion.couponCode;
    }
    createPromotionMutation({
      variables: { input: formData },
      refetchQueries: ["promotions"],
    })
      .then(({ data }: any) => {
        if (data.createPromotion.__typename === "Promotion") {
          message.success("Discount added");
          history(`/marketing/discounting/${data.createPromotion.id}`);
        } else {
          message.error(data.createPromotion.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={submitFormHandler}
        initialValues={{ enabled: true }}
      >
        <Row justify="space-between" gutter={[20, 20]}>
          <Col>
            <Row>
              <Col>
                <Row gutter={12} align="middle">
                  <Col>
                    <BackButton />
                  </Col>
                  <Col>
                    <PageTitle>Add New Discount</PageTitle>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {breakpoints.md && (
            <Col>
              <Row gutter={24}>
                <Col>
                  <TwfButton
                    color-twf="gray-line"
                    size-twf="lg"
                    type-twf="wide"
                    onClick={() => {
                      history("/marketing/discounting");
                    }}
                  >
                    Cancel
                  </TwfButton>
                </Col>
                <Col>
                  <TwfButton
                    color-twf="primary"
                    type="primary"
                    size-twf="lg"
                    type-twf="wide"
                    htmlType="submit"
                    loading={loading}
                  >
                    Add
                  </TwfButton>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <MarginBox mt={breakpoints.md ? 69 : 32}>
          <Container maxWidth="500px">
            <Row gutter={32}>
              <Col span={24}>
                <Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name!",
                    },
                  ]}
                >
                  <Input placeholder="Name" />
                </Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={12}>
                <Item name="startsAt">
                  <DatePicker placeholder="Start Date" />
                </Item>
              </Col>
              <Col span={12}>
                <Item name="startsAt">
                  <TimePicker placeholder="Start Time" />
                </Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={12}>
                <Item name="endsAt">
                  <DatePicker placeholder="Expiry Date" />
                </Item>
              </Col>
              <Col span={12}>
                <Item name="endsAt">
                  <TimePicker placeholder="Expiry Time" />
                </Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Item name="couponCode">
                  <Input placeholder="Coupon code" />
                </Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Item name="perCustomerUsageLimit">
                  <Input placeholder="Per-Customer Limit" type="number" />
                </Item>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={24}>
                <Item name="enabled" valuePropName="checked">
                  <Checkbox>Enabled</Checkbox>
                </Item>
              </Col>
            </Row>
          </Container>

          <Container maxWidth="100%">
            <Row gutter={32}>
              <Col lg={12} span={24}>
                <Conditions
                  conditions={conditions}
                  onConditionsChange={setConditions}
                />
              </Col>
              <Col lg={12} span={24}>
                <Actions actions={actions} onActionsChange={setActions} />
              </Col>
            </Row>
          </Container>
        </MarginBox>
      </Form>
    </div>
  );
};

export default AddDiscount;
