import { Col, Collapse, Grid, message, Row, Switch, Table } from "antd";
import Container from "component/container";
import {
  useUpdateEmailTemplateMutation,
  useDeleteEmailTemplateMutation,
  useGetEmailTemplatesQuery,
  useEmailCategoriesQuery,
} from "generated/graphql";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../assets/icons";
import TwfButton from "../../component/button";
import { TwfCard } from "../../component/card";
import { PageTitle } from "../../component/header";
import { MarginBox } from "../../component/margin";
//import { EmailCategories } from "../../enums/enums";
 
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

interface PageProps {}

const MarketingPage: FC<PageProps> = () => {
  const history = useNavigate();
  const breakpoints = useBreakpoint();
  const { data } = useGetEmailTemplatesQuery();
  const [deleteEmailTemplateMutation] = useDeleteEmailTemplateMutation();
  const [ updateEmailTemplateMutation ] = useUpdateEmailTemplateMutation();

  const [EmailCategories, setEmailCategories] = useState<any[]>([]);

  const { data: emailCategories } = useEmailCategoriesQuery({
    variables:{
      options:{}
    }
  });

  useEffect(()=>{
    if(emailCategories?.emailCategories){
     const options = emailCategories.emailCategories.items.map((emailCategory)=>{
      return{
        label: emailCategory.name,
        value: emailCategory.slug
      }
     }) 
     setEmailCategories(options);
    }
  },[emailCategories])

  const deleteEmailTemplate = (id: string) => {
    deleteEmailTemplateMutation({
      variables: {
        id: id,
      },
      refetchQueries: ["GetEmailTemplates"],
    })
      .then(() => {
        message.success("Successfully delete the email template");
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const switchChange = (state:boolean,id:any) => {
    let active = false;
    if (state === true) {
      active = true;
    }
    const formData = {
      id,
      active,
    };
    updateEmailTemplate(formData);
  };

  const updateEmailTemplate = (formData:any): void => {
    updateEmailTemplateMutation({
      variables:{
        input: {
          ...formData,
          id: formData.id
        }
      },
      refetchQueries:["GetEmailTemplates"]
    }).then(({ data })=>{
      if(data?.updateEmailTemplate?.__typename==="EmailTemplateResult"){
        message.success("Email state changed successfully");
      }
    }).catch((error)=>{
      message.error(error.message);
    })
  }

  const columns: any[] = breakpoints.md
    ? [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Subject",
          dataIndex: "subject",
          key: "subject",
        },
        {
          title: "State",
          key: "subject",
          render: (email:any) => {
            return (
              email.active ? "Active":"Inactive"
            )
          }
        },
        {
          title: "Status",
          key: "status",
          render: (email:any) => {
            return (
              <span>
                <Switch style={{ display:"inline-block" }} checked={email.active} onChange={(e)=>{
                  switchChange(e,email.key);
                }} />
              </span>
            );
          }
        },
        {
          title: "Action",
          key: "action",
          render: (email: any) => {
            return (
              <>
                <TwfButton
                  shape="circle"
                  type-twf="icon"
                  onClick={() => {
                    history(`/marketing/${email.key}`)
                  }}
                >
                  <Icon keyword="edit" color="#3C64B1" />
                </TwfButton>

                <TwfButton
                  onClick={() => {
                    deleteEmailTemplate(email.key);
                  }}
                  shape="circle"
                  type-twf="icon"
                  style={{ marginLeft: "2%" }}
                >
                  <Icon keyword="delete" color="#3C64B1" />
                </TwfButton>
              </>
            );
          },
        },
      ]
    : [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Action",
          key: "action",
          render: () => {
            return (
              <TwfButton shape="circle" type-twf="icon">
                <Icon keyword="edit" color="#3C64B1" />
              </TwfButton>
            );
          },
        },
      ];

  const etData = {} as any;

  if (data?.emailTemplates.items) {
    for (let i = 0; i < data.emailTemplates.items.length; i++) {
      const emailT = data.emailTemplates.items[i] as any;
      const eT = {
        key: emailT.id,
        name: emailT.name,
        subject: emailT.subject,
        content: emailT.content,
        category: emailT.category,
        active: emailT.active
      };

      if(emailT.category in etData){
        etData[emailT.category].push(eT);
      }else{
        etData[emailT.category] = [];
        etData[emailT.category].push(eT);
      }
    }
  }

  return (
    <div>
      <Row gutter={[30, 20]} justify="space-between" align="middle">
        <Col>
          <PageTitle>Email Templates</PageTitle>
        </Col>
        <Col>
          <Row gutter={breakpoints.md ? [24, 24] : [12, 12]} align="middle">
            <Col>
              <TwfButton
                color-twf="primary"
                type="primary"
                size-twf={breakpoints.md ? "lg" : "md"}
                type-twf="wide"
                onClick={() => {
                  history("/marketing/discounting");
                }}
              >
                {breakpoints.md && (
                  <Icon keyword="discounts" width={20} color="#fff" />
                )}
                Manage Discounts
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                color-twf="primary"
                type="primary"
                size-twf={breakpoints.md ? "lg" : "md"}
                type-twf="wide"
                onClick={() => {
                  history("/marketing/create");
                }}
              >
                {breakpoints.md && (
                  <Icon keyword="plus" width={16} color="#fff" />
                )}
                Create New Template
              </TwfButton>
            </Col>

            <Col>
              <TwfButton
                color-twf="primary"
                type="primary"
                size-twf={breakpoints.md ? "lg" : "md"}
                type-twf="wide"
                onClick={() => {
                  history("/email-categories");
                }}
              >
                Email Categories
              </TwfButton>
            </Col>

          </Row>
        </Col>
        
      </Row>

      <Container maxWidth="1500px">
        <TwfCard style={{ marginTop: 32 }}>
          <MarginBox mt={5} style={{ minHeight: 600 }}>
            <Collapse>

              {
                Object.keys(etData).map((key:any,index:number)=>(
                <Panel header={EmailCategories.find((eC)=>eC.value===etData[key][0].category)?.label} key={index}>
                  <Table
                  columns={columns}
                  dataSource={etData[key]}
                  showHeader={false}
                  pagination={false}
                />
                </Panel>
                ))
              }

            </Collapse>
          </MarginBox>
        </TwfCard>
      </Container>

    </div>
  );
};

export default MarketingPage;
