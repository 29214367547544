import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const HideIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.7451 13.2105L4.56235 9.02771C2.91568 10.8228 2.29168 12.6211 2.28085 12.658L2.16602 13.0003L2.27977 13.3426C2.3036 13.4152 4.78877 20.5836 13.0578 20.5836C14.0643 20.5836 14.9808 20.4731 15.8225 20.2879L12.8477 17.313C11.7766 17.2605 10.7633 16.8114 10.005 16.0531C9.2467 15.2948 8.79761 14.2816 8.7451 13.2105ZM13.0578 5.41696C11.0483 5.41696 9.4016 5.85462 8.02902 6.49813L4.01527 2.48438L2.48343 4.01621L21.9834 23.5162L23.5153 21.9844L19.9424 18.4115C22.8003 16.2958 23.8197 13.3881 23.8348 13.3426L23.9486 13.0003L23.8348 12.658C23.811 12.5854 21.3269 5.41696 13.0578 5.41696ZM15.1227 13.5918C15.3253 12.8584 15.153 12.0329 14.5897 11.4685C14.0263 10.904 13.1998 10.7329 12.4663 10.9355L10.8327 9.30179C11.5021 8.89011 12.2719 8.67048 13.0578 8.66696C15.4477 8.66696 17.3912 10.6105 17.3912 13.0003C17.388 13.7861 17.1679 14.5557 16.7553 15.2244L15.1227 13.5918Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export default HideIcon;
