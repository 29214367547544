import React, { Fragment } from "react";
import { Table, Tag } from "antd";
import { Link } from "react-router-dom";
import InvoiceButton from "./style/action";

const pageData = [
  {
    key: 1,
    orderId: "1123441",
    status: "Shipped",
    tracking: "123566234",
    product: {
      name: "Red Wine",
      url: "/red-wine"
    },
    orderType: "Internet Delivery"
  },
  {
    key: 2,
    orderId: "1134231",
    status: "Shipped",
    tracking: "123566234",
    product: {
      name: "Red Wine",
      url: "https://"
    },
    orderType: "Internet Delivery"
  }
];

const Orders = () => {

  const columns: any = [
    {
      title: "#",
      dataIndex: "orderId",
      render: (orderId: string) => <React.Fragment># {orderId}</React.Fragment>
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: string) => {
        return (
          <Fragment>
            <Tag
              color={
                status === "Pending"
                  ? "#FB8832"
                  : status === "Canceled"
                    ? "#FF5756"
                    : "#4AAF05"
              }
            >
              {status}
            </Tag>
          </Fragment>
        );
      }
    },
    {
      title: "Tracking",
      dataIndex: "tracking",
      render: (tracking: string) => {
        return (
          <React.Fragment>#{tracking}</React.Fragment>
        );
      }
    },
    {
      title: "Product",
      dataIndex: "product",
      render: (product: any) => {
        return (
          <Link to={product.link ? product.link : "#"}>
            {product.name}
          </Link>
        );
      }
    },
    {
      title: "Order Type",
      dataIndex: "orderType"
    },
    {
      title: "",
      id: "Action",
      render: (row: any) => {
        console.log(row);
        return (
          <InvoiceButton>
            Invoice
          </InvoiceButton>
        );
      }
    }
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={pageData}
        pagination={{ position: ["bottomCenter"] }}
        scroll={{ x: 600 }}
      />
    </div>
  );
};

export default Orders;