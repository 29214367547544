import React from "react";
import { Checkbox, Col, Form, Row } from "antd";
import { TwfCard } from "../../../../component/card";
import { BlockTitle } from "../../../../component/header";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
const { Item } = Form;

const DiscountClubLevels: React.FC = () => {
  const breakpoints = useBreakpoint();
  return (
    <TwfCard
      style={{ padding: breakpoints.md ? "32px 48px" : "15px", minHeight: 600 }}
    >
      <Row gutter={64}>
        <Col md={{ span: 9 }} span={24}>
          <BlockTitle>Clubs</BlockTitle>
          <Item>
            <Checkbox>6 Bottles</Checkbox>
          </Item>
          <Item>
            <Checkbox>12 Bottles</Checkbox>
          </Item>
          <Item>
            <Checkbox>Industry 6 Bottles</Checkbox>
          </Item>
          <Item>
            <Checkbox>Industry 12 Bottles</Checkbox>
          </Item>
        </Col>
      </Row>
    </TwfCard>
  );
};

export default DiscountClubLevels;
