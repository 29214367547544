import styled from "styled-components";

const AuthLayoutWrap = styled.div`
  width: 403px;
  min-height: 600px;
  height: 100%;
  padding-top: 45px;
  .ant-row {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: space-between;
      input {
        height: 48px;
        border-radius: 14px;
        border: 1px solid #d8e0f0;
        padding: 0 18px;
        font-size: 14px;
        line-height: 24px;
      }
    }
    .ant-form-item-label {
      label {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #7d8592;
      }
    }
    .ant-form-item-control-input-content {
      .ant-checkbox-wrapper {
        span {
          font-size: 16px;
          line-height: 20px;
          color: #7d8592;
        }
        .ant-checkbox-inner {
          width: 18px;
          height: 18px;
          border: 2px solid #d8e0f0;
          border-radius: 4px;
          .active {
            border: 2px solid #0a1629;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            border: 2px solid #0a1629;
            background-color: transparent;
            :after {
              border-color: #3f8cff;
              height: 9px;
              top: 45%;
              left: 18%;
            }
          }
        }
      }
      a {
        font-size: 16px;
        line-height: 20px;
        color: #7d8592;
      }
    }
  }
`;

export default AuthLayoutWrap;
