import React from "react";
import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import { TwfCard } from "../../../../component/card";
import Icon from "../../../../assets/icons";
import { MarginBox } from "../../../../component/margin";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const { Item } = Form;
const { Option } = Select;

const DiscountDiscounts: React.FC = () => {
  const breakpoints = useBreakpoint();
  return (
    <TwfCard
      style={{ padding: breakpoints.md ? "32px 48px" : "15px", minHeight: 600 }}
    >
      <Row gutter={[30, 15]}>
        <Col md={{ span: 9 }} span={24}>
          <Item label="Product Promo Type">
            <Select
              placeholder="Select"
              suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
            >
              <Option value="type1">Type 1</Option>
              <Option value="type1">Type 1</Option>
            </Select>
          </Item>
          <Item label="Shipping Promo Type">
            <Select
              placeholder="Select"
              suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
            >
              <Option value="type1">Type 1</Option>
              <Option value="type1">Type 1</Option>
            </Select>
          </Item>
        </Col>
        <Col md={{ span: 9 }} span={24}>
          <Item label="Promo % off">
            <Input />
          </Item>
          <Item label="Promo $">
            <Input />
          </Item>
        </Col>
      </Row>
      <Row gutter={[30, 0]}>
        <Col md={{ span: 9 }} span={24}>
          <Item label="Order Total From">
            <Input />
          </Item>
        </Col>
        <Col md={{ span: 9 }} span={24}>
          <MarginBox mt={32}>
            <Item>
              <Input />
            </Item>
          </MarginBox>
        </Col>
      </Row>
      <Row gutter={[30, 0]}>
        <Col md={{ span: 9 }} span={24}>
          <Item label="Quantity From">
            <Input />
          </Item>
        </Col>
        <Col md={{ span: 9 }} span={24}>
          <MarginBox mt={32}>
            <Item>
              <Input />
            </Item>
          </MarginBox>
        </Col>
      </Row>
      <MarginBox mt={32}>
        <Row gutter={56}>
          <Col md={{ span: 12 }} span={24}>
            <Item>
              <Checkbox>
                Include subSkus (Items in Bundle) when doing a quantity count
              </Checkbox>
            </Item>
          </Col>
        </Row>
      </MarginBox>
    </TwfCard>
  );
};

export default DiscountDiscounts;
