import { legacy_createStore as createStore, applyMiddleware,Store } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import RootReducer from "./redux/root.reducer";
import { loadState, saveState } from "../utils/localStorage";
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';



export type RootState = ReturnType<typeof RootReducer>;
export type AppThunk = ThunkAction<void, RootState, unknown, AnyAction>;
export type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction>;

const localState = loadState();

const middleware = [thunk];

export const store: Store<RootState, AnyAction> & { dispatch: AppDispatch } = createStore(
  RootReducer,
  localState,
  composeWithDevTools(applyMiddleware(...middleware))
);

store.subscribe(() => saveState(store.getState()));
