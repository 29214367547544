import { gql } from "@apollo/client";
import {
  ADMINISTRATOR_FRAGMENT,
  ANNOUNCEMENT_FRAGMENT,
  ASSET_FRAGMENT,
  CARD_FRAGMENT,
  // CARD_FRAGMENT,
  CHANNEL_FRAGMENT,
  CLUB_FRAGMENT,
  COMPLIANCE_FRAGMENT,
  COMPLIANCE_LIST_ITEM_FRAGMENT,
  CONFIGURABLE_OPERATION_DEF_FRAGMENT,
  CUSTOMER_CLUB_FRAGMENT,
  CUSTOMER_FRAGMENT,
  EMAIL_CATEGORY_FRAGMENT,
  EMAIL_TEMPLATE_FRAGMENT,
  ORDER_ADDRESS_FRAGMENT,
  ORDER_HISTORY_FRAGMENT,
  ORDER_LINE_FRAGMENT,
  PAGE_MENU_FRAGMENT,
  PICKUP_OPTION_FRAGMENT,
  PRODUCT_IN_COMPLIANCE_FRAGMENT,
  PRODUCT_OPTION_FRAGMENT,
  PRODUCT_OPTION_GROUP_FRAGMENT,
  PRODUCT_VARIANT_FRAGMENT,
  PRODUCT_WITH_VARIANTS_FRAGMENT,
  PROMOTION_FRAGMENT,
  SEASON_FRAGMENT,
  SEASON_LIST_FRAGMENT,
  SHIPMENT_COMPLIANCE_SALES_ORDER_RESULT_FRAGMENT,
  SHIPPING_METHOD_FRAGMENT,
  TAG_FRAGMENT,
  ZONE_FRAGMENT,
} from "./fragments.graphql";

export const GET_ORDER_LIST = gql`
  query getOrderList($options: OrderListOptions) {
    orders(options: $options) {
      items {
        id
        createdAt
        code
        state
        payments {
          id
          method
          amount
        }
        customer {
          id
          firstName
          lastName
        }
        lines {
          ...OrderLine
        }
        totalWithTax
        customFields {
          orderClassification
          complianceStatus
          paid
          archived
          csvShippingMethod
          cancelledLines
          cancelledShippingCost
          cancelledDiscounts
          cancelledSubTotal
          cancelledTotal
          cancelledTotalWithTax
          oldId
          deleted
        }
        channel {
          id
          token
          customFields {
            orderPrefix
            brandName
          }
        }
        refundStatus
      }
      totalItems
    }
  }
  ${ORDER_LINE_FRAGMENT}
`;

export const GET_FILTERED_ORDERS = gql`
  query FilteredOrders(
    $filterValues: OrderFilterValues
    $options: OrderListOptions
  ) {
    filteredOrders(filterValues: $filterValues, options: $options) {
      items {
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        shipping
        payments {
          id
          method
          amount
        }
        customer {
          id
          firstName
          lastName
        }
        lines {
          ...OrderLine
        }
        totalWithTax
        history(options: {}) {
          items {
            ...HistoryEntry
          }
          totalItems
        }
        customFields {
          orderClassification
          complianceStatus
          paid
          archived
          csvShippingMethod
          cancelledLines
          cancelledShippingCost
          cancelledDiscounts
          cancelledSubTotal
          cancelledTotal
          cancelledTotalWithTax
          oldId
          deleted
          shipTax
          byPassed
        }
        channel {
          id
          token
          customFields {
            orderPrefix
            brandName
          }
        }
        refundStatus
      }
      totalItems
    }
  }
  ${ORDER_LINE_FRAGMENT}
  ${ORDER_HISTORY_FRAGMENT}
`;

export const GET_CUSTOMER = gql`
  query GetCustomer($id: ID!, $orderListOptions: OrderListOptions) {
    customer(id: $id) {
      ...Customer
      groups {
        id
        createdAt
        updatedAt
        name
        customers {
          items {
            ...Customer
          }
          totalItems
        }
      }
      orders(options: $orderListOptions) {
        items {
          id
          code
          state
          total
          totalWithTax
          totalQuantity
          currencyCode
          updatedAt
          createdAt
          customFields {
            orderClassification
          }
          channel {
            customFields {
              orderPrefix
            }
          }
        }
        totalItems
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const GET_CUSTOMER_LIST = gql`
  query getCustomerList($options: CustomerListOptions) {
    customers(options: $options) {
      items {
        id
        createdAt
        updatedAt
        title
        firstName
        lastName
        emailAddress
        phoneNumber
        user {
          id
          verified
        }
        customFields {
          status
          archived
          registeredDate
          oldId
          isTradeUser
          dob
        }
      }
      totalItems
    }
  }
`;

export const GET_FILTERED_CUSTOMERS = gql`
  query FilteredCustomers($filterData: String, $options: CustomerListOptions) {
    filteredCustomers(filterData: $filterData, options: $options) {
      items {
        id
        createdAt
        updatedAt
        title
        firstName
        lastName
        emailAddress
        phoneNumber
        user {
          id
          verified
        }
        customFields {
          status
          archived
          registeredDate
          oldId
        }
      }
      totalItems
    }
  }
`;

export const GET_CUSTOMER_LIST_FOR_SEARCH = gql`
  query getCustomerListForSearch($options: CustomerListOptions) {
    customers(options: $options) {
      items {
        id
        createdAt
        updatedAt
        title
        firstName
        lastName
        emailAddress
        phoneNumber
        addresses {
          fullName
          phoneNumber
          id
          streetLine1
          streetLine2
          city
          province
          postalCode
          company
          defaultBillingAddress
          defaultShippingAddress
          customFields {
            dob
            nickName
            firstName
            lastName
          }
        }
        customFields {
          isTradeUser
          dob
        }
      }
      totalItems
    }
  }
`;

export const GET_ORDER = gql`
  query getOrder($id: ID!, $historyEntryListOptions: HistoryEntryListOptions) {
    order(id: $id) {
      id
      createdAt
      updatedAt
      orderPlacedAt
      code
      state
      active
      nextStates
      couponCodes
      payments {
        id
        method
        amount
        transactionId
        refunds {
          id
          total
          method
          state
        }
        metadata
      }
      discounts {
        amount
        amountWithTax
        description
        adjustmentSource
        type
      }
      promotions {
        id
        createdAt
        updatedAt
        startsAt
        endsAt
        couponCode
        perCustomerUsageLimit
        name
        enabled
        conditions {
          code
          args {
            name
            value
          }
        }
      }
      customer {
        id
        createdAt
        updatedAt
        title
        firstName
        lastName
        phoneNumber
        emailAddress
      }
      shippingAddress {
        ...OrderAddress
      }
      billingAddress {
        ...OrderAddress
      }
      lines {
        ...OrderLine
      }
      totalQuantity
      subTotal
      subTotalWithTax
      total
      totalWithTax
      shipping
      history(options: $historyEntryListOptions) {
        items {
          ...HistoryEntry
        }
        totalItems
      }
      refundStatus
      channel {
        id
        customFields {
          legalEntityName
          brandName
          address
          city
          state
          zip
          webmasterEmail
          brandPhone
        }
      }
      customFields {
        source
        orderClassification
        paymentType
        shippingOptions
        creditCardInformation
        futureShipDate
        pickupLocation
        shippingMethod
        specialInstructions
        defaultShipping
        complianceStatus
        complianceresponse
        compliancetime
        giftMessage
        paid
        csvShippingMethod
        cancelledLines
        cancelledShippingCost
        cancelledDiscounts
        cancelledSubTotal
        cancelledTotal
        cancelledTotalWithTax
        oldId
        shippingMethod
        pickupLocation
        trackingStatus
        shipmentStatus
        trackingNumbers
        shipTax
        byPassed
        orderCreator
        isAdminPlaced
        orderTax
        orderDiscount
      }
      shippingLines {
        id
        shippingMethod {
          id
          code
          name
          description
        }
      }
    }
  }
  ${ORDER_ADDRESS_FRAGMENT}
  ${ORDER_LINE_FRAGMENT}
  ${ORDER_HISTORY_FRAGMENT}
`;

export const GET_PRODUCTS = gql`
  query getProducts {
    products {
      totalItems
      items {
        enabled
        id
        name
        slug
        description
        featuredAsset {
          type
          preview
          source
        }
        assets {
          type
          preview
          source
        }
        variants {
          enabled
          id
          name
          sku
          price
          stockOnHand
          trackInventory
          options {
            code
            group {
              code
            }
          }
          featuredAsset {
            type
            preview
            source
          }
          assets {
            type
            preview
            source
          }
        }
        optionGroups {
          name
          options {
            code
            name
          }
        }
        channel {
          id
          code
          token
          customFields {
            brandName
          }
        }
      }
    }
  }
`;

export const GET_ACTIVE_CHANNEL = gql`
  query getActiveChannel {
    activeChannel {
      ...Channel
    }
  }
  ${CHANNEL_FRAGMENT}
  ${ZONE_FRAGMENT}
`;

export const GET_ACTIVE_ADMINISTRATOR = gql`
  query GetActiveAdministrator {
    activeAdministrator {
      ...Administrator
    }
  }
  ${ADMINISTRATOR_FRAGMENT}
`;

export const GET_PRODUCT_WITH_VARIANTS = gql`
  query GetProductWithVariants($id: ID!, $slug: String) {
    product(slug: $slug, id: $id) {
      ...ProductWithVariants
    }
  }
  ${PRODUCT_WITH_VARIANTS_FRAGMENT}
`;

export const GET_PRODUCT_LIST = gql`
  query GetProductList($options: ProductListOptions) {
    products(options: $options) {
      items {
        id
        languageCode
        name
        slug
        featuredAsset {
          id
          preview
        }
        assets {
          id
          preview
        }
        optionGroups {
          name
          code
          options {
            code
          }
        }
        variants {
          id
          sku
          trackInventory
          stockOnHand
          stockAllocated
          price
          options {
            code
            group {
              code
            }
          }
          featuredAsset {
            id
            preview
          }
          assets {
            id
            preview
          }
          customFields {
            status
            shipComplianceVerified
            displayOrder
          }
        }
        customFields {
          status
        }
        channel {
          id
          code
          token
          customFields {
            brandName
          }
        }
      }
      totalItems
    }
  }
`;

export const GET_REFINED_PRODUCT_LIST = gql`
  query GetRefinedProductList($options: ProductListOptions) {
    refinedProductsSearch(options: $options) {
      items {
        id
        languageCode
        name
        slug
        featuredAsset {
          id
          preview
        }
        assets {
          id
          preview
        }
        optionGroups {
          name
          code
          options {
            code
          }
        }
        variants {
          id
          sku
          trackInventory
          stockOnHand
          stockAllocated
          price
          options {
            code
            group {
              code
            }
          }
          featuredAsset {
            id
            preview
          }
          assets {
            id
            preview
          }
          customFields {
            status
            shipComplianceVerified
            displayOrder
          }
        }
        customFields {
          status
        }
        channel {
          id
          code
          token
          customFields {
            brandName
          }
        }
      }
      totalItems
    }
  }
`;

export const GET_PRODUCT_VARIANT = gql`
  query GetProductVariant($id: ID!) {
    productVariant(id: $id) {
      product {
        name
        assets {
          name
        }
      }
      ...ProductVariant
    }
  }
  ${PRODUCT_VARIANT_FRAGMENT}
`;

export const GET_ASSET_LIST = gql`
  query GetAssetList($options: AssetListOptions) {
    assets(options: $options) {
      items {
        ...Asset
        tags {
          ...Tag
        }
      }
      totalItems
    }
  }
  ${ASSET_FRAGMENT}
  ${TAG_FRAGMENT}
`;

export const GET_CUSTOMER_HISTORY = gql`
  query GetCustomerHistory($id: ID!, $options: HistoryEntryListOptions) {
    customer(id: $id) {
      id
      history(options: $options) {
        totalItems
        items {
          id
          administrator {
            id
          }
          type
          data
        }
      }
    }
  }
`;

export const GET_PRODUCT_VARIANT_OPTIONS = gql`
  query GetProductVariantOptions($id: ID!) {
    product(id: $id) {
      id
      createdAt
      updatedAt
      name
      optionGroups {
        ...ProductOptionGroup
        options {
          ...ProductOption
        }
      }
      variants {
        id
        createdAt
        updatedAt
        enabled
        name
        sku
        price
        stockOnHand
        enabled
        options {
          id
          createdAt
          updatedAt
          name
          code
          groupId
        }
      }
    }
  }
  ${PRODUCT_OPTION_GROUP_FRAGMENT}
  ${PRODUCT_OPTION_FRAGMENT}
`;

export const GET_ORDER_LINE_LIST = gql`
  query getOrderLineList($customerId: ID!, $options: OrderLineListOptions) {
    orderLines(customerId: $customerId, options: $options) {
      items {
        id
        createdAt
        order {
          id
          channel {
            customFields {
              orderPrefix
            }
          }
        }
        productVariant {
          id
          name
          sku
        }
        items {
          id
        }
      }
      totalItems
    }
  }
`;

export const PRODUCT_SELECTOR_SEARCH = gql`
  query ProductSelectorSearch($term: String!, $take: Int!) {
    search(input: { groupByProduct: false, term: $term, take: $take }) {
      items {
        productVariantId
        productVariantName
        productName
        price {
          ... on SinglePrice {
            value
          }
        }
        priceWithTax {
          ... on SinglePrice {
            value
          }
        }
        sku
      }
    }
  }
`;

export const GET_CUSTOMER_GROUPS = gql`
  query GetCustomerGroups($options: CustomerGroupListOptions) {
    customerGroups(options: $options) {
      items {
        id
        name
      }
      totalItems
    }
  }
`;

export const GET_ADJUSTMENT_OPERATIONS = gql`
  query GetAdjustmentOperations {
    promotionConditions {
      ...ConfigurableOperationDef
    }
    promotionActions {
      ...ConfigurableOperationDef
    }
  }
  ${CONFIGURABLE_OPERATION_DEF_FRAGMENT}
`;

export const GET_PROMOTION_LIST = gql`
  query GetPromotionList($options: PromotionListOptions) {
    promotions(options: $options) {
      items {
        ...Promotion
      }
      totalItems
    }
  }
  ${PROMOTION_FRAGMENT}
`;

export const GET_PROMOTION = gql`
  query GetPromotion($id: ID!) {
    promotion(id: $id) {
      ...Promotion
    }
  }
  ${PROMOTION_FRAGMENT}
`;

export const GET_SHIPPING_METHOD_LIST = gql`
  query GetShippingMethodList($options: ShippingMethodListOptions) {
    shippingMethods(options: $options) {
      items {
        ...ShippingMethod
      }
      totalItems
    }
  }
  ${SHIPPING_METHOD_FRAGMENT}
`;

export const GET_SHIPPING_METHOD = gql`
  query GetShippingMethod($id: ID!) {
    shippingMethod(id: $id) {
      ...ShippingMethod
    }
  }
  ${SHIPPING_METHOD_FRAGMENT}
`;

export const GET_CLUB_LIST = gql`
  query GetClubList($options: ClubListOptions) {
    clubs(options: $options) {
      items {
        id
        name
        order
        active
        clubCustomers {
          customer {
            id
          }
        }
      }
      totalItems
    }
  }
`;

export const GET_CLUB = gql`
  query GetClub($id: ID!, $searchTerm: String) {
    club(id: $id, searchTerm: $searchTerm) {
      ...Club
    }
  }
  ${CLUB_FRAGMENT}
`;

export const GET_CUSTOMER_LIST_FOR_CLUB = gql`
  query getCustomerListForClub($options: CustomerListOptions) {
    customers(options: $options) {
      items {
        ...CustomerClub
      }
      totalItems
    }
  }
  ${CUSTOMER_CLUB_FRAGMENT}
`;

export const GET_SEASON_LIST_FOR_CFeLUB = gql`
  query getSeasonListForClub($clubID: ID!, $options: SeasonListOptions) {
    seasons(clubID: $clubID, options: $options) {
      items {
        ...SeasonList
      }
      totalItems
    }
  }
  ${SEASON_LIST_FRAGMENT}
`;

export const GET_SEASON = gql`
  query getSeason($id: ID!) {
    season(id: $id) {
      ...Season
    }
  }
  ${SEASON_FRAGMENT}
`;

export const GET_SEASON_CUSTOMER_LIST = gql`
  query getSeasonCustomerList($id: ID!, $options: SeasonCustomerListOptions) {
    seasonCustomers(id: $id, options: $options) {
      items {
        id
        customer {
          id
          firstName
          lastName
          phoneNumber
        }
        status
      }
      totalItems
    }
  }
`;

export const GET_PRODUCT_VARIANTS_SEARCH = gql`
  query getProductVariantsSearch($options: ProductVariantListOptions!) {
    productVariantsSearch(options: $options) {
      items {
        id
        name
        priceWithTax
        product {
          name
        }
      }
    }
  }
`;

export const GET_PRODUCT_VARIANTS_ADVANCED_SEARCH = gql`
  query getProductVariantsAdvancedSearch($options: ProductVariantListOptions!) {
    productVariantsAdvancedSearch(options: $options) {
      items {
        id
        name
        priceWithTax
        product {
          name
        }
      }
    }
  }
`;

export const GET_CLUB_STAT = gql`
  query getClubStat($id: ID!) {
    clubStat(id: $id) {
      totalMembers
      activeMembers
      cancelledMembers
      pausedMembers
      lastShipment
    }
  }
`;

export const GET_SEASON_STAT = gql`
  query getSeasonStat($id: ID!) {
    seasonStat(id: $id) {
      totalMembers
      processedMembers
      declinedMembers
      skippedMembers
      lastShipment
    }
  }
`;

export const GET_CLUB_CUSTOMER_LIST = gql`
  query GetClubCustomerList($customerId: ID!) {
    clubCustomers(customerId: $customerId) {
      items {
        id
        cancelDate
        signUpDate
        status
        club {
          id
          name
          userChoice
        }
      }
    }
  }
`;

export const GET_ORDER_SUMMARY = gql`
  query getOrderSummary($options: OrderListOptions) {
    orders(options: $options) {
      totalItems
      items {
        id
        total
        currencyCode
      }
    }
  }
`;

export const GET_CHANNELS = gql`
  query GetChannels($options: ChannelListOptions) {
    channelList(options: $options) {
      totalItems
      items {
        ...Channel
      }
    }
  }
  ${CHANNEL_FRAGMENT}
`;

export const GET_CHANNELS_OLD = gql`
  query GetChannelsOld {
    channels {
      ...Channel
    }
  }
  ${CHANNEL_FRAGMENT}
`;

export const GET_COMPLIANCE_LIST = gql`
  query GetComplianceList($options: ComplianceListOptions, $stateId: ID) {
    compliances(options: $options, stateId: $stateId) {
      items {
        ...ComplianceListItem
      }
      totalItems
    }
  }
  ${COMPLIANCE_LIST_ITEM_FRAGMENT}
`;

export const GET_COMPLIANCE = gql`
  query GetCompliance($id: ID!) {
    compliance(id: $id) {
      ...Compliance
    }
  }
  ${COMPLIANCE_FRAGMENT}
`;

export const GET_STATE_LIST = gql`
  query GetStateList {
    states {
      id
      code
      name
    }
  }
`;

export const GET_COMPLIANCE_DATA_FOR_ORDER = gql`
  query complianceDataForOrder($code: String!) {
    complianceDataForOrder(code: $code) {
      ...SalesOrderResult
      ... on OrderComplianceFetchError {
        errorCode
        message
      }
    }
  }
  ${SHIPMENT_COMPLIANCE_SALES_ORDER_RESULT_FRAGMENT}
`;

export const GET_ALL_CUSTOMER_CARDS = gql`
  query GetAllCustomerCards($input: ListCardForAdminInput) {
    getCustomerCards(input: $input) {
      items {
        ...Card
      }
    }
  }
  ${CARD_FRAGMENT}
`;

export const GET_PRODUCT_IN_COMPLIANCE = gql`
  query ProductInCompliance($productKey: String!, $brandKey: String!) {
    ProductInCompliance(productKey: $productKey, brandKey: $brandKey) {
      ...ProductInComplianceResponse
    }
  }
  ${PRODUCT_IN_COMPLIANCE_FRAGMENT}
`;

export const GET_SHIPPING_METHOD_OPERATIONS = gql`
  query GetShippingMethodOperations {
    shippingEligibilityCheckers {
      ...ConfigurableOperationDef
    }
    shippingCalculators {
      ...ConfigurableOperationDef
    }
    fulfillmentHandlers {
      ...ConfigurableOperationDef
    }
  }
  ${CONFIGURABLE_OPERATION_DEF_FRAGMENT}
`;

export const GET_ROLES = gql`
  query GetRoles($options: RoleListOptions) {
    roles(options: $options) {
      items {
        id
        channels {
          id
        }
      }
    }
  }
`;

export const GET_ADMINISTRATORS = gql`
  query GetAdministrators($options: AdministratorListOptions) {
    administrators(options: $options) {
      items {
        ...Administrator
      }
    }
  }
  ${ADMINISTRATOR_FRAGMENT}
`;

export const GET_EMAIL_TEMPLATES = gql`
  query GetEmailTemplates($options: EmailTemplateResultListOptions) {
    emailTemplates(options: $options) {
      items {
        ...EmailTemplate
      }
      totalItems
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;

export const GET_EMAIL_TEMPLATE = gql`
  query GetEmailTemplate($id: ID!) {
    emailTemplate(id: $id) {
      ...EmailTemplate
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;

export const GET_ANNOUNCEMENTS = gql`
  query GetAnnouncements($options: AnnouncementResultListOptions) {
    announcements(options: $options) {
      items {
        ...Announcement
      }
      totalItems
    }
  }
  ${ANNOUNCEMENT_FRAGMENT}
`;

export const GET_ANNOUNCEMENT = gql`
  query GetAnnouncement($id: ID!) {
    announcement(id: $id) {
      ...Announcement
    }
  }
  ${ANNOUNCEMENT_FRAGMENT}
`;

export const CHECK_REDIRECT_LOGIN = gql`
  query CheckRedirectLoginAdmin($emailAddress: String!) {
    checkRedirectLoginAdmin(emailAddress: $emailAddress)
  }
`;

export const GET_PAGE_MENUS = gql`
  query GetPageMenus($options: PageListOptions) {
    pages(options: $options) {
      items {
        ...PageMenu
      }
      totalItems
    }
  }
  ${PAGE_MENU_FRAGMENT}
`;

export const GET_NO_PARENT_PAGE_MENUS = gql`
  query NoParentPageMenus {
    noParentPages {
      items {
        ...PageMenu
      }
      totalItems
    }
  }
  ${PAGE_MENU_FRAGMENT}
`;

export const GET_WINE_LABELS = gql`
  query WineLabels($options: WineLabelListOptions) {
    wineLabels(options: $options) {
      items {
        id
        label
      }
    }
  }
`;

export const GET_WINE_LABEL = gql`
  query WineLabel($id: ID!) {
    wineLabel(id: $id) {
      id
      label
    }
  }
`;

export const GET_WINE_TYPES = gql`
  query WineTypes($options: WineTypeListOptions) {
    wineTypes(options: $options) {
      items {
        id
        type
      }
    }
  }
`;

export const GET_WINE_TYPE = gql`
  query WineType($id: ID!) {
    wineType(id: $id) {
      id
      type
    }
  }
`;

export const GET_WINE_APPELLATIONS = gql`
  query Appellations($options: AppellationListOptions) {
    appellations(options: $options) {
      items {
        id
        appellation
      }
    }
  }
`;

export const GET_WINE_APPELLATION = gql`
  query Appellation($id: ID!) {
    appellation(id: $id) {
      id
      appellation
    }
  }
`;

export const GET_WINE_VARIETALS = gql`
  query Varietals($options: VarietalListOptions) {
    varietals(options: $options) {
      items {
        id
        varietal
      }
    }
  }
`;

export const GET_WINE_VARIETAL = gql`
  query Varietal($id: ID!) {
    varietal(id: $id) {
      id
      varietal
    }
  }
`;

export const GET_EMAIL_CATEGORY = gql`
  query EmailCategory($id: ID!) {
    emailCategory(id: $id) {
      ...EmailCategory
    }
  }
  ${EMAIL_CATEGORY_FRAGMENT}
`;

export const GET_EMAIL_CATEGORIES = gql`
  query EmailCategories($options: EmailCategoryListOptions!) {
    emailCategories(options: $options) {
      items {
        ...EmailCategory
      }
      totalItems
    }
  }
  ${EMAIL_CATEGORY_FRAGMENT}
`;

export const GET_PICKUP_OPTIONS = gql`
  query PickupOptions($options: PickupOptionListOptions!) {
    pickupOptions(options: $options) {
      items {
        ...PickupOption
      }
      totalItems
    }
  }
  ${PICKUP_OPTION_FRAGMENT}
`;

export const GET_PICKUP_LABELS = gql`
  query PickUpLabels {
    pickupLabels {
      id
      cost
      label
      value
    }
  }
`;

export const CHECK_IF_SKU = gql`
  query CheckSKU($sku: String!) {
    checkSKU(sku: $sku)
  }
`;

export const CHECK_PRODUCT_SHIP_COMPLIANCE = gql`
  query CheckProductShipCompliance($productKey: String!) {
    checkProductShipCompliance(productKey: $productKey)
  }
`;

export const CHECK_IF_ORDER_COMPLIANT = gql`
  query CheckIfCompliant($id: ID!) {
    checkIfCompliant(id: $id)
  }
`;

export const GET_TWF_SHIPPING_METHODS = gql`
  query TwfShippingMethodsByState($input: twfShippingMethodsInput!) {
    twfShippingMethodsByState(input: $input)
  }
`;

export const GET_TWF_SHIPPING_STATES = gql`
  query TwfShippingStates {
    twfShippingStates
  }
`;

export const CALCULATE_TWF_SHIPPING_RATE = gql`
  query TwfShippingRate($input: twfShippingCostCalculationInput!) {
    twfShippingRate(input: $input)
  }
`;

export const GET_ELIGIBLE_SHIPPING_METHODS = gql`
  query EligibleShippingMethods($orderId: ID!) {
    eligibleShippingMethods(orderId: $orderId) {
      id
      price
      priceWithTax
      code
      name
      description
      metadata
      customFields {
        enabled
      }
    }
  }
`;

export const GET_TWF_SHIPPING_RATES = gql`
  query ShippingStatePrice($options: ShippingStatePriceListOptions) {
    shippingStatePrice(options: $options) {
      items {
        id
        createdAt
        updatedAt
        shippingTypeId
        shippingMethodCode
        stateCode
        bottleSize
        quantity
        price
        overridePrice
        isOverride
      }
      totalItems
    }
  }
`;

export const GET_EMAIL_TOKENS = gql`
  query EmailTokens($category: String) {
    emailTokens(category: $category)
  }
`;

export const GENERATE_ORDER_INVOICE_REPORT = gql`
  query orderInvoiceReport($input: OrderInvoiceReportInput) {
    orderInvoiceReport(input: $input)
  }
`;

export const GENERATE_DETAILED_ORDER_INVOICE_REPORT = gql`
  query detailedOrderInvoiceReport($input: OrderInvoiceReportInput) {
    detailedOrderInvoiceReport(input: $input)
  }
`;

export const GENERATE_DETAILED_CUSTOMER_REPORT = gql`
  query customerDetailedReport($input: CustomerReportInput) {
    customerDetailedReport(input: $input)
  }
`;

export const GET_CLUB_NON_MEMBER_CUSTOMERS = gql`
  query NonMemberCustomers(
    $clubID: ID!
    $filterData: String
    $options: CustomerListOptions
  ) {
    nonMemberCustomers(
      clubID: $clubID
      filterData: $filterData
      options: $options
    ) {
      items {
        ...Customer
      }
      totalItems
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const GET_CLUB_REPORT = gql`
  query GetClubReport($id: ID!) {
    clubReport(id: $id) {
      customerId
      fullName
      email
      clubName
      clubOptions
      clubMembershipStatus
      dateClubJoined
      dateClubCancelled
      shipmentsReceived
      ltvOfAllShipments
    }
  }
`;

export const GET_CUSTOMER_REPORT = gql`
  query GetCustomerReport {
    customerReport
  }
`;
