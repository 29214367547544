export const SET_IMAGE_COUNTER = "SET_IMAGE_COUNTER";
export const SET_PRODUCT_ASSETS = "SET_PRODUCT_ASSETS"
export const SET_CUSTOMER_STREET_LINE_1 = "SET_CUSTOMER_STREET_LINE_1"


export interface UIActionType {
  type: typeof SET_PRODUCT_ASSETS | typeof SET_CUSTOMER_STREET_LINE_1 | typeof SET_IMAGE_COUNTER;
  data: any;
}



export const productAssetsAction = (data: any) => (dispatch: any) => {
  dispatch({
    type:SET_PRODUCT_ASSETS,
    data:[ ...data ],
  });
};




export const setCustomerStreetLine1Action = (data: string) => (dispatch: any) => {
  dispatch({
    type: SET_CUSTOMER_STREET_LINE_1,
    data,
  });
};