import styled from "styled-components";
import { Layout } from "antd";

const { Header } = Layout;

const TwfHeader = styled(Header)`
  background-color: #fff;
  padding: 0;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: 75px;
    background-color: transparent;
  }
`;

export const TwfHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 42px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export default TwfHeader;
