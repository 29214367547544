import Container from "component/container";
import React, { FC } from "react";

interface PageProps {}

const GuidePage: FC<PageProps> = () => {
  return <Container maxWidth='1500px'></Container>;
};

export default GuidePage;
