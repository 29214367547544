import { Button } from "antd";
import styled from "styled-components";
import { variant } from "styled-system";

interface ButtonProps {
  "size-twf"?: string;
  "color-twf"?: string;
  "type-twf"?: string;
}

const TwfButton = styled(Button)<ButtonProps>(
  {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    gridGap: 8,
    fontWeight: "bold",
  },
  variant({
    prop: "size-twf",
    variants: {
      xl: {
        height: "50px",
        borderRadius: "10px",
        fontSize: "16px",
      },
      lg: {
        height: "48px",
        borderRadius: "14px",
        fontSize: "16px",
      },
      md: {
        height: "44px",
        borderRadius: "14px",
        fontSize: "14px",
        padding: "5px 10px",
      },
      xm: {
        height: "42px",
        borderRadius: "14px",
        fontSize: "14px",
      },
      sm: {
        height: "36px",
        borderRadius: "10px",
        fontSize: "14px",
      },
    },
  }),
  variant({
    prop: "color-twf",
    variants: {
      primary: {
        color: "#fff",
        backgroundColor: "#3C64B1",
        borderColor: "#3C64B1",
      },
      secondary: {
        color: "#fff",
        backgroundColor: "#FB8832",
        borderColor: "#FB8832",
        ":hover, :focus, :active": {
          backgroundColor: "#FB8832",
          borderColor: "#FB8832",
          opacity: 0.9,
        },
      },
      "primary-line": {
        borderColor: "#3C64B1",
        color: "#3C64B1",
      },
      "secondary-line": {},
      white: {
        color: "#333333",
        backgroundColor: "#fff",
        borderColor: "#fff",
      },
      "gray-line": {
        color: "#979797",
        backgroundColor: "transparent",
        borderColor: "#979797",
      },
    },
  }),
  variant({
    prop: "type-twf",
    variants: {
      icon: {
        border: "none",
        boxShadow: "none",
        backgroundColor: "transparent",
      },
      wide: {
        minWidth: "135px",
      },
      full: {
        width: "100%",
      },
      normal: {},
    },
  })
);

export default TwfButton;
