import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const HamburgerActiveIcon = ({ width, color }: IconProps) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.36933 0H24.6292C25.3832 0 26 0.427 26 0.949V1.051C26 1.573 25.3832 2 24.6292 2H1.36933C0.615333 2 0 1.573 0 1.051V0.949C0 0.427 0.615333 0 1.36933 0ZM24.6292 5H1.36933C0.615333 5 0 5.427 0 5.949V6.051C0 6.573 0.615333 7 1.36933 7H24.6292C25.3832 7 26 6.573 26 6.051V5.949C26 5.427 25.3832 5 24.6292 5ZM16.1037 10H0.895333C0.402333 10 0 10.427 0 10.949V11.051C0 11.573 0.402333 12 0.895333 12H16.1037C16.5967 12 17 11.573 17 11.051V10.949C17 10.427 16.5967 10 16.1037 10Z"
      fill={color ? color : "#000000"}
    />
    <path
      d="M14 13.85C14 15.4015 14.6163 16.8895 15.7134 17.9866C16.8105 19.0837 18.2985 19.7 19.85 19.7C21.4015 19.7 22.8895 19.0837 23.9866 17.9866C25.0837 16.8895 25.7 15.4015 25.7 13.85C25.7 12.2985 25.0837 10.8105 23.9866 9.71343C22.8895 8.61634 21.4015 8 19.85 8C18.2985 8 16.8105 8.61634 15.7134 9.71343C14.6163 10.8105 14 12.2985 14 13.85ZM19.3898 10.7898C19.5119 10.6677 19.6774 10.5992 19.85 10.5992C20.0226 10.5992 20.1881 10.6677 20.3102 10.7898C20.4323 10.9119 20.5008 11.0774 20.5008 11.25C20.5008 11.4226 20.4323 11.5881 20.3102 11.7102L18.8191 13.2H22.45C22.6224 13.2 22.7877 13.2685 22.9096 13.3904C23.0315 13.5123 23.1 13.6776 23.1 13.85C23.1 14.0224 23.0315 14.1877 22.9096 14.3096C22.7877 14.4315 22.6224 14.5 22.45 14.5H18.8191L20.3102 15.9898C20.4323 16.1119 20.5008 16.2774 20.5008 16.45C20.5008 16.6226 20.4323 16.7881 20.3102 16.9102C20.1881 17.0323 20.0226 17.1008 19.85 17.1008C19.6774 17.1008 19.5119 17.0323 19.3898 16.9102L16.7898 14.3102C16.6689 14.189 16.6007 14.0251 16.6 13.8539V13.8461C16.601 13.6767 16.6682 13.5143 16.7872 13.3937L16.7911 13.3898L19.3911 10.7898H19.3898Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

const HamburgerIcon = ({ width, color }: IconProps) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 26 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.36933 0H24.6292C25.3832 0 26 0.427 26 0.949V1.051C26 1.573 25.3832 2 24.6292 2H1.36933C0.615333 2 0 1.573 0 1.051V0.949C0 0.427 0.615333 0 1.36933 0ZM24.6292 5H1.36933C0.615333 5 0 5.427 0 5.949V6.051C0 6.573 0.615333 7 1.36933 7H24.6292C25.3832 7 26 6.573 26 6.051V5.949C26 5.427 25.3832 5 24.6292 5ZM24.6292 10H1.36933C0.615333 10 0 10.427 0 10.949V11.051C0 11.573 0.615333 12 1.36933 12H24.6292C25.3832 12 26 11.573 26 11.051V10.949C26 10.427 25.3832 10 24.6292 10Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export { HamburgerIcon, HamburgerActiveIcon };
