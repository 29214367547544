import { AutoComplete, Col, Form, InputNumber, Row } from "antd";
import Icon from "assets/icons";
import TwfButton from "component/button";
import {
  SeasonProductVariantInput,
  //useGetProductVariantsSearchLazyQuery,
  useGetProductVariantsAdvancedSearchLazyQuery,
} from "generated/graphql";
import { FC, useEffect, useState } from "react";

const { Item } = Form;

export interface VariantOptionWithPrice {
  label: string;
  value: string;
  price: string;
}

interface OrderItemProps {
  seasonProduct: SeasonProductVariantInput;
  onSeasonProductChange: (
    seasonProduct: SeasonProductVariantInput & { originalPrice?: number }
  ) => void;
  onSeasonProductRemove: () => void;
  productName?: string;
  productPrice?: number;
}

const SeasonProductVariant: FC<OrderItemProps> = ({
  seasonProduct,
  onSeasonProductChange,
  onSeasonProductRemove,
  productName,
  productPrice,
}) => {
  const [getProductVariantsSearchQuery, { data: productResult }] =
    useGetProductVariantsAdvancedSearchLazyQuery({
      fetchPolicy: "network-only",
    });

  const [productVariantValue, setProductVariantValue] = useState<string>("");
  const [productVariantDefaultPrice, setProductVariantDefaultPrice] =
    useState<number>();
  const [productVariantOptions, setProductVariantOptions] = useState<
    VariantOptionWithPrice[]
  >([]);

  useEffect(() => {
    if (productName) setProductVariantValue(productName);
    if (productPrice) {
      setProductVariantDefaultPrice(productPrice / 100);
    }
  }, [productName, productPrice]);

  useEffect(() => {
    const result = productResult?.productVariantsAdvancedSearch.items.map(
      (c) => ({
        label: `${c.product.name} ${c.name}`,
        value: c.id,
        price: (c.priceWithTax / 100).toFixed(2),
      })
    );
    setProductVariantOptions(result || []);
  }, [productResult]);

  const productVariantSearchHandler = (term: string) => {
    getProductVariantsSearchQuery({
      variables: {
        options: {
          filter: { name: { contains: term } },
          take: 10,
        },
      },
    });
  };

  const productVariantChangeHandler = (value: any) => {
    if (typeof value === "string") {
      setProductVariantValue(value);
    } else {
      productVariantSearchHandler("");
    }
  };

  const productVariantSelectHandler = (value: string, item: any) => {
    setProductVariantValue(item.label);
    setProductVariantDefaultPrice(item.price);
    onSeasonProductChange({
      ...seasonProduct,
      productVariantID: item.value,
      price: item.price,
    });
  };

  return (
    <Row gutter={12}>
      <Col span={2}>
        <Item
          label="Order"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <InputNumber
            value={seasonProduct.order}
            onChange={(value) =>
              onSeasonProductChange({
                ...seasonProduct,
                order: value,
              })
            }
            size="large"
            style={{ borderRadius: 12, width: "100%" }}
          />
        </Item>
      </Col>
      <Col span={8}>
        <Item label="Product">
          <AutoComplete
            options={productVariantOptions}
            onSelect={productVariantSelectHandler}
            style={{ minWidth: 200, width: "100%" }}
            onSearch={productVariantSearchHandler}
            value={productVariantValue}
            onChange={productVariantChangeHandler}
            onFocus={productVariantChangeHandler}
            onBlur={productVariantChangeHandler}
            onMouseEnter={productVariantChangeHandler}
            onClick={productVariantChangeHandler}
          ></AutoComplete>
        </Item>
      </Col>
      <Col span={4}>
        <Item label="Price">
          <InputNumber
            size="large"
            style={{ borderRadius: 12, width: "100%" }}
            value={productVariantDefaultPrice}
            readOnly={true}
          />
        </Item>
      </Col>
      <Col span={4}>
        <Item
          label="Override"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <InputNumber
            size="large"
            style={{ borderRadius: 12, width: "100%" }}
            value={seasonProduct.price}
            onChange={(value) =>
              onSeasonProductChange({
                ...seasonProduct,
                price: value,
                originalPrice: productVariantDefaultPrice,
              })
            }
          />
        </Item>
      </Col>
      <Col span={4}>
        <Item
          label="Quantity"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <InputNumber
            size="large"
            style={{ borderRadius: 12, width: "100%" }}
            value={seasonProduct.defaultBottle}
            onChange={(value) =>
              onSeasonProductChange({
                ...seasonProduct,
                defaultBottle: value,
              })
            }
          />
        </Item>
      </Col>
      {/*<Col span={2}>
        <Item
          label="Min"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <InputNumber
            size="large"
            style={{ borderRadius: 12, width: "100%" }}
            value={seasonProduct.minimumBottle}
            onChange={(value) =>
              onSeasonProductChange({
                ...seasonProduct,
                minimumBottle: value,
              })
            }
          />
        </Item>
      </Col>
      <Col span={2}>
        <Item
          label="Max"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <InputNumber
            size="large"
            style={{ borderRadius: 12, width: "100%" }}
            value={seasonProduct.maximumBottle}
            onChange={(value) =>
              onSeasonProductChange({
                ...seasonProduct,
                maximumBottle: value,
              })
            }
          />
        </Item>
          </Col>*/}
      <Col span={1}>
        <Item label=" ">
          <TwfButton
            shape="circle"
            type-twf="icon"
            title="Delete"
            onClick={onSeasonProductRemove}
          >
            <Icon keyword="delete" color="#FF4C61" />
          </TwfButton>
        </Item>
      </Col>
    </Row>
  );
};

export default SeasonProductVariant;
