import React, { FC } from "react";
import AppBar from "../../../component/app-bar";
import { Col, Row } from "antd";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";

interface FooterProps {
  current: string;
  setCurrent: (value: string) => void;
}

const CustomerDashboardMobileFooter: FC<FooterProps> = ({
                                                          current,
                                                          setCurrent
                                                        }) => {

  return (
    <AppBar>
      <Row justify="space-around" align="middle">
        <Col>
          <TwfButton
            shape="circle"
            type-twf="icon"
            onClick={() => {
              setCurrent("orders");
            }}
          >
            <Icon
              keyword="archive"
              color={
                current === "orders"
                  ? "#3C64B1"
                  : "#979797"
              }
              width={24}
            />
          </TwfButton>
          <p
            style={{
              color: current === "orders"
                ? "#3C64B1"
                : "#979797"
            }}
          >
            Orders
          </p>
        </Col>

        <Col>
          <TwfButton
            shape="circle"
            type-twf="icon"
            onClick={() => {
              setCurrent("announcements");
            }}
          >
            <Icon
              keyword="announce"
              color={
                current === "announcements"
                  ? "#3C64B1"
                  : "#979797"
              }
              width={24}
            />
          </TwfButton>
          <p
            style={{
              color: current === "announcements"
                ? "#3C64B1"
                : "#979797"
            }}
          >
            Announcements
          </p>
        </Col>
      </Row>
    </AppBar>
  );
};

export default CustomerDashboardMobileFooter;
