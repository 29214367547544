import {
  Col,
  Typography,
  Grid,
  message,
  Row,
  Table,
  TablePaginationConfig,
} from "antd";
import Container from "component/container";
import {
  SortOrder,
  useDeleteAnnouncementMutation,
  useGetAnnouncementsLazyQuery,
} from "generated/graphql";
import { TableOptions } from "pages/orders/components/datatable";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../assets/icons";
import TwfButton from "../../component/button";
import { PageTitle } from "../../component/header";
import { MarginBox } from "../../component/margin";
import AnnouncementCreate from "./components/create";
import AnnouncementUpdate from "./components/edit";

const { useBreakpoint } = Grid;
const { Paragraph } = Typography;

interface PageProps {}

const AnnouncementPage: FC<PageProps> = () => {
  const breakpoints = useBreakpoint();
  const history = useNavigate();
  const [getAnnouncementsQuery, { data, loading }] =
    useGetAnnouncementsLazyQuery();
  const [deleteAnnouncementMutation] = useDeleteAnnouncementMutation();

  const [createVisible, setCreateVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [announcement, setAnnouncement] = useState<any>();
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    pagination: { current: 1, pageSize: 10 },
    sort: { id: SortOrder.Desc },
  });

  let mainAdmin = false;

  if (localStorage) {
    if (localStorage.getItem("used-channel") === "__default_channel__") {
      mainAdmin = true;
    }
  }

  if (!mainAdmin) {
    history("/");
  }

  useEffect(() => {
    getAnnouncementsQuery({
      variables: {
        options: {
          skip:
            (tableOptions.pagination.pageSize || 10) *
            ((tableOptions.pagination.current || 1) - 1),
          take: tableOptions.pagination.pageSize,
          sort: tableOptions.sort,
        },
      },
    });
  }, [getAnnouncementsQuery, tableOptions]);

  const deleteAnnouncement = (id: string) => {
    deleteAnnouncementMutation({
      variables: { id },
      refetchQueries: ["GetAnnouncements"],
    })
      .then(() => message.success("Announcement deleted"))
      .catch((error) => message.error(error.message));
  };

  const tableChangeHandler = (
    pagination: TablePaginationConfig,
    filters: any,
    sort: any
  ) => {
    setTableOptions({
      pagination,
      sort: sort.field
        ? {
            [sort.field]:
              sort.order === "ascend" ? SortOrder.Asc : SortOrder.Desc,
          }
        : tableOptions.sort,
    });
  };

  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: {},
    },
    {
      title: "Content",
      dataIndex: "content",
      width: 300,
      sorter: {},
      render: (content: any) => {
        return (
          <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: "more" }}>
            {content.toString().replace(/<(.|\n)*?>/g, "")}
          </Paragraph>
        );
      },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      sorter: {},
      render: (date: any) => {
        return <span>{new Date(date).toLocaleDateString()}</span>;
      },
    },
    {
      title: "",
      key: "action",
      width: breakpoints.md ? 102 : 50,
      fixed: "right",
      render: (record: any) => {
        return (
          <Row gutter={10}>
            <Col span={12}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setAnnouncement(record);
                  setUpdateVisible(true);
                }}
              >
                <Icon keyword="edit" color="green" />
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  deleteAnnouncement(record.id);
                }}
              >
                <Icon keyword="delete" color="red" />
              </div>
            </Col>
          </Row>
        );
      },
    },
  ];

  if (!mainAdmin) {
    return <></>;
  }

  return (
    <div>
      <Row justify="space-between" gutter={[24, 12]} align="middle">
        <Col>
          <PageTitle>Announcements</PageTitle>
        </Col>
        <Col>
          <Row gutter={[24, 24]}>
            <Col>
              <TwfButton
                type="primary"
                type-twf="wide"
                color-twf="primary"
                size-twf={breakpoints.md ? "lg" : "md"}
                onClick={() => {
                  setCreateVisible(true);
                }}
              >
                {breakpoints.md && <Icon keyword="plus" color="#fff" />}
                Create New Announcement
              </TwfButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <Container>
        <MarginBox mt={32}>
          <MarginBox mt={12} mb={56}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={data?.announcements.items.map((obj) => ({
                ...obj,
                key: obj.id,
              }))}
              pagination={{
                ...tableOptions.pagination,
                total: data?.announcements.totalItems,
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              onChange={tableChangeHandler}
              style={{ maxWidth: "max-content" }}
              scroll={{ x: "max-content" }}
            />
          </MarginBox>
        </MarginBox>
      </Container>
      <AnnouncementCreate
        visible={createVisible}
        setVisible={setCreateVisible}
      />
      <AnnouncementUpdate
        key={Math.floor(Math.random() * 10000)}
        announcement={announcement}
        visible={updateVisible}
        setVisible={setUpdateVisible}
      />
    </div>
  );
};

export default AnnouncementPage;
