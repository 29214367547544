import React, { FC, Fragment } from "react";
import { PageTitle } from "../../../component/header";
import { Dropdown, Menu, Space, Table } from "antd";
import ShadowCard from "component/card";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";

const { Item } = Menu;

const pageData = [
  {
    key: 1,
    name: "Joie Case Club",
    option: "Read Only",
    signupDate: "06/07/2021",
    cancelDate: "06/08/2021",
    totalShipment: 104
  }
];

const UpcomingShipmentsPage: FC = () => {
  const clubAction: any = () => (
    <Menu style={{ borderRadius: "12px", border: "1px solid #E5E5E5" }}>
      <Item key="track">Track Shipment</Item>
      <Item key="cancel">Cancel Shipment</Item>
      <Item key="message">Send Message</Item>
    </Menu>
  );

  const columns: any = [
    {
      title: "Club Name",
      dataIndex: "name"
    },
    {
      title: "Club Option",
      dataIndex: "option"
    },
    {
      title: "Signup Data",
      dataIndex: "signupDate"
    },
    {
      title: "Cancel Date",
      dataIndex: "cancelDate"
    },
    {
      title: "Total Shipment",
      dataIndex: "totalShipment",
      render: (price: number) => (
        <Fragment>
          $ {price ? price.toFixed(2) : ""}
        </Fragment>
      )
    },
    {
      title: "Action",
      render: (data: any) => {
        console.log(data);
        return (
          <Fragment>
            <Dropdown overlay={clubAction}>
              <TwfButton type="default" size-twf="sm">
                Club Actions
                <Icon keyword="chevron-down" color="#7D8592" />
              </TwfButton>
            </Dropdown>
          </Fragment>
        );
      }
    }
  ];
  return (
    <Fragment>
      <Space size={24} direction="vertical" style={{ width: "100%" }}>
        <PageTitle>
          Upcoming Shipments
        </PageTitle>
        <ShadowCard>
          <Table
            columns={columns}
            dataSource={pageData}
            pagination={false}
            scroll={{ x: 600 }}
          />
        </ShadowCard>
      </Space>
    </Fragment>
  );
};

export default UpcomingShipmentsPage;