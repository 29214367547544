import React from "react";
import { Col, Form, Row, Tabs } from "antd";
import { PageTitle } from "../../../../component/header";
import TwfButton from "../../../../component/button";
import { MarginBox } from "../../../../component/margin";
import DiscountGeneral from "./general";
import DiscountClubLevels from "./clubLevels";
import DiscountExcludeProducts from "./excludedProducts";
import DiscountDiscounts from "./discounts";
import DiscountShippingTypes from "./shippingTypes";
import DiscountCategories from "./categories";
import DiscountOrderTypes from "./orderTypes";
import DiscountCoupling from "./coupling";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
const { TabPane } = Tabs;

const Discount: React.FC = () => {
  const breakpoints = useBreakpoint();
  return (
    <div>
      <Row justify="space-between" gutter={[30, 15]}>
        <Col>
          <PageTitle>
            {breakpoints.md && "Discounts - "}Name of Discount
          </PageTitle>
        </Col>
        <Col>
          <Row gutter={[24, 24]}>
            <Col>
              <TwfButton type-twf="wide" color-twf="gray-line" size-twf="md">
                Cancel
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                type-twf="wide"
                color-twf="primary"
                type="primary"
                size-twf="md"
              >
                Save
              </TwfButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <MarginBox mt={32}>
        <Form layout="vertical">
          <Tabs>
            <TabPane tab="General" key="general">
              <DiscountGeneral />
            </TabPane>
            <TabPane tab="Club Levels" key="clubLevels">
              <DiscountClubLevels />
            </TabPane>
            <TabPane tab="Excluded Products" key="excludedProducts">
              <DiscountExcludeProducts />
            </TabPane>
            <TabPane tab="Discount" key="discount">
              <DiscountDiscounts />
            </TabPane>
            <TabPane tab="Categories" key="categories">
              <DiscountCategories />
            </TabPane>
            <TabPane tab="Order Types" key="orderTypes">
              <DiscountOrderTypes />
            </TabPane>
            <TabPane tab="Shipping Types" key="shippingTypes">
              <DiscountShippingTypes />
            </TabPane>
            <TabPane tab="Coupling" key="coupling">
              <DiscountCoupling />
            </TabPane>
          </Tabs>
        </Form>
      </MarginBox>
    </div>
  );
};

export default Discount;
