import styled from "styled-components";

interface MarginProps {
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  m?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
  p?: number;
}

export const MarginBox = styled.div<MarginProps>`
  width: 100%;
  margin: ${(props) => (props.m ? props.m : 0)}px;
  margin-top: ${(props) => (props.mt ? props.mt : 0)}px;
  margin-right: ${(props) => (props.mr ? props.mr : 0)}px;
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)}px;
  margin-left: ${(props) => (props.ml ? props.ml : 0)}px;
  padding: ${(props) => (props.p ? props.p : 0)}px;
  padding-top: ${(props) => (props.pt ? props.pt : 0)}px;
  padding-right: ${(props) => (props.pr ? props.pr : 0)}px;
  padding-bottom: ${(props) => (props.pb ? props.pb : 0)}px;
  padding-left: ${(props) => (props.pl ? props.pl : 0)}px;
`;
