/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  Radio,
  Row,
  Select,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Icon from "../../../../assets/icons";
import TwfButton from "../../../../component/button";
import BackButton from "../../../../component/button/back";
import { BlockTitle, H2, SelectTitle } from "../../../../component/header";
import { MarginBox } from "../../../../component/margin";
import {
  LineDetail,
  LineValue,
  PriceTable,
} from "../../../../component/table/price";
import CustomerSearch from "../customer-search";
import DiscountApply from "../discount";
import {
  orderClassifications,
  paymentTypes,
  //pickupLocations,
  sources,
} from "./../../../../enums/enums";
import {
  GetAllCustomerCardsQueryHookResult,
  Order,
  useAddManualPaymentMutation,
  useAddPaymentMutation,
  useCalculateOrderTaxMutation,
  useCheckComplianceMutation,
  useCheckIfCompliantLazyQuery,
  useCreateCustomerCardMutation,
  useCreateCustomerUserIfNotExistMutation,
  useEligibleShippingMethodsLazyQuery,
  useGetActiveChannelQuery,
  useGetAllCustomerCardsQuery,
  useGetOrderQuery,
  useManageComplianceStateMutation,
  usePickUpLabelsQuery,
  useRemoveItemFromOrderMutation,
  useSetCustomerForOrderMutation,
  useSetOrderBillingAddressMutation,
  useSetOrderCustomFieldsMutation,
  useSetOrderShippingAddressMutation,
  useSetShippingMethodMutation,
  useTransitionOrderToStateMutation,
} from "./../../../../generated/graphql";
import CreateConfirmation from "./confirm";
import CreateMobileFooter from "./mobile-footer";
import CreateOrderProducts from "./products";
import CreateOrderReview from "./review";

import { InfoCircleOutlined } from "@ant-design/icons";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { TwfCard } from "component/card";
import USStateSelector from "component/form-elements/us-state-selector";
import SmartyAutoComplete from "component/smarty-street";
import moment from "moment";
import { useDispatch } from "react-redux";
import uuid from "react-uuid";
import { setOrder } from "store/action/ecommerce.action";
import styled from "styled-components";
import { currencyFormatter } from "utils/currency-formatter";
import { Suggestion } from "utils/types/smarty";
import Container from "../../../../component/container";

//const states = require("us-state-converter");

const { Item } = Form;
const { TextArea } = Input;
const { useBreakpoint } = Grid;

interface CardInfo {
  paymentMethodId: string;
}

interface SelectOption {
  label: string;
  value: string;
}

const CreateNewOrder = () => {
  const [form] = Form.useForm();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const history = useNavigate();
  const dispatch = useDispatch();
  const breakpoints = useBreakpoint();
  const orderResult = useSelector((state: any) => state.ecommerce?.order);
  const [helpers, setHelpers] = useState({
    inputTypeBilling: "text",
    inputTypeShipping: "text",
    billingStreetAddress: "",
    disableInputField: false,
    shippingStreetAddress: "",
    shippingProvince: "",
  });
  const [doDiscount, setDoDiscount] = useState(0);
  const [makeDiscount, setMakeDiscount] = useState(0);
  const [pickUp, setPickUp] = useState(false);
  const [pickupLocations, setPickupLocations] = useState<any[]>([]);
  const [shipCost, setShipCost] = useState<number>();
  const [tradeUser, setTradeUser] = useState(false);
  const [orderClass, setOrderClass] = useState<number | null>(1);
  const today = moment();

  const { data: orderDetail } = useGetOrderQuery({
    variables: {
      id: orderResult.id!,
    },
    fetchPolicy: "network-only",
  });

  const { data: pickupLabels } = usePickUpLabelsQuery();

  useEffect(() => {
    if (pickupLabels?.pickupLabels) {
      setPickupLocations(pickupLabels.pickupLabels);
    }
  }, [pickupLabels]);

  const order = orderDetail?.order;

  const [createCustomerUserIfNotExist] =
    useCreateCustomerUserIfNotExistMutation();

  const [setOrderBillingAddressMutation, { data: billingAddressResult }] =
    useSetOrderBillingAddressMutation();
  const [setOrderShippingAddressMutation, { data: shippingAddressResult }] =
    useSetOrderShippingAddressMutation();
  const [setOrderCustomFieldsMutation, { data: customFieldsResult }] =
    useSetOrderCustomFieldsMutation();
  const [setCustomerForOrderMutation] = useSetCustomerForOrderMutation();
  const [transitionOrderToStateMutation] = useTransitionOrderToStateMutation();
  const [removeItemFromOrderMutation] = useRemoveItemFromOrderMutation();
  const [createCustomerCardMutation] = useCreateCustomerCardMutation();

  const [checkIfOrderCompliant] = useCheckIfCompliantLazyQuery();

  const [calculateOrderTaxMutation] = useCalculateOrderTaxMutation({
    fetchPolicy: "network-only",
  });

  const { data } = useGetActiveChannelQuery();

  const [getEligibleShippingMethods, { data: eligibleShippingMethods }] =
    useEligibleShippingMethodsLazyQuery({
      fetchPolicy: "network-only",
    });

  const [setShippingMethodMutation] = useSetShippingMethodMutation();

  const [nullid, setNullId] = useState<string>("");

  const [shippingmethods, setShippingMethods] = useState<any>();

  const shippingMethodsFetch = (order: Order) => {
    getEligibleShippingMethods({
      variables: {
        orderId: order.id,
      },
    })
      .then((data) => {
        if (data?.data?.eligibleShippingMethods) {
          const shippingMethods = data.data.eligibleShippingMethods;
          const SM = [] as any;
          if (shippingMethods.length !== 0) {
            shippingMethods.forEach((val) => {
              const method = Object(val);
              if (method.code !== "null-shipping-method") {
                const nmethod = {
                  label:
                    method.name +
                    " ($" +
                    (method.priceWithTax / 100).toFixed(2) +
                    ")",
                  value: Number(method.id),
                };
                SM.push(nmethod);
              } else {
                setNullId(method.id.toString());
              }
            });
          }
          setShippingMethods(SM);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const [formData, setFormData] = useState<any>();
  const [productVisible, setProductVisible] = useState(false);
  const [reviewVisible, setReviewVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const [loading, setLoading] = useState(false);
  const [shipping, setShipping] = useState(true);
  const [shipM, setShipM] = useState<string | null>();
  const [pickM, setPickM] = useState<string | null>();
  const [card, setCard] = useState<CardInfo | null>(null);
  const [showCardForm, setShowCardForm] = useState<boolean>(false);
  const [cardOptions, setCardOptions] = useState<SelectOption[]>([]);

  const [customerStripeID, setCustomerStripeID] = useState<
    string | undefined
  >();

  const [showReviewButton, setShowReviewButton] = useState(false);

  const [cardEmail, setCardEmail] = useState<string>();

  const [addPaymentMutation] = useAddPaymentMutation();
  const [addManualPaymentMutation] = useAddManualPaymentMutation();

  const [checkComplianceMutation] = useCheckComplianceMutation();

  const [manageComplianceState] = useManageComplianceStateMutation();

  let cards = useGetAllCustomerCardsQuery({
    variables: {
      input: {
        customerEmail: cardEmail
          ? cardEmail
          : form.getFieldValue("billingEmail"),
      },
    },
    fetchPolicy: "network-only",
  });

  const initialValues = {
    billingCountryCode: "United States",
    shippingCountryCode: "United States",
    paymentType: 1,
    defaultShipping: true,
    source: 1,
    orderClassification: 1,
    futureShipDate: today.format(),
  };

  const cardToggle = (event: any) => {
    if (event === 1) {
      setShowCard(true);
    } else {
      setShowCard(false);
      setShowCardForm(false);
    }
  };

  useEffect(() => {
    setCardOptions([
      { label: "Select", value: "0" },
      ...getCustomerCreditCards(cards),
      { label: "- Add a New Card -", value: "-1" },
    ]);
  }, [cards, cardEmail]);

  const getCustomerCreditCards = ({
    data,
  }: GetAllCustomerCardsQueryHookResult): SelectOption[] => {
    if (data?.getCustomerCards && data?.getCustomerCards.items.length > 0) {
      return data.getCustomerCards.items.map((card) => ({
        label: card.isDefault ? card.last4! + " (Default)" : card.last4!,
        value: card.id!,
        selected: card.isDefault! ? "selected" : "",
      }));
    } else {
      return [];
    }
  };

  const cardChangeHandler = (value: string) => {
    if (value === "-1" || value === "0") {
      setCard(null);
      setShowCardForm(value === "-1");
    } else {
      setCard({ paymentMethodId: value } as CardInfo);
      setShowCardForm(false);
    }
  };

  const calculateTax = () => {
    const taxVariable = {
      id: order!.id,
      onSite: pickUp,
    } as any;
    if (tradeUser || orderClass === 4) {
      taxVariable.zero = true;
    }
    return new Promise((resolve, reject) => {
      calculateOrderTaxMutation({
        variables: taxVariable,
        fetchPolicy: "network-only",
      })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  //values are form data
  const onFinishHandler = (values: any) => {
    console.log("onFinish", values);

    if (
      helpers.shippingStreetAddress &&
      helpers.shippingStreetAddress.length !== 0
    ) {
      values.shippingStreetLine1 = helpers.shippingStreetAddress;
    }

    if (
      helpers.billingStreetAddress &&
      helpers.billingStreetAddress.length !== 0
    ) {
      values.billingStreetLine1 = helpers.billingStreetAddress;
    }

    let stateCode;
    if (pickUp) {
      //TODO when its pick up you should use the following hard coded info

      stateCode = "CA";

      //city = "sonoma"
      //zipCode = 95476

      //TODO if you need address
      // Address= "20580 8th St E"
    } else {
      stateCode = values.shippingProvince;
    }

    // internal compliance check
    checkComplianceMutation({
      variables: {
        stateCode: stateCode,
      },
    }).then(async ({ data }) => {
      if (data?.checkCompliance?.__typename === "ComplianceCheckError") {
        message.error(data?.checkCompliance.message);
      }
      if (data?.checkCompliance.__typename === "ComplianceCheck") {
        if (data?.checkCompliance.status === 1) {
          setShowReviewButton(true);
          setReviewVisible(false);
          await calculateTax()
            .then(async () => {
              await saveAddressInfo(orderResult?.id)
                .then(() => {
                  setShowReviewButton(false);
                  setReviewVisible(true);
                })
                .catch((err1) => {
                  setShowReviewButton(false);
                  message.error(err1);
                });
            })
            .catch((err) => {
              const finalError = err + "System Error, Please Try Again";
              setShowReviewButton(false);
              message.error(finalError, 5);
            });
        } else {
          const cleanmsg =
            data?.checkCompliance?.compliance?.complianceAdvisory?.replace(
              /<\/?[^>]+(>|$)/g,
              ""
            );
          const cleanmsg1 =
            data?.checkCompliance?.compliance?.shippingAdvisory?.replace(
              /<\/?[^>]+(>|$)/g,
              ""
            );
          message.warning(cleanmsg);
          message.warning(cleanmsg1);
          setShowReviewButton(true);
        }
      }
    });
  };

  // Submits the order via a child component call back called action ugh
  // check out
  const submitOrder = () => {
    if (order?.lines) {
      if (order?.lines?.length > 0) {
        setLoading(true);
        setCustomerForOrder();
      } else message.error("No item is added to order");
    }
  };

  const allCompleted = () => {
    manageComplianceState({
      variables: {
        id: order!.id,
      },
    })
      .then(() => {
        setLoading(false);
        message.success("You have successfully added order information");
        setConfirmVisible(true);
        setReviewVisible(false);
        setTimeout(() => {
          history("/orders");
        }, 5000);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message);
      });
  };

  const createUserIfNotExits = (id: string) => {
    return new Promise((resolve, reject) => {
      createCustomerUserIfNotExist({
        variables: {
          id: id,
        },
      })
        .then((data) => {
          if (data?.data?.createCustomerUserIfNotExist) {
            setCustomerStripeID(
              data.data.createCustomerUserIfNotExist.customFields?.stripeId
            );
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const setCustomerForOrder = () => {
    if (orderResult?.id) {
      setCustomerForOrderMutation({
        variables: {
          orderId: orderResult.id,
          input: {
            firstName: form.getFieldValue("billingFirstName"),
            lastName: form.getFieldValue("billingLastName"),
            phoneNumber: form.getFieldValue("billingPhoneNumber"),
            emailAddress: form.getFieldValue("billingEmail"),
            customFields: {
              adminCreated: true,
            },
          },
        },
      }).then(({ data }) => {
        if (data?.setCustomerForOrder.__typename === "Order") {
          const customerID = data.setCustomerForOrder.customer?.id;
          if (customerID) {
            createUserIfNotExits(customerID).then((data: any) => {
              if (data === true) {
                manageAddresses();
              } else {
                setLoading(false);
                message.error(data);
              }
            });
          }
        }
      });
    }
  };

  const manageAddresses = () => {
    saveBillingInfo(orderResult.id)
      .then((value) => {
        if (value === true) {
          saveShippingInfo(orderResult.id)
            .then((value1) => {
              if (value1 === true) {
                continueshipping();
              } else {
                setLoading(false);
                message.error(value);
              }
            })
            .catch((error) => {
              setLoading(false);
              message.error(error);
            });
        } else {
          setLoading(false);
          message.error(value);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };

  const continueshipping = () => {
    if (form.getFieldValue("shippingMethod")) {
      const sM = shippingmethods.find(
        (sM: any) => sM.value === Number(form.getFieldValue("shippingMethod"))
      );

      if (sM?.label) {
        setOrderCustomFieldsMutation({
          variables: {
            input: {
              id: orderResult.id,
              customFields: {
                csvShippingMethod: sM.label.split("(")[0].trim(),
                orderClassification: tradeUser
                  ? 4
                  : form.getFieldValue("orderClassification"),
                specialInstructions: form.getFieldValue("specialInstructions"),
                giftMessage: form.getFieldValue("giftMessage"),
                //this dose work
                futureShipDate: form.getFieldValue("futureShipDate"),
              },
            },
          },
          fetchPolicy: "network-only",
          refetchQueries: ["FilteredOrders"],
        })
          .then(() => {
            shipit(sM.value);
          })
          .catch((error) => {
            message.error(error.message);
          });
      } else {
        message.error(
          "Selected shipping method doesn't exist or not applicable"
        );
      }
    } else {
      makePickup();
    }
  };

  const makePickup = () => {
    setOrderCustomFieldsMutation({
      variables: {
        input: {
          id: orderResult.id,
          customFields: {
            pickupLocation: form.getFieldValue("pickupLocation"),
          },
        },
      },
      fetchPolicy: "network-only",
    })
      .then(() => {
        setShippingMethod(nullid)
          .then(() => {
            middleman();
          })
          .catch(() => {
            setLoading(false);
            message.error("Setting Pickup Location Error");
          });
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const makeIntermediatePickup = (e: any) => {
    saveAddressInfo(orderResult.id)
      .then(() => {
        setOrderCustomFieldsMutation({
          variables: {
            input: {
              id: orderResult.id,
              customFields: {
                pickupLocation: e,
                orderClassification: tradeUser
                  ? 4
                  : form.getFieldValue("orderClassification"),
              },
            },
          },
          fetchPolicy: "network-only",
          refetchQueries: ["getOrder"],
        })
          .then(() => {
            setShippingMethod(nullid)
              .then(() => {})
              .catch(() => {
                setLoading(false);
                message.error("Setting Pickup Location Error");
              });
          })
          .catch((error) => {
            message.error(error.message);
          });
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  useEffect(() => {
    if (pickM) {
      makeIntermediatePickup(pickM);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickM]);

  const shipit = (sId: string) => {
    setShippingMethod(sId)
      .then(() => {
        middleman();
      })
      .catch(() => {
        setLoading(false);
        message.error("Setting Shipping Method Error");
      });
  };

  const middleman = () => {
    if (order?.state !== "ArrangingPayment") {
      transitionOrderToState("ArrangingPayment")
        .then(() => {
          checkCompliance();
        })
        .catch((msg) => {
          setLoading(false);
          message.error(msg);
        });
    } else {
      checkCompliance();
    }
  };

  const continuepayment = () => {
    if (card) {
      if (cards?.data?.getCustomerCards) {
        addPayment(
          cards.data.getCustomerCards.items.find(
            (car) => car.id === card.paymentMethodId
          )?.stripeResponse
        );
      }
    } else {
      if (showCard) {
        createStripeCreditCard()
          .then((paymentMethod) => createCustomerCard(paymentMethod))
          .then((paymentMethod) => addPayment(paymentMethod))
          .catch((error) => {
            setLoading(false);
            message.error(error);
          });
      } else {
        addNullPayment();
      }
    }
  };

  const checkCompliance = () => {
    if (!tradeUser && orderClass !== 4) {
      checkIfOrderCompliant({
        variables: {
          id: orderResult.id!,
        },
      })
        .then((data) => {
          if (data?.data?.checkIfCompliant) {
            message.success("Compliance Passed");
          } else {
            message.error("Compliance Failed");
          }
          continuepayment();
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.message);
        });
    } else {
      continuepayment();
    }
  };

  const setShippingMethod = (shippingMethodId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      setShippingMethodMutation({
        variables: {
          orderId: orderResult.id!,
          shippingMethodId,
        },
        fetchPolicy: "network-only",
        refetchQueries: ["getOrder"],
      }).then(({ data }) => {
        if (data) {
          if (data.setOrderShippingMethod.__typename === "Order") {
            //dispatch<any>(setOrder({ ...formData, ...data.setOrderShippingMethod }));
            //dispatch<any>(setOrder({ ...data.setOrderShippingMethod }));
            resolve(true);
          } else {
            reject(false);
          }
        }
      });
    });
  };

  const addNullPayment = () => {
    addManualPaymentMutation({
      variables: {
        input: {
          orderId: orderResult.id!,
          transactionId: uuid(),
          method: "null",
          metadata: {},
        },
      },
    })
      .then((output) => {
        if (output?.data?.addManualPaymentToOrder?.__typename === "Order") {
          transitionOrderToState("PaymentSettled")
            .then(() => {
              message.info("Order Successfully Processed");
              customFieldsSetting();
            })
            .catch((err) => {
              setLoading(false);
              message.error(err.message);
            });
        } else {
          if (
            output?.data?.addManualPaymentToOrder.__typename ===
            "ManualPaymentStateError"
          ) {
            setLoading(false);
            message.error(output.data.addManualPaymentToOrder.message);
            message.warn("System Error, Please Try Again");
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message);
      });
  };

  const addNotNullPayment = () => {
    addManualPaymentMutation({
      variables: {
        input: {
          orderId: orderResult.id!,
          transactionId: uuid(),
          method: "stripe",
          metadata: {},
        },
      },
    })
      .then((output: any) => {
        if (output.data.addManualPaymentToOrder.__typename === "Order") {
          transitionOrderToState("PaymentSettled")
            .then(() => {
              message.info("Payment Succeed");
              customFieldsSetting();
            })
            .catch((err) => {
              setLoading(false);
              message.error(err.message);
            });
        } else {
          setLoading(false);
          let emsg = output.data.addManualPaymentToOrder.message;
          message.error(emsg);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message);
      });
  };

  const addPayment = (stripeResponse: any) => {
    addPaymentMutation({
      variables: {
        orderId: orderResult.id,
        input: {
          method: "stripe",
          metadata: stripeResponse,
        },
      },
      fetchPolicy: "network-only",
    })
      .then((output: any) => {
        if (output.data.addPaymentToOrder.__typename === "Order") {
          if (output.data.addPaymentToOrder.state === "ArrangingPayment") {
            addNotNullPayment();
          } else {
            message.info("Payment Succeed");
            customFieldsSetting();
          }
        } else {
          let emsg = output.data.addPaymentToOrder.message;
          if (emsg) {
            setLoading(false);
            message.error(emsg);
          } else {
            setLoading(false);
            message.error(output.data.addPaymentToOrder.__typename);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message);
      });
  };

  const saveBillingInfo = (id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const values = {
        fullName:
          form.getFieldValue("billingFirstName") +
          " " +
          form.getFieldValue("billingLastName"),
        phoneNumber: form.getFieldValue("billingPhoneNumber"),
        company: form.getFieldValue("billingCompany"),
        streetLine1:
          helpers.billingStreetAddress ||
          form.getFieldValue("billingStreetLine1"),
        streetLine2: form.getFieldValue("billingStreetLine2"),
        city: form.getFieldValue("billingCity"),
        province: form.getFieldValue("billingProvince"),
        countryCode: "us",
        postalCode: form.getFieldValue("billingPostalCode"),
        customFields: {
          email: form.getFieldValue("billingEmail"),
          dob: form.getFieldValue("billingDOB"),
          firstName: form.getFieldValue("billingFirstName"),
          lastName: form.getFieldValue("billingLastName"),
        },
      } as any;

      setOrderBillingAddressMutation({
        variables: {
          input: values,
          orderId: id,
        },
        fetchPolicy: "network-only",
      })
        .then(({ data }) => {
          if (data?.setOrderBillingAddress.__typename === "Order") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const saveAddressInfo = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      await saveBillingInfo(id)
        .then(async () => {
          await saveShippingInfo(id)
            .then(() => {
              resolve(true);
            })
            .catch((shippingAddressError) => {
              reject(shippingAddressError);
            });
        })
        .catch((billingAddressError) => {
          message.error(billingAddressError);
        });
    });
  };

  const saveShippingInfo = (id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const values = {
        fullName:
          form.getFieldValue("shippingFirstName") +
          " " +
          form.getFieldValue("shippingLastName"),
        phoneNumber: form.getFieldValue("shippingPhoneNumber"),
        streetLine1:
          helpers.shippingStreetAddress ||
          form.getFieldValue("shippingStreetLine1"),
        streetLine2: form.getFieldValue("shippingStreetLine2"),
        company: form.getFieldValue("shippingCompany"),
        city: form.getFieldValue("shippingCity"),
        province:
          form.getFieldValue("shippingProvince") || helpers.shippingProvince,
        countryCode: "us",
        postalCode: form.getFieldValue("shippingPostalCode"),
        customFields: {
          email: form.getFieldValue("shippingEmail"),
          dob: form.getFieldValue("shippingDOB"),
          firstName: form.getFieldValue("shippingFirstName"),
          lastName: form.getFieldValue("shippingLastName"),
        },
      } as any;
      if (pickUp) {
        values["streetLine1"] =
          helpers.billingStreetAddress ||
          form.getFieldValue("billingStreetLine1");
        values["streetLine2"] = form.getFieldValue("billingStreetLine2");
        values["city"] = form.getFieldValue("billingCity");
        values["province"] = form.getFieldValue("billingProvince");
        values["postalCode"] = form.getFieldValue("billingPostalCode");
        values["customFields"]["firstName"] =
          form.getFieldValue("billingFirstName");
        values["customFields"]["lastName"] =
          form.getFieldValue("billingLastName");
        values["fullName"] =
          form.getFieldValue("billingFirstName") +
          " " +
          form.getFieldValue("billingLastName");
      }

      setOrderShippingAddressMutation({
        variables: {
          input: values,
          orderId: id,
        },
        fetchPolicy: "network-only",
        refetchQueries: ["getOrder"],
      })
        .then(({ data }) => {
          if (data?.setOrderShippingAddress.__typename === "Order") {
            //dispatch<any>(setOrder(data.setOrderShippingAddress));
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const customFieldsSetting = () => {
    //console.log(form.getFieldValue("futureShipDate"))
    setOrderCustomFieldsMutation({
      variables: {
        input: {
          id: orderResult.id,
          customFields: {
            source: form.getFieldValue("source"),
            orderClassification: tradeUser
              ? 4
              : form.getFieldValue("orderClassification"),
            paymentType: form.getFieldValue("paymentType"),
            futureShipDate: form.getFieldValue("futureShipDate"),
            pickupLocation: form.getFieldValue("pickupLocation"),
            defaultShipping: form.getFieldValue("defaultShipping"),
            shippingMethod: Number(shipM),
            paid: true,
            byPassed: tradeUser || orderClass === 4,
          },
        },
      },
      fetchPolicy: "network-only",
      refetchQueries: ["filteredOrders"],
    }).then(() => {
      allCompleted();
    });
  };

  const createStripeCreditCard = (): Promise<CardInfo> => {
    return new Promise(async (resolve, reject) => {
      if (elements === null || stripe === null || CardNumberElement === null) {
        reject(undefined);
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement)!,
        billing_details: {
          email: form.getFieldValue("billingEmail"),
          name: form.getFieldValue("nameOnCard"),
          address: {
            postal_code: form.getFieldValue("billingZip"),
            state: form.getFieldValue("billingState"),
          },
        },
        metadata: {
          "store-token": data?.activeChannel?.token || null,
        },
      });
      if (paymentMethod?.id)
        resolve({ paymentMethodId: paymentMethod.id } as CardInfo);
      else reject(error?.message);
    });
  };

  const createCustomerCard = (cardInfo: CardInfo): Promise<CardInfo> => {
    return new Promise(async (resolve, reject) => {
      createCustomerCardMutation({
        variables: {
          paymentMethodId: cardInfo.paymentMethodId,
          customerEmail: form.getFieldValue("billingEmail"),
        },
      })
        .then(({ data }) => {
          if (data?.attachPaymentMethodToCustomer.__typename === "Card") {
            resolve(cardInfo);
          } else if (
            data?.attachPaymentMethodToCustomer.__typename ===
            "PaymentMethodAttachError"
          ) {
            if (
              data.attachPaymentMethodToCustomer.message ===
              "Credit card already exists"
            ) {
              resolve(cardInfo);
            } else {
              reject(data.attachPaymentMethodToCustomer.message);
            }
          }
        })
        .catch((error) => {
          if (error === "Credit card already exists") {
            resolve(cardInfo);
          } else {
            reject(error.message);
          }
        });
    });
  };

  const customerSearchHandler = (customer: any) => {
    // console.log('CUSTOMER on Search handler',customer)
    const defaultCustomerInfo: any = getCustomerAddress(customer.addresses);
    if (customer?.customFields?.isTradeUser) {
      setTradeUser(true);
    } else {
      setTradeUser(false);
    }

    form.setFieldsValue({
      billingFirstName: customer.firstName,
      billingLastName: customer.lastName,
      billingEmail: customer.emailAddress,
      billingPhoneNumber: customer.phoneNumber,
      shippingEmail: customer.emailAddress,
      shippingPhoneNumber: customer.phoneNumber,
      billingDOB: customer.customFields?.dob
        ? new Date(customer.customFields?.dob).toISOString().slice(0, 10)
        : customer.customFields?.dob,
      shippingDOB: customer.customFields?.dob
        ? new Date(customer.customFields?.dob).toISOString().slice(0, 10)
        : customer.customFields?.dob,
      ...defaultCustomerInfo,
    });

    if (defaultCustomerInfo?.billingStreetLine1) {
      setHelpers((prevState) => ({
        ...prevState,
        billingStreetAddress: defaultCustomerInfo.billingStreetLine1,
        inputTypeBilling: "date",
      }));
    }

    if (
      defaultCustomerInfo?.shippingStreetLine1 &&
      defaultCustomerInfo?.shippingProvince
    ) {
      setHelpers((prevState) => ({
        ...prevState,
        shippingStreetAddress: defaultCustomerInfo.shippingStreetLine1,
        shippingProvince: defaultCustomerInfo.shippingProvince,
        inputTypeShipping: "date",
      }));
      setIntermediateStreet1(defaultCustomerInfo.shippingStreetLine1);
      getShippingMethodsByState(defaultCustomerInfo.shippingProvince, 1);
    }

    if (customer.customFields?.dob) {
      setHelpers((prevState) => ({
        ...prevState,
        inputTypeBilling: "date",
        inputTypeShipping: "date",
      }));
    }
  };

  const getCustomerAddress = (addresses: any[]) => {
    let address = {};
    if (addresses.length > 0) {
      const billingAddress: any = addresses.find(
        (address: any) => address.defaultBillingAddress
      );

      if (billingAddress) {
        address = {
          billingStreetLine1: billingAddress.streetLine1,
          billingStreetLine2: billingAddress.streetLine2,
          billingCity: billingAddress.city,
          billingProvince: billingAddress.province,
          billingPostalCode: billingAddress.postalCode,
          billingCompany: billingAddress.company,
          billingDOB:
            new Date(billingAddress?.customFields?.dob)
              .toISOString()
              .slice(0, 10) || null,
        };
      }
      const shippingAddress: any = addresses.find(
        (address: any) => address.defaultShippingAddress
      );
      // console.log('shipping', shippingAddress)
      if (shippingAddress) {
        address = {
          ...address,
          shippingStreetLine1: shippingAddress.streetLine1,
          shippingStreetLine2: shippingAddress.streetLine2,
          shippingCity: shippingAddress.city,
          shippingProvince: shippingAddress.province,
          shippingPostalCode: shippingAddress.postalCode,
          shippingCompany: shippingAddress.company,
          shippingDOB:
            new Date(shippingAddress?.customFields?.dob)
              .toISOString()
              .slice(0, 10) || null,
          shippingFirstName: shippingAddress?.customFields.firstName,
          shippingLastName: shippingAddress?.customFields.lastName,
        };
      }
    }
    return address;
  };

  const transitionOrderToState = (state: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      transitionOrderToStateMutation({
        variables: { id: orderResult.id, state },
      }).then(({ data }) => {
        if (data?.transitionOrderToState?.__typename === "Order") {
          resolve(data.transitionOrderToState);
        }
        if (
          data?.transitionOrderToState?.__typename ===
          "OrderStateTransitionError"
        ) {
          reject(data.transitionOrderToState.message);
        }
      });
    });
  };

  useEffect(() => {
    if (form.getFieldValue("billingEmail")) {
      setCustomerForOrderMutation({
        variables: {
          orderId: orderResult.id,
          input: {
            firstName: form.getFieldValue("billingFirstName"),
            lastName: form.getFieldValue("billingLastName"),
            phoneNumber: form.getFieldValue("billingPhoneNumber"),
            emailAddress: form.getFieldValue("billingEmail"),
          },
        },
      }).then(async ({ data }) => {
        if (data?.setCustomerForOrder.__typename === "Order") {
          await saveAddressInfo(orderResult.id);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const sameAddressHandler = (e: any) => {
    if (!e?.target?.checked) {
      setHelpers((prevState) => ({ ...prevState, disableInputField: false }));
      return;
    }
    setHelpers((prevState) => ({
      ...prevState,
      disableInputField: true,
      shippingStreetAddress: helpers.billingStreetAddress,
    }));

    form.setFieldsValue({
      shippingFirstName: form.getFieldValue("billingFirstName"),
      shippingLastName: form.getFieldValue("billingLastName"),
      shippingEmail: form.getFieldValue("billingEmail"),
      shippingPhoneNumber: form.getFieldValue("billingPhoneNumber"),
      shippingDOB: form.getFieldValue("billingDOB"),
      shippingStreetLine1: form.getFieldValue("billingStreetLine1"),
      shippingStreetLine2: form.getFieldValue("billingStreetLine2"),
      shippingCity: form.getFieldValue("billingCity"),
      shippingProvince: form.getFieldValue("billingProvince"),
      shippingCountryCode: form.getFieldValue("billingCountryCode"),
      shippingPostalCode: form.getFieldValue("billingPostalCode"),
      shippingCompany: form.getFieldValue("billingCompany"),
    });

    if (form.getFieldValue("billingDOB")) {
      setHelpers((prevState) => ({ ...prevState, inputTypeShipping: "date" }));
    }

    if (form.getFieldValue("shippingProvince")) {
      setIntermediateState(form.getFieldValue("shippingProvince"));
      getShippingMethodsByState(form.getFieldValue("shippingProvince"), 1);
    }
    if (form.getFieldValue("shippingPostalCode")) {
      setIntermediateZipCode(form.getFieldValue("shippingPostalCode"));
    }
    if (form.getFieldValue("shippingStreet1")) {
      setIntermediateStreet1(form.getFieldValue("shippingStreet1"));
    }
  };

  const shippingRadioChange = (e: any) => {
    e.preventDefault();
    setShipping(e.target.value);
    if (e.target.value) {
      form.setFieldValue("pickupLocation", null);
      setPickM(null);
      sameAddressHandler(false);
      setPickUp(false);
    } else {
      form.setFieldValue("shippingMethod", null);
      setShipM(null);
      sameAddressHandler(true);
      setPickUp(true);
    }
  };

  const addressChangeHandler = (address: Suggestion | undefined) => {
    form.setFieldsValue({
      billingStreetLine1: address?.street_line,
      billingStreetLine2: address?.secondary,
      billingCity: address?.city,
      billingProvince: address?.state,
      billingPostalCode: address?.zipcode,
    });
  };

  const shippingAddressChangeHandler = (address: Suggestion | undefined) => {
    form.setFieldsValue({
      shippingStreetLine1: address?.street_line,
      shippingStreetLine2: address?.secondary,
      shippingCity: address?.city,
      shippingProvince: address?.state,
      shippingPostalCode: address?.zipcode,
    });
    if (address?.state) {
      setHelpers((prevState) => ({
        ...prevState,
        shippingProvince: address.state,
      }));
      getShippingMethodsByState(address.state, 1);
    }
  };

  useEffect(() => {
    if (order?.totalQuantity) {
      if (order?.shippingAddress?.province) {
        getShippingMethodsByState(order.shippingAddress.province, 1);
      } else {
        shippingMethodsFetch(order as Order);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.totalQuantity]);

  const getShippingMethodsByState = (
    myState: string,
    another = 0,
    newOrder?: Order
  ) => {
    let myOrder = order as Order;

    if (newOrder) {
      myOrder = newOrder as Order;
    }

    if (another === 1) {
      form.resetFields(["shippingMethod"]);
    }

    saveShippingInfo(myOrder.id).then(() => {
      shippingMethodsFetch(myOrder);
    });
  };

  const deleteOrderLine = (id: string) => {
    removeItemFromOrderMutation({
      variables: {
        orderId: order?.id!,
        orderLineId: id,
      },
    })
      .then(({ data }: any) => {
        if (data?.removeOrderLine.__typename === "Order") {
          message.success("Item removed");
          dispatch<any>(setOrder(data.removeOrderLine));
          const state = localStorage.getItem("province");
          if (state) {
            getShippingMethodsByState(state, 1, data.removeOrderLine);
          }
        }
      })
      .catch((error: any) => message.error(error.message));
  };

  const setEmail = (e: any) => {
    setCardEmail(e.target.value);
  };

  const checkIfTWF = (e: any) => {
    setShowReviewButton(false);
    setShipM(e);
  };

  const callMethod = () => {
    if (shipM) {
      const sM = shippingmethods.find((sM: any) => sM.value === Number(shipM));
      if (sM) {
        saveAddressInfo(orderResult.id).then(() => {
          setOrderCustomFieldsMutation({
            variables: {
              input: {
                id: orderResult.id,
                customFields: {
                  csvShippingMethod: sM.label.split("(")[0].trim(),
                },
              },
            },
            refetchQueries: ["getOrder"],
          }).then((data) => {
            setShippingMethod(shipM);
          });
        });
      }
    }
  };

  useEffect(() => {
    if (shipM) {
      callMethod();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipM]);

  const [intermediateState, setIntermediateState] = useState("");
  const [intermediateStreet1, setIntermediateStreet1] = useState("");
  const [intermediateZipCode, setIntermediateZipCode] = useState("");

  const getShippingTypes = (value: string) => {
    setIntermediateState(value);
    setHelpers((prevState) => ({
      ...prevState,
      shippingProvince: value,
    }));
    getShippingMethodsByState(value, 1);
  };

  if (!order) {
    return <>loading...</>;
  }

  const now = new Date();
  const minDate = new Date(
    now.getFullYear() - 130,
    now.getMonth(),
    now.getDate()
  );
  const maxDate = new Date(
    now.getFullYear() - 21,
    now.getMonth(),
    now.getDate()
  );

  const disabledDate = (current: any) => {
    const thirtyDaysFromToday = moment().add(30, "days");

    // Handle the case when current is null or undefined
    if (!current) {
      return false; // Allow all dates when there's no selection
    }

    // Handle the case when current is not a valid moment object
    if (!moment.isMoment(current) || !current.isValid()) {
      return false; // Allow all dates when the current value is not a valid moment object
    }

    // Disable dates before today or after 30 days from today
    return current < today || current > thirtyDaysFromToday;
  };

  return (
    <Container>
      <Row justify="space-between" gutter={[24, 24]}>
        <Col>
          <Row>
            <Col>
              <Row align="middle" gutter={12}>
                <Col>
                  <BackButton />
                </Col>
                <Col>
                  <H2>Create New Order</H2>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={[24, 24]}>
            <Col>
              <CustomerSearch onChange={customerSearchHandler} />
            </Col>
            {breakpoints.md && (
              <React.Fragment>
                <Col>
                  <TwfButton
                    size-twf="lg"
                    color-twf="primary"
                    type="primary"
                    onClick={() => {
                      setProductVisible(true);
                    }}
                  >
                    <Icon keyword="plus" color="#ffffff" width={24} /> Add
                    Products
                  </TwfButton>
                </Col>
                <Col>
                  <TwfButton
                    size-twf="lg"
                    color-twf="primary"
                    type="primary"
                    onClick={() => {
                      history("/customers/create");
                    }}
                  >
                    <Icon keyword="plus" color="#ffffff" width={24} />
                    Add New Customer
                  </TwfButton>
                </Col>
                <Col>
                  <TwfButton
                    color-twf="secondary"
                    type="primary"
                    size-twf="md"
                    style={{ padding: "0 31px" }}
                    onClick={() => form.resetFields()}
                  >
                    Reset
                  </TwfButton>
                </Col>
              </React.Fragment>
            )}
          </Row>
        </Col>
      </Row>
      <TwfCard
        style={{
          marginTop: 32,
          padding: breakpoints.md ? "39px 44px" : "19px 24px 91px",
        }}
      >
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={onFinishHandler}
        >
          <Row gutter={[30, 30]}>
            <Col span={24} xxl={18}>
              <BlockTitle>1 ) Billing Information</BlockTitle>
              <Row gutter={[30, 30]}>
                <Col lg={{ span: 12 }} md={{ span: 24 }} span={24}>
                  <Row gutter={17}>
                    <Col sm={12} span={24}>
                      <Item
                        name="billingFirstName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter first name",
                          },
                        ]}
                      >
                        <Input placeholder="First Name" />
                      </Item>
                    </Col>
                    <Col sm={12} span={24}>
                      <Item
                        name="billingLastName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter last name",
                          },
                        ]}
                      >
                        <Input placeholder="Last Name" />
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col sm={12} span={24}>
                      <Item name="billingPhoneNumber">
                        <Input placeholder="Phone" />
                      </Item>
                    </Col>
                    <Col sm={12} span={24}>
                      <Item
                        name="billingEmail"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your email",
                          },
                        ]}
                      >
                        {<Input onChange={setEmail} placeholder="Email" />}
                        {/*
                          <CustomerEmailSearch someValue={em} onChange={setEmail}></CustomerEmailSearch>
                          */}
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col sm={24} span={24}>
                      <Item name="billingCompany">
                        <Input placeholder="Company" />
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Item name="billingDOB">
                        <Input
                          type={helpers.inputTypeBilling}
                          id="date_of_birth_alt"
                          name="date_of_birth_alt"
                          min={minDate.toISOString().substring(0, 10)}
                          max={maxDate.toISOString().substring(0, 10)}
                          autoComplete="bday"
                          required
                          placeholder="DOB MM/DD/YYYY"
                          onFocus={() =>
                            setHelpers((prevState) => ({
                              ...prevState,
                              inputTypeBilling: "date",
                            }))
                          }
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Item
                        name="billingStreetLine1"
                        rules={[
                          {
                            required: true,
                            message: "street address is required!",
                            validator: async (_, value) => {
                              if (!value || value.length) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject();
                              }
                            },
                          },
                        ]}
                      >
                        <SmartyAutoComplete
                          customerStreetAddress1={helpers.billingStreetAddress}
                          selectedData={addressChangeHandler}
                          onChangeValue={(value: string) =>
                            setHelpers((prevState) => ({
                              ...prevState,
                              billingStreetAddress: value,
                            }))
                          }
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Item name="billingStreetLine2">
                        <Input placeholder="Address 2" />
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col sm={12} span={24}>
                      <Item
                        name="billingCity"
                        rules={[
                          {
                            required: true,
                            message: "Please enter city",
                          },
                        ]}
                      >
                        <Input placeholder="City" />
                      </Item>
                    </Col>
                    <Col sm={12} span={24}>
                      <USStateSelector
                        name="billingProvince"
                        rules={[
                          {
                            required: true,
                            message: "Please enter state",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    {/* <Col sm={12} span={24}>
                      <Item name="billingCountryCode">
                        <Input placeholder="Country" readOnly />
                      </Item>
                    </Col> */}
                    <Col sm={12} span={24}>
                      <Item
                        name="billingPostalCode"
                        rules={[
                          {
                            required: true,
                            message: "Please enter zip",
                          },
                        ]}
                      >
                        <Input placeholder="Zip" />
                      </Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  span={24}
                  style={{
                    marginTop: breakpoints.lg ? "-26px" : 0,
                    position: "relative",
                  }}
                >
                  <BlockTitle
                    style={{
                      position: "absolute",
                      left: 10,
                    }}
                  ></BlockTitle>
                  <Row gutter={17}>
                    <Col sm={12} span={24}>
                      <h1> 3 ) Source</h1>
                      <Item name="source">
                        <Select
                          suffixIcon={
                            <Icon keyword="chevron-down" color="#7D8592" />
                          }
                          placeholder="Select"
                          onChange={() => {}}
                          allowClear
                          options={sources}
                        />
                      </Item>
                    </Col>
                    <Col sm={12} span={24}>
                      <SelectTitle>Order Classification</SelectTitle>
                      <Item name="orderClassification">
                        <Select
                          suffixIcon={
                            <Icon keyword="chevron-down" color="#7D8592" />
                          }
                          placeholder="Select"
                          onChange={(e) => {
                            //console.log(e);
                            setOrderClass(e);
                          }}
                          allowClear
                          options={orderClassifications}
                        ></Select>
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col span={24}>
                      <SelectTitle>Gift Message</SelectTitle>
                      <Item name="giftMessage">
                        <TextArea
                          placeholder="Gift Message"
                          rows={5}
                          style={{ minHeight: 86 }}
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col span={24}>
                      <SelectTitle>Payment Type</SelectTitle>
                      <Item name="paymentType">
                        <Select
                          suffixIcon={
                            <Icon keyword="chevron-down" color="#7D8592" />
                          }
                          placeholder="Select"
                          onChange={cardToggle}
                          allowClear
                          options={paymentTypes}
                        ></Select>
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col span={24}>
                      <SelectTitle>
                        Future Ship Date{" "}
                        <Tooltip title="You may select a Ship Date up to 30 days in the future.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </SelectTitle>
                      <Item
                        name="futureShipDate"
                        getValueProps={(i) => ({
                          value: i ? moment(i) : undefined,
                        })}
                      >
                        <DatePicker
                          disabledDate={disabledDate}
                          showToday={false}
                          defaultValue={today}
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col span={24}>
                      {showCard && (
                        <>
                          <SelectTitle>Credit Card Information</SelectTitle>
                          <Item
                            name="creditCardInformation"
                            rules={[
                              {
                                required: true,
                                message: "Please choose the credit card",
                              },
                            ]}
                          >
                            <Select
                              suffixIcon={
                                <Icon keyword="chevron-down" color="#7D8592" />
                              }
                              placeholder="Select"
                              allowClear
                              options={cardOptions}
                              onChange={cardChangeHandler}
                            ></Select>
                          </Item>
                        </>
                      )}
                    </Col>
                  </Row>
                  {showCardForm && (
                    <Row>
                      <Col span={24}>
                        <Item name="nameOnCard">
                          <Input placeholder="Name on Card" />
                        </Item>
                      </Col>
                      <Col span={24}>
                        <CardNumberItem name="cardNumber">
                          <CardNumberElement options={options} />
                        </CardNumberItem>
                      </Col>
                      <Col span={12}>
                        <CardExpiryItem name="expiryDate">
                          <CardExpiryElement options={options} />
                        </CardExpiryItem>
                      </Col>
                      <Col span={12}>
                        <CardCvcItem name="cvs">
                          <CardCvcElement options={options} />
                        </CardCvcItem>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <MarginBox mt={34}>
                <Row gutter={34}>
                  <Col span={breakpoints.md ? 12 : 24}>
                    <BlockTitle hidden={pickUp}>
                      2 ) Shipping Information
                    </BlockTitle>
                    <Row>
                      <Col span={24}>
                        <Item hidden={pickUp}>
                          <Checkbox onChange={sameAddressHandler}>
                            Same as billing information
                          </Checkbox>
                        </Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={24} span={24}>
                        <Item hidden={pickUp} name="shippingCompany">
                          <Input
                            placeholder="Company"
                            disabled={helpers.disableInputField}
                          />
                        </Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Row gutter={17}>
                          <Col md={12} span={24}>
                            <Item
                              hidden={pickUp}
                              name="shippingFirstName"
                              rules={[
                                {
                                  required: !pickUp,
                                  message: "Please enter first name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="First Name"
                                disabled={helpers.disableInputField}
                              />
                            </Item>
                          </Col>
                          <Col md={12} span={24}>
                            <Item
                              hidden={pickUp}
                              name="shippingLastName"
                              rules={[
                                {
                                  required: !pickUp,
                                  message: "Please enter last name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Last Name"
                                disabled={helpers.disableInputField}
                              />
                            </Item>
                          </Col>
                        </Row>

                        <Row gutter={17}>
                          <Col sm={12} span={24}>
                            <Item name="shippingPhoneNumber" hidden={pickUp}>
                              <Input
                                placeholder="Phone"
                                disabled={helpers.disableInputField}
                              />
                            </Item>
                          </Col>
                          <Col sm={12} span={24}>
                            <Item name="shippingEmail" hidden={pickUp}>
                              <Input
                                placeholder="Email"
                                disabled={helpers.disableInputField}
                              />
                            </Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Item name="shippingDOB" hidden={pickUp}>
                              <Input
                                type={helpers.inputTypeShipping}
                                id="date_of_birth_alt"
                                name="date_of_birth_alt"
                                min={minDate.toISOString().substring(0, 10)}
                                max={maxDate.toISOString().substring(0, 10)}
                                autoComplete="bday"
                                disabled={helpers.disableInputField}
                                placeholder={"DOB MM/DD/YYYY"}
                                onFocus={() =>
                                  setHelpers((prevState) => ({
                                    ...prevState,
                                    inputTypeShipping: "date",
                                  }))
                                }
                              />
                            </Item>
                          </Col>
                        </Row>
                        <Item
                          hidden={pickUp}
                          name="shippingStreetLine1"
                          rules={[
                            {
                              required: true,
                              message: "street address is required!",
                              validator: async (_, value) => {
                                if (!value || value.length) {
                                  return Promise.resolve(value);
                                } else {
                                  return Promise.reject();
                                }
                              },
                            },
                          ]}
                        >
                          <SmartyAutoComplete
                            disabled={helpers?.disableInputField}
                            customerStreetAddress1={
                              helpers.shippingStreetAddress
                            }
                            selectedData={shippingAddressChangeHandler}
                            onChangeValue={(value: string) =>
                              setHelpers((prevState) => ({
                                ...prevState,
                                shippingStreetAddress: value,
                              }))
                            }
                          />
                        </Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Item name="shippingStreetLine2" hidden={pickUp}>
                          <Input
                            placeholder="Address 2"
                            disabled={helpers.disableInputField}
                          />
                        </Item>
                      </Col>
                    </Row>
                    <Row gutter={17}>
                      <Col md={12} span={24}>
                        <Item
                          name="shippingCity"
                          rules={[
                            {
                              required: !pickUp,
                              message: "Please enter city",
                            },
                          ]}
                          hidden={pickUp}
                        >
                          <Input
                            placeholder="City"
                            disabled={helpers.disableInputField}
                          />
                        </Item>
                      </Col>
                      <Col md={12} span={24}>
                        <USStateSelector
                          name="shippingProvince"
                          rules={[
                            {
                              required: !pickUp,
                              message: "Please enter state",
                            },
                          ]}
                          hidden={pickUp}
                          selectProps={{
                            disabled: helpers.disableInputField,
                            onChange: (value) => {
                              if (value) {
                                getShippingTypes(value.toString());
                              }
                            },
                          }}
                        />
                      </Col>
                    </Row>
                    <Row gutter={17}>
                      {/* <Col md={12} span={24}>
                        <Item name="shippingCountryCode" hidden={pickUp}>
                          <Input
                            placeholder="Country"
                            readOnly
                            disabled={sameAddressChecked}
                          />
                        </Item>
                      </Col> */}
                      <Col md={12} span={24}>
                        <Item
                          hidden={pickUp}
                          name="shippingPostalCode"
                          rules={[
                            {
                              required: !pickUp,
                              message: "Please enter zip",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Zip"
                            disabled={helpers.disableInputField}
                            onChange={(e) => {
                              setIntermediateZipCode(e.target.value);
                            }}
                          />
                        </Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} span={24}>
                    <h1>
                      <BlockTitle>4 ) Pickup / Shipping Method</BlockTitle>
                    </h1>
                    <Item
                      name="defaultShipping"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Shipping/Pickup Details",
                        },
                      ]}
                    >
                      <Radio.Group
                        onChange={shippingRadioChange}
                        value={shipping}
                      >
                        <Radio value={true}>Shipping</Radio>
                        <Radio value={false}>Pickup</Radio>
                      </Radio.Group>
                    </Item>
                    {shipping && (
                      <Item
                        name="shippingMethod"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Shipping/Pickup Details",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select"
                          onChange={checkIfTWF}
                          allowClear
                          suffixIcon={
                            <Icon keyword="chevron-down" color="#7D8592" />
                          }
                          options={shippingmethods}
                          notFoundContent="Please add product(s) and/or address information to view the available shipping methods."
                        />
                      </Item>
                    )}
                    {!shipping && (
                      <Item
                        name="pickupLocation"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Shipping/Pickup Details",
                          },
                        ]}
                      >
                        <Select
                          suffixIcon={
                            <Icon keyword="chevron-down" color="#7D8592" />
                          }
                          placeholder="Select"
                          onChange={(e) => {
                            setPickM(e);
                          }}
                          allowClear
                          options={pickupLocations}
                          disabled={shipping}
                        ></Select>
                      </Item>
                    )}
                    {/*
                      shipM && (
                        <Item
                        name="csvShippingMethod"
                        rules={[
                          {
                            required: true,
                            message: "Please enter shipping method type",
                          },
                        ]}
                      >
                    <Select
                    suffixIcon={
                    <Icon keyword="chevron-down" color="#7D8592" />
                     }
                     placeholder="Select Method"
                     allowClear
                     options={twfShippingTypes}>
                    </Select>

                    </Item>
                      )
                    */}

                    <MarginBox mt={24}>
                      <BlockTitle> Special Instructions</BlockTitle>
                    </MarginBox>
                    <Item name="specialInstructions">
                      <TextArea
                        placeholder="Special Instructions"
                        rows={3}
                        style={{ minHeight: 86 }}
                      />
                    </Item>
                  </Col>
                </Row>
              </MarginBox>
            </Col>
            <Col xxl={6} lg={{ span: 18 }} span={24}>
              <div
                style={{
                  height: "100%",
                  backgroundColor: "#ffffff",
                  borderRadius: 12,
                  // padding: "23px 26px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    gridGap: 24,
                  }}
                >
                  <div key={shipCost}>
                    <BlockTitle>Discount Code</BlockTitle>
                    <DiscountApply
                      orderId={orderResult.id}
                      doDiscount={doDiscount}
                      makeDiscount={makeDiscount}
                    />
                    <MarginBox mt={30}>
                      <PriceTable>
                        <tbody>
                          {order?.lines?.map((line: any) => (
                            <tr key={line.id}>
                              <LineDetail>
                                <strong style={{ fontSize: "16px" }}>
                                  {line.quantity}
                                </strong>{" "}
                                x {line.productVariant?.product?.name}{" "}
                                {line.productVariant?.name}
                              </LineDetail>
                              <LineValue>
                                {currencyFormatter.format(line.linePrice / 100)}
                                <TwfButton
                                  shape="circle"
                                  type-twf="icon"
                                  onClick={() => deleteOrderLine(line.id)}
                                >
                                  <Icon keyword="delete" color="#FF4C61" />
                                </TwfButton>
                              </LineValue>
                            </tr>
                          ))}
                        </tbody>
                      </PriceTable>
                    </MarginBox>
                  </div>

                  {/* <PricingTable order={order} /> */}
                </div>
              </div>
            </Col>
            <Col lg={{ span: 18 }} md={{ span: 24 }} span={24} />
            <Col lg={{ span: 6 }} md={{ span: 24 }} span={24}>
              <TwfButton
                color-twf="primary"
                type="primary"
                size-twf="lg"
                type-twf="full"
                htmlType="submit"
                loading={showReviewButton}
              >
                Review
              </TwfButton>
            </Col>
          </Row>
        </Form>
      </TwfCard>
      <CreateOrderProducts
        productVisible={productVisible}
        setProductVisible={setProductVisible}
        activeOrderId={order?.id!}
        getShippingMethodsByState={getShippingMethodsByState}
      />
      <CreateOrderReview
        order={{ ...form.getFieldsValue(), ...order }}
        newOrder={true}
        loading={loading}
        visible={reviewVisible}
        setVisible={setReviewVisible}
        action={submitOrder}
      />
      <CreateConfirmation
        order={{ ...formData, ...order }}
        setVisible={setConfirmVisible}
        visible={confirmVisible}
      />
      {!breakpoints.md && <CreateMobileFooter />}
    </Container>
  );
};

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "16px",
          color: "#333",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          boxSizing: "border-box",
          "::placeholder": {
            color: "#aaa",
          },
        },
        invalid: {
          color: "#eb1c26",
        },
      },
    }),
    []
  );

  return options;
};

export default CreateNewOrder;

const CardItem = styled(Item)`
  border: 1px solid rgba(60, 66, 87, 0.12);
  height: 40px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
  border-radius: 14px;
  margin-bottom: 0;
  box-sizing: border-box;
  padding: 3px 10px;
`;

const CardNumberItem = styled(CardItem)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
`;

const CardExpiryItem = styled(CardItem)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const CardCvcItem = styled(CardItem)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
`;
