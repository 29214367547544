import { ApolloClient, ApolloLink, from, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const default_token = "__default_channel__";
const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token") || null;
  const vendureToken = localStorage.getItem("vendure-token");
  const vendureTokenSegment = vendureToken
    ? { "vendure-token": vendureToken }
    : { "vendure-token": default_token };

  const authorizationHeader = token
    ? `Bearer ${token}`
    : `Bearer ${default_token}`;
  operation.setContext({
    headers: {
      authorization: authorizationHeader,
      ...vendureTokenSegment,
    },
  });
  return forward(operation);
});

const afterLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const refreshToken = headers.get("vendure-auth-token");
      if (refreshToken) {
        localStorage.setItem("token", refreshToken);
      }
    }

    return response;
  });
});

const client = new ApolloClient({
  link: from([middlewareAuthLink, afterLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
