export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const loginAction = (data: any) => (dispatch: any) => {
  dispatch({
    type: LOGIN,
    data: { ...data },
  });
};

export const logoutAction = (data: any) => (dispatch: any) => {
  dispatch({
    type: LOGOUT,
    data: { ...data },
  });
};
