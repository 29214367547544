import client from "api/graphql/connect";
import {
  ADD_OPTION_GROUP_TO_PRODUCT,
  ADD_OPTION_TO_GROUP,
  CREATE_PRODUCT_OPTION_GROUP,
  CREATE_PRODUCT_VARIANTS,
  UDPATE_OPTION_TO_GROUP,
  UPDATE_PRODUCT_OPTION_GROUP,
} from "graphql/mutations.graphql";
import { OptionGroup, ProductOption, Variant } from "../variants/variants";
import { LanguageCode, SortOrder } from "generated/graphql";
import { GET_PRODUCT_LIST } from "graphql/queries.graphql";
import { OptionValue } from "../variants";
import { CreateProductInput } from "./../create/index";

export const createProductOptionGroup = (
  optionGroups: OptionGroup[]
): Promise<string[]> => {
  return new Promise((resolve) => {
    Promise.all(createProductOptionGroupAll(optionGroups).map(mapper)).then(
      (response) => {
        const ids = response.map((d: any) => d.data.id);
        // message.success("Option groups created");
        resolve(ids);
      }
    );
  });
};

export const updateProductOptionGroup = (
  optionGroups: OptionGroup[]
): Promise<string[]> => {
  return new Promise((resolve) => {
    Promise.all(updateProductOptionGroupAll(optionGroups).map(mapper)).then(
      (response) => {
        const ids = response.map((d: any) => d.data.id);
        // message.success("Option groups created");
        resolve(ids);
      }
    );
  });
};

const createProductOptionGroupAll = (optionGroups: OptionGroup[]) =>
  optionGroups.map((optionGroup) => ({
    //TODO: remove all but fn & data
    option: optionGroup,
    fn: () => createProductOptionGroupSingle(optionGroup),
    status: null,
    data: null,
  }));

  const updateProductOptionGroupAll = (optionGroups: OptionGroup[]) =>
  optionGroups.map((optionGroup) => ({
    //TODO: remove all but fn & data
    option: optionGroup,
    fn: () => updateProductOptionGroupSingle(optionGroup),
    status: null,
    data: null,
  }));

const createProductOptionGroupSingle = (optionGroup: OptionGroup) => {
  return new Promise(async (resolve) => {
    client
      .mutate({
        mutation: CREATE_PRODUCT_OPTION_GROUP,
        variables: {
          input: {
            code: optionGroup.name.toLowerCase().replaceAll(" ", "-"),
            options: optionGroup.options.map(
              (productOption: ProductOption) => ({
                code: productOption.name,
                translations: [
                  {
                    languageCode: LanguageCode.En,
                    name: productOption.name,
                  },
                ],
              })
            ),
            translations: [
              { languageCode: LanguageCode.En, name: optionGroup.name },
            ],
          },
        },
        refetchQueries:[{
          query: GET_PRODUCT_LIST,
          variables: {
            options: {
              filter: { productType: { eq: 1 } },
              skip: 0,
              take: 10,
              sort: { id: SortOrder.Desc },
            },
          },
        }]
      })
      .then((response: any) => {
        resolve(response.data?.createProductOptionGroup);
      });
  });
};

const updateProductOptionGroupSingle = (optionGroup: OptionGroup) => {
  console.log(optionGroup);
  return new Promise(async (resolve) => {
    client
      .mutate({
        mutation: UPDATE_PRODUCT_OPTION_GROUP,
        variables: {
          input: {
            id: optionGroup.id,
            code: optionGroup.name.toLowerCase().replaceAll(" ", "-"),
            options: optionGroup.options.map(
              (productOption: ProductOption) => ({
                id: productOption.id,
                code: productOption.name,
                translations: [
                  {
                    languageCode: LanguageCode.En,
                    name: productOption.name,
                  },
                ],
              })
            ),
            translations: [
              { languageCode: LanguageCode.En, name: optionGroup.name },
            ],
          },
        },
        refetchQueries:[{
          query: GET_PRODUCT_LIST,
          variables: {
            options: {
              filter: { productType: { eq: 1 } },
              skip: 0,
              take: 10,
              sort: { id: SortOrder.Desc },
            },
          },
        }]
      })
      .then((response: any) => {
        resolve(response.data?.updateProductOptionGroup);
      });
  });
};

export const addOptionGroupToProduct = (ids: string[], productId: string) => {
  return new Promise((resolve) => {
    Promise.all(addOptionGroupToProductAll(ids, productId).map(mapper)).then(
      (response) => {
        // message.success("Option groups added to product");
        resolve(response);
      }
    );
  });
};

const addOptionGroupToProductAll = (groupIds: string[], productId: string) => {
  return groupIds.map((id) => ({
    id,
    fn: () => addOptionGroupToProductSingle(id, productId),
    status: null,
    data: null,
  }));
};

const addOptionGroupToProductSingle = (id: string, productId: string) => {
  return new Promise(async (resolve) => {
    client
      .mutate({
        mutation: ADD_OPTION_GROUP_TO_PRODUCT,
        variables: {
          productId,
          optionGroupId: id,
        },
        refetchQueries:[{
          query: GET_PRODUCT_LIST,
          variables: {
            options: {
              filter: { productType: { eq: 1 } },
              skip: 0,
              take: 10,
              sort: { id: SortOrder.Desc },
            },
          },
        }]
      })
      .then(({ data }) => {
        resolve(
          data.addOptionGroupToProduct.optionGroups.map((og: any) => og.options)
        );
      });
  });
};

export const createProductVariants = (
  data: any,
  variants: Variant[],
  productId: string,
  product: CreateProductInput,
  assetIds: any
) => {
  return new Promise((resolve, reject) => {
    const selectedVariants = variants.filter((v) => v.selected);
    const productOptions = data.reduce((acc: any, d: any) => {
      acc.push(
        ...d.data.reduce((acc2: any, e: any) => {
          acc2.push(...e);
          return acc2;
        }, [])
      );
      return acc;
    }, []);
    return client
      .mutate({
        mutation: CREATE_PRODUCT_VARIANTS,
        variables: {
          input: selectedVariants.map((variant: Variant) => ({
            productId,
            sku: variant.sku,
            price: +(variant.price * 100).toFixed(2),
            stockOnHand: variant.stock,
            assetIds: assetIds,
            optionIds: removeDuplicates(
              productOptions
                .filter((p: any) =>
                  variant.options.map((option) => option.name).includes(p.code)
                )
                .map((p: any) => p.id)
            ),
            translations: [
              {
                languageCode: LanguageCode.En,
                name: variant.options.map((option) => option.name).join(" "),
              },
            ],
            customFields: {
              saleChannel: product.saleChannel,
              wineCategory: product.wineCategory,
            },
          })),
        },
        refetchQueries: [
          // the only way it works
          {
            query: GET_PRODUCT_LIST,
            variables: {
              options: {
                filter: { productType: { eq: 1 } },
                skip: 0,
                take: 10,
                sort: { id: SortOrder.Desc },
              },
            },
          },
        ],
      })
      .then(() => resolve(true))
      .catch((error: any) => reject(error));
  });
};

export const createProductVariantsForUpdate = (
  createdOptionGroups: any,
  variants: Variant[],
  productId: string
) => {
  return new Promise((resolve, reject) => {
    const variables = {
      input: variants.map((variant: Variant) => ({
        productId,
        sku: variant.sku,
        price: +(variant.price * 100).toFixed(2),
        stockOnHand: variant.stock,
        optionIds: getOptionIds(createdOptionGroups, variant.options),
        translations: [
          {
            languageCode: LanguageCode.En,
            name: variant.options.map((option) => option.name).join(" "),
          },
        ],
      })),
    };
    return client
      .mutate({
        mutation: CREATE_PRODUCT_VARIANTS,
        variables,
        refetchQueries: ["GetProductVariantOptions"],
      })
      .then(() => resolve(true))
      .catch((error: any) => reject(error));
  });
};

const getOptionIds = (
  createdOptionGroups: any,
  variantOptions: ProductOption[]
) => {
  const optionIds: any[] = [];

  const productOptions = createdOptionGroups.reduce((acc: any, d: any) => {
    acc.push(d.data);
    return acc;
  }, []);

  optionIds.push(
    ...productOptions
      .filter((p: any) =>
        variantOptions.map((option) => option.name).includes(p.name)
      )
      .map((p: any) => p.id)
  );
  optionIds.push(...variantOptions.map((option) => option.id));
  return removeDuplicates(optionIds);
};

export const addOptionsToGroups = (optionValues: OptionValue[]) => {
  return new Promise((resolve) => {
    Promise.all(addOptionsToGroupsAll(optionValues).map(mapper)).then(
      (response) => resolve(response)
    );
  });
};

export const updateOptionsToGroups = (optionValues: OptionValue[]) => {
  return new Promise((resolve) => {
    Promise.all(updateOptionsToGroupsAll(optionValues).map(mapper)).then(
      (response) => resolve(response)
    );
  });
};

const addOptionsToGroupsAll = (optionValues: OptionValue[]) => {
  return optionValues.map((optionValue) => ({
    fn: () => addOptionToGroupSingle(optionValue),
    data: null,
  }));
};

const updateOptionsToGroupsAll = (optionValues: OptionValue[]) => {
  return optionValues.map((optionValue) => ({
    fn: () => updateOptionToGroupSingle(optionValue),
    data: null,
  }));
};

const addOptionToGroupSingle = (optionValue: OptionValue) => {
  return new Promise(async (resolve) => {
    client
      .mutate({
        mutation: ADD_OPTION_TO_GROUP,
        variables: {
          input: {
            productOptionGroupId: optionValue.productOptionGroupId,
            code: optionValue.name.toLowerCase().replaceAll(" ", "-"),
            translations: [
              { languageCode: LanguageCode.En, name: optionValue.name },
            ],
          },
        },
      })
      .then(({ data }) => {
        resolve(data.createProductOption);
      });
  });
};

const updateOptionToGroupSingle = (optionValue: OptionValue) => {
  return new Promise(async (resolve) => {
    client
      .mutate({
        mutation: UDPATE_OPTION_TO_GROUP,
        variables: {
          input: {
            id: optionValue.id,
            productOptionGroupId: optionValue.productOptionGroupId,
            code: optionValue.name.toLowerCase().replaceAll(" ", "-"),
            translations: [
              { languageCode: LanguageCode.En, name: optionValue.name },
            ],
          },
        },
      })
      .then(({ data }) => {
        resolve(data.updateProductOption);
      });
  });
};

const mapper = (item: any) => {
  return new Promise(async (resolve) => {
    const call = async () => {
      try {
        const data = await item.fn();
        resolve({ ...item, status: "OK", data });
      } catch (err) {
        resolve({ ...item, status: "ERROR", data: null });
      }
    };
    call();
  });
};

const removeDuplicates = (array: any[]) => {
  return array
    .filter(function (elem: any, index: any, self: any) {
      return index === self.indexOf(elem);
    })
    .filter((item) => item !== undefined);
};
