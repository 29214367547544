import { FunctionComponent, useEffect, useState } from "react";
import { Row, Col, Select } from "antd";
import TwfButton from "component/button";
import { OperationCard, OperationProps } from "../conditions";
import { useGetClubListQuery } from "generated/graphql";
import { SelectTitle } from "component/header";
import Icon from "assets/icons";

const CustomerClubCondition: FunctionComponent<OperationProps> = ({
  code,
  configArgs,
  onConfigArgChange,
  onRemove,
}) => {
  const { data: clubsResult } = useGetClubListQuery();

  const [value, setValue] = useState<string|undefined>();
  const [initialValue, setInitialValue] = useState<string|undefined>();
  const [key, setKey] = useState(0);

  useEffect(()=>{
    setInitialValue(clubsResult?.clubs.items.find((club)=>club.id === configArgs.find((arg) => arg.name === "clubId")?.value)?.id);
  },[clubsResult, configArgs])

  const changeValue = (val: string) =>{
    setValue(val);
    setKey(Math.floor(Math.random()*10000));
    onConfigArgChange(
      { name: "clubId", value: val.toString(), },
      code
    );
  }

  return (
    <OperationCard
      key={key}
      title="Customer is a member of the specified club"
      style={{ marginTop: 16 }}
      actions={[
        <TwfButton key="remove" type="link" onClick={() => onRemove(code)}>
          Remove
        </TwfButton>,
      ]}
    >
      <Row>
        <Col span={24}>
          <SelectTitle>Club</SelectTitle>
          <Select
            key={key}
            suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
            style={{ width: "100%" }}
            options={clubsResult?.clubs.items.map((club) => ({
              label: club.name,
              value: club.id,
            }))}
            defaultActiveFirstOption={true}
            value={key===0 ? initialValue : value}
            onChange={(value: string) => {
              changeValue(value);
            }}
          ></Select>
        </Col>
      </Row>
    </OperationCard>
  );
};

export default CustomerClubCondition;
