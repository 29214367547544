import { FunctionComponent } from "react";
import { Row, Col, Select } from "antd";
import TwfButton from "component/button";
import { OperationCard, OperationProps } from "../conditions";
import { useGetCustomerGroupsQuery } from "generated/graphql";
import { SelectTitle } from "component/header";
import Icon from "assets/icons";

const CustomerGroupCondition: FunctionComponent<OperationProps> = ({
  code,
  configArgs,
  onConfigArgChange,
  onRemove,
}) => {
  const { data: groupsResult } = useGetCustomerGroupsQuery();

  return (
    <OperationCard
      title="Customer is a member of the specified group"
      style={{ marginTop: 16 }}
      actions={[
        <TwfButton key="remove" type="link" onClick={() => onRemove(code)}>
          Remove
        </TwfButton>,
      ]}
    >
      <Row>
        <Col span={24}>
          <SelectTitle>Customer group</SelectTitle>
          <Select
            suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
            style={{ width: "100%" }}
            options={groupsResult?.customerGroups.items.map((group) => ({
              label: group.name,
              value: group.id,
            }))}
            defaultValue={JSON.parse(
              configArgs.find((arg) => arg.name === "customerGroupId")?.value ||
                "0"
            )}
            onChange={(value: number) => {
              onConfigArgChange(
                { name: "customerGroupId", value: value.toString() },
                code
              );
            }}
          ></Select>
        </Col>
      </Row>
    </OperationCard>
  );
};

export default CustomerGroupCondition;
