import {
  Checkbox,
  Col,
  message,
  Popconfirm,
  Row,
  Table,
  TablePaginationConfig,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Container from "component/container";
import {
  ComplianceListItemFragment,
  DeletionResult,
  SortOrder,
  UpdateComplianceInput,
  useDeleteComplianceMutation,
  useGetComplianceListLazyQuery,
  useUpdateComplianceMutation,
} from "generated/graphql";
import React, { FC, useEffect, useReducer, useState } from "react";
import Icon from "../../assets/icons";
import TwfButton from "../../component/button";
import { TwfCard } from "../../component/card";
import { PageTitle } from "../../component/header";
import { MarginBox } from "../../component/margin";
import CreateCompliance from "./components/create";
import EditCompliance from "./components/edit";
import StateSearch from "./components/stateSearch";

interface TableSort {
  [id: string]: SortOrder;
}

interface TableOptions {
  skip: number;
  take: number;
  sort?: TableSort;
}

type Action =
  | { type: "state"; value: string }
  | { type: "tableChange"; skip: number; take: number };

interface PageProps {}

const tableInitialState: TableOptions = {
  skip: 0,
  take: 10,
  sort: {},
};

const CompliancePage: FC<PageProps> = () => {
  const breakpoints = useBreakpoint();
  const [getComplianceListQuery, { data, loading }] =
    useGetComplianceListLazyQuery();
  const [deleteComplianceMutation] = useDeleteComplianceMutation();

  const [updateComplianceMutation] = useUpdateComplianceMutation();

  const [selectedComplianceId, setSelectedComplianceId] = useState<string>();
  const [createVisible, setCreateVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [filterStateId, setFilterStateId] = useState("");
  const [tableOptions, setTableOptions] = useReducer(
    reducer,
    tableInitialState
  );

  let mainAdmin = false;

  if (localStorage) {
    if (localStorage.getItem("used-channel") === "__default_channel__") {
      mainAdmin = true;
    }
  }

  useEffect(() => {
    const fid = filterStateId;
    const variables = {
      options: tableOptions,
      ...(fid.length !== 0 ? { stateId: fid } : {}),
    };
    getComplianceListQuery({
      variables,
      fetchPolicy: "no-cache",
    });
  }, [tableOptions, filterStateId, getComplianceListQuery]);

  const editClickHandler = (id: string) => {
    setSelectedComplianceId(id);
    setEditVisible(true);
  };

  const deleteClickHandler = (id: string) => {
    deleteComplianceMutation({
      variables: { id },
      refetchQueries: ["GetComplianceList"],
    })
      .then(({ data }) => {
        if (data?.deleteCompliance.result === DeletionResult.Deleted)
          message.success("Compliance deleted");
      })
      .catch((error) => message.error(error.message));
  };

  function reducer(state: TableOptions, action: Action): TableOptions {
    switch (action.type) {
      case "tableChange":
        return {
          ...state,
          skip: action.skip,
          take: action.take,
        };
      default:
        return state;
    }
  }

  const tableChangeHandler = (pagination: TablePaginationConfig) => {
    setTableOptions({
      type: "tableChange",
      skip: (pagination.pageSize || 10) * ((pagination.current || 1) - 1),
      take: pagination.pageSize || 10,
    });
  };

  const updateCompliance = (formData: UpdateComplianceInput): void => {
    updateComplianceMutation({
      variables: { input: { ...formData, id: formData.id } },
      refetchQueries: ["GetComplianceList"],
    })
      .then(({ data }) => {
        if (data?.updateCompliance.__typename === "Compliance") {
          message.success("Compliance updated");
        }
      })
      .catch((error) => message.error(error.message));
  };

  const checkboxchange = (event: any, id: any) => {
    let status = 0;
    if (event.target.checked === true) {
      status = 1;
    }
    const formData = {
      id,
      status,
    };
    updateCompliance(formData);
  };

  let counter = 0;
  const columns: any[] = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      render: () => {
        counter += 1;
        return counter.toString();
      },
    },
    {
      title: "Status",
      align: "left",
      render: (record: ComplianceListItemFragment) => {
        return (
          <Checkbox
            disabled={!mainAdmin}
            checked={record.status === 1}
            onChange={(e) => {
              checkboxchange(e, record.id);
            }}
          ></Checkbox>
        );
      },
    },
    {
      title: "State",
      sortDirections: ["descend", "ascend"],
      dataIndex: ["state", "name"],
      align: "left",
      sorter: (a: any, b: any) => a.state.name.localeCompare(b.state.name),
    },
    {
      title: "Rules & Regulations",
      dataIndex: "rules",
      align: "left",
      width: 500,
      render: (rules: string) => (
        <div
          dangerouslySetInnerHTML={{
            __html: rules,
          }}
        ></div>
      ),
    },
    {
      title: "Notes",
      dataIndex: "note",
      align: "left",
      render: (notes: string) => (
        <div
          dangerouslySetInnerHTML={{
            __html: notes,
          }}
        ></div>
      ),
    },
    {
      title: "",
      align: "left",
      width: breakpoints.md ? 120 : 60,
      fixed: "right",
      render: (record: ComplianceListItemFragment) => {
        return (
          <Row gutter={24} justify="center">
            <Col>
              <TwfButton
                shape="circle"
                type-twf="icon"
                onClick={() => editClickHandler(record.id)}
              >
                <Icon keyword="edit" color="#3C64B1" />
              </TwfButton>
            </Col>
            <Col>
              {mainAdmin && (
                <Popconfirm
                  title="Are you sure to delete this compliance?"
                  onConfirm={() => deleteClickHandler(record.id)}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <TwfButton shape="circle" type-twf="icon">
                    <Icon keyword="delete" color="#FF4C61" />
                  </TwfButton>
                </Popconfirm>
              )}
            </Col>
          </Row>
        );
      },
    },
  ];

  const changeid = (obj: any) => {
    return obj;
  };

  return (
    <div>
      <Row
        gutter={[30, 20]}
        justify="space-between"
        wrap={breakpoints.sm ? false : true}
        align="middle"
      >
        <Col>
          <Row align="middle" gutter={[30, 20]}>
            <Col>
              <PageTitle>Compliance</PageTitle>
            </Col>
            {breakpoints.md && (
              <Col>
                <StateSearch onStateIdChange={setFilterStateId} />
              </Col>
            )}
          </Row>
        </Col>

        {mainAdmin && (
          <Col>
            <Row
              gutter={[24, 20]}
              justify={breakpoints.md ? "end" : "space-between"}
            >
              {/*<Col>
                <TwfButton
                  type="primary"
                  color-twf="primary"
                  size-twf="md"
                  type-twf="wide"
                  onClick={setDefaultCompliance}
                >
                  Create Default Compliances
                </TwfButton>
                </Col>*/}

              <Col>
                <TwfButton
                  type="primary"
                  color-twf="primary"
                  size-twf="md"
                  type-twf="wide"
                  onClick={() => {
                    setCreateVisible(true);
                  }}
                >
                  Create
                </TwfButton>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <MarginBox mt={32}>
        <Container minWidth={breakpoints.sm ? "max-content" : "100%"}>
          <TwfCard>
            <Table
              loading={loading}
              columns={columns}
              dataSource={data?.compliances.items
                .filter(changeid)
                .map((obj) => ({
                  ...obj,
                  key: obj.id,
                }))}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} Items`,
                pageSize: tableOptions.take,
                current: tableOptions.skip / tableOptions.take + 1,
                total: data?.compliances.totalItems,
                position: ["bottomCenter"],
                showSizeChanger: true,
              }}
              onChange={tableChangeHandler}
              style={{ maxWidth: "max-content", minHeight: 300 }}
              scroll={{ x: "max-content" }}
            />
          </TwfCard>
        </Container>

        <CreateCompliance
          visible={createVisible}
          setVisible={setCreateVisible}
        />
        <EditCompliance
          visible={editVisible}
          setVisible={setEditVisible}
          currentId={selectedComplianceId}
          setCurrentId={setSelectedComplianceId}
        />
      </MarginBox>
    </div>
  );
};

export default CompliancePage;
