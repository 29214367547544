/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Col, Grid, message, Row, Tabs } from "antd";
import { PageTitle } from "../../../component/header";
import TwfButton from "../../../component/button";
import { MarginBox } from "../../../component/margin";
import ProductSingleGeneral from "./general";
import ProductSingleSkuPricing from "./sku";
import ProductSingleWineAttribute from "./wine-attribute";
import { useNavigate, useParams } from "react-router-dom";
import {
  LanguageCode,
  useEditProductToComplianceMutation,
  useGetActiveChannelQuery,
  useGetProductVariantQuery,
  useGetProductWithVariantsQuery,
  useUpdateProductMutation,
  useUpdateProductOptionMutation,
  useVarietalsQuery,
} from "generated/graphql";
import { useUpdateProductVariantsMutation } from "./../../../generated/graphql";
import ProductBasics from "./basic";
import { Variant } from "../variants/variants";

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

interface UpdateProductInput {
  name: string;
  productType?: number;
  saleChannel?: [];
  productUrl?: string;
  status?: number;
  bottlingDate?: Date;
  harvestDate?: Date;
  wineCategory?: number;
  varietal?: string;
  appellation?: string;
  wineType?: string;
  label?: string;
}

const SingleProductAttributes = () => {
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const cid: string = id as string;
  const breakpoints = useBreakpoint();
  const { data } = useGetProductVariantQuery({ variables: { id: cid } });
  const pid = data?.productVariant?.productId;
  const [updateProductVariantsMutation, { loading: VariantLoading }] =
    useUpdateProductVariantsMutation();
  const [updateProductMutation, { loading: DetailLoading }] =
    useUpdateProductMutation();
  const [updateProductOptionMutation, { loading: OptionLoading }] =
    useUpdateProductOptionMutation();

  const activeChannel = useGetActiveChannelQuery();
  let brandkey: string | undefined;

  if (activeChannel) {
    brandkey =
      activeChannel?.data?.activeChannel?.customFields?.complianceBrandKey;
  }

  const { data: getProductResult } = useGetProductWithVariantsQuery({
    variables: { id: pid! },
    skip: !pid,
    fetchPolicy: "network-only",
  });

  const { data: channelVarietals } = useVarietalsQuery({
    variables: {
      options: {},
    },
  });

  const [varietals, setVarietals] = useState<any[]>([]);

  useEffect(() => {
    if (
      channelVarietals?.varietals?.items &&
      channelVarietals.varietals.items.length !== 0
    ) {
      const ourWineVarietals = channelVarietals.varietals.items;
      const varietalsDropdown = [] as any;
      ourWineVarietals.forEach((varietal) => {
        const varietalObject = {
          label: varietal.varietal,
          value: varietal.varietal,
        } as any;
        varietalsDropdown.push(varietalObject);
      });
      setVarietals(varietalsDropdown);
    }
  }, [channelVarietals]);

  const [editProductToComplianceMutation, { loading: ComplianceLoading }] =
    useEditProductToComplianceMutation();

  const [productFormData, setProductFormData] = useState({
    productType: undefined,
    saleChannel: undefined,
    name: undefined,
    productUrl: undefined,
    bottlingDate: undefined,
    harvestDate: undefined,
    wineCategory: undefined,
    varietal: undefined,
    appellation: undefined,
    wineType: undefined,
    label: undefined,
    status: undefined,
    bottleSize: undefined,
    vintage: undefined,
    isOutOfStock: undefined,
    outOfStockMsg: undefined,
    shipComplianceVerified: undefined,
  });

  const [formData, setFormData] = useState({
    price:undefined,
    customFields: {
      acidLevel: undefined,
      aging: undefined,
      alcohol: undefined,
      appellation: undefined,
      bottleInCase: undefined,
      bottlingDate: undefined,
      description: undefined,
      endDate: undefined,
      exemptions: undefined,
      foodPairing: undefined,
      harvestDate: undefined,
      otherNotes: undefined,
      phLevel: undefined,
      preferredPrice: undefined,
      productBadgeId: undefined,
      region: undefined,
      residualSugarLevel: undefined,
      retailPrice: undefined,
      saleChannel: undefined,
      startDate: undefined,
      formData: undefined,
      sugarLevel: undefined,
      teaser: undefined,
      testingNotes: undefined,
      unit: undefined,
      upcCode: undefined,
      varietal: undefined,
      vineyardDestination: undefined,
      vineyardNotes: undefined,
      weight: undefined,
      wineCategory: undefined,
      wineType: undefined,
      isOutOfStock: undefined,
      outOfStockMsg: undefined,
      shipComplianceVerified: undefined,
    },
  });

  const variantChangeHandler = (state: any) => {
  
    setFormData({
      ...formData,
      ...state,
      customFields: { ...formData.customFields, ...state.customFields },
    });
  };

  const productChangeHandler = (state: any) => {
    setProductFormData({
      ...productFormData,
      ...state,
    });
  };

  const loopcompliance = async (
    formData: UpdateProductInput,
    variants: Variant[]
  ) => {
    /*const addtocomp = await Promise.all(
      variants.map(async (variant) => await edittocompliance(formData, variant))
    );*/
    const addtocomp: string[] = [];
    for (const [_, variant] of variants.entries()) {
      addtocomp.push(variant.sku);
    }
    return addtocomp;
  };

  const edittocompliance = (
    myformData: UpdateProductInput,
    variant: Variant
  ): Promise<boolean | string> => {
    return new Promise(async (resolve) => {
      const forml =
        (formData as any)?.translations?.at(0)?.name?.split(" ") ||
        variant.name.split(" ");
      const ml = forml[forml.indexOf("ML") - 1];
      const prodcompliance = {
        BottleSizeML: Number(ml),
        BrandKey: brandkey as string,
        Description:
          (formData as any)?.translations?.at(0)?.name || variant.name,
        ProductType: "Wine",
        UnitPrice: variant.price || variant.name,
        Varietal: myformData.varietal
          ? varietals.filter((a) => a.value === myformData.varietal)[0].label
          : varietals.filter(
              (a) =>
                a.value === getProductResult?.product?.customFields?.varietal
            )[0].label,
        Vintage: 2010,
        VolumeAmount: Number(ml),
        VolumeUnit: "Milliliter",
        ContainerType: "Bottle",
      } as any;
      return editProductToComplianceMutation({
        variables: {
          //allow SKU edit or no?
          productKey: variant.sku,
          input: prodcompliance,
        },
      })
        .then(() => {
          resolve(variant.sku);
        })
        .catch(() => {
          resolve(false);
        });
    });
  };

  const complianceEditHandler = (value: UpdateProductInput) => {
    const variants = getProductResult?.product?.variants;
    if (variants) {
      loopcompliance(value, variants as any)
        .then(() => {
          message.success("Product updated");
        })
        .catch((e) => {
          message.error(e);
        });
    }
  };
//Update Product Details
//OverWite Price with custom price


  const updateProductDetails = () => {
    updateProductMutation({
      variables: {
        input: {
          id: pid!,
          translations: [
            {
              name: productFormData.name,
              slug: "",
              description: "",
              languageCode: LanguageCode.En,
            },
          ],
          customFields: {
            productType: productFormData?.productType,
            saleChannel: productFormData?.saleChannel,
            productUrl: productFormData?.productUrl,
            status: productFormData?.status,
            bottlingDate: productFormData?.bottlingDate,
            harvestDate: productFormData?.harvestDate,
            wineCategory: productFormData?.wineCategory,
            varietal: productFormData?.varietal,
            appellation: productFormData?.appellation,
            wineType: productFormData?.wineType,
            label: productFormData?.label,
          },
        },
      },
      refetchQueries: ["GetProductVariant", "GetProductWithVariants"],
    })
      .then(({ data }) => {
        if (data?.updateProduct) {
          //if (productFormData.bottleSize || productFormData.vintage) {
            updateOptions();
          /*} else {
            updateProductVariants();
          }*/
        }
      })
      .catch((response) => message.error(response.message));
  };

  const updateOptions = async() =>{
    const bottleVariables = {} as any;
    const vintageVariables = {} as any;
    const finalVariables = [] as any;
    const code0 = data!.productVariant!.options!.at(0)!.code;
    const code1 = data!.productVariant!.options!.at(1)!.code;

    const isNum0 = isNaN(Number(code0));
    const isNum1 = isNaN(Number(code1));
    const id0 = data!.productVariant!.options!.at(0)!.id;
    const id1 = data!.productVariant!.options!.at(1)!.id;
    let bottleSizeId = data!.productVariant!.options!.at(0)!.id;
    let vintageId = data!.productVariant!.options!.at(1)!.id;

    let vintage = !isNum0 ? code0:code1;
    let bottleSize = isNum0 ? code0:code1;

    console.log(vintage, bottleSize);

    if(!isNum0){
      vintageId = id0;
      bottleSizeId = id1;
      vintage = code0;
      bottleSize = code1;
    }

    if(!isNum1){
      vintageId = id1;
      bottleSizeId = id0;
      vintage = code1;
      bottleSize = code0;
    }

    if(productFormData.bottleSize){
      bottleVariables["id"] = bottleSizeId;
      bottleVariables["code"] = productFormData.bottleSize;
      finalVariables.push(bottleVariables);
      bottleSize = productFormData.bottleSize;
    }

    if(productFormData.vintage){
      vintageVariables["id"] = vintageId;
      vintageVariables["code"] = productFormData.vintage;
      finalVariables.push(vintageVariables);
      vintage = productFormData.vintage;
    }

    const updateOptions = await Promise.all(
      finalVariables.map((async (variables: any) => await finalUpdateOptions(variables)))
    )

    const isAllDone = updateOptions.every((value)=>value===true);
    if(isAllDone){
      if(!bottleSize.includes("ML") && bottleSize.includes("L")){
        const characters = bottleSize.split("");
        characters.pop();
        const value = characters.join("").replaceAll(",","");
        bottleSize = value+" L";
      }
      const name = bottleSize+" "+vintage;
      updateProductVariants(name);
    }
  }

  const finalUpdateOptions = (variables: any) =>{
    return new Promise((resolve,reject)=>{
      updateProductOptionMutation({
        variables: {
          input: variables
        }
      }).then(({ data }) => {
        /*if(data?.updateProductOption){
          updateProductVariants();
        }*/
        if(data?.updateProductOption){
          resolve(true);
        }
      }).catch((response) => {
        reject(false);
      });
    })
  }

  const updateProductVariants = (name?: string) => {
    if(name && (formData as any)?.translations && (formData as any)?.translations?.at(0) ){
      (formData as any).translations.at(0).name = name;
    }

   
   console.log('FORM DATA',  formData)
   
  


    updateProductVariantsMutation({
      variables: {
        input: {
          id: cid,
          ...formData,
          customFields: {
            acidLevel: formData.customFields?.acidLevel,
            aging: formData.customFields?.aging,
            alcohol: formData.customFields?.alcohol,
            appellation: formData.customFields?.appellation,
            bottleInCase: formData.customFields?.bottleInCase,
            bottlingDate: formData.customFields?.bottlingDate,
            description: formData.customFields?.description,
            endDate: formData.customFields?.endDate,
            exemptions: formData.customFields?.exemptions,
            foodPairing: formData.customFields?.foodPairing,
            harvestDate: formData.customFields?.harvestDate,
            otherNotes: formData.customFields?.otherNotes,
            phLevel: formData.customFields?.phLevel,
            preferredPrice: formData.customFields?.preferredPrice,
            productBadgeId: formData.customFields?.productBadgeId,
            region: formData.customFields?.region,
            residualSugarLevel: formData.customFields?.residualSugarLevel,
            retailPrice: formData.customFields?.retailPrice,
            saleChannel: formData.customFields?.saleChannel,
            startDate: formData.customFields?.startDate,
            sugarLevel: formData.customFields?.sugarLevel,
            teaser: formData.customFields?.teaser,
            testingNotes: formData.customFields?.testingNotes,
            unit: formData.customFields?.unit,
            upcCode: formData.customFields?.upcCode,
            varietal: formData.customFields?.varietal,
            vineyardDestination: formData.customFields?.vineyardDestination,
            vineyardNotes: formData.customFields?.vineyardNotes,
            weight: formData.customFields?.weight,
            wineCategory: formData.customFields?.wineCategory,
            wineType: formData.customFields?.wineType,
            isOutOfStock: productFormData?.isOutOfStock,
            outOfStockMsg: productFormData?.outOfStockMsg,
            shipComplianceVerified: productFormData?.shipComplianceVerified,
          },
        },
      },
      refetchQueries: ["GetProductVariant", "GetProductWithVariants"],
    })
      .then(({ data }) => {
        if (data?.updateProductVariants) {
          complianceEditHandler(productFormData as any);
        }
      })
      .catch((response) => message.error(response.message));
  };

  return (
    <div>
      <Row justify="space-between" gutter={[30, 30]}>
        <Col>
          <PageTitle>{data?.productVariant?.product?.name}</PageTitle>
        </Col>
        {breakpoints.md && (
          <Col>
            <Row gutter={24} align="middle">
              {/*
              <Col>
                <TwfButton shape="circle" type-twf="icon" onClick={() => {}}>
                  <Icon keyword="duplicate" width={30} color="#3C64B1" />
                </TwfButton>
              </Col>
              <Col>
                <TwfButton shape="circle" type-twf="icon" onClick={() => {}}>
                  <Icon keyword="content-view" width={30} color="#3C64B1" />
                </TwfButton>
              </Col>
              */}
              <Col>
                <TwfButton
                  color-twf="gray-line"
                  size-twf="lg"
                  type-twf="wide"
                  onClick={() => history(-1)}
                >
                  Cancel
                </TwfButton>
              </Col>
              <Col>
                <TwfButton
                  color-twf="primary"
                  type="primary"
                  size-twf="lg"
                  type-twf="wide"
                  onClick={updateProductDetails}
                  loading={
                    VariantLoading ||
                    DetailLoading ||
                    OptionLoading ||
                    ComplianceLoading
                  }
                >
                  Save
                </TwfButton>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <MarginBox mt={10}>
        <Tabs>
          <TabPane key="general" tab="General">
            {data && (
              <ProductBasics
                variant={data}
                onChange={productChangeHandler}
                getProductResult={getProductResult}
                brandKey={brandkey}
              />
            )}
          </TabPane>
          <TabPane key="description" tab="Description">
            {data && (
              <ProductSingleGeneral
                variant={data}
                onChange={variantChangeHandler}
                onProductChange={productChangeHandler}
              />
            )}
          </TabPane>
          <TabPane key="sku-pricing" tab="SKU & Pricing">
            {data && (
              <ProductSingleSkuPricing
                variant={data}
                onChange={variantChangeHandler}
              />
            )}
          </TabPane>
          {/* <TabPane key="product-attributes" tab="Product Attributes">
            {data && (
              <ProductSingleWineAttribute
                variant={data}
                onChange={variantChangeHandler}
              />
            )}
          </TabPane> */}
          {/*
             <TabPane key="seo" tab="SEO">
             <ProductSingleSeo />
             </TabPane>
            */}
        </Tabs>
      </MarginBox>
    </div>
  );
};

export default SingleProductAttributes;
