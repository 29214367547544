import { Col, InputNumber, Row } from "antd";
import TwfButton from "component/button";
import { usePickUpLabelsQuery } from "generated/graphql";
import { FunctionComponent } from "react";
import { OperationCard, OperationProps } from "../conditions";

const FixPickupRateAction: FunctionComponent<OperationProps> = ({
  code,
  configArgs,
  onConfigArgChange,
  onRemove,
}) => {
  const { data } = usePickUpLabelsQuery();
  const dataString = configArgs.find((arg) => arg.name === "data")?.value;
  const dataObj = JSON.parse(dataString || "{}") as Record<string, number>;

  const convertToString = (key: number, value: string | number | undefined) => {
    const newObject = { ...dataObj };

    if (value === undefined || value === null || value === "") {
      delete newObject[key];
    } else {
      newObject[key] = Number(value);
    }

    const finalString = JSON.stringify(newObject);
    onConfigArgChange({ name: "data", value: finalString }, code);
  };

  return (
    <OperationCard
      title="Fix Flat Rate Pickup Discount"
      style={{ marginTop: 16 }}
      actions={[
        <TwfButton key="remove" type="link" onClick={() => onRemove(code)}>
          Remove
        </TwfButton>,
      ]}
    >
      <Row>
        <Col>
          <>
            Flat Rates (Keep Empty for No Change)
            <br />
            Free Pickup (Input 0 for Free Pickup)
            {data?.pickupLabels?.map(({ label, value: id, cost }, index) => {
              const value = dataObj[id] ?? undefined;

              return (
                <div key={index}>
                  <br />
                  <br />
                  {label}
                  {" : "}
                  <InputNumber
                    key={index}
                    min={0}
                    max={cost}
                    formatter={(v: any) =>
                      `$ ${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(v: any) => {
                      if (!v) {
                        return "0";
                      }
                      return v.replace(/\$\s?|(,*)/g, "");
                    }}
                    value={value}
                    onChange={(v) => {
                      convertToString(id, v);
                    }}
                    style={{ width: 160 }}
                  />
                </div>
              );
            })}
          </>
        </Col>
      </Row>
    </OperationCard>
  );
};

export default FixPickupRateAction;
