import {
  Col,
  DatePicker,
  Form,
  Grid,
  Row,
  Table,
  TablePaginationConfig,
} from "antd";
import TwfButton from "component/button";
import Container from "component/container";
import { orderClassifications } from "enums/enums";
import { Order, SortOrder, useGetCustomerQuery } from "generated/graphql";
import { TableOptions } from "pages/orders/components/datatable";
import React, { useState } from "react";
import CsvDownloader from "react-csv-downloader";
import { useParams } from "react-router-dom";
import { TwfCard } from "../../../component/card";
import { MarginBox } from "../../../component/margin";

const { useBreakpoint } = Grid;

const { Item } = Form;

interface Filter {
  fromDate?: Date;
  toDate?: Date;
}

const CustomerSingleOrder: React.FC = () => {
  const breakpoints = useBreakpoint();
  const { id } = useParams<{ id: string }>();
  const cid: string = id as string;

  const [filter, setFilter] = useState<Filter>();
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    pagination: { current: 1, pageSize: 10 },
    sort: { id: SortOrder.Desc },
  });

  const { data, loading } = useGetCustomerQuery({
    variables: {
      id: cid,
      orderListOptions: {
        filter: {
          updatedAt: {
            ...(filter?.fromDate ? { after: filter.fromDate } : {}),
            ...(filter?.toDate ? { before: filter.toDate } : {}),
          },
        },
        skip:
          (tableOptions.pagination.pageSize || 10) *
          ((tableOptions.pagination.current || 1) - 1),
        take: tableOptions.pagination.pageSize,
        sort: tableOptions.sort,
      },
    },
  });

  const tableChangeHandler = (pagination: TablePaginationConfig) => {
    setTableOptions({
      pagination,
    });
  };

  const orderFilter = (order: any) => {
    return order.totalQuantity > 0 && order.state !== "AddingItems";
  };

  const csvData = (data?.customer?.orders.items.filter(orderFilter) || []).map(
    (order: any) => {
     console.log('=======',order)
      return (
        {
          orderId: order.channel?.customFields?.orderPrefix + "-" + order.id,
          orderType: orderClassifications.find(
            (c) => c.value === order.customFields?.orderClassification
          )?.label,
          purchaseDate: new Date(order.createdAt).toLocaleString().split(",")[0],
          purchaseTime: new Date(order.createdAt).toLocaleString().split(",")[1],
          orderTotal: `$${(order.totalWithTax / 100).toFixed(2)}`,
          status: order.state,
        }
      )
    }
  );

  const columns: any[] = [
    {
      title: "Order ID",
      render: (order: Order) => {
        const prefix = order.channel?.customFields?.orderPrefix;
        return prefix + "-" + order.id;
      },
    },
    {
      title: "Order Type",
      dataIndex: "customFields",
      render: (customFields: any) => {
        return orderClassifications.find(
          (c) => c.value === customFields?.orderClassification
        )?.label;
      },
    },
    {
      title: "Purchase Date",
      dataIndex: "createdAt",
      render: (createdAt: any) =>
        new Date(createdAt).toLocaleString().split(",")[0],
    },
    {
      title: "Purchase Time",
      dataIndex: "createdAt",
      render: (createdAt: any) =>
        new Date(createdAt).toLocaleString().split(",")[1],
    },
    {
      title: "Order Total",
      dataIndex: "totalWithTax",
      render: (totalWithTax: any) => {
        return (
          `$${(totalWithTax / 100).toFixed(2)}`
        )
      },
    },
    {
      title: "Status",
      dataIndex: "state",
      align: "center",
    },
  ];

  return (
    <Container>
      <TwfCard>
        <Form layout="vertical">
          <MarginBox
            pl={breakpoints.md ? 75 : 15}
            pr={breakpoints.md ? 75 : 15}
            pt={breakpoints.md ? 32 : 21}
            mb={breakpoints.md ? 32 : 21}
          >
            <Row gutter={breakpoints.md ? 24 : 12}>
              <Col lg={{ span: 6 }} span={12}>
                <Item label="From Date">
                  <DatePicker
                    onChange={(e: any) =>
                      setFilter({ ...filter, fromDate: e ? e._d : null })
                    }
                  />
                </Item>
              </Col>
              <Col lg={{ span: 6 }} span={12}>
                <Item label="To Date">
                  <DatePicker
                    onChange={(e: any) =>
                      setFilter({ ...filter, toDate: e ? e._d : null })
                    }
                  />
                </Item>
              </Col>
              {breakpoints.md && (
                <Col lg={{ span: 12 }} span={24}>
                  <MarginBox mt={28}>
                    <Item>
                      <CsvDownloader datas={csvData} filename="orders">
                        <TwfButton
                          type="primary"
                          color-twf="primary"
                          size-twf="md"
                          type-twf="wide"
                        >
                          Export Orders
                        </TwfButton>
                      </CsvDownloader>
                    </Item>
                  </MarginBox>
                </Col>
              )}
            </Row>
          </MarginBox>
        </Form>
        <MarginBox
          pl={breakpoints.md ? 75 : 15}
          pr={breakpoints.md ? 75 : 15}
          mt={12}
          mb={56}
        >
          <Table
            columns={columns}
            dataSource={data?.customer?.orders.items
              .filter(orderFilter)
              .map((obj) => ({
                ...obj,
                key: obj.id,
              }))}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} Items`,
              ...tableOptions.pagination,
              total: data?.customer?.orders.totalItems,
              position: ["bottomCenter"],
              showSizeChanger: true,
            }}
            onChange={tableChangeHandler}
            loading={loading}
            style={{ maxWidth: "max-content" }}
            scroll={{ x: "max-content" }}
          />
        </MarginBox>
      </TwfCard>
    </Container>
  );
};

export default CustomerSingleOrder;
