import React, { useState, useEffect } from "react";
import {
  Col,
  message,
  Popconfirm,
  Row,
  Table,
  TablePaginationConfig,
} from "antd";
import { PageTitle } from "../../../component/header";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";
import AdvancedSearch from "../../orders/components/search";
import { MarginBox } from "../../../component/margin";
import { TwfCard } from "../../../component/card";
import AddDiscounts from "./addDiscounts";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../component/button/back";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  Promotion,
  SortOrder,
  useDeletePromotionMutation,
  useGetPromotionListLazyQuery,
} from "generated/graphql";
import { TableOptions } from "pages/orders/components/datatable";
import Container from "component/container";

interface ComponentProps {}

const DiscountsManagement: React.FC<ComponentProps> = () => {
  const history = useNavigate();
  const breakpoints = useBreakpoint();
  const [getPromotionListQuery, { data, loading }] =
    useGetPromotionListLazyQuery();
  const [deletePromotionMutation] = useDeletePromotionMutation();

  const [addNew, setAddNew] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    pagination: { current: 1, pageSize: 10 },
    sort: { id: SortOrder.Desc },
  });

  useEffect(() => {
    getPromotionListQuery({
      variables: {
        options: {
          filter: {
            ...(filter && { name: { contains: filter } }),
          },
          skip:
            (tableOptions.pagination.pageSize || 10) *
            ((tableOptions.pagination.current || 1) - 1),
          take: tableOptions.pagination.pageSize,
          sort: tableOptions.sort,
        },
      },
    });
  }, [getPromotionListQuery, tableOptions, filter]);

  const tableChangeHandler = (
    pagination: TablePaginationConfig,
    filters: any,
    sort: any
  ) => {
    setTableOptions({
      pagination,
      sort: sort.field
        ? {
            [sort.field]:
              sort.order === "ascend" ? SortOrder.Asc : SortOrder.Desc,
          }
        : tableOptions.sort,
    });
  };

  const promotionDeleteHandler = (id: string) => {
    deletePromotionMutation({
      variables: { id },
      refetchQueries: ["GetPromotionList"],
    }).then(() => message.success("Discount deleted"));
  };

  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      // width: breakpoints.md ? 200 : 100,
      align: "center",
      sorter: {},
    },
    {
      title: "Coupon Code",
      dataIndex: "couponCode",
      align: "center",
      sorter: {},
    },
    {
      title: "Starts at",
      dataIndex: "startsAt",
      align: "center",
      render: (startsAt: any) =>
        startsAt && new Date(startsAt).toLocaleString(),
      sorter: {},
    },
    {
      title: "Ends at",
      dataIndex: "endsAt",
      align: "center",
      render: (endsAt: any) => endsAt && new Date(endsAt).toLocaleString(),
      sorter: {},
    },
    {
      title: "Status",
      dataIndex: "enabled",
      align: "center",
      render: (enabled: boolean) => (enabled ? "Active" : "Inactive"),
      sorter: {},
    },
    {
      title: "",
      key: "action",
      // width: breakpoints.md ? 140 : 100,
      render: (record: Promotion) => {
        return (
          <Row justify="center">
            <Col>
              <TwfButton
                shape="circle"
                type-twf="icon"
                onClick={() => {
                  history("/marketing/discounting/" + record.id);
                }}
              >
                <Icon keyword="edit" color="#3C64B1" />
              </TwfButton>
            </Col>
            <Col>
              <Popconfirm
                title="Are you sure to delete this promotion?"
                onConfirm={() => promotionDeleteHandler(record.id)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <TwfButton shape="circle" type-twf="icon">
                  <Icon keyword="delete" color="#FF4C61" />
                </TwfButton>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <div>
      <Row justify="space-between" gutter={[24, 24]}>
        <Col>
          <Row gutter={[30, 30]}>
            <Col>
              <Row gutter={12} align="middle">
                <Col>
                  <BackButton />
                </Col>
                <Col>
                  <PageTitle>Discounts</PageTitle>
                </Col>
              </Row>
            </Col>
            {breakpoints.md && (
              <Col>
                <Row gutter={[24, 24]}>
                  <Col>
                    <AdvancedSearch setFilter={setFilter} />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
        <Col>
          <Row gutter={[24, 24]} align="middle">
            <Col>
              <TwfButton
                color-twf="primary"
                type="primary"
                size-twf="lg"
                type-twf="wide"
                onClick={() => {
                  history("/marketing");
                }}
              >
                {breakpoints.md && (
                  <Icon keyword="email-management" width={20} color="#fff" />
                )}
                Email Templates
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                color-twf="primary"
                type="primary"
                size-twf="lg"
                type-twf="wide"
                onClick={() => {
                  history("/marketing/discounting/create");
                }}
              >
                {breakpoints.md && (
                  <Icon keyword="plus" width={16} color="#fff" />
                )}
                New Discounts
              </TwfButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <MarginBox mt={32}>
        <Container>
          <TwfCard>
            <MarginBox mt={12} mb={56}>
              <Table
                columns={columns}
                onChange={tableChangeHandler}
                dataSource={data?.promotions.items.map((obj) => ({
                  ...obj,
                  key: obj.id,
                }))}
                loading={loading}
                pagination={{
                  ...tableOptions.pagination,
                  total: data?.promotions.totalItems,
                  position: ["bottomCenter"],
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} Items`,
                  showSizeChanger: true,
                }}
                style={{ maxWidth: "max-content" }}
                scroll={{ x: "max-content" }}
              />
            </MarginBox>
          </TwfCard>
        </Container>
      </MarginBox>
      <AddDiscounts visible={addNew} setVisible={setAddNew} />
    </div>
  );
};

export default DiscountsManagement;
