import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const EditIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.916 4.33203H4.33268C3.75805 4.33203 3.20695 4.5603 2.80062 4.96663C2.39429 5.37296 2.16602 5.92406 2.16602 6.4987V21.6654C2.16602 22.24 2.39429 22.7911 2.80062 23.1974C3.20695 23.6038 3.75805 23.832 4.33268 23.832H19.4993C20.074 23.832 20.6251 23.6038 21.0314 23.1974C21.4377 22.7911 21.666 22.24 21.666 21.6654V14.082"
      stroke={color ? color : "#000000"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.041 2.70825C20.472 2.27728 21.0565 2.03516 21.666 2.03516C22.2755 2.03516 22.86 2.27728 23.291 2.70825C23.722 3.13923 23.9641 3.72376 23.9641 4.33325C23.9641 4.94275 23.722 5.52728 23.291 5.95825L12.9993 16.2499L8.66602 17.3333L9.74935 12.9999L20.041 2.70825Z"
      stroke={color ? color : "#000000"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
