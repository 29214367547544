import { Dropdown, Menu } from "antd";
import Icon from "assets/icons";
import TwfButton from "component/button";
import { ClubReport, useGetClubReportLazyQuery } from "generated/graphql";
import * as XLSX from "xlsx";
import { ClubReportTableColumn } from "./types";
import { s2ab } from "utils/excel";

export default function ClubReportTableAction(record: ClubReportTableColumn) {
  const [getClubReport, { loading }] = useGetClubReportLazyQuery();

  const generateExcel = async (data: ClubReport[]) => {
    try {
      const worksheetData = data.map((data) => ({
        "Customer ID": data.customerId,
        "Full Name": data.fullName,
        Email: data.email,
        "Club Name": data.clubName,
        "Club Options": data.clubOptions,
        "Club Membership Status": data.clubMembershipStatus,
        "Date Club Joined": data.dateClubJoined,
        "Date Club Cancelled": data.dateClubCancelled,
        "# of Shipments received": data.shipmentsReceived,
        "LTV of All Shipments": data.ltvOfAllShipments,
      }));

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(worksheetData);

      ws["!cols"] = [
        { wch: 15 }, // "Customer ID"
        { wch: 20 }, // "Full Name"
        { wch: 30 }, // "Email"
        { wch: 25 }, // "Club Name"
        { wch: 25 }, // "Club Options"
        { wch: 20 }, // "Club Membership Status"
        { wch: 20 }, // "Date Club Joined"
        { wch: 20 }, // "Date Club Cancelled"
        { wch: 15 }, // "# of Shipments received"
        { wch: 18 }, // "LTV of All Shipments"
      ];

      XLSX.utils.book_append_sheet(wb, ws, "Report");

      const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = record.name + "_club_report.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };

  return (
    <Dropdown
      overlay={
        <Menu style={{ borderRadius: 8 }}>
          <Menu.Item
            onClick={async () => {
              const { data } = await getClubReport({
                variables: {
                  id: record.id,
                },
              });

              if (data?.clubReport?.length) {
                generateExcel(data.clubReport);
              }
            }}
          >
            Export Report
          </Menu.Item>
        </Menu>
      }
    >
      <TwfButton shape="circle" type-twf="icon" loading={loading}>
        <Icon keyword="collapse" width={18} />
      </TwfButton>
    </Dropdown>
  );
}
