import styled from "styled-components";
import { Modal } from "antd";

const TwfModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 24px;
    @media (max-width: 768px) {
      border-radius: 0;
    }
    .ant-modal-close {
      top: 31px;
      right: 39px;
      @media (max-width: 768px) {
        top: 28px;
        right: 32px;
      }
      .ant-modal-close-x {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f4f9fd;
        border-radius: 14px;
        @media (max-width: 768px) {
          width: 32px;
          height: 32px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .ant-modal-body {
      padding: 31px 51px;
      @media (max-width: 768px) {
        padding: 31px 36px;
        min-height: 100vh;
      }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding-bottom: 0;
  }
`;

export default TwfModal;
