/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  Radio,
  Row,
  Select,
  message,
} from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import BackButton from "../../../component/button/back";
import { BlockTitle, H2, SelectTitle } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import {
  LineDetail,
  LineValue,
  PriceTable,
} from "../../../component/table/price";
import {
  GetAllCustomerCardsQueryHookResult,
  Order,
  useAddManualPaymentMutation,
  useAddPaymentMutation,
  useCalculateOrderTaxMutation,
  useCheckComplianceMutation,
  useCheckIfCompliantLazyQuery,
  useCreateCustomerCardMutation,
  useCreateCustomerUserIfNotExistMutation,
  useEligibleShippingMethodsLazyQuery,
  useGetActiveChannelQuery,
  useGetAllCustomerCardsQuery,
  useGetOrderQuery,
  useManageComplianceStateMutation,
  usePickUpLabelsQuery,
  useRemoveItemFromOrderMutation,
  useRunComplianceCheckAdminMutation,
  useSetCustomerForOrderMutation,
  useSetOrderBillingAddressMutation,
  useSetOrderCustomFieldsMutation,
  useSetOrderShippingAddressMutation,
  useSetShippingMethodMutation,
  useTransitionOrderToStateMutation,
} from "../../../generated/graphql";
import {
  orderClassifications,
  paymentTypes,
  sources,
} from "./../../../enums/enums";
import CreateMobileFooter from "./create/mobile-footer";
import CreateOrderProducts from "./create/products";
import CreateOrderReview from "./create/review";
import DiscountApply from "./discount";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import USStateSelector from "component/form-elements/us-state-selector";
import PricingTable from "component/pricing-table";
import SmartyAutoComplete from "component/smarty-street";
import { Suggestion } from "component/smarty-street/types";
import { useDispatch } from "react-redux";
import uuid from "react-uuid";
import { setOrder } from "store/action/ecommerce.action";
import styled from "styled-components";
import Container from "../../../component/container";

const { Item } = Form;
const { TextArea } = Input;
const { useBreakpoint } = Grid;

interface SelectOption {
  label: string;
  value: string;
}

interface CardInfo {
  paymentMethodId: string;
}

const EditOrder = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const breakpoints = useBreakpoint();
  const { id } = useParams<{ id: string }>();
  const cid: string = id as string;
  const [setOrderBillingAddressMutation, { data: billingAddressResult }] =
    useSetOrderBillingAddressMutation();
  const [setOrderShippingAddressMutation, { data: shippingAddressResult }] =
    useSetOrderShippingAddressMutation();
  const [setOrderCustomFieldsMutation, { data: customFieldsResult }] =
    useSetOrderCustomFieldsMutation();

  const [RunComplianceCheckAdminMutation] =
    useRunComplianceCheckAdminMutation();

  const [checkIfOrderCompliant] = useCheckIfCompliantLazyQuery();

  const [checkComplianceMutation] = useCheckComplianceMutation();

  const [createCustomerCardMutation] = useCreateCustomerCardMutation();

  const [createCustomerUserIfNotExist] =
    useCreateCustomerUserIfNotExistMutation();

  const [transitionOrderToStateMutation] = useTransitionOrderToStateMutation();

  const [showReviewButton, setShowReviewButton] = useState(false);

  const [calculateOrderTaxMutation] = useCalculateOrderTaxMutation();

  const { data: orderResult } = useGetOrderQuery({
    variables: {
      id: cid,
    },
  });

  const [pickupLocations, setPickupLocations] = useState<any[]>([]);

  const [doDiscount, setDoDiscount] = useState(0);

  const [makeDiscount, setMakeDiscount] = useState(0);

  const { data: pickupLabels } = usePickUpLabelsQuery();

  useEffect(() => {
    if (pickupLabels?.pickupLabels) {
      setPickupLocations(pickupLabels.pickupLabels);
    }
  }, [pickupLabels]);

  const order = orderResult?.order;

  const options = useOptions();
  const stripe = useStripe();
  const elements = useElements();
  const [formData, setFormData] = useState<any>();
  const [reviewVisible, setReviewVisible] = useState(false);
  const [productVisible, setProductVisible] = useState(false);
  const [sameAddressChecked, setSameAddressChecked] = useState(false);
  const [pickUp, setPickUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shipping, setShipping] = useState(true);
  const [showCard, setShowCard] = useState(false);
  const [showCardForm, setShowCardForm] = useState<boolean>(false);
  const [cardOptions, setCardOptions] = useState<SelectOption[]>([]);
  const [card, setCard] = useState<CardInfo | null>(null);
  const [setCustomerForOrderMutation] = useSetCustomerForOrderMutation();

  const [shipM, setShipM] = useState<string | null>();

  const [pickM, setPickM] = useState<string | null>();

  const [cardEmail, setCardEmail] = useState<string>();

  const [addPaymentMutation] = useAddPaymentMutation();
  const [addManualPaymentMutation] = useAddManualPaymentMutation();

  const [removeItemFromOrderMutation] = useRemoveItemFromOrderMutation();

  const cards = useGetAllCustomerCardsQuery({
    variables: {
      input: {
        customerEmail: cardEmail ? cardEmail : formData?.billingEmail,
      },
    },
    fetchPolicy: "network-only",
  });

  const now = new Date();
  const minDate = new Date(
    now.getFullYear() - 130,
    now.getMonth(),
    now.getDate()
  );
  const maxDate = new Date(
    now.getFullYear() - 21,
    now.getMonth(),
    now.getDate()
  );

  useEffect(() => {
    setCardOptions([
      { label: "Select", value: "0" },
      ...getCustomerCreditCards(cards),
      { label: "- Add a New Card -", value: "-1" },
    ]);
  }, [cards]);

  const getCustomerCreditCards = ({
    data,
  }: GetAllCustomerCardsQueryHookResult): SelectOption[] => {
    if (data?.getCustomerCards && data?.getCustomerCards.items.length > 0) {
      return data.getCustomerCards.items.map((card) => ({
        label: card.last4!,
        value: card.id!,
        selected: card.isDefault! ? "selected" : "",
      }));
    } else {
      return [];
    }
  };

  const [setShippingMethodMutation] = useSetShippingMethodMutation();

  const [nullid, setNullId] = useState<string>("");

  const [smartyKey, setSmartyKey] = useState(Math.floor(Math.random() * 10000));

  const [replicableStreetLine1, setReplicableStreetLine1] = useState<
    string | undefined
  >();

  const [shippingmethods, setShippingMethods] = useState<any>();

  const [helpers, setHelpers] = useState({
    inputTypeBilling: "text",
    inputTypeShipping: "text",
    billingStreetAddress: "",
    disableInputField: false,
    shippingStreetAddress: "",
    shippingProvince: "",
  });

  const [getEligibleShippingMethods, { data: eligibleShippingMethods }] =
    useEligibleShippingMethodsLazyQuery({
      fetchPolicy: "network-only",
    });

  const [manageComplianceState] = useManageComplianceStateMutation();

  let initialData: any;

  const shippingMethodsFetch = (order: Order) => {
    getEligibleShippingMethods({
      variables: {
        orderId: order.id,
      },
    })
      .then((data) => {
        if (data?.data?.eligibleShippingMethods) {
          const shippingMethods = data.data.eligibleShippingMethods;
          const SM = [] as any;
          if (shippingMethods.length !== 0) {
            shippingMethods.forEach((val) => {
              const method = Object(val);
              if (method.code !== "null-shipping-method") {
                const nmethod = {
                  label:
                    method.name +
                    " ($" +
                    (method.priceWithTax / 100).toFixed(2) +
                    ")",
                  value: Number(method.id),
                };
                SM.push(nmethod);
              } else {
                setNullId(method.id.toString());
              }
            });
          }
          setShippingMethods(SM);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  useEffect(() => {
    if (orderResult?.order) {
      shippingMethodsFetch(orderResult.order as Order);
      initializeForm(orderResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderResult]);

  useEffect(() => {
    if (order?.totalQuantity) {
      if (order?.shippingAddress?.province) {
        getShippingMethodsByState(order.shippingAddress.province, 1);
      } else {
        shippingMethodsFetch(order as Order);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.totalQuantity]);

  const initializeForm = (orderResult: any) => {
    getInitialData(orderResult);
  };

  const getInitialData = (orderResult: any) => {
    const { order } = orderResult;

    if (order?.shippingAddress?.province) {
      shippingMethodsFetch(order);
    }

    initialData = {
      billingFirstName: order?.billingAddress?.customFields?.firstName,
      billingLastName: order?.billingAddress?.customFields?.lastName,
      billingPhoneNumber: order?.billingAddress?.phoneNumber,
      billingEmail: order?.billingAddress?.customFields?.email,
      billingCompany: order?.billingAddress?.company,
      billingDOB: order?.billingAddress?.customFields?.dob,
      billingStreetLine1: order?.billingAddress?.streetLine1,
      billingStreetLine2: order?.billingAddress?.streetLine2,
      billingCity: order?.billingAddress?.city,
      billingProvince: order?.billingAddress?.province,
      billingCountryCode: "United States",
      billingPostalCode: order?.billingAddress?.postalCode,
      shippingFirstName: order?.shippingAddress?.customFields?.firstName,
      shippingLastName: order?.shippingAddress?.customFields?.lastName,
      shippingPhoneNumber: order?.shippingAddress?.phoneNumber,
      shippingEmail: order?.shippingAddress?.customFields?.email,
      shippingCompany: order?.shippingAddress?.company,
      shippingDOB: order?.shippingAddress?.customFields?.dob,
      shippingStreetLine1: order?.shippingAddress?.streetLine1,
      shippingStreetLine2: order?.shippingAddress?.streetLine2,
      shippingCity: order?.shippingAddress?.city,
      shippingProvince: order?.shippingAddress?.province,
      shippingCountryCode: "United States",
      shippingPostalCode: order?.shippingAddress?.postalCode,
      defaultShipping: order?.customFields.defaultShipping,
      source: order?.customFields.source,
      orderClassification: order?.customFields.orderClassification,
      paymentType: order?.customFields.paymentType,
      giftMessage: order?.customFields.giftMessage,
      pickupLocation: order?.customFields.pickupLocation,
      shippingMethod: order?.customFields?.shippingMethod,
      specialInstructions: order?.customFields.specialInstructions,
    } as any;

    console.log(order?.customFields?.shippingMethod);

    setHelpers((prevState) => ({
      ...prevState,
      shippingStreetAddress: order?.shippingAddress?.streetLine1 || "",
      billingStreetAddress: order?.billingAddress?.streetLine1 || "",
      shippingProvince: order?.shippingAddress?.province || "",
    }));

    if (order?.customFields.paymentType === 1) {
      setShowCard(true);
    }

    if (order?.customFields.futureShipDate) {
      initialData.futureShipDate = moment(order?.customFields.futureShipDate);
    }
    if (order?.customFields.creditCardInformation) {
      initialData.creditCardInformation = String(
        order?.customFields?.creditCardInformation
      );
    }

    if (order?.customFields?.shippingMethod) {
      setShipM(order.customFields.shippingMethod.toString());
    }

    setFormData(initialData);

    form.setFieldsValue(initialData);

    if (order?.customFields.defaultShipping === true) {
      setPickUp(false);
    } else {
      setPickUp(true);
    }
    setShipping(order?.customFields.defaultShipping);
  };

  const onFinishHandler = (values: any) => {
    if (
      helpers.shippingStreetAddress !== "" &&
      helpers.shippingStreetAddress !== values.shippingStreetLine1
    ) {
      values.shippingStreetLine1 = helpers.shippingStreetAddress;
    }

    if (
      helpers.billingStreetAddress !== "" &&
      helpers.billingStreetAddress !== values.billingStreetLine1
    ) {
      values.billingStreetLine1 = helpers.billingStreetAddress;
    }

    let stateCode;
    if (pickUp) {
      stateCode = "CA";
    } else {
      stateCode = values.shippingProvince;
    }
    checkComplianceMutation({
      variables: {
        stateCode: stateCode,
      },
    }).then(async ({ data }) => {
      if (data?.checkCompliance?.__typename === "ComplianceCheckError") {
        message.error(data?.checkCompliance.message);
      }
      if (data?.checkCompliance.__typename === "ComplianceCheck") {
        if (data?.checkCompliance.status === 1) {
          setShowReviewButton(true);
          setReviewVisible(false);
          await saveAddressInfo()
            .then(async () => {
              await calculateTax()
                .then(() => {
                  setShowReviewButton(false);
                  setReviewVisible(true);
                })
                .catch((err) => {
                  const finalError = err + "System Error, Please Try Again";
                  message.error(finalError, 5);
                  setShowReviewButton(false);
                });
            })
            .catch((err1) => {
              setShowReviewButton(false);
              message.error(err1);
            });
        } else {
          const cleanmsg =
            data?.checkCompliance?.compliance?.complianceAdvisory?.replace(
              /<\/?[^>]+(>|$)/g,
              ""
            );
          const cleanmsg1 =
            data?.checkCompliance?.compliance?.shippingAdvisory?.replace(
              /<\/?[^>]+(>|$)/g,
              ""
            );
          message.warning(cleanmsg);
          message.warning(cleanmsg1);
          setShowReviewButton(false);
        }
      }
    });
  };

  let totalCost = 0;
  if (order?.lines && order.lines.length !== 0) {
    const orderItems = order.lines.map((line) => line.items);
    const finalOrderItems = orderItems.flat(1);
    const costs = finalOrderItems.map((item) => item.unitPrice);
    totalCost = costs.reduce((a, b) => a + b, 0);
  }

  const saveAddressInfo = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      await saveBillingInfo()
        .then(async () => {
          await saveShippingInfo()
            .then(() => {
              resolve(true);
            })
            .catch((shippingAddressError) => {
              reject(shippingAddressError);
            });
        })
        .catch((billingAddressError) => {
          message.error(billingAddressError);
        });
    });
  };

  const submitOrder = () => {
    if (order?.lines) {
      if (order?.lines?.length > 0) {
        setLoading(true);
        setCustomerForOrder();
      } else message.error("No item is added to order!");
    }
  };

  const createUserIfNotExits = (id: string) => {
    return new Promise((resolve, reject) => {
      createCustomerUserIfNotExist({
        variables: {
          id: id,
        },
      })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const setCustomerForOrder = (flag = 0) => {
    if (order?.id) {
      setCustomerForOrderMutation({
        variables: {
          orderId: order?.id,
          input: {
            firstName: form.getFieldValue("billingFirstName"),
            lastName: form.getFieldValue("billingLastName"),
            phoneNumber: form.getFieldValue("billingPhoneNumber"),
            emailAddress: form.getFieldValue("billingEmail"),
          },
        },
      }).then(({ data }) => {
        if (data?.setCustomerForOrder.__typename === "Order") {
          const customerID = data.setCustomerForOrder.customer?.id;
          if (customerID) {
            createUserIfNotExits(customerID).then((data: any) => {
              if (data === true) {
                manageAddresses(flag);
              } else {
                setLoading(false);
                message.error(data);
              }
            });
          }
        }
      });
    }
  };

  const manageAddresses = (flag = 0) => {
    saveBillingInfo()
      .then(() => {
        saveShippingInfo()
          .then(() => {
            makeTheShipping(order!.id, flag);
          })
          .catch((error) => {
            setLoading(false);
            message.error(error.message);
          });
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message);
      });
  };

  useEffect(() => {
    if (sameAddressChecked) {
      setSmartyKey(Math.floor(Math.random() * 10000));
    }
  }, [sameAddressChecked]);

  const allCompleted = () => {
    manageComplianceState({
      variables: {
        id: order!.id,
      },
    })
      .then(() => {
        setLoading(false);
        message.success("You have successfully updated order information");
        setReviewVisible(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message);
      });
  };

  const makeTheShipping = (id: any, flag = 0) => {
    if (form.getFieldValue("shippingMethod")) {
      const sM = shippingmethods.find(
        (sM: any) => sM.value === Number(form.getFieldValue("shippingMethod"))
      );
      if (sM?.label) {
        setOrderCustomFieldsMutation({
          variables: {
            input: {
              id: id,
              customFields: {
                csvShippingMethod: sM.label.split("(")[0].trim(),
                orderClassification: form.getFieldValue("orderClassification"),
                specialInstructions: form.getFieldValue("specialInstructions"),
                giftMessage: form.getFieldValue("giftMessage"),
              },
            },
          },
          fetchPolicy: "network-only",
        })
          .then(() => {
            shipit(sM.value, flag);
          })
          .catch((error) => {
            message.error(error.message);
          });
      } else {
        message.error(
          "Selected shipping method doesn't exist or not applicable"
        );
      }
    } else {
      setOrderCustomFieldsMutation({
        variables: {
          input: {
            id: id,
            customFields: {
              pickupLocation: form.getFieldValue("pickupLocation"),
            },
          },
        },
        fetchPolicy: "network-only",
      })
        .then(() => {
          setShippingMethod(nullid)
            .then(() => {
              if (flag === 0) {
                middleman();
              }
            })
            .catch(() => {
              setLoading(false);
              message.error("Setting Pickup Location Error");
            });
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  const shipit = (sId: string, flag = 0) => {
    setShippingMethod(sId)
      .then(() => {
        if (flag === 0) {
          middleman();
        }
      })
      .catch(() => {
        setLoading(false);
        message.error("Setting Shipping Method Error");
      });
  };

  const calculateTax = () => {
    return new Promise((resolve, reject) => {
      const taxVariable = {
        id: order!.id,
        onSite: pickUp,
      } as any;
      if (order?.customFields?.byPassed) {
        taxVariable["zero"] = true;
      }
      calculateOrderTaxMutation({
        variables: taxVariable,
        fetchPolicy: "network-only",
      })
        .then((data) => {
          resolve(true);
        })
        .catch((error) => {
          const finalError =
            "Tax Calculation Error: " +
            error.message +
            " Please consult main admin or store owner.";
          reject(finalError);
        });
    });
  };

  const middleman = () => {
    if (order?.state !== "ArrangingPayment") {
      transitionOrderToState("ArrangingPayment")
        .then(() => {
          checkCompliance();
        })
        .catch((msg) => {
          setLoading(false);
          message.error(msg);
        });
    } else {
      checkCompliance();
    }
  };

  const checkCompliance = () => {
    if (form.getFieldValue("orderClassification") !== 4) {
      checkIfOrderCompliant({
        variables: {
          id: order!.id,
        },
      })
        .then((data) => {
          if (data?.data?.checkIfCompliant) {
            message.success("Compliance Passed");
          } else {
            message.error("Compliance Failed");
          }
          continuepayment();
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.message);
        });
    } else {
      continuepayment();
    }
  };

  const continuepayment = () => {
    if (order?.customFields?.paid) {
      addNullPayment();
    } else {
      if (card) {
        if (cards?.data?.getCustomerCards) {
          addPayment(
            cards.data.getCustomerCards.items.find(
              (car) => car.id === card.paymentMethodId
            )?.stripeResponse
          );
        }
      } else {
        if (showCard) {
          createStripeCreditCard()
            .then((paymentMethod) => createCustomerCard(paymentMethod))
            .then((paymentMethod) => addPayment(paymentMethod))
            .catch((error) => {
              setLoading(false);
              message.error(error);
            });
        } else {
          addNullPayment();
        }
      }
    }
  };

  const addNotNullPayment = () => {
    addManualPaymentMutation({
      variables: {
        input: {
          orderId: order?.id!,
          transactionId: uuid(),
          method: "stripe",
          metadata: {},
        },
      },
    })
      .then((output: any) => {
        if (output.data.addManualPaymentToOrder.__typename === "Order") {
          transitionOrderToState("PaymentSettled")
            .then(() => {
              message.info("Payment Succeed");
              customFieldsSetting();
            })
            .catch((err) => {
              setLoading(false);
              message.error(
                err.message + " Please click review button once again."
              );
            });
        } else {
          setLoading(false);
          let emsg =
            output.data.addManualPaymentToOrder.message +
            " Please click review button once again.";
          message.error(emsg);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          error.message + " Please click review button once again."
        );
      });
  };

  const addNullPayment = () => {
    addManualPaymentMutation({
      variables: {
        input: {
          orderId: order?.id!,
          transactionId: uuid(),
          method: "null",
          metadata: {},
        },
      },
    })
      .then((output: any) => {
        if (output.data.addManualPaymentToOrder.__typename === "Order") {
          transitionOrderToState("PaymentSettled")
            .then(() => {
              if (!order?.customFields?.paid) {
                message.info("Payment Succeed");
              }
              customFieldsSetting();
            })
            .catch((err) => {
              setLoading(false);
              message.error(
                err.message + " Please click review button once again."
              );
            });
        } else {
          let emsg =
            output.data.addManualPaymentToOrder.message +
            " Please click review button once again.";
          if (emsg) {
            setLoading(false);
            message.error(emsg + " Please click review button once again.");
          } else {
            setLoading(false);
            message.error(
              output.data.addPaymentToOrder.__typename +
                " Please click review button once again."
            );
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message);
      });
  };

  const addPayment = (stripeResponse: any) => {
    addPaymentMutation({
      variables: {
        orderId: order?.id!,
        input: {
          method: "stripe",
          metadata: stripeResponse,
        },
      },
      fetchPolicy: "network-only",
    })
      .then((output: any) => {
        if (output.data.addPaymentToOrder.__typename === "Order") {
          addNotNullPayment();
        } else if (
          output.data.addPaymentToOrder.__typename === "PaymentDeclinedError"
        ) {
          message.error("Payment Declined");
          setLoading(false);
          setReviewVisible(false);
        } else {
          let emsg = output.data.addPaymentToOrder.message;
          message.error(emsg);
          setLoading(false);
          setReviewVisible(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message);
      });
  };

  const { data: getActiveChannel } = useGetActiveChannelQuery();

  const createStripeCreditCard = (): Promise<CardInfo> => {
    return new Promise(async (resolve, reject) => {
      if (elements === null || stripe === null || CardNumberElement === null) {
        reject(undefined);
        return;
      }
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement)!,
        billing_details: {
          email: form.getFieldValue("billingEmail"),
          name: form.getFieldValue("nameOnCard"),
          address: {
            postal_code: form.getFieldValue("billingZip"),
            state: form.getFieldValue("billingState"),
          },
        },
        metadata: {
          "store-token": getActiveChannel?.activeChannel?.token || null,
        },
      });
      if (paymentMethod?.id)
        resolve({ paymentMethodId: paymentMethod.id } as CardInfo);
      else reject(error?.message);
    });
  };

  const createCustomerCard = (cardInfo: CardInfo): Promise<CardInfo> => {
    return new Promise(async (resolve, reject) => {
      createCustomerCardMutation({
        variables: {
          paymentMethodId: cardInfo.paymentMethodId,
          customerEmail: formData.billingEmail,
        },
      })
        .then(({ data }) => {
          if (data?.attachPaymentMethodToCustomer.__typename === "Card") {
            resolve(cardInfo);
          } else if (
            data?.attachPaymentMethodToCustomer.__typename ===
            "PaymentMethodAttachError"
          ) {
            if (
              data.attachPaymentMethodToCustomer.message ===
              "Credit card already exists"
            ) {
              resolve(cardInfo);
            } else {
              reject(data.attachPaymentMethodToCustomer.message);
            }
          }
        })
        .catch((error) => {
          if (error === "Credit card already exists") {
            resolve(cardInfo);
          } else {
            reject(error.message);
          }
        });
    });
  };

  const transitionOrderToState = (state: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      transitionOrderToStateMutation({
        variables: { id: order?.id!, state },
        refetchQueries: ["filteredOrders"],
      }).then(({ data }) => {
        if (data?.transitionOrderToState?.__typename === "Order") {
          resolve(true);
        }
        if (
          data?.transitionOrderToState?.__typename ===
          "OrderStateTransitionError"
        ) {
          reject(data.transitionOrderToState.message);
        }
      });
    });
  };

  const setShippingMethod = (shippingMethodId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      setShippingMethodMutation({
        variables: {
          orderId: order?.id!,
          shippingMethodId,
        },
        refetchQueries: ["getOrder"],
      }).then(({ data }) => {
        if (data) {
          if (data.setOrderShippingMethod.__typename === "Order") {
            dispatch<any>(setOrder(data.setOrderShippingMethod));
            resolve(true);
          } else {
            reject(false);
          }
        }
      });
    });
  };

  const cardChangeHandler = (value: string) => {
    if (value === "-1" || value === "0") {
      setCard(null);
      setShowCardForm(value === "-1");
    } else {
      setCard({ paymentMethodId: value } as CardInfo);
      setShowCardForm(false);
    }
  };

  const [intermediateState, setIntermediateState] = useState("");

  const saveBillingInfo = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      const values = {
        fullName:
          form.getFieldValue("billingFirstName") +
          " " +
          form.getFieldValue("billingLastName"),
        phoneNumber: form.getFieldValue("billingPhoneNumber"),
        streetLine1:
          helpers.billingStreetAddress ||
          form.getFieldValue("billingStreetLine1"),
        streetLine2: form.getFieldValue("billingStreetLine2"),
        city: form.getFieldValue("billingCity"),
        province: form.getFieldValue("billingProvince"),
        company: form.getFieldValue("billingCompany"),
        countryCode: "us",
        postalCode: form.getFieldValue("billingPostalCode"),
        customFields: {
          email: form.getFieldValue("billingEmail"),
          dob: form.getFieldValue("billingDOB"),
          firstName: form.getFieldValue("billingFirstName"),
          lastName: form.getFieldValue("billingLastName"),
        },
      } as any;
      setOrderBillingAddressMutation({
        variables: {
          input: values,
          orderId: cid,
        },
      })
        .then(({ data }) => {
          if (data?.setOrderBillingAddress.__typename === "Order") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const saveIntermediateShippingInfo = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      const values = {
        fullName:
          formData?.shippingFirstName + " " + formData?.shippingLastName,
        streetLine1: formData?.shippingStreetLine1 || "",
        province: formData?.shippingProvince || intermediateState,
        countryCode: "us",
      } as any;
      setOrderShippingAddressMutation({
        variables: {
          input: values,
          orderId: cid,
        },
      })
        .then(({ data }) => {
          if (data?.setOrderShippingAddress.__typename === "Order") {
            dispatch<any>(setOrder(data.setOrderShippingAddress));
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const saveShippingInfo = (): Promise<any> => {
    console.log("helpers", helpers);
    return new Promise((resolve, reject) => {
      const values = {
        fullName:
          form.getFieldValue("shippingFirstName") +
          " " +
          form.getFieldValue("shippingLastName"),
        phoneNumber: form.getFieldValue("shippingPhoneNumber"),
        streetLine1:
          helpers.shippingStreetAddress ||
          form.getFieldValue("shippingStreetLine1"),
        streetLine2: form.getFieldValue("shippingStreetLine2"),
        city: form.getFieldValue("shippingCity"),
        company: form.getFieldValue("shippingCompany"),
        province:
          helpers.shippingProvince || form.getFieldValue("shippingProvince"),
        countryCode: "us",
        postalCode: form.getFieldValue("shippingPostalCode"),
        customFields: {
          email: form.getFieldValue("shippingEmail"),
          dob: form.getFieldValue("shippingDOB"),
          firstName: form.getFieldValue("shippingFirstName"),
          lastName: form.getFieldValue("shippingLastName"),
        },
      } as any;
      if (pickUp) {
        values["streetLine1"] =
          helpers.billingStreetAddress ||
          form.getFieldValue("billingStreetLine1");
        values["streetLine2"] = form.getFieldValue("billingStreetLine2");
        values["city"] = form.getFieldValue("billingCity");
        values["province"] = form.getFieldValue("billingProvince");
        values["postalCode"] = form.getFieldValue("billingPostalCode");
      }
      setOrderShippingAddressMutation({
        variables: {
          input: values,
          orderId: cid,
        },
      })
        .then(({ data }) => {
          if (data?.setOrderShippingAddress.__typename === "Order") {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const customFieldsSetting = () => {
    const customFields = {
      source: form.getFieldValue("source"),
      orderClassification: form.getFieldValue("orderClassification"),
      paymentType: form.getFieldValue("paymentType"),
      futureShipDate: form.getFieldValue("futureShipDate"),
      pickupLocation: form.getFieldValue("pickupLocation"),
      defaultShipping: form.getFieldValue("defaultShipping"),
      shippingMethod: Number(shipM),
    } as any;
    if (order?.customFields?.paid === false) {
      customFields.paid = true;
    }
    setOrderCustomFieldsMutation({
      variables: {
        input: {
          id: cid,
          customFields: customFields,
        },
      },
      fetchPolicy: "network-only",
      refetchQueries: ["getOrder"],
    }).then(() => {
      allCompleted();
    });
  };

  const sameAddressHandler = (e: any) => {
    if (typeof e === "boolean") {
      setSameAddressChecked(e);
    } else {
      setSameAddressChecked(e.target.checked);
    }
    if ((typeof e !== "boolean" && e.target.checked) || e) {
      setHelpers((prevState) => ({
        ...prevState,
        disableInputField: true,
        shippingStreetAddress: helpers.billingStreetAddress,
      }));
      form.setFieldsValue({
        shippingFirstName: form.getFieldValue("billingFirstName"),
        shippingLastName: form.getFieldValue("billingLastName"),
        shippingEmail: form.getFieldValue("billingEmail"),
        shippingPhoneNumber: form.getFieldValue("billingPhoneNumber"),
        shippingCompany: form.getFieldValue("billingCompany"),
        shippingDOB: form.getFieldValue("billingDOB"),
        shippingStreetLine1: form.getFieldValue("billingStreetLine1") || "",
        shippingStreetLine2: form.getFieldValue("billingStreetLine2"),
        shippingCity: form.getFieldValue("billingCity"),
        shippingProvince: form.getFieldValue("billingProvince"),
        shippingCountryCode: form.getFieldValue("billingCountryCode"),
        shippingPostalCode: form.getFieldValue("billingPostalCode"),
      });
      setReplicableStreetLine1(form.getFieldValue("billingStreetLine1"));
      if (form.getFieldValue("shippingProvince")) {
        setIntermediateState(form.getFieldValue("shippingProvince"));
        getShippingMethodsByState(form.getFieldValue("shippingProvince"));
        form.resetFields(["shippingMethod"]);
      }
    }
  };

  const shippingRadioChange = (e: any) => {
    setShipping(e.target.value);
    if (e.target.value) {
      setFormData({
        pickupLocation: null,
      });
      sameAddressHandler(false);
      setPickUp(false);
    } else {
      setFormData({
        shippingMethod: null,
      });
      sameAddressHandler(true);
      setPickUp(true);
    }
  };

  const addressChangeHandler = (address: Suggestion) => {
    form.setFieldsValue({
      billingStreetLine1: address.street_line,
      billingStreetLine2: address.secondary,
      billingCity: address.city,
      billingProvince: address.state,
      billingPostalCode: address.zipcode,
    });
  };

  const shippingAddressChangeHandler = (address: Suggestion | undefined) => {
    form.setFieldsValue({
      shippingStreetLine1: address?.street_line,
      shippingStreetLine2: address?.secondary,
      shippingCity: address?.city,
      shippingProvince: address?.state,
      shippingPostalCode: address?.zipcode,
    });
    if (address?.state) {
      getShippingMethodsByState(address.state, 1);
    }
  };

  const cardToggle = (event: any) => {
    if (event === 1) {
      setShowCard(true);
    } else {
      setShowCard(false);
    }
  };

  const setEmail = (e: any) => {
    if (e.target.value) {
      setFormData({
        billingEmail: e.target.value,
      });
      setCardEmail(e.target.value);
    }
  };

  const deleteOrderLine = (id: string, quantity: number) => {
    removeItemFromOrderMutation({
      variables: {
        orderId: order?.id!,
        orderLineId: id,
      },
    })
      .then((data) => {
        if (data.data?.removeOrderLine) {
          const state = localStorage.getItem("province");
          if (state) {
            getShippingMethodsByState(state, 1);
            message.success("Order Item(s) successfully deleted");
            dispatch<any>(setOrder(data.data.removeOrderLine));
          }
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const checkIfTWF = (e: any, flag: number) => {
    if (flag === 0) {
      setShipM(e);
    } else {
      setPickM(e);
    }
  };

  const filterShipping = (shippingM: any) => {
    return shippingM.customFields.enabled === true;
  };

  const getShippingMethodsByState = (
    myState: string,
    another = 0,
    newOrder?: Order
  ) => {
    let myOrder = order as Order;

    if (newOrder) {
      myOrder = newOrder as Order;
    }

    if (another === 1) {
      form.resetFields(["shippingMethod"]);
    }

    saveShippingInfo().then(() => {
      shippingMethodsFetch(myOrder);
    });
  };

  const getShippingTypes = (value: string) => {
    setIntermediateState(value);
    getShippingMethodsByState(value, 1);
  };

  if (!orderResult?.order) {
    return <>loading...</>;
  }

  return (
    <Container maxWidth="1500px">
      <Row justify="space-between" gutter={[20, 20]}>
        <Col lg={{ span: 12 }} span={24}>
          <Row>
            <Col>
              <Row align="middle" gutter={12}>
                <Col>
                  <BackButton />
                </Col>
                <Col>
                  <H2>Edit Order</H2>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={24} />
            </Col>
          </Row>
        </Col>
        <Col lg={{ span: 12 }} span={24}>
          <Row gutter={24} justify={breakpoints.md ? "end" : "start"}>
            <Col>
              <TwfButton
                size-twf="lg"
                color-twf="primary"
                type="primary"
                style={{ width: 150 }}
                onClick={() => {
                  if (
                    order?.state !== "AddingItems" &&
                    order?.state !== "ArrangingPayment"
                  ) {
                    message.error(
                      "Product(s) cannot be updated when order is in state of " +
                        order?.state
                    );
                  } else {
                    setProductVisible(true);
                  }
                }}
              >
                {breakpoints.md && (
                  <Icon keyword="plus" color="#ffffff" width={24} />
                )}{" "}
                Add Products
              </TwfButton>
            </Col>
            {breakpoints.md && (
              <Col>
                <TwfButton
                  color-twf="secondary"
                  type="primary"
                  size-twf="md"
                  style={{ padding: "0 31px" }}
                >
                  Reset
                </TwfButton>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <MarginBox mt={breakpoints.md ? 69 : 20}>
        <Form
          form={form}
          onFinish={onFinishHandler}
          onValuesChange={(values) => form.setFieldsValue(values)}
        >
          <Row gutter={[30, 30]}>
            <Col span={24} xl={18}>
              <BlockTitle>Billing Information</BlockTitle>
              <Row gutter={[30, 30]}>
                <Col lg={{ span: 12 }} md={{ span: 24 }} span={24}>
                  <Row gutter={17}>
                    <Col sm={12} span={24}>
                      <Item
                        name="billingFirstName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter first name!",
                          },
                        ]}
                      >
                        <Input placeholder="First Name" />
                      </Item>
                    </Col>
                    <Col sm={12} span={24}>
                      <Item
                        name="billingLastName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter last name!",
                          },
                        ]}
                      >
                        <Input placeholder="Last Name" />
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col sm={12} span={24}>
                      <Item name="billingPhoneNumber">
                        <Input placeholder="Phone" />
                      </Item>
                    </Col>
                    <Col sm={12} span={24}>
                      <Item name="billingEmail">
                        <Input onChange={setEmail} placeholder="Email" />
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} span={24}>
                      <Item name="billingCompany">
                        <Input placeholder="Company" />
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Item
                        name="billingDOB"
                        rules={[
                          {
                            required: true,
                            message: "Please enter date of birth!",
                          },
                        ]}
                      >
                        <Input
                          type="date"
                          id="date_of_birth_alt"
                          name="date_of_birth_alt"
                          min={minDate.toISOString().substring(0, 10)}
                          max={maxDate.toISOString().substring(0, 10)}
                          autoComplete="bday"
                          required
                          placeholder="DOB (MM/DD/YYYY)"
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {/*<Item name="billingStreetLine1">
                        <SmartyAutoComplete
                          customerStreetAddress1={
                            order?.billingAddress?.streetLine1
                          }
                          selectedData={addressChangeHandler}
                        />
                        </Item>*/}
                      <Item
                        name="billingStreetLine1"
                        rules={[
                          {
                            required: true,
                            message: "street address is required!",
                            validator: async (_, value) => {
                              if (!value || value.length) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject();
                              }
                            },
                          },
                        ]}
                      >
                        <SmartyAutoComplete
                          customerStreetAddress1={helpers.billingStreetAddress}
                          selectedData={addressChangeHandler}
                          onChangeValue={(value: string) =>
                            setHelpers((prevState) => ({
                              ...prevState,
                              billingStreetAddress: value,
                            }))
                          }
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Item name="billingStreetLine2">
                        <Input placeholder="Address 2" />
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col sm={12} span={24}>
                      <Item
                        name="billingCity"
                        rules={[
                          {
                            required: true,
                            message: "Please enter city!",
                          },
                        ]}
                      >
                        <Input placeholder="City" />
                      </Item>
                    </Col>
                    <Col sm={12} span={24}>
                      <USStateSelector
                        name="billingProvince"
                        rules={[
                          {
                            required: true,
                            message: "Please enter state!",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col sm={12} span={24}>
                      <Item name="billingCountryCode">
                        <Input placeholder="Country" readOnly />
                      </Item>
                    </Col>
                    <Col sm={12} span={24}>
                      <Item
                        name="billingPostalCode"
                        rules={[
                          {
                            required: true,
                            message: "Please enter zip!",
                          },
                        ]}
                      >
                        <Input placeholder="Zip" />
                      </Item>
                    </Col>
                  </Row>
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} span={24}>
                  <Row gutter={17}>
                    <Col sm={12} span={24}>
                      <SelectTitle>Source</SelectTitle>
                      <Item name="source">
                        <Select
                          placeholder="Select"
                          allowClear
                          suffixIcon={
                            <Icon keyword="chevron-down" color="#7D8592" />
                          }
                          options={sources}
                        ></Select>
                      </Item>
                    </Col>
                    <Col sm={12} span={24}>
                      <SelectTitle>Order Classification</SelectTitle>
                      <Item name="orderClassification">
                        <Select
                          placeholder="Select"
                          onChange={() => {}}
                          allowClear
                          //disabled={true}
                          suffixIcon={
                            <Icon keyword="chevron-down" color="#7D8592" />
                          }
                          options={orderClassifications}
                        ></Select>
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col sm={12} span={24}>
                      <SelectTitle>Payment Type</SelectTitle>
                      <Item
                        name="paymentType"
                        rules={[
                          {
                            required: true,
                            message: "Please select payment type!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select"
                          onChange={cardToggle}
                          allowClear
                          suffixIcon={
                            <Icon keyword="chevron-down" color="#7D8592" />
                          }
                          options={paymentTypes}
                        ></Select>
                      </Item>
                    </Col>
                    {/*
                    <Col sm={12} span={24}>
                      <SelectTitle>Shipping Options</SelectTitle>
                      <Item name="shippingOptions">
                        <Select
                          placeholder="Select"
                          onChange={() => {}}
                          allowClear
                          suffixIcon={
                            <Icon keyword="chevron-down" color="#7D8592" />
                          }
                          options={shippingOptions}
                        ></Select>
                      </Item>
                    </Col>
                        */}
                  </Row>
                  <Row gutter={17}>
                    <Col sm={12} span={24}>
                      <SelectTitle>Future Ship Date</SelectTitle>
                      <Item name="futureShipDate">
                        <DatePicker
                          disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current < moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col span={24}>
                      <SelectTitle>Gift Message</SelectTitle>
                      <Item name="giftMessage">
                        <TextArea placeholder="Gift Message" rows={5} />
                      </Item>
                    </Col>
                  </Row>

                  <Row gutter={17}>
                    <Col span={12}>
                      {showCard && (
                        <>
                          <SelectTitle>Credit Card Information</SelectTitle>
                          <Item
                            name="creditCardInformation"
                            rules={[
                              {
                                required: true,
                                message: "Please choose the credit card",
                              },
                            ]}
                          >
                            <Select
                              suffixIcon={
                                <Icon keyword="chevron-down" color="#7D8592" />
                              }
                              placeholder="Select"
                              allowClear
                              options={cardOptions}
                              onChange={cardChangeHandler}
                            ></Select>
                          </Item>
                        </>
                      )}
                    </Col>
                  </Row>

                  {showCardForm && (
                    <Row>
                      <Col span={24}>
                        <Item name="nameOnCard">
                          <Input placeholder="Name on Card" />
                        </Item>
                      </Col>
                      <Col span={24}>
                        <CardNumberItem name="cardNumber">
                          <CardNumberElement options={options} />
                        </CardNumberItem>
                      </Col>
                      <Col span={12}>
                        <CardExpiryItem name="expiryDate">
                          <CardExpiryElement options={options} />
                        </CardExpiryItem>
                      </Col>
                      <Col span={12}>
                        <CardCvcItem name="cvs">
                          <CardCvcElement options={options} />
                        </CardCvcItem>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col lg={{ span: 12 }} span={24}>
                  <BlockTitle hidden={pickUp}>Shipping Information</BlockTitle>
                  <Row>
                    <Col span={24}>
                      <Item hidden={pickUp}>
                        <Checkbox onChange={sameAddressHandler}>
                          Same as billing information
                        </Checkbox>
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} span={24}>
                      <Item name="shippingCompany" hidden={pickUp}>
                        <Input
                          disabled={sameAddressChecked}
                          placeholder="Company"
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Row gutter={17}>
                        <Col md={12} span={24}>
                          <Item
                            hidden={pickUp}
                            name="shippingFirstName"
                            rules={[
                              {
                                required: !pickUp,
                                message: "Please enter first name!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="First Name"
                              disabled={sameAddressChecked}
                            />
                          </Item>
                        </Col>
                        <Col md={12} span={24}>
                          <Item
                            hidden={pickUp}
                            name="shippingLastName"
                            rules={[
                              {
                                required: !pickUp,
                                message: "Please enter last name!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Last Name"
                              disabled={sameAddressChecked}
                            />
                          </Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row gutter={17}>
                    <Col sm={12} span={24}>
                      <Item name="shippingPhoneNumber" hidden={pickUp}>
                        <Input
                          placeholder="Phone"
                          disabled={sameAddressChecked}
                        />
                      </Item>
                    </Col>
                    <Col sm={12} span={24}>
                      <Item name="shippingEmail" hidden={pickUp}>
                        <Input
                          placeholder="Email"
                          disabled={sameAddressChecked}
                        />
                      </Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Item name="shippingDOB" hidden={pickUp}>
                        <Input
                          type="date"
                          id="date_of_birth_alt"
                          name="date_of_birth_alt"
                          min={minDate.toISOString().substring(0, 10)}
                          max={maxDate.toISOString().substring(0, 10)}
                          autoComplete="bday"
                          placeholder="DOB (MM/DD/YYYY)"
                          disabled={sameAddressChecked}
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {/*<Item
                        hidden={pickUp}
                        name="shippingStreetLine1"
                        rules={[
                          {
                            required: !pickUp,
                            message: "Please enter address!",
                          },
                        ]}
                      >
                        <SmartyAutoComplete
                          disabled={sameAddressChecked}
                          key={smartyKey}
                          customerStreetAddress1={
                            replicableStreetLine1 ||
                            order?.shippingAddress?.streetLine1
                          }
                          selectedData={shippingAddressChangeHandler}
                        />
                        </Item>*/}

                      <Item
                        hidden={pickUp}
                        name="shippingStreetLine1"
                        rules={[
                          {
                            required: true,
                            message: "street address is required!",
                            validator: async (_, value) => {
                              if (!value || value.length) {
                                return Promise.resolve(value);
                              } else {
                                return Promise.reject();
                              }
                            },
                          },
                        ]}
                      >
                        <SmartyAutoComplete
                          disabled={helpers?.disableInputField}
                          customerStreetAddress1={helpers.shippingStreetAddress}
                          selectedData={shippingAddressChangeHandler}
                          onChangeValue={(value: string) =>
                            setHelpers((prevState) => ({
                              ...prevState,
                              shippingStreetAddress: value,
                            }))
                          }
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Item name="shippingStreetLine2" hidden={pickUp}>
                        <Input
                          placeholder="Address 2"
                          disabled={sameAddressChecked}
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col md={12} span={24}>
                      <Item
                        hidden={pickUp}
                        name="shippingCity"
                        rules={[
                          {
                            required: !pickUp,
                            message: "Please enter city!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="City"
                          disabled={sameAddressChecked}
                        />
                      </Item>
                    </Col>
                    <Col md={12} span={24}>
                      <USStateSelector
                        hidden={pickUp}
                        name="shippingProvince"
                        rules={[
                          {
                            required: !pickUp,
                            message: "Please enter state!",
                          },
                        ]}
                        selectProps={{
                          disabled: sameAddressChecked,
                          onChange: (value) => {
                            if (value) {
                              getShippingTypes(value.toString());
                            }
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={17}>
                    <Col md={12} span={24}>
                      <Item name="shippingCountryCode" hidden={pickUp}>
                        <Input
                          placeholder="Country"
                          readOnly
                          disabled={sameAddressChecked}
                        />
                      </Item>
                    </Col>
                    <Col md={12} span={24}>
                      <Item
                        name="shippingPostalCode"
                        hidden={pickUp}
                        rules={[
                          {
                            required: !pickUp,
                            message: "Please enter zip!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Zip"
                          disabled={sameAddressChecked}
                        />
                      </Item>
                    </Col>
                  </Row>
                </Col>
                <Col lg={{ span: 12 }} span={24}>
                  <BlockTitle>Pickup / Shipping Method</BlockTitle>
                  <Item
                    name="defaultShipping"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Shipping/Pickup Details",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={shippingRadioChange}
                      value={shipping}
                    >
                      <Radio value={true}>Shipping</Radio>
                      <Radio value={false}>Pickup</Radio>
                    </Radio.Group>
                  </Item>

                  {shipping && (
                    <Item
                      name="shippingMethod"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Shipping/Pickup Details",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        allowClear
                        onChange={(e) => {
                          checkIfTWF(e, 0);
                        }}
                        suffixIcon={
                          <Icon keyword="chevron-down" color="#7D8592" />
                        }
                        options={shippingmethods}
                      ></Select>
                    </Item>
                  )}

                  {!shipping && (
                    <Item
                      name="pickupLocation"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Shipping/Pickup Details",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        allowClear
                        suffixIcon={
                          <Icon keyword="chevron-down" color="#7D8592" />
                        }
                        options={pickupLocations}
                        onChange={(e) => {
                          checkIfTWF(e, 1);
                        }}
                      ></Select>
                    </Item>
                  )}

                  <MarginBox mt={24}>
                    <BlockTitle>Special Instructions</BlockTitle>
                  </MarginBox>
                  <Item name="specialInstructions">
                    <TextArea placeholder="Special Instructions" rows={3} />
                  </Item>
                </Col>
              </Row>
            </Col>
            <Col xl={6} span={24}>
              <div
                style={{
                  height: "100%",
                  backgroundColor: "#ffffff",
                  borderRadius: 12,
                  padding: "23px 26px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    gridGap: 24,
                  }}
                >
                  <div>
                    <BlockTitle>Order Summary</BlockTitle>
                    <DiscountApply
                      doDiscount={doDiscount}
                      makeDiscount={makeDiscount}
                    />
                    <MarginBox mt={30}>
                      <PriceTable>
                        <tbody>
                          {orderResult?.order?.totalQuantity !== 0 &&
                            orderResult?.order?.lines?.map((line: any) => (
                              <tr key={line.id}>
                                <LineDetail>
                                  {line.quantity} x{" "}
                                  {line.productVariant?.product?.name}{" "}
                                  {line.productVariant?.name}
                                </LineDetail>
                                <LineValue>
                                  ${(line.linePrice / 100).toFixed(2)}
                                  <TwfButton
                                    shape="circle"
                                    type-twf="icon"
                                    onClick={() =>
                                      deleteOrderLine(line.id, line.quantity)
                                    }
                                    hidden={
                                      order?.state !== "AddingItems" &&
                                      order?.state !== "ArrangingPayment"
                                    }
                                  >
                                    <Icon keyword="delete" color="#FF4C61" />
                                  </TwfButton>
                                </LineValue>
                              </tr>
                            ))}
                        </tbody>
                      </PriceTable>
                    </MarginBox>
                  </div>
                  <PricingTable order={order} />
                </div>
              </div>
            </Col>
          </Row>
          <MarginBox mt={24}>
            <Row gutter={[30, 30]}>
              <Col lg={{ span: 18 }} span={24} />
              <Col lg={{ span: 6 }} span={24}>
                <TwfButton
                  color-twf="primary"
                  type="primary"
                  size-twf="lg"
                  type-twf="full"
                  htmlType="submit"
                  loading={showReviewButton}
                >
                  Review
                </TwfButton>
              </Col>
            </Row>
          </MarginBox>
        </Form>
      </MarginBox>
      {!breakpoints.md && <CreateMobileFooter />}
      <CreateOrderProducts
        productVisible={productVisible}
        setProductVisible={setProductVisible}
        activeOrderId={order?.id!}
        getShippingMethodsByState={getShippingMethodsByState}
      />
      <CreateOrderReview
        order={{ ...form.getFieldsValue(), ...orderResult?.order }}
        newOrder={false}
        visible={reviewVisible}
        shippingMethods={shippingmethods}
        setVisible={setReviewVisible}
        action={submitOrder}
        loading={loading}
      />
    </Container>
  );
};

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "16px",
          color: "#333",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          boxSizing: "border-box",
          "::placeholder": {
            color: "#aaa",
          },
        },
        invalid: {
          color: "#eb1c26",
        },
      },
    }),
    []
  );

  return options;
};

export default EditOrder;

const CardItem = styled(Item)`
  border: 1px solid rgba(60, 66, 87, 0.12);
  height: 40px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
  border-radius: 14px;
  margin-bottom: 0;
  box-sizing: border-box;
  padding: 3px 10px;
`;

const CardNumberItem = styled(CardItem)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
`;

const CardExpiryItem = styled(CardItem)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const CardCvcItem = styled(CardItem)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
`;
