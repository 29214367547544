import { Checkbox, Col, Form, Input, Row } from "antd";
import { FC } from "react";
import TwfButton from "../../../component/button";

const { Item } = Form;

interface AddressFormProps {
  cancelHandler: () => void;
  makePrimaryHandler: (event: any) => void;
}

const AddressForm: FC<AddressFormProps> = ({
  cancelHandler,
  makePrimaryHandler,
}) => {
  const now = new Date();
  const minDate = new Date(
    now.getFullYear() - 130,
    now.getMonth(),
    now.getDate()
  );
  const maxDate = new Date(
    now.getFullYear() - 21,
    now.getMonth(),
    now.getDate()
  );
  return (
    <Row gutter={24}>
      <Col span={24}>
        <Item label="Nick Name" name="nickName">
          <Input placeholder="Nick Name" />
        </Item>
      </Col>
      <Col span={12}>
        <Item label="First Name" name="firstname">
          <Input placeholder="First Name" />
        </Item>
      </Col>
      <Col span={12}>
        <Item label="Last Name" name="lastname">
          <Input placeholder="Last Name" />
        </Item>
      </Col>
      <Col span={12}>
        <Item label="Phone" name="phone">
          <Input placeholder="Phone" />
        </Item>
      </Col>
      <Col span={12}>
        <Item label="Email" name="email">
          <Input placeholder="Email" />
        </Item>
      </Col>
      <Col span={24}>
        <Item label="DOB" name="dob">
          <Input
            type="date"
            id="date_of_birth_alt"
            name="date_of_birth_alt"
            min={minDate.toISOString().substring(0, 10)}
            max={maxDate.toISOString().substring(0, 10)}
            autoComplete="bday"
            required
            placeholder="DOB (MM/DD/YYYY)"
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item label="Address" name="address1">
          <Input placeholder="Address1" />
        </Item>
        <Item name="address1">
          <Input placeholder="Address2" />
        </Item>
      </Col>
      <Col span={24}>
        <Item label="City">
          <Input placeholder="City" />
        </Item>
      </Col>
      <Col span={24}>
        <Item label="State" name="state">
          <Input placeholder="State" />
        </Item>
      </Col>
      <Col span={24}>
        <Item label="Country" name="country">
          <Input placeholder="Country" />
        </Item>
      </Col>
      <Col span={24}>
        <Item label="Zip" name="zip">
          <Input placeholder="Zip" />
        </Item>
      </Col>
      <Col span={24}>
        <Item>
          <Checkbox onChange={makePrimaryHandler}>Primary Address</Checkbox>
        </Item>
      </Col>
      <Col span={24}>
        <Row justify="end" align="middle" gutter={12}>
          <Col>
            <TwfButton
              size-twf="md"
              type="primary"
              ghost
              type-twf="wide"
              onClick={cancelHandler}
            >
              Cancel
            </TwfButton>
          </Col>
          <Col>
            <TwfButton
              size-twf="md"
              type="primary"
              type-twf="wide"
              htmlType="submit"
            >
              Add
            </TwfButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AddressForm;
