import { FC, useState } from "react";
import { SketchPicker } from "react-color";
import styled from "styled-components";

interface ColorPickerProps {
  color?: string;
  onChange: (color: string) => void;
}

const ColorPicker: FC<ColorPickerProps> = ({ color, onChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  return (
    <div>
      <ColorWatch onClick={() => setDisplayColorPicker(!displayColorPicker)}>
        <Color color={color} />
      </ColorWatch>
      {displayColorPicker ? (
        <Popover>
          <Cover onClick={() => setDisplayColorPicker(false)} />
          <SketchPicker
            color={color}
            onChange={(color: any) => onChange(color?.hex)}
          />
        </Popover>
      ) : null}
    </div>
  );
};

interface Props {
  color?: string;
}

const Color = styled.div<Props>`
  width: 36px;
  height: 14px;
  border-radius: 2px;
  background: ${(props) => props.color};
`;

const ColorWatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`;

const Popover = styled.div`
  position: absolute;
  z-index: 2;
`;

const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export default ColorPicker;
