import styled from "styled-components";

const AppBar = styled.div`
  position: fixed;
  width: 100%;
  height: 87px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 10;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  padding: 10px 35px;
  display: grid;
  align-items: center;
  div {
    text-align: center;
  }
  p {
    font-size: 9px;
    font-weight: 700;
    color: #979797;
  }
`;

export default AppBar;
