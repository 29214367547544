import { gql } from "@apollo/client";

export const TAG_FRAGMENT = gql`
  fragment Tag on Tag {
    id
    createdAt
    updatedAt
    value
  }
`;


export const ASSET_FRAGMENT = gql`
  fragment Asset on Asset {
    id
    createdAt
    updatedAt
    width
    height
    name
    type
    preview
    source
    fileSize
    mimeType
    tags {
      ...Tag
    }
    focalPoint {
      x
      y
    }
  }
  ${TAG_FRAGMENT}
`;

export const CART_FRAGMENT = gql`
  fragment Cart on Order {
    id
    code
    state
    active
    lines {
      id
      featuredAsset {
        ...Asset
      }
      unitPrice
      unitPriceWithTax
      quantity
      linePriceWithTax
      discountedLinePriceWithTax
      productVariant {
        id
        name
      }
      discounts {
        amount
        amountWithTax
        description
        adjustmentSource
        type
      }
    }
    totalQuantity
    subTotal
    subTotalWithTax
    total
    totalWithTax
    shipping
    shippingWithTax
    shippingLines {
      priceWithTax
      shippingMethod {
        id
        code
        name
        description
      }
    }
    discounts {
      amount
      amountWithTax
      description
      adjustmentSource
      type
    }
  }
  ${ASSET_FRAGMENT}
`;

export const ORDER_ADDRESS_FRAGMENT = gql`
  fragment OrderAddress on OrderAddress {
    fullName
    company
    streetLine1
    streetLine2
    city
    province
    postalCode
    country
    phoneNumber
    customFields{
      nickName
      firstName
      lastName
      email
      dob
    }
  }
`;

export const ADDRESS_FRAGMENT = gql`
  fragment Address on Address {
    id
    fullName
    company
    streetLine1
    streetLine2
    city
    province
    postalCode
    country {
      id
      code
      name
    }
    phoneNumber
    defaultShippingAddress
    defaultBillingAddress
    customFields {
      nickName
      firstName
      lastName
      email
      dob
    }
  }
`;

export const ERROR_RESULT_FRAGMENT = gql`
  fragment ErrorResult on ErrorResult {
    errorCode
    message
  }
`;

export const CUSTOMER_FRAGMENT = gql`
  fragment Customer on Customer {
    id
    createdAt
    updatedAt
    title
    firstName
    lastName
    phoneNumber
    emailAddress
    user {
      id
      identifier
      verified
      lastLogin
    }
    addresses {
      ...Address
    }
    customFields {
      dob
      address1
      address2
      city
      state
      country
      zip
      customerType
      status
      taxExemption
      isTradeUser
      tradeAccountNumber
      nameOnCard
      cardNumber
      expiryDate
      cvs
      billingAddress1
      billingAddress2
      billingCity
      billingState
      billingCountry
      billingZip
      isMarkedAsDefault
      archived
      isTradeUser
      stripeId
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const COUNTRY_FRAGMENT = gql`
  fragment Country on Country {
    id
    createdAt
    updatedAt
    code
    name
    enabled
    translations {
      id
      languageCode
      name
    }
  }
`;

export const ZONE_FRAGMENT = gql`
  fragment Zone on Zone {
    id
    name
    members {
      ...Country
    }
  }
  ${COUNTRY_FRAGMENT}
`;

export const CHANNEL_FRAGMENT = gql`
  fragment Channel on Channel {
    id
    createdAt
    updatedAt
    code
    token
    defaultLanguageCode
    currencyCode
    pricesIncludeTax
    customFields {
      firstName
      lastName
      email
      phone
      address
      city
      state
      zip
      membershipLevel
      logo
      favicon
      brandName
      legalEntityName
      headerColor
      headerTextColor
      mainBodyColor
      footerColor
      footerTextColor
      productBorderColor
      productCardColor
      productTextColor
      buttonBackgroundColor
      buttonTextColor
      fontColor
      fontFamily
      brandPhone
      webmasterEmail
      orderConfirmation
      clubConfirmation
      orderPrefix
      shipCompliantKey
      instagram
      facebook
      linkedIn
      twitter
      pinterest
      youtube
      tiktok
      others      
      archived
      complianceBrandKey
      mainWebsite
      isOldStore
      policy
      advisoryMessage
    }
  }
`;

export const ADMINISTRATOR_FRAGMENT = gql`
  fragment Administrator on Administrator {
    id
    createdAt
    updatedAt
    firstName
    lastName
    emailAddress
    user {
      id
      identifier
      lastLogin
      roles{
        id
        code
        permissions
      }
    }
    customFields{
      isStoreAdmin
    }
  }
`;

export const PRODUCT_VARIANT_CUSTOM_FIELDS_FRAGMENT = gql`
   fragment ProductVariantCustomFields on ProductVariantCustomFields {
    alcohol
    teaser
    description
    status
    retailPrice
    preferredPrice
    unit
    weight
    saleChannel
    wineCategory
    exemptions
    startDate
    endDate
    upcCode
    bottleInCase
    shipComplianceVerified
    productBadge {
      id
      createdAt
      updatedAt
      width
      height
      name
      type
      preview
      source
      fileSize
      mimeType
      focalPoint {
        x
        y
      }
    }
    wineType
    varietal
    region
    appellation
    acidLevel
    phLevel
    sugarLevel
    residualSugarLevel
    bottlingDate
    harvestDate
    vineyardDestination
    aging
    vineyardNotes
    testingNotes
    otherNotes
    foodPairing
    isOutOfStock
    outOfStockMsg
    displayOrder
   }
`;

export const PRODUCT_VARIANT_FRAGMENT = gql`
  fragment ProductVariant on ProductVariant {
    id
    enabled
    updatedAt
    languageCode
    name
    currencyCode
    price
    priceWithTax
    stockOnHand
    stockAllocated
    outOfStockThreshold
    stockLevel
    trackInventory
    productId
    taxRateApplied {
      id
      name
      value
    }
    taxCategory {
      id
      name
    }
    sku
    options {
      id
      code
      languageCode
      name
    }
    product {
      id
      name
    }
    facetValues {
      id
      code
      name
      facet {
        id
        name
      }
    }
    featuredAsset {
      ...Asset
    }
    assets {
      ...Asset
    }
    translations {
      id
      languageCode
      name
    }
    channels {
      id
      code
    }
    customFields {
      ...ProductVariantCustomFields
    }
  }
  ${PRODUCT_VARIANT_CUSTOM_FIELDS_FRAGMENT}
  ${ASSET_FRAGMENT}
`;

export const PRODUCT_CUSTOM_FIELDS_FRAGMENT = gql`
  fragment ProductCustomFields on ProductCustomFields {
    productType
    saleChannel
    productUrl
    status
    bottlingDate
    harvestDate
    wineCategory
    varietal
    appellation
    wineType
    label
  }
`;

export const PRODUCT_WITH_VARIANTS_FRAGMENT = gql`
  fragment ProductWithVariants on Product {
    id
    enabled
    languageCode
    name
    slug
    description
    featuredAsset {
      ...Asset
    }
    assets {
      ...Asset
    }
    translations {
      languageCode
      name
      slug
      description
    }
    optionGroups {
      name
      code
      options {
        id
        code
        name
      }
    }
    variants {
      ...ProductVariant
    }
    customFields {
      ...ProductCustomFields
    }
    facetValues {
      id
      code
      name
      facet {
        id
        name
      }
    }
    channels {
      id
      code
    }
  }
  ${PRODUCT_CUSTOM_FIELDS_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
  ${ASSET_FRAGMENT}
`;

export const PRODUCT_OPTION_GROUP_FRAGMENT = gql`
  fragment ProductOptionGroup on ProductOptionGroup {
    id
    code
    name
    options {
      id
      code
      name
    }
    translations {
      id
      languageCode
      name
    }
  }
`;

export const PRODUCT_WITH_OPTIONS_FRAGMENT = gql`
  fragment ProductWithOptions on Product {
    id
    optionGroups {
      id
      code
      options {
        id
        code
      }
    }
  }
`;

export const ORDER_FRAGMENT = gql`
  fragment Order on Order {
    id
    createdAt
    updatedAt
    orderPlacedAt
    code
    state
    nextStates
    active
    payments{
      id
      method
      amount
    }
    customer {
      ...Customer
    }
    shippingAddress {
      ...OrderAddress
    }
    billingAddress {
      ...OrderAddress
    }
    lines {
      id
      createdAt
      updatedAt
      productVariant {
        id
        name
        sku
        product {
          name
        }
      }
      featuredAsset {
        id
        width
        height
        source
        type
      }
      unitPrice
      unitPriceWithTax
      unitPriceChangeSinceAdded
      unitPriceWithTaxChangeSinceAdded
      discountedUnitPrice
      discountedUnitPriceWithTax
      proratedUnitPrice
      proratedUnitPriceWithTax
      quantity
      items {
        id
        createdAt
        updatedAt
        cancelled
        unitPrice
        unitPriceWithTax
        discountedUnitPrice
        discountedUnitPriceWithTax
        proratedUnitPrice
        proratedUnitPriceWithTax
        unitTax
        taxRate
        adjustments {
          adjustmentSource
          type
          description
          amount
        }
        refundId
      }
      taxRate
      linePrice
      linePriceWithTax
      discountedLinePriceWithTax
      discountedLinePriceWithTax
      proratedLinePrice
      proratedLinePriceWithTax
      lineTax
      discounts {
        adjustmentSource
        type
        description
        amount
        amountWithTax
      }
      customFields {
        customPrice
      }
    }
    totalQuantity
    subTotal
    subTotalWithTax
    total
    totalWithTax
    channel{
      id
      customFields{
        legalEntityName
      }
    }
    customFields {
      source
      orderClassification
      paymentType
      shippingOptions
      creditCardInformation
      futureShipDate
      pickupLocation
      specialInstructions
      complianceStatus
      complianceresponse
      compliancetime
      paid
      archived
      oldId
      deleted
      shipTax
    }
  }
  ${CUSTOMER_FRAGMENT}
  ${ORDER_ADDRESS_FRAGMENT}
`;

export const PRODUCT_OPTION_FRAGMENT = gql`
  fragment ProductOption on ProductOption {
    id
    createdAt
    updatedAt
    code
    languageCode
    name
    groupId
    translations {
      id
      languageCode
      name
    }
  }
`;

export const CONFIGURABLE_OPERATION_DEF_FRAGMENT = gql`
  fragment ConfigurableOperationDef on ConfigurableOperationDefinition {
    args {
      name
      type
      required
      defaultValue
      list
      ui
      label
    }
    code
    description
  }
`;

export const CONFIGURABLE_OPERATION_FRAGMENT = gql`
  fragment ConfigurableOperation on ConfigurableOperation {
    args {
      name
      value
    }
    code
  }
`;

export const PROMOTION_FRAGMENT = gql`
  fragment Promotion on Promotion {
    id
    createdAt
    updatedAt
    name
    enabled
    couponCode
    perCustomerUsageLimit
    startsAt
    endsAt
    conditions {
      ...ConfigurableOperation
    }
    actions {
      ...ConfigurableOperation
    }
  }
  ${CONFIGURABLE_OPERATION_FRAGMENT}
`;

export const CUSTOMER_CLUB_FRAGMENT = gql`
  fragment CustomerClub on Customer {
    id
    firstName
    lastName
    emailAddress
  }
`;

export const SEASON_LIST_FRAGMENT = gql`
  fragment SeasonList on Season {
    id
    name
    displayOnWeb
    active
    shippingMethod {
      id
    }
    seasonProcess {
      status
      shipDate
    }
    seasonProductVariants {
      id
      productVariant {
        id
        name
        product {
          name
        }
      }
    }
  }
`;

export const SEASON_FRAGMENT = gql`
  fragment Season on Season {
    id
    name
    order
    minimumBottle
    maximumBottle
    teaser
    displayOnWeb
    active
    shippingMethod{
      id
    }
    seasonProcess {
      shipDate
      status
    }
    seasonProductVariants {
      id
      order
      price
      defaultBottle
      minimumBottle
      maximumBottle
      productVariant {
        id
        name
        priceWithTax
        product {
          name
        }
      }
    }
  }
`;

export const CLUB_FRAGMENT = gql`
  fragment Club on Club {
    id
    createdAt
    updatedAt
    name
    description
    slug
    order
    displayOnWeb
    active
    userChoice
    clubCustomers {
      id
      status
      customer {
        id
        emailAddress
        firstName
        lastName
      }
    }
    url
    marketingUrl
    titleMetaTag
    descriptionMetaTag
  }
`;

export const ROLE_FRAGMENT = gql`
  fragment Role on Role {
    id
    createdAt
    updatedAt
    code
    description
    permissions
    channels {
      id
      code
      token
    }
  }
`;

export const COMPLIANCE_LIST_ITEM_FRAGMENT = gql`
  fragment ComplianceListItem on Compliance {
    id
    status
    rules
    note
    state {
      name
    }
  }
`;

export const COMPLIANCE_FRAGMENT = gql`
  fragment Compliance on Compliance {
    id
    status
    state {
      id
      name
    }
    rules
    shippingAdvisory
    complianceAdvisory
    note
    edited
    holdDate
  }
`;

export const CARD_FRAGMENT = gql`
  fragment Card on Card {
    id
    last4
    type
    expYear
    expMonth
    paymentMethodId
    isDefault
    cardName
    stripeResponse
  }
`;


export const ORDER_DETAIL_FRAGMENT = gql`
    fragment OrderDetail on Order {
        id
        createdAt
        updatedAt
        code
        state
        nextStates
        couponCodes
        payments{
          id
          method
          amount
        }
        active
        customer {
            id
            firstName
            lastName
        }
        surcharges {
            id
            sku
            description
            price
            priceWithTax
            taxRate
        }
        promotions {
            id
            couponCode
        }
        subTotal
        subTotalWithTax
        total
        totalWithTax
        currencyCode
        shipping
        shippingWithTax
        shippingLines {
            shippingMethod {
                id
                code
                name
                fulfillmentHandlerCode
                description
            }
        }
        taxSummary {
            description
            taxBase
            taxRate
            taxTotal
        }
        shippingAddress {
            ...OrderAddress
        }
        billingAddress {
            ...OrderAddress
        }
        payments {
            id
            createdAt
            transactionId
            amount
            method
            state
            nextStates
            errorMessage
            metadata
            refunds {
                id
                createdAt
                state
                items
                adjustment
                total
                paymentId
                reason
                transactionId
                method
                metadata
                orderItems {
                    id
                }
            }
        }
        modifications {
            id
            createdAt
            isSettled
            priceChange
            note
            payment {
                id
                amount
            }
            orderItems {
                id
            }
            refund {
                id
                paymentId
                total
            }
            surcharges {
                id
            }
        }
        customFields{
          complianceStatus
        }
    }
    ${ORDER_ADDRESS_FRAGMENT}
`;

export const SHIPMENT_COMPLIANCE_DATA_FRAGMENT = gql`
  fragment ShipmentComplianceData on ShipmentComplianceData{
    BillTo
    CustomerKey
    FulfillmentType
    OrderType
    Payments
    PurchaseDate
    SalesOrderKey
    SalesTaxCollected
    Shipments
    Tags
  }
`;

export const SHIPMENT_COMPLIANCE_SALES_ORDER_RESULT_FRAGMENT = gql`
  fragment SalesOrderResult on SalesOrderResult {
    SalesOrder{
      ...ShipmentComplianceData 
    }
    AddressOption
  }
`;

export const SALES_ORDER_COMPLIANCE_RULES_FRAGMENT = gql`
fragment ComplianceRules on ComplianceRules{
  complianceDescription
  licenseRelationship
  ruleDescription
  ruleType
  complianceStatus
}
`

export const SALES_ORDER_COMPLIANCE_SHIPMENT_FRAGMENT = gql`
fragment ComplianceShipment on ComplianceShipment{
  complianceStatus
  key
  rules{
    ...ComplianceRules
  }
}
`;

export const SALES_ORDER_COMPLIANCE_CHECK_FRAGMENT = gql`
fragment SalesOrderComplianceCheck on SalesOrderComplianceCheck{
  complianceStatus
  key
  salesTaxRates
  shipments{
    ...ComplianceShipment
  }
}
`;

export const COMPLIANCE_QUOTE_DATA_FRAGMENT = gql`
   fragment ComplianceQuoteData on ComplianceQuoteData{
     salesOrder{
        ...SalesOrderComplianceCheck
     }
     responseStatus
     statusCode
   } 
`;

export const PRODUCT_IN_COMPLIANCE_FRAGMENT = gql`
  fragment ProductInComplianceResponse on ProductInComplianceResponse{
    age
    brandKey
    defaultCase
    defaultRetailUnitPrice
    defaultWholesaleCasePrice
    description
    flavor
    gtin
    label
    marketplaceSKUs
    nabca
    percentAlcohol
    productDistribution
    productKey
    productType
    scc
    unimerc
    unitPrice
    upc
    varietal
    vintage
    volumeAmount
    volumeUnit
    containerType
    containersPerSellingUnit
    brandName
    brandDescription
  }
`;

export const SHIPPING_METHOD_FRAGMENT = gql`
fragment ShippingMethod on ShippingMethod {
  id
  createdAt
  updatedAt
  code
  name
  description
  fulfillmentHandlerCode
  checker {
    ...ConfigurableOperation
  }
  calculator {
    ...ConfigurableOperation
  }
  translations {
    id
    languageCode
    name
    description
  }
  customFields{
    isdefault
    enabled
  }
 }
  ${CONFIGURABLE_OPERATION_FRAGMENT}
`;

export const PAYMENT_METHOD_FRAGMENT = gql`
fragment PaymentMethod on PaymentMethod{
  id
  name
  code
  description
  enabled
  customFields
}
`;

export const REFUND_ORDER_FRAGMENT = gql`
fragment Refund on Refund{
  id
  state
  items
  shipping
  adjustment
  transactionId
  paymentId
}
`;

export const EMAIL_TEMPLATE_FRAGMENT = gql`
fragment EmailTemplate on EmailTemplateResult{
  id
  name
  subject
  content
  category
  type
  active
  mjmltohtmlcontent
  isOld
}
`;

export const ANNOUNCEMENT_FRAGMENT = gql`
fragment Announcement on AnnouncementResult{
  id
  name
  content
  createdAt
  updatedAt
}
`;

export const ORDER_HISTORY_FRAGMENT = gql`
fragment HistoryEntry on HistoryEntry{
  isPublic
  administrator {
    ...Administrator
  }
  id
  createdAt
  updatedAt
  type
  data
  }
  ${ADMINISTRATOR_FRAGMENT}
`;

export const ORDER_LINE_FRAGMENT = gql`
fragment OrderLine on OrderLine{
  id
  createdAt
  updatedAt
  productVariant {
    id
    name
    sku
    product {
      name
      }
    }
    featuredAsset {
      id
      width
      height
      source
      type
    }
    unitPrice
    unitPriceWithTax
    unitPriceChangeSinceAdded
    unitPriceWithTaxChangeSinceAdded
    discountedUnitPrice
    discountedUnitPriceWithTax
    proratedUnitPrice
    proratedUnitPriceWithTax
    quantity
    items {
      id
      createdAt
      updatedAt
      cancelled
      unitPrice
      unitPriceWithTax
      discountedUnitPrice
      discountedUnitPriceWithTax
      proratedUnitPrice
      proratedUnitPriceWithTax
      unitTax
      taxRate
      adjustments {
        adjustmentSource
        type
        description
        amount
        }
        refundId
      }
      taxRate
      linePrice
      linePriceWithTax
      discountedLinePriceWithTax
      discountedLinePriceWithTax
      proratedLinePrice
      proratedLinePriceWithTax
      lineTax
      discounts {
        adjustmentSource
        type
        description
        amount
        amountWithTax
      }
      customFields {
        customPrice
      }
}
`;

export const PAGE_MENU_FRAGMENT = gql`
  fragment PageMenu on Page{
    id
    title
    parent_id
    order_no
    redirect
  }
`;

export const EMAIL_CATEGORY_FRAGMENT = gql`
  fragment EmailCategory on EmailCategory{
    id
    name
    slug
  }
`;

export const PICKUP_OPTION_FRAGMENT = gql`
  fragment PickupOption on PickupOption{
    id
    name
    label
    slug
    pickUpId
    enabled
    cost
  }
`;