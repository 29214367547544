export function sortBrands(arr:any) {
  if (!arr) {
    return;
  }
  
  arr.sort((a:any, b:any) => {
    if (a?.customFields?.brandName === "Guild Admin") {
      return -1; // "Guild Admin" comes before other brands
    }

    if (b?.customFields?.brandName === "Guild Admin") {
      return 1; // "Guild Admin" comes after other brands
    }

    return a?.customFields?.brandName.localeCompare(b.customFields.brandName); // Sort alphabetically
  });

  arr.forEach((obj:any) => {
    obj.key = obj.id;
  });

  return arr;
}
