import React, { useState, useEffect } from "react";
import {
  Col,
  Form,
  Grid,
  Input,
  Row,
  DatePicker,
  TimePicker,
  message,
  Checkbox,
} from "antd";
import { PageTitle } from "../../../../component/header";
import TwfButton from "../../../../component/button";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../../component/button/back";
import Conditions from "pages/marketing/components/discount/conditions";
import Actions from "pages/marketing/components/discount/actions";
import { MarginBox } from "component/margin";
import { useParams } from "react-router";
import {
  ConfigurableOperationInput,
  Promotion,
  PromotionFragment,
  useGetPromotionQuery,
  useUpdatePromotionMutation,
} from "generated/graphql";
import moment from "moment";

const { Item } = Form;
const { useBreakpoint } = Grid;

const EditDiscount: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const breakpoints = useBreakpoint();
  const { id } = useParams<{ id: string }>();
  const cid:string = id as string;
  const { data } = useGetPromotionQuery({ variables: { id:cid } });
  const [updatePromotionMutation, { loading }] = useUpdatePromotionMutation();

  const [conditions, setConditions] = useState<ConfigurableOperationInput[]>(
    []
  );
  const [actions, setActions] = useState<ConfigurableOperationInput[]>([]);

  useEffect(() => {
    if (data?.promotion) {
      form.setFieldsValue(preparePromotion(data.promotion));
      setConditions(prepareOperation(data.promotion, "condition"));
      setActions(prepareOperation(data.promotion, "action"));
    }
  }, [data, form]);


  const preparePromotion = (promotion: PromotionFragment) => {
    return {
      ...promotion,
      startsAt: promotion.startsAt ? moment(promotion.startsAt) : null,
      endsAt: promotion.endsAt ? moment(promotion.endsAt) : null,
    };
  };

  const prepareOperation = (promotion: PromotionFragment, type: string) => {
    const operation =
      type === "condition" ? promotion.conditions : promotion.actions;
    return (
      operation.map((operation) => (
        {
        code: operation.code,
        arguments: operation.args.map((arg) => ({
          name: arg.name,
          value: arg.value,
        })),
      })) || []
    );
  };

  const submitFormHandler = (value: Promotion) => {
    const formData = { ...value, id:cid, conditions, actions };
    if(formData?.perCustomerUsageLimit){
      formData["perCustomerUsageLimit"] = +formData["perCustomerUsageLimit"];
    }
    updatePromotionMutation({
      variables: { input: formData },
      refetchQueries:["promotions"]
    })
      .then(({ data }: any) => {
        if (data.updatePromotion.__typename === "Promotion")
          message.success("Discount updated");
      })
      .catch((error: any) => message.error(error.message));
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={submitFormHandler}
        initialValues={{ enabled: true }}
      >
        <Row justify="space-between" gutter={[20, 20]}>
          <Col>
            <Row>
              <Col>
                <Row gutter={12} align="middle">
                  <Col>
                    <BackButton />
                  </Col>
                  <Col>
                    <PageTitle>Edit Discount</PageTitle>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {breakpoints.md && (
            <Col>
              <Row gutter={24}>
                <Col>
                  <TwfButton
                    color-twf="gray-line"
                    size-twf="lg"
                    type-twf="wide"
                    onClick={() => {
                      history("/marketing/discounting");
                    }}
                  >
                    Cancel
                  </TwfButton>
                </Col>
                <Col>
                  <TwfButton
                    color-twf="primary"
                    type="primary"
                    size-twf="lg"
                    type-twf="wide"
                    htmlType="submit"
                    loading={loading}
                  >
                    Save
                  </TwfButton>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <MarginBox mt={breakpoints.md ? 69 : 32}>
          <Row gutter={32}>
            <Col lg={{ span: 10 }} span={24}>
              <Item name="name">
                <Input placeholder="Name" />
              </Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col lg={{ span: 5 }} span={12}>
              <Item name="startsAt">
                <DatePicker placeholder="Start Date" />
              </Item>
            </Col>
            <Col lg={{ span: 5 }} span={12}>
              <Item name="startsAt">
                <TimePicker placeholder="Start Time" />
              </Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col lg={{ span: 5 }} span={12}>
              <Item name="endsAt">
                <DatePicker placeholder="Expiry Date" />
              </Item>
            </Col>
            <Col lg={{ span: 5 }} span={12}>
              <Item name="endsAt">
                <TimePicker placeholder="Expiry Time" />
              </Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col lg={{ span: 10 }} span={24}>
              <Item 
               name="couponCode"
              >
                <Input placeholder="Coupon code" />
              </Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col lg={{ span: 10 }} span={24}>
              <Item name="perCustomerUsageLimit">
                <Input placeholder="Per-Customer Limit" type="number" />
              </Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col lg={{ span: 10 }} span={24}>
              <Item name="enabled" valuePropName="checked">
                <Checkbox>Enabled</Checkbox>
              </Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col lg={{ span: 12 }} span={24}>
              <Conditions
                conditions={conditions}
                onConditionsChange={setConditions}
              />
            </Col>
            <Col lg={{ span: 12 }} span={24}>
              <Actions actions={actions} onActionsChange={setActions} />
            </Col>
          </Row>
        </MarginBox>
      </Form>
    </div>
  );
};

export default EditDiscount;
