/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
//import { EmailCategories } from "enums/enums";
import { 
  useUpdateEmailTemplateMutation,
  useSendTestEmailMutation,
  useGetEmailTemplateQuery,
  useEmailCategoriesQuery,
  useEmailTokensLazyQuery
} from "generated/graphql";
import { Col, Form, Grid, Input, message, Row, Select } from "antd";
import { MarginBox } from "component/margin";
import Icon from "assets/icons";
import TwfButton from "component/button";
import BackButton from "component/button/back";
import { useParams } from "react-router-dom";
import HTMLEmailEditor from "../richTextEditor/richTextEditor";
import { useNavigate } from "react-router-dom";
//import MJMLEmailEditor from "../easyEmail";
import DefaultEmailEditor from "../mjmlEditor/mjmlEditor";

const { Item } = Form;
const { useBreakpoint } = Grid;

interface ComponentProps {
  
}

const UpdateEmailTemplate: React.FC<ComponentProps> = () => {

  const history = useNavigate();

  const [content, setContent] = useState<string>();

  const [tokens, setTokens] = useState<any[]>([]);

  const [htmlContent,setHtmlContent] = useState<string>();

  const [isHTML, setIsHTML] = useState<boolean>(false);

  const { id } = useParams<{ id: string }>();

  const [form] = Form.useForm();

  const cid = id as string;

  const { data } = useGetEmailTemplateQuery({
    variables:{
      id:cid
    },
    fetchPolicy:"network-only"
  })

  const [EmailCategories, setEmailCategories] = useState<any[]>([]);

  const { data: emailCategories } = useEmailCategoriesQuery({
    variables:{
      options:{}
    }
  });

  useEffect(()=>{
    if(emailCategories?.emailCategories){
     const options = emailCategories.emailCategories.items.map((emailCategory)=>{
      return{
        label: emailCategory.name,
        value: emailCategory.slug
      }
     }) 
     setEmailCategories(options);
    }
  },[emailCategories])

  const initializeForm = useCallback(
    (emailTemplate: any) => {
      fetchToken(emailTemplate.category);
      form.setFieldsValue({ ...emailTemplate });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  useEffect(()=>{
    if(data?.emailTemplate){
      setContent(data.emailTemplate.content);
      if(data.emailTemplate.type==="html"){
        setIsHTML(true);
      }else{
        setHtmlContent(data.emailTemplate.mjmltohtmlcontent)
      }
      initializeForm(data.emailTemplate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])

  const breakpoints = useBreakpoint();

  const [updateEmailTemplateMutation,{ loading }] = useUpdateEmailTemplateMutation();

  const [sendTestEmailMutation,{ loading: sendTestEmailLoading }] = useSendTestEmailMutation();


  const [testEmailAddress, setTestEmailAddress] = useState<string>("");

  const [getEmailTokens] = useEmailTokensLazyQuery({
    fetchPolicy:"network-only"
  });

  const fetchToken = (e: any) =>{
    getEmailTokens({
      variables:{
        category: e
      }
    }).then((data)=>{
      if(data?.data?.emailTokens){
        const theObjects = [];
        for ( let token of data.data.emailTokens ){
          const theObject = {
            label: token,
            key: token
          }
          theObjects.push(theObject);
        }
        setTokens(theObjects);
      }
    })
  }

  const updateEmailTemplate = (formData:any)=>{
    formData["id"] = data?.emailTemplate?.id;
    formData["content"] = content;
    /*if(isHTML){
      formData["mjmltohtmlcontent"] = null;
      formData["type"]="html"
    }else{
      formData["mjmltohtmlcontent"] = htmlContent;
      formData["type"]="mjml"
    }*/
    formData["type"] = "html";
    formData["mjmltohtmlcontent"] = null;
    updateEmailTemplateMutation({
      variables:{
        input: formData
      }
      ,fetchPolicy:"network-only"
      ,refetchQueries:["GetEmailTemplates","GetEmailTemplate"]
    }).then(()=>{
      message.success("Email Template Successfully Updated");
      setTimeout(()=>{
        history(0);
      },1000);
    }).catch((error)=>{
      message.error(error.message);
    })
  }

  const sendTestEmail = () => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(testEmailAddress) && data?.emailTemplate){
      sendTestEmailMutation({
        variables:{
          input:{
            emailAddress: testEmailAddress,
            category: data.emailTemplate.category,
            id: data.emailTemplate.id
          }
        },
        fetchPolicy:"network-only"
      }).then((data)=>{
        if(data.data?.sendTestEmail && data.data.sendTestEmail===true){
          message.success("Test Email Successfully Sent")
        }
      }).catch((error)=>{
        message.error(error.message);
      })
    }else{
      message.error("Please enter a valid email address for testing");
    }
  }


  return (
      <Form form={form} layout="vertical" onFinish={updateEmailTemplate}>
        <BackButton/>
        <MarginBox mt={32}>
          <Row>
            <Col span={24}>
              <Item name="name" label="Name"
               rules={[
                {
                  required: true,
                  message: "Please enter the template name!",
                },
              ]}>
                <Input />
              </Item>
            </Col>
            <Col span={24}>
              <Item name="subject" label="Subject"
               rules={[
                {
                  required: true,
                  message: "Please enter the template subject!",
                },
              ]}>
                <Input />
              </Item>
            </Col>
            <Col span={24}>
            <Item name="category" label="Template Category"
               rules={[
                {
                  required: true,
                  message: "Please select the template category!",
                },
              ]}>
              <Select
                  placeholder="Select"
                  suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
                  options={EmailCategories}
                  onChange={fetchToken}
                >
                </Select>
            </Item>
            </Col>
            <Col span={24}>
              <Item name="content" label="Content">
                {/*
                  isHTML ? (
                    <HTMLEmailEditor value={data?.emailTemplate?.content} setContent={setContent}/>
                  ):(
                    <DefaultEmailEditor
                    value={data?.emailTemplate?.content} 
                    setContent={setContent} 
                    setHtmlContent={setHtmlContent}
                    tokens = {tokens}
                    />
                  )*/
                  }
                  <HTMLEmailEditor value={data?.emailTemplate?.content} setContent={setContent}/>
              </Item>
            </Col>
            <Col span={24}>

              <Item label="Test Email Template">
                <Input type="email" style={{ marginTop:"1%" }} placeholder="Enter Email Address To Test" onChange={(e)=>{
                  setTestEmailAddress(e.target.value);
                }
                }/>
                <TwfButton
                  type="primary"
                  color-twf="primary"
                  size-twf="sm"
                  type-twf="wide"
                  style={{ marginTop:"2%" }}
                  onClick={sendTestEmail}
                  loading={sendTestEmailLoading}
                >
                  Send Test Email
                </TwfButton>
              </Item>

            </Col>

          </Row>
          <MarginBox mt={32}>
            <Row gutter={breakpoints.md ? [24, 24] : [12, 12]} justify="end">
              <Col>
                <TwfButton
                  color-twf="gray-line"
                  size-twf="md"
                  type-twf="wide"
                  onClick={() => {
                    history("/marketing");
                  }}
                >
                  Cancel
                </TwfButton>
              </Col>
              <Col>
                <TwfButton
                  type="primary"
                  color-twf="primary"
                  size-twf="md"
                  type-twf="wide"
                  htmlType="submit"
                  loading={loading}
                >
                  Update
                </TwfButton>
              </Col>
            </Row>
          </MarginBox>
        </MarginBox>
      </Form>
    
  );
};

export default UpdateEmailTemplate;
