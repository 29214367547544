import React from "react";
import TwfModal from "../../../component/modal";
import Icon from "../../../assets/icons";
import ModalTitle from "../../../component/modal/title";
import { H3 } from "../../../component/header";
import {
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import { MarginBox } from "../../../component/margin";
import TwfButton from "../../../component/button";
import {
  //useGetShippingMethodListQuery,
  useCreateClubMutation,
} from "./../../../generated/graphql";
import TextEditor from "component/textEditor/textEditor";

const { Item } = Form;
const { Option } = Select;

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const ClubCreate: React.FC<ComponentProps> = ({ visible, setVisible }) => {
  const [form] = Form.useForm();
  //const { data: shippingMethods } = useGetShippingMethodListQuery();
  const [createClub, { loading }] = useCreateClubMutation();

  const submitFormHandler = (values: any) => {
    const input = {
      name: values.name,
      description: values.description,
      slug: values?.slug?.toLowerCase(),
      order: values.order,
      //shippingMethodID: values.shippingMethod?.id,
      displayOnWeb: values.displayOnWeb,
      active: values.active,
      //userChoice: values.userChoice === "1" ? true : false,
      userChoice: true,
    };
    createClub({
      variables: { input },
      refetchQueries: ["GetClubList"],
    })
      .then(({ data }) => {
        if (data?.createClub?.id) {
          message.success("Club created");
          setVisible(false);
          form.resetFields();
        }
      })
      .catch((error) => message.error(error.message));
  };

  /*const removeNull = (shippingM: any)=>{
    return (
      shippingM.name !== "NULL SHIPPING METHOD"
      &&
      shippingM.customFields.enabled === true
    );
  }*/

  const initialValues = { displayOnWeb: false, active: true };

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closeIcon={<Icon keyword="close" />}
      footer={false}
      width={662}
      centered
    >
      <ModalTitle>
        <H3>Create New Club</H3>
      </ModalTitle>
      <Form
        form={form}
        layout="vertical"
        onFinish={submitFormHandler}
        initialValues={initialValues}
      >
        <Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter the name!",
            },
          ]}
        >
          <Input />
        </Item>
        <Item label="Desciption" name="description">
          <TextEditor />
        </Item>
        <Item label="Slug" name="slug">
        <Input />
        </Item>
        <Item
          label="Display Order"
          name="order"
          rules={[
            {
              required: true,
              message: "Please enter order!",
            },
          ]}
        >
          <Select
            placeholder="Select"
            suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
          >
            {Array.from(Array(5), (e, i) => i + 1).map((i) => (
              <Option key={i} value={i}>{i}</Option>
            ))}
          </Select>
        </Item>
        {/*<Item
          label="Shipping"
          name={["shippingMethod", "id"]}
          rules={[
            {
              required: true,
              message: "Please select shipping!",
            },
          ]}
        >
          <Select
            placeholder="Select"
            options={shippingMethods?.shippingMethods.items.filter(removeNull).map((method) => ({
              value: method.id,
              label: method.name,
            }))}
            suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
          ></Select>
          </Item>*/}
        <MarginBox mt={21} mb={32}>
          <Item name="displayOnWeb" valuePropName="checked">
            <Checkbox>Display on Web?</Checkbox>
          </Item>
          <Item name="active" valuePropName="checked">
            <Checkbox>Active?</Checkbox>
          </Item>
        </MarginBox>
        {/*<Item label="User Choice" name="userChoice">
          <Select
            placeholder="Select"
            suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
          >
            <Option value="1">Yes</Option>
            <Option value="0">No</Option>
          </Select>
          </Item>*/}
        <MarginBox mt={48}>
          <Row gutter={12} justify="end">
            <Col>
              <TwfButton
                color-twf="gray-line"
                size-twf="md"
                type-twf="wide"
                onClick={() => {
                  setVisible(false);
                  form.resetFields();
                }}
              >
                Cancel
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                type="primary"
                color-twf="primary"
                size-twf="md"
                type-twf="wide"
                htmlType="submit"
                loading={loading}
              >
                Create
              </TwfButton>
            </Col>
          </Row>
        </MarginBox>
      </Form>
    </TwfModal>
  );
};

export default ClubCreate;
