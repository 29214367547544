import React, { useState, useEffect } from "react";
import { H3 } from "../../../component/header";
import TwfModal from "../../../component/modal";
import ModalTitle from "../../../component/modal/title";
import Icon from "../../../assets/icons";
import { Col, Row, message, Table, TablePaginationConfig } from "antd";
import { MarginBox } from "../../../component/margin";
import TwfButton from "../../../component/button";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  SortOrder,
  useAddCustomersToClubMutation,
  useNonMemberCustomersLazyQuery,
} from "generated/graphql";
import { useParams } from "react-router-dom";
import { TableOptions } from "../components/customerList";
import AdvancedSearch from "pages/orders/components/search";

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const ClubAddMember: React.FC<ComponentProps> = ({ visible, setVisible }) => {
  const breakpoints = useBreakpoint();
  const { id } = useParams<{ id: string }>();
  const cid: string = id as string;
  const [getNonMemberCustomersListQuery, { data, loading }] = 
   useNonMemberCustomersLazyQuery();
  const [addCustomersToClubMutation, { loading: addLoading }] =
    useAddCustomersToClubMutation();

  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    skip: 0,
    take: 10,
    sort: { id: SortOrder.Desc },
  });
  const [searchTerm, setSearchTerm] = useState<string|undefined>();

  useEffect(() => {
    if (visible){
      getNonMemberCustomersListQuery({
        variables:{
          clubID: cid,
          filterData: searchTerm,
          options: tableOptions,
        },
        fetchPolicy: "network-only"
      })
    }
  }, [tableOptions, getNonMemberCustomersListQuery, visible, cid, searchTerm]);

  const addCustomerHandler = () => {
    if (selectedKeys.length > 0) {
      addCustomersToClubMutation({
        variables: { clubId: cid, customerIds: selectedKeys },
        refetchQueries: ["GetClub", "NonMemberCustomers"],
      })
        .then(({ data }) => {
          if (data?.addCustomersToClub)
            message.success(
              `Customer${selectedKeys.length > 1 ? "s" : ""} added`
            );
            setVisible(false);
        })
        .catch((error) => message.error(error.message));
    } else {
      message.error("Customer is not selected");
    }
  };

  const filterChangeHandler = (term: string) => {
    setTableOptions({
      ...tableOptions,
    });
    setSearchTerm(term);
  };

  const tableChangeHandler = (
    pagination: TablePaginationConfig,
    filters: any,
    sort: any
  ) => {
    setTableOptions({
      filter: tableOptions.filter,
      skip: (pagination.pageSize || 10) * ((pagination.current || 1) - 1),
      take: pagination.pageSize || 10,
      sort: sort.field
        ? {
            [sort.field]:
              sort.order === "ascend" ? SortOrder.Asc : SortOrder.Desc,
          }
        : tableOptions.sort,
    });
  };

  const onSelectionChange = (selectedRowKeys: any[]) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedKeys: [],
    onChange: onSelectionChange,
  };

  const columns: any = [
    {
      title: "Name",
      render: (record: any) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
    },
  ];

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closeIcon={<Icon keyword="close" />}
      footer={false}
      width={662}
      centered
    >
      <ModalTitle>
        <H3>Add Customers</H3>
      </ModalTitle>
      <AdvancedSearch
        placeholder="Search by name, email address"
        setFilter={filterChangeHandler}
      />
      <Table
        loading={loading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data?.nonMemberCustomers.items.map((obj) => ({
          ...obj,
          key: obj.id,
        }))}
        pagination={{
          pageSize: tableOptions.take,
          current: tableOptions.skip / tableOptions.take + 1,
          total: data?.nonMemberCustomers?.totalItems,
          position: ["bottomCenter"],
          showSizeChanger: true,
        }}
        onChange={tableChangeHandler}
        style={{ minHeight: 300 }}
      />
      <MarginBox mt={32}>
        <Row gutter={breakpoints.md ? [24, 24] : [12, 12]} justify="end">
          <Col>
            <TwfButton
              size-twf="xm"
              color-twf="gray-line"
              type-twf="wide"
              onClick={() => {
                setVisible(false);
              }}
            >
              Close
            </TwfButton>
          </Col>
          <Col>
            <TwfButton
              size-twf="xm"
              type="primary"
              color-twf="primary"
              type-twf="wide"
              onClick={addCustomerHandler}
              loading={addLoading}
            >
              Add
            </TwfButton>
          </Col>
        </Row>
      </MarginBox>
    </TwfModal>
  );
};

// const CustomerTable = styled(Table)`
//   .ant-table-selection-col {
//     width: 20px;
//   }
// `;

export default ClubAddMember;
