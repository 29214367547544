import React from "react";
import AuthStepText from "../style/stepText";
import { MarginBox } from "../../../component/margin";
import { H3 } from "../../../component/header";
import AuthLayoutWrap from "../style/wrap";
import CheckedIllus from "../../../assets/illustration/checked";
import { Grid, Space } from "antd";
const { useBreakpoint } = Grid;

const RegisterComplete: React.FC = () => {
  const screens = useBreakpoint();
  console.log(screens);
  return (
    <AuthLayoutWrap>
      <AuthStepText>STEP 3/3</AuthStepText>
      <Space direction="vertical" size={screens.md ? 172 : 112}>
        <MarginBox mt={65}>
          <H3>Sign Up Confirmation</H3>
        </MarginBox>
        <MarginBox>
          <MarginBox mb={32}>
            <CheckedIllus width={screens.md ? 90 : 80} />
          </MarginBox>
          <H3>You are successfully registered!</H3>
        </MarginBox>
      </Space>
    </AuthLayoutWrap>
  );
};

export default RegisterComplete;
