import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const CollapseIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 15 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="1.66669"
      cy="2"
      rx="1.66669"
      ry="2"
      fill={color ? color : "#000000"}
    />
    <ellipse
      cx="7.22138"
      cy="2"
      rx="1.66669"
      ry="2"
      fill={color ? color : "#000000"}
    />
    <ellipse
      cx="13.3327"
      cy="2"
      rx="1.66669"
      ry="2"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export default CollapseIcon;
