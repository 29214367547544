import { Col, Divider, Row } from "antd";
import React, { memo, useEffect, useState } from "react";
import Icon from "../../../../assets/icons";
import TwfButton from "../../../../component/button";
import { H3 } from "../../../../component/header";
import TwfModal from "../../../../component/modal";
import ModalTitle from "../../../../component/modal/title";
import TableItem from "../../../../component/table/item";
import TableTitle, { TableBigTitle } from "../../../../component/table/title";
import { MarginBox } from "../../../../component/margin";
import moment from "moment";

import {
  orderClassifications,
  sources,
  //pickupLocations,
} from "./../../../../enums/enums";
import { usePickUpLabelsQuery } from "generated/graphql";
import { currencyFormatter } from "utils/currency-formatter";
import PricingTable from "component/pricing-table";


interface ViewProps {
  order: any;
  visible?: boolean;
  newOrder?: boolean;
  loading?: boolean;
  shippingMethods?: { label: string; value: number }[];
  setVisible: (value: boolean) => void;
  action: (value: boolean) => void;
}

const CreateOrderReview: React.FC<ViewProps> = ({
  order: orderProp,
  visible,
  newOrder,
  loading,
  shippingMethods,
  setVisible,
  action,
}) => {
  const [pickupLocations, setPickupLocations] = useState<any[]>([]);

  const { data: pickupLabels } = usePickUpLabelsQuery();

  useEffect(() => {
    if (pickupLabels?.pickupLabels) {
      setPickupLocations(pickupLabels.pickupLabels);
    }
  }, [pickupLabels]);

  const order = {
    ...orderProp,
    sourceLabel: sources.find((s) => s.value === orderProp?.source)?.label,
    orderClassificationLabel: orderClassifications.find(
      (s) => s.value === orderProp?.orderClassification
    )?.label,
    pickupLocationLabel: pickupLocations.find(
      (s) => s.value === orderProp?.pickupLocation
    )?.label,
    shippingMethodLabel: shippingMethods?.find(
      (s) => s.value === orderProp?.shippingMethod
    )?.label,
  };

  const removeZero = (line: any) => {
    return line.quantity > 0;
  };

  return (
    <TwfModal
      forceRender
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      centered
      width={746}
      footer={false}
      closeIcon={<Icon keyword="close" width={24} color="#0A1629" />}
    >
      <ModalTitle>
        <Row gutter={21} align="middle">
          <Col>
            <H3>Review {newOrder && "New"} Order</H3>
          </Col>
        </Row>
      </ModalTitle>
      <MarginBox mb={24}>
        <Row gutter={12}>
          <Col span={12} md={6}>
            <TableTitle>Product</TableTitle>
          </Col>
          <Col span={12} md={6}>
            <TableTitle>Price</TableTitle>
          </Col>
          <Col span={12} md={6}>
            <TableTitle>Qty</TableTitle>
          </Col>
          <Col span={12} md={6}>
            <TableTitle>Total</TableTitle>
          </Col>
        </Row>
        {order?.lines?.filter(removeZero).map((obj: any) => (
          <Row gutter={12} key={obj.id}>
            <Col span={12} md={6}>
              <TableItem>
                {obj.productVariant?.product?.name} {obj.productVariant?.name}
                {" - "}
                {obj.productVariant?.sku}
              </TableItem>
            </Col>
            <Col span={12} md={6}>
              <TableItem>
                {currencyFormatter.format(obj.unitPrice / 100)}
              </TableItem>
            </Col>
            <Col span={12} md={6}>
              <TableItem>{obj.quantity}</TableItem>
            </Col>
            <Col span={12} md={6}>
              <TableItem>
                {currencyFormatter.format(obj.linePrice / 100)}
              </TableItem>
            </Col>
          </Row>
        ))}
      </MarginBox>
      <MarginBox mb={15}>
        <Row gutter={12}>
          <Col span={12} md={6}>
            <TableTitle>Order ID</TableTitle>
            <TableItem>{order.code}</TableItem>
          </Col>
          <Col span={12} md={6}>
            <TableTitle>Customer</TableTitle>
            <TableItem>
              {order.billingFirstName} {order.billingLastName}
            </TableItem>
          </Col>
          <Col span={12} md={6}>
            <TableTitle>Source</TableTitle>
            <TableItem>{order.sourceLabel}</TableItem>
          </Col>
          <Col span={12} md={6}>
            <TableTitle>Order Classification</TableTitle>
            <TableItem>{order.orderClassificationLabel}</TableItem>
          </Col>
        </Row>
      </MarginBox>
      <Divider />
      <MarginBox mb={24}>
        <Row gutter={[24, 24]}>
          <Col span={24} md={12}>
            <TableBigTitle>Billing Information</TableBigTitle>
            <TableItem>
              {order.billingFirstName} {order.billingLastName}
            </TableItem>
            <TableItem>{order.billingPhoneNumber}</TableItem>
            <TableItem>{order.billingEmail}</TableItem>
            <TableItem>{order.billingCompany}</TableItem>
            <TableItem>
              {moment(order?.billingDOB).format("DD/MM/YYYY")}
            </TableItem>
            <TableItem>{order.billingAddress?.streetLine1}</TableItem>
            <TableItem>{order.billingAddress?.streetLine2}</TableItem>
            <TableItem>
              {order.billingAddress?.city},&nbsp;
              {order.billingAddress?.province}&nbsp;
              {order.billingAddress?.postalCode}
            </TableItem>
          </Col>

          <Col span={24} md={12}>
            <TableBigTitle>Shipping Information</TableBigTitle>
            <TableItem>
              {order.shippingFirstName} {order.shippingLastName}
            </TableItem>
            <TableItem>{order.shippingPhoneNumber}</TableItem>
            <TableItem>{order.shippingEmail}</TableItem>
            <TableItem>{order.shippingCompany}</TableItem>
            <TableItem>
              {moment(order?.shippingDOB).format("DD/MM/YYYY")}
            </TableItem>

            <TableItem>{order.shippingAddress?.streetLine1}</TableItem>
            <TableItem>{order.shippingAddress?.streetLine2}</TableItem>
            <TableItem>
              {order.shippingAddress?.city},&nbsp;
              {order.shippingAddress?.province}&nbsp;
              {order.shippingAddress?.postalCode}
            </TableItem>
          </Col>

          <Col span={24} md={12}>
            <TableBigTitle>Pickup/Shipping Method</TableBigTitle>
            {order.pickupLocationLabel && (
              <TableItem>{order.pickupLocationLabel}</TableItem>
            )}
            {order.customFields?.csvShippingMethod && (
              <TableItem>{order.customFields?.csvShippingMethod}</TableItem>
            )}
          </Col>

          <Col span={24} md={12}>
            <TableBigTitle>Special Instruction</TableBigTitle>
            <TableItem>{order.specialInstructions}</TableItem>
          </Col>
        </Row>
      </MarginBox>
      <Divider />
      <MarginBox mt={24}>
        <Row gutter={24} align="bottom">
          <Col span={12}>
           <PricingTable order={order}/>
          </Col>
          <Col span={1} />
          <Col>
            <TwfButton
              color-twf="gray-line"
              size-twf="md"
              style={{ padding: "0 31px" }}
              onClick={() => setVisible(false)}
            >
              Cancel
            </TwfButton>
          </Col>
          <Col>
            <TwfButton
              color-twf="primary"
              type="primary"
              size-twf="md"
              style={{ padding: "0 29px" }}
              onClick={() => {
                action(true);
              }}
              loading={loading}
            >
              Checkout
            </TwfButton>
          </Col>
        </Row>
      </MarginBox>
    </TwfModal>
  );
};

export default memo(CreateOrderReview);
