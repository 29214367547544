import React, { useEffect } from "react";
import TwfModal from "../../../component/modal";
import Icon from "../../../assets/icons";
import ModalTitle from "../../../component/modal/title";
import { H3 } from "../../../component/header";
import { Col, DatePicker, Form, Input, message, Row, Select } from "antd";
import TwfButton from "../../../component/button";
import { MarginBox } from "../../../component/margin";
import {
  useAddNoteToCustomerMutation,
  useUpdateCustomerNoteMutation,
} from "generated/graphql";
import { useParams } from "react-router";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

export interface Note {
  id?: string;
  note: string;
  status: string;
  date: Date;
}

interface ComponentProps {
  note: Note | null;
  setNote: (value: Note | null) => void;
  visible?: boolean;
  setVisible: (value: boolean) => void;
}

const CustomerSingleAddNote: React.FC<ComponentProps> = ({
  note,
  setNote,
  visible,
  setVisible,
}) => {
  const [form] = useForm();
  const { id: customerId } = useParams<{ id: string }>();
  const custid:string = customerId as string;
  const [addNoteMutation, { loading: addLoading }] =
    useAddNoteToCustomerMutation();
  const [updateNoteMutation, { loading: updateLoading }] =
    useUpdateCustomerNoteMutation();

  useEffect(() => {
    if (note) {
      console.log(note);
      note.date = moment(note.date) as any;
      form.setFieldsValue(note);
    }
  }, [note, form]);

  const submitHandler = (formData: Note) => {
    if (!note?.id) {
      addNote(formData);
    } else {
      updateNote(note.id, formData);
    }
  };

  const addNote = (formData: Note) => {
    addNoteMutation({
      variables: {
        input: {
          id: custid,
          isPublic: false,
          note: JSON.stringify({ ...formData }),
        },
      },
      refetchQueries: ["GetCustomerHistory"],
    }).then(() => {
      message.success("Note added");
      form.resetFields();
      setVisible(false);
    });
  };

  const updateNote = (noteId: string, formData: Note) => {
    updateNoteMutation({
      variables: {
        input: {
          noteId,
          note: JSON.stringify({ ...formData }),
        },
      },
      refetchQueries: ["GetCustomerHistory"],
    }).then(() => {
      message.success("Note updated");
      form.resetFields();
      setVisible(false);
      setNote(null);
    });
  };

  const cancelModal = () => {
    form.resetFields();
    setVisible(false);
    setNote(null);
  };

  return (
    <TwfModal
      footer={false}
      visible={visible}
      onCancel={cancelModal}
      width={662}
      closeIcon={<Icon keyword="close" />}
      centered
    >
      <ModalTitle>
        <H3> {note?.id ? "Edit" : "Add"} Customer Note</H3>
      </ModalTitle>
      <Form form={form} layout="vertical" onFinish={submitHandler}>
        <Row>
          <Col span={24}>
            <Item label="Note Details" name="note">
              <TextArea />
            </Item>
          </Col>
          <Col span={24}>
            <Item label="Status" name="status">
              <Select
                placeholder="Select"
                suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
              >
                <Option value="Follow Up">Follow Up</Option>
                <Option value="Completed">Completed</Option>
                <Option value="No Action Needed">No Action Needed</Option>
              </Select>
            </Item>
          </Col>
          <Col span={24}>
            <Item label="Follow Up Date" name="date">
            <DatePicker/>
            </Item>
          </Col>
          <Col span={24}>
            <MarginBox mt={32}>
              <Row gutter={12} justify="end">
                <Col>
                  <TwfButton
                    size-twf="xm"
                    color-twf="gray-line"
                    type-twf="wide"
                    onClick={cancelModal}
                  >
                    Cancel
                  </TwfButton>
                </Col>
                <Col>
                  <TwfButton
                    size-twf="xm"
                    type="primary"
                    color-twf="primary"
                    type-twf="wide"
                    htmlType="submit"
                    loading={addLoading || updateLoading}
                  >
                    {note?.id ? "Save" : "Add"}
                  </TwfButton>
                </Col>
              </Row>
            </MarginBox>
          </Col>
        </Row>
      </Form>
    </TwfModal>
  );
};

export default CustomerSingleAddNote;
