import React, { FC, useState } from "react";
import ShadowCard from "../../../../component/card";
import { CardContainer, CardTitle } from "../../style/card";
import Icon from "../../../../assets/icons";
import { SubTitle } from "../../../../component/header";
import { ShowButton } from "./style/showButton";
import { Grid } from "antd";

const { useBreakpoint } = Grid;

interface CardProps {
  cardData?: any;
}

const StatusCard: FC<CardProps> = ({ cardData }) => {
  const breakpoints = useBreakpoint();

  const [show, setShow] = useState(false);
  return (
    <ShadowCard>
      <CardContainer>
        {cardData.status === "increase" ? (
          <Icon
            keyword="increase"
            width={breakpoints.md ? 50 : 20}
            color="#33D69F"
          />
        ) : (
          <Icon
            keyword="decrease"
            width={breakpoints.md ? 50 : 20}
            color="#FF2626"
          />
        )}
        <div>
          <CardTitle>
            {cardData.hide && !show ? "*******" : cardData.title}
          </CardTitle>
          <SubTitle>{cardData.subTitle}</SubTitle>
        </div>
        {cardData.hide && (
          <ShowButton
            type-twf="icon"
            shape="circle"
            onClick={() => {
              setShow(!show);
            }}
          >
            <Icon
              keyword="hide"
              width={breakpoints.md ? 26 : 15}
              color="#3C64B1"
            />
          </ShowButton>
        )}
      </CardContainer>
    </ShadowCard>
  );
};

export default StatusCard;
