import styled from "styled-components";

const PriceTable = styled.table`
  width: 100%;
`;

const PriceDetail = styled.td`
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
const PriceValue = styled.td`
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  text-align: right;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const PriceTotalDetail = styled.td`
  font-size: 20px;
  line-height: 28px;
  font-weight: 900;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
`;
const PriceTotalValue = styled.td`
  font-size: 20px;
  line-height: 28px;
  font-weight: 900;
  text-align: right;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const LineDetail = styled.td`
  text-align: left;
  line-height: 40px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
const LineValue = styled.td`
  text-align: right;
  line-height: 40px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export {
  PriceTable,
  PriceDetail,
  PriceValue,
  PriceTotalDetail,
  PriceTotalValue,
  LineDetail,
  LineValue,
};
