import { Col, Grid, Row } from "antd";
import { OrderLine } from "pages/orders/components/create/products";
import AddProductsTable from "pages/orders/components/create/add-products-table";
import { FC, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import { H2 } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import AdvancedSearch from "../../orders/components/search";
import ProductsTable from "../components/table";

const { useBreakpoint } = Grid;

export interface ProductFilter {
  name?: string;
  type: number;
  status: number;
}

interface ReducerAction {
  type: string;
  value: string;
}

interface Props {
  isModal: boolean;
  onOrderLineChange: (orderLines: OrderLine[]) => void;
}

const ProductList: FC<Props> = ({ isModal, onOrderLineChange }) => {
  const history = useNavigate();
  const breakpoints = useBreakpoint();

  const initialState: ProductFilter = { type: 1, status: 1 };

  const reducer = (state: ProductFilter, action: ReducerAction) => {
    switch (action.type) {
      case "type":
      case "status":
        return { ...state, [action.type]: parseInt(action.value) };
      case "name":
        return { ...state, name: action.value };
      default:
        return state;
    }
  };

  const [filter, dispatch] = useReducer(reducer, initialState);

  return (
    <div>
      <Row
        gutter={[30, 20]}
        justify="space-between"
        wrap={false}
      >
        <Col style={{ width: "100%" }}>
          <Row align="middle" gutter={[30, 20]}>
            {breakpoints.md && (
              <Col>
                <H2>Products</H2>
              </Col>
            )}

            <Col  style={{ width: breakpoints.md ? "max-content" : "100%" }}>
              <Row gutter={[24, 24]}>
                <Col style={{ width: breakpoints.md ? "max-content" : "100%" }}>
                  <AdvancedSearch
                    setFilter={(value) => dispatch({ type: "name", value })}
                  />
                </Col>
                {breakpoints.md && !isModal && (
                  <Col>
                    <TwfButton
                      size-twf="xl"
                      color-twf="gray-line"
                      onClick={() =>
                        dispatch({
                          type: "status",
                          value: filter.status === 1 ? "2" : "1",
                        })
                      }
                    >
                      <Icon keyword="archive" color="#979797" width={24} />{" "}
                      {filter.status === 2 ? "Active" : "Archived"} products
                    </TwfButton>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        {breakpoints.md && !isModal && (
          <Col>
            <TwfButton
              color-twf="primary"
              type="primary"
              size-twf="lg"
              type-twf="wide"
              onClick={() => {
                history("/products/create");
              }}
            >
              <Icon keyword="plus" width={16} color="#fff" />
              Create New Product
            </TwfButton>
          </Col>
        )}
      </Row>
      <MarginBox mt={32}>
        {/* To Do with Bundle: Navid/Biplab
        <TwfTabsProducts
          onChange={(value) => dispatch({ type: "type", value })}
        >*/}

        {/*<TwfTabItem tab="Wine" key={1}>*/}

        
        {
          isModal ? <AddProductsTable
          filter={filter}
          onOrderLineChange={onOrderLineChange}
          /> :
          <ProductsTable
          filter={filter}
          isModal={isModal}
          onOrderLineChange={onOrderLineChange}
        />}

        {/*</TwfTabItem>*/}

        {/* <TwfTabItem tab="Bundle" key={2}>
            <ProductsTable
              filter={filter}
              isModal={isModal}
              onOrderLineChange={onOrderLineChange}
            />
          </TwfTabItem> */}

        {/*</TwfTabsProducts>*/}
      </MarginBox>
      {/* {!breakpoints.md && (
        <ProductsMobileFooter setCreateVisible={setCreateVisible} />
      )} */}
    </div>
  );
};

export default ProductList;
