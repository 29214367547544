import React, { useMemo, useState } from "react";
import TwfModal from "../../../component/modal";
import Icon from "../../../assets/icons";
import ModalTitle from "../../../component/modal/title";
import { H3 } from "../../../component/header";
import { Col, Form, Input, message, Row } from "antd";
import TwfButton from "../../../component/button";
import { MarginBox } from "../../../component/margin";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import styled from "styled-components";
import { useCreateCustomerCardMutation, useGetActiveChannelQuery } from "generated/graphql";

const { Item } = Form;

interface ComponentProps {
  visible?: boolean;
  setVisible: (value: boolean) => void;
  emailAddress?: string;
  loading?: boolean;
}

const CustomerSingleAddCard: React.FC<ComponentProps> = ({
  visible,
  setVisible,
  emailAddress,
  //loading
}) => {
  const [form] = Form.useForm();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [load, setLoad] = useState(false);
  const [createCustomerCardMutation] = useCreateCustomerCardMutation();


  const submitFormHandler = (data: any) => {
    setLoad(true);
    createCreditCard(data);
  };

  const createCreditCard = async (data: any) => {
    createStripeCreditCard(data).then((data) => {
      if (data?.paymentMethod){ 
        createCustomerCard(data.paymentMethod.id);
      }else{
        setLoad(false);
      }
    }).catch((error)=>{
      message.error(error.message);
      setLoad(false);
    })
  };

  const { data: getActiveChannel } = useGetActiveChannelQuery();

  const createStripeCreditCard = async (data: any) => {
    if (elements == null || stripe === null) return;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)!,
      billing_details: {
        email: data.email,
        name: data.name,
      },
      metadata:{
        "store-token": getActiveChannel?.activeChannel?.token || null
      }
    });
    return { error, paymentMethod };
  };

  const createCustomerCard = async (paymentMethodId: string) => {
    createCustomerCardMutation({
      variables: {
        paymentMethodId,
        customerEmail: emailAddress,
      },
      refetchQueries:["GetAllCustomerCards"]
    })
      .then(({ data }) => {
        if (data?.attachPaymentMethodToCustomer.__typename === "Card") {
          message.success("Credit card created");
          form.resetFields();
          setVisible(false);
        } else if (
          data?.attachPaymentMethodToCustomer.__typename ===
          "PaymentMethodAttachError"
        ) {
          message.error(data.attachPaymentMethodToCustomer.message);
        }
        setLoad(false)
      })
      .catch((error) => {
        setLoad(false);
        message.error(error.message);
      });
  };

  return (
    <TwfModal
      footer={false}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      width={662}
      closeIcon={<Icon keyword="close" />}
      centered
    >
      <ModalTitle>
        <H3>Add New Credit Card</H3>
      </ModalTitle>
      <Form form={form} layout="vertical" onFinish={submitFormHandler}>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Item name="nameOnCard">
                  <Input placeholder="Name on Card" />
                </Item>
              </Col>
              <Col span={24}>
                <CardNumberItem name="cardNumber">
                  <CardNumberElement options={options} />
                </CardNumberItem>
              </Col>
              <Col span={12}>
                <CardExpiryItem name="expiryDate">
                  <CardExpiryElement options={options} />
                </CardExpiryItem>
              </Col>
              <Col span={12}>
                <CardCvcItem name="cvs">
                  <CardCvcElement options={options} />
                </CardCvcItem>
              </Col>
            </Row>
          </Col>
          {/* <Col span={24}>
            <Item name="isDefault">
              <Checkbox>Mark as default</Checkbox>
            </Item>
            </Col> */}
          <Col span={24}>
            <MarginBox mt={32}>
              <Row gutter={12} justify="end">
                <Col>
                  <TwfButton
                    size-twf="xm"
                    color-twf="gray-line"
                    type-twf="wide"
                    onClick={() => {
                      setVisible(false);
                    }}
                  >
                    Cancel
                  </TwfButton>
                </Col>
                <Col>
                  <TwfButton
                    size-twf="xm"
                    type="primary"
                    color-twf="primary"
                    type-twf="wide"
                    htmlType="submit"
                    loading={load}
                  >
                    Add
                  </TwfButton>
                </Col>
              </Row>
            </MarginBox>
          </Col>
        </Row>
      </Form>
    </TwfModal>
  );
};

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "16px",
          color: "#333",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          boxSizing: "border-box",
          "::placeholder": {
            color: "#aaa",
          },
        },
        invalid: {
          color: "#eb1c26",
        },
      },
    }),
    []
  );

  return options;
};

const CardItem = styled(Item)`
  border: 1px solid rgba(60, 66, 87, 0.12);
  height: 40px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
  border-radius: 14px;
  margin-bottom: 0;
  box-sizing: border-box;
  padding: 3px 10px;
`;

const CardNumberItem = styled(CardItem)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
`;

const CardExpiryItem = styled(CardItem)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const CardCvcItem = styled(CardItem)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
`;

export default CustomerSingleAddCard;
