import React, { useEffect } from "react";
import { TwfCard } from "../../../component/card";
import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import { MarginBox } from "../../../component/margin";
import Icon from "../../../assets/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { 
  GetClubQuery, 
  //useGetShippingMethodListQuery 
} from "generated/graphql";
import TextEditor from "../../../component/textEditor/textEditor";

const { Item } = Form;
const { Option } = Select;

interface Props {
  club?: GetClubQuery;
  onClubChange: (value: any) => void;
}

const ClubSingleGeneral: React.FC<Props> = ({ club, onClubChange }) => {
  const [form] = Form.useForm();
  const breakpoints = useBreakpoint();
  //const { data: shippingMethods } = useGetShippingMethodListQuery();

  useEffect(() => {
    if (club?.club)
      form.setFieldsValue({
        ...club.club,
        //userChoice: club.club.userChoice ? "1" : "0",
      });
  }, [club, form]);

  /*const removeNull = (shippingM: any)=>{
    return shippingM.name !== "NULL SHIPPING METHOD";
  }*/

  return (
    <TwfCard style={{ padding: breakpoints.md ? "32px 56px" : "12px 15px" }}>
      <Form form={form} layout="vertical" onValuesChange={onClubChange}>
        <Row>
          <Col md={{ span: 9 }} span={24}>
            <Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter the name!",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Item>
            <Item label="Description" name="description">
              <TextEditor />
            </Item>
            <Item label="Slug" name="slug">
            <Input placeholder="Slug" />
            </Item>
            <Item
              label="Order"
              name="order"
              rules={[
                {
                  required: true,
                  message: "Please enter order!",
                },
              ]}
            >
              <Select
                placeholder="Select"
                suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
              >
                {Array.from(Array(5), (e, i) => i + 1).map((i) => (
                  <Option key={i} value={i}>
                    {i}
                  </Option>
                ))}
              </Select>
            </Item>
            {/*<Item label="Shipping" name={["shippingMethod", "id"]}
            rules={[
              {
                required: true,
                message: "Please enter shipping method!",
              },
            ]}>
              <Select
                placeholder="Select"
                options={shippingMethods?.shippingMethods.items.filter(removeNull).map(
                  (method) => ({
                    value: method.id,
                    label: method.name,
                  })
                )}
                suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
              ></Select>
                </Item>*/}
            <MarginBox mt={21} mb={32}>
              <Item name="displayOnWeb" valuePropName="checked">
                <Checkbox>Display on Web?</Checkbox>
              </Item>
              <Item name="active" valuePropName="checked">
                <Checkbox>Active?</Checkbox>
              </Item>
            </MarginBox>
            {/*<Item label="User Choice" name="userChoice">
              <Select
                placeholder="Select"
                suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
              >
                <Option value="1">Yes</Option>
                <Option value="0">No</Option>
              </Select>
                </Item>*/}
            {/*<p style={{ color: "#BBBBBB", marginTop: 4 }}>
              Allows customer to edit shipment.
              </p>*/}
          </Col>
        </Row>
      </Form>
    </TwfCard>
  );
};

export default ClubSingleGeneral;
