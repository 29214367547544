import styled from "styled-components";

const TableTitle = styled.h5`
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 2px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const TableBigTitle = styled.h5`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export { TableBigTitle };
export default TableTitle;
