import { useMutation } from "@apollo/client";
import { Col, message, Row } from "antd";
import { ADD_ITEM_TO_ORDER } from "graphql/mutations.graphql";
import ProductList from "pages/products/product/product-list";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setOrder } from "store/action/ecommerce.action";
import Icon from "../../../../assets/icons";
import TwfButton from "../../../../component/button";
import { MarginBox } from "../../../../component/margin";
import TwfModal from "../../../../component/modal";

export interface OrderLine {
  id: string;
  quantity?: number;
  price?: number;
  selected?: boolean;
}

interface ModalProps {
  productVisible?: boolean;
  setProductVisible: (value: boolean) => void;
  activeOrderId: string;
  getShippingMethodsByState?: any;
}

const CreateOrderProducts: React.FC<ModalProps> = ({
  productVisible,
  setProductVisible,
  activeOrderId,
  getShippingMethodsByState,
}) => {
  const dispatch = useDispatch();
  const [addItemToOrderMutation, { loading }] = useMutation(ADD_ITEM_TO_ORDER, {
    onCompleted: onAddItemSuccess,
    onError: onAddItemError,
  });

  const [orderLines, setOrderLines] = useState<OrderLine[]>([]);

  const closeModalHandler = () => {
    setProductVisible(false);
  };

  const addProductVariantsClickHandler = async () => {
    try {
      if (orderLines.length > 0)
        await Promise.all(
          orderLines.map((orderLine)=> addSingleVariantToOrder(orderLine))
        )
      else message.error("No item is selected");
    } catch (err) {
      console.log(err);
    } finally {
      resetHandler();
      setProductVisible(false);
    }
  };

  function addSingleVariantToOrder(orderLine: OrderLine) {
    return addItemToOrderMutation({
      variables: {
        orderId: activeOrderId,
        productVariantId: orderLine.id,
        quantity: orderLine.quantity || 1,
        ...(orderLine.price && {
          customFields: {
            customPrice: +(orderLine.price * 100).toFixed(2),
          },
        }),
      },
      fetchPolicy: "network-only",
    });
  }

  function onAddItemSuccess(data: any) {
    const state = localStorage.getItem("province");
    if (state) {
      getShippingMethodsByState(state, 1, data.addItemToOrder);
    }
    dispatch<any>(setOrder(data.addItemToOrder));
    message.success("Item Added");
  }

  function onAddItemError(e:any) {
    message.error(e?.message || "Product is out of stock");
  }

  const orderLineChangeHandler = (lines: OrderLine[]) => {
    setOrderLines(lines.filter((v) => v.selected === true));
  };

  const resetHandler = () => {
    const checkboxes = document.querySelectorAll(".product-selector");
    checkboxes.forEach((checkbox) => {
      const span = checkbox.querySelector(".ant-checkbox") as HTMLSpanElement;
      const checkboxInput = checkbox.querySelector(
        ".ant-checkbox-input"
      ) as HTMLInputElement;
      if (checkboxInput.checked) {
        span.click();
        checkboxInput.checked = false;
        if (!checkboxInput.checked) {
          span.classList.remove("ant-checkbox-checked");
        }
      }
    });
    setOrderLines([]);
  };

  return (
    <div>
      <TwfModal
        visible={productVisible}
        onCancel={closeModalHandler}
        centered
        width={1220}
        footer={false}
        closeIcon={<Icon keyword="close" width={24} color="#0A1629" />}
      >
        <ProductList
          isModal={true}
          onOrderLineChange={orderLineChangeHandler}
        />
        <MarginBox mt={16}>
          <Row gutter={24} align="bottom" justify="end">
            <Col>
              <TwfButton
                color-twf="gray-line"
                size-twf="md"
                style={{ minWidth: "131px" }}
                onClick={closeModalHandler}
              >
                Close
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                color-twf="gray-line"
                size-twf="md"
                style={{ minWidth: "131px" }}
                onClick={resetHandler}
              >
                Clear
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                color-twf="primary"
                type="primary"
                size-twf="md"
                style={{ minWidth: "131px" }}
                onClick={addProductVariantsClickHandler}
                loading={loading}
              >
                Add
              </TwfButton>
            </Col>
          </Row>
        </MarginBox>
      </TwfModal>
    </div>
  );
};

export default CreateOrderProducts;
