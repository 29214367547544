import React, { useCallback, useEffect, useState } from "react";
import { CheckCircleTwoTone, IssuesCloseOutlined } from "@ant-design/icons";
import {
  Col,
  Dropdown,
  Grid,
  InputNumber,
  Menu,
  message,
  Row,
  Table,
  TablePaginationConfig,
} from "antd";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import {
  SortOrder,
  useDeleteProductFromComplianceMutation,
  useGetActiveChannelQuery,
  //useGetProductListLazyQuery,
  ProductVariant,
  useGetRefinedProductListLazyQuery,
} from "./../../../generated/graphql";
import { TableOptions } from "pages/orders/components/datatable";
import client from "api/graphql/connect";
import {
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
} from "./../../../graphql/mutations.graphql";
import { ProductFilter } from "../product/product-list";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { OrderLine } from "./../../orders/components/create/products";
import { TableRow } from "./row";

import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useUpdateProductVariantsMutation } from "generated/graphql";

const { useBreakpoint } = Grid;

interface Props {
  filter: ProductFilter;
  isModal: boolean;
  onOrderLineChange: (orderLines: OrderLine[]) => void;
}

const ProductsTable: React.FC<Props> = ({
  filter,
  isModal,
  onOrderLineChange,
}) => {
  const [updateProductVariantsMutation, { data }] =
  useUpdateProductVariantsMutation();
  const history = useNavigate();
  const breakpoints = useBreakpoint();
  /*const [getProductListQuery, { data: products, loading }] =
    useGetProductListLazyQuery({
      fetchPolicy: "network-only",
    });*/
  const [getRefinedProductListQuery, { data: products, loading }] =
    useGetRefinedProductListLazyQuery({
      fetchPolicy: "network-only",
    });

  const [isSuperadmin, setIsSuperAdmin] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    pagination: { current: 1, pageSize: 10 },
    sort: { id: SortOrder.Desc },
  });
  const [orderLines, setOrderLines] = useState<OrderLine[]>([]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("used-channel") === "__default_channel__") {
        setIsSuperAdmin(true);
      }
    }
  }, []);

  const activeChannel = useGetActiveChannelQuery();
  let brandkey: string | undefined;

  if (activeChannel) {
    brandkey =
      activeChannel?.data?.activeChannel?.customFields?.complianceBrandKey;
  }

  const [deleteProductFromComplianceMutation] =
    useDeleteProductFromComplianceMutation();

  const getProductList = useCallback(() => {
    getRefinedProductListQuery({
      variables: {
        options: {
          filter: {
            productType: { eq: filter.type },
            ...(filter.status === 2
              ? { status: { eq: 2 } }
              : { status: { lt: 2 } }),
            ...(filter.name && { name: { contains: filter.name } }),
          },
          skip:
            (tableOptions.pagination.pageSize || 10) *
            ((tableOptions.pagination.current || 1) - 1),
          take: tableOptions.pagination.pageSize,
          sort: tableOptions.sort,
        },
      },
    });
  }, [getRefinedProductListQuery, tableOptions, filter]);

  useEffect(() => {
    getProductList();
  }, [getProductList, tableOptions]);

  const deleteProductHandler = (id: string) => {
    const product = products?.refinedProductsSearch.items.filter(
      (product) => product.id === id
    );
    if (product && product.length !== 0) {
      const variants: any = product?.at(0)?.variants;

      if (variants) {
        loopcompliance(variants as any)
          .then(() => {
            finallyDeleteProductHandler(id);
          })
          .catch(() => {
            message.error("Some Server Error Occurred");
          });
      }
    }
  };

  const loopcompliance = async (variants: ProductVariant[]) => {
    const deletefromcomp = await Promise.all(
      variants.map(async (variant) => await deletefromcompliance(variant))
    );
    return deletefromcomp;
  };

  const deletefromcompliance = (
    variant: ProductVariant
  ): Promise<boolean | string> => {
    return new Promise(async (resolve, _) => {
      return deleteProductFromComplianceMutation({
        variables: {
          productKey: variant.sku,
          brandKey: brandkey as string,
        },
      })
        .then(() => {
          resolve(variant.sku);
        })
        .catch(() => {
          resolve(false);
        });
    });
  };

  const finallyDeleteProductHandler = useCallback((id: string) => {
    client
      .mutate({
        mutation: DELETE_PRODUCT,
        variables: { id },
      })
      .then(() => {
        message.success("Product deleted");
      });
  }, []);

  const archiveProductHandler = useCallback(
    (id: string) => {
      client
        .mutate({
          mutation: UPDATE_PRODUCT,
          variables: {
            input: {
              id,
              customFields: { status: filter.status === 1 ? 2 : 1 },
            },
          },
          refetchQueries: ["GetProductList"], // TODO: add another query here
        })
        .then(() => {
          message.success(
            `Product ${filter.status === 2 ? "unarchived" : "archived"}`
          );
        });
    },
    [filter.status]
  );

  const tableChangeHandler = (
    pagination: TablePaginationConfig,
    filters: any,
    sort: any
  ) => {
    setTableOptions({
      pagination,
      sort: sort.columnKey
        ? {
            [sort.columnKey]:
              sort.order === "ascend" ? SortOrder.Asc : SortOrder.Desc,
          }
        : tableOptions.sort,
    });
  };


  const updateDisplayOrder = async (product:any,displayOrder:number) => {
   


    return  await updateProductVariantsMutation({
         variables: { input: { id: product.productVariantId, customFields: { displayOrder } } },
       });
  };


  const updateAllDisplayOrders = async (array:any) => {
    const promises = array.map((items:any,index:number) =>updateDisplayOrder(items,index +1));
    try {
      await Promise.all(promises)
      .then(()=>{
        setTableData(array)
      })
      console.log('All displayOrders updated successfully');
    } catch (error) {
      console.error('Error occurred while updating displayOrders:', error);
    }
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setTableData((previous: any) => {
        const activeIndex = previous.findIndex((i: any) => i.key === active.id);
        const overIndex = previous.findIndex((i: any) => i.key === over?.id);
        const sortedData = arrayMove(previous, activeIndex, overIndex)
        updateAllDisplayOrders(sortedData)
        return sortedData
      });
    }
  };

  const compareDisplayOrder = (a:any, b:any) => {
    if (a.displayOrder === null && b.displayOrder === null) {
      return 0;
    } else if (a.displayOrder === null) {
      return 1;
    } else if (b.displayOrder === null) {
      return -1;
    } else {
      return a.displayOrder - b.displayOrder;
    }
  };

  useEffect(() => {
    let dataSource: any[] = [];
    products?.refinedProductsSearch.items?.forEach((product: any) => {
      if (product.channel) {
        let vintages = product.optionGroups.find(
          (item: any) => item.code === "vintage"
        );
        let vintagesString = "";
        let bottles = product.optionGroups.find(
          (item: any) => item.code === "bottle-size"
        );
        let bottlesString = "";
        vintages?.options.forEach((vintage: any) => {
          vintagesString = vintage.code.toString() + ", " + vintagesString;
        });
        bottles?.options.forEach((bottle: any) => {
          bottlesString = bottle.code.toString() + ", " + bottlesString;
        });
        let skuString = product.variants
          .map((variant: any) => variant.sku)
          .join(", ");
        let inventoryString = product.variants
          .map((variant: any) =>
            (variant.stockOnHand - variant.stockAllocated > 0
              ? variant.stockOnHand - variant.stockAllocated
              : 0
            ).toString()
          )
          .join(", ");
        let retailPriceString = Array.from(
          new Set(
            product.variants.map(
              (variant: any) => `$${(variant.price / 100).toFixed(2)}`
            )
          )
        ).join(", ");
        let compStatus = Array.from(
          new Set(
            product.variants.map((variant: any) =>
              getComplianceStatus(variant?.customFields?.shipComplianceVerified)
            )
          )
        ).join(", ");
        const row: any = {};

        row.key =  +product?.variants[0].id //Math.floor(Math.random() * 10000);
        row.product = product;
        row.productVariantId = +product?.variants[0].id
        row.displayOrder = product?.variants[0].customFields.displayOrder

        const mItems = [
          {
            key: `edit${product.id}`,
            label: "Edit Product",
            onClick: () => {
              if (product.variants[0].id) {
                history(`/products/edit/${product.variants[0].id}`);
              } else {
                message.error(
                  "The product data is damaged. Consider re adding it"
                );
              }
            },
          },
          {
            key: `archive${product.id}`,
            label:
              filter.status === 2 ? "Unarchive Product" : "Archive Product",
            onClick: () => {
              archiveProductHandler(product.id);
            },
          },
          {
            key: `delete${product.id}`,
            label: "Delete Product",
            disabled: !isSuperadmin,
            onClick: () => {
              deleteProductHandler(product.id);
            },
          },
        ];

        row.action = (
          <Row gutter={24}>
            <Col>
              <Dropdown
                overlay={
                  <Menu
                    items={mItems}
                    style={{
                      borderRadius: 12,
                      padding: "8px 12px",
                      border: "1px solid #E5E5E5",
                    }}
                  >
                    {/*
                      <Popconfirm
                      title="Are you sure to delete this product?"
                      onConfirm={() => deleteProductHandler(product.id)}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Menu.Item key={`delete${product.id}`}>
                        Delete Product
                      </Menu.Item>
                    </Popconfirm>
                    */}
                  </Menu>
                }
                placement="bottomRight"
              >
                <TwfButton shape="circle" type-twf="icon">
                  <Icon keyword="collapse" width={14} />
                </TwfButton>
              </Dropdown>
            </Col>
            {breakpoints.md && (
              <Col>
                <TwfButton shape="circle" type-twf="icon">
                  <Icon keyword="info" color="#3C64B1" />
                </TwfButton>
              </Col>
            )}
          </Row>
        );

        row.vintage = vintagesString;
        row.bottleSize = bottlesString;
        row.overridePrice = "";
        row.sku = skuString;
        row.inventory = inventoryString;
        row.retailPrice = retailPriceString;
        row.status = getStatus(product.customFields?.status);
        row.complianceStatus = compStatus;
        dataSource.push(row);
      }
    });

    setTableData(dataSource.sort(compareDisplayOrder));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, products, breakpoints.md, filter]);


  let columns: any = [
    { key: "sort" },
    {
      title: "Product",
      dataIndex: "product",
      width: 300,
      key: "name",
      sorter: {},
      render: (product: any) => {
        return !product.isVariant ? (
          <Row gutter={12} align="middle">
            <Col span={8}>
              {/*product.featuredAsset && (
                <img
                  src={product.featuredAsset?.preview}
                  alt={product.name}
                  width="50"
                  height="50"
                  style={{
                    backgroundColor: "#FAF6EE",
                    borderRadius: 12,
                    objectFit: "contain",
                  }}
                />
                )*/}
              {/*!product.featuredAsset && product.assets[0] && (
                <img
                  src={product.assets[0]?.preview}
                  alt={product.name}
                  width="50"
                  height="50"
                  style={{
                    backgroundColor: "#FAF6EE",
                    borderRadius: 12,
                    objectFit: "contain",
                  }}
                />
                )*/}
              {product?.variants?.at(0)?.featuredAsset && (
                <img
                  src={product?.variants?.at(0)?.featuredAsset?.preview}
                  alt={product.name}
                  width="50"
                  height="50"
                  style={{
                    backgroundColor: "#FAF6EE",
                    borderRadius: 12,
                    objectFit: "contain",
                  }}
                />
              )}
              {!product?.variants?.at(0)?.featuredAsset &&
                product?.variants?.at(0)?.assets?.length !== 0 && (
                  <img
                    src={product.variants?.at(0)?.assets?.at(0)?.preview}
                    alt={product.name}
                    width="50"
                    height="50"
                    style={{
                      backgroundColor: "#FAF6EE",
                      borderRadius: 12,
                      objectFit: "contain",
                    }}
                  />
                )}
            </Col>
            <Col span={16}>{product.name}</Col>
          </Row>
        ) : (
          <>
            {isModal && (
              <Row gutter={12} align="middle">
                <Col span={8}>
                  <Checkbox
                    onChange={(e) =>
                      orderLineFieldChangeHandler(
                        "selected",
                        e.target.checked,
                        product.variantId
                      )
                    }
                  />
                </Col>
                <Col span={16}>
                  <InputNumber
                    min={0}
                    size="large"
                    defaultValue={1}
                    style={{ borderRadius: 14 }}
                    onChange={(value) => {
                      orderLineFieldChangeHandler(
                        "quantity",
                        value,
                        product.variantId
                      );
                    }}
                  />
                </Col>
              </Row>
            )}
          </>
        );
      },
    },
    // {
    //   title: "Store",
    //   key: "channel",
    //   render: (product: any) => {
    //     if (product.product?.channel && product.product.channel.customFields) {
    //       return product.product.channel.customFields.brandName;
    //     }
    //   },
    // },
    {
      title: "Vintage",
      dataIndex: "vintage",
    },
    {
      title: "Bottle Size",
      dataIndex: "bottleSize",
    },
    {
      title: "SKU",
      dataIndex: "sku",
    },
    {
      title: "Inventory",
      dataIndex: "inventory",
      key: "customer",
    },
    {
      title: "Current Price",
      dataIndex: "retailPrice",
    },
    {
      title: "Override Price",
      dataIndex: "product",
      key: "overridePrice",
      render: (product: any) =>
        product.isVariant ? (
          <InputNumber
            min={0}
            size="large"
            formatter={(value: any) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ borderRadius: 14 }}
            onChange={(value) => {
              orderLineFieldChangeHandler("price", value, product.variantId);
            }}
          />
        ) : (
          ""
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a: any, b: any) => (a.english > b.english ? 1 : -1),
        multiple: 1,
      },
    },
  ];

  if (isSuperadmin)
    columns.push({
      title: "Verified",
      dataIndex: "complianceStatus",
      key: "complianceStatus",
      render: (isVerified: string) => {
        return (
          <>
            {isVerified === "True" && (
              <CheckCircleTwoTone width={"2em"} twoToneColor="#52c41a" />
            )}
            {isVerified === "False" && (
              <IssuesCloseOutlined style={{ color: "red" }} />
            )}
          </>
        );
      },
    });

  columns.push({
    title: "",
    width: breakpoints.md ? 120 : 50,
    fixed: "right",
    key: "action",
    dataIndex: "action",
  });

  if (isModal) columns = columns.filter((c: any) => c.key !== "action");
  else columns = columns.filter((c: any) => c.key !== "overridePrice");

  const getStatus = (status: number) => {
    return status === 1 ? "Active" : status === 0 ? "InActive" : "Archived";
  };

  const getComplianceStatus = (status: boolean) => {
    return status ? "True" : "False";
  };

  const orderLineFieldChangeHandler = (
    field: string,
    value: any,
    variantId: string
  ) => {
    const orderLine = orderLines.find((s) => s.id === variantId);
    if (orderLine)
      setOrderLines((lines) => [
        ...lines.filter((s) => s.id !== variantId),
        { ...orderLine, [field]: value },
      ]);
    else {
      setOrderLines((lines) => [...lines, { id: variantId, [field]: value }]);
    }
  };

  useEffect(() => {
    onOrderLineChange(orderLines);
    // eslint-disable-next-line
  }, [orderLines]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const filterNoChannel = (product: any) => {
  //   return product.channel !== null;
  // };
 
  return (
    <React.Fragment>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={tableData.map((i: any) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: TableRow,
              },
            }}
            loading={loading}
            columns={columns}
            dataSource={tableData}
            pagination={{
              ...tableOptions.pagination,
              total: products?.refinedProductsSearch.totalItems,
              position: ["bottomCenter"],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} Items`,
              showSizeChanger: true,
            }}
            onChange={tableChangeHandler}
            style={{ maxWidth: "max-content" }}
            scroll={{ x: "max-content" }}
            rowKey={"key"}
          />
        </SortableContext>
      </DndContext>
    </React.Fragment>
  );
};

export default ProductsTable;
