import React from "react";
import AppBar from "../../../component/app-bar";
import { Col, Row } from "antd";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";
import { useNavigate } from "react-router";

const CustomersMobileFooter: React.FC = () => {
  const history = useNavigate();

  return (
    <AppBar>
      <Row justify="space-around" align="middle">
        <Col>
          <TwfButton shape="circle" type-twf="icon">
            <Icon keyword="archive" color="#979797" width={24} />
          </TwfButton>
          <p>Archived customers</p>
        </Col>
        <Col>
          <TwfButton
            shape="circle"
            type-twf="icon"
            style={{
              width: 38,
              height: 38,
              backgroundColor: "#3C64B1",
            }}
            onClick={() => {
              history("/customers/create");
            }}
          >
            <Icon keyword="plus" color="#ffffff" width={24} />
          </TwfButton>
        </Col>
        <Col>
          <TwfButton shape="circle" type-twf="icon">
            <Icon keyword="export" color="#979797" width={24} />
          </TwfButton>
          <p>Export customers</p>
        </Col>
      </Row>
    </AppBar>
  );
};

export default CustomersMobileFooter;
