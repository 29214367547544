import { useEffect, useState } from "react";
import { Col, message, Row, Tabs } from "antd";
import { PageTitle } from "../../../component/header";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";
import { MarginBox } from "../../../component/margin";
import CustomerSingleGeneral from "../components/general";
import CustomerSingleOrder from "../components/order";
import CustomerSingleAddress from "../components/address";
import CustomerSingleClub from "../components/club";
import CustomerSingleCreditCard from "../components/cards";
// import CustomerSingleContacts from "../components/contacts";
import CustomerSingleNotes from "../components/notes";
import CustomerSinglePurchased from "../components/purchased";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../component/button/back";
import { useGetCustomerQuery } from "generated/graphql";

const { TabPane } = Tabs;

const CustomerSingle = () => {
  const { id } = useParams<{ id: string }>();
  const cid:string = id as string;
  const history = useNavigate();
  const { data } = useGetCustomerQuery({
    variables: {
      id:cid,
    },
  });

  useEffect(()=>{
    if(data){
      if(!data.customer){
        message.error("No Such Customer Exists or is Deleted");
        history(-1);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])

  const [screen, setScreen] = useState("general");
  const [addressCreateVisible, setAddressCreateVisible] =
    useState<boolean>(false);
  const [cardCreateVisible, setCardCreateVisible] = useState<boolean>(false);
  // const [contactCreateVisible, setContactCreateVisible] =
  //   useState<boolean>(false);
  const [noteCreateVisible, setNoteCreateVisible] = useState<boolean>(false);

  return (
    <div>
      <Row justify="space-between" gutter={[20, 20]}>
        <Col>
          <Row>
            <Col>
              <Row gutter={12} align="middle">
                <Col>
                  <BackButton />
                </Col>
                <Col>
                  <PageTitle>
                    {data?.customer?.firstName} {data?.customer?.lastName}
                  </PageTitle>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {screen !== "general" &&
          screen !== "productsPurchased" &&
          screen !== "club" && (
            <Col>
              <Row gutter={24}>
                <Col>
                  <TwfButton
                  // temp remove ORDER btn from customer single
                  style={{ display: screen === "order"? 'none':'' }}
                    color-twf="primary"
                    type="primary"
                    size-twf="lg"
                    type-twf="wide"
                    onClick={() => {
                      screen === "address" && setAddressCreateVisible(true);
                      screen === "order" && history("/orders/create");
                      screen === "creditCards" && setCardCreateVisible(true);
                      // screen === "contacts" && setContactCreateVisible(true);
                      screen === "customerNotes" && setNoteCreateVisible(true);
                    }}
                  >
                    <Icon keyword="plus" color="#ffffff" />
                    Create New&nbsp;
                    {
                      {
                        order: "Order",
                        address: "Address",
                        creditCards: "Card",
                        contacts: "Contact",
                        customerNotes: "Note",
                        default: "Order",
                      }[screen]
                    }
                  </TwfButton>
                </Col>
              </Row>
            </Col>
          )}
      </Row>
      <MarginBox mt={22}>
        <Tabs
          activeKey={screen}
          onTabClick={(key: any) => {
            setScreen(key);
          }}
        >
          <TabPane key="general" tab="General">
            <CustomerSingleGeneral customerQuery={data} />
          </TabPane>
          <TabPane key="order" tab="Order">
            <CustomerSingleOrder />
          </TabPane>
          <TabPane key="address" tab="Address">
            <CustomerSingleAddress
              addressCreateVisible={addressCreateVisible}
              setAddressCreateVisible={setAddressCreateVisible}
            />
          </TabPane>
          <TabPane key="club" tab="Club">
            <CustomerSingleClub />
          </TabPane>
          <TabPane key="creditCards" tab="Credit Cards">
            <CustomerSingleCreditCard
              cardCreateVisible={cardCreateVisible}
              setCardCreateVisible={setCardCreateVisible}
              emailAddress={data?.customer?.emailAddress}
            />
          </TabPane>
          {/* <TabPane key="contacts" tab="Contacts">
            <CustomerSingleContacts
              contactCreateVisible={contactCreateVisible}
              setContactCreateVisible={setContactCreateVisible}
            />
          </TabPane> */}
          <TabPane key="customerNotes" tab="Customer Notes">
            <CustomerSingleNotes
              noteCreateVisible={noteCreateVisible}
              setNoteCreateVisible={setNoteCreateVisible}
            />
          </TabPane>
          <TabPane key="productsPurchased" tab="Products Purchased">
            <CustomerSinglePurchased />
          </TabPane>
        </Tabs>
      </MarginBox>
    </div>
  );
};

export default CustomerSingle;
