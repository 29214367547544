import React, { useEffect } from "react";
import TwfModal from "../../../component/modal";
import Icon from "../../../assets/icons";
import ModalTitle from "../../../component/modal/title";
import { BlockTitle, H3 } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import { Col, DatePicker, Form, message, Row, Select } from "antd";
import TwfButton from "../../../component/button";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import TextEditor from "component/textEditor/textEditor";
import moment from "moment";
import {
  UpdateComplianceInput,
  useGetComplianceLazyQuery,
  useGetStateListQuery,
  useUpdateComplianceMutation,
  useGetActiveChannelQuery
} from "generated/graphql";

const { Item } = Form;
const { Option } = Select;

interface ComponentProps {
  visible?: boolean;
  setVisible: (value: boolean) => void;
  currentId?: string;
  setCurrentId: (value: string) => void;
}

const EditCompliance: React.FC<ComponentProps> = ({
  visible,
  setVisible,
  currentId,
  setCurrentId,
}) => {
  const [form] = Form.useForm();
  const breakpoints = useBreakpoint();
  const { data: states } = useGetStateListQuery();
  const [getComplianceQuery, { data }] = useGetComplianceLazyQuery();
  const [updateComplianceMutation] = useUpdateComplianceMutation();

  const getActiveChannelQuery = useGetActiveChannelQuery();

  let isAdmin = false;

  let mainAdmin = false;

  if(getActiveChannelQuery?.data){
    if(Number(getActiveChannelQuery.data.activeChannel.id)==1){
      isAdmin = true;
    }
  }

  if(localStorage){
    if(localStorage.getItem("used-channel")==="__default_channel__"){
      mainAdmin = true;
    }
  }

  useEffect(() => {
    if (currentId) getComplianceQuery({ variables: { id: currentId } });
  }, [currentId, getComplianceQuery]);

  useEffect(() => {
    if (data?.compliance)
      form.setFieldsValue({
        ...data.compliance,
        holdDate: data.compliance.holdDate
          ? moment(data.compliance.holdDate)
          : null,
        stateId: data.compliance.state.id,
      });
  }, [data, form]);

  const submitFormHandler = (formData: UpdateComplianceInput): void => {
    updateComplianceMutation({
      variables: { input: { ...formData, id: currentId! } },
      refetchQueries: ["GetComplianceList"],
    })
      .then(({ data }) => {
        if (data?.updateCompliance.__typename === "Compliance") {
          message.success("Compliance updated");
          setVisible(false);
          form.resetFields();
          setCurrentId("");
        }
      })
      .catch((error) => message.error(error.message));
  };

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closeIcon={<Icon keyword="close" />}
      centered
      width={662}
      footer={false}
    >
      <ModalTitle>
        <H3>Edit Compliance</H3>
      </ModalTitle>
      <MarginBox mt={32}>
        <Form form={form} layout="vertical" onFinish={submitFormHandler}>
          <Row>
            <Col span={24}>
              <Item
                label="State"
                name="stateId"
                rules={[
                  {
                    required: true,
                    message: "Please enter the state!",
                  },
                ]}
              >
                <Select
                  disabled={!mainAdmin}
                  showSearch
                  options={states?.states.map((state) => ({
                    label: state.name,
                    value: state.id,
                  }))}
                  style={{ width: "100%" }}
                  placeholder="State"
                  filterOption={(input, option) =>
                    (option!.label as string)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Item>
            </Col>
            <Col span={24}>
              <Item label="Status" name="status">
                <Select
                  disabled={!mainAdmin}
                  suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
                  placeholder="Select"
                >
                  <Option value={1}>Active</Option>
                  <Option value={0}>Inactive</Option>
                </Select>
              </Item>
            </Col>
            <Col span={24}>
              <BlockTitle style={{ marginBottom: 0 }}>
                Shipping Advisory
              </BlockTitle>
              <p style={{ color: "#7D8592", marginBottom: 6 }}>
                Appears in the cart and checkout.
              </p>
              <Item name="shippingAdvisory">
                <TextEditor/>
              </Item>
            </Col>
            <Col span={24}>
              <Item label="Weather Hold Date" name="holdDate">
                <DatePicker disabled={!mainAdmin}/>
              </Item>
            </Col>
            <Col span={24}>
              <BlockTitle style={{ marginBottom: 0 }}>
                Compliance Advisory
              </BlockTitle>
              <p style={{ color: "#7D8592", marginBottom: 6 }}>
                Appears on the product list and drilldown pages.
              </p>
              <Item name="complianceAdvisory">
                <TextEditor/>
              </Item>
            </Col>
            <Col span={24}>
              <BlockTitle style={{ marginBottom: 0 }}>
                Rules and Regulations
              </BlockTitle>
              <Item name="rules">
                <TextEditor disable={!mainAdmin}/>
              </Item>
            </Col>
            <Col span={24}>
              <BlockTitle>Note</BlockTitle>
              <Item name="note">
                <TextEditor disable={!mainAdmin}/>
              </Item>
            </Col>
          </Row>
          <MarginBox mt={32}>
            <Row justify="end" gutter={breakpoints.md ? [24, 24] : [12, 12]}>
              <Col>
                <TwfButton
                  color-twf="gray-line"
                  size-twf="md"
                  type-twf="wide"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Cancel
                </TwfButton>
              </Col>
              <Col>
                <TwfButton
                  type="primary"
                  color-twf="primary"
                  size-twf="md"
                  type-twf="wide"
                  htmlType="submit"
                >
                  Save
                </TwfButton>
              </Col>
            </Row>
          </MarginBox>
        </Form>
      </MarginBox>
    </TwfModal>
  );
};

export default EditCompliance;
