import React from "react";
import { Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { TwfCard } from "../../../../component/card";
import Icon from "../../../../assets/icons";
import { MarginBox } from "../../../../component/margin";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
const { Item } = Form;
const { Option } = Select;

const DiscountGeneral: React.FC = () => {
  const breakpoints = useBreakpoint();
  return (
    <TwfCard style={{ padding: breakpoints.md ? "32px 48px" : "15px" }}>
      <Row gutter={64}>
        <Col md={{ span: 9 }} span={24}>
          <Item label="Type">
            <Select
              placeholder="Select"
              suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
            >
              <Option value="type1">Type 1</Option>
              <Option value="type1">Type 1</Option>
            </Select>
          </Item>
          <Item label="Name">
            <Input />
          </Item>
          <Item label="Code">
            <Input />
          </Item>
          <Item label="Available To">
            <Select
              placeholder="Select"
              suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
            >
              <Option value="type1">Type 1</Option>
              <Option value="type1">Type 1</Option>
            </Select>
          </Item>
          <Item label="Product Selection">
            <Select
              placeholder="Select"
              suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
            >
              <Option value="type1">Type 1</Option>
              <Option value="type1">Type 1</Option>
            </Select>
          </Item>
          <Row gutter={24}>
            <Col span={12}>
              <Item label="Start Date">
                <DatePicker />
              </Item>
            </Col>
            <Col span={12}>
              <Item label="End Date">
                <DatePicker />
              </Item>
            </Col>
          </Row>
        </Col>
        <Col md={{ span: 9 }} span={24}>
          <Item label="Limited Use">
            <Select
              placeholder="Select"
              suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
            >
              <Option value="type1">Type 1</Option>
              <Option value="type1">Type 1</Option>
            </Select>
          </Item>
          <MarginBox mt={32} mb={32}>
            <Item>
              <Checkbox>Allow Show Coupon on List in POS</Checkbox>
            </Item>
          </MarginBox>
          <Item label="POS Coupon Name">
            <Input />
          </Item>
        </Col>
      </Row>
    </TwfCard>
  );
};

export default DiscountGeneral;
