import { Form, FormItemProps, Select, SelectProps } from "antd";
import { US_STATES } from "data/us-states";

export default function USStateSelector({
  selectProps,
  ...rest
}: FormItemProps & { selectProps?: SelectProps }) {
  return (
    <Form.Item {...rest}>
      <Select
        showSearch
        {...selectProps}
        optionFilterProp="children"
        placeholder={selectProps?.placeholder ?? "State"}
      >
        {US_STATES.map((state) => {
          return (
            <Select.Option
              key={state.code}
              value={state.code}
              style={{ color: "black" }}
            >
              {state.name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
}
