import React, { FC, Fragment, useState } from "react";
import { Col, Row, Space, Table } from "antd";
import { PageTitle } from "../../../component/header";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";
import ShadowCard from "../../../component/card";
import NewCard from "./new-card";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const pageData = [
  {
    key: 1,
    cardNumber: "12331445122346",
    isDefault: true,
    expiry: "02/13/2024",
  },
];

const CreditCardsPage: FC = () => {
  const breakpoints = useBreakpoint();
  const [newVisible, setNewVisible] = useState<boolean>(false);

  const columns: any = [
    {
      title: "Card Number",
      dataIndex: "cardNumber",
    },
    {
      title: "Default",
      dataIndex: "isDefault",
      render: (isDefault: boolean) => {
        return <Fragment>{isDefault ? "Y" : "N"}</Fragment>;
      },
    },
    {
      title: "Expiry",
      dataIndex: "expiry",
    },
    {
      title: "",
      width: 250,
      render: (data: any) => {
        return (
          <Fragment>
            <Space>
              <TwfButton type-twf="icon" size-twf="md">
                <Icon
                  keyword="edit"
                  color="#3C64B1"
                  width={breakpoints.md ? 18 : 16}
                />
              </TwfButton>
              <TwfButton type-twf="icon" size-twf="md">
                <Icon
                  keyword="delete"
                  color="#FF4C61"
                  width={breakpoints.md ? 18 : 16}
                />
              </TwfButton>
              <TwfButton type="link">Make as Default</TwfButton>
            </Space>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <Row gutter={[12, 12]} align="middle" justify="space-between">
          <Col span={24} md={{ span: 12 }}>
            <PageTitle>Credit Cards</PageTitle>
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <TwfButton
              type="primary"
              size-twf="lg"
              onClick={() => {
                setNewVisible(true);
              }}
            >
              <Icon keyword="plus" color="#ffffff" />
              Add New card
            </TwfButton>
          </Col>
        </Row>
        <ShadowCard>
          <Table
            columns={columns}
            dataSource={pageData}
            pagination={false}
            scroll={{ x: 600 }}
          />
        </ShadowCard>
        <NewCard visible={newVisible} setVisible={setNewVisible} />
      </Space>
    </Fragment>
  );
};

export default CreditCardsPage;
