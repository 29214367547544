import { Table, notification } from "antd";
import TwfButton from "component/button";
import { TwfCard } from "component/card";
import Container from "component/container";
import { MarginBox } from "component/margin";
import { useGetCustomerReportLazyQuery } from "generated/graphql";
import { s2ab } from "utils/excel";
import * as XLSX from "xlsx";

export default function CustomerReport() {
  const [fetchData, { loading }] = useGetCustomerReportLazyQuery();

  const generateExcel = async (data: Record<string, any>[]) => {
    try {
      const worksheetData = data;

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(worksheetData);

      ws["!cols"] = [];
      if (worksheetData.length > 0) {
        for (let i = 0; i < Object.values(worksheetData[0]).length; i++) {
          if (i === 2) {
            ws["!cols"].push({ wch: 25 }); // email
          } else {
            ws["!cols"].push({ wch: 20 });
          }
        }
      }

      XLSX.utils.book_append_sheet(wb, ws, "Report");

      const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "customer-report.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };

  return (
    <Container maxWidth="800px">
      <TwfCard>
        <MarginBox mt={12} mb={56}>
          <Table
            columns={[
              {
                title: "Name",
                key: "name",
                dataIndex: "name",
              },
              {
                title: "",
                key: "action",
                align: "center",
                render: () => {
                  return (
                    <TwfButton
                      onClick={async () => {
                        const { data } = await fetchData({
                          onError: (error) => {
                            notification.error({
                              message: "Error",
                              description: error.message,
                            });
                          },
                        });
                        if (data?.customerReport) {
                          generateExcel(
                            JSON.parse(data?.customerReport ?? "[]")
                          );
                        }
                      }}
                      loading={loading}
                    >
                      Download
                    </TwfButton>
                  );
                },
              },
            ]}
            dataSource={[
              {
                name: "Customer Report",
              },
            ]}
            pagination={false}
          />
        </MarginBox>
      </TwfCard>
    </Container>
  );
}
