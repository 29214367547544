import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const PhotoIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 27 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.4202 20.6394H2.58023C1.25423 20.6394 0.240234 19.6254 0.240234 18.2994V2.69937C0.240234 1.37337 1.25423 0.359375 2.58023 0.359375H24.4202C25.7462 0.359375 26.7602 1.37337 26.7602 2.69937V18.2994C26.7602 19.6254 25.7462 20.6394 24.4202 20.6394ZM2.58023 1.91937C2.11223 1.91937 1.80023 2.23137 1.80023 2.69937V18.2994C1.80023 18.7674 2.11223 19.0794 2.58023 19.0794H24.4202C24.8882 19.0794 25.2002 18.7674 25.2002 18.2994V2.69937C25.2002 2.23137 24.8882 1.91937 24.4202 1.91937H2.58023Z"
      fill={color ? color : "#000000"}
    />
    <path
      d="M17.3993 9.72047C15.6833 9.72047 14.2793 8.31647 14.2793 6.60047C14.2793 4.88447 15.6833 3.48047 17.3993 3.48047C19.1153 3.48047 20.5193 4.88447 20.5193 6.60047C20.5193 8.31647 19.1153 9.72047 17.3993 9.72047ZM17.3993 5.04047C16.5413 5.04047 15.8393 5.74247 15.8393 6.60047C15.8393 7.45847 16.5413 8.16047 17.3993 8.16047C18.2573 8.16047 18.9593 7.45847 18.9593 6.60047C18.9593 5.74247 18.2573 5.04047 17.3993 5.04047Z"
      fill={color ? color : "#000000"}
    />
    <path
      d="M21.5346 20.4071L8.82061 8.47306L1.56661 15.1811L0.474609 14.0891L8.82061 6.28906L22.6266 19.3151L21.5346 20.4071Z"
      fill={color ? color : "#000000"}
    />
    <path
      d="M25.5114 16.5069L21.2994 13.0749L17.7894 15.9609L16.7754 14.7129L21.2994 11.0469L26.4474 15.2589L25.5114 16.5069Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export default PhotoIcon;
