import React from "react";
import { Table } from "antd";
import { SettingPanelProps } from "./../index";

const BrandProfileSetting: React.FC<SettingPanelProps> = ({
  channel,
  columns,
  isMain,
}) => {
  const customFields = channel?.customFields;

  const isValidHttpUrl = (value: string) => {
    let url;

    try {
      url = new URL(value);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  const data = [
    {
      key: "logo",
      option: "Logo",
      value: customFields?.logo,
      type: "file",
      render: (value: string) => {
        if (isValidHttpUrl(value)) {
          return (
            <span>
              <a href={value} target="_blank" rel="noreferrer">
                LOGO LINK
              </a>
            </span>
          );
        } else {
          return value;
        }
      },
      readonly: isMain === true ? null : true,
    },
    {
      key: "favicon",
      option: "Favicon",
      value: customFields?.favicon,
      type: "file",
      render: (value: string) => {
        if (isValidHttpUrl(value)) {
          return (
            <span>
              <a href={value} target="_blank" rel="noreferrer">
                FAVICON LINK
              </a>
            </span>
          );
        } else {
          return value;
        }
      },
      readonly: isMain === true ? null : true,
    },
    {
      key: "legalEntityName",
      option: <b>Legal Entity Name</b>,
      value: customFields?.legalEntityName,
      render: (value: string) => <b>{value}</b>,
      readonly: isMain === true ? null : true,
    },
    {
      key: "headerColor",
      option: "Header Color",
      type: "color-picker",
      value: customFields?.headerColor,
      readonly: isMain === true ? null : true,
    },
    {
      key: "headerTextColor",
      option: "Header Text Color",
      type: "color-picker",
      value: customFields?.headerTextColor,
      readonly: isMain === true ? null : true,
    },
    {
      key: "mainBodyColor",
      option: "Main Body Color",
      type: "color-picker",
      value: customFields?.mainBodyColor,
      readonly: isMain === true ? null : true,
    },
    {
      key: "footerColor",
      option: "Footer Color",
      type: "color-picker",
      value: customFields?.footerColor,
      readonly: isMain === true ? null : true,
    },
    {
      key: "footerTextColor",
      option: "Footer Text Color",
      type: "color-picker",
      value: customFields?.footerTextColor,
      readonly: isMain === true ? null : true,
    },
    {
      key: "productBorderColor",
      option: "Product Border Color",
      type: "color-picker",
      value: customFields?.productBorderColor,
      readonly: isMain === true ? null : true,
    },
    {
      key: "productCardColor",
      option: "Product Card Color",
      type: "color-picker",
      value: customFields?.productCardColor,
      readonly: isMain === true ? null : true,
    },
    {
      key: "productTextColor",
      option: "Product Text Color",
      type: "color-picker",
      value: customFields?.productTextColor,
      readonly: isMain === true ? null : true,
    },
    {
      key: "buttonBackgroundColor",
      option: "Button Background Color",
      type: "color-picker",
      value: customFields?.buttonBackgroundColor,
      readonly: isMain === true ? null : true,
    },
    {
      key: "buttonTextColor",
      option: "Button Text Color",
      type: "color-picker",
      value: customFields?.buttonTextColor,
      readonly: isMain === true ? null : true,
    },
    {
      key: "fontColor",
      option: "Font Color",
      type: "color-picker",
      value: customFields?.fontColor,
      readonly: isMain === true ? null : true,
    },
    {
      key: "fontFamily",
      option: "Font Family",
      type: "font-picker",
      value: customFields?.fontFamily,
      readonly: isMain === true ? null : true,
    },
    {
      key: "brandPhone",
      option: "Phone #",
      value: customFields?.brandPhone,
      readonly: isMain === true ? null : true,
    },
    {
      key: "webmasterEmail",
      option: "Webmaster Email",
      value: customFields?.webmasterEmail,
      readonly: isMain === true ? null : true,
    },
    {
      key: "mainWebsite",
      option: "Main Website",
      value: customFields?.mainWebsite,
      readonly: isMain === true ? null : true,
      render: (value: string) => {
        if (isValidHttpUrl(value)) {
          return (
            <a href={value} target="_blank" rel="noreferrer">
              {value}
            </a>
          );
        } else {
          return value;
        }
      },
    },
    {
      key: "orderConfirmation",
      option: "Order Confirmation",
      value: customFields?.orderConfirmation,
      readonly: isMain === true ? null : true,
    },
    {
      key: "clubConfirmation",
      option: "Club Confirmation",
      value: customFields?.clubConfirmation,
      readonly: isMain === true ? null : true,
    },
    {
      key: "orderPrefix",
      option: "Order Prefix",
      value: customFields?.orderPrefix,
      readonly: isMain === true ? null : true,
    },
    {
      key: "complianceBrandKey",
      option: "Ship Compliance Brand Key",
      value: customFields?.complianceBrandKey,
      readonly: isMain === true ? null : true,
    },
    /*,
    {
      key: "shipCompliantKey",
      option: <span style={{ color: "#FB8832" }}>Ship Compliant Key</span>,
      render: (value: string) => (
        <span style={{ color: "#FB8832" }}>{value}</span>
      ),
      value: "Key",
      readonly: true,
    },*/
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      showHeader={false}
      style={{ width: "100%" }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default BrandProfileSetting;
