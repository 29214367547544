import React from "react";
import TwfModal from "../../../component/modal";
import Icon from "../../../assets/icons";
import ModalTitle from "../../../component/modal/title";
import { BlockTitle, H3 } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import TableTitle from "../../../component/table/title";
import TableItem from "../../../component/table/item";
import { Divider } from "antd";
import ComplianceBlockView from "./compliance-block";

interface Props {
  selectedId?: any;
  complianceVisible?: boolean;
  setComplianceVisible: (value: boolean) => void;
  customFields :any;
}

const ComplianceView: React.FC<Props> = ({
  complianceVisible,
  setComplianceVisible,
  customFields
}) => {

  const isJsonString = (str:string) =>{
    try {
       JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }


  let compresponse = [];
  let stringresp: string = "";
  
  if(customFields){
    if(customFields?.complianceresponse){
      if(isJsonString(customFields?.complianceresponse)){
        compresponse = JSON.parse(customFields?.complianceresponse!);
      }else{
        stringresp = customFields?.complianceresponse!;
        if(stringresp.indexOf("ShippingService not found in system")!==-1){
          stringresp = "Shipping/Pickup Method not found in shipcompliant";
        }
      }
    }
  }

  return (
    <TwfModal
      visible={complianceVisible}
      onCancel={() => {
        setComplianceVisible(false);
      }}
      centered
      width={746}
      footer={false}
      closeIcon={<Icon keyword="close" width={24} color="#0A1629" />}
    >
      <MarginBox mb={24}>
        <ModalTitle>
          <H3>View Compliance</H3>
        </ModalTitle>
        <TableTitle>Last Compliance check date: {new Date(customFields?.compliancetime!).toLocaleString()}</TableTitle>
            <TableItem>Compliance Status: {customFields?.complianceStatus===1? "Passed":"Failed"}</TableItem>
        <Divider />

        {
          customFields?.complianceStatus===1 &&
          <>
        
          <TableItem>
            {stringresp}
          </TableItem>
          </>
        }
        
        {
          customFields?.complianceStatus===0 &&
          <>
          <BlockTitle>Not Compliant</BlockTitle>
          <TableItem>
            {stringresp}
          </TableItem>
          </>
        }

        {
          (stringresp.length===0 && compresponse.length>0) ? compresponse.map((compdata: any,index:number)=>(
            <ComplianceBlockView key={index+"cb"} compdata={compdata} index={index}/>
          )):<div>
            </div>
        }

      </MarginBox>
    </TwfModal>
  );
};

export default ComplianceView;
