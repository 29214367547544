import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const DuplicateIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66699 13.0013C2.66699 11.7133 3.71233 10.668 5.00033 10.668H8.33366C8.59888 10.668 8.85323 10.7733 9.04077 10.9609C9.2283 11.1484 9.33366 11.4028 9.33366 11.668C9.33366 11.9332 9.2283 12.1875 9.04077 12.3751C8.85323 12.5626 8.59888 12.668 8.33366 12.668H5.00033C4.91192 12.668 4.82714 12.7031 4.76462 12.7656C4.70211 12.8281 4.66699 12.9129 4.66699 13.0013V27.0013C4.66699 27.1853 4.81633 27.3346 5.00033 27.3346H19.0003C19.0887 27.3346 19.1735 27.2995 19.236 27.237C19.2985 27.1745 19.3337 27.0897 19.3337 27.0013V23.668C19.3337 23.4028 19.439 23.1484 19.6266 22.9609C19.8141 22.7733 20.0684 22.668 20.3337 22.668C20.5989 22.668 20.8532 22.7733 21.0408 22.9609C21.2283 23.1484 21.3337 23.4028 21.3337 23.668V27.0013C21.3337 27.6201 21.0878 28.2136 20.6502 28.6512C20.2127 29.0888 19.6192 29.3346 19.0003 29.3346H5.00033C4.38149 29.3346 3.78799 29.0888 3.35041 28.6512C2.91282 28.2136 2.66699 27.6201 2.66699 27.0013V13.0013Z"
      fill={color ? color : "#000000"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.667 5.0013C10.667 3.7133 11.7123 2.66797 13.0003 2.66797H27.0003C28.2883 2.66797 29.3337 3.7133 29.3337 5.0013V19.0013C29.3337 19.6201 29.0878 20.2136 28.6502 20.6512C28.2127 21.0888 27.6192 21.3346 27.0003 21.3346H13.0003C12.3815 21.3346 11.788 21.0888 11.3504 20.6512C10.9128 20.2136 10.667 19.6201 10.667 19.0013V5.0013ZM13.0003 4.66797C12.9119 4.66797 12.8271 4.70309 12.7646 4.7656C12.7021 4.82811 12.667 4.9129 12.667 5.0013V19.0013C12.667 19.1853 12.8163 19.3346 13.0003 19.3346H27.0003C27.0887 19.3346 27.1735 19.2995 27.236 19.237C27.2985 19.1745 27.3337 19.0897 27.3337 19.0013V5.0013C27.3337 4.9129 27.2985 4.82811 27.236 4.7656C27.1735 4.70309 27.0887 4.66797 27.0003 4.66797H13.0003Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);
export default DuplicateIcon;
