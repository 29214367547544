import { Col, Form, Input, message, Row } from "antd";
import Center from "component/center";
import { H2 } from "component/header";
import { MarginBox } from "component/margin";
import { useRequestAdminPasswordResetMutation } from "generated/graphql";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import AuthLayoutCard from "../style/card";
import AuthLayoutContainer from "../style/container";
import AuthLayoutWrap from "../style/wrap";

const { Item } = Form;

const ForgetPassword = () => {
  const [form] = Form.useForm();

  const [requestAdminPasswordReset, { loading }] =
    useRequestAdminPasswordResetMutation();

  const submitFormHandler = (values: any) => {
    const email = values.email.trim();
    if (email.length !== 0) {
      requestAdminPasswordReset({
        variables: {
          emailAddress: email,
        },
      })
        .then((data) => {
          if (data?.data?.requestAdminPasswordReset?.id) {
            message.success(
              "A password reset link was sent. Click the link in the email to create a new password. If you do not receive an email within 5 minutes, please click the re-send email link below."
            );
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    } else {
      message.error("Please enter valid email address");
    }
  };

  return (
    <AuthLayoutContainer>
      <AuthLayoutCard>
        <AuthLayoutWrap>
          <Center>
            <H2>Please enter your email to request a password reset.</H2>
            <MarginBox mb={40} />
            <Form form={form} onFinish={submitFormHandler}>
              <Row align="middle" justify="center" gutter={[0, 24]}>
                <Col span={24}>
                  <Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter email address!",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Item>
                </Col>
                <Col span={24}>
                  <Item>
                    <TwfButton
                      type="primary"
                      color-twf="primary"
                      size-twf="lg"
                      type-twf="wide"
                      htmlType="submit"
                      loading={loading}
                      style={{ margin: "0 auto" }}
                    >
                      Request Reset
                      <Icon keyword="arrow-right" width={20} color="#fff" />
                    </TwfButton>
                  </Item>
                </Col>
              </Row>
            </Form>
          </Center>
        </AuthLayoutWrap>
      </AuthLayoutCard>
    </AuthLayoutContainer>
  );
};

export default ForgetPassword;
