import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const LineChartIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.70898 2.98047C2.70898 2.76498 2.62338 2.55832 2.47101 2.40594C2.31864 2.25357 2.11197 2.16797 1.89648 2.16797C1.681 2.16797 1.47433 2.25357 1.32196 2.40594C1.16959 2.55832 1.08398 2.76498 1.08398 2.98047V23.0221C1.08398 23.4706 1.44798 23.8346 1.89648 23.8346H21.6673C21.8828 23.8346 22.0895 23.749 22.2418 23.5967C22.3942 23.4443 22.4798 23.2376 22.4798 23.0221C22.4798 22.8066 22.3942 22.6 22.2418 22.4476C22.0895 22.2952 21.8828 22.2096 21.6673 22.2096H2.70898V2.98047Z"
      fill={color ? color : "#000000"}
    />
    <path
      d="M24.1374 8.4289C24.2809 8.27488 24.3591 8.07116 24.3553 7.86067C24.3516 7.65017 24.2664 7.44934 24.1175 7.30048C23.9686 7.15161 23.7678 7.06634 23.5573 7.06263C23.3468 7.05891 23.1431 7.13705 22.9891 7.28057L16.7924 13.4772L12.7624 9.44723C12.6101 9.29508 12.4035 9.20961 12.1882 9.20961C11.9729 9.20961 11.7664 9.29508 11.6141 9.44723L5.11407 15.9472C5.03424 16.0216 4.97021 16.1113 4.92581 16.211C4.8814 16.3106 4.85752 16.4182 4.8556 16.5273C4.85367 16.6364 4.87374 16.7448 4.9146 16.846C4.95547 16.9471 5.01629 17.039 5.09344 17.1162C5.1706 17.1933 5.2625 17.2542 5.36367 17.295C5.46484 17.3359 5.57321 17.356 5.6823 17.354C5.7914 17.3521 5.89899 17.3282 5.99865 17.2838C6.09832 17.2394 6.18802 17.1754 6.2624 17.0956L12.1882 11.1697L16.2182 15.1997C16.3706 15.3519 16.5771 15.4373 16.7924 15.4373C17.0077 15.4373 17.2142 15.3519 17.3666 15.1997L24.1374 8.4289Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export { LineChartIcon };
