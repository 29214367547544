import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const SortIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0071 2.86953C13.7904 2.65286 13.4546 2.65286 13.2379 2.86953L11.0604 5.04703H16.1737L14.0071 2.86953ZM13.2487 23.1279C13.4654 23.3445 13.8012 23.3445 14.0179 23.1279L16.1629 20.9829H11.1146L13.2487 23.1279ZM11.8946 19.2062C12.5446 19.2062 12.9887 18.5562 12.7504 17.9495L8.70957 7.61453C8.6131 7.37149 8.44587 7.16301 8.22953 7.01613C8.01319 6.86924 7.75773 6.79071 7.49624 6.79071C7.23475 6.79071 6.97929 6.86924 6.76295 7.01613C6.54661 7.16301 6.37937 7.37149 6.28291 7.61453L2.23124 17.9387C1.99291 18.5454 2.43707 19.1954 3.08707 19.1954C3.46624 19.1954 3.81291 18.957 3.95374 18.5995L4.72291 16.5412H10.2587L11.0279 18.5995C11.1687 18.9679 11.5154 19.2062 11.8946 19.2062ZM5.38374 14.7754L7.48541 9.1637L9.58707 14.7754H5.38374ZM22.8037 17.4837H17.0296L23.2262 8.50286C23.7246 7.78786 23.2046 6.80203 22.3379 6.80203H15.3179C14.8412 6.80203 14.4621 7.19203 14.4621 7.6687V7.67953C14.4621 8.1562 14.8521 8.5462 15.3179 8.5462H20.8321L14.6246 17.5162C14.1262 18.2312 14.6462 19.217 15.5129 19.217H22.7929C23.2696 19.217 23.6487 18.827 23.6487 18.3612C23.6537 18.2474 23.6355 18.1337 23.5953 18.0271C23.555 17.9206 23.4935 17.8233 23.4144 17.7412C23.3354 17.6592 23.2405 17.594 23.1355 17.5497C23.0306 17.5055 22.9177 17.483 22.8037 17.4837Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export default SortIcon;
