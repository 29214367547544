import styled from "styled-components";
import { Drawer } from "antd";

const MobileDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

export default MobileDrawer;
