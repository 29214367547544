import React, { FC, Fragment } from "react";
import { BlockTitle, PageTitle } from "component/header";
import { Space, Table } from "antd";
import ShadowCard from "../../../component/card";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
const data = [
  {
    key: 1,
    title: "Username",
    value: "sharathchandra"
  },
  {
    key: 2,
    title: "First Name",
    value: "Sharath"
  },
  {
    key: 3,
    title: "Last Name",
    value: "Chandra"
  },
  {
    key: 4,
    title: "Email",
    value: "chandra@thewinefoundry.com"
  },
  {
    key: 5,
    title: "Signup Date",
    value: "2021-05-26 17:04:43"
  },
  {
    key: 6,
    title: "Last Login",
    value: "2021-06-26 12:00:20"
  },
  {
    key: 7,
    title: "Status",
    value: "Active"
  }
];

const MyAccountPage: FC = () => {
  const breakpoints = useBreakpoint();
  const columns: any = [
    {
      title: "",
      dataIndex: "title"
    },
    {
      title: "",
      dataIndex: "value"
    }
  ];
  return (
    <Fragment>
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        {
          breakpoints.md && <PageTitle>My Account</PageTitle>
        }
        <ShadowCard style={{ padding: "32px 0" }}>
          <div style={{ padding: "0 12px" }}>
            <BlockTitle>Main Information</BlockTitle>
          </div>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </ShadowCard>
      </Space>
    </Fragment>
  );
};

export default MyAccountPage;