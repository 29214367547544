import styled from "styled-components";

const AuthStepText = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #3f8cff;
`;

export default AuthStepText;
