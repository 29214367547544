import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const InfoIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
      stroke={color ? color : "#000000"}
      strokeWidth="2"
    />
    <path
      d="M8.625 5.25H9"
      stroke={color ? color : "#000000"}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M7.5 8.25H9V12"
      stroke={color ? color : "#000000"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 12H10.5"
      stroke={color ? color : "#000000"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default InfoIcon;
