/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import {
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  Row,
  Radio,
  message,
  Checkbox,
} from "antd";
import { BlockTitle, H2 } from "../../../component/header";
import TwfButton from "../../../component/button";
import { MarginBox } from "../../../component/margin";
import BackButton from "../../../component/button/back";
import { wineCategories } from "enums/enums";
import client from "./../../../api/graphql/connect";
import { CREATE_PRODUCT } from "graphql/mutations.graphql";
import {
  Asset,
  LanguageCode,
  SortOrder,
  useAddProductToComplianceMutation,
  useAppellationsQuery,
  useCheckSkuLazyQuery,
  useCreateAppellationMutation,
  useCreateVarietalMutation,
  useCreateWineLabelMutation,
  useCreateWineTypeMutation,
  useDeleteAppellationMutation,
  useDeleteVarietalMutation,
  useDeleteWineLabelMutation,
  useDeleteWineTypeMutation,
  useGetActiveChannelQuery,
  useVarietalsQuery,
  useWineLabelsQuery,
  useWineTypesQuery,
} from "generated/graphql";
import AssetSelector from "pages/assets/component/asset-selector";
import Variants, { Variant, OptionGroup } from "../variants/variants";
import {
  addOptionGroupToProduct,
  createProductOptionGroup,
  createProductVariants,
} from "../utils/common";

import { GET_PRODUCT_LIST } from "graphql/queries.graphql";
import Container from "component/container";
import DropdownAdder from "../components/dropdown-adder";
import { reject } from "lodash";
import AssetSelectorCreate from 'pages/assets/component/asset-selector-create';
import { useSelector } from 'react-redux';
import { productAssetsAction } from 'store/action/ui.action';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';

const { Item } = Form;
const { useBreakpoint } = Grid;

export interface CreateProductInput {
  name: string;
  productType?: number;
  saleChannel?: [];
  productUrl?: string;
  status?: number;
  bottlingDate?: Date;
  harvestDate?: Date;
  wineCategory?: number;
  varietal?: number;
  appellation?: number;
  wineType?: number;
  label?: number;
}

let productId = "";

const CreateNewProduct = () => {
  const [form] = Form.useForm();
  const breakpoints = useBreakpoint();
  const dispatch:AppDispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false);
  const selectedProductAssets = useSelector((state: { ui: { createProductAssets: any[] } }) => state.ui.createProductAssets);
  const [optionGroups, setOptionGroups] = useState<OptionGroup[]>([
    { name: "", options: [] },
  ]);
  const [variants, setVariants] = useState<Variant[]>([
    { name: "", options: [], sku: "", price: 0, stock: 0 },
  ]);

  const [checkIfSKUExists] = useCheckSkuLazyQuery();

  const [createVarietalMutation] = useCreateVarietalMutation();
  const [createAppellationMutation] = useCreateAppellationMutation();
  const [createWineTypeMutation] = useCreateWineTypeMutation();
  const [createWineLabelMutation] = useCreateWineLabelMutation();

  const [deleteVarietalMutation] = useDeleteVarietalMutation();
  const [deleteAppellationMutation] = useDeleteAppellationMutation();
  const [deleteWineTypeMutation] = useDeleteWineTypeMutation();
  const [deleteWineLabelMutation] = useDeleteWineLabelMutation();

  const { data: channelWineLabels } = useWineLabelsQuery({
    variables: {
      options: {},
    },
  });

  const { data: channelWineTypes } = useWineTypesQuery({
    variables: {
      options: {},
    },
  });

  const { data: channelAppellations } = useAppellationsQuery({
    variables: {
      options: {},
    },
  });

  const { data: channelVarietals } = useVarietalsQuery({
    variables: {
      options: {},
    },
  });

  const [labels, setLabels] = useState<any[]>([]);
  const [wineTypes, setWineTypes] = useState<any[]>([]);
  const [varietals, setVarietals] = useState<any[]>([]);
  const [appellations, setAppellations] = useState<any[]>([]);

  useEffect(() => {
    if (
      channelWineLabels?.wineLabels?.items &&
      channelWineLabels.wineLabels.items.length !== 0
    ) {
      const ourWineLabels = channelWineLabels.wineLabels.items;
      const wineLabelsDropdown = [] as any;
      ourWineLabels.forEach((label) => {
        const wineLabelObject = {
          label: label.label,
          value: label.label,
        } as any;
        wineLabelsDropdown.push(wineLabelObject);
      });
      setLabels(wineLabelsDropdown);
    }

    form.setFieldsValue({
      saleChannel: ["1", "2", "3"],
    });
  }, [channelWineLabels]);

  useEffect(() => {
    if (
      channelWineTypes?.wineTypes?.items &&
      channelWineTypes.wineTypes.items.length !== 0
    ) {
      const ourWineTypes = channelWineTypes.wineTypes.items;
      const wineTypesDropdown = [] as any;
      ourWineTypes.forEach((type) => {
        const wineTypeObject = {
          label: type.type,
          value: type.type,
        } as any;
        wineTypesDropdown.push(wineTypeObject);
      });
      setWineTypes(wineTypesDropdown);
    }
  }, [channelWineTypes]);

  useEffect(() => {
    if (
      channelAppellations?.appellations?.items &&
      channelAppellations.appellations.items.length !== 0
    ) {
      const ourWineAppellations = channelAppellations.appellations.items;
      const appellationsDropdown = [] as any;
      ourWineAppellations.forEach((appellation) => {
        const appellationObject = {
          label: appellation.appellation,
          value: appellation.appellation,
        } as any;
        appellationsDropdown.push(appellationObject);
      });
      setAppellations(appellationsDropdown);
    }
  }, [channelAppellations]);

  useEffect(() => {
    if (
      channelVarietals?.varietals?.items &&
      channelVarietals.varietals.items.length !== 0
    ) {
      const ourWineVarietals = channelVarietals.varietals.items;
      const varietalsDropdown = [] as any;
      ourWineVarietals.forEach((varietal) => {
        const varietalObject = {
          label: varietal.varietal,
          value: varietal.varietal,
        } as any;
        varietalsDropdown.push(varietalObject);
      });
      setVarietals(varietalsDropdown);
    }
  }, [channelVarietals]);

  const activeChannel = useGetActiveChannelQuery();
  let brandkey: string | undefined;

  if (activeChannel) {
    brandkey =
      activeChannel?.data?.activeChannel?.customFields?.complianceBrandKey;
  }

  const [addProductToComplianceMutation] = useAddProductToComplianceMutation();

  const submitFormHandler = (formData: CreateProductInput) => {

console.log("test===========")
  
    const selectedVariants = variants.filter((v) => v.selected);
    if (
      optionGroups.length > 0 &&
      optionGroups.some((option) => option.name === "")
    ) {
      message.error("Option is not selected");
    } else if (
      optionGroups.length > 0 &&
      optionGroups.some((option) => option.options.length === 0)
    ) {
      message.error("No bottle size and/or vintage selected");
      return;
    } else if (selectedVariants.length === 0) {
      message.error("No bottle size and/or vintage selected");
    } else if (selectedVariants[0].sku.length === 0) {
      message.error("Please enter SKU for the product");
    } else if (selectedVariants[0].price === 0) {
      message.error("Please enter price for the product");
    } else if (selectedVariants[0].stock === 0) {
      message.error("Stock value cannot be zero");
    } else if (!selectedVarietal) {
      message.error("No varietal selected");
    } else if (!selectedAppellation) {
      message.error("No appellation selected");
    } else if (!selectedWineType) {
      message.error("No wine type selected");
    } else if (!selectedWineLabel) {
      message.error("No wine label selected");
    } else {
      formData.appellation = selectedAppellation;
      formData.varietal = selectedVarietal;
      formData.wineType = selectedWineType;
      formData.label = selectedWineLabel;
      setLoading(true);
      let finalvariants: Variant[] = [];
      //console.log(selectedVariants);
      loopcompliance(formData, selectedVariants)
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            if (typeof data[i] == "string") {
              const svariant = selectedVariants.filter(
                (v) => v.sku === data[i]
              );
              finalvariants = finalvariants.concat(svariant);
            }
          }

          if (finalvariants.length === 0) {
            setLoading(false);
            message.error(
              "The entered SKU seems already existed in our database."
            );
          } else {
            setVariants(finalvariants);
            processCreateProduct(formData);
          }
        })
        .catch(() => {
          setLoading(false);
          message.error("Some Server error occurred");
        });
    }
  };

  const loopcompliance = async (
    formData: CreateProductInput,
    selectedVariants: Variant[]
  ) => {
    const addtocomp = await Promise.all(
      selectedVariants.map(async (variant) => await checkifSkU(variant))
    );
    /*const addtocomp: string[] = [];
    for (const [_,variant] of selectedVariants.entries()){
      addtocomp.push(variant.sku);
    }*/
    return addtocomp;
  };

  const checkifSkU = (variant: Variant) => {
    return new Promise((resolve) => {
      checkIfSKUExists({
        variables: {
          sku: variant.sku,
        },
      })
        .then((data) => {
          if (!data.data?.checkSKU) {
            resolve(variant.sku);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          resolve(false);
        });
    });
  };

  //TODO HOLD we do not push product to ship compliance at this time!
  const addtocompliance = (
    formData: CreateProductInput,
    variant: Variant
  ): Promise<boolean | string> => {
    return new Promise(async (resolve) => {
      const forml = variant.name.split(" ");
      const ml = forml[forml.indexOf("ML") - 1];
      const prodcompliance = {
        BottleSizeML: Number(ml),
        BrandKey: brandkey as string,
        Description: formData.name,
        ProductType: "Wine",
        UnitPrice: variant.price,
        ProductKey: variant.sku,
        Varietal: varietals.filter((a) => a.value === formData.varietal)[0]
          .label,
        Vintage: 2010, //TODO fix the hard CODED data
        VolumeAmount: Number(ml),
        VolumeUnit: "Milliliter",
        ContainerType: "Bottle",
      } as any;
      return addProductToComplianceMutation({
        variables: {
          input: prodcompliance,
        },
        refetchQueries: [
          {
            query: GET_PRODUCT_LIST,
            variables: {
              options: {
                filter: { productType: { eq: 1 } },
                skip: 0,
                take: 10,
                sort: { id: SortOrder.Desc },
              },
            },
          },
        ],
      })
        .then(() => {
          resolve(variant.sku);
        })
        .catch(() => {
          resolve(false);
        });
      /*.then(() => {
          resolve(variant.sku);
        })
        .catch(() => {
          resolve(false);
        });*/
    });
  };

  const processCreateProduct = async (product: CreateProductInput) => {
    try {
      await createProduct(product);
      const optionGroupIds = await createProductOptionGroup(optionGroups);
      const result = await addOptionGroupToProduct(optionGroupIds, productId);
      await createProductVariants(result, variants, productId, product, selectedProductAssets.map((item: any) => item.id));
      
      setLoading(false);

      //reset the ui product asset state
      dispatch(productAssetsAction([]));
      window.location.href = "/products/";
    } catch (error: any) {
      message.error(error.message);
      setLoading(false);
    }
  };
  

  const createProduct = (product: CreateProductInput) => {
    return new Promise((resolve) => {
      client
        .mutate({
          mutation: CREATE_PRODUCT,
          variables: {
            input: {
              translations: [
                {
                  name: product.name,
                  slug: product.name.split(" ").join("-"),
                  description: "",
                  languageCode: LanguageCode.En,
                },
              ],
              assetIds:selectedProductAssets.map((item: any) => item.id),
              customFields: {
                productType: product.productType,
                saleChannel: product.saleChannel,
                productUrl: product.productUrl,
                status: product.status,
                bottlingDate: product.bottlingDate,
                harvestDate: product.harvestDate,
                wineCategory: product.wineCategory,
                varietal: product.varietal,
                appellation: product.appellation,
                wineType: product.wineType,
                label: product.label,
              },
            },
          },
          refetchQueries: [
            {
              query: GET_PRODUCT_LIST,
              variables: {
                options: {
                  filter: { productType: { eq: 1 } },
                  skip: 0,
                  take: 10,
                  sort: { id: SortOrder.Desc },
                },
              },
            },
          ],
        })
        .then(({ data }: any) => {
          if (data.createProduct.__typename === "Product") {
            message.success("Product created");
            productId = data.createProduct.id;
            resolve(true);
          }
        });
    });
  };

  const checkBoxItems = [
    {
      label: "Web",
      value: "1",
    },
    {
      label: "Club",
      value: "2",
    },
    {
      label: "Trade",
      value: "3",
    },
  ] as any;

  const initialValues = {
    productType: 1,
    saleChannel: "1",
    wineCategory: 1,
    status: 1,
  };

  const [selectedVarietal, setSelectedVarietal] = useState(null);
  const [selectedAppellation, setSelectedAppellation] = useState(null);
  const [selectedWineType, setSelectedWineType] = useState(null);
  const [selectedWineLabel, setSelectedWineLabel] = useState(null);

  const [name, setName] = useState("");
  const inputRef = useRef(null);

  const onNameChange = (event: any) => {
    setName(event.target.value);
  };

  const addItem = (e: any, type: string) => {
    e.preventDefault();
    if (name.length !== 0) {
      switch (type) {
        case "varietal":
          createVarietalMutation({
            variables: {
              input: {
                varietal: name,
              },
            },
            refetchQueries: ["Varietals"],
          })
            .then(() => {
              setName("");
            })
            .catch((error) => {
              message.error(error.message);
            });
          break;
        case "appellation":
          createAppellationMutation({
            variables: {
              input: {
                appellation: name,
              },
            },
            refetchQueries: ["Appellations"],
          })
            .then(() => {
              setName("");
            })
            .catch((error) => {
              message.error(error.message);
            });
          break;
        case "wineType":
          createWineTypeMutation({
            variables: {
              input: {
                type: name,
              },
            },
            refetchQueries: ["WineTypes"],
          })
            .then(() => {
              setName("");
            })
            .catch((error) => {
              message.error(error.message);
            });
          break;
        case "wineLabel":
          createWineLabelMutation({
            variables: {
              input: {
                label: name,
              },
            },
            refetchQueries: ["WineLabels"],
          })
            .then(() => {
              setName("");
            })
            .catch((error) => {
              message.error(error.message);
            });
          break;
      }
    }
  };

  const deleteItem = (value: string, type: string) => {
    switch (type) {
      case "varietal":
        const varietalId = channelVarietals!.varietals.items.filter(
          (varietal) => varietal.varietal === value
        )![0].id;
        deleteVarietalMutation({
          variables: {
            id: varietalId,
          },
          refetchQueries: ["Varietals"],
        })
          .then(() => {
            message.success("Varietal successfully deleted");
            setSelectedVarietal(null);
            form.resetFields(["varietal"]);
          })
          .catch((error) => {
            message.error(error.message);
          });
        break;
      case "appellation":
        const appellationId = channelAppellations!.appellations.items.filter(
          (appellation) => appellation.appellation === value
        )![0].id;
        deleteAppellationMutation({
          variables: {
            id: appellationId,
          },
          refetchQueries: ["Appellations"],
        })
          .then(() => {
            message.success("Appellation successfully deleted");
            setSelectedAppellation(null);
            form.resetFields(["appellation"]);
          })
          .catch((error) => {
            message.error(error.message);
          });
        break;
      case "wineType":
        const wineTypeId = channelWineTypes!.wineTypes.items.filter(
          (wineType) => wineType.type === value
        )![0].id;
        deleteWineTypeMutation({
          variables: {
            id: wineTypeId,
          },
          refetchQueries: ["WineTypes"],
        })
          .then(() => {
            message.success("Winetype successfully deleted");
            setSelectedWineType(null);
            form.resetFields(["appellation"]);
          })
          .catch((error) => {
            message.error(error.message);
          });
        break;
      case "wineLabel":
        const wineLabelId = channelWineLabels!.wineLabels.items.filter(
          (wineLabel) => wineLabel.label === value
        )![0].id;
        deleteWineLabelMutation({
          variables: {
            id: wineLabelId,
          },
          refetchQueries: ["WineLabels"],
        })
          .then(() => {
            message.success("Winetype successfully deleted");
            setSelectedWineLabel(null);
            form.resetFields(["appellation"]);
          })
          .catch((error) => {
            message.error(error.message);
          });
        break;
    }
  };



  return (
    <Container maxWidth="1000px">
      <Row justify="space-between" gutter={[24, 24]}>
        <Col>
          <Row>
            <Col>
              <Row align="middle" gutter={12}>
                <Col>
                  <BackButton />
                </Col>
                <Col>
                  <H2>Create New Product</H2>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <MarginBox mt={20}>
        <Form
          initialValues={initialValues}
          layout="vertical"
          onFinish={submitFormHandler}
          form={form}
        >
          <Row gutter={[12, 12]}>
            <Col lg={{ span: 12 }} span={24}>
              <Item label="Product Type" name="productType">
                <Radio.Group>
                  <Radio value={1}>Wine</Radio>
                  {/*
                     <Radio value={2}>Bundle</Radio>
                  */}
                </Radio.Group>
              </Item>
            </Col>
            <Col lg={12} span={24}>
              <Item label="Sale Channel" name="saleChannel">
                <Checkbox.Group options={checkBoxItems} />
              </Item>
            </Col>
            <Col lg={12} span={24}>
              <Item
                label="Product Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter product name!",
                  },
                ]}
              >
                <Input />
              </Item>
            </Col>
            <Col lg={12} span={24}>
              <Item label="Product URL" name="productUrl">
                <Input />
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Bottling Date" name="bottlingDate">
                <DatePicker />
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Harvest Date" name="harvestDate">
                <DatePicker />
              </Item>
            </Col>
            <Col span={24}>
              <Row>
                <Col lg={12} span={24}>
                  <Col>
                    <Item
                      label="Wine Category"
                      name="wineCategory"
                      rules={[
                        {
                          required: true,
                          message: "Please enter wine category!",
                        },
                      ]}
                    >
                      <Radio.Group options={wineCategories} />
                    </Item>
                  </Col>
                  <br />
                  <Col span={24}>
                    <Item
                      label="Status"
                      name="status"
                      rules={[
                        {
                          required: true,
                          message: "Please enter product status!",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={1}>Active</Radio>
                        <Radio value={0}>Inactive</Radio>
                        <Radio value={2}>Archived</Radio>
                      </Radio.Group>
                    </Item>
                  </Col>
                </Col>
                <Col lg={12} span={24}>
                  <BlockTitle>Upload Product Images</BlockTitle>
                  <AssetSelectorCreate />
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} span={24}>
              <Item label="Varietal" name="varietal">
                <DropdownAdder
                  key={0}
                  options={varietals}
                  type="varietal"
                  deleteItem={deleteItem}
                  selected={selectedVarietal}
                  setSelected={setSelectedVarietal}
                  addItem={addItem}
                  name={name}
                  setName={setName}
                  onNameChange={onNameChange}
                  inputRef={inputRef}
                />
              </Item>
            </Col>
            <Col lg={6} md={12} span={24}>
              <Item label="Appellation" name="appellation">
                <DropdownAdder
                  key={1}
                  options={appellations}
                  type="appellation"
                  deleteItem={deleteItem}
                  selected={selectedAppellation}
                  setSelected={setSelectedAppellation}
                  addItem={addItem}
                  name={name}
                  setName={setName}
                  onNameChange={onNameChange}
                  inputRef={inputRef}
                />
              </Item>
            </Col>
            <Col lg={6} md={12} span={24}>
              <Item label="Wine Type" name="wineType">
                <DropdownAdder
                  key={2}
                  options={wineTypes}
                  type="wineType"
                  deleteItem={deleteItem}
                  selected={selectedWineType}
                  setSelected={setSelectedWineType}
                  addItem={addItem}
                  name={name}
                  setName={setName}
                  onNameChange={onNameChange}
                  inputRef={inputRef}
                />
              </Item>
            </Col>
            <Col lg={6} md={12} span={24}>
              <Item label="Label" name="label">
                <DropdownAdder
                  key={3}
                  options={labels}
                  type="wineLabel"
                  deleteItem={deleteItem}
                  selected={selectedWineLabel}
                  setSelected={setSelectedWineLabel}
                  addItem={addItem}
                  name={name}
                  setName={setName}
                  onNameChange={onNameChange}
                  inputRef={inputRef}
                />
              </Item>
            </Col>
          </Row>
          <Variants
            optionGroups={optionGroups}
            setOptionGroups={setOptionGroups}
            variants={variants}
            setVariants={setVariants}
            manage={false}
          />
          <MarginBox mt={32}>
            <Row
              justify={breakpoints.md ? "end" : "center"}
              gutter={breakpoints.md ? 24 : 12}
            >
              <Col>
                <TwfButton
                  type="primary"
                  size-twf="md"
                  color-twf="primary"
                  type-twf="wide"
                  htmlType="submit"
                  loading={loading}
                >
                  Create
                </TwfButton>
              </Col>
            </Row>
          </MarginBox>
        </Form>
      </MarginBox>
    </Container>
  );
};

export default CreateNewProduct;
