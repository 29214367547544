import styled from "styled-components";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export const TwfTabs = styled(Tabs)`
  overflow: visible;

  .ant-tabs-nav {
    margin-bottom: 28px;
    @media (max-width: 768px) {
      margin-bottom: 0;
    }

    :before {
      border: none;
    }

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        width: 100%;
        display: flex;
        justify-content: space-around;
        background-color: #e6edf5;
        border-radius: 24px;
        border-bottom: none;
        height: 48px;
        padding-left: 6px;
        padding-right: 6px;
        @media (max-width: 768px) {
          background-color: #ffffff;
          border-radius: 0;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          // .ant-tabs-tab-btn {
          //   font-size: 12px;
          //   width: 100%;
          // }
        }
      }
    }
  }

  .ant-tabs-tab {
    width: 100%;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #fff;
        @media (max-width: 768px) {
          color: #3c64b1;
        }
      }
    }
  }

  .ant-tabs-nav {
    z-index: 1;

    .ant-tabs-ink-bar {
      height: calc(100% - 8px);
      z-index: -1;
      top: 4px;
      border-radius: 20px;
      max-width: none;
      @media (max-width: 768px) {
        height: 2px;
        width: 30px !important;
        top: auto;
        bottom: 0;
      }
    }
  }
`;

export const TwfTabsProducts = styled(Tabs)`
  overflow: visible;
  @media (max-width: 768px) {
    padding-bottom: 91px;
  }

  .ant-tabs-nav {
    margin-bottom: 28px;
    @media (max-width: 768px) {
      margin-bottom: 0;
    }

    :before {
      border: none;
    }

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        background-color: #e6edf5;
        border-radius: 24px;
        border-bottom: none;
        height: 48px;
        padding-left: 6px;
        padding-right: 6px;
        @media (max-width: 768px) {
          display: block;
          background-color: #ffffff;
          border-radius: 0;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          .ant-tabs-tab-btn {
            font-size: 12px;
          }
        }
      }
    }
  }

  .ant-tabs-tab {
    text-align: center;
    justify-content: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #fff;
        @media (max-width: 768px) {
          color: #3c64b1;
        }
      }
    }
  }

  .ant-tabs-nav {
    z-index: 1;

    .ant-tabs-ink-bar {
      height: calc(100% - 8px);
      z-index: -1;
      top: 4px;
      border-radius: 20px;
      max-width: none;
      @media (max-width: 768px) {
        height: 2px;
        width: 30px !important;
        top: auto;
        bottom: 0;
      }
    }
  }
`;

export const TwfTabItem = styled(TabPane)`
  .ant-card {
    min-height: 491px;
  }
`;
