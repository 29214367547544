import React, { FC, useState } from "react";
import AuthLayoutContainer from "../style/container";
import AuthLayoutColumns from "../style/columns";
import AuthLayoutCard from "../style/card";
import FavIllus from "../../../assets/illustration/fav";
import AuthWidgetNavWrap from "../style/widgetNavWrap";
import { H1 } from "../../../component/header";
import { Col, Row, Steps } from "antd";
import AuthSteps from "../style/steps";
import RegisterForm from "./form";
import AuthLayoutFooter from "../style/footer";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";
import EmailVerification from "./emailVerification";
import RegisterComplete from "./complete";
import { useNavigate } from "react-router-dom";

const { Step } = Steps;

interface PageProps {}

const RegisterPage: FC<PageProps> = () => {
  const history = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  // const [formData, setFormData] = useState({});

  const nextStepHandler = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStepHandler = () => {
    setCurrentStep(currentStep - 1);
  };
  return (
    <AuthLayoutContainer>
      <AuthLayoutColumns>
        <AuthLayoutCard bg="primary">
          <AuthWidgetNavWrap>
            <FavIllus />
            <H1>Get started</H1>
            <AuthSteps progressDot direction="vertical" current={currentStep}>
              <Step title="Tell About Yourself" />
              <Step title="Email Verification" />
              <Step title="Sign Up Confirmation" />
            </AuthSteps>
          </AuthWidgetNavWrap>
        </AuthLayoutCard>
        <AuthLayoutCard>
          {
            {
              0: <RegisterForm />,
              1: <EmailVerification />,
              2: <RegisterComplete />,
            }[currentStep]
          }
          <AuthLayoutFooter>
            <Row
              justify="space-between"
              align="middle"
              style={{ width: "100%" }}
            >
              <Col>
                {currentStep === 1 && (
                  <TwfButton
                    type="link"
                    size-twf="md"
                    onClick={prevStepHandler}
                  >
                    <Icon keyword="arrow-left" color="#3C64B1" />
                    Previous
                  </TwfButton>
                )}
              </Col>
              <Col>
                {currentStep < 2 && (
                  <TwfButton
                    color-twf="primary"
                    type="primary"
                    size-twf="md"
                    onClick={nextStepHandler}
                  >
                    Next Step
                    <Icon keyword="arrow-right" color="#ffffff" />
                  </TwfButton>
                )}
                {currentStep === 2 && (
                  <TwfButton
                    color-twf="primary"
                    type="primary"
                    size-twf="md"
                    onClick={() => {
                      history("/signin");
                    }}
                  >
                    SIGN IN
                    <Icon keyword="arrow-right" color="#ffffff" />
                  </TwfButton>
                )}
              </Col>
            </Row>
          </AuthLayoutFooter>
        </AuthLayoutCard>
      </AuthLayoutColumns>
    </AuthLayoutContainer>
  );
};

export default RegisterPage;
