/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, Form, Grid, Input, message, Row, Select } from "antd";
import BackButton from "component/button/back";
//import { EmailCategories } from "enums/enums";
import { useAddEmailTemplateMutation, useEmailCategoriesQuery, useEmailTokensLazyQuery, useEmailTokensQuery } from "generated/graphql";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../../../assets/icons";
import TwfButton from "../../../../component/button";
import { MarginBox } from "../../../../component/margin";
import DefaultEmailEditor from "../mjmlEditor/mjmlEditor";
import HTMLEmailEditor from "../richTextEditor/richTextEditor";

const { Item } = Form;
const { useBreakpoint } = Grid;

interface ComponentProps {}

const CreateEmailTemplate: React.FC<ComponentProps> = () => {
  const history = useNavigate();
  const breakpoints = useBreakpoint();

  const [tokens, setTokens] = useState<any[]>([]);

  const [content, setContent] = useState<string>();

  const [htmlContent, setHtmlContent] = useState<string>();

  const [EmailCategories, setEmailCategories] = useState<any[]>([]);

  const { data } = useEmailCategoriesQuery({
    variables:{
      options:{}
    }
  });

  const [getEmailTokens] = useEmailTokensLazyQuery({
    fetchPolicy:"network-only"
  });

  useEffect(()=>{
    if(data?.emailCategories){
     const options = data.emailCategories.items.map((emailCategory)=>{
      return{
        label: emailCategory.name,
        value: emailCategory.slug
      }
     }) 
     setEmailCategories(options);
    }
  },[data])

  const [addEmailTemplateMutation, { loading }] = useAddEmailTemplateMutation();

  const fetchToken = (e: any) =>{
    getEmailTokens({
      variables:{
        category: e
      }
    }).then((data)=>{
      if(data?.data?.emailTokens){
        const theObjects = [];
        for ( let token of data.data.emailTokens ){
          const theObject = {
            label: token,
            key: token
          }
          theObjects.push(theObject);
        }
        setTokens(theObjects);
      }
    })
  }

  const addEmailTemplate = (formData: any) => {
    formData["content"] = content;
    //formData["type"] = "html";
    //formData["mjmltohtmlcontent"] = null;
    formData["type"] = "mjml";
    formData["mjmltohtmlcontent"] = htmlContent;
    addEmailTemplateMutation({
      variables: {
        input: formData,
      },
      fetchPolicy: "network-only",
      refetchQueries: ["GetEmailTemplates"],
    })
      .then(() => {
        message.success("Email Template Successfully Added");
        setTimeout(()=>{
          history(0);
        },1000);
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  return (
    <Form
      layout="vertical"
      onFinish={addEmailTemplate}
      style={{ maxWidth: "max-content" }}
    >
      <BackButton />
      <MarginBox mt={32}>
        <Row>
          <Col span={24}>
            <Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter the template name!",
                },
              ]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={24}>
            <Item
              name="subject"
              label="Subject"
              rules={[
                {
                  required: true,
                  message: "Please enter the template subject!",
                },
              ]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={24}>
            <Item
              name="category"
              label="Template Category"
              rules={[
                {
                  required: true,
                  message: "Please select the template category!",
                },
              ]}
            >
              <Select
                placeholder="Select"
                suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
                options={EmailCategories}
                onChange={fetchToken}
              ></Select>
            </Item>
          </Col>
          <Col span={24}>
            <Item name="content" label="Content">
              {/*<DefaultEmailEditor
                setContent={setContent}
                setHtmlContent={setHtmlContent}
                tokens = {tokens}
            />*/}
              <HTMLEmailEditor value={content} setContent={setContent}/>
            </Item>
          </Col>
        </Row>
        <MarginBox mt={32}>
          <Row gutter={breakpoints.md ? [24, 24] : [12, 12]} justify="end">
            <Col>
              <TwfButton
                color-twf="gray-line"
                size-twf="md"
                type-twf="wide"
                onClick={() => {
                  history("/marketing");
                }}
              >
                Cancel
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                type="primary"
                color-twf="primary"
                size-twf="md"
                type-twf="wide"
                htmlType="submit"
                loading={loading}
              >
                Add
              </TwfButton>
            </Col>
          </Row>
        </MarginBox>
      </MarginBox>
    </Form>
  );
};

export default CreateEmailTemplate;
