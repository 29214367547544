import React from "react";
import TwfModal from "../../../../component/modal";
import Icon from "../../../../assets/icons";
import ConfirmIllus from "../../../../assets/illustration/confirm";
import CheckedIllus from "assets/illustration/checked";
import { MarginBox } from "../../../../component/margin";
import { H3, H4 } from "../../../../component/header";
import { Divider } from "antd";
import { Order } from "../../../../generated/graphql";

interface ComponentProps {
  order: Order;
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const CreateConfirmation: React.FC<ComponentProps> = ({
  order,
  visible,
  setVisible,
}) => {
  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      centered
      closeIcon={<Icon keyword="close" />}
      footer={false}
      width={394}
    >
      <MarginBox mt={32} style={{  textAlign: "center" }}>
        <CheckedIllus />
        <MarginBox mt={21}>
          <H3>Order Confirmation</H3>
        </MarginBox>
        <MarginBox mt={12}>
          <H4>Order ID: {order.code}</H4>
        </MarginBox>
        <Divider />
      </MarginBox>
    </TwfModal>
  );
};

export default CreateConfirmation;
