import styled from "styled-components";
import { Layout } from "antd";

const { Content } = Layout;

const TwfContent = styled(Content)`
  min-height: calc(100vh - 100px);
  padding: 45px 30px;
  .ant-card-body {
    border: none;
  }
  @media (max-width: 768px) {
    padding: 20px 15px;
  }
`;

export default TwfContent;
