import { Col, Grid, Row } from "antd";
import { FC, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../assets/icons";
import TwfButton from "../../component/button";
import { H2 } from "../../component/header";
import { MarginBox } from "../../component/margin";
import AdvancedSearch from "../orders/components/search";
import CustomersMobileFooter from "./components/mobile-footer";
import CustomersTable from "./components/table";

const { useBreakpoint } = Grid;

export interface CustomerFilter {
  //lastName?: string;
  //emailAddress?: string;
  data?: string;
  archived: boolean;
}

interface ReducerAction {
  type: string;
  value: string;
}

const CustomersPage: FC = () => {
  const history = useNavigate();
  const breakpoints = useBreakpoint();

  const initialState: CustomerFilter = { archived: false };

  const reducer = (state: CustomerFilter, action: ReducerAction) => {
    switch (action.type) {
      case "archived":
        return { ...state, archived: !state.archived };
      /*case "emailAddress":
      case "lastName":
        return { ...state, [action.type]: action.value };*/
      case "data":
          return { ...state, [action.type]: action.value };
      default:
        return state;
    }
  };

  const [filter, dispatch] = useReducer(reducer, initialState);

  return (
    <div>
      <Row
        gutter={[30, 15]}
        justify="space-between"
        wrap={false}
      >
        <Col>
          <Row align="middle" gutter={[30, 15]}>
            {breakpoints.md && (
              <Col>
                <H2>Customers</H2>
              </Col>
            )}
            <Col>
              <Row gutter={[30, 15]}>
                <Col style={{ width: breakpoints.md ? 'max-content' : '100%' }}>
                  <AdvancedSearch
                    placeholder="Search...."
                    setFilter={(value) => dispatch({ type: "data", value })}
                  />
                </Col>
              
                {/*<Col style={{ width: breakpoints.md ? 'max-content' : '100%' }}>
                  <AdvancedSearch
                    placeholder="Search by email address"
                    setFilter={(value) =>
                      dispatch({ type: "emailAddress", value })
                    }
                  />
                  </Col>*/}
              </Row>
            </Col>
            {breakpoints.md && (
              <Col>
                <TwfButton
                  size-twf="xl"
                  color-twf="gray-line"
                  onClick={() =>
                    dispatch({
                      type: "archived",
                      value: "",
                    })
                  }
                >
                  <Icon keyword="archive" color="#979797" width={24} />{" "}
                  {filter.archived ? "Active" : "Archived"} Customers
                </TwfButton>
              </Col>
            )}
       
          </Row>
        </Col>
        {breakpoints.md && (
          <Col>
            <TwfButton
              color-twf="primary"
              type="primary"
              size-twf="lg"
              type-twf="wide"
              onClick={() => {
                history("/customers/create");
              }}
            >
              <Icon keyword="plus" width={16} color="#fff" />
              Create New Customer
            </TwfButton>
          </Col>
        )}
      </Row>
      <MarginBox mt={32}>
        <CustomersTable filter={filter} />
      </MarginBox>
      {!breakpoints.md && <CustomersMobileFooter />}
    </div>
  );
};

export default CustomersPage;
