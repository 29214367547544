import React, { FC, Fragment, useState } from "react";
import { Col, Row, Space, Table } from "antd";
import { PageTitle } from "component/header";
import TwfButton from "component/button";
import Icon from "../../../assets/icons";
import ShadowCard from "component/card";
import AddressEdit from "./edit";
import AddressNew from "./new";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const pageData = [
  {
    key: 1,
    nickName: "Chandra",
    address: "123 Happy Raad, Apt #4, San Jose, CA 95123",
    primary: "Yes"
  },
  {
    key: 2,
    nickName: "Chandra",
    address: "123 Happy Raad, Apt #4, San Jose, CA 95123",
    primary: "Yes"
  }
];

const AddressBookPage: FC = () => {
  const breakpoints = useBreakpoint();
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [newVisible, setNewVisible] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>({});
  const columns: any = [
    {
      title: "Nick Name",
      dataIndex: "nickName"
    },
    {
      title: "Address",
      dataIndex: "address"
    },
    {
      title: "Primary",
      dataIndex: "primary"
    },
    {
      title: "",
      render: (data: any) => {
        return (
          <Fragment>
            <Space>
              <TwfButton
                type-twf="icon"
                size-twf="md"
                onClick={() => {
                  setRowData(data);
                  setEditVisible(true);
                }}
              >
                <Icon
                  keyword="edit"
                  color="#3C64B1"
                  width={breakpoints.md ? 18 : 16}
                />
              </TwfButton>
              <TwfButton
                type-twf="icon"
                size-twf="md"
                onClick={() => {
                  console.log(data);
                }}
              >
                <Icon
                  width={breakpoints.md ? 18 : 16}
                  keyword="delete"
                  color="#FF4C61"
                />
              </TwfButton>
            </Space>
          </Fragment>
        );
      }
    }
  ];
  return (
    <div>
      <Space
        direction="vertical"
        size={[24, 24]}
        style={{ width: "100%" }}
      >
        <Row
          justify="space-between"
          align="middle"
          gutter={[24, 12]}
        >
          <Col>
            <PageTitle>
              Address Book
            </PageTitle>
          </Col>
          <Col>
            <TwfButton
              type="primary"
              size-twf={breakpoints.md ? 'lg' : 'sm'}
              onClick={() => {
                setNewVisible(true);
              }}
            >
              <Icon
                keyword="plus"
                color="#ffffff"
                width={breakpoints.md ? 18 : 16}
              />
              Add New Address
            </TwfButton>
          </Col>
        </Row>
        <ShadowCard>
          <Table
            scroll={{ x: 600 }}
            columns={columns}
            dataSource={pageData}
            pagination={false}
          />
        </ShadowCard>
      </Space>
      <AddressEdit
        visible={editVisible}
        setVisible={setEditVisible}
        data={rowData}
      />
      <AddressNew
        visible={newVisible}
        setVisible={setNewVisible}
      />
    </div>
  );
};

export default AddressBookPage;