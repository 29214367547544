import React, { useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  Grid,
  Menu,
  Row,
  Table,
  Tag,
  message,
  TablePaginationConfig,
  Popconfirm,
} from "antd";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import {
  SortOrder,
  useDeleteCustomerMutation,
  //useGetCustomerListLazyQuery,
  useUpdateCustomerMutation,
  useFilteredCustomersLazyQuery,
  //Customer,
} from "../../../generated/graphql";
import { TableOptions } from "pages/orders/components/datatable";
import { GET_CUSTOMER_LIST } from "graphql/queries.graphql";
import { CustomerFilter } from "./../index";

const { useBreakpoint } = Grid;

interface Props {
  filter: CustomerFilter;
}

const CustomersTable: React.FC<Props> = ({ filter }) => {
  const history = useNavigate();
  const breakpoints = useBreakpoint();
  /*const [getCustomerListQuery, { data, loading }] =
    useGetCustomerListLazyQuery();*/

  const [getCustomerListQuery, { data, loading }] =
    useFilteredCustomersLazyQuery({
      fetchPolicy: "network-only",
    });

  const [updateCustomerMutation, { data: updateResult }] =
    useUpdateCustomerMutation();
  const [deleteCustomerMutation, { data: deleteResult }] =
    useDeleteCustomerMutation();

  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    pagination: { current: 1, pageSize: 10 },
    sort: { id: SortOrder.Desc },
  });
  const [queryVariables, setQueryVariables] = useState<any>();

  useEffect(() => {
    setQueryVariables({
      options: {
        filter: {
          archived: { eq: filter.archived },
          /*...(filter.lastName && { lastName: { contains: filter.lastName } }),
          ...(filter.emailAddress && {
            emailAddress: { contains: filter.emailAddress },
          }),*/
        },
        skip:
          (tableOptions.pagination.pageSize || 10) *
          ((tableOptions.pagination.current || 1) - 1),
        take: tableOptions.pagination.pageSize,
        sort: tableOptions.sort,
      },
      filterData: filter.data,
    });
  }, [filter, tableOptions]);

  useEffect(() => {
    getCustomerListQuery({
      variables: queryVariables,
    });
  }, [queryVariables, getCustomerListQuery]);

  useEffect(() => {
    if (updateResult) message.success("Successfully archived!");
  }, [updateResult]);

  const archiveCustomer = (id: string) => {
    updateCustomerMutation({
      variables: {
        input: {
          id: id,
          customFields: {
            archived: !filter.archived,
          },
        },
      },
      refetchQueries: ["FilteredCustomers"], // TODO: add another query here
    });
  };

  const deleteCustomer = (id: string) => {
    deleteCustomerMutation({
      variables: { id },
      refetchQueries: ["FilteredCustomers"],
      fetchPolicy:"network-only"
    }).then((data)=>{
      if(data.data?.deleteCustomer){
        message.success("Successfully deleted customer");
      }
    }).catch((error)=>{
      message.error(error.message);
    })
  };

  const tableChangeHandler = (
    pagination: TablePaginationConfig,
    filters: any,
    sort: any
  ) => {
    setTableOptions({
      pagination,
      sort: sort.field
        ? {
            [sort.field]:
              sort.order === "ascend" ? SortOrder.Asc : SortOrder.Desc,
          }
        : tableOptions.sort,
    });
  };

  const columns: any = [
    {
      title: "ID",
      dataIndex: "id",
      // width: 80,
      sorter: {},
    },
    {
      title: "First Name",
      // width: 120,
      sorter: {},
      render: (record: any) => {
        //const fname = record.firstName+" "+record.lastName;
        const url = "/customers/single/" + record.id;
        return (
          <a href={url} target="_blank" rel="noreferrer">
            {record.firstName}
          </a>
        );
      },
    },
    {
      title: "Last Name",
      // width: 120,
      sorter: {},
      render: (record: any) => {
        //const fname = record.firstName+" "+record.lastName;
        const url = "/customers/single/" + record.id;
        return (
          <a href={url} target="_blank" rel="noreferrer">
            {record.lastName}
          </a>
        );
      },
    },
    {
      title: "Username",
      dataIndex: "emailAddress",
      sorter: {},
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
      sorter: {},
    },
    {
      title: "Registration",
      sorter: {},
      render: (customer: any) => {
        if (
          customer?.customFields?.registeredDate &&
          customer?.customFields?.oldId
        ) {
          return new Date(
            customer.customFields.registeredDate
          ).toLocaleString();
        } else {
          return new Date(customer.createdAt).toLocaleString();
        }
      },
    },
    {
      title: "Status",
      dataIndex: "customFields",
      render: (customFields: any) => {
        return (
          <Tag color={customFields?.status === 1 ? "#4AAF05" : "#FB8832"}>
            {customFields?.status === 1 ? "active" : "inactive"}
          </Tag>
        );
      },
    },
    {
      title: "",
      width: breakpoints.md ? 150 : 50,
      fixed: "right",
      render: (record: any) => {
        return (
          <Row gutter={24}>
            <Col>
              <Dropdown
                overlay={
                  <Menu
                    style={{
                      borderRadius: 12,
                      padding: "8px 12px",
                      border: "1px solid #E5E5E5",
                    }}
                  >
                    <Menu.Item
                      key={`view${record.id}`}
                      onClick={() => {
                        history(`/customers/single/${record.id}`);
                      }}
                    >
                      View customer
                    </Menu.Item>
                    <Menu.Item
                      key={`edit${record.id}`}
                      onClick={() => {
                        history(`/customers/edit/${record.id}`);
                      }}
                    >
                      Edit customer
                    </Menu.Item>
                    <Menu.Item
                      key={`archive${record.id}`}
                      onClick={() => archiveCustomer(record.id)}
                    >
                      {filter.archived ? "Unarchive" : "Archive"} Customer
                    </Menu.Item>
                    <Popconfirm
                      title="Are you sure to delete this customer?"
                      onConfirm={() => deleteCustomer(record.id)}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Menu.Item key={`delete${record.id}`}>
                        Delete Customer
                      </Menu.Item>
                    </Popconfirm>
                  </Menu>
                }
                placement="bottomRight"
              >
                {breakpoints.md ? (
                  <TwfButton
                    size-twf="md"
                    type-twf="wide"
                    color-twf="gray-line"
                  >
                    More Actions <Icon keyword="chevron-down" width={14} />
                  </TwfButton>
                ) : (
                  <TwfButton shape="circle" type-twf="icon">
                    <Icon keyword="collapse" width={14} />
                  </TwfButton>
                )}
              </Dropdown>
            </Col>
          </Row>
        );
      },
    },
  ];

  const onselectionchange = (selectedRowKeys: any[]) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedKeys,
    onChange: onselectionchange,
  };

  return (
    <React.Fragment>
      <Table
        loading={loading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data?.filteredCustomers.items.map((obj) => ({
          ...obj,
          key: obj.id,
        }))}
        pagination={{
          ...tableOptions.pagination,
          total: data?.filteredCustomers?.totalItems,
          position: ["bottomCenter"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} Items`,
          showSizeChanger: true,
        }}
        onChange={tableChangeHandler}
        style={{ maxWidth: "max-content" }}
        scroll={{ x: "max-content" }}
      />
    </React.Fragment>
  );
};

export default CustomersTable;
