import { gql } from "@apollo/client";

export const QUERY_GET_PRODUCTS = gql`
  query {
    products {
      totalItems
      items {
        enabled
        id
        name
        slug
        description
        featuredAsset {
          type
          preview
          source
        }
        assets {
          type
          preview
          source
        }
        variants {
          enabled
          id
          name
          sku
          price
          trackInventory
          options {
            code
            group {
              code
            }
          }
          featuredAsset {
            type
            preview
            source
          }
          assets {
            type
            preview
            source
          }
        }
        optionGroups {
          name
          options {
            code
            name
          }
        }
      }
    }
  }
`;
