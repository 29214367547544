import React from "react";
import { Checkbox, Col, Form, Row } from "antd";
import { TwfCard } from "../../../../component/card";
import { BlockTitle } from "../../../../component/header";
import TwfButton from "../../../../component/button";
import Icon from "../../../../assets/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
const { Item } = Form;

const DiscountCategories: React.FC = () => {
  const breakpoints = useBreakpoint();
  return (
    <TwfCard
      style={{ padding: breakpoints.md ? "32px 48px" : "15px", minHeight: 600 }}
    >
      <Row gutter={[30, 15]}>
        <Col md={{ span: 9 }} span={24}>
          <BlockTitle>Categories</BlockTitle>
          <Item>
            <Checkbox>Friends & Family</Checkbox>
          </Item>
          <TwfButton
            type="link"
            style={{ padding: 0 }}
            icon={<Icon keyword="plus" color="#A6ACBE" width={16} />}
          >
            Add
          </TwfButton>
        </Col>
      </Row>
    </TwfCard>
  );
};

export default DiscountCategories;
