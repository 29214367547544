import { gql } from "@apollo/client";

export const QUERY_GET_ORDERS = gql`
  query {
    orders {
      totalItems
      items {
        id
        createdAt
        updatedAt
        orderPlacedAt
        code
        state
        active
        discounts {
          id
          amount
          amountWithTax
          description
          adjustmentSource
          type
        }
        couponCodes {
          code
        }
        promotions{
          id
          createdAt
          updatedAt
          startsAt
          endsAt
          couponCode
          perCustomerUsageLimit
          name
          enabled
        }

        customer {
          id
          createdAt
          updatedAt
          title
          firstName
          lastName
          phoneNumber
          emailAddress
        }
        shippingAddress {
          fullName
          company
          streetLine1
          streetLine2
          city
          province
          postalCode
          country
          countryCode
          phoneNumber
          customFields
        }
        billingAddress {
          fullName
          company
          streetLine1
          streetLine2
          city
          province
          postalCode
          country
          countryCode
          phoneNumber
          customFields
        }
        lines {
          id
          createdAt
          updatedAt
          productVariant {
            id
          }
          featuredAsset {
            id
            width
            height
            source
            type
          }
          unitPrice
          unitPriceWithTax
          unitPriceChangeSinceAdded
          unitPriceWithTaxChangeSinceAdded
          discountedUnitPrice
          discountedUnitPriceWithTax
          proratedUnitPrice
          proratedUnitPriceWithTax
          quantity
          items {
            id
            createdAt
            updatedAt
            cancelled
            unitPrice
            unitPriceWithTax
            discountedUnitPrice
            discountedUnitPriceWithTax
            proratedUnitPrice
            proratedUnitPriceWithTax
            unitTax
            taxRate
            adjustments {
              adjustmentSource
              type
              description
              amount
            }
            refundId
          }
          taxRate
          linePrice
          linePriceWithTax
          discountedLinePriceWithTax
          discountedLinePriceWithTax
          proratedLinePrice
          proratedLinePriceWithTax
          lineTax
          discounts {
            adjustmentSource
            type
            description
            amount
            amountWithTax
          }
          customFields
        }
      }
    }
  }
`;

export const GUQERY_GET_PRODUCTS = gql`
  query {
    products {
      items {
        id
        createdAt
        description
      }
    }
  }
`;
