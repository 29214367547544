import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const EmailManagementIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.07 13.88L13 19.94V22H15.06L21.12 15.93L19.07 13.88ZM22.7 13.58L21.42 12.3C21.3719 12.2469 21.3133 12.2043 21.2479 12.1751C21.1825 12.1458 21.1117 12.1305 21.04 12.13C20.89 12.14 20.75 12.19 20.65 12.3L19.65 13.3L21.7 15.3L22.7 14.3C22.89 14.1 22.89 13.78 22.7 13.58ZM11 18H4V8L12 13L20 8V10H22V6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H11V18ZM20 6L12 11L4 6H20Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export default EmailManagementIcon;
