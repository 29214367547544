import React, { useState, useCallback } from "react";
import { Input } from "antd";
import Icon from "../../../assets/icons";
import styled from "styled-components";

interface Props {
  setFilter: (keyword: string) => void;
  placeholder?: string;
}

const AdvancedSearch: React.FC<Props> = ({ setFilter, placeholder }) => {
  const [search, setSearch] = useState<string>("");

  const handleSearch = useCallback(() => {
    setFilter(search);
  }, [search, setFilter]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSearch(value);
      if (value === "") {
        setFilter("");
      }
    },
    [setFilter]
  );

  return (
    <InputArea>
      <Icon keyword="search" color="#C9CED6" width={24} />
      <Input
        style={{ width: "100%" }}
        onChange={handleChange}
        placeholder={placeholder || "Advanced Search"}
        onPressEnter={handleSearch}
      />
    </InputArea>
  );
};

const InputArea = styled.div`
  background-color: #fff;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  grid-gap: 12px;
  width: 100%;

  .ant-input {
    border: none !important;
    box-shadow: none !important;
  }
`;

export default AdvancedSearch;
