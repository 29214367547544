import { H3 } from "component/header";
import TwfModal from "component/modal";
import React, { FC, Fragment } from "react";
import CloseIcon from "../../../assets/icons/close";
import { Col, Form, Row, Space } from "antd";
import CardForm from "./card-form";
import TwfButton from "../../../component/button";

interface NewCardProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const NewCard: FC<NewCardProps> = ({
                                     visible,
                                     setVisible
                                   }) => {
  const submitHandler = (data: any) => {
    console.log(data);
  };

  return (
    <Fragment>
      <TwfModal
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        closeIcon={<CloseIcon />}
        centered
        footer={false}
        width={771}
      >
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
          <H3>Add New Credit Card</H3>
          <Form
            layout="vertical"
            onFinish={submitHandler}
          >
            <CardForm />
            <Row gutter={12} justify="end">
              <Col>
                <TwfButton
                  size-twf="md"
                  type-twf="wide"
                  type="primary"
                  ghost
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Cancel
                </TwfButton>
              </Col>
              <Col>
                <TwfButton
                  size-twf="md"
                  type-twf="wide"
                  type="primary"
                >
                  Add
                </TwfButton>
              </Col>
            </Row>
          </Form>
        </Space>
      </TwfModal>
    </Fragment>
  );
};

export default NewCard;