import { Card, Dropdown, Menu } from "antd";
import TwfButton from "component/button";
import { MarginBox } from "component/margin";
import { ActionCodes } from "enums/enums";
import { ConfigArgInput, ConfigurableOperationInput } from "generated/graphql";
import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { SelectTitle } from "../../../../component/header";
import BuyXGetYFreeAction from "./actions/buy-x-get-y-free-action";
import { default as FixPickupRateAction } from "./actions/fix-pickup-rate";
import FixShippingRateAction from "./actions/fix-shipping-rate";
import FreeOfCostAction from "./actions/free-of-cost";
import FreeOrderAction from "./actions/free-order-action";
import FreeShippingAction from "./actions/free-shipping-action";
import OrderFixedDiscountAction from "./actions/order-fixed-discount-action";
import OrderPercentageDiscountAction from "./actions/order-percentage-discount-action";
import ProductsPercentageDiscountAction from "./actions/product-percentage-discount-action";
import { AvailableOperation } from "./conditions";

interface ActionsProps {
  actions: Array<ConfigurableOperationInput>;
  onActionsChange: (actions: Array<ConfigurableOperationInput>) => void;
}

const Actions: FunctionComponent<ActionsProps> = ({
  actions,
  onActionsChange,
}) => {
  const [selectedOperations, setSelectedOperations] = useState<
    ConfigurableOperationInput[]
  >([]);

  useEffect(() => {
    setSelectedOperations(actions);
  }, [actions]);

  const removeCard = (code: string) => {
    setSelectedOperations(
      selectedOperations.filter((operation) => operation.code !== code)
    );

    const updatedActions = actions.filter(
      (condition) => condition.code !== code
    );
    onActionsChange(updatedActions);
  };

  const configArgChangeHandler = (configArgs: ConfigArgInput, code: string) => {
    onActionsChange(
      actions.map((condition) =>
        condition.code === code
          ? updateConditionArgs(condition, configArgs)
          : condition
      )
    );
  };

  const updateConditionArgs = (
    condition: ConfigurableOperationInput,
    configArg: ConfigArgInput
  ) => ({
    code: condition.code,
    arguments: condition.arguments.map((arg) =>
      arg.name === configArg.name ? configArg : arg
    ),
  });

  const addOperation = (operation: ConfigurableOperationInput) => {
    setSelectedOperations([...selectedOperations, operation]);
    onActionsChange([...actions, operation]);
  };

  const getConfigArgs = (operation: ConfigurableOperationInput) =>
    actions.find((condition) => condition.code === operation.code)?.arguments ||
    [];

  const availableOperations: AvailableOperation[] = [
    {
      description: "Discount order by fixed amount",
      operation: {
        code: ActionCodes.OrderFixedDiscount,
        arguments: [{ name: "discount", value: "0" }],
      },
    },
    {
      description: "Discount order by fixed percentage",
      operation: {
        code: ActionCodes.OrderPercentageDiscount,
        arguments: [{ name: "discount", value: "" }],
      },
    },
    {
      description: "Discount specified products by fixed percentage",
      operation: {
        code: ActionCodes.ProductsPercentageDiscount,
        arguments: [
          { name: "discount", value: "" },
          { name: "productVariantIds", value: "" },
        ],
      },
    },
    {
      description: "Fix rate flat shipping",
      operation: {
        code: ActionCodes.FixedShippingRateDiscount,
        arguments: [{ name: "data", value: "" }],
      },
    },
    {
      description: "Fix rate flat pickup",
      operation: {
        code: ActionCodes.FixedPickupRateDiscount,
        arguments: [{ name: "data", value: "" }],
      },
    },
    {
      description: "Free Order",
      operation: {
        code: ActionCodes.FreeOrder,
        arguments: [],
      },
    },
    // {
    //   description: "Free shipping",
    //   operation: {
    //     code: ActionCodes.FreeShipping,
    //     arguments: [],
    //   },
    // },
    // {
    //   description: "Free of Cost",
    //   operation: {
    //     code: ActionCodes.FreeOfCostDiscount,
    //     arguments: [],
    //   },
    // },
    //Free shipping Navid Biplab
    /*
    {
      description: "Free shipping",
      operation: {
        code: ActionCodes.FreeShipping,
        arguments: [],
      },
    },
    */
    /*{
      description:
        "Buy certain amount of one product, get certain amount of other products free",
      operation: {
        code: ActionCodes.BuyXGetYFree,
        arguments: [],
      },
    },*/
  ];

  return (
    <>
      <SelectTitle>Actions</SelectTitle>
      {selectedOperations.map((operation) => {
        switch (operation.code) {
          case ActionCodes.OrderFixedDiscount:
            return (
              <OrderFixedDiscountAction
                key={operation.code}
                code={operation.code}
                configArgs={getConfigArgs(operation)}
                onConfigArgChange={configArgChangeHandler}
                onRemove={removeCard}
              />
            );
          case ActionCodes.OrderPercentageDiscount:
            return (
              <OrderPercentageDiscountAction
                key={operation.code}
                code={operation.code}
                configArgs={getConfigArgs(operation)}
                onConfigArgChange={configArgChangeHandler}
                onRemove={removeCard}
              />
            );
          case ActionCodes.ProductsPercentageDiscount:
            return (
              <ProductsPercentageDiscountAction
                key={operation.code}
                code={operation.code}
                configArgs={getConfigArgs(operation)}
                onConfigArgChange={configArgChangeHandler}
                onRemove={removeCard}
              />
            );
          case ActionCodes.FreeOrder:
            return (
              <FreeOrderAction
                key={operation.code}
                code={operation.code}
                configArgs={[]}
                onConfigArgChange={() => {}}
                onRemove={removeCard}
              />
            );
          case ActionCodes.FreeShipping:
            return (
              <FreeShippingAction
                key={operation.code}
                code={operation.code}
                configArgs={[]}
                onConfigArgChange={() => {}}
                onRemove={removeCard}
              />
            );
          case ActionCodes.BuyXGetYFree:
            return (
              <BuyXGetYFreeAction
                key={operation.code}
                code={operation.code}
                configArgs={[]}
                onConfigArgChange={() => {}}
                onRemove={removeCard}
              />
            );
          case ActionCodes.FixedShippingRateDiscount:
            return (
              <FixShippingRateAction
                key={operation.code}
                code={operation.code}
                configArgs={getConfigArgs(operation)}
                onConfigArgChange={configArgChangeHandler}
                onRemove={removeCard}
              />
            );
          case ActionCodes.FixedPickupRateDiscount:
            return (
              <FixPickupRateAction
                key={operation.code}
                code={operation.code}
                onRemove={removeCard}
                configArgs={getConfigArgs(operation)}
                onConfigArgChange={configArgChangeHandler}
              />
            );
          case ActionCodes.FreeOfCostDiscount:
            return (
              <FreeOfCostAction
                key={operation.code}
                code={operation.code}
                configArgs={getConfigArgs(operation)}
                onConfigArgChange={configArgChangeHandler}
                onRemove={removeCard}
              />
            );
          default:
            return null;
        }
      })}
      <MarginBox mt={15}>
        <Dropdown
          overlay={
            <Menu
              style={{
                borderRadius: 12,
                padding: "8px 12px",
                border: "1px solid #E5E5E5",
              }}
            >
              {availableOperations
                .filter(
                  (operation) =>
                    !selectedOperations.find(
                      (op) => op.code === operation.operation.code
                    )
                )
                .map((operation) => (
                  <Menu.Item
                    key={operation.operation.code}
                    onClick={() => addOperation(operation.operation)}
                  >
                    {operation.description}
                  </Menu.Item>
                ))}
            </Menu>
          }
          placement="bottomLeft"
        >
          <TwfButton size-twf="md" type-twf="wide">
            Add Action
          </TwfButton>
        </Dropdown>
      </MarginBox>
    </>
  );
};

export const ActionCard = styled(Card)`
  .ant-card-actions li {
    text-align: left;
  }
`;

export const EmptyCard = styled(ActionCard)`
  .ant-card-body {
    padding: 0;
  }
`;

export default Actions;
