import {  SET_PRODUCT_ASSETS,UIActionType,SET_CUSTOMER_STREET_LINE_1 } from "../action/ui.action";

const INITIAL_STATE = {
  createProductAssets: [],
  customerAddressStreetLine1:null
};



const UiReducer = (state: any = INITIAL_STATE, action: UIActionType) => {
  switch (action.type) {
    case SET_PRODUCT_ASSETS:
      return {
        ...state,
        createProductAssets: action.data,
      };
     case SET_CUSTOMER_STREET_LINE_1:
      return {
        ...state,
        customerAddressStreetLine1:action.data
      } 

    default:
      return state;
  }
};

export default UiReducer;
