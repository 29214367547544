import React from "react";
import { Col, Divider, Form, Input, Row, Select } from "antd";
import { TwfCard } from "../../../../component/card";
import Icon from "../../../../assets/icons";
import { MarginBox } from "../../../../component/margin";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
const { Option } = Select;
const { Item } = Form;

const DiscountExcludeProducts: React.FC = () => {
  const breakpoints = useBreakpoint();
  return (
    <TwfCard
      style={{ padding: breakpoints.md ? "32px 48px" : "15px", minHeight: 600 }}
    >
      <Row gutter={[30, 15]}>
        <Col md={{ span: 9 }} span={24}>
          <Item>
            <Input
              prefix={<Icon keyword="search" color="#C9CED6" />}
              placeholder="Search by name or SKU to add"
            />
          </Item>
          <MarginBox mt={24} mb={24}>
            <Divider>Or</Divider>
          </MarginBox>
          <Item label="Name">
            <Input />
          </Item>
          <Item label="SKU">
            <Input />
          </Item>
          <Item label="Brand">
            <Select
              placeholder="Select"
              suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
            >
              <Option value="type1">Type 1</Option>
              <Option value="type1">Type 1</Option>
            </Select>
          </Item>
          <Item label="Department">
            <Select
              placeholder="Select"
              suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
            >
              <Option value="type1">Type 1</Option>
              <Option value="type1">Type 1</Option>
            </Select>
          </Item>
          <Item label="Category">
            <Select
              placeholder="Select"
              suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
            >
              <Option value="type1">Type 1</Option>
              <Option value="type1">Type 1</Option>
            </Select>
          </Item>
        </Col>
      </Row>
    </TwfCard>
  );
};

export default DiscountExcludeProducts;
