import React from "react";
import { Table } from "antd";
import { SettingPanelProps } from "../index";

const PolicySetting: React.FC<SettingPanelProps> = ({
  channel,
  columns,
  isMain,
}) => {
  const customFields = channel?.customFields;

  const data = [
    {
      key: "policy",
      option: "Policy",
      value: customFields?.policy ,
      render: (value: string) => value?.length<500? value: ".....",
      readonly: isMain === true ? null : true,
      type: "rich-text-editor",
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      showHeader={false}
      style={{ width: "100%" }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default PolicySetting;
