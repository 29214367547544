import React, { useState, useEffect } from "react";
import { TwfCard } from "../../../component/card";
import { MarginBox } from "../../../component/margin";
import CustomerList, { TableOptions } from "../components/customerList";
import {
  SortOrder,
  useGetClubLazyQuery,
  useRemoveCustomersFromClubMutation,
} from "generated/graphql";
import { useParams } from "react-router";
import TwfButton from "component/button";
import { Dropdown, Menu, message } from "antd";
import Icon from "assets/icons";

const ClubSingleMembers: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const cid:string = id as string;
  const [getClubQuery, { data, loading }] = useGetClubLazyQuery();
  const [removeCustomersFromClubMutation] =
    useRemoveCustomersFromClubMutation();

  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    filter: {},
    skip: 0,
    take: 10,
    sort: { id: SortOrder.Desc },
  });

  useEffect(() => {
    getClubQuery({
      variables: { 
        id:cid,
        searchTerm: searchTerm
      },
      fetchPolicy:"network-only"
    });
  }, [searchTerm, tableOptions, getClubQuery, cid]);

  const removeCustomersHandler = () => {
    removeCustomersFromClubMutation({
      variables: { clubCustomerIds: selectedKeys },
      refetchQueries: ["GetClub"],
    })
      .then(({ data }) => {
        if (data?.removeCustomersFromClub)
          message.success(
            `Customer${selectedKeys.length > 1 ? "s" : ""} deleted`
          );
      })
      .catch((error) => message.error(error.message));
  };

  return (
    <TwfCard style={{ minHeight: 600 }}>
      <MarginBox mt={12} ml={15}>
        <Dropdown
          disabled={selectedKeys.length === 0}
          overlay={
            <Menu
              style={{
                borderRadius: 12,
                padding: "8px 12px",
                border: "1px solid #E5E5E5",
              }}
            >
              <Menu.Item key="1" onClick={removeCustomersHandler}>
                Remove from this group
              </Menu.Item>
            </Menu>
          }
          placement="bottomLeft"
        >
          <TwfButton hidden={true} size-twf="sm" type-twf="wide" color-twf="gray-line">
            With Selected <Icon keyword="chevron-down" width={14} />
          </TwfButton>
          
        </Dropdown>
      </MarginBox>
      <MarginBox mt={12} mb={56}>
        <CustomerList
          data={data}
          onCustomerSelectionchange={setSelectedKeys}
          tableOptions={tableOptions}
          onTableOptionsChange={setTableOptions}
          setSearchTerm={setSearchTerm}
          loading={loading}
        />
      </MarginBox>
    </TwfCard>
  );
};

export default ClubSingleMembers;
