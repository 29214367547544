import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const GuideIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8563 8.21038C10.8565 8.45439 10.8086 8.69604 10.7154 8.92154C10.6222 9.14705 10.4855 9.35198 10.3131 9.52465C10.1407 9.69731 9.93594 9.83433 9.71057 9.92788C9.48521 10.0214 9.24363 10.0697 8.99963 10.0698C8.75562 10.07 8.51397 10.0222 8.28847 9.92894C8.06297 9.83574 7.85803 9.69903 7.68536 9.52662C7.5127 9.35421 7.37568 9.14948 7.28213 8.92412C7.18859 8.69876 7.14035 8.45718 7.14016 8.21317C7.13979 7.72038 7.3352 7.24762 7.68339 6.89891C8.03159 6.55019 8.50405 6.35408 8.99684 6.35371C9.48963 6.35334 9.96239 6.54874 10.3111 6.89694C10.6598 7.24513 10.8559 7.71759 10.8563 8.21038ZM12.2492 11.7538V12.2552C12.2492 13.2627 10.8656 14.0167 8.99916 14.0167C7.13273 14.0167 5.74916 13.2618 5.74916 12.2562V11.7538C5.7488 11.655 5.76792 11.557 5.80544 11.4656C5.84296 11.3741 5.89813 11.2909 5.96782 11.2208C6.0375 11.1507 6.12032 11.095 6.21154 11.0569C6.30276 11.0189 6.4006 10.9991 6.49945 10.9989H11.4998C11.5986 10.9992 11.6963 11.0191 11.7874 11.0572C11.8785 11.0953 11.9613 11.151 12.0308 11.2212C12.1004 11.2913 12.1555 11.3744 12.193 11.4658C12.2304 11.5572 12.2495 11.655 12.2492 11.7538ZM3.19559 0.320312C2.39521 0.320313 1.6276 0.638264 1.06164 1.20422C0.495686 1.77018 0.177734 2.53778 0.177734 3.33817V18.6596C0.177734 19.46 0.495686 20.2276 1.06164 20.7935C1.6276 21.3595 2.39521 21.6775 3.19559 21.6775H17.1242C17.3089 21.6775 17.486 21.6041 17.6166 21.4735C17.7472 21.3429 17.8206 21.1657 17.8206 20.981C17.8206 20.7963 17.7472 20.6192 17.6166 20.4886C17.486 20.358 17.3089 20.2846 17.1242 20.2846H3.19559C2.80484 20.2846 2.42718 20.1438 2.13181 19.888C1.83643 19.6322 1.64313 19.2785 1.58731 18.8917H17.1242C17.3089 18.8917 17.486 18.8184 17.6166 18.6878C17.7472 18.5572 17.8206 18.38 17.8206 18.1953V3.33817C17.8206 2.53778 17.5026 1.77018 16.9367 1.20422C16.3707 0.638264 15.6031 0.320313 14.8027 0.320312H3.19559ZM16.4277 3.33817V17.4989H1.57059V3.33817C1.57059 2.44117 2.29859 1.71317 3.19559 1.71317H14.8027C15.7007 1.71317 16.4277 2.44117 16.4277 3.33817Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export default GuideIcon;
