import React, { FC, Fragment, useState, useEffect } from "react";
import ShadowCard from "../../../component/card";
import {
  Dropdown,
  Menu,
  message,
  Table,
  TablePaginationConfig,
  Tag,
} from "antd";
import TwfButton from "../../../component/button";
import Icon from "../../../assets/icons";
import { useNavigate, useParams } from "react-router";
import {
  Customer,
  DeletionResult,
  SeasonCustomer,
  useAddCustomerToSeasonMutation,
  useGetSeasonCustomerListLazyQuery,
  useRemoveCustomerFromSeasonMutation,
} from "generated/graphql";
import { TableOptions } from "../components/customerList";

const { Item } = Menu;

interface Props {
  onProcessCustomer: (id: string) => void;
}

const SeasonClubMembers: FC<Props> = ({ onProcessCustomer }) => {
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const cid: string = id as string;
  const [getSeasonCustomerListQuery, { data, loading }] =
    useGetSeasonCustomerListLazyQuery({
      fetchPolicy: "network-only",
    });
  const [removeCustomerFromSeasonMutation] =
    useRemoveCustomerFromSeasonMutation();
  const [addCustomerToSeasonMutation] = useAddCustomerToSeasonMutation();

  const [tableOptions, setTableOptions] = useState<TableOptions>({
    filter: {},
    skip: 0,
    take: 10,
  });

  useEffect(() => {
    getSeasonCustomerListQuery({
      variables: {
        id: cid,
      },
    });
  }, [tableOptions, getSeasonCustomerListQuery, cid]);

  const skipCustomerHandler = (seasonCustomer: SeasonCustomer) => {
    if (seasonCustomer.status !== 3) {
      removeCustomerFromSeasonMutation({
        variables: {
          seasonId: cid,
          customerId: seasonCustomer.customer.id,
        },
        refetchQueries: ["getSeasonCustomerList"],
      })
        .then(({ data }) => {
          if (
            data?.removeCustomerFromSeason.result === DeletionResult.Deleted
          ) {
            message.success("Customer skipped");
          }
        })
        .catch((error) => message.error(error.message));
    } else {
      addCustomerToSeasonMutation({
        variables: {
          seasonId: cid,
          customerId: seasonCustomer.customer.id,
        },
        refetchQueries: ["getSeasonCustomerList"],
      })
        .then(({ data }) => {
          if (data?.addCustomerToSeason) {
            message.success("Customer re-added");
          }
        })
        .catch((error) => message.error(error.message));
    }
  };

  const tableChangeHandler = (pagination: TablePaginationConfig) => {
    setTableOptions({
      filter: tableOptions.filter,
      skip: (pagination.pageSize || 10) * ((pagination.current || 1) - 1),
      take: pagination.pageSize || 10,
    });
  };

  const columns: any = [
    {
      title: "Customer Name",
      dataIndex: "customer",
      render: (customer: Customer) =>
        `${customer.firstName} ${customer.lastName}`,
    },
    {
      title: "Phone",
      dataIndex: ["customer", "phoneNumber"],
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: number) => {
        return (
          <Fragment>
            <Tag
              color={
                status === 2 ? "#4AAF05" : status === 3 ? "#FF5756" : "#FB8832"
              }
            >
              {status === 2
                ? "Processed"
                : status === 3
                ? "Skippable" 
                : "Pending"}
            </Tag>
          </Fragment>
        );
      },
    },
    {
      title: "",
      width: 50,
      render: (record: SeasonCustomer) => {
        return (
          <Fragment>
            <Dropdown
              overlay={
                <Menu style={{ borderRadius: 12 }}>
                  <Item
                    key="review"
                    onClick={() =>
                      history("/customers/edit/" + record.customer.id)
                    }
                  >
                    Review/ Edit Member
                  </Item>
                  <Item key="skip" onClick={() => skipCustomerHandler(record)}>
                    {record.status === 3 ? "Activate Member" : "Skip Member"}
                  </Item>
                  <Item
                    key="processMember"
                    onClick={() => onProcessCustomer(record.customer.id)}
                  >
                    Process Member
                  </Item>
                </Menu>
              }
              placement="bottomRight"
            >
              <TwfButton type-twf="icon" size-twf="sm">
                <Icon keyword="collapse" width={16} />
              </TwfButton>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  return (
    <Fragment>
      <ShadowCard>
        <Table
          columns={columns}
          dataSource={data?.seasonCustomers.items.map((obj) => ({
            ...obj,
            key: obj.id,
          }))}
          onChange={tableChangeHandler}
          pagination={{
            pageSize: tableOptions.take,
            current: tableOptions.skip / tableOptions.take + 1,
            total: data?.seasonCustomers?.totalItems,
            position: ["bottomCenter"],
            showSizeChanger: true,
          }}
          loading={loading}
        />
      </ShadowCard>
    </Fragment>
  );
};

export default SeasonClubMembers;
