import React from "react";
import AuthLayoutWrap from "../style/wrap";
import AuthStepText from "../style/stepText";
import { MarginBox } from "../../../component/margin";
import { H3, SubTitle } from "../../../component/header";
import VerifyAlert from "../style/alert";

const EmailVerification: React.FC = () => {
  return (
    <AuthLayoutWrap>
      <AuthStepText>STEP 2/3</AuthStepText>
      <MarginBox mt={7} mb={32}>
        <H3>Verify Your Email</H3>
      </MarginBox>
      <MarginBox mb={32}>
        <VerifyAlert
          style={{ textAlign: "left" }}
          message=""
          description="Please check your email and click on the link to activate
          your account. The email was sent to yourmail@gmail.com"
          type="info"
          showIcon
        />
      </MarginBox>
      <SubTitle>Please verify your email to activate your account.</SubTitle>
    </AuthLayoutWrap>
  );
};

export default EmailVerification;
