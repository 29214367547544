import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const Label = styled.td`
  text-align: right;
  font-weight: bold;
  padding-right: 1rem;
`;
