import React from "react";
import { Checkbox, Col, Form, Row } from "antd";
import { TwfCard } from "../../../../component/card";
import { BlockTitle } from "../../../../component/header";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
const { Item } = Form;

const DiscountOrderTypes: React.FC = () => {
  const breakpoints = useBreakpoint();
  return (
    <TwfCard
      style={{ padding: breakpoints.md ? "32px 48px" : "15px", minHeight: 600 }}
    >
      <BlockTitle>Order Types</BlockTitle>
      <Row gutter={[30, 15]}>
        <Col>
          <Item>
            <Checkbox>AdminPanel</Checkbox>
          </Item>
          <Item>
            <Checkbox>ClubOrder</Checkbox>
          </Item>
          <Item>
            <Checkbox>Marketing</Checkbox>
          </Item>
          <Item>
            <Checkbox>Personal</Checkbox>
          </Item>
          <Item>
            <Checkbox>Website</Checkbox>
          </Item>
        </Col>
      </Row>
    </TwfCard>
  );
};

export default DiscountOrderTypes;
