import BrandsPage from "./pages/brands";
import OrdersPage from "./pages/orders";
import CustomersPage from "./pages/customers";
import ProductsPage from "./pages/products";
import ClubPage from "./pages/club";
import MarketingPage from "./pages/marketing";
import CompliancePage from "./pages/compliance";
import ShippingPage from "./pages/shipping";
import ReportsPage from "./pages/reports";
import GuidePage from "./pages/guide";
import CreateNewOrder from "./pages/orders/components/create";
import EditOrder from "./pages/orders/components/edit";
import ProductAttributes from "./pages/products/product/attributes";
import CustomerCreate from "./pages/customers/create";
import CustomerSingle from "./pages/customers/single";
import DiscountsManagement from "./pages/marketing/components/discounts";
import Discount from "./pages/marketing/components/discount";
import ShippingSingle from "./pages/shipping/components/single";
import ShippingEdit from "./pages/shipping/components/edit";
import ShippingMethodsTable from "pages/shipping/components/methodsTable";
import ClubSingle from "./pages/club/single";
import SingleProductAttributes from "./pages/products/product/attributes";
import SettingsPage from "./pages/settings";
import CustomerEdit from "./pages/customers/edit";
import CreateNewProduct from "./pages/products/create";
import AssetsPage from "./pages/assets";
import ManageVariants from "pages/products/variants";
import ClientDashboardPage from "pages/client-portal/dashboard/index";
import MyAccountPage from "./pages/client-portal/my-account";
import EditProfilePage from "./pages/client-portal/edit-profile";
import AddressBookPage from "./pages/client-portal/address-book";
import ChangePasswordPage from "./pages/client-portal/change-password";
import UpcomingShipmentsPage from "./pages/client-portal/upcoming-shipments";
import ClubListPage from "./pages/client-portal/club-list";
import CreditCardsPage from "./pages/client-portal/credit-cards";
import MyOrdersPage from "./pages/client-portal/my-orders";
import SeasonSteps from "./pages/club/single/seasonSteps";
import AddDiscount from "pages/marketing/discount/create";
import EditDiscount from "pages/marketing/discount/edit";
import AnnouncementPage from "pages/announcements";
import DashboardPage from "pages/dashboard";
import UpdateEmailTemplate from "pages/marketing/components/emailEditor/updateEmailTemplate";
import CreateEmailTemplate from "pages/marketing/components/emailEditor/createEmailTemplate";
import EmailCategoryPage from "pages/email-categories";
import PickupOptionsPage from "pages/pickup";

const routes = [
  { path: "/brands", name: "Brands", component: BrandsPage },
  { path: "/dashboard", name: "Dashboard", component: DashboardPage },
  { path: "/orders", name: "Orders", component: OrdersPage },
  { path: "/orders/create", name: "Order Product", component: CreateNewOrder },
  { path: "/orders/edit/:id", name: "Order Edit", component: EditOrder },
  { path: "/customers", name: "Customers", component: CustomersPage },
  {
    path: "/customers/create",
    name: "Customer Create",
    component: CustomerCreate,
  },
  {
    path: "/customers/edit/:id",
    name: "Customer Edit",
    component: CustomerEdit,
  },
  {
    path: "/customers/single/:id",
    name: "Customer Single",
    component: CustomerSingle,
  },
  { path: "/products", name: "Products", component: ProductsPage },
  {
    path: "/products/single/:id/:attribute_id",
    name: "Product Single",
    component: SingleProductAttributes,
  },
  {
    path: "/products/edit/:id/variants",
    name: "Manage Variants",
    component: ManageVariants,
  },
  {
    path: "/products/attributes",
    name: "Product Attributes",
    component: ProductAttributes,
  },
  {
    path: "/products/create",
    name: "Create New Product",
    component: CreateNewProduct,
  },
  {
    path: "/products/edit/:id",
    name: "Product Edit",
    component: SingleProductAttributes,
  },
  { path: "/assets", name: "Assets", component: AssetsPage },
  { path: "/club", name: "Club", component: ClubPage },
  { path: "/club/single/:id", name: "Club Id", component: ClubSingle },
  { path: "/club/season/:id", name: "Club Id", component: SeasonSteps },

  { path: "/marketing", name: "Marketing", component: MarketingPage },

  { path: "/pickup", name: "Pickup Options", component: PickupOptionsPage },

  { path: "/email-categories", name:"Email Categories", component: EmailCategoryPage },

  { path: "/marketing/create", name: "Create Email Template", component: CreateEmailTemplate },

  { path: "/marketing/:id", name: "Update Email Template", component: UpdateEmailTemplate },
  
  {
    path: "/marketing/discounting",
    name: "Manage Discounting",
    component: DiscountsManagement,
  },
  {
    path: "/marketing/discounting/create",
    name: "Add New Discount",
    component: AddDiscount,
  },
  {
    path: "/marketing/discounting/:id",
    name: "Edit Discount",
    component: EditDiscount,
  },
  {
    path: "/marketing/discounting/:id",
    name: "Manage Discounting single",
    component: Discount,
  },
  { path: "/compliance", name: "Compliance", component: CompliancePage },
  { path: "/shipping", name: "Shipping", component: ShippingPage },
  {
    path: "/shipping/single/:id",
    name: "Shipping single",
    component: ShippingSingle,
  },
  {
    path: "/shipping/edit/:id",
    name: "Shipping single Edit",
    component: ShippingEdit,
  },
  {
    path: "/shipping/datatable",
    name: "Shipping Data Table",
    component: ShippingMethodsTable,
  },
  { path: "/reports", name: "Reports", component: ReportsPage },
  { path: "/guide", name: "Help", component: GuidePage },
  { path: "/announcement", name: "Announcement", component: AnnouncementPage },
  { path: "/settings", name: "Settings", component: SettingsPage },
];

const clientRoutes = [
  {
    path: "/user/dashboard",
    name: "Dashboard",
    component: ClientDashboardPage,
  },
  { path: "/user/my-account", name: "My Account", component: MyAccountPage },
  {
    path: "/user/edit-profile",
    name: "Edit Profile",
    component: EditProfilePage,
  },
  {
    path: "/user/address-book",
    name: "Address Book",
    component: AddressBookPage,
  },
  { path: "/user/my-orders", name: "My Orders", component: MyOrdersPage },
  {
    path: "/user/credit-cards",
    name: "Credit Cards",
    component: CreditCardsPage,
  },
  { path: "/user/club-list", name: "Club List", component: ClubListPage },
  {
    path: "/user/upcoming-shipments",
    name: "Upcoming Shipments",
    component: UpcomingShipmentsPage,
  },
  {
    path: "/user/change-password",
    name: "Change Password",
    component: ChangePasswordPage,
  },
];

export { clientRoutes };
export default routes;
