import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const BottleIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    version="1.0"
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 640.000000 1280.000000"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
      fill={color ? color : "#000000"}
      stroke="none"
    >
      <path
        d="M2855 12791 c-73 -13 -124 -52 -202 -156 -48 -64 -57 -115 -50 -293
 3 -86 10 -172 16 -190 21 -68 35 -399 35 -832 0 -727 -34 -1086 -131 -1385
 -55 -171 -176 -455 -217 -510 -12 -16 -35 -52 -50 -80 -16 -27 -79 -135 -141
 -240 -201 -341 -233 -399 -276 -494 -117 -264 -180 -480 -200 -686 -9 -88 -13
 -1046 -18 -3595 -3 -1911 -4 -3557 -1 -3657 l5 -182 45 -31 c25 -16 63 -42 85
 -57 64 -42 261 -137 370 -177 55 -20 116 -45 135 -56 83 -45 499 -140 699
 -160 619 -62 1405 139 1673 428 66 71 94 128 108 218 17 108 29 1557 36 4349
 7 2649 5 2817 -31 3045 -22 135 -48 224 -111 378 -80 195 -196 422 -335 654
 -259 433 -344 634 -427 1013 -36 160 -51 277 -86 635 -22 233 -24 303 -26 826
 -1 482 1 589 15 680 34 227 -12 386 -147 508 l-61 56 -336 -1 c-185 -1 -354
 -5 -376 -8z m788 -627 c28 -4 49 -13 53 -23 4 -9 9 -119 11 -246 4 -181 2
 -233 -9 -246 -12 -15 -31 -16 -188 -7 -96 5 -319 8 -496 6 l-321 -3 -10 250
 c-5 138 -8 251 -7 253 12 18 849 32 967 16z m-836 -596 c5 -8 7 -140 3 -315
 -5 -311 -10 -342 -44 -314 -17 14 -25 62 -26 146 -1 123 16 470 23 483 5 6 14
 12 21 12 8 0 18 -6 23 -12z m177 -247 l30 -31 -26 -27 c-25 -27 -26 -33 -30
 -170 -2 -87 -8 -147 -15 -154 -18 -18 -25 56 -21 250 3 197 2 195 62 132z
 m-110 -351 l-7 -35 -9 30 c-10 32 -2 291 10 334 9 33 15 -282 6 -329z m864
 -95 c4 -4 10 -79 13 -168 4 -89 12 -211 19 -272 28 -265 27 -257 12 -262 -22
 -9 -1063 5 -1102 15 l-35 8 3 60 c1 32 7 82 12 109 5 28 15 141 20 253 10 190
 19 249 40 262 12 7 1011 2 1018 -5z m408 -2743 c36 -24 40 -89 8 -139 -20 -32
 -28 -29 -49 17 -23 51 -34 41 -45 -40 -9 -62 -26 -88 -44 -70 -6 6 -10 56 -10
 112 -1 99 0 103 24 115 27 14 98 17 116 5z m-222 -79 l39 -6 -5 -99 c-3 -54
 -8 -101 -11 -105 -12 -11 -37 19 -48 59 -8 31 -14 38 -27 33 -34 -13 -37 -9
 -40 41 -2 37 2 56 13 68 18 18 24 18 79 9z m-2175 -1090 c94 -35 242 -70 356
 -82 525 -58 684 -65 1280 -58 594 7 766 19 1075 72 75 13 160 33 190 44 30 11
 58 17 63 12 22 -22 27 -284 27 -1642 l0 -1457 -53 -44 c-152 -129 -355 -222
 -611 -279 -70 -16 -131 -31 -135 -33 -4 -3 -58 -12 -121 -21 -177 -26 -762
 -37 -955 -19 -167 16 -396 48 -495 69 -36 8 -83 17 -105 21 -145 25 -407 154
 -548 271 l-58 48 -2 1568 c-2 1390 0 1567 13 1561 8 -3 44 -17 79 -31z"
      />
    </g>
  </svg>
);

export default BottleIcon;
