import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const DashboardIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    stroke={color ? color : "#000"}
    color={color ? color : "#000"}
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="1.5"
  >
    <path d="M3 3h7v9H3Z" className="fill-none" />
    <path d="M14 3h7v5H14Z" className="fill-none" />
    <path d="M14 12h7v9H14Z" className="fill-none" />
    <path d="M3 16h7v5H3Z" className="fill-none" />
  </svg>
);

export default DashboardIcon;
