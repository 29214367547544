import { MenuFoldOutlined } from "@ant-design/icons";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { Col, Grid, Layout, Row } from "antd";
import {
  useGetActiveAdministratorQuery,
  useGetActiveChannelQuery,
  useGetChannelsLazyQuery,
} from "generated/graphql";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Icon from "../assets/icons";
import { HamburgerIcon } from "../assets/icons/hamburger";
import { LogoutIcon } from "../assets/icons/logout";
import TwfButton from "../component/button";
import routes from "../route";
import { logoutAction } from "../store/action/authenticate";
import TwfMenuLists from "./menu";
import TwfContent from "./style/content";
import TwfHeader, { TwfHeaderWrap } from "./style/header";
import MobileDrawer from "./style/mobileDrawer";
import TwfSide from "./style/sider";

const { useBreakpoint } = Grid;

interface LayoutProps {}

const TwfLayout: FC<LayoutProps> = () => {
  const breakpoints = useBreakpoint();
  const dispatch = useDispatch();
  const client = useApolloClient();
  const [logout] = useMutation(LOGOUT_QUERY);
  const user = useSelector((state: any) => state.user);
  const { data: getChannelResult } = useGetActiveChannelQuery();

  const { data: getAdminResult } = useGetActiveAdministratorQuery();

  const [collapsed, setCollapsed] = useState(false);
  const [sideMenuVisible, setSideMenuVisible] = useState(false);
  const [logo, setLogo] = useState<string>();

  useEffect(() => {
    breakpoints.md ? setCollapsed(false) : setCollapsed(true);
  }, [breakpoints]);

  const handleVendorRedirect = () => {
    let protocol = window?.location.protocol;
    let subdomain = getChannelResult?.activeChannel.token;
    return `${protocol}//${subdomain}.${process.env.REACT_APP_STORE_URL}`;
  };

  const [getChannelsQuery] = useGetChannelsLazyQuery({
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getChannelsQuery({
      variables: {
        options: {},
      },
    })
      .then((channel) => {
        if (channel.data === undefined) {
          localStorage.clear();
          window.location.href = "/";
        }
      })
      .catch(() => {
        localStorage.clear();
        window.location.href = "/";
      });
  }, [getChannelsQuery]);

  useEffect(() => {
    if (getChannelResult?.activeChannel.customFields?.logo)
      setLogo(getChannelResult?.activeChannel.customFields?.logo);
    else setLogo("");
  }, [getChannelResult]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const toggleSideMenu = () => {
    setSideMenuVisible(true);
  };

  const logoutHandler = () => {
    logout({ variables: {} })
      .then(() => {
        client.resetStore();
        dispatch<any>(logoutAction({}));
      })
      .catch((error) => console.log(error));
  };

  const onSideMenuClose = () => {
    setSideMenuVisible(false);
  };

  const isValidHttpUrl = (value: string) => {
    let url;
    try {
      url = new URL(value);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };

  return user.channels && user.channels[0].token ? (
    <Layout hasSider={breakpoints.md ? true : false}>
      {breakpoints.md ? (
        <TwfSide
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={220}
          style={{ zIndex: 20 }}
        >
          {logo && (
            <TwfHeader
              onClick={() => {
                if (
                  getChannelResult?.activeChannel?.customFields?.mainWebsite
                ) {
                  let mainWebsite =
                    getChannelResult.activeChannel.customFields.mainWebsite;
                  if (isValidHttpUrl(mainWebsite)) {
                    window.open(mainWebsite, "_blank");
                  }
                }
              }}
              style={{
                cursor: "pointer",
                backgroundImage: `url(${logo.replaceAll("\\", "/")})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
                boxSizing: "border-box",
                border: "2px solid #3C64B1",
                backgroundOrigin: "content-box",
                padding: 10,
              }}
            />
          )}
          {!logo && (
            <TwfHeader
              style={{
                backgroundColor: "#3C64B1",
              }}
            />
          )}
          <TwfMenuLists
            collapsed={collapsed}
            setSideMenuVisible={setSideMenuVisible}
          />
        </TwfSide>
      ) : (
        <MobileDrawer
          placement="left"
          closable={true}
          onClose={onSideMenuClose}
          visible={sideMenuVisible}
          width={"100%"}
          closeIcon={<Icon keyword="close" />}
        >
          <TwfMenuLists
            collapsed={false}
            setSideMenuVisible={setSideMenuVisible}
          />
        </MobileDrawer>
      )}
      <Layout
        style={{
          marginLeft: breakpoints.md ? (collapsed ? 70 : 220) : 0,
          transitionDelay: "200ms",
        }}
      >
        <TwfHeader>
          <TwfHeaderWrap>
            {!collapsed && breakpoints.md ? (
              <>
                <MenuFoldOutlined style={{ width: 48 }} onClick={toggle} />

                <TwfButton type-twf="icon" shape="circle">
                  <a
                    href={handleVendorRedirect()}
                    target="_blank"
                    referrerPolicy="origin"
                    rel="noreferrer"
                  >
                    {getChannelResult?.activeChannel.customFields
                      ?.brandName && (
                      <>
                        <Col
                          style={{
                            textTransform: "uppercase",
                            background: "#FE7B42",
                            height: 50,
                            display: "flex",
                            alignItems: "center",
                            borderRadius: 5,
                            padding: "0 20px",
                            fontWeight: 800,
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          {
                            getChannelResult?.activeChannel.customFields
                              ?.brandName
                          }
                        </Col>
                      </>
                    )}
                  </a>
                </TwfButton>
              </>
            ) : (
              <TwfButton type-twf="icon" shape="circle">
                <div onClick={breakpoints.md ? toggle : toggleSideMenu}>
                  <HamburgerIcon width={30} color="#3C64B1" />
                </div>

                <a
                  href={handleVendorRedirect()}
                  target="_blank"
                  referrerPolicy="origin"
                  rel="noreferrer"
                >
                  {getChannelResult?.activeChannel.customFields?.brandName && (
                    <Col
                      style={{
                        textTransform: "uppercase",
                        background: "#FE7B42",
                        height: 50,
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 5,
                        padding: "0 20px",
                        fontWeight: 800,
                        color: "white",
                      }}
                    >
                      {getChannelResult?.activeChannel.customFields?.brandName}
                    </Col>
                  )}
                </a>
              </TwfButton>
            )}
            <Row gutter={24} align="middle">
              {getChannelResult?.activeChannel.customFields?.brandName && (
                <Col>
                  <span style={{ textTransform: "capitalize" }}>
                    {getAdminResult?.activeAdministrator?.firstName}
                  </span>{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {getAdminResult?.activeAdministrator?.lastName}
                  </span>
                </Col>
              )}

              <Col>
                <TwfButton
                  type-twf="icon"
                  shape="circle"
                  onClick={logoutHandler}
                >
                  <LogoutIcon width={24} color="#C9CED6" />
                </TwfButton>
              </Col>
            </Row>
          </TwfHeaderWrap>
        </TwfHeader>
        <TwfContent>
          <Routes>
            {routes.map((route, key) => (
              <Route
                key={key}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Routes>
        </TwfContent>
      </Layout>
    </Layout>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default TwfLayout;

const LOGOUT_QUERY = gql`
  mutation {
    logout {
      success
    }
  }
`;
