import styled from "styled-components";

const AuthLayoutContainer = styled.div`
  background-color: #f4f9fd;
  padding: 20px 20px 30px;
  height: 100vh;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }
`;

export default AuthLayoutContainer;
