import { combineReducers } from "redux";
import EcommerceReducer from "./ecommerce.reducer";
import UserReducer from "./user.reducer";
import UiReducer from './ui.reducer';

const RootReducer = combineReducers({
  ecommerce: EcommerceReducer,
  user: UserReducer,
  ui:UiReducer
});

export default RootReducer;
