import {
  Col,
  Dropdown,
  Grid,
  Menu,
  message,
  // message,
  Popconfirm,
  Row,
  Table,
} from "antd";
import Container from "component/container";
import {
  CardFragment,
  useDeleteCustomerCardMutation,
  useGetAllCustomerCardsQuery,
  useSetDefaultCardAdminMutation,
} from "generated/graphql";
import React, { useState } from "react";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import { TwfCard } from "../../../component/card";
import { MarginBox } from "../../../component/margin";
import CustomerSingleAddCard from "./addCard";

const { useBreakpoint } = Grid;

interface ComponentProps {
  cardCreateVisible?: boolean;
  setCardCreateVisible: (value: boolean) => void;
  emailAddress?: string;
}

const CustomerSingleCreditCard: React.FC<ComponentProps> = ({
  cardCreateVisible,
  setCardCreateVisible,
  emailAddress,
}) => {
  const breakpoints = useBreakpoint();
  const [loading, _setLoading] = useState(false);
  const { data } = useGetAllCustomerCardsQuery({
    variables: {
      input: {
        customerEmail: emailAddress,
      },
    },
    // eslint-disable-next-line eqeqeq
    skip: emailAddress == undefined,
  });
  const [deleteCustomerCard] = useDeleteCustomerCardMutation();

  const [setDefaultCardAdminMutation] = useSetDefaultCardAdminMutation();

  const confirmDeleteHandler = (record: any) => {
    if (emailAddress) {
      deleteCustomerCard({
        variables: {
          customerEmail: emailAddress,
          id: record.id,
        },
        refetchQueries: ["GetAllCustomerCards"],
      })
        .then(({ data }) => {
          if (
            data?.deleteCustomerPaymentMethod.__typename ===
            "CardListDeleteResponse"
          ) {
            if (data?.deleteCustomerPaymentMethod.isDeleted)
              message.success("Credit card deleted");
          } else if (
            data?.deleteCustomerPaymentMethod.__typename ===
            "PaymentMethodDeleteError"
          )
            message.error(data.deleteCustomerPaymentMethod.message);
        })
        .catch((error) => message.error(error.message));
    }
  };

  const makeDefaultHandler = (record: any) => {
    if (emailAddress) {
      setDefaultCardAdminMutation({
        variables: {
          customerEmail: emailAddress,
          id: record.id,
        },
        refetchQueries: ["GetAllCustomerCards"],
      })
        .then(({ data }) => {
          if (data?.setDefaultCardAdmin?.__typename === "Card") {
            message.success("Credit card successfully set default");
          }
          if (data?.setDefaultCardAdmin?.__typename === "CardNotFoundError") {
            message.error(data.setDefaultCardAdmin.message);
          }
        })
        .catch((error) => message.error(error.message));
    }
  };

  const columns: any[] = [
    {
      title: "Last 4",
      dataIndex: "last4",
      align: "center",
    },
    {
      title: "Default",
      dataIndex: "isDefault",
      render: (value: boolean) => (value ? "Yes" : "No"),
      align: "center",
    },
    {
      title: "Expiry",
      render: (record: CardFragment) => `${record.expYear}/${record.expMonth}`,
      align: "center",
    },
    {
      title: "",
      width: breakpoints.md ? 320 : 50,
      fixed: "right",
      render: (record: any) => {
        console.log(record.isDefault);
        return breakpoints.md ? (
          <Row gutter={12} justify="center">
            <Col>
              <TwfButton
                onClick={() => makeDefaultHandler(record)}
                type="link"
                hidden={record.isDefault}
                style={{ fontWeight: 700, color: "#3C64B1" }}
              >
                {!record.isDefault ? "Make Default" : ""}
              </TwfButton>
            </Col>
            {/*
            <Col>
              <TwfButton shape="circle" type-twf="icon">
                <Icon keyword="edit" color="#3C64B1" />
              </TwfButton>
            </Col>
            */}
            <Col>
            { //!record.isDefault &&
              <Popconfirm
              title="Are you sure to delete this card?"
              onConfirm={() => confirmDeleteHandler(record)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <TwfButton shape="circle" type-twf="icon" style={ record.isDefault ? { paddingLeft:"200%" }:{ paddingLeft:"0%" } }>
                <Icon keyword="delete" color="#FF4C61" />
              </TwfButton>
            </Popconfirm>
            }
            </Col>
          </Row>
        ) : (
          <Dropdown
            overlay={
              <Menu style={{ width: 120, padding: 4, borderRadius: 8 }}>
                <Menu.Item key="edit">
                  <Row gutter={8} align="middle">
                    <Col>
                      <Icon keyword="edit" color="#3C64B1" width={16} />
                    </Col>
                    <Col>Edit</Col>
                  </Row>
                </Menu.Item>
                <Menu.Item key="delete">
                  <Row gutter={8} align="middle">
                    <Col>
                      <Popconfirm
                        title="Are you sure to delete this card?"
                        onConfirm={() => confirmDeleteHandler(record)}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Icon keyword="delete" color="#FF4C61" width={16} />
                      </Popconfirm>
                    </Col>
                    <Col>Delete</Col>
                  </Row>
                </Menu.Item>
              </Menu>
            }
          >
            <TwfButton shape="circle" type-twf="icon">
              <Icon keyword="collapse" width={16} />
            </TwfButton>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Container>
      <TwfCard>
        <MarginBox
          pl={breakpoints.md ? 75 : 15}
          pr={breakpoints.md ? 75 : 15}
          mt={12}
          mb={56}
        >
          <Table
            columns={columns}
            dataSource={data?.getCustomerCards.items.map((obj) => ({
              ...obj,
              key: obj.id,
            }))}
            pagination={false}
            style={{ maxWidth: "max-content" }}
            scroll={{ x: "max-content" }}
          />
        </MarginBox>
        <CustomerSingleAddCard
          visible={cardCreateVisible}
          setVisible={setCardCreateVisible}
          emailAddress={emailAddress}
          loading={loading}
        />
      </TwfCard>
    </Container>
  );
};

export default CustomerSingleCreditCard;
