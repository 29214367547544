import { FunctionComponent } from "react";
import { Row, Col, InputNumber } from "antd";
import TwfButton from "component/button";
import { OperationCard, OperationProps } from "../conditions";

const MininumOrderAmountCondition: FunctionComponent<OperationProps> = ({
  code,
  configArgs,
  onConfigArgChange,
  onRemove,
}) => {
  const orderTotal =
    configArgs.find((arg) => arg.name === "amount")?.value || "0";

  return (
    <OperationCard
      title={`If order total is greater than ${+orderTotal / 100}`}
      style={{ marginTop: 16 }}
      actions={[
        <TwfButton key="remove" type="link" onClick={() => onRemove(code)}>
          Remove
        </TwfButton>,
      ]}
    >
      <Row>
        <Col>
          Amount:{" "}
          <InputNumber
            min={0}
            formatter={(value: any) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
            value={+orderTotal / 100}
            onChange={(value: any) =>
              onConfigArgChange(
                { name: "amount", value: (value * 100).toString() },
                code
              )
            }
            style={{ width: 160 }}
          />
        </Col>
      </Row>
    </OperationCard>
  );
};

export default MininumOrderAmountCondition;
