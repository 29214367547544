import React from "react";
import { Checkbox, Col, Form, Row, Typography } from "antd";
import { TwfCard } from "../../../../component/card";
import { BlockTitle } from "../../../../component/header";
import { MarginBox } from "../../../../component/margin";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const { Item } = Form;

const DiscountCoupling: React.FC = () => {
  const breakpoints = useBreakpoint();
  return (
    <TwfCard
      style={{ padding: breakpoints.md ? "32px 48px" : "15px", minHeight: 600 }}
    >
      <MarginBox mb={12}>
        <BlockTitle>Coupling</BlockTitle>
        <MarginBox mt={32}>
          <Typography>
            <b>Promos</b>
          </Typography>
          <Typography>
            Allow promo to be combined with other discounts
          </Typography>
        </MarginBox>
      </MarginBox>
      <Row gutter={[30, 0]}>
        <Col>
          <Item>
            <Checkbox>12 Bottle Club Member Free Shipping</Checkbox>
          </Item>
          <Item>
            <Checkbox>40% AWC Employee T-shirt</Checkbox>
          </Item>
          <Item>
            <Checkbox>Tasting Buy 1 Get 1</Checkbox>
          </Item>
          <Item>
            <Checkbox>Club 10% Discount</Checkbox>
          </Item>
          <Item>
            <Checkbox>Club 15% Discount</Checkbox>
          </Item>
          <Item>
            <Checkbox>Club Tasting</Checkbox>
          </Item>
          <Item>
            <Checkbox>Employee Discount</Checkbox>
          </Item>
          <Item>
            <Checkbox>Friends</Checkbox>
          </Item>
        </Col>
        <Col>
          <Item>
            <Checkbox>Friends of 10%</Checkbox>
          </Item>
          <Item>
            <Checkbox>Gratitude Discount</Checkbox>
          </Item>
          <Item>
            <Checkbox>Industry Discount 30%</Checkbox>
          </Item>
          <Item>
            <Checkbox>Industry Wine Club Discount</Checkbox>
          </Item>
          <Item>
            <Checkbox>Manager's Discount</Checkbox>
          </Item>
          <Item>
            <Checkbox>Military</Checkbox>
          </Item>
          <Item>
            <Checkbox>Napa Neighbors</Checkbox>
          </Item>
          <Item>
            <Checkbox>Napa Valley Wine Country Tours</Checkbox>
          </Item>
        </Col>
        <Col>
          <Item>
            <Checkbox>Tasting Wine</Checkbox>
          </Item>
        </Col>
      </Row>
    </TwfCard>
  );
};

export default DiscountCoupling;
