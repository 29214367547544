import styled from "styled-components";

const AuthLayoutFooter = styled.div`
  position: absolute;
  width: 100%;
  height: 84px;
  bottom: 0;
  padding: 0 41px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e4e6e8;
  @media (max-width: 991px) {
    border-top: none;
  }
`;

export default AuthLayoutFooter;
