import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const CreditCardIcon = ({ width, color }: IconProps) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0625 12.25C15.847 12.25 15.6403 12.3356 15.488 12.488C15.3356 12.6403 15.25 12.847 15.25 13.0625C15.25 13.278 15.3356 13.4847 15.488 13.637C15.6403 13.7894 15.847 13.875 16.0625 13.875H19.3125C19.528 13.875 19.7347 13.7894 19.887 13.637C20.0394 13.4847 20.125 13.278 20.125 13.0625C20.125 12.847 20.0394 12.6403 19.887 12.488C19.7347 12.3356 19.528 12.25 19.3125 12.25H16.0625Z"
      fill={color ? color : "#000000"}
    />
    <path
      d="M0.625 4.125C0.625 3.26305 0.96741 2.4364 1.5769 1.8269C2.1864 1.21741 3.01305 0.875 3.875 0.875H20.125C20.987 0.875 21.8136 1.21741 22.4231 1.8269C23.0326 2.4364 23.375 3.26305 23.375 4.125V13.875C23.375 14.737 23.0326 15.5636 22.4231 16.1731C21.8136 16.7826 20.987 17.125 20.125 17.125H3.875C3.01305 17.125 2.1864 16.7826 1.5769 16.1731C0.96741 15.5636 0.625 14.737 0.625 13.875V4.125ZM21.75 4.125C21.75 3.69402 21.5788 3.2807 21.274 2.97595C20.9693 2.67121 20.556 2.5 20.125 2.5H3.875C3.44402 2.5 3.0307 2.67121 2.72595 2.97595C2.4212 3.2807 2.25 3.69402 2.25 4.125V5.75H21.75V4.125ZM2.25 13.875C2.25 14.306 2.4212 14.7193 2.72595 15.024C3.0307 15.3288 3.44402 15.5 3.875 15.5H20.125C20.556 15.5 20.9693 15.3288 21.274 15.024C21.5788 14.7193 21.75 14.306 21.75 13.875V7.375H2.25V13.875Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export default CreditCardIcon;
