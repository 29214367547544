import React, { useState } from "react";
import Icon from "../../../assets/icons";
import TwfModal from "../../../component/modal/index";
import { UploadButton } from "./style";
import { Col, Row } from "antd";
import { Asset } from "generated/graphql";
import AssetList from "./asset-list";
import Container from "component/container";
import ImageWallCreate from "../image-wall/create";
import {  useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { productAssetsAction } from 'store/action/ui.action';


const AssetSelectorCreate: React.FC = () => {
  const selectedProductAssets = useSelector((state: { ui: { createProductAssets: any[] } }) => state.ui.createProductAssets);
  const dispatch: AppDispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<Asset>();
  const submitHandler = () => {
    if (selectedAsset) {
     dispatch(productAssetsAction([...selectedProductAssets,selectedAsset]));
      setVisible(false);
    }
  };

  return (
    <Container>
      <Row gutter={[8, 8]}>
        <Col>
          <ImageWallCreate  />
        </Col>
        <Col>
         
         {selectedProductAssets.length < 5 ?   <UploadButton
              color-twf="primary"
              type="default"
              size-twf="sm"
              type-twf="normal"
              onClick={() => setVisible(true)}
              style={{ height: 100, padding: 25 }}
            >
              <Icon keyword="plus" width={12} color="#333" />
              Image
            </UploadButton>:null}
         
        </Col>
      </Row>
      <TwfModal
        open={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={submitHandler}
        width="100%"
        style={{ maxWidth: "1500px" }}
      >
        <AssetList isModal onAssetSelect={setSelectedAsset} />
      </TwfModal>
    </Container>
  );
};

export default AssetSelectorCreate;
