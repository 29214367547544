import {
  Col,
  DatePicker,
  Form,
  Grid,
  Row,
  Table,
  TablePaginationConfig,
} from "antd";
import Container from "component/container";
import {
  OrderLine,
  ProductVariant,
  useGetOrderLineListLazyQuery,
} from "generated/graphql";
import React, { useEffect, useState } from "react";
import CsvDownloader from "react-csv-downloader";
import { useParams } from "react-router";
import TwfButton from "../../../component/button";
import { TwfCard } from "../../../component/card";
import { MarginBox } from "../../../component/margin";
import { TableOptions } from "./../../orders/components/datatable";

const { useBreakpoint } = Grid;

const { Item } = Form;

interface Filter {
  fromDate?: Date;
  toDate?: Date;
}

const CustomerSinglePurchased: React.FC = () => {
  const breakpoints = useBreakpoint();
  const { id } = useParams<{ id: string }>();
  const cid: string = id as string;

  const [getOrderLineListQuery, { data, loading }] =
    useGetOrderLineListLazyQuery();

  const [filter, setFilter] = useState<Filter>();
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    pagination: { current: 1, pageSize: 10 },
  });

  useEffect(() => {
    getOrderLineListQuery({
      variables: {
        customerId: cid,
        options: {
          filter: {
            createdAt: {
              ...(filter?.fromDate ? { after: filter.fromDate } : {}),
              ...(filter?.toDate ? { before: filter.toDate } : {}),
            },
          },
          skip:
            (tableOptions.pagination.pageSize || 10) *
            ((tableOptions.pagination.current || 1) - 1),
          take: tableOptions.pagination.pageSize,
        },
      },
    });
  }, [getOrderLineListQuery, tableOptions, filter, cid]);

  const tableChangeHandler = (pagination: TablePaginationConfig) => {
    setTableOptions({
      pagination,
    });
  };

  const csvData = (data?.orderLines.items || []).map((line: any) => ({
    name: line.productVariant.name,
    quantity: line.items.length,
    SKU: line.productVariant.sku,
    orderId:
      line.order.channel?.customFields?.orderPrefix + "-" + line.order.id,
    purchaseDate: new Date(line.createdAt).toLocaleString().split(",")[0],
    purchaseTime: new Date(line.createdAt).toLocaleString().split(",")[1],
  }));

  const columns: any[] = [
    {
      title: "Product",
      dataIndex: "productVariant",
      align: "center",
      render: (variant: ProductVariant) => variant.name,
    },
    {
      title: "Quantity",
      dataIndex: "items",
      align: "center",
      render: (items: any[]) => items.length,
    },
    {
      title: "SKU",
      dataIndex: "productVariant",
      align: "center",
      render: (variant: ProductVariant) => variant.sku,
    },
    {
      title: "Order Id",
      align: "center",
      render: (orderLine: OrderLine) => {
        const prefix = orderLine.order.channel?.customFields?.orderPrefix;
        return prefix + "-" + orderLine.order.id;
      },
    },
    {
      title: "Purchased Date",
      dataIndex: "createdAt",
      align: "center",
      render: (createdAt: any) =>
        new Date(createdAt).toLocaleString().split(",")[0],
    },
    {
      title: "Purchased Time",
      dataIndex: "createdAt",
      align: "center",
      render: (createdAt: any) =>
        new Date(createdAt).toLocaleString().split(",")[1],
    },
  ];

  return (
    <Container>
      <TwfCard>
        <Form layout="vertical">
          <MarginBox
            pl={breakpoints.md ? 75 : 15}
            pr={breakpoints.md ? 75 : 15}
            pt={breakpoints.md ? 32 : 21}
            mb={breakpoints.md ? 32 : 21}
          >
            <Row gutter={breakpoints.md ? 24 : 12}>
              <Col lg={{ span: 6 }} span={12}>
                <Item label="From Date">
                  <DatePicker
                    onChange={(e: any) =>
                      setFilter({ ...filter, fromDate: e ? e._d : null })
                    }
                  />
                </Item>
              </Col>
              <Col lg={{ span: 6 }} span={12}>
                <Item label="To Date">
                  <DatePicker
                    onChange={(e: any) =>
                      setFilter({ ...filter, toDate: e ? e._d : null })
                    }
                  />
                </Item>
              </Col>
              {breakpoints.md && (
                <Col lg={{ span: 12 }} span={24}>
                  <MarginBox mt={28}>
                    <Item>
                      <CsvDownloader
                        datas={csvData}
                        filename="purchased products"
                      >
                        <TwfButton
                          type="primary"
                          color-twf="primary"
                          size-twf="md"
                          type-twf="wide"
                        >
                          Export Products
                        </TwfButton>
                      </CsvDownloader>
                    </Item>
                  </MarginBox>
                </Col>
              )}
            </Row>
          </MarginBox>
        </Form>
        <MarginBox
          pr={breakpoints.md ? 75 : 15}
          pl={breakpoints.md ? 75 : 15}
          mb={56}
        >
          <Table
            columns={columns}
            dataSource={data?.orderLines.items}
            loading={loading}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} Items`,
              ...tableOptions.pagination,
              total: data?.orderLines.totalItems,
              position: ["bottomCenter"],
              showSizeChanger: true,
            }}
            onChange={tableChangeHandler}
            style={{ maxWidth: "max-content" }}
            scroll={{ x: "max-content" }}
          />
        </MarginBox>
      </TwfCard>
    </Container>
  );
};

export default CustomerSinglePurchased;
