import { FC } from "react";
import { Price } from "utils/price";
import { Order } from "generated/graphql";
import { currencyFormatter } from "utils/currency-formatter";

import {
  PriceDetail,
  PriceTotalDetail,
  PriceTotalValue,
  PriceValue,
} from "component/table/price";

export interface Props {
  order: Order | any;
}

const PricingTable: FC<Props> = ({ order }) => {
  return (
    <table style={{ width: "100%" }}>
      <tbody>
        <tr>
          <PriceDetail>Products</PriceDetail>
          <PriceValue>
            <Price priceWithTax={order?.subTotal} />
          </PriceValue>
        </tr>
        <tr>
          <PriceDetail>Shipping</PriceDetail>
          <PriceValue>
            <Price priceWithTax={order?.shipping ?? 0} />
          </PriceValue>
        </tr>
        <tr>
          <PriceDetail>Discounts</PriceDetail>
          <PriceValue>
            <Price
              priceWithTax={order?.discounts?.reduce(
                (total: any, discount: any) => total + discount.amount,
                0
              )}
            />
          </PriceValue>
        </tr>
        <tr>
          <PriceDetail>Total before tax</PriceDetail>
          <PriceValue>
            {currencyFormatter.format((order?.subTotal + order.shipping) / 100)}
          </PriceValue>
        </tr>
        <tr>
          <PriceDetail>Estimated tax to be collected</PriceDetail>
          <PriceValue>
            <Price priceWithTax={order?.subTotalWithTax - order?.subTotal} />
          </PriceValue>
        </tr>
        <tr>
          <PriceTotalDetail>Total</PriceTotalDetail>
          <PriceTotalValue>
            <Price priceWithTax={order?.totalWithTax} />
          </PriceTotalValue>
        </tr>
      </tbody>
    </table>
  );
};

export default PricingTable;
