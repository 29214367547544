import React, { FC, Fragment } from "react";
import { Table } from "antd";

interface AnnouncementsProps {}

const data = [
  {
    key: 1,
    event: "Latest version of the system has been updated.",
    date: "05/26/2021"
  },
  {
    key: 2,
    event: "Legal agreement changed.",
    date: "05/25/2021"
  }
]

const Announcements: FC<AnnouncementsProps> = () => {

  const columns: any = [
    {
      title: "",
      dataIndex: "event",
    },
    {
      title: "",
      dataIndex: "date",
      align: 'right'
    }
  ]

  return (
    <Fragment>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </Fragment>
  );
};

export default Announcements;