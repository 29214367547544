import React from "react";
import { Col, Row } from "antd";
import { PageTitle } from "../../../component/header";
import TwfButton from "../../../component/button";
import { MarginBox } from "../../../component/margin";
import ShippingGeneral from "./general";
import BackButton from "../../../component/button/back";
import { useNavigate } from "react-router-dom";

const ShippingSingle: React.FC = () => {

  const history = useNavigate();

  return (
    <div>
        <Row gutter={[30, 15]} align="middle" justify="space-between">
          <Col>
            <Row gutter={12} align="middle">
              <Col>
                <BackButton />
              </Col>
              <Col>
                <PageTitle>Shipping</PageTitle>
              </Col>
            </Row>
          </Col>
          <Col>
          <Row gutter={24}>
            <Col>
            <TwfButton
            color-twf="gray-line"
            size-twf="md"
            type-twf="wide"
            onClick={()=>{
              history(`/shipping`);
            }}
            >
              Cancel
            </TwfButton>
            </Col>
            <Col>
            {/*
            <TwfButton
            type="primary"
            color-twf="primary"
            size-twf="md"
            type-twf="wide"
            onClick = {()=>{
              
            }}
            >
              Save
              </TwfButton>
              */}
              </Col>
              </Row>
              </Col>
              </Row>
              <MarginBox mt={32}>
                <ShippingGeneral />
              </MarginBox>
    </div>
  );
};

export default ShippingSingle;
