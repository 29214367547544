import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const DecreaseIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.75 35.418L27.0833 18.7513L18.75 27.0846L6.25 14.5846M27.0833 35.418H43.75H27.0833ZM43.75 35.418V18.7513V35.418Z"
      stroke={color ? color : "#000000"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DecreaseIcon;
