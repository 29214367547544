/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Icon from "../../../assets/icons";
import { Table, Dropdown, Menu, message, Popconfirm } from "antd";
import TwfButton from "../../../component/button";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  GetClubQuery,
  SortOrder,
  useDeleteClubCustomerMutation,
  useUpdateClubCustomerMutation,
} from "generated/graphql";
import { TablePaginationConfig } from "antd/es";
import AdvancedSearch from "pages/orders/components/search";
import { ClubCustomerState } from "enums/enums";
import { useNavigate } from "react-router-dom";

export interface TableOptions {
  filter?: any;
  skip: number;
  take: number;
  sort?: any;
}

interface ComponentProps {
  data?: GetClubQuery;
  onCustomerSelectionchange: (customerIds: string[]) => void;
  tableOptions: TableOptions;
  onTableOptionsChange: (tableOptions: TableOptions) => void;
  loading: boolean;
  setSearchTerm: (searchTerm: string) => void;
}

const CustomerList: React.FC<ComponentProps> = ({
  data,
  onCustomerSelectionchange,
  tableOptions,
  onTableOptionsChange,
  loading,
  setSearchTerm,
}) => {
  const navigate = useNavigate();
  const breakpoints = useBreakpoint();
  const [updateClubCustomerMutation] = useUpdateClubCustomerMutation();
  const [deleteClubCustomerMutation] = useDeleteClubCustomerMutation();

  const [isSuperadmin, setIsSuperAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("used-channel") === "__default_channel__") {
        setIsSuperAdmin(true);
      }
    }
  }, []);

  const updateClubCustomer = (id: string, status: ClubCustomerState) => {
    updateClubCustomerMutation({
      variables: { input: { id, status } },
      refetchQueries: ["GetClub", "getClubStat"],
    })
      .then(({ data }) => {
        if (data?.updateClubCustomer.__typename === "ClubCustomer")
          message.success("Customer updated");
      })
      .catch((error) => message.error(error.message));
  };

  const deleteClubCustomer = (id: string) => {
    deleteClubCustomerMutation({
      variables: { id },
      refetchQueries: ["GetClub", "getClubStat"],
    })
      .then(({ data }) => {
        if (data?.deleteClubCustomer.result === "DELETED") {
          message.success("Customer deleted");
        } else {
          message.error(
            "Customer not deleted:" + data?.deleteClubCustomer.result
          );
        }
      })
      .catch((error) => message.error(error.message));
  };

  const filterChangeHandler = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const tableChangeHandler = (
    pagination: TablePaginationConfig,
    filters: any,
    sort: any
  ) => {
    onTableOptionsChange({
      filter: tableOptions.filter,
      skip: (pagination.pageSize || 10) * ((pagination.current || 1) - 1),
      take: pagination.pageSize || 10,
      sort: sort.field
        ? {
            [sort.field]:
              sort.order === "ascend" ? SortOrder.Asc : SortOrder.Desc,
          }
        : tableOptions.sort,
    });
  };

  const onSelectionChange = (selectedRowKeys: any[]) => {
    onCustomerSelectionchange(selectedRowKeys);
  };

  const navigateToCustomer = (id: string) => {
    const url = "/customers/single/" + id;
    navigate(url);
  };

  const rowSelection = {
    selectedKeys: [],
    onChange: onSelectionChange,
  };

  const columns: any = [
    {
      title: "Name",
      dataIndex: "customer",
      render: (record: any) => {
        const fname = record.firstName + " " + record.lastName;
        //const url = "/customers/single/" + record.id;
        return (
          // eslint-disable-next-line no-script-url
          <a
            href="javascript:void(0);"
            onClick={() => {
              navigateToCustomer(record.id);
            }}
          >
            {fname}
          </a>
        );
      },
    },
    {
      title: "Email",
      dataIndex: ["customer", "emailAddress"],
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: number) => ClubCustomerState[status],
    },
    {
      title: "",
      render: (record: any) => {
        //console.log(">>>>>>>>>>",record)
        return (
          <Dropdown
            placement="bottomRight"
            overlay={
              <Menu style={{ borderRadius: 12 }}>
                {record.status !== 2 && <Menu.Item
                  key="key2"
                  onClick={() =>
                    updateClubCustomer(record.id, ClubCustomerState.Paused)
                  }
                >
                  Pause Membership
                </Menu.Item>}
              {record.status !== 3 &&  <Menu.Item
                  key="key3"
                  onClick={() =>
                    updateClubCustomer(record.id, ClubCustomerState.Cancelled)
                  }
                >
                  Cancel Membership
                </Menu.Item>}
             {  record.status !== 1 && <Menu.Item
                  key="key4"
                  onClick={() =>
                    updateClubCustomer(record.id, ClubCustomerState.Active)
                  }
                >
                  Activate Membership
                </Menu.Item>}
                {isSuperadmin && (
                  <Popconfirm
                    title="Are you sure to delete this club customer?"
                    onConfirm={() => deleteClubCustomer(record.id)}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Menu.Item key="key5">Delete Membership</Menu.Item>
                  </Popconfirm>
                )}
              </Menu>
            }
          >
            {breakpoints.md ? (
              <TwfButton size-twf="sm" color-twf="gray-line">
                Actions <Icon keyword="chevron-down" color="#7D8592" />
              </TwfButton>
            ) : (
              <TwfButton shape="circle" type-twf="icon">
                <Icon keyword="collapse" color="#7D8592" width={16} />
              </TwfButton>
            )}
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <AdvancedSearch
        placeholder="Search by name, email address"
        setFilter={filterChangeHandler}
      />
      <Table
        loading={loading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data?.club?.clubCustomers.map((obj) => ({
          ...obj,
          key: obj.id,
        }))}
        pagination={{
          showSizeChanger: true,
          pageSize: tableOptions.take,
          current: tableOptions.skip / tableOptions.take + 1,
          total: data?.club?.clubCustomers.map((c) => c.customer).length,
          position: ["bottomCenter"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} Items`,
        }}
        onChange={tableChangeHandler}
        style={{ minHeight: 300 }}
      />
    </>
  );
};




export default CustomerList;
