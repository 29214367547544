import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const DeleteIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 21 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.46875 0.125C7.92941 0.125 7.37666 0.31475 6.98581 0.705594C6.596 1.09438 6.40625 1.64713 6.40625 2.1875V3.21875H0.21875V5.28125H1.25V21.7812C1.25 23.4777 2.64734 24.875 4.34375 24.875H16.7188C18.4152 24.875 19.8125 23.4777 19.8125 21.7812V5.28125H20.8438V3.21875H14.6562V2.1875C14.6562 1.64816 14.4665 1.09541 14.0757 0.704562C13.6869 0.31475 13.1331 0.125 12.5938 0.125H8.46875ZM8.46875 2.1875H12.5938V3.21875H8.46875V2.1875ZM3.3125 5.28125H17.75V21.7812C17.75 22.3536 17.2911 22.8125 16.7188 22.8125H4.34375C3.77141 22.8125 3.3125 22.3536 3.3125 21.7812V5.28125ZM5.375 8.375V19.7188H7.4375V8.375H5.375ZM9.5 8.375V19.7188H11.5625V8.375H9.5ZM13.625 8.375V19.7188H15.6875V8.375H13.625Z"
      fill={color ? color : "#000000"}
    />
  </svg>
);

export default DeleteIcon;
