import { Col, DatePicker, Form, Input, Row, Select, Space } from "antd";
import TwfButton from "component/button";
import { H3 } from "component/header";
import TwfModal from "component/modal";
import { FC, Fragment } from "react";
import { ChevronDownIcon } from "../../../assets/icons/chevron";
import CloseIcon from "../../../assets/icons/close";

const { Item } = Form;
const { Option } = Select;

interface NewClubProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const NewClub: FC<NewClubProps> = ({ visible, setVisible }) => {
  const submitHandler = (data: any) => {
    console.log(data);
  };
  return (
    <Fragment>
      <TwfModal
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        closeIcon={<CloseIcon />}
        centered
        width={771}
        footer={false}
      >
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
          <H3>Add New Club</H3>
          <Form layout="vertical" onFinish={submitHandler}>
            <Row>
              <Col span={24}>
                <Item label="Club" name="Option">
                  <Select placeholder="Select" suffixIcon={<ChevronDownIcon />}>
                    <Option value="c1">Club 1</Option>
                  </Select>
                </Item>
                <Item label="Club Option" name="clubOption">
                  <Select placeholder="Select" suffixIcon={<ChevronDownIcon />}>
                    <Option value="co1">Club Option 1</Option>
                  </Select>
                </Item>
                <Item label="Billing Address" name="billingAddress">
                  <Select placeholder="Select" suffixIcon={<ChevronDownIcon />}>
                    <Option value="a1">Address 1</Option>
                  </Select>
                </Item>
                <Item label="Shipping Address" name="shippingAddress">
                  <Select placeholder="Select" suffixIcon={<ChevronDownIcon />}>
                    <Option value="as1">Shipping Address 1</Option>
                  </Select>
                </Item>
                <Item label="Credit Card" name="shippingAddress">
                  <Select placeholder="Select" suffixIcon={<ChevronDownIcon />}>
                    <Option value="cc1">Credit Card 1</Option>
                  </Select>
                </Item>
                <Item label="Shipment Type" name="shipmentType">
                  <Select placeholder="Select" suffixIcon={<ChevronDownIcon />}>
                    <Option value="s1">Shipment 1</Option>
                  </Select>
                </Item>
                <Item label="Signup Date" name="signupDate">
                  <DatePicker />
                </Item>
                <Item label="Source" name="source">
                  <Select placeholder="Select" suffixIcon={<ChevronDownIcon />}>
                    <Option value="ss1">Source 1</Option>
                  </Select>
                </Item>
                <Item label="Note" name="note">
                  <Input />
                </Item>
              </Col>
            </Row>
            <div style={{ height: 24 }} />
            <Row gutter={12} justify="end">
              <Col>
                <TwfButton type="primary" ghost size-twf="md" type-twf="wide">
                  Cancel
                </TwfButton>
              </Col>
              <Col>
                <TwfButton
                  type="primary"
                  size-twf="md"
                  type-twf="wide"
                  htmlType="submit"
                >
                  Add
                </TwfButton>
              </Col>
            </Row>
          </Form>
        </Space>
      </TwfModal>
    </Fragment>
  );
};

export default NewClub;
