import React from "react";
import AccountIcon from "./account";
import AnnounceIcon from "./announce";
import ArchiveIcon from "./archive";
import { ArrowLeftIcon, ArrowRightIcon } from "./arrow";
import BottleIcon from "./bottle";
import { BurgerLongIcon } from "./burger";
import { LineChartIcon } from "./chart";
import { ChevronDownIcon } from "./chevron";
import CloseIcon from "./close";
import ClubIcon from "./club";
import CollapseIcon from "./collapse";
import ContentViewIcon from "./content-view";
import CreditCardIcon from "./credit-card";
import CustomersIcon from "./customers";
import DashboardIcon from "./dashboard";
import DecreaseIcon from "./decrease";
import DeleteIcon from "./delete";
import DiscountsIcon from "./discounts";
import DropdownIcon from "./drop";
import DuplicateIcon from "./duplicate";
import EditIcon from "./edit";
import EmailManagementIcon from "./email";
import ExportIcon from "./export";
import GuideIcon from "./guide";
import HideIcon from "./hide";
import HomeIcon from "./home";
import IncreaseIcon from "./increase";
import InfoIcon from "./info";
import LockIcon from "./lock";
import MarketingIcon from "./marketing";
import PackageIcon from "./package";
import PaperIcon from "./paper";
import PencilIcon from "./pencil";
import PhotoIcon from "./photo";
import PictureIcon from "./picture";
import PlusIcon from "./plus";
import ProcessIcon from "./process";
import SearchIcon from "./search";
import SettingIcon from "./setting";
import ShippingIcon from "./shipping";
import SortIcon from "./sort";

interface IconProps {
  width?: number;
  color?: string;
  keyword: string;
}

const Icon: React.FC<IconProps> = ({ keyword, width, color }) => {
  return (
    <>
      {
        {
          bottle: <BottleIcon width={width} color={color} />,
          home: <HomeIcon width={width} color={color} />,
          credit: <CreditCardIcon width={width} color={color} />,
          customers: <CustomersIcon width={width} color={color} />,
          photo: <PhotoIcon width={width} color={color} />,
          club: <ClubIcon width={width} color={color} />,
          marketing: <MarketingIcon width={width} color={color} />,
          lineChart: <LineChartIcon width={width} color={color} />,
          shipping: <ShippingIcon width={width} color={color} />,
          paper: <PaperIcon width={width} color={color} />,
          guide: <GuideIcon width={width} color={color} />,
          "arrow-right": <ArrowRightIcon width={width} color={color} />,
          "arrow-left": <ArrowLeftIcon width={width} color={color} />,
          increase: <IncreaseIcon width={width} color={color} />,
          decrease: <DecreaseIcon width={width} color={color} />,
          hide: <HideIcon width={width} color={color} />,
          announce: <AnnounceIcon width={width} color={color} />,
          "chevron-down": <ChevronDownIcon width={width} color={color} />,
          plus: <PlusIcon width={width} color={color} />,
          search: <SearchIcon width={width} color={color} />,
          sort: <SortIcon width={width} color={color} />,
          dropdown: <DropdownIcon width={width} color={color} />,
          archive: <ArchiveIcon width={width} color={color} />,
          export: <ExportIcon width={width} color={color} />,
          close: <CloseIcon width={width} color={color} />,
          edit: <EditIcon width={width} color={color} />,
          delete: <DeleteIcon width={width} color={color} />,
          package: <PackageIcon width={width} color={color} />,
          "burger-long": <BurgerLongIcon width={width} color={color} />,
          collapse: <CollapseIcon width={width} color={color} />,
          info: <InfoIcon width={width} color={color} />,
          duplicate: <DuplicateIcon width={width} color={color} />,
          "content-view": <ContentViewIcon width={width} color={color} />,
          discounts: <DiscountsIcon width={width} color={color} />,
          process: <ProcessIcon width={width} color={color} />,
          setting: <SettingIcon width={width} color={color} />,
          account: <AccountIcon width={width} color={color} />,
          pencil: <PencilIcon width={width} color={color} />,
          picture: <PictureIcon width={width} color={color} />,
          lock: <LockIcon width={width} color={color} />,
          "email-management": (
            <EmailManagementIcon width={width} color={color} />
          ),
          dashboard: <DashboardIcon width={width} color={color} />,
          default: <HomeIcon width={width} color={color} />,
        }[keyword]
      }
    </>
  );
};

export default Icon;
