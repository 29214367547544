import { Pagination, Timeline } from "antd";
import Icon from "assets/icons";
import { H1, H3, H4 } from "component/header";
import { MarginBox } from "component/margin";
import TwfModal from "component/modal";
import ModalTitle from "component/modal/title";
import { FC, useEffect } from "react";
import { Collapse } from 'antd';
import styled from "styled-components";

const { Panel } = Collapse;

interface OrderHistoryProps {
  visible: boolean;
  orderId: string;
  orderHistory: any;
  setVisible: (value: boolean) => void;
  totalItems: number;
  pagination: {
    current: number;
    pageSize: number;
};
  updatePagination: React.Dispatch<React.SetStateAction<{
    current: number;
    pageSize: number;
}>>
}


const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
  timeZone: "America/Los_Angeles",
};

const OrderHistory: FC<OrderHistoryProps> = ({ visible, setVisible, orderHistory, totalItems, pagination, updatePagination }) => {
  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
        updatePagination((p)=>{
      return {current:1,pageSize:p.pageSize};
    });
      }}
      centered
      width={746}
      footer={false}
      closeIcon={<Icon keyword="close" width={24} color="#0A1629" />}
    >
      <ModalTitle>
        <H3>Order History</H3>
      </ModalTitle>
      <MarginBox mt={24}>
        <Timeline>
          {
            orderHistory.map((h: any) => (
              <Timeline.Item key={h.date}>
                <p style={{ fontSize: 12 }}>{new Intl.DateTimeFormat("default", dateOptions).format(Date.parse(h.date))} · {h.creator}</p>
                <CollapseWrapper>
                  <Collapse defaultActiveKey={['1']} ghost expandIconPosition='end'>
                    <Panel header={<H4>{h.heading}</H4>} key={h.date}>
                      <p>{h.text}</p>
                    </Panel>
                  </Collapse>
                </CollapseWrapper>

              </Timeline.Item>
            ))
          }
        </Timeline>
        <Pagination
          defaultPageSize={pagination?.pageSize}
          total={totalItems}
          style={{ width: "max-content", margin:"auto" }}
          onChange={
            (page, pageSize)=>{
              updatePagination((p)=>{
                return {current:page,pageSize};
              });
            }
          }
          current={pagination?.current}
        />
      </MarginBox>

    </TwfModal>
  );
}

export default OrderHistory;

const CollapseWrapper = styled.div`
.ant-collapse > .ant-collapse-item{
  border: none;
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header{
  padding: 0;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
  padding:0;
}
`