/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import styled from "styled-components";
import TwfButton from "../../../component/button";
import { Input, message } from "antd";
import { useApplyCouponCodeMutation, useGetOrderQuery, useRemoveCouponCodeMutation } from "generated/graphql";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setOrder } from "store/action/ecommerce.action";

interface DiscountProps { 
  orderId?:string; 
  doDiscount: number;
  makeDiscount: number;
}

const DiscountApply: React.FC<DiscountProps> = ({
  orderId,
  doDiscount,
  makeDiscount
}) => {
  let { id } = useParams<{ id: string }>();
  if(!id){
    id = orderId
  }
  const cid:string = id as string;
  const [couponCode, setCouponCode] = useState<string>("");
  const [appliedCoupons, setAppliedCoupons] = useState<string[]>([]);

  const { data: orderResult } = useGetOrderQuery({
    variables: {
      id:cid,
    },
  });



  const dispatch = useDispatch();



//TODO check orderResult.order
  useEffect(() => {
  if(orderResult){
    if(orderResult.order?.couponCodes.length !== 0){
      setAppliedCoupons(orderResult.order?.couponCodes!)
    }
  }
  },[orderResult])

  const [applyCouponCode, { loading }] = useApplyCouponCodeMutation({
    variables: { orderId:cid, couponCode },
    fetchPolicy: "network-only"
  });

  const [removeCouponCode] = useRemoveCouponCodeMutation({ fetchPolicy:"network-only" });

  const formSubmitHandler = () => {
    if (couponCode){
      applyCouponCode().then(({ data }) => {
        if(data){
          if (data.applyCouponCode.__typename === "Order") {
            setAppliedCoupons([...new Set([...appliedCoupons, couponCode])]);
            setCouponCode("");
            dispatch<any>(setOrder(data.applyCouponCode));
         }
         if(data.applyCouponCode.__typename==="CouponCodeExpiredError" ||
         data.applyCouponCode.__typename==="CouponCodeLimitError" ||
         data.applyCouponCode.__typename==="CouponCodeInvalidError"){
           message.error(data.applyCouponCode.message)
         }
      }
      });
    }
  };
  
  const removeCouponCodeHandler = (couponCode: string, notRemove?:boolean) => {
    removeCouponCode({ variables: { orderId:cid,couponCode } }).then(
      ({ data }) => {
        if(data?.removeCouponCode){
          if (data.removeCouponCode.__typename === "Order") {
            if(!notRemove){
              setAppliedCoupons([
                ...appliedCoupons.filter((c) => c !== couponCode),
              ]);
            }else{
              setCouponCode(couponCode);
            }
          dispatch<any>(setOrder(data.removeCouponCode));
        }
      }
      }
    );
  };
  
  return (
    <Wrapper>
      <DiscountInput placeholder="Discount Code" 
      value={couponCode}
      onChange={(e) => setCouponCode(e.target.value)}/>
      <TwfButton
        color-twf="primary-line"
        size-twf="xm"
        style={{ borderRadius: 20, fontWeight: 700 }}
        loading={loading}
        onClick={formSubmitHandler}
      >
        Apply
      </TwfButton>
      {(appliedCoupons.length>0)? appliedCoupons.map((coupon: any) => (
            <div style={{ width:"100%" }} key={coupon.id+"m"}>
              <div key={coupon.id+"c"} style={{ padding: 10 }}> Coupon "{coupon}" applied</div>
              <div key={coupon.id+"cb"}>
                <TwfButton key={coupon.id+"crb"} onClick={() => removeCouponCodeHandler(coupon)}>
                  Remove
                </TwfButton>
              </div>
            </div>
      )):<></>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 71px;
  grid-gap: 13px;
`;

const DiscountInput = styled(Input)`
  border-radius: 12px;
  background-color: #f4f5f4;
  border: none;
  padding: 0 16px;
`;

export default DiscountApply;
