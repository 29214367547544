import styled from "styled-components";

const Label = styled.label`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #7D8592;
  margin-bottom: 8px;
  display: inline-block;
`

export default Label