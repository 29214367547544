import React from "react";

interface IconProps {
  width?: number;
  color?: string;
}

const DiscountsIcon: React.FC<IconProps> = ({ width, color }) => (
  <svg
    width={width ? width : 22}
    height={width ? width : 22}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 15L15 9"
      stroke={color ? color : "#000000"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 10C9.77614 10 10 9.77614 10 9.5C10 9.22386 9.77614 9 9.5 9C9.22386 9 9 9.22386 9 9.5C9 9.77614 9.22386 10 9.5 10Z"
      fill="white"
      stroke={color ? color : "#000000"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 15C14.7761 15 15 14.7761 15 14.5C15 14.2239 14.7761 14 14.5 14C14.2239 14 14 14.2239 14 14.5C14 14.7761 14.2239 15 14.5 15Z"
      fill="white"
      stroke={color ? color : "#000000"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.00046 7.20046C5.00046 6.61698 5.23225 6.05741 5.64483 5.64483C6.05741 5.23225 6.61698 5.00046 7.20046 5.00046H8.20046C8.78136 5.00013 9.33855 4.77006 9.75046 4.36046L10.4505 3.66046C10.6549 3.45486 10.898 3.2917 11.1657 3.18037C11.4334 3.06903 11.7205 3.01172 12.0105 3.01172C12.3004 3.01172 12.5875 3.06903 12.8552 3.18037C13.1229 3.2917 13.366 3.45486 13.5705 3.66046L14.2705 4.36046C14.6824 4.77006 15.2396 5.00013 15.8205 5.00046H16.8205C17.4039 5.00046 17.9635 5.23225 18.3761 5.64483C18.7887 6.05741 19.0205 6.61698 19.0205 7.20046V8.20046C19.0208 8.78136 19.2509 9.33855 19.6605 9.75046L20.3605 10.4505C20.5661 10.6549 20.7292 10.898 20.8406 11.1657C20.9519 11.4334 21.0092 11.7205 21.0092 12.0105C21.0092 12.3004 20.9519 12.5875 20.8406 12.8552C20.7292 13.1229 20.5661 13.366 20.3605 13.5705L19.6605 14.2705C19.2509 14.6824 19.0208 15.2396 19.0205 15.8205V16.8205C19.0205 17.4039 18.7887 17.9635 18.3761 18.3761C17.9635 18.7887 17.4039 19.0205 16.8205 19.0205H15.8205C15.2396 19.0208 14.6824 19.2509 14.2705 19.6605L13.5705 20.3605C13.366 20.5661 13.1229 20.7292 12.8552 20.8406C12.5875 20.9519 12.3004 21.0092 12.0105 21.0092C11.7205 21.0092 11.4334 20.9519 11.1657 20.8406C10.898 20.7292 10.6549 20.5661 10.4505 20.3605L9.75046 19.6605C9.33855 19.2509 8.78136 19.0208 8.20046 19.0205H7.20046C6.61698 19.0205 6.05741 18.7887 5.64483 18.3761C5.23225 17.9635 5.00046 17.4039 5.00046 16.8205V15.8205C5.00013 15.2396 4.77006 14.6824 4.36046 14.2705L3.66046 13.5705C3.45486 13.366 3.2917 13.1229 3.18037 12.8552C3.06903 12.5875 3.01172 12.3004 3.01172 12.0105C3.01172 11.7205 3.06903 11.4334 3.18037 11.1657C3.2917 10.898 3.45486 10.6549 3.66046 10.4505L4.36046 9.75046C4.77006 9.33855 5.00013 8.78136 5.00046 8.20046V7.20046"
      stroke={color ? color : "#000000"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DiscountsIcon;
