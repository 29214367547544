import React, { useEffect, useState } from "react";
import {  Table, TablePaginationConfig } from "antd";
import { SortOrder } from "generated/graphql";
import { useLazyQuery } from "@apollo/client";
import { GET_TWF_SHIPPING_RATES } from "graphql/queries.graphql";
import BackButton from "component/button/back";
import Container from "component/container";
//import TwfButton from "../../../component/button";
//import Icon from "../../../assets/icons";

export interface TableOptions {
  pagination: TablePaginationConfig;
  sort?: any;
}
interface ComponentProps {}

const ShippingMethodsTable: React.FC<ComponentProps> = () => {
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    pagination: { current: 1, pageSize: 12 },
    sort: { id: SortOrder.Asc },
  });

  const queryVariables = {
    options: {
      skip:
        (tableOptions.pagination.pageSize || 12) *
        ((tableOptions.pagination.current || 1) - 1),
      take: tableOptions.pagination.pageSize,
      sort: tableOptions.sort,
    },
  };

  const [getSMS, { data, loading }] = useLazyQuery(GET_TWF_SHIPPING_RATES, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getSMS({
      variables: queryVariables,
    });
    // eslint-disable-next-line
  }, [getSMS, tableOptions]);

  const columns: any[] = [
    {
      title: "Shipping Method",
      dataIndex: "shippingMethodCode",
      key: "shippingMethod",
      align: "center",
    },
    {
      title: "Size (ML)",
      dataIndex: "bottleSize",
      key: "sizeMl",
      align: "center",
    },
    {
      title: "Units",
      dataIndex: "quantity",
      key: "units",
      align: "center",
    },
    {
      title: "Rate",
      dataIndex: "price",
      key: "rate",
      align: "center",
      render: (price: number) => `$${(price / 100).toFixed(2)}`,
    },
    {
      title: "Zone",
      dataIndex: "stateCode",
      key: "zones",
      align: "center",
    },

    /*{
      title: "",
      key: "action",
      align: "center",
      render: () => {
        return (
          <Dropdown
            placement="bottomRight"
            overlay={
              <Menu style={{ padding: "12px 0", borderRadius: 12 }}>
                <Menu.Item key="makeDefault">Make Default</Menu.Item>
                <Menu.Item key="deleteShipping">Delete Shipping</Menu.Item>
              </Menu>
            }
          >
            <TwfButton shape="circle" type-twf="icon">
              <Icon keyword="collapse" width={18} />
            </TwfButton>
          </Dropdown>
        );
      },
    },*/
  ];

  const tableChangeHandler = (
    pagination: TablePaginationConfig,
    filters: any,
    sort: any
  ) => {
    setTableOptions({
      pagination,
      sort: sort.field
        ? {
            [sort.field]:
              sort.order === "ascend" ? SortOrder.Asc : SortOrder.Desc,
          }
        : tableOptions.sort,
    });
  };

  return (
    <Container maxWidth="1000px">
      <span
        style={{
          width: "500px",
          fontSize: "25px",
          fontWeight: "700",
          marginBottom: "20%",
        }}
      >
        <BackButton /> Shipping Rates
      </span>
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data?.shippingStatePrice.items?.map((obj: any) => ({
          ...obj,
          key: obj.id,
        }))}
        loading={loading}
        pagination={{
          ...tableOptions.pagination,
          total: data?.shippingStatePrice?.totalItems,
          position: ["bottomCenter"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} Items`,
          showSizeChanger: true,
        }}
        onChange={tableChangeHandler}
        style={{ maxWidth: "max-content" }}
        scroll={{ x: "max-content" }}
      />
    </Container>
  );
};

export default ShippingMethodsTable;
