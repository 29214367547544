import { useLazyQuery } from "@apollo/client";
import {
  Col,
  Collapse,
  Divider,
  Dropdown,
  Grid,
  Menu,
  Row,
  Table,
  Tag,
} from "antd";
import Container from "component/container";
import { format } from "date-fns";
import {
  Order,
  useGetActiveAdministratorQuery,
  useGetAnnouncementsLazyQuery,
  useGetOrderSummaryLazyQuery,
} from "generated/graphql";
import { GET_ORDER_LIST } from "graphql/queries.graphql";
import React, { FC, useEffect } from "react";
import Icon from "../../assets/icons";
import TwfButton from "../../component/button";
import ShadowCard from "../../component/card";
import { H6, PageTitle, SubTitle } from "../../component/header";
import { MarginBox } from "../../component/margin";
import { ScrollBox } from "../../component/scroll";
import StatusCard from "./components/statusCard";
import { TwfTabItem, TwfTabs } from "./components/tabs";
import moment from "moment";

const { useBreakpoint } = Grid;

const { Panel } = Collapse;

interface PageProps {}

const ChannelAdminPage: FC<PageProps> = () => {
  const breakpoints = useBreakpoint();

  const [getTodayOrderSummaryQuery, { data: todayData }] =
    useGetOrderSummaryLazyQuery();
  const [getAnnualOrderSummaryQuery, { data: annualData }] =
    useGetOrderSummaryLazyQuery();
  const [getAnnualClubOrderSummaryQuery, { data: annualClubData }] =
    useGetOrderSummaryLazyQuery();

  const [getAnnouncementsQuery, { data: AnnouncementResults }] =
    useGetAnnouncementsLazyQuery();

  const getActiveAdministrator = useGetActiveAdministratorQuery();

  let fname;
  let lname;

  /*if(getActiveChannel){
    fname = getActiveChannel?.data?.activeChannel?.customFields?.firstName;
    lname = getActiveChannel?.data?.activeChannel?.customFields?.lastName;
  }*/

  if (getActiveAdministrator) {
    fname = getActiveAdministrator?.data?.activeAdministrator?.firstName;
    lname = getActiveAdministrator?.data?.activeAdministrator?.lastName;
  }

  useEffect(() => {
    getTodayOrderSummaryQuery({
      variables: {
        options: {
          filter: {
            createdAt: {
              between: {
                start: new Date(new Date().setHours(0, 0, 0, 0)),
                end: new Date(new Date().setDate(new Date().getDate() + 1)), //skip timezone
              },
            },
          },
        },
      },
    });
    getAnnualOrderSummaryQuery({
      variables: {
        options: {
          filter: {
            createdAt: {
              between: {
                start: new Date(new Date().setDate(new Date().getDate() - 365)),
                end: new Date(),
              },
            },
          },
        },
      },
    });
    getAnnualClubOrderSummaryQuery({
      variables: {
        options: {
          filter: {
            createdAt: {
              between: {
                start: new Date(new Date().setDate(new Date().getDate() - 365)),
                end: new Date(),
              },
            },
            orderClassification: { eq: 2 },
          },
        },
      },
    });
    getAnnouncementsQuery();
  }, [
    getTodayOrderSummaryQuery,
    getAnnualOrderSummaryQuery,
    getAnnualClubOrderSummaryQuery,
    getAnnouncementsQuery,
  ]);

  const getOrdersTotal = (orders: any[] | undefined) =>
    orders?.reduce((acc, item) => {
      acc = acc + item.total;
      return acc;
    }, 0) / 100 || 0;

  const announcementRawData = AnnouncementResults?.announcements.items;

  const announcementData = announcementRawData?.map((announcement, index) => {
    return {
      key: index,
      content: (
        <div
          style={{ marginTop: "1%", marginBottom: "1%", marginLeft: "1%" }}
          dangerouslySetInnerHTML={{ __html: announcement?.content }}
        />
      ),
      updatedAt: (
        <div>{new Date(announcement?.updatedAt).toLocaleDateString()}</div>
      ),
      name: (
        <div
          dangerouslySetInnerHTML={{
            __html:
              announcement?.name +
              " - " +
              new Date(announcement?.updatedAt).toLocaleDateString(),
          }}
        />
      ),
    };
  });

  const [getOrders, { data }] = useLazyQuery(GET_ORDER_LIST, {
    fetchPolicy: "network-only",
  });

  const queryVariables = {
    options: {
      filter: {
        archived: {
          eq: false,
        },
        totalQuantity: {
          gt: 0,
        },
      },
      skip: 0,
      take: 9,
      sort: {
        id: "DESC",
      },
    },
  };

  useEffect(() => {
    getOrders({
      variables: queryVariables,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrders]);

  const announceTable = {
    columns: [
      {
        title: "Details",
        dataIndex: "content",
      },
      {
        title: "Date",
        dataIndex: "updatedAt",
      },
    ],
    data: announcementData,
  };

  const formatDateTime = (date: string | Date = new Date()): string =>
    format(new Date(date), "L/d/Y h:mmaaa");

  const recentOrders = {
    columns: [
      {
        title: "ID",
        key: "id",
        render: (record: Order) => {
          return record?.code;
        },
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
        render: (status: any) => {
          switch (status) {
            case "ComplianceFailed":
              status = "OrderSucceed";
              return <Tag color="#039108">{status}</Tag>;
            case "ComplianceSuccess":
              status = "OrderSucceed";
              return <Tag color="#039108">{status}</Tag>;
            case "ArrangingPayment":
              return <Tag color="#0e84eb">{status}</Tag>;
            case "AddingItems":
              return <Tag color="#6e0eeb">{status}</Tag>;
            case "PaymentSettled":
              return <Tag color="#f56105">{status}</Tag>;
            case "Cancelled":
              return <Tag color="#eb190e">{status}</Tag>;
            default:
              return <Tag color="#FB8832">{status}</Tag>;
          }
        },
      },
      {
        title: "Order Placed At",
        dataIndex: "createdAt",
        key: "date",
        render: (createdAt: any) => formatDateTime(createdAt),
      },
      {
        title: "Amount",
        key: "amount",
        render: (record: any) => {
          if (record.state !== "Cancelled") {
            return (record.totalWithTax / 100).toFixed(2);
          } else {
            return (record.customFields.cancelledTotalWithTax / 100).toFixed(2);
          }
        },
      },
    ],
    data: [] as any,
  };

  if (data?.orders) {
    data.orders.items.forEach((order: any) => {
      recentOrders.data.push(order);
    });
  }

  return (
    <Container maxWidth="1500px">
      <PageTitle>
        Welcome Back {fname} {lname}
      </PageTitle>
      <MarginBox mt={8} mb={breakpoints.md ? 43 : 21}>
        <SubTitle>
          {todayData?.orders.totalItems || 0} orders today for a total of $
          {getOrdersTotal(todayData?.orders.items)}
        </SubTitle>
      </MarginBox>
      <Row gutter={breakpoints.md ? [30, 38] : [16, 15]}>
        <Col span={24} xxl={{ span: 6 }} xl={{ span: 12 }} sm={{ span: 12 }}>
          <StatusCard
            cardData={{
              title: getOrdersTotal(annualData?.orders.items),
              subTitle: "Annual Revenue",
              status: "increase",
              hide: true,
            }}
          />
        </Col>
        <Col span={24} xxl={{ span: 6 }} xl={{ span: 12 }} sm={{ span: 12 }}>
          <StatusCard
            cardData={{
              title: getOrdersTotal(annualClubData?.orders.items),
              subTitle: "Annual Club Revenue ",
              status: "increase",
              hide: true,
            }}
          />
        </Col>
        <Col span={24} xxl={{ span: 6 }} xl={{ span: 12 }} sm={{ span: 12 }}>
          <StatusCard
            cardData={{
              title: todayData?.orders.totalItems || 0,
              subTitle: "Today’s Order",
              status: "decrease",
              hide: false,
            }}
          />
        </Col>
        <Col span={24} xxl={{ span: 6 }} xl={{ span: 12 }} sm={{ span: 12 }}>
          <StatusCard
            cardData={{
              title: annualData?.orders.totalItems || 0,
              subTitle: "Annual Orders",
              status: "increase",
              hide: false,
            }}
          />
        </Col>
        <Col span={24} xxl={{ span: 12 }} xl={{ span: 24 }} sm={{ span: 24 }}>
          <TwfTabs defaultActiveKey="1">
            <TwfTabItem tab="Announcements" key="1">
              <ShadowCard>
                <MarginBox style={{ height: "400px", overflow: "scroll" }}>
                
                  <Collapse>
                    {announceTable?.data?.map((data, index) => {
                      return (
                        <Panel header={data.name} key={index}>
                          <div style={{ padding: "0px 30px" }}>
                            {data.content}
                          </div>
                        </Panel>
                      );
                    })}
                  </Collapse>
                </MarginBox>
              </ShadowCard>
            </TwfTabItem>
            <TwfTabItem tab="Recent Orders" key="2">
              <ShadowCard>
                <MarginBox mb={34}>
                  <Table
                    pagination={false}
                    columns={recentOrders.columns}
                    dataSource={recentOrders.data.slice(0, 10)}
                  />
                </MarginBox>
              </ShadowCard>
            </TwfTabItem>
            {/*
            <TwfTabItem tab="Analytics" key="3">
              <ShadowCard>
                <MarginBox mt={34} mb={25} pl={16} pr={32}>
                  <Row justify="space-between">
                    <H6>The number of visits and orders per month</H6>
                    <Space size={24}>
                      <Row align="middle" justify="space-between" gutter={8}>
                        <Col>
                          <Circle width={8} color="#6F52ED" />
                        </Col>
                        <Col>
                          <SubTitle>Visits</SubTitle>
                        </Col>
                      </Row>
                      <Row align="middle" justify="space-between" gutter={8}>
                        <Col>
                          <Circle width={8} color="#FF7A00" />
                        </Col>
                        <Col>
                          <SubTitle>Orders</SubTitle>
                        </Col>
                      </Row>
                    </Space>
                  </Row>
                </MarginBox>
                <MarginBox pb={34} pl={16} pr={16}>
                  <GroupedBar />
                </MarginBox>
              </ShadowCard>
            </TwfTabItem>
          */}
          </TwfTabs>
        </Col>
        {breakpoints.md && (
          <React.Fragment>
            <Col
              span={24}
              xxl={{ span: 6 }}
              xl={{ span: 12 }}
              sm={{ span: 24 }}
            >
              <ShadowCard>
                <MarginBox pl={20} pr={20} pt={25}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <H6>Most Popular Products</H6>
                    </Col>
                    <Col>
                      <Dropdown overlay={<Menu />}>
                        <TwfButton type="link">
                          <SubTitle>Sale #</SubTitle>
                          <Icon
                            keyword="chevron-down"
                            width={20}
                            color="#B0B9C7"
                          />
                        </TwfButton>
                      </Dropdown>
                    </Col>
                  </Row>
                </MarginBox>
                <Divider />
                <MarginBox pl={25} pr={25} pb={35}>
                  <ScrollBox
                    style={{
                      height: "max-content",
                      maxHeight: 512,
                    }}
                  >
                    {/* <MarginBox mb={19}>
                      <Row justify="space-between" gutter={24}>
                        <Col span={20}>
                          <Caption>2020 Dawna Rosé</Caption>
                        </Col>
                        <Col span={4}>
                          <Caption>15</Caption>
                        </Col>
                      </Row>
                    </MarginBox> */}
                  </ScrollBox>
                </MarginBox>
              </ShadowCard>
            </Col>
            <Col
              span={24}
              xxl={{ span: 6 }}
              xl={{ span: 12 }}
              sm={{ span: 24 }}
            >
              <ShadowCard>
                <MarginBox pl={20} pr={20} pt={25}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <H6>Sales Categories</H6>
                    </Col>
                  </Row>
                </MarginBox>
                <Divider />
                {/* <MarginBox pt={5} pb={35} pl={25} pr={25}>
                  <DoughnutChart />
                </MarginBox> */}
                {/* <MarginBox pb={15} pl={25} pr={25}>
                  <Row justify="space-between">
                    <Col>
                      <Row gutter={8} align="middle">
                        <Col>
                          <Circle color="#6F52ED" width={8} />
                        </Col>
                        <Col>
                          <SubTitle>Red Wine</SubTitle>
                        </Col>
                      </Row>
                    </Col>
                    <Col></Col>
                  </Row>
                </MarginBox> */}
              </ShadowCard>
            </Col>
          </React.Fragment>
        )}
      </Row>
    </Container>
  );
};

export default ChannelAdminPage;
