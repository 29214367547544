import { Card, Col, Grid, List, message, Popconfirm, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import client from "api/graphql/connect";
import Container from "component/container";
import { CREATE_ASSETS } from "graphql/mutations.graphql";
import { TableOptions } from "pages/orders/components/datatable";
import { createRef, FC, useEffect, useState } from "react";
import styled from "styled-components";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import { H2 } from "../../../component/header";
import { MarginBox } from "../../../component/margin";
import {
  Asset,
  // SortOrder,
  useDeleteAssetsMutation,
  useGetAssetListLazyQuery,
} from "../../../generated/graphql";
import AdvancedSearch from "../../orders/components/search";
import { AssetItem, PreviewItem } from "./style";
import { FileUploader } from "react-drag-drop-files";

const { useBreakpoint } = Grid;

interface PageProps {
  isModal: boolean;
  onAssetSelect: (item: Asset) => void;
}

const fileTypes = ["JPEG", "PNG", "GIF", "WEBP", "JPG", "TIFF", "AVIF"];

const AssetList: FC<PageProps> = ({ isModal, onAssetSelect }) => {
  const breakpoints = useBreakpoint();
  const hiddenFileInput = createRef<HTMLInputElement>();
  const [getAssetListQuery, { data, loading }] = useGetAssetListLazyQuery();
  const [deleteAssetsMutation, { data: deleteResult, loading: deleteLoading }] =
    useDeleteAssetsMutation({
      refetchQueries: ["GetAssetList"],
    });

  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<Asset>();
  const [tableOptions, setTableOptions] = useState<TableOptions>({
    pagination: { current: 1, pageSize: 15 },
    // sort: { id: SortOrder.Desc },
  });

  useEffect(() => {
    if (selectedAsset && isModal) onAssetSelect(selectedAsset);
  }, [selectedAsset, isModal, onAssetSelect]);

  useEffect(() => {
    if (deleteResult) {
      if (deleteResult.deleteAssets.result === "DELETED")
        message.success("Asset deleted");
      else message.error(deleteResult.deleteAssets.message);
    }
  }, [deleteResult]);

  useEffect(() => {
    getAssetListQuery({
      variables: {
        options: {
          skip:
            (tableOptions.pagination.pageSize || 10) *
            ((tableOptions.pagination.current || 1) - 1),
          take: tableOptions.pagination.pageSize,
          //sort: tableOptions.sort,
        },
      },
    });
  }, [getAssetListQuery, tableOptions]);

  const pageChangeHandler = (current?: number, pageSize?: number) => {
    setTableOptions({ pagination: { current, pageSize } });
  };

  const assetDeleteHandler = (id: string) => {
    deleteAssetsMutation({
      variables: { input: { assetIds: [id] } },
    });
  };

  const handleClick = () => {
    if (hiddenFileInput.current !== null) hiddenFileInput.current.click();
  };

  

  const fileChangeHandler = async (e: any) => {
    try {
      let filesSelected = e;
      if (e.target) {
        filesSelected = e.target.files;
      }
  
      setUploadLoading(true);
  
      const files: File[] = [];
  
      for (const file of filesSelected) {
        if (file.size > 10242880) {
          message.error("File size is too large");
          setUploadLoading(false);
          continue;
        }
  
        try {
          const resizedFile = await resizeImage(file);
          files.push(resizedFile);
        } catch (error) {
          console.log(error);
          message.error("Failed to resize image");
          setUploadLoading(false);
        }
      }
  
      client
        .mutate({
          mutation: CREATE_ASSETS,
          variables: {
            input: files.map((file: any) => ({ file })),
          },
          refetchQueries: ["GetAssetList"],
        })
        .then(({ data: { createAssets } }) => {
          console.log("assets LIST",createAssets)
          if (createAssets.length > 0) message.success("File uploaded");
          setUploadLoading(false);
        });
    } catch (error) {
      console.log(error);
      message.error("Failed to upload file");
      setUploadLoading(false);
    }
  };
  
  const resizeImage = (file: File) => {
    return new Promise<File>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx:any = canvas.getContext("2d");
  
          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 600;
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
  
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
  
          canvas.toBlob((blob:any) => {
            const resizedFile = new File([blob], file.name, { type: file.type });
            resolve(resizedFile);
          }, file.type);
        };
        img.onerror = () => {
          reject(new Error("Failed to load image"));
        };
        img.src = event.target.result;
      };
      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };
      reader.readAsDataURL(file);
    });
  };
  
  


  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const toggleAssetSelection = (item: any) => {
    if (selectedAsset && item.id === selectedAsset.id) {
      setSelectedAsset(undefined);
    } else {
      setSelectedAsset(item);
    }
  };

  const renderListItem = (item: any) => (
    <AssetItem className={item.id === selectedAsset?.id ? "selected" : ""}>
      <Card
        hoverable
        onClick={() => toggleAssetSelection(item)}
        cover={
          <img
            className="rect-img"
            alt=""
            src={`${item.preview}?preset=thumb`}
          />
        }
      >
        <Meta description={item.name} />
      </Card>
    </AssetItem>
  );

  return (
    <div>
      <Row gutter={[30, 20]} justify="space-between" wrap={false}>
        <Col style={{ width: "100%" }}>
          <Row align="middle" gutter={[30, 20]}>
            {breakpoints.md && (
              <Col>
                <H2>Assets</H2>
              </Col>
            )}
            <Col style={{ width: breakpoints.md ? "max-content" : "100%" }}>
              <AdvancedSearch setFilter={() => {}} />
            </Col>
          </Row>
        </Col>
        {breakpoints.md && (
          <Col style={{ marginRight: 50 }}>
            <input
              type="file"
              id="file-upload"
              multiple
              style={{ display: "none" }}
              ref={hiddenFileInput}
              onChange={fileChangeHandler}
              accept="image/*"
            />
            <label htmlFor="file-upload">
              <TwfButton
                color-twf="primary"
                type="primary"
                size-twf="lg"
                type-twf="wide"
                onClick={handleClick}
                loading={uploadLoading}
                icon={<Icon keyword="plus" width={16} color="#fff" />}
              >
                Upload Assets
              </TwfButton>
            </label>
          </Col>
        )}
      </Row>
      <Container>
        <MarginBox mt={32}>
          <Row gutter={[15, 15]} justify="space-between">
            <Col span={9} md={7} lg={13}>
              <ListWrapper>
                <List
                  grid={{
                    gutter: 16,
                    column: breakpoints.lg ? 5 : breakpoints.md ? 3 : 1,
                  }}
                  dataSource={data?.assets.items}
                  loading={loading}
                  pagination={{
                    onChange: pageChangeHandler,
                    pageSize: tableOptions.pagination.pageSize,
                    total: data?.assets.totalItems,
                  }}
                  renderItem={renderListItem}
                />
              </ListWrapper>
            </Col>
            <Col>
              <Card className="file-uploader-wrapper">
                <FileUploader
                  multiple={true}
                  handleChange={fileChangeHandler}
                  types={fileTypes}
                />
              </Card>

              <Card
                style={{
                  width: breakpoints.md ? "400px" : "max-content",
                  maxWidth: "400px",
                  marginTop: "5%",
                }}
              >
                {!selectedAsset ? (
                  "No Selection"
                ) : (
                  <PreviewItem>
                    <Row>
                      <img
                        style={{ width: "100%" }}
                        src={selectedAsset.preview}
                        alt={selectedAsset.name}
                      />
                    </Row>
                    <MarginBox mt={20} mb={20}>
                      <Row>{selectedAsset.name}</Row>
                      <Row>
                        Source size: {formatBytes(selectedAsset.fileSize)}
                      </Row>
                    </MarginBox>
                    <Row>
                      <TwfButton
                        type="link"
                        onClick={() => window.open(selectedAsset.preview)}
                      >
                        <Icon keyword="photo" color="#3C64B1" />
                        Preview
                      </TwfButton>
                      <Popconfirm
                        title="Are you sure to delete this asset?"
                        onConfirm={() => assetDeleteHandler(selectedAsset.id)}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                      >
                        <TwfButton
                          type="link"
                          style={{ fontWeight: 700, color: "#FF4C61" }}
                          icon={<Icon keyword="delete" color="#FF4C61" />}
                          loading={deleteLoading}
                        >
                          Delete
                        </TwfButton>
                      </Popconfirm>
                    </Row>
                  </PreviewItem>
                )}
              </Card>
            </Col>
          </Row>
        </MarginBox>
      </Container>
    </div>
  );
};

const ListWrapper = styled.div`
  .ant-list-pagination {
    text-align: left;
  }
`;

export default AssetList;
