import  { FC, useEffect, useState } from "react";
import { AutoComplete } from "antd";
import { useAutoCompleteInput } from "./useAutoComplete";
import { Suggestion } from "./types";
import { useDispatch } from 'react-redux';
import { setCustomerStreetLine1Action } from 'store/action/ui.action';
import { AppDispatch } from 'store';

const { Option } = AutoComplete;

interface Props {
  selectedData: (values: Suggestion) => void;
  customerStreetAddress1?: string;
  disabled?: boolean;
  onChangeValue?:(value:string)=>void
  customerAddress?:boolean
}

const SmartyAutoComplete: FC<Props> = ({
  selectedData,
  customerStreetAddress1,
  disabled,
  onChangeValue,
  customerAddress
}) => {
  const dispatch:AppDispatch = useDispatch();
  const { getSuggestions, suggestions } = useAutoCompleteInput();
  const [value, setValue] = useState<string | undefined>();
  const onSearch = async (searchText: string) => {
    if (!searchText) return;
    await getSuggestions(searchText);
  };

  const onChange = (streetAddress: string) => {
      setValue(streetAddress);
      if(onChangeValue){
        onChangeValue(streetAddress)
      }
      
      if(customerAddress){
          dispatch(setCustomerStreetLine1Action(streetAddress))
      }
  };

 

  // we set disabled to true when setting same as billing info
  useEffect(()=>{
    if(disabled || customerStreetAddress1){
      setValue(customerStreetAddress1)
    }
  },[disabled,customerStreetAddress1])

  

  const handleSelections = (suggestion: any) => {
    selectedData(suggestion);
  };

  if (!suggestions) {
    <>loading...</>;
  }
  return (
    <AutoComplete
      style={{ width: "100%" }}
      onSearch={onSearch}
      onChange={onChange}
      value={value}
      placeholder="street address"
      disabled={disabled}
    >
      {suggestions.map((suggestion, index: number) => (
        <Option
          key={`${index}_${suggestion.street_line}`}
          value={suggestion.street_line}
          label={suggestion.state}
          state={suggestion.state}
          address={suggestion}
          suggestions={suggestions}
          index={index}
        >
          <span onClick={() => handleSelections(suggestion)}>
            {`${suggestion.street_line} ${suggestion.city} ${suggestion.state} ${suggestion.zipcode}`}
          </span>
        </Option>
      ))}
    </AutoComplete>
  );
};

export default SmartyAutoComplete;
