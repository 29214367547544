import React, { FC, useState } from "react";
import { Col, Divider, Grid, Row, Space } from "antd";
import { TwfTabItem, TwfTabs } from "../../brands/components/tabs";
import ShadowCard from "../../../component/card";
import { H3, PageTitle } from "../../../component/header";
import Label from "./style/label";
import Value from "./style/value";
import Announcements from "./announcements";
import Orders from "./orders";
import CustomerDashboardMobileFooter from "./mobile-footer";

const { useBreakpoint } = Grid;

const ClientDashboardPage: FC = () => {
  const breakpoints = useBreakpoint();
  const [tab, setTab] = useState("orders");

  return (
    <Space
      direction="vertical"
      size={breakpoints.md ? 24 : 12}
      style={{ width: "100%" }}
    >
      <PageTitle>Welcome back Sharath</PageTitle>
      {breakpoints.lg ? (
        <Row gutter={32}>
          <Col span={24} lg={{ span: 18 }}>
            <TwfTabs defaultActiveKey="1">
              <TwfTabItem tab="Orders" key="1">
                <ShadowCard>
                  <Orders />
                </ShadowCard>
              </TwfTabItem>
              <TwfTabItem tab="Announcements" key="2">
                <ShadowCard>
                  <Announcements />
                </ShadowCard>
              </TwfTabItem>
            </TwfTabs>
          </Col>
          <Col span={6}>
            <ShadowCard style={{ padding: 24 }}>
              <H3>Sharath Chandra</H3>
              <Divider />
              <Space direction="vertical" size={12}>
                <div>
                  <Label>Username</Label>
                  <Value>sharathchandra</Value>
                </div>
                <div>
                  <Label>First Name</Label>
                  <Value>Sharath</Value>
                </div>
                <div>
                  <Label>Last Name</Label>
                  <Value>Chandra</Value>
                </div>
                <div>
                  <Label>Email</Label>
                  <Value>chandra@thewinefoundry.com</Value>
                </div>
                <div>
                  <Label>Signup Date</Label>
                  <Value>2021-05-26 17:04:43</Value>
                </div>
                <div>
                  <Label>Last Login</Label>
                  <Value>2021-06-26 12:00:02</Value>
                </div>
                <div>
                  <Label>Status</Label>
                  <Value>active</Value>
                </div>
              </Space>
            </ShadowCard>
          </Col>
        </Row>
      ) : (
        <ShadowCard style={{ marginBottom: 64 }}>
          {tab === "orders" ? <Orders /> : <Announcements />}
          <CustomerDashboardMobileFooter current={tab} setCurrent={setTab} />
        </ShadowCard>
      )}
    </Space>
  );
};

export default ClientDashboardPage;
