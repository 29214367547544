import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Col,
  Form,
  Grid,
  Input,
  InputNumber,
  Modal,
  Row,
  Tooltip,
  notification,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import Container from "component/container";
import {
  GetProductVariantDocument,
  GetProductVariantQuery,
  useAdjustStockAllocatedMutation,
  useGetActiveAdministratorQuery,
} from "generated/graphql";
import moment from "moment";
import React, { FC, useEffect, useReducer, useState } from "react";
import { TwfCard } from "../../../component/card";
import { BlockTitle } from "../../../component/header";
import TwfButton from "component/button";

const { Item } = Form;
const { useBreakpoint } = Grid;

interface ViewProps {
  variant: GetProductVariantQuery;
  onChange: (state: any) => void;
}

const ProductSingleSkuPricing: FC<ViewProps> = ({
  variant: { productVariant },
  onChange,
}) => {
  const { data: getAdminResult } = useGetActiveAdministratorQuery();
  const [form] = useForm();
  const breakpoints = useBreakpoint();
  const [isOpen, setIsOpen] = useState(false);
  const [adjustStockAllocated, { loading, client }] =
    useAdjustStockAllocatedMutation();

  let disabledSKU = true;

  if (typeof window !== "undefined") {
    if (localStorage.getItem("used-channel") === "__default_channel__") {
      disabledSKU = false;
    }
  }

  const initialState = {
    customFields: {},
  };

  const reducer = (state: any, action: any) => {
    switch (action.name) {
      case "sku":
        return {
          ...state,
          sku: action.value,
        };
      case "stockOnHand":
        return {
          ...state,
          [action.name]: action.value,
        };
      case "price":
        return {
          ...state,
          [action.name]: Math.round(action.value * 100),
        };
      case "retailPrice":
        return {
          ...state,
          customFields: {
            ...state.customFields,
            retailPrice: Math.round(action.value * 100),
          },
        };
      default:
        return {
          ...state,
          customFields: { ...state.customFields, [action.name]: action.value },
        };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    onChange(state);
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    form.setFieldsValue({
      ...productVariant,
      ...productVariant?.customFields,
      startDate: productVariant?.customFields?.startDate
        ? moment(productVariant?.customFields?.startDate)
        : "",
      endDate: productVariant?.customFields?.endDate
        ? moment(productVariant?.customFields?.endDate)
        : "",
      price: ((productVariant?.price || 0) / 100).toFixed(2),
      retailPrice: (
        (productVariant?.customFields?.retailPrice || 0) / 100
      ).toFixed(2),
    });
  }, [form, productVariant]);

  const priceFormatter = (value: string) => {
    if (!value) return value;
    let [int, decimal] = value.toString().split(".");
    if (decimal) {
      if (decimal.length === 1) {
        return `${int}.${decimal}`;
      }
      return Number(value).toFixed(2);
    }
    return int;
  };

  return (
    <Container maxWidth="1000px">
      <TwfCard style={{ padding: breakpoints.md ? "49px 29px" : "20px" }}>
        <Form form={form} layout="vertical">
          <Row gutter={[30, 30]}>
            <Col lg={{ span: 12 }} span={24}>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Item name="sku" label="SKU">
                    <Input
                      placeholder="Code"
                      readOnly={disabledSKU}
                      disabled={disabledSKU}
                      style={{ boxShadow: "none" }}
                      onChange={(e: any) =>
                        dispatch({ name: "sku", value: e.target.value })
                      }
                    />
                  </Item>
                  {/*<Explain>
                    SKU is Editable Only During New Product Creation
                    </Explain>*/}
                </Col>

                <Col sm={12} span={24}>
                  <BlockTitle>
                    Purchase Price &nbsp;
                    <Tooltip title="Purchase Price is the price that will be charged for the product. This could be the regular retail price or a sale price.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </BlockTitle>
                  <Item name="price">
                    <InputNumber
                      formatter={(v) => priceFormatter(v ?? "")}
                      size="large"
                      style={{ borderRadius: 12, width: "100%" }}
                      placeholder="$0"
                      onChange={(value: string) => {
                        const price = Number(Number(value).toFixed(2));
                        dispatch({ name: "price", value: price });
                      }}
                    />
                  </Item>
                </Col>
                <Col sm={12} span={24}>
                  <BlockTitle>
                    Strikethrough Price&nbsp;{" "}
                    <Tooltip title="When you enter a Sale price, enter the original retail price in this field.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </BlockTitle>
                  <Item name="retailPrice">
                    <InputNumber
                      formatter={(v) => priceFormatter(v ?? "")}
                      parser={(value) =>
                        (value ?? "").replace(/\$\s?|(,*)/g, "")
                      }
                      size="large"
                      style={{ borderRadius: 12, width: "100%" }}
                      placeholder="$0"
                      onChange={(value: string) => {
                        const price = Number(Number(value).toFixed(2));
                        dispatch({ name: "retailPrice", value: price });
                        //dispatch({ name: "preferredPrice", value: productVariant?.price });
                      }}
                    />
                  </Item>
                </Col>

                <Col sm={12} span={24}>
                  <BlockTitle>Initial Stock Available</BlockTitle>
                  <Item name="stockOnHand">
                    <InputNumber
                      formatter={(value) => parseInt(value ?? "").toString()}
                      size="large"
                      style={{ borderRadius: 12, width: "100%" }}
                      placeholder="$0"
                      onChange={(value: string) =>
                        dispatch({ name: "stockOnHand", value })
                      }
                    />
                  </Item>
                </Col>
                <Col sm={12} span={24}>
                  <BlockTitle>Stock Allocated/Sold</BlockTitle>
                  <Item name="stockAllocated">
                    <InputNumber
                      size="large"
                      style={{ borderRadius: 12, width: "100%" }}
                      disabled={true}
                    />
                  </Item>
                </Col>

                {/* <Col>
                  <BlockTitle>Sale Channel</BlockTitle>
                  <Item name="saleChannel">
                    <Radio.Group
                      value={state.customFields.saleChannel}
                      onChange={(e: any) =>
                        dispatch({
                          name: "saleChannel",
                          value: e.target.value,
                        })
                      }
                    >
                      <Radio value={1}>Web</Radio>
                      <Radio value={2}>Club</Radio>
                      <Radio value={3}>Trade</Radio>
                    </Radio.Group>
                  </Item>
                    </Col> */}
                {/* <Col>
                  <BlockTitle>Wine Category</BlockTitle>
                  <Item name="wineCategory">
                    <Radio.Group
                      value={state.customFields.wineCategory}
                      options={wineCategories}
                      onChange={(e: any) =>
                        dispatch({
                          name: "wineCategory",
                          value: e.target.value,
                        })
                      }
                    />
                  </Item>
                    </Col> */}
                {/*
                <Col>
                  <BlockTitle>UPC Code</BlockTitle>
                  <Item name="upcCode">
                    <Input
                      placeholder="Code"
                      onChange={(e: any) =>
                        dispatch({ name: "upcCode", value: e.target.value })
                      }
                    />
                  </Item>
                  <Explain>
                    Add barcode digits to enable scanning for this item.
                  </Explain>
                </Col>
                  */}
              </Row>
            </Col>
            <Col lg={{ span: 12 }} span={24}>
              <Row gutter={[12, 12]}>
                {/* <Col span={24}>
                  <Item name="unit" label="Unit">
                    <Input
                      placeholder="Unit"
                      onChange={(e: any) =>
                        dispatch({ name: "unit", value: e.target.value })
                      }
                    />
                  </Item>
                    </Col> */}
                {/* <Col span={12}>
                  <Item name="weight" label="Weight">
                    <Input
                      placeholder="0 lbs"
                      onChange={(e: any) =>
                        dispatch({ name: "weight", value: e.target.value })
                      }
                    />
                  </Item>
                </Col> */}
                {/* <Row gutter={12}>
                <Col span={12}>
                  <Item name="preferredPrice" label="Preferred Price">
                    <InputNumber
                      size="large"
                      style={{ borderRadius: 12, width: "100%" }}
                      placeholder="$0"
                      onChange={(value: string) =>
                        dispatch({ name: "preferredPrice", value })
                      }
                    />
                  </Item>
                </Col>
              </Row> */}
                {/* <Col span={24}>
                  <BlockTitle>Exemptions</BlockTitle>
                  <Item name="exemptions">
                    <Checkbox.Group
                      value={state.customFields.exemptions}
                      onChange={(values: any) =>
                        dispatch({
                          name: "exemptions",
                          value: JSON.stringify(values),
                        })
                      }
                    >
                      <Checkbox value={1}>Web</Checkbox>
                      <Checkbox value={2}>Club</Checkbox>
                      <Checkbox value={3}>Trade</Checkbox>
                    </Checkbox.Group>
                  </Item>
                    </Col> */}

                {/* <Col span={24} style={{ marginTop: "11px" }}>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Item name="startDate" label="Start Date">
                        <DatePicker
                          onChange={(e: any, value: any) =>
                            dispatch({
                              name: "startDate",
                              value: new Date(value),
                            })
                          }
                        />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item name="endDate" label="End Date">
                        <DatePicker
                          onChange={(e: any, value: any) =>
                            dispatch({
                              name: "endDate",
                              value: new Date(value),
                            })
                          }
                        />
                      </Item>
                    </Col>
                  </Row>
                </Col>*/}
              </Row>
            </Col>
          </Row>
        </Form>
        
        {getAdminResult?.activeAdministrator?.user?.roles?.find(
          (r) => r.code === "__super_admin_role__"
        ) && (
          <TwfButton
            onClick={() => setIsOpen(true)}
            type="primary"
            size-twf="md"
            style={{
              marginTop: 20,
            }}
          >
            Adjust Stock Allocated/Sold
          </TwfButton>
        )}

        <Modal
          open={isOpen}
          onCancel={() => setIsOpen(false)}
          footer={null}
          centered
        >
          <Form
            layout="vertical"
            onFinish={(values) => {
              if (productVariant?.id) {
                adjustStockAllocated({
                  variables: {
                    input: {
                      productVariantId: productVariant?.id,
                      stockAllocated: values.stockAllocated,
                    },
                  },

                  onCompleted(data) {
                    if (data.adjustStockAllocated) {
                      client.refetchQueries({
                        include: [GetProductVariantDocument],
                      });
                      setIsOpen(false);
                    }
                  },
                  onError(error) {
                    notification.error({
                      message: error.message,
                    });
                  },
                });
              }
            }}
          >
            <Item
              name="stockAllocated"
              label="Stock Allocated/Sold"
              rules={[
                { required: true, message: "Stock Allocated/Sold is required" },
              ]}
            
              initialValue={productVariant?.stockAllocated || 0}
            >
              <InputNumber
                size="large"
                style={{ borderRadius: 12, width: "100%" }}
              />
            </Item>
            <TwfButton
              type="primary"
              size-twf="md"
              loading={loading}
              htmlType="submit"
            >
              Save
            </TwfButton>
          </Form>
        </Modal>
      </TwfCard>
    </Container>
  );
};

export default ProductSingleSkuPricing;
