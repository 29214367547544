import { Col, Divider, Dropdown, Grid, Menu, message, Row } from "antd";
import PricingTable from "component/pricing-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Icon from "../../../assets/icons";
import TwfButton from "../../../component/button";
import { MarginBox } from "../../../component/margin";
import TwfModal from "../../../component/modal";
import ModalTitle from "../../../component/modal/title";
import TableItem from "../../../component/table/item";
import TableTitle, { TableBigTitle } from "../../../component/table/title";
import {
  ComplianceDataInput,
  Maybe,
  Order,
  useComplianceDataForOrderQuery,
  useGetOrderLazyQuery,
  useGetOrderQuery,
  usePickUpLabelsQuery,
  usePushOrderToShipComplianceMutation,
  //useRefundOnlyOrderMutation,
  useReCheckComplianceAdminMutation,
  useRefundOrderMutation,
  useTransitionOrderToComplianceFailedMutation,
  useTransitionOrderToComplianceSuccessMutation,
  useTransitionOrderToStateMutation,
} from "../../../generated/graphql";
import { phoneNumberFormatter } from "../../../utils/phone-formatter";
import {
  orderClassifications,
  paymentTypes,
  sources,
} from "./../../../enums/enums";
import ComplianceView from "./compliance";

const { useBreakpoint } = Grid;

interface ViewProps {
  orderId: string;
  selectedId?: any;
  viewVisible?: boolean;
  refetchOrder?: any;
  setViewVisible: (value: boolean) => void;
  voidHandler: (id: any, code: string) => void;
}

const OrderView: React.FC<ViewProps> = ({
  orderId,
  viewVisible,
  refetchOrder,
  setViewVisible,
  voidHandler,
}) => {
  const breakpoints = useBreakpoint();
  const [complianceVisible, setComplianceVisible] = useState<boolean>(false);
  const [cust, setCust] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [getOrderQuery] = useGetOrderLazyQuery({
    variables: {
      id: orderId,
    },
  });

  let isNotSuperAdmin = true;

  if (typeof window !== "undefined") {
    if (localStorage.getItem("used-channel") === "__default_channel__") {
      isNotSuperAdmin = false;
    }
  }

  const { data } = useGetOrderQuery({
    variables: {
      id: orderId,
    },
  });

  const order = data?.order || ({} as Order);

  const [pickupLocations, setPickupLocations] = useState<any[]>([]);

  const { data: pickupLabels } = usePickUpLabelsQuery();

  useEffect(() => {
    if (pickupLabels?.pickupLabels) {
      setPickupLocations(pickupLabels.pickupLabels);
    }
  }, [pickupLabels]);

  const orderCopy = {
    ...order,
    orderClassificationLabel: orderClassifications.find(
      (s) => s.value === (order?.customFields?.orderClassification as any)
    )?.label,
    pickupLocationLabel: pickupLocations.find(
      (s) => s.value === (order?.customFields?.pickupLocation as any)
    )?.label,
    shippingMethodLabel: (order?.customFields?.csvShippingMethod ??
    order?.shippingLines?.length > 0
      ? order?.shippingLines[0]?.shippingMethod?.name
      : "") as any,
  };

  const { data: complianceDataForOrder } = useComplianceDataForOrderQuery({
    variables: {
      code: order?.code,
    },
    // eslint-disable-next-line eqeqeq
    skip: order?.code == undefined,
    fetchPolicy: "network-only",
  });

  const [pushOrderToCompliance] = usePushOrderToShipComplianceMutation();

  const [reCheckComplianceMutation] = useReCheckComplianceAdminMutation();

  const [transitionOrderToComplianceFailedMutation] =
    useTransitionOrderToComplianceFailedMutation();

  const [transitionOrderToComplianceSuccessMutation] =
    useTransitionOrderToComplianceSuccessMutation();

  const [refundOrderMutation] = useRefundOrderMutation();

  //const [refundOnlyOrderMutation] = useRefundOnlyOrderMutation();

  const [transitionOrderToStateMutation] = useTransitionOrderToStateMutation();

  const getEnumLabel = (list: any[], value?: Maybe<number>): string => {
    return list.find((i) => i.value === value)?.label;
  };

  const orderLines = [] as any;
  const orderDiscounts = [] as any;

  if (order.customFields?.cancelledLines) {
    if (order.customFields.cancelledLines.length !== 0) {
      order.customFields.cancelledLines.forEach((line, _index) => {
        orderLines.push(JSON.parse(line));
      });
    }
  }

  if (order.customFields?.cancelledDiscounts) {
    if (order.customFields.cancelledDiscounts.length !== 0) {
      order.customFields.cancelledDiscounts.forEach((disc, _index) => {
        orderDiscounts.push(JSON.parse(disc));
      });
    }
  }

  let promoCodes = "";

  if (order.discounts && order.discounts.length !== 0) {
    console.log(order);
    const codes = order.discounts.map((discount) => discount.description);
    promoCodes = codes.join(", ");
  }

  let totalCost = 0;
  if (order.lines && order.lines.length !== 0) {
    const orderItems = order.lines.map((line) => line.items);
    const finalOrderItems = orderItems.flat(1);
    const costs = finalOrderItems.map((item) => item.unitPrice);
    totalCost = costs.reduce((a, b) => a + b, 0);
  }

  const recheckcompliance = () => {
    setLoading(true);
    if (complianceDataForOrder) {
      if (
        complianceDataForOrder?.complianceDataForOrder?.__typename ===
        "OrderComplianceFetchError"
      ) {
        message.error(complianceDataForOrder?.complianceDataForOrder.message);
        setLoading(false);
      }
      if (
        complianceDataForOrder?.complianceDataForOrder?.__typename ===
        "SalesOrderResult"
      ) {
        let datafororder = JSON.parse(
          JSON.stringify(complianceDataForOrder.complianceDataForOrder)
        );
        delete datafororder["__typename"];
        delete datafororder["SalesOrder"]["__typename"];
        let complianceDataInput: ComplianceDataInput = datafororder;
        runComplianceCheck(complianceDataInput)
          .then((data: any) => {
            if (data === "Failed") {
              message.error("Ship Compliance Failed");
            }
            if (data === "Success") {
              message.success("Ship Compliance Passed");
            }
            setLoading(false);
          })
          .catch((error: any) => {
            message.error(error.message);
            setLoading(false);
          });
      }
    }
  };

  const transitionToComplianceSuccess = (id: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      transitionOrderToComplianceSuccessMutation({
        variables: {
          id: id,
        },
      })
        .then(({ data }) => {
          if (data?.transitionOrderToComplianceSuccess) {
            if (
              data?.transitionOrderToComplianceSuccess?.__typename === "Order"
            ) {
              resolve(id);
            } else if (
              data?.transitionOrderToComplianceSuccess.__typename ===
              "OrderStateTransitionError"
            ) {
              reject(data.transitionOrderToComplianceSuccess.message);
            }
          }
        })
        .catch((error: any) => {
          reject(error.message);
        });
    });
  };

  const transitionToComplianceFailed = (id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      transitionOrderToComplianceFailedMutation({
        variables: {
          id: id,
        },
      })
        .then(({ data }) => {
          if (data?.transitionOrderToComplianceFailed) {
            if (
              data?.transitionOrderToComplianceFailed?.__typename === "Order"
            ) {
              resolve(id);
            } else if (
              data?.transitionOrderToComplianceFailed.__typename ===
              "OrderStateTransitionError"
            ) {
              reject(data.transitionOrderToComplianceFailed.message);
            }
          }
        })
        .catch((error: any) => {
          reject(error.message);
        });
    });
  };

  const runComplianceCheck = (
    datafororder: ComplianceDataInput
  ): Promise<boolean | string> => {
    return new Promise((resolve, reject) => {
      reCheckComplianceMutation({
        variables: { input: datafororder },
      }).then(({ data }) => {
        if (data) {
          if (
            data.reCheckCompliance?.__typename === "ComplianceQuoteCheckError"
          ) {
            if (
              order?.state !== "ComplianceFailed" &&
              order?.state !== "AddingItems"
            ) {
              transitionToComplianceFailed(order?.id!)
                .then(() => {
                  resolve("Failed");
                })
                .catch((error: any) => {
                  reject(error);
                });
            } else {
              resolve("Failed");
            }
          } else {
            if (
              order?.state !== "ComplianceSuccess" &&
              order?.state !== "AddingItems"
            ) {
              transitionToComplianceSuccess(order?.id)
                .then(() => {
                  resolve("Success");
                })
                .catch((error: any) => {
                  reject(error);
                });
            } else {
              resolve("Success");
            }
          }
        }
      });
    });
  };

  const cancelOrder = () => {
    setLoading(true);
    let lines = [] as any;
    if (order.lines.length !== 0) {
      order.lines.forEach((data) => {
        // eslint-disable-next-line no-lone-blocks
        {
          lines.push({
            orderLineId: data.id,
            quantity: data.quantity,
          });
        }
      });
    }

    let pid;

    if (order.payments && order.payments.length !== 0) {
      pid = order.payments[0].id;
    }

    if (pid && lines.length !== 0) {
      refundOrderMutation({
        variables: {
          input: {
            adjustment: 0,
            shipping: order.shipping,
            paymentId: pid,
            lines: lines,
          },
        },
      }).then((data) => {
        if (data.data?.refundOrder.__typename === "Refund") {
          voidHandler(orderId, order?.code);
          setLoading(false);
        }
        if (data.data?.refundOrder.__typename === "AlreadyRefundedError") {
          voidHandler(order.id, order?.code);
          setLoading(false);
        }
        if (
          data.data?.refundOrder.__typename === "MultipleOrderError" ||
          data.data?.refundOrder.__typename === "NothingToRefundError" ||
          data.data?.refundOrder.__typename === "OrderStateTransitionError" ||
          data.data?.refundOrder.__typename === "PaymentOrderMismatchError" ||
          data.data?.refundOrder.__typename === "QuantityTooGreatError" ||
          data.data?.refundOrder.__typename === "RefundOrderStateError" ||
          data.data?.refundOrder.__typename === "RefundStateTransitionError"
        ) {
          message.error(data.data?.refundOrder?.message);
          setLoading(false);
        }
      });
    } else {
      voidHandler(orderId, order?.code);
      setLoading(false);
    }
  };

  const transitionTo = async (state: string) => {
    setLoading(true);

    try {
      if (state === "Cancelled") {
        await voidHandler(orderId, order?.code);
        message.success("Successfully transitioned to " + state);
      } else {
        const { data } = await transitionOrderToStateMutation({
          variables: {
            id: orderId,
            state,
          },
          refetchQueries: refetchOrder,
        });

        if (data?.transitionOrderToState) {
          if (
            data.transitionOrderToState.__typename ===
            "OrderStateTransitionError"
          ) {
            message.error(data.transitionOrderToState.message);
          } else if (data.transitionOrderToState.__typename === "Order") {
            message.success("Successfully transitioned to " + state);
          }
        } else {
          message.error("An error occurred during the transition.");
        }
      }
    } catch (error) {
      message.error("An error occurred during the transition.");
    }

    setLoading(false);
  };

  const menuItems = [
    {
      key: "refund",
      label: "Refund and Cancel",
      onClick: cancelOrder,
      disabled: order.state === "Cancelled",
    } as any,
  ];

  if (order.nextStates && order.nextStates.length !== 0) {
    order.nextStates.forEach((state, _) => {
      if (
        state !== "ArrangingPayment" &&
        state !== "Cancelled" &&
        state !== "Modifying" &&
        state !== "PartiallyDelivered" &&
        state !== "PartiallyShipped" &&
        state !== "ArrangingAdditionalPayment" &&
        state !== "AddingItems" &&
        state !== "Cancelled" &&
        state !== "ComplianceFailed"
      ) {
        let label = "";
        switch (state) {
          case "PaymentSettled":
            label = "Charge Card";
            break;
          case "Cancelled":
            label = "Cancel Check/Account";
            break;
          case "Shipped":
            label = "Shipped";
            break;
          case "Delivered":
            label = "Delivered";
            break;
          default:
            label = "Order To " + state;
            break;
        }
        menuItems.push({
          key: state,
          label: label,
          onClick: () => {
            transitionTo(state);
          },
          disabled: order.state === "Cancelled",
        });
      }
    });
  }

  const pushToCompliance = () => {
    setLoading(true);
    const code = order.code;
    pushOrderToCompliance({
      variables: {
        code: code,
      },
    })
      .then((data) => {
        if (data.data?.pushOrderToShipCompliance) {
          message.success("Successfully pushed to compliance");
          setLoading(false);
        }
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  };

  menuItems.push({
    key: "pushCompliance",
    label: "Commit To Ship Compliance",
    onClick: () => {
      pushToCompliance();
    },
    disabled:
      order.state === "AddingItems" ||
      order.state === "ArrangingPayment" ||
      order.state === "Shipped" ||
      order.state === "Cancelled" ||
      order.state === "Delivered" ||
      order.customFields?.orderClassification === 4,
  });

  menuItems.push({
    key: "recheck",
    label: "Check Compliance",
    onClick: () => {
      recheckcompliance();
    },
    disabled:
      order.customFields?.complianceStatus === 1 ||
      order.state === "Cancelled" ||
      order.state === "AddingItems" ||
      order.state === "ArrangingPayment" ||
      order.customFields?.orderClassification === 4,
  });

  /*const refundOnlyOrder = () => {
    refundOnlyOrderMutation({
      variables: {
        id: orderId,
      },
      refetchQueries: refetchOrder,
    })
      .then((data) => {
        if (data?.data?.refundOnlyOrder) {
          message.success("Successfully refunded the order");
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  menuItems.push({
    key: "refund",
    label: "Refund Order",
    onClick: () => {
      refundOnlyOrder();
    },
    disabled: order.state !== "Cancelled" || order.refundStatus,
  });*/

  const viewCompliance = () => {
    onViewComplianceHandler(orderId);
  };

  const onViewComplianceHandler = (id: any) => {
    getOrderQuery({
      variables: {
        id,
      },
      fetchPolicy: "network-only",
    })
      .then((data) => {
        if (data?.data?.order) {
          setCust(data?.data?.order?.customFields);
          setComplianceVisible(true);
        } else {
          message.error("The order seems not available in admin panel");
        }
      })
      .catch((error) => {
        message.error(error);
      });
  };

  const removeZero = (line: any) => {
    return line.quantity > 0;
  };

  const overAdminDrop = () => (
    <Menu
      items={menuItems}
      //disabled={order.state === "Cancelled"}
      style={{ borderRadius: "12px", border: "1px solid #E5E5E5" }}
    />
  );

  if (!order) {
    return <>loading....</>;
  }

  return (
    <React.Fragment>
      <TwfModal
        visible={viewVisible}
        onCancel={() => {
          setViewVisible(false);
        }}
        centered
        width={746}
        footer={false}
        closeIcon={<Icon keyword="close" width={24} color="#0A1629" />}
      >
        <ModalTitle>
          <Row gutter={[21, 21]} align="middle" justify="space-between">
            <Col span={24} xs={12}>
              <TwfButton
                onClick={viewCompliance}
                type="primary"
                color-twf="primary"
                size-twf="md"
                hidden={order?.customFields?.byPassed}
              >
                View Compliance
              </TwfButton>
            </Col>
          </Row>

          {breakpoints.md && (
            <Dropdown overlay={overAdminDrop}>
              <TwfButton
                loading={loading}
                type="primary"
                color-twf="secondary"
                size-twf="md"
              >
                Admin Options
                <Icon keyword="chevron-down" color="#fff" width={24} />
              </TwfButton>
            </Dropdown>
          )}
        </ModalTitle>
        <MarginBox mb={24}>
          {/* Fill up with something */}
          <Row gutter={6}>
            <Col span={6}>
              <TableTitle></TableTitle>
            </Col>
            <Col span={6}>
              <TableTitle></TableTitle>
            </Col>
            <Col span={6}>
              <TableTitle></TableTitle>
            </Col>
            <Col span={6}>
              <TableTitle></TableTitle>
            </Col>
          </Row>

          <Row gutter={6}>
            <Col span={6}>
              <TableTitle>Product</TableTitle>
            </Col>
            <Col span={6}>
              <TableTitle>Price</TableTitle>
            </Col>
            <Col span={6}>
              <TableTitle>Qty</TableTitle>
            </Col>
            <Col span={6}>
              <TableTitle>Total</TableTitle>
            </Col>
          </Row>

          {order.state !== "Cancelled" &&
            order?.lines?.filter(removeZero).map((obj: any) => (
              <Row gutter={12} key={obj.id}>
                <Col span={12} sm={6}>
                  <TableItem>
                    {obj.productVariant?.product?.name}{" "}
                    {obj.productVariant?.name}
                    {" - "}
                    {obj.productVariant?.sku}
                  </TableItem>
                </Col>
                <Col span={12} sm={6}>
                  <TableItem>${(obj.unitPrice / 100).toFixed(2)}</TableItem>
                </Col>
                <Col span={12} sm={6}>
                  <TableItem>{obj.quantity}</TableItem>
                </Col>
                <Col span={12} sm={6}>
                  <TableItem>${(obj.linePrice / 100).toFixed(2)}</TableItem>
                </Col>
              </Row>
            ))}

          {order.state === "Cancelled" &&
            orderLines?.map((obj: any) => (
              <Row gutter={12} key={obj.id}>
                <Col span={6}>
                  <TableItem>
                    {obj.productVariant?.product?.name}{" "}
                    {obj.productVariant?.name}
                    {" - "}
                    {obj.productVariant?.sku}
                  </TableItem>
                </Col>
                <Col span={6}>
                  <TableItem>${(obj.unitPrice / 100).toFixed(2)}</TableItem>
                </Col>
                <Col span={6}>
                  <TableItem>{obj.quantity}</TableItem>
                </Col>
                <Col span={6}>
                  <TableItem>${(obj.linePrice / 100).toFixed(2)}</TableItem>
                </Col>
              </Row>
            ))}
        </MarginBox>
        <MarginBox mb={15}>
          <Row gutter={[6, 6]}>
            <Col md={{ span: 6 }} span={12}>
              <TableTitle>Order ID</TableTitle>
              <TableItem>{order.code}</TableItem>
            </Col>
            <Col md={{ span: 6 }} span={12}>
              <TableTitle>Customer</TableTitle>
              <TableItem>
                {order.customer?.firstName} {order.customer?.lastName}
              </TableItem>
            </Col>
            <Col md={{ span: 6 }} span={12}>
              <TableTitle>Source</TableTitle>
              <TableItem>
                {getEnumLabel(sources, order.customFields?.source)}
              </TableItem>
            </Col>
            <Col md={{ span: 6 }} span={12}>
              <TableTitle>Order Classification</TableTitle>
              <TableItem>
                {getEnumLabel(
                  orderClassifications,
                  order.customFields?.orderClassification
                )}
              </TableItem>
            </Col>
            <Col md={{ span: 6 }} span={12}>
              <TableTitle>Payment Type</TableTitle>
              <TableItem>
                {order.customFields?.paymentType
                  ? getEnumLabel(paymentTypes, order.customFields?.paymentType)
                  : order.payments?.at(0)?.method === "stripe"
                  ? "Credit Card"
                  : order.payments?.at(0)?.method}
                {order.payments?.at(0)?.metadata?.public?.creditCardNumber
                  ? " - " +
                    order.payments?.at(0)?.metadata?.public?.creditCardNumber +
                    " "
                  : ""}
              </TableItem>
            </Col>

            {!isNotSuperAdmin && order.payments?.at(0)?.method === "stripe" && (
              <Col md={{ span: 6 }} span={12}>
                <TableTitle>Transaction ID</TableTitle>
                <TableItem>{order.payments?.at(0)?.transactionId}</TableItem>
              </Col>
            )}

            {!isNotSuperAdmin && (
              <>
                <Col md={{ span: 6 }} span={12}>
                  <TableTitle>Store Name</TableTitle>
                  <TableItem>
                    {order.channel?.customFields?.brandName}
                  </TableItem>
                </Col>
                {order.customFields?.isAdminPlaced != null && (
                  <Col md={{ span: 6 }} span={12}>
                    <TableTitle>Placed From:</TableTitle>
                    <TableItem>
                      {order.customFields?.isAdminPlaced === true &&
                        "Admin Side"}
                      {order.customFields?.isAdminPlaced === false &&
                        "Customer Side"}
                    </TableItem>
                  </Col>
                )}
              </>
            )}

            {/* <Col md={{ span: 6 }} span={12}>
              <TableTitle>Future Ship Date</TableTitle>
              <TableItem>
                {order.customFields?.futureShipDate
                  ? new Date(
                      order.customFields?.futureShipDate
                    ).toLocaleString()
                  : ""}
              </TableItem>
            </Col> */}

            <Col md={{ span: 6 }} span={12}>
              <TableTitle>Fulfillment Status</TableTitle>
              <TableItem>
                {order.customFields?.shipmentStatus
                  ? order.customFields.shipmentStatus
                  : order.state === "ComplianceSuccess"
                  ? "Sent To Fulfillment"
                  : "Not Processed"}
              </TableItem>
            </Col>

            {!isNotSuperAdmin && order.customFields?.orderCreator && (
              <Col md={{ span: 6 }} span={12}>
                <TableTitle>Placed By:</TableTitle>
                <TableItem>{order.customFields?.orderCreator}</TableItem>
              </Col>
            )}

            <Col md={{ span: 6 }} span={12}>
              <TableTitle>Promo Codes</TableTitle>
              <TableItem>
                {
                  //promoCodes.length!==0 ? promoCodes : "None"
                  promoCodes
                }
              </TableItem>
            </Col>

            <Col md={{ span: 6 }} span={12}>
              <TableTitle>Tracking Numbers</TableTitle>
              <TableItem>
                {order?.customFields?.trackingNumbers &&
                  order.customFields.trackingNumbers.length !== 0 &&
                  order.customFields.trackingNumbers.map(
                    (trackingNumber: string, index: number) => {
                      const trackingURL =
                        "https://www.fedex.com/fedextrack/?trknbr=" +
                        trackingNumber;
                      return (
                        <div key={index}>
                          <a
                            href={trackingURL}
                            target="_blank"
                            key={index}
                            rel="noreferrer"
                          >
                            {trackingNumber}
                          </a>
                        </div>
                      );
                    }
                  )}
              </TableItem>
            </Col>

            <Col md={{ span: 6 }} span={12}>
              <TableTitle>Future Ship Date</TableTitle>
              <TableItem>
                {order.customFields?.futureShipDate
                  ? moment(order.customFields?.futureShipDate).format(
                      "MM/DD/YYYY"
                    )
                  : ""}
              </TableItem>
            </Col>
          </Row>
        </MarginBox>
        <Divider />
        <MarginBox mb={24}>
          <Row gutter={[24, 24]}>
            <Col md={12} span={24}>
              <TableBigTitle>Billing Information</TableBigTitle>
              <TableItem>
                <br />
                <strong>{order.billingAddress?.fullName}</strong>
              </TableItem>

              <TableItem>{order.billingAddress?.streetLine1}</TableItem>
              <TableItem>{order.billingAddress?.streetLine2}</TableItem>
              <TableItem>
                {order.billingAddress?.city},&nbsp;
                {order.billingAddress?.province}&nbsp;
                {order.billingAddress?.postalCode}
              </TableItem>
              <TableItem>
                {(order.billingAddress as any)?.customFields?.email}
              </TableItem>
              <TableItem>
                {phoneNumberFormatter(order.billingAddress?.phoneNumber)}
              </TableItem>

              <TableItem>Company: {order.billingAddress?.company}</TableItem>

              <TableItem>
                DOB:{" "}
                {moment(order.billingAddress!?.customFields?.dob).format(
                  "DD/MM/YYYY"
                )}
              </TableItem>
            </Col>

            <Col md={12} span={24}>
              <TableBigTitle>Shipping Information</TableBigTitle>
              <TableItem>
                <br />
                <strong>{order.shippingAddress?.fullName}</strong>
              </TableItem>
              <TableItem>{order.shippingAddress?.streetLine1}</TableItem>
              <TableItem>{order.shippingAddress?.streetLine2}</TableItem>
              <TableItem>
                {order.shippingAddress?.city},&nbsp;
                {order.shippingAddress?.province}&nbsp;
                {order.shippingAddress?.postalCode}
              </TableItem>

              <TableItem>
                {(order.shippingAddress as any)?.customFields?.email}
              </TableItem>
              <TableItem>
                {phoneNumberFormatter(order.shippingAddress?.phoneNumber)}
              </TableItem>

              <TableItem>Company: {order.shippingAddress?.company}</TableItem>

              <TableItem>
                DOB:{" "}
                {moment(
                  (order.shippingAddress as any)?.customFields?.dob
                ).format("DD/MM/YYYY")}
              </TableItem>
            </Col>

            <Col md={12} span={24}>
              <TableBigTitle>Pickup/Shipping Method</TableBigTitle>
              {orderCopy.pickupLocationLabel && (
                <TableItem>{orderCopy.pickupLocationLabel}</TableItem>
              )}
              {orderCopy.shippingMethodLabel && (
                <TableItem>{orderCopy.shippingMethodLabel}</TableItem>
              )}
            </Col>

            <Col md={12} span={24}>
              <TableBigTitle>Special Instruction</TableBigTitle>
              <TableItem>{order.customFields?.specialInstructions}</TableItem>
            </Col>
          </Row>
        </MarginBox>
        {order.customFields?.giftMessage && (
          <MarginBox mb={24}>
            <Row>
              <Col>
                <TableBigTitle>Gift Message</TableBigTitle>
                <TableItem>{order.customFields?.giftMessage}</TableItem>
              </Col>
            </Row>
          </MarginBox>
        )}
        <Divider />
        <MarginBox mt={24}>
          <Row gutter={24} align="bottom">
            <Col span={16}>
              <PricingTable order={order} />
            </Col>
          </Row>
        </MarginBox>
      </TwfModal>

      <ComplianceView
        complianceVisible={complianceVisible}
        setComplianceVisible={setComplianceVisible}
        selectedId={orderId}
        customFields={cust}
      />
    </React.Fragment>
  );
};

export default OrderView;
