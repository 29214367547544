import React from "react";

interface Props {
  width?: number;
}

const CheckedIllus = ({ width }: Props) => {
  return (
    <svg
      width={width ? width : 90}
      height={width ? width : 90}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45 5C37.0888 5 29.3552 7.34596 22.7772 11.7412C16.1992 16.1365 11.0723 22.3836 8.04484 29.6927C5.01733 37.0017 4.2252 45.0444 5.76861 52.8036C7.31202 60.5629 11.1216 67.6902 16.7157 73.2843C22.3098 78.8784 29.4372 82.688 37.1964 84.2314C44.9556 85.7748 52.9983 84.9827 60.3074 81.9552C67.6164 78.9277 73.8635 73.8008 78.2588 67.2228C82.6541 60.6448 85 52.9113 85 45C85 34.3913 80.7857 24.2172 73.2843 16.7157C65.7828 9.21427 55.6087 5 45 5ZM71.125 31.575L38.275 64.4L18.875 45C18.212 44.337 17.8395 43.4377 17.8395 42.5C17.8395 41.5623 18.212 40.663 18.875 40C19.5381 39.337 20.4373 38.9645 21.375 38.9645C22.3127 38.9645 23.212 39.337 23.875 40L38.325 54.45L66.175 26.625C66.5033 26.2967 66.8931 26.0363 67.322 25.8586C67.751 25.6809 68.2107 25.5895 68.675 25.5895C69.1393 25.5895 69.5991 25.6809 70.028 25.8586C70.457 26.0363 70.8467 26.2967 71.175 26.625C71.5033 26.9533 71.7637 27.3431 71.9414 27.772C72.1191 28.201 72.2106 28.6607 72.2106 29.125C72.2106 29.5893 72.1191 30.049 71.9414 30.478C71.7637 30.9069 71.5033 31.2967 71.175 31.625L71.125 31.575Z"
        fill="#42b13c"
      />
    </svg>
  );
};

export default CheckedIllus;
