import React from "react";
import TwfModal from "../../../component/modal";
import ModalTitle from "../../../component/modal/title";
import { H3 } from "../../../component/header";
import Icon from "../../../assets/icons";
import { Col, Form, Input, message, Row } from "antd";
import TwfButton from "../../../component/button";
import { MarginBox } from "../../../component/margin";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useCreateAdministratorMutation, useGetActiveChannelQuery, useGetRolesLazyQuery } from "generated/graphql";
import { useForm } from "antd/lib/form/Form";

const { Item } = Form;

interface ComponentProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const AddNewUser: React.FC<ComponentProps> = ({ visible, setVisible }) => {
  const [form] = useForm();
  const breakpoints = useBreakpoint();
  const [createAdministratorMutation, { loading }] =
    useCreateAdministratorMutation();
  
  const { data: getChannelResult } = useGetActiveChannelQuery();
  
  const [getRoles] = useGetRolesLazyQuery({
      fetchPolicy:"network-only"
  })

  const submitFormHandler = (formData: any) => {
    formData.customFields = {} as any;
    formData.customFields.isStoreAdmin=true; //need to ask
    if(getChannelResult?.activeChannel){
    if(getChannelResult.activeChannel.code==="__default_channel__"){
      createIt(formData,"1");
    }else{
      let roleId: string;
      getRoles().then((data)=>{
        if(data.data?.roles && data.data?.roles.items){
          const roles = data.data.roles.items;
          for(let i=0;i<roles.length;i++){
            const channelids = roles[i].channels.map((channel)=>channel.id);
            if(channelids.length===1 && channelids.indexOf(String(getChannelResult.activeChannel.id))!==-1){
              roleId=String(roles[i].id);
              break;
            }
          }
        }
        createIt(formData,roleId);
      }).catch((err)=>{
        message.error(err.message);
      })
    }
    }
  };

  const createIt = (formData: any, roleId:string) => {

    createAdministratorMutation(getCreateAdminVariables(formData,roleId))
      .then(({ data }: any) => {
        if (data?.createAdministrator.__typename === "Administrator") {
          message.success("User created with default password 123456!");
          form.resetFields();
          setVisible(false);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  }

  const getCreateAdminVariables = (data: any, roleId: string) => ({
    variables: {
      input: {
        ...data,
        password: "123456",
        roleIds: [roleId],
      },
    },
    refetchQueries:["GetAdministrators"]
  });

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      centered
      footer={false}
      closeIcon={<Icon keyword="close" />}
      width={662}
    >
      <Form form={form} layout="vertical" onFinish={submitFormHandler}>
        <ModalTitle>
          <H3>Add New Users</H3>
        </ModalTitle>
        <MarginBox mt={32} mb={32} style={{ minHeight: 250 }}>
          <Row>
            <Col span={24}>
              <Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name!",
                  },
                ]}
              >
                <Input placeholder="Kate" />
              </Item>
              <Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name!",
                  },
                ]}
              >
                <Input placeholder="Johnson" />
              </Item>
              <Item
                label="Email"
                name="emailAddress"
                rules={[
                  {
                    required: true,
                    message: "Please enter email address!",
                  },
                ]}
              >
                <Input placeholder="katejohnson@thewinefoundry.com" />
              </Item>
              {
                /*
                <Item
                label="Is Store Owner?"
              >
                <Checkbox onChange={(e)=>{
                  setOwner(e.target.checked);
                }} style={{paddingLeft:"2%"}}></Checkbox>
              </Item>
                */
              }
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <TwfButton type="link">
                <Icon keyword="plus" color="#A6ACBE" />
                Add More
              </TwfButton>
            </Col>
          </Row> */}
        </MarginBox>
        <Row gutter={breakpoints.md ? [24, 24] : [12, 12]} justify="end">
          <Col>
            <TwfButton
              color-twf="gray-line"
              size-twf="md"
              type-twf="wide"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </TwfButton>
          </Col>
          <Col>
            <TwfButton
              type="primary"
              color-twf="primary"
              size-twf="md"
              type-twf="wide"
              htmlType="submit"
              loading={loading}
            >
              Add
            </TwfButton>
          </Col>
        </Row>
      </Form>
    </TwfModal>
  );
};

export default AddNewUser;
